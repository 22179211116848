import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import ProfileCard from 'components/profileCard/profileCard';
import Label from 'components/label/label';
import { Row, Col, Dropdown, Menu } from 'antd';
import { 
    LLData, GeneralConditionData, ActiveRangeMotionData, 
    MuscleToneData, MuscleStrengthData, BalanceData, MovementAnalysisData 
} from '../type';
import BlockTable, { RowData } from 'components/blockTable/blockTable';
import { GlobalContext } from '../../../../../index';
import { SitToStandView, GaitAnalysisView, StairAscentView, StairDescentView, SquattingView, SingleLegStanceView } from '../TrainingItemComponents';
import { icon_info_black } from 'components/image';
import { CloseOutlined } from '@ant-design/icons';

const AdditionalData = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    opacity: 0.4;
    word-break: break-all;
`;

interface Props {
    setDrawerSetting: React.Dispatch<React.SetStateAction<{
        showDrawer: boolean;
        tabKey: string;
    }>>;
    suspend: boolean;
}

interface LLPageProps extends Props {
    data?: LLData;
}

const LLPage: React.FC<LLPageProps> = (props: LLPageProps) => {

    return (
        <div>
            <GeneralCondition {...props} />
            <ActiveRangeMotion {...props} />
            <MuscleTone {...props} />
            <MuscleStrength {...props} />
            <Balance {...props} />
            <MovementAnalysis {...props} />
        </div>
    )
}

export default LLPage;


interface GeneralConditionProps extends Props {
    data?: GeneralConditionData;
}

const GeneralCondition: React.FC<GeneralConditionProps> = (props: GeneralConditionProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const sensationCol = [
        formatMessage({id:'LT'}),
        formatMessage({id:'RT'})
    ];
    const sensationData: RowData[] = [
        {
            rowHeader: 'Proprioception',
            [formatMessage({id:'LT'})]: (
                <div>
                    <div className='text-16 text--bold'>{props.data?.gcSensationLtProprioceptionCode || '--'}</div>
                    {(props.data?.gcSensationLtProprioceptionCode === nameMapping['PRO02'] || props.data?.gcSensationLtProprioceptionCode === nameMapping['PRO03']) &&
                        <AdditionalData>{props.data?.gcSensationLtProprioceptionText}</AdditionalData>
                    }
                </div>
            ),
            [formatMessage({id:'RT'})]: (
                <div>
                    <div className='text-16 text--bold'>{props.data?.gcSensationRtProprioceptionCode || '--'}</div>
                    {(props.data?.gcSensationRtProprioceptionCode === nameMapping['PRO02'] || props.data?.gcSensationRtProprioceptionCode === nameMapping['PRO03']) &&
                        <AdditionalData>{props.data?.gcSensationRtProprioceptionText}</AdditionalData>
                    }
                </div>
            )
        },
        {
            rowHeader: 'Light touch',
            [formatMessage({id:'LT'})]: (
                <div>
                    <div className='text-16 text--bold'>{props.data?.gcSensationLtLightTouchCode || '--'}</div>
                    {(props.data?.gcSensationLtLightTouchCode === nameMapping['PRO02'] || props.data?.gcSensationLtLightTouchCode === nameMapping['PRO03']) &&
                        <AdditionalData>{props.data?.gcSensationLtLightTouchCodeText}</AdditionalData>
                    }
                </div>
            ),
            [formatMessage({id:'RT'})]: (
                <div>
                    <div className='text-16 text--bold'>{props.data?.gcSensationRtLightTouchCode || '--'}</div>
                    {(props.data?.gcSensationRtLightTouchCode === nameMapping['PRO02'] || props.data?.gcSensationRtLightTouchCode === nameMapping['PRO03']) &&
                        <AdditionalData>{props.data?.gcSensationRtLightTouchCodeText}</AdditionalData>
                    }
                </div>
            )
        }
    ]

    return (
        <ProfileCard
            editable={!props.suspend}
            onEdit={() => props.setDrawerSetting({ showDrawer: true, tabKey: 'GeneralCondition' })}
            title={formatMessage({ id: 'GENERAL_CONDITION' })}
        >
            <Row className='mb-30' gutter={24}>
                <Col span={6}>
                    <Label
                        name={formatMessage({ id: 'MENTAL_STATUS' })}
                        text={props.data?.gcMentalStatusCode || '--'}
                    />
                </Col>
                <Col span={6}>
                    <Label
                        name={formatMessage({ id: 'MOTIVATION' })}
                        text={props.data?.gcMotivationCode || '--'}
                    />
                </Col>
                <Col span={6}>
                    <Label
                        name={formatMessage({ id: 'ATTENTION' })}
                        text={props.data?.gcAttentionCode || '--'}
                    />
                </Col>
                <Col span={6}></Col>
            </Row>

            <Row className='mb-30' gutter={24}>
                <Col span={6}>
                    <Label
                        name={formatMessage({ id: 'COMMUNICATION' })}
                        text={props.data?.gcCommunicationCode || '--'}
                    />
                    {props.data?.gcCommunicationCode === nameMapping['COM09'] && 
                    <AdditionalData>
                        {props.data?.gcCommunicationOther}
                    </AdditionalData>}
                </Col>
                <Col span={6}>
                    <Label 
                        name={formatMessage({ id: 'VISION' })} 
                        text={props.data?.gcVisionCode || '--'} 
                    />
                    {props.data?.gcVisionCode === nameMapping['VIS02'] && 
                    <AdditionalData>
                        {props.data?.gcVisionNeglectCode}
                    </AdditionalData>}
                    {props.data?.gcVisionCode === nameMapping['VIS04'] && 
                    <AdditionalData>
                        {props.data?.gcVisionOther}
                    </AdditionalData>}
                </Col>
                <Col span={6}>
                    <Label 
                        name={formatMessage({ id: 'HEARING' })} 
                        text={props.data?.gcHearingCode || '--'} 
                    />
                    {(props.data?.gcHearingCode === nameMapping['HEA02'] || props.data?.gcHearingCode === nameMapping['HEA03'] ||props.data?.gcHearingCode === nameMapping['HEA04']) &&
                    <AdditionalData>
                        {props.data?.gcHearingImpairedHardHearingDeafCode}
                    </AdditionalData>}
                </Col>
            </Row>
            <Row className='mb-30' gutter={24}>
                <Col span={6}>
                    <Label 
                        name={formatMessage({ id: 'PAIN' })} 
                        text={props.data?.gcPainCode || '--'} 
                    />
                    {props.data?.gcPainCode === nameMapping['LOC02'] &&
                    <AdditionalData>
                        {props.data?.gcPainLocationText} 
                    </AdditionalData>}
                    <AdditionalData>
                        {props.data?.gcPainVasText}
                    </AdditionalData>
                </Col>
                <Col span={6}>
                    <Label 
                        name={formatMessage({ id: 'SWELLING' })} 
                        text={props.data?.gcSwellingCode || '--'} 
                    />
                    {props.data?.gcSwellingCode === nameMapping['LOC02'] &&
                    <AdditionalData>
                        {props.data?.gcSwellingLocationText}
                    </AdditionalData>}
                </Col>
            </Row>

            <Label name={formatMessage({id:'SENSATION'})} text=''/>
            <BlockTable colHeader={sensationCol} data={sensationData} width='70%' />
            
        </ProfileCard>
    )
}


interface ActiveRangeMotionProps extends Props {
    data?: ActiveRangeMotionData;
}

const ActiveRangeMotion: React.FC<ActiveRangeMotionProps> = (props: ActiveRangeMotionProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const activeRangeFormatter = (code?: string, limit?: number) => {
        if(code === undefined){
            return '--';
        }

        if(code === nameMapping['ARM01'] || code === nameMapping['ARM03']){
            return code;
        }
        return code + (limit !== undefined ? (' ' + limit?.toString() + '°') : '');
    }

    const activeRangeCol = [
        formatMessage({id:'LT'}),
        formatMessage({id:'RT'})
    ];

    const activeRangeULData: RowData[] = [
        {
            rowHeader: 'Shoulder',
            [formatMessage({id:'LT'})]: (
                <div>
                    <Row gutter={24} className='mb-30'>
                        <Col span={8}>
                            <Label name='Flex' text={activeRangeFormatter(props.data?.armUlLtShoulderFlexCode, props.data?.armUlLtShoulderFlexLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Ext' text={activeRangeFormatter(props.data?.armUlLtShoulderExtCode, props.data?.armUlLtShoulderExtLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Abd' text={activeRangeFormatter(props.data?.armUlLtShoulderAbdCode, props.data?.armUlLtShoulderAbdLimit)}/>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Add' text={activeRangeFormatter(props.data?.armUlLtShoulderAddCode, props.data?.armUlLtShoulderAddLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='ER' text={activeRangeFormatter(props.data?.armUlLtShoulderErCode, props.data?.armUlLtShoulderErLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='IR' text={activeRangeFormatter(props.data?.armUlLtShoulderIrCode, props.data?.armUlLtShoulderIrLimit)}/>
                        </Col>
                    </Row>
                </div>
            ),
            [formatMessage({id:'RT'})]: (
                <div>
                    <Row gutter={24} className='mb-30'>
                        <Col span={8}>
                            <Label name='Flex' text={activeRangeFormatter(props.data?.armUlRtShoulderFlexCode, props.data?.armUlRtShoulderFlexLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Ext' text={activeRangeFormatter(props.data?.armUlRtShoulderExtCode, props.data?.armUlRtShoulderExtLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Abd' text={activeRangeFormatter(props.data?.armUlRtShoulderAbdCode, props.data?.armUlRtShoulderAbdLimit)}/>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Add' text={activeRangeFormatter(props.data?.armUlRtShoulderAddCode, props.data?.armUlRtShoulderAddLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='ER' text={activeRangeFormatter(props.data?.armUlRtShoulderErCode, props.data?.armUlRtShoulderErLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='IR' text={activeRangeFormatter(props.data?.armUlRtShoulderIrCode, props.data?.armUlRtShoulderIrLimit)}/>
                        </Col>
                    </Row>
                </div>
            )
        },
        {
            rowHeader: 'Elbow',
            [formatMessage({id:'LT'})]: (
                <div>
                    <Row gutter={24} className='mb-30'>
                        <Col span={8}>
                            <Label name='Flex' text={activeRangeFormatter(props.data?.armUlLtElbowFlexCode, props.data?.armUlLtElbowFlexLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Ext' text={activeRangeFormatter(props.data?.armUlLtElbowExtCode, props.data?.armUlLtElbowExtLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Pronation' text={activeRangeFormatter(props.data?.armUlLtElbowPronationCode, props.data?.armUlLtElbowPronationLimit)}/>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Supination' text={activeRangeFormatter(props.data?.armUlLtElbowSupinationCode, props.data?.armUlLtElbowSupinationLimit)}/>
                        </Col>
                        <Col span={16}></Col>
                    </Row>
                </div>
            ),
            [formatMessage({id:'RT'})]: (
                <div>
                    <Row gutter={24} className='mb-30'>
                        <Col span={8}>
                            <Label name='Flex' text={activeRangeFormatter(props.data?.armUlRtElbowFlexCode, props.data?.armUlRtElbowFlexLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Ext' text={activeRangeFormatter(props.data?.armUlRtElbowExtCode, props.data?.armUlRtElbowExtLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Pronation' text={activeRangeFormatter(props.data?.armUlRtElbowPronationCode, props.data?.armUlRtElbowPronationLimit)}/>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Supination' text={activeRangeFormatter(props.data?.armUlRtElbowSupinationCode, props.data?.armUlRtElbowSupinationLimit)}/>
                        </Col>
                        <Col span={16}></Col>
                    </Row>
                </div>
            )
        },
        {
            rowHeader: 'Finger',
            [formatMessage({id:'LT'})]: (
                <div>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Gripping' text={props.data?.armUlLtFinerGrippingCode || '--'}/>
                        </Col>
                        <Col span={16}></Col>
                    </Row>
                </div>
            ),
            [formatMessage({id:'RT'})]: (
                <div>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Gripping' text={props.data?.armUlRtFinerGrippingCode || '--'}/>
                        </Col>
                        <Col span={16}></Col>
                    </Row>
                </div>
            )
        }
    ];

    const activeRangeLLData: RowData[] = [
        {
            rowHeader: 'Hip',
            [formatMessage({id:'LT'})]: (
                <div>
                    <Row gutter={24} className='mb-30'>
                        <Col span={8}>
                            <Label name='Flex' text={activeRangeFormatter(props.data?.armLlLtHipFlexCode, props.data?.armLlLtHipFlexLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Ext' text={activeRangeFormatter(props.data?.armLlLtHipExtCode, props.data?.armLlLtHipExtLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Abd' text={activeRangeFormatter(props.data?.armLlLtHipAbdCode, props.data?.armLlLtHipAbdLimit)}/>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Add' text={activeRangeFormatter(props.data?.armLlLtHipAddCode, props.data?.armLlLtHipAddLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='ER' text={activeRangeFormatter(props.data?.armLlLtHipErCode, props.data?.armLlLtHipErLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='IR' text={activeRangeFormatter(props.data?.armLlLtHipIrCode, props.data?.armLlLtHipIrLimit)}/>
                        </Col>
                    </Row>
                </div>
            ),
            [formatMessage({id:'RT'})]: (
                <div>
                    <Row gutter={24} className='mb-30'>
                        <Col span={8}>
                            <Label name='Flex' text={activeRangeFormatter(props.data?.armLlRtHipFlexCode, props.data?.armLlRtHipFlexLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Ext' text={activeRangeFormatter(props.data?.armLlRtHipExtCode, props.data?.armLlRtHipExtLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Abd' text={activeRangeFormatter(props.data?.armLlRtHipAbdCode, props.data?.armLlRtHipAbdLimit)}/>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Add' text={activeRangeFormatter(props.data?.armLlRtHipAddCode, props.data?.armLlRtHipAddLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='ER' text={activeRangeFormatter(props.data?.armLlRtHipErCode, props.data?.armLlRtHipErLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='IR' text={activeRangeFormatter(props.data?.armLlRtHipIrCode, props.data?.armLlRtHipIrLimit)}/>
                        </Col>
                    </Row>
                </div>
            )
        },
        {
            rowHeader: 'Knee',
            [formatMessage({id:'LT'})]: (
                <div>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Flex' text={activeRangeFormatter(props.data?.armLlLtKneeFlexCode, props.data?.armLlLtKneeFlexLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Ext' text={activeRangeFormatter(props.data?.armLlLtKneeExtCode, props.data?.armLlLtKneeExtLimit)}/>
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                </div>
            ),
            [formatMessage({id:'RT'})]: (
                <div>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Flex' text={activeRangeFormatter(props.data?.armLlRtKneeFlexCode, props.data?.armLlRtKneeFlexLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Ext' text={activeRangeFormatter(props.data?.armLlRtKneeExtCode, props.data?.armLlRtKneeExtLimit)}/>
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                </div>
            )
        },
        {
            rowHeader: 'Ankle',
            [formatMessage({id:'LT'})]: (
                <div>
                    <Row gutter={24} className='mb-30'>
                        <Col span={8}>
                            <Label name='DF' text={activeRangeFormatter(props.data?.armLlLtAnkleDfCode, props.data?.armLlLtAnkleDfLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='PF' text={activeRangeFormatter(props.data?.armLlLtAnklePfCode, props.data?.armLlLtAnklePfLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Inversion' text={activeRangeFormatter(props.data?.armLlLtAnkleInversionCode, props.data?.armLlLtAnkleInversionLimit)}/>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Eversion' text={activeRangeFormatter(props.data?.armLlLtAnkleEversionCode, props.data?.armLlLtAnkleEversionLimit)}/>
                        </Col>
                        <Col span={16}></Col>
                    </Row>
                </div>
            ),
            [formatMessage({id:'RT'})]: (
                <div>
                    <Row gutter={24} className='mb-30'>
                        <Col span={8}>
                            <Label name='DF' text={activeRangeFormatter(props.data?.armLlRtAnkleDfCode, props.data?.armLlRtAnkleDfLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='PF' text={activeRangeFormatter(props.data?.armLlRtAnklePfCode, props.data?.armLlRtAnklePfLimit)}/>
                        </Col>
                        <Col span={8}>
                            <Label name='Inversion' text={activeRangeFormatter(props.data?.armLlRtAnkleInversionCode, props.data?.armLlRtAnkleInversionLimit)}/>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Label name='Eversion' text={activeRangeFormatter(props.data?.armLlRtAnkleEversionCode, props.data?.armLlRtAnkleEversionLimit)}/>
                        </Col>
                        <Col span={16}></Col>
                    </Row>
                </div>
            )
        }
    ];

    return (
        <ProfileCard
            editable={!props.suspend}
            onEdit={() => props.setDrawerSetting({ showDrawer: true, tabKey: 'ActiveRange' })}
            title={formatMessage({ id: 'ACTIVE_RANGE_OF_MOTION' })}
        >
            <Label name='Upper Limbs' text=''/>
            <BlockTable colHeader={activeRangeCol} data={activeRangeULData} colSpacing rowHeaderWidth='10%' rowHeaderVertical='top'/>

            <Label className='mt-30' name='Lower Limbs' text=''/>
            <BlockTable colHeader={activeRangeCol} data={activeRangeLLData} colSpacing rowHeaderWidth='10%' rowHeaderVertical='top'/>
        </ProfileCard>
    )
}


interface MuscleToneProps extends Props {
    data?: MuscleToneData;
}

const MuscleTone: React.FC<MuscleToneProps> = (props: MuscleToneProps) => {

    const { formatMessage } = useIntl();

    const muscleToneCol = [
        formatMessage({ id: 'LT' }),
        formatMessage({ id: 'RT' })
    ];

    const muscleToneULData: RowData[] = [
        {
            rowHeader: 'Shoulder flexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtUlLtShoulderFlexorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtUlRtShoulderFlexorCode || '--'}</div>
        },
        {
            rowHeader: 'Shoulder adductor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtUlLtShoulderAdductorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtUlRtShoulderAdductorCode || '--'}</div>
        },
        {
            rowHeader: 'Elbow flexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtUlLtElbowFlexorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtUlRtElbowFlexorCode || '--'}</div>
        },
        {
            rowHeader: 'Wrist flexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtUlLtWristFlexorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtUlRtWristFlexorCode || '--'}</div>
        }
    ];

    const muscleToneLLData: RowData[] = [
        {
            rowHeader: 'Hip flexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtLlLtHipFlexorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtLlRtHipFlexorCode || '--'}</div>
        },
        {
            rowHeader: 'Hip extensor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtLlLtHipExtensorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtLlRtHipExtensorCode || '--'}</div>
        },
        {
            rowHeader: 'Hip adductor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtLlLtHipAdductorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtLlRtHipAdductorCode || '--'}</div>
        },
        {
            rowHeader: 'Knee flexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtLlLtKneeFlexorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtLlRtKneeFlexorCode || '--'}</div>
        },
        {
            rowHeader: 'Knee extensor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtLlLtKneeExtensorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtLlRtKneeExtensorCode || '--'}</div>
        },
        {
            rowHeader: 'Ankle plantarflexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.mtLlLtAnklePlantarflexorCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.mtLlRtAnklePlantarFlexorCode || '--'}</div>
        }
    ];

    return (
        <ProfileCard
            editable={!props.suspend}
            onEdit={() => props.setDrawerSetting({ showDrawer: true, tabKey: 'MuscleTone' })}
            title={<div>{formatMessage({ id: 'MUSCLE_TONE' })}<span style={{fontWeight: 'normal'}}> - {formatMessage({id:'MAS_SCORE'})}</span></div>}
        >
            <Label name='Upper Limbs' text=''/>
            <BlockTable colHeader={muscleToneCol} data={muscleToneULData} width='60%' rowHeaderWidth='20%'/>

            <Label className='mt-30' name='Lower Limbs' text=''/>
            <BlockTable colHeader={muscleToneCol} data={muscleToneLLData} width='60%' rowHeaderWidth='20%'/>
        </ProfileCard>
    )
}


interface MuscleStrengthProps extends Props {
    data?: MuscleStrengthData;
}

const MuscleStrength: React.FC<MuscleStrengthProps> = (props: MuscleStrengthProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const muscleStrengthCol = [
        formatMessage({ id: 'LT' }),
        formatMessage({ id: 'RT' })
    ];

    const muscleStrengthULData: RowData[] = [
        {
            rowHeader: 'General',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.msUlLtGeneralCode || '--'}</div>,
            [formatMessage({ id: 'RT' })]:<div>{props.data?.msUlRtGeneralCode || '--'}</div>
        }
    ];

    const muscleStrengthLLData: RowData[] = [
        {
            rowHeader: 'Hip flexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.msLlLtHipFlexorCode || '--'}{props.data?.msLlLtHipFlexorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlLtHipFlexorOther}</AdditionalData>}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.msLlRtHipFlexorCode || '--'}{props.data?.msLlRtHipFlexorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlRtHipFlexorOther}</AdditionalData>}</div>
        },
        {
            rowHeader: 'Hip abductor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.msLlLtHipAbductorCode || '--'}{props.data?.msLlLtHipAbductorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlLtHipAbductorOther}</AdditionalData>}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.msLlRtHipAbductorCode || '--'}{props.data?.msLlRtHipAbductorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlRtHipAbductorOther}</AdditionalData>}</div>
        },
        {
            rowHeader: 'Hip adductor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.msLlLtHipAdductorCode || '--'}{props.data?.msLlLtHipAdductorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlLtHipAdductorOther}</AdditionalData>}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.msLlRtHipAdductorCode || '--'}{props.data?.msLlRtHipAdductorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlRtHipAdductorOther}</AdditionalData>}</div>
        },
        {
            rowHeader: 'Knee flexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.msLlLtKneeFlexorCode || '--'}{props.data?.msLlLtKneeFlexorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlLtKneeFlexorOther}</AdditionalData>}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.msLlRtKneeFlexorCode || '--'}{props.data?.msLlRtKneeFlexorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlRtKneeFlexorOther}</AdditionalData>}</div>
        },
        {
            rowHeader: 'Knee extensor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.msLlLtKneeExtensorCode || '--'}{props.data?.msLlLtKneeExtensorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlLtKneeExtensorOther}</AdditionalData>}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.msLlRtKneeExtensorCode || '--'}{props.data?.msLlRtKneeExtensorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlRtKneeExtensorOther}</AdditionalData>}</div>
        },
        {
            rowHeader: 'Ankle dorsiflexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.msLlLtAnkleDorsiflexorCode || '--'}{props.data?.msLlLtAnkleDorsiflexorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlLtAnkleDorsiflexorOther}</AdditionalData>}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.msLlRtAnkleDorsiflexorCode || '--'}{props.data?.msLlRtAnkleDorsiflexorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlRtAnkleDorsiflexorOther}</AdditionalData>}</div>
        },
        {
            rowHeader: 'Ankle plantarflexor',
            [formatMessage({ id: 'LT' })]: <div>{props.data?.msLlLtAnklePlantarflexorCode || '--'}{props.data?.msLlLtAnklePlantarflexorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlLtAnklePlantarflexorOther}</AdditionalData>}</div>,
            [formatMessage({ id: 'RT' })]: <div>{props.data?.msLlRtAnklePlantarflexorCode || '--'}{props.data?.msLlRtAnklePlantarflexorCode === nameMapping['MSL10'] && <AdditionalData>{props.data?.msLlRtAnklePlantarflexorOther}</AdditionalData>}</div>
        }
    ];

    return (
        <ProfileCard
            editable={!props.suspend}
            onEdit={() => props.setDrawerSetting({ showDrawer: true, tabKey: 'MuscleStrength' })}
            title={<div>{formatMessage({ id: 'MUSCLE_STRENGTH' })}<span style={{fontWeight: 'normal'}}> - {formatMessage({id:'MMT_SCALE'})}</span></div>}
        >
            <Label name='Upper Limbs' text=''/>
            <BlockTable colHeader={muscleStrengthCol} data={muscleStrengthULData} width='60%' rowHeaderWidth='20%'/>

            <Label className='mt-30' name='Lower Limbs' text=''/>
            <BlockTable colHeader={muscleStrengthCol} data={muscleStrengthLLData} width='60%' rowHeaderWidth='20%'/>
        </ProfileCard>
    )
}


interface BalanceProps extends Props {
    data?: BalanceData;
}

const Balance: React.FC<BalanceProps> = (props: BalanceProps) => {

    const { formatMessage } = useIntl();

    return (
        <ProfileCard
            editable={!props.suspend}
            onEdit={() => props.setDrawerSetting({ showDrawer: true, tabKey: 'Balance' })}
            title={formatMessage({ id: 'BALANCE' })}
        >
            <Row gutter={24}>
                <Col span={5}>
                    <Label
                        name={<span>{formatMessage({ id: 'STATIC_SITTING_BALANCE' })}<BalanceInfoDropdown/></span>}
                        text={props.data?.balanceStaticSittingBalanceCode || '--'}
                    />
                </Col>
                <Col span={5}>
                    <Label
                        name={<span>{formatMessage({ id: 'STATIC_STANDING_BALANCE' })}<BalanceInfoDropdown/></span>}
                        text={props.data?.balanceStaticStandingBalanceCode || '--'}
                    />
                </Col>
                <Col span={5}>
                    <Label
                        name={<span>{formatMessage({ id: 'DYNAMIC_SITTING_BALANCE' })}<BalanceInfoDropdown dynamic/></span>}
                        text={props.data?.balanceDynamicSittingBalanceCode || '--'}
                    />
                </Col>
                <Col span={5}>
                    <Label
                        name={<span>{formatMessage({ id: 'DYNAMIC_STANDING_BALANCE' })}<BalanceInfoDropdown dynamic/></span>}
                        text={props.data?.balanceDynamicStandingBalanceCode || '--'}
                    />
                </Col>
                <Col span={4}></Col>
            </Row>
        </ProfileCard>
    )
}


interface MovementAnalysisProps extends Props {
    data?: MovementAnalysisData;
}

const MovementAnalysis: React.FC<MovementAnalysisProps> = (props: MovementAnalysisProps) => {

    const { formatMessage } = useIntl();

    return (
        <ProfileCard
            editable={!props.suspend}
            onEdit={() => props.setDrawerSetting({ showDrawer: true, tabKey: 'MovementAnalysis' })}
            title={formatMessage({ id: 'MOVEMENT_ANALYSIS' })}
        >
            <Label name={formatMessage({id:'ASSISTIVE_DEVICE'})} text={props.data?.makAssistiveDeviceCode || '--'}/>
            <AdditionalData>{props.data?.makAssistiveDeviceOther}</AdditionalData>

            <Row gutter={24} className='mt-20 mb-24'>
                <Col span={8}>
                    <SitToStandView 
                        data={{
                            time: props.data?.makSitStandTime || '--', 
                            observation: props.data?.makSitStandObservation || '--'
                        }} 
                    />
                </Col>
                <Col span={8}>
                    <GaitAnalysisView 
                        data={{
                            time: props.data?.makGaitAnalysisTime || '--', 
                            fac: props.data?.makGaitAnalysisFacScoreCode || '--', 
                            affected: props.data?.makGaitAnalysisAffectedSide || [], 
                            affectedOther: props.data?.makGaitAnalysisAffectedSideOther || '',
                            unaffected: props.data?.makGaitAnalysisUnaffectedSideCode || '--',
                            unaffectedOther: props.data?.makGaitAnalysisUnaffectedSideOther || ''
                        }} 
                    />
                </Col>
                <Col span={8}>
                    <StairAscentView 
                        data={{
                            time: props.data?.makStairAscentTime || '--', 
                            stairs: props.data?.makStairAscentStairs.toString() || '--', 
                            observation: props.data?.makStairAscentObservation || '--'
                        }} 
                    />
                </Col>
            </Row>
            <Row gutter={24} className='mb-50'>
                <Col span={8}>
                    <StairDescentView 
                        data={{
                            time: props.data?.makStairDescentTime || '--', 
                            stairs: props.data?.makStairDescentStairs.toString() || '--', 
                            observation: props.data?.makStairDescentObservation || '--'
                        }} 
                        />
                </Col>
                <Col span={8}>
                    <SquattingView 
                        data={{
                            assistDevice: props.data?.makOtherSquattingAssistiveDeviceCode || '',
                            assistDeviceOther: props.data?.makOtherSquattingAssistiveDeviceOther || '',
                            affected: props.data?.makOtherSquattingAffectdSide || [],
                            affectedOther: props.data?.makOtherSquattingAffectdSideOther || ''
                        }} 
                    />
                </Col>
                <Col span={8}>
                    <SingleLegStanceView 
                        data={{
                            assistDevice: props.data?.makOtherSingleLegStanceAssistiveDeviceCode || '',
                            assistDeviceOther: props.data?.makOtherSingleLegStanceAssistiveDeviceOther || '',
                            affectedLt: props.data?.makOtherLtSingleLegStanceAffectdSide || [],
                            affectedLtOther: props.data?.makOtherLtSingleLegStanceAffectdSideOther || '',
                            affectedRt: props.data?.makOtherRtSingleLegStanceAffectdSide || [],
                            affectedRtOther: props.data?.makOtherRtSingleLegStanceAffectdSideOther || ''
                        }} 
                    />
                </Col>
            </Row>
        </ProfileCard>
    )
}


const BalanceInfoDropdown: React.FC<{dynamic?: boolean}> = ({dynamic}) => {

    const [toggleDropdown, setToggleDropdown] = useState(false);
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const staticContent = (
        <div>
            <div className='mb-10'>
                <div className='text--bold'>{nameMapping['CON01']}</div>
                <p style={{fontSize: '12px', color: '#909090', width: '200px'}}>
                    Can maintain upright sitting for ≥ 2 minutes with minimal sway.
                </p>
            </div>
            <div className='mb-10'>
                <div className='text--bold'>{nameMapping['CON02']}</div>
                <p style={{fontSize: '12px', color: '#909090', width: '200px'}}>
                    Can maintain upright sitting for ≥ 30 seconds.
                </p>
            </div>
            <div className='mb-10'>
                <div className='text--bold'>{nameMapping['CON03']}</div>
                <p style={{fontSize: '12px', color: '#909090', width: '200px'}}>
                    {'Can maintain upright sitting < 30 seconds, or can maintain upright sitting by holding onto external support.'}
                </p>
            </div>
        </div>
    );

    const dynamicContent = (
        <div>
            <div className='mb-10'>
                <div className='text--bold'>{nameMapping['CON01']}</div>
                <p style={{fontSize: '12px', color: '#909090', width: '300px'}}>
                    Can reach forward ≥ 10 cm in different directions and return to original position; can withstand moderate external perturbation; can bend over and pick up objects from the floor.
                </p>
            </div>
            <div className='mb-10'>
                <div className='text--bold'>{nameMapping['CON02']}</div>
                <p style={{fontSize: '12px', color: '#909090', width: '300px'}}>
                    Can reach forward ≥ 10 cm in different directions but cannot return to original position; can withstand minimal external perturbation; can maintain sitting balance while rotating head or trunk.
                </p>
            </div>
            <div className='mb-10'>
                <div className='text--bold'>{nameMapping['CON03']}</div>
                <p style={{fontSize: '12px', color: '#909090', width: '300px'}}>
                    Can initiate movement in sitting position, but loses balance immediately.
                </p>
            </div>
        </div>
    )

    const dropdownContent = (
        <Menu>
            <div className="d-flex is--space-between v--center mb-15">
                <span className='text--bold text-16'>Criteria</span>
                <CloseOutlined onClick={()=>setToggleDropdown(!toggleDropdown)}/>
            </div>
            {dynamic ? dynamicContent : staticContent}
        </Menu>
    )

    return (
        <Dropdown 
            overlay={dropdownContent}
            placement='bottomRight'
            trigger={['click']}
            visible={toggleDropdown}
            onVisibleChange={visible=>setToggleDropdown(visible)}
            className='ml-10 cursor--pointer'
            getPopupContainer={trigger=>trigger.parentElement || document.body}
            overlayStyle={{zIndex: 1}}
        >
            <img src={icon_info_black}/>
        </Dropdown>
    )
}
