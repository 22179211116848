import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import { Modal } from 'antd';
import ImageMapping from 'constants/imageMapping';
import { GlobalContext } from '../../../../../index';
import { getKRAList, TrainingPackage, PackageKRA, KRABrief } from 'api/summary';
import KRAChartTab from './KRAChartTab';
import moment from 'moment';
import ClassPreviewCard from './ClassPreviewCard';
import { KraConfig } from '../SummaryEdit';

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        padding-top: 24px;
        padding-bottom: 24px;
        display: flex;

        .chart {
            width: 60%;
            padding: 0 10px;
            display: flex;
            flex-direction: column;

            .header {
                font-size: 18px;
                font-weight: bold;
                color: #000000;
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                img {
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }
            }
        }

        .list {
            width: 40%;
            padding: 0 10px;
            overflow-y: scroll;

            .package {
                font-size: 18px;
                font-weight: bold;
                color: #000000;
            }

            .date {
                color: #909090;
                margin-bottom: 20px;
            }

            &::-webkit-scrollbar {
                width: 0;
            }
        }
    }
`;

interface KRADrawerProps {
    onClose: () => void;
    examItem: string;
    selelctedPackage: TrainingPackage[];
    closeLastDrawer: () => void;
    addKra: (kra: KraConfig) => void;
}

const KRASelectDrawer: React.FC<KRADrawerProps> = (props: KRADrawerProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const [kraList, setKraList] = useState<PackageKRA[]>([]);
    const [selectedKra, setSelectedKra] = useState<number[]>([]);
    const [kraData, setKraData] = useState<KRABrief[]>([]);

    useEffect(()=>{
        getKRAList({
            selectedPackages: props.selelctedPackage.map(d=>d.id),
            keeogoExaminationCode: props.examItem
        }).then(res=>{
            setKraList(res.data.data);
        }).catch(err=>{
            console.error(err)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        })
    }, []);

    const handleOptionOnClick = (kraDetail: KRABrief) => {
        const index = selectedKra.indexOf(kraDetail.configId);
        if(index < 0) { // not found
            setSelectedKra(prv=>prv.concat([kraDetail.configId]));
            setKraData(prv=>prv.concat([kraDetail]));
        }
        else {
            let newSelectedKra = selectedKra.slice();
            newSelectedKra.splice(index, 1);
            setSelectedKra(newSelectedKra);

            let newKraData = kraData.slice();
            newKraData.splice(index, 1);
            setKraData(newKraData);
        }
    }

    const handleGenerateOnClick = () => {
        props.addKra({
            trainingType: props.examItem,
            configId: selectedKra
        });
        props.closeLastDrawer();
        props.onClose();
    }

    const kraOptions = props.selelctedPackage.map(packageData=>(
        <div className='mb-20'>
            <div className='package'>{packageData.name}</div>
            <div className='date'>{formatMessage({id:'PURCHASE_DATE'})}: {moment(packageData.createdAt).format('DD/MM/YYYY')}</div>
            {(kraList.find(kra=>kra.packageName===packageData.name)?.kraReport ?? []).map(kraDetail=>(
                <ClassPreviewCard
                    name={kraDetail.class}
                    treatmentDate={kraDetail.treatmentDate}
                    treatmentTime={kraDetail.treatmentHour}
                    profile={kraDetail.profile}
                    assistDevice={kraDetail.assistDevice.map(d=>nameMapping[d])}
                    keeogoLevel={kraDetail.level === 'Free Mode' ? 'F' : kraDetail.level.slice(-1)}
                    onClick={()=>handleOptionOnClick(kraDetail)}
                    selected={selectedKra.includes(kraDetail.configId)}
                />
            ))}
        </div>
    ));
    
    return (
        <StyledDrawer
            visible
            closable
            maskClosable
            onClose={props.onClose}
            width='60vw'
            title={formatMessage({id:'KRA_REPORT'})}
            footer={
                <div className="pull--right">
                    <Button className="rect mr-10 text--bold" onClick={()=>props.onClose()} >
                        {formatMessage({id:'PREVIOUS_UPPERCASE'})}
                    </Button>
                    <Button className="is--primary rect text--bold" onClick={handleGenerateOnClick} >
                        {formatMessage({id:'GENERATE_UPPERCASE'})}
                    </Button>
                </div>
            }
        >
            <div className='chart'>
                <div className='header'>
                    <img src={ImageMapping.KRAExamination[props.examItem]}/>
                    {nameMapping[props.examItem]}
                </div>
                <KRAChartTab 
                    canvasId={`add-chart-${props.examItem}`} 
                    examItem={props.examItem} 
                    data={kraData.map(kra=>({
                        level: kra.level,
                        assistDevice: kra.assistDevice,
                        configId: kra.configId,
                        flexionHipAngleLeft: kra.summaryData.flexionHipAngleLeft,
                        flexionHipAngleRight: kra.summaryData.flexionHipAngleRight,
                        extensionHipAngleLeft: kra.summaryData.extensionHipAngleLeft,
                        extensionHipAngleRight: kra.summaryData.extensionHipAngleRight,
                        flexionKneeAngleLeft: kra.summaryData.flexionKneeAngleLeft,
                        flexionKneeAngleRight: kra.summaryData.flexionKneeAngleRight,
                        extensionKneeAngleLeft: kra.summaryData.extensionKneeAngleLeft,
                        extensionKneeAngleRight: kra.summaryData.extensionKneeAngleRight,
                        timeStep: kra.summaryData.timeStep,
                        avgStepLength: kra.summaryData.avgStepLength,
                        kraDateTime: kra.summaryData.kraDateTime
                    }))} 
                />
            </div>
            <div className='list'>
                {kraOptions}
            </div>
        </StyledDrawer>
    )
}

export default KRASelectDrawer;
