import React, { useState } from 'react';
import { Modal, Form } from 'antd';
import { Input } from 'components/form';
import { ModalProps } from 'antd/lib/modal';
import { pwd_done as done, pwd_reset as reset } from '../image';
import Button from '../button/button';
import styled from 'styled-components';
import './forgotPassword.sass';
import useVisible from '../../hooks/useVisible';
import { resetPasswordApi } from '../../api/login';
import { ResetPasswordRequest, ResetPasswordResponse } from '../../api/type';
import { apiCaller } from '../../service/apiCaller';
import { AxiosResponse } from 'axios';
import { useIntl } from 'react-intl';


type Props = Omit<ModalProps, 'onCancel'> & {
    onCancel: () => any;
};

/**
 * 控制忘記密碼的顯示
 *
 * @enum {number}
 */
enum Step {
    // Request Modal
    Request,
    // Response Modal
    Response
};

/**
 * api success 回傳的data type
 */
type Emails = {
    email?: string | undefined,
    subEmail?: string | undefined;
};

/**
 * 忘記密碼 component
 *
 * @export
 * @param {Props} { onCancel } callback of cancel ForgotPassword Modal
 * @returns
 */
export function ForgotPassword({ onCancel }: Props) {
    const [errMsgVisible, setErrMsgVisible] = useVisible();
    const [errMsg, setErrMsg] = useState<string>();
    const [form] = Form.useForm();
    const [step, setStep] = useState<Step>(Step.Request);
    const [emails, setEmails] = useState<Emails>();
    const {formatMessage} = useIntl() 

    /**
     * validate input value & call api
     *
     */
    const handleOk = () => {
        if (validateFields()) {
            const req: any/*ResetPasswordRequest*/ = {
                username: form.getFieldValue('account')
            }

            const success = (resp: AxiosResponse<any/*ResetPasswordResponse*/>) => {
                const {status, data: payload} = resp;
                const {success, data} = payload;
                if (status === 200 && success) {
                    const {email, subEmail} = data;
                    setEmails({email, subEmail});
                    setStep(Step.Response);
                } else {
                    reject(resp);
                }
            };

            const reject = (error: any) => {
                let resp = error as AxiosResponse;
                const {status, data: payload} = resp;
                const {success, code, msg} = payload;
                let errMsg = '';
                if (payload && success === false) {
                    errMsg = formatMessage({id:'ACCOUNT_NOT_FOUND'})
                } else {
                    errMsg = `status:${resp.status}, msg:${resp.statusText}`;
                }
                showErrMsg(errMsg);
            };

            apiCaller(resetPasswordApi, req, success, reject);
        }
    };
    

    /**
     * validate input value
     *
     * @returns
     */
    const validateFields = () => {
        let account = form.getFieldValue('account');
        if (account === undefined || account === '') {
            showErrMsg(formatMessage({id:'INTER_ACCOUNT'})+'!!!');
            return false;
        }
        return true;
    };

    const showErrMsg = (errMsg: string) => {
        if (errMsg) {
            setErrMsg(errMsg);
            if (!errMsgVisible) {
                setErrMsgVisible(true);
            }
        }
    };

    /**
     * return footer of Modal by Step enum
     *
     * @returns
     */
    const getModalFooter = () => {
        if (step === Step.Request) {
            return (
                <div className='forgot_password_footer'>
                    <Button
                        key="btn-ok"
                        className="forgot_password_button"
                        onClick={handleOk}
                    >
                        {formatMessage({id:'CONFIRM'})}  
                    </Button>
                    <Button
                        type='link'
                        key="btn-cancel"
                        className="forgot_password_cancel"
                        onClick={onCancel}
                    >
                        {formatMessage({id:'CANCEL'})}
                    </Button>
                </div>);
        } else if (step === Step.Response) {
            return (
                <div className='forgot_password_footer'>
                    <Button
                        key="btn-ok"
                        className="forgot_password_button"
                        onClick={onCancel}
                    >
                        {formatMessage({id:'CONFIRM'})}  
                    </Button>
                </div>);
        } else {
            return [];
        }
    };


    /**
     * return content of Modal by Step enum
     *
     * @returns
     */
    const getModalContent = () => {
        if (step === Step.Request) {
            return (
                <div className='forgot_password'>
                    <img className='forgot_password_pic' src={reset} alt='' />
                    <div className='forgot_password_title'>{formatMessage({id:'RESET_PASSWORD'})}</div>
                    <div className='forgot_password_desc'>{formatMessage({id:'RESET_PASSWORD_AS_ACCOUNT_LAST_5'})}</div>
                    <Form layout="vertical" form={form}  >
                        <Form.Item 
                            name='account' 
                            label={formatMessage({id:'ACCOUNT'})}
                            className='forgot_password_account_label' 
                        >
                            <Input 
                                className='forgot_password_account' 
                                placeholder={formatMessage({id:'INTER_ACCOUNT'})}
                            />
                        </Form.Item>
                    </Form>
                    <div className='forgot_password_err_msg' >
                        {errMsgVisible && (errMsg)}
                    </div>
                </div>
            );
        } else if (step === Step.Response) {
            return (
                <div className='forgot_password'>
                    <img className='forgot_password_pic' src={done} alt='' />
                    <div className='forgot_password_title'>{formatMessage({id:'RESET_PASSWORD_SUCCESS'})}</div>
                    <div className='forgot_password_desc'>{formatMessage({id:'RELOGIN'})}</div>
                </div>
            );
        } else {
            return [];
        }
    };

    return (
        <StyledModal
            centered={true}
            closable={false}
            visible={true}
            maskClosable={false}
            keyboard={false}
            onCancel={onCancel}
            footer={getModalFooter()}
        >
            {getModalContent()}
        </StyledModal>
    );
}

const StyledModal = styled(Modal)`
    
    .ant-modal-content {
        border-radius: 1rem;        
        text-align:center;
    }

    .ant-modal-footer {
        border: 0px
    }

    .ant-form {
        width: 370px;
    }
`;
