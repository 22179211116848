import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { stair_descent } from 'components/image';
import { ViewWrapper } from './index';

interface Props {
    data: {
        time: string;
        stairs: string;
        observation: string;
    };
}

const StairDescentView: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();

    const observationWithNewline = props.data.observation?.split('\n').map(d=><span>{d}<br/></span>) || '--';

    return (
        <ViewWrapper>
            <div className="header">
                {formatMessage({id:'ITEM_STAIR_DESCENT'})}
            </div>
            <div className="content">
                <div className="left">
                    <div className="icon">
                        <img src={stair_descent} width='100%' />
                    </div>
                </div>
                <div className="right">
                    <div className='d-flex mb-15'>
                        <div className='label' style={{width: '50%'}}>
                            <div className="name">{formatMessage({id:'TIME'})}</div>
                            <div className="text large">{props.data.time || '--'}</div>
                        </div>
                        <div className='label' style={{width: '50%'}}>
                            <div className="name">{formatMessage({id:'STAIRS'})}</div>
                            <div className="text large">{props.data.stairs || '--'}</div>
                        </div>
                    </div>
                    <div className='label'>
                        <div className="name">{formatMessage({id:'OBSERVATION'})}</div>
                        <div className="text">{observationWithNewline}</div>
                    </div>
                </div>
            </div>
        </ViewWrapper>
    )
}

export default StairDescentView;
