import React from "react";
import { Pagination, Button } from "antd";
import { VerticalRightOutlined, RightOutlined, VerticalLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { PaginationProps } from "antd/lib/pagination";
import styled from "styled-components";

const StyledPagination = styled(Pagination)`
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
        display: none;
    }
`;

export const PaginationButton = styled(Button)`
    .ant-btn {
        color: #0083C1;
    }

    .ant-btn:disabled {
        background-color: transparent;
        color: #C4C4C4;
    }
`;

const CustomPaginationWrapper = styled.div`
    background-color: white;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
    display: flex;
    height: 10%;

    .page_count, page_control {
        margin: 0 30px;
    }

    .page_control>* {
        margin: 0 10px;
    }
`;

export interface PaginationState {
    pageSize: number;
    page:number;
}

interface Props extends PaginationProps {
    pagination: PaginationState;
    paginationTotal: number;
    setPagination: Function;
}

const CustomPagination = (props: Props) => {
    return (
        <CustomPaginationWrapper>
            <div className='page_count'>
                {`${(props.pagination.page-1)*props.pagination.pageSize+1} - ${(props.pagination.page*props.pagination.pageSize)>props.paginationTotal?props.paginationTotal:(props.pagination.page*props.pagination.pageSize)} of ${props.paginationTotal}`}
            </div>
            <div className='page_control'>
                <PaginationButton type='link' disabled={props.pagination.page==1} onClick={()=>{props.setPagination({page: 1, pageSize: props.pagination.pageSize})}} icon={<VerticalRightOutlined/>}/>
                <PaginationButton type='link' disabled={props.pagination.page==1} onClick={()=>{props.setPagination({page: props.pagination.page-1, pageSize: props.pagination.pageSize})}} icon={<LeftOutlined/>}/>
                <PaginationButton type='link' disabled={props.pagination.page==(Math.ceil(props.paginationTotal/props.pagination.pageSize))} onClick={()=>{props.setPagination({page: props.pagination.page+1, pageSize: props.pagination.pageSize})}} icon={<RightOutlined/>}/>
                <PaginationButton type='link' disabled={props.pagination.page==(Math.ceil(props.paginationTotal/props.pagination.pageSize))} onClick={()=>{props.setPagination({page: Math.ceil(props.paginationTotal/props.pagination.pageSize-1)+1, pageSize: props.pagination.pageSize})}} icon={<VerticalLeftOutlined/>}/>
            </div>
        </CustomPaginationWrapper>
    );
}

export default CustomPagination;
