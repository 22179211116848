/** @jsx jsx */
// tslint:disable quotemark
import { jsx } from '@emotion/core';

type Props = {
    required?: boolean;
    name: string;
    className?: string;
};

export default function Label({ required = false, name, className = '' }: Props) {
    return (
        <div className={className}>
            {
                required && <span css={{ color: 'red', position: 'relative', top: 3 }}>*</span>
            }
            <span>{name}</span>
        </div>
    );
}
