import React, { useEffect } from 'react';
import { PaginationConfig } from 'antd/es/pagination';
import { SorterResult } from 'antd/es/table/interface';
import { getRangeDate } from 'utils/webHelper';

export type Props = {
    getFieldsValue?: (
        fieldNames?: string[] | undefined,
    ) => {
        [field: string]: any;
    };
    searchFields?: string[];
    dateRangeField?: [string, string];
    dateFormat?: string;
    pageNum?: number;
    pageSize?: number;
    variables?: {
        [key: string]: any;
    };
    transform?: (data: {
        variables: { [key: string]: any };
        formValues: { [key: string]: any };
    }) => {
        [key: string]: any;
    };
    sort?: string;
    desc?: string;
};

const ORDER_TYPES = {
    descend: 'desc',
    ascend: 'asc',
};

export function useFormValues({
    searchFields = [],
    getFieldsValue,
    variables,
    pageNum,
    pageSize,
    transform,
    dateRangeField = ['startDate', 'stopDate'],
    dateFormat,
    sort,
    desc
}: Props) {
    return ({ page = pageNum, limit = pageSize, ...params } = {}) => {
        if (!getFieldsValue) {
            return {
                ...variables,
                page,
            };
        }

        const { date = [], search, ...others } = getFieldsValue();console.log(getFieldsValue())

        const [startDate, stopDate] = getRangeDate(date, dateFormat);
        const [startDateField, stopDateField] = dateRangeField;

        const values = {
            ...variables,
            ...(search ? Object.assign({}, ...searchFields.map(field => ({ [field]: search }))) : {}),
            ...others,
            ...params,
            [startDateField]: startDate,
            [stopDateField]: stopDate,
            page,
            pageSize: limit,
            sort,
            desc
        };

        return transform ? transform({ variables: values, formValues: getFieldsValue() }) : values;
    };
}

export default function useSearch<T = any>({
    refetch,
    json = false,
    ...params
}: Props & {
    json?: boolean;
    refetch?: (
        variables?:
            | {
                  [key: string]: any;
                  id?: number | string;
              }
            | undefined,
    ) => Promise<any>;
}) {
    const [pageNum, setPageNum] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [sorter, setSorter] = React.useState<SorterResult<T>>({});

    const getSortField = ({ field, order }: SorterResult<T>) => {
        return {
            ...(field && order
                ? {
                      [json ? '_sort' : 'sort']: field,
                      [json ? '_order' : 'desc']: json
                          ? order === 'descend'
                          : ORDER_TYPES[order] === 'desc',
                  }
                : {}),
        };
    };

    const getFormValues = useFormValues({ ...params, pageNum, pageSize, ...getSortField(sorter) });

    const handleSearch = () => {
        setPageNum(1);

        if (refetch) {
            refetch(getFormValues({ page: 1, limit: pageSize, ...getSortField(sorter) }));
        }
    };

    const handlePaginationChange = (current: number, size?: number) => {
        setPageNum(current);

        // 如果 size 為 null 調整回 10筆
        setPageSize(size || 10);

        if (refetch) {
            refetch(
                getFormValues({
                    page: current,
                    limit: size,
                    ...getSortField(sorter),
                }),
            );
        }
    };

    const handleTableChange = (
        { current = pageNum }: PaginationConfig,
        _: any,
        { field, order }: SorterResult<T> = sorter,
    ) => {
        setPageNum(current);
        setSorter({ field, order });
        console.log('tc', field, order)
        if (refetch) {
            refetch(
                getFormValues({
                    page: current,
                    limit: pageSize,
                    ...getSortField({ field, order }),
                }),
            );
        }
    };

    return {
        getQueries: getFormValues,
        handleSearch,
        handleTableChange,
        handlePaginationChange,
        pageNum,
        pageSize,
        sorter
    };
}
