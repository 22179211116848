import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import { Checkbox, Row, Col, Modal } from 'antd';
import ImageMapping from 'constants/imageMapping';
import { GlobalContext } from '../../../../../index';
import VideoOption from './VideoOption';
import { getVideoList } from 'api/summary';
import { Video } from '../SummaryEdit';
import VideoCompareDetail from './VideoCompareDetail';
import moment from 'moment';

interface VideoResource {
    packageName: string;
    className: string;
    video: Video[];
}

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        padding-top: 24px;
        padding-bottom: 24px;
        display: flex;

        .left {
            border-radius: 0.5rem;
            background-color: #F3F3F3;
            padding: 20px;
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .trainingItem {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .item {
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 10px;
                    }
                }
                
                .keeogo {
                    color: #0083C1;
                    font-size: 12px;
                }
            }

            .selectedPackage {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .name {
                    font-size: 16px;
                    font-weight: bold;
                }

                .date {
                    color: #909090;
                    font-size: 12px;
                    margin-bottom: 5px;
                }
            }
        }

        .right {
            overflow-y: scroll;
            padding: 0 15px;
            width: 60%;
            height: 100%;

            .videoOptions {
                overflow-y: scroll;
                width: 100%;
                display: flex;
                flex-direction: column;

                &::-webkit-scrollbar {
                    width: 0;
                }
            }

            &::-webkit-scrollbar {
                width: 0;
            }
        }
    }
`;

const CheckboxContainer = styled(Checkbox.Group)`
    display: flex;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        height: 0;
    }

    .ant-checkbox-wrapper {
        margin: 12px 3px;

        .ant-checkbox {
            display: none;
        }

        span {
            padding: 10px 15px;
            background-color: #F3F3F3;
            color: #000000;
            border-radius: 2rem;
            font-weight: normal;
            white-space: nowrap;
        }

        &.ant-checkbox-wrapper-checked {
            span {
                color: #FFFFFF;
                background-color: #2E63A3;
            }
        }
    }
`;

interface Package {
    id: number;
    name: string;
}

interface VideoDrawerProps {
    onClose: () => void;
    trainingType: string;
    packages: Package[];
    selectedVideo: [string | undefined, string | undefined];
    withKeeogo?: boolean;
    setVideo: (videos: [Video | undefined, Video | undefined]) => void;
}

const VideoCompareDrawer: React.FC<VideoDrawerProps> = (props: VideoDrawerProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const [videoResources, setVideoResources] = useState<VideoResource[]>([]);
    const [selectedVideo, setSelectedVideo] = useState<[string | undefined, string | undefined]>(props.selectedVideo);
    const [selectedPackage, setSelectedPackage] = useState<number[]>(props.packages.map(d=>d.id)); // default select all
    const [detailModalSetting, setDetailModalSetting] = useState({
        show: false,
        id: ''
    });

    useEffect(()=>{
        getVideoList({
            selectedPackages: selectedPackage,
            trainingTypeCode: props.trainingType,
            classification: props.withKeeogo ? 'W/ Keeogo' : 'W/O Keeogo'
        }).then(res=>{
            const videos = res.data.data.map(packageVideo => ({
                packageName: packageVideo.package,
                className: packageVideo.class,
                video: packageVideo.video.filter(file=>file.filePath).map(file => ({
                    fileId: file.id,
                    id: `${packageVideo.package}-${packageVideo.class}-${file.fileKey}`,
                    sagittalView: file.videoDirection === 'sagittalView' ? file.filePath : '',
                    frontalView: file.videoDirection === 'frontalView' ? file.filePath : '',
                    sagittalThumbnail: file.videoDirection === 'sagittalView' ? file.thumbnail : '',
                    frontalThumbnail: file.videoDirection === 'frontalView' ? file.thumbnail : '',
                    uploadView: file.upload ? file.filePath : '',
                    uploadThumbnail: file.upload ? file.thumbnail : '',
                    memo: '',
                    timestamp: moment(file.createdAt),
                    packageName: packageVideo.package,
                    className: packageVideo.class,
                    upload: file.upload
                }))
            }));
            setVideoResources(videos);
        }).catch(err=>{
            console.error(err)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        })
    }, [selectedPackage]);

    const handleSave = () => {
        const allVideos = videoResources.map(f=>f.video).flat();
        props.setVideo([
            allVideos.find(v=>v.id===selectedVideo[0]),
            allVideos.find(v=>v.id===selectedVideo[1])
        ]);
        props.onClose();
    }

    const handleSelectVideo = (id: string) => {
        if(selectedVideo.some(d=>d===id)){
            return;
        }
        
        let emptyIndex = selectedVideo.indexOf(undefined);
        if(emptyIndex >= 0){
            setSelectedVideo(
                emptyIndex > 0 ? [selectedVideo[0], id] : [id, selectedVideo[1]]
            );
        }
    }

    const handleRemoveVideo = (id: string) => {
        let videoToRemove = selectedVideo.indexOf(id);
        if(videoToRemove >= 0){
            setSelectedVideo(
                videoToRemove > 0 ? [selectedVideo[0], undefined] : [undefined, selectedVideo[1]]
            );
        }
    }

    const options = props.packages.map(d=>(
        <Checkbox value={d.id}>{d.name}</Checkbox>
    ));

    const chosenVideos = selectedVideo.map(vId=>{
        const matchVideo = videoResources.map(f=>f.video).flat().find(v=>v.id===vId);
        return (
            <div className='selectedPackage'>
                <div className='name'>
                    {`
                        ${matchVideo?.packageName ?? ''} - 
                        ${matchVideo?.className ?? ''}
                    `}
                </div>
                <div className='date'>{matchVideo?.timestamp.format('DD/MM/YYYY') ?? '--'}</div>
                <Col className='width--full'>
                    {vId ? <VideoOption
                        removable
                        onRemove={()=>handleRemoveVideo(vId)}
                        leftView={matchVideo?.upload ? undefined : (matchVideo?.frontalThumbnail ?? '')}
                        rightView={matchVideo?.upload ? undefined : (matchVideo?.sagittalThumbnail ?? '')}
                        singleView={matchVideo?.upload ? matchVideo.uploadThumbnail : undefined}
                        duration
                        videoUrl={matchVideo?.upload ? matchVideo.uploadView : matchVideo?.frontalView}
                    /> : <div style={{backgroundColor: '#000000', width: '100%', aspectRatio: '1.8', borderRadius: '0.3rem'}}></div>}
                </Col>
            </div>
        )
    });

    const videoOptions = videoResources.map(d=>(
        <div className='width--full mb-20'>
            <div className='d-flex is--space-between v--center'>
                <div className='text-18 text--bold'>{`${d.packageName} - ${d.className}`}</div>
            </div>
            <div>
                <Row gutter={24} className='ml-0 mr-0'>
                    {d.video.map(v=>(
                        <Col span={12} className='mb-20'>
                            <VideoOption
                                onClick={(selectedVideo.filter(f=>f).length >= 2 || selectedVideo.some(s=>s===v.id)) ? ()=>handleRemoveVideo(v.id) : ()=>handleSelectVideo(v.id)}
                                expandable
                                onExpand={()=>setDetailModalSetting({show: true, id: v.id})}
                                leftView={v.upload ? undefined : v.frontalThumbnail}
                                rightView={v.upload ? undefined : v.sagittalThumbnail}
                                singleView={v.upload ? v.uploadThumbnail : undefined}
                                duration
                                videoUrl={v.upload ? v.uploadView : v.frontalView}
                                iconOffset='2%'
                                timestampOffset='3%'
                                style={{
                                    border: selectedVideo.some(s=>s===v.id) ? '3px #47A6D8 solid' : 'none',
                                    pointerEvents: (selectedVideo.filter(f=>f).length >= 2 && !selectedVideo.some(s=>s===v.id)) ? 'none' : undefined,
                                    opacity: (selectedVideo.filter(f=>f).length >= 2 && !selectedVideo.some(s=>s===v.id)) ? 0.4 : undefined
                                }}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    ));

    return (
        <StyledDrawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='60vw'
            title={formatMessage({id:'SELECT_PACKAGE'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect" onClick={handleSave}>
                        {formatMessage({id:'SAVE'})}
                    </Button>
                </div>
            }
        >
            {detailModalSetting.show && 
            <VideoCompareDetail 
                video={videoResources.map(d=>d.video).flat().filter(d=>d.id===detailModalSetting.id)[0]} 
                selected={selectedVideo.some(d=>d===detailModalSetting.id)} 
                onSelect={selectedVideo.some(d=>d===detailModalSetting.id) ? id=>handleRemoveVideo(id) : id=>handleSelectVideo(id)}
                onClose={()=>setDetailModalSetting({show: false, id: ''})}
            />}

            <div className='left'>
                <div className='trainingItem'>
                    <div className='item'>
                        <img src={ImageMapping.TrainingItem[props.trainingType]}/>
                        <div>{nameMapping[props.trainingType]}</div>
                    </div>
                    <div className='keeogo'>
                        ({formatMessage({id: props.withKeeogo ? 'WITH_KEEOGO' : 'WITHOUT_KEEOGO'})})
                    </div>
                </div>
                {chosenVideos}
            </div>
            <div className='right'>
                <CheckboxContainer value={selectedPackage} onChange={(val: any[])=>{if(val.length>0)setSelectedPackage(val);}}>
                    {options}
                </CheckboxContainer>
                <div className='videoOptions'>
                    {videoOptions}
                </div>
            </div>
        </StyledDrawer>
    )
}

export default VideoCompareDrawer;
