import React, { useState, useEffect } from 'react';
import { LeftOutlined, SearchOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { PieChart } from 'components/component';
import { Select } from 'components/form';
import Button from 'components/button/button';
import { cityList as twCity } from './taiwanCtiy';
import styled from 'styled-components';
import * as gmap from '../../map/gMap';
import { getCaseType } from '../../api/caseTypes';
import { apiCaller } from '../../service/apiCaller';
import { location } from '../../components/image';
import { useHistory } from 'react-router-dom'
import { find, propEq, path } from 'ramda';

export const GeoMap: React.FC<any> = (props: any) => {
    const history = useHistory()

    const mapInfo = async () => {
        gmap.initWnd();
    };
    useEffect(() => {
        mapInfo();
    }, []);

    return (
        <div style={{position: 'relative', width: 'calc(100vw - 120px)', height: 'calc(100vh - 50px)'}}>
            <div style={{position: 'absolute', width: '100%', height: '100%'}} id='TGMap' />
            <RetrunIcon style={{color: '#fff'}} onClick={() => history.push('/caseMgmt')}/>
            <MapTool />
            <Tool />
        </div>
    );
};

const RetrunIcon = styled(LeftOutlined)`
    position: absolute;
    left: 0px;
    top: 20px;
    padding: 0 8px;
    background-color: rgba(13, 165, 171, .6);
    border-radius: 0 4px 4px 0;
    line-height: 30px;
    cursor: pointer;
`;

const Tool = (props: any) => {
    const [cityList, setCityList] = useState<any>([]);
    const [caseTypeList, setCaseTypeList] = useState<any>([]);
    const [caseStatusList, setCaseStatusList] = useState<any>([]);
    const [setting, setSetting] = useState<any>({
        country: {value: 'TW', label: '台灣'},
        city: {value: 'All'},
        caseType: {value: 'All'},
        caseStatus: {value: 'All'},
        chartTitle: '',
    });

    useEffect(() => {
        getCaseTypeList();
        changeCountry(setting.country);
        getTitle();
    }, []);

    const getCaseTypeList = () => {
        const success = (res: any) => {
            const { status, data: { data } } = res;
            if (status === 200) {
                const typeList = data.filter((item: any) => item.active && (item.level === 'A' || item.level === localStorage.getItem('managingOrganizationType')))
                    .map((item: any) => {
                        return {
                            id: item.id,
                            name: item.name,
                            statusList: item.status.filter((sItem: any) => sItem.active).map((sItem: any) => {
                                return {
                                    id: sItem.id,
                                    name: sItem.name
                                }
                            })
                        }
                    });
                setCaseTypeList(typeList);
            }
        };
        const reject = (error: any) => {
            const { status, data } = error;
        };
        apiCaller(getCaseType, null, success, reject);
    }

    const changeCountry = (e: any) => {
        if (e.value === 'TW') {
            setCityList(twCity);
        } else {
            setCityList([]);
        }
        setSetting({...setting, country: e, city: {value: 'All'}});
    }

    const changeCaseType = (e: any) => {
        setCaseStatusList(path(['statusList'], find(propEq('id', e.value))(caseTypeList)) || []);
        setSetting({...setting, caseType: e, caseStatus: {value: 'All'}});
    }

    const getInfo = () => {
        const { country, city } = setting;
        getTitle();
        gmap.drawMap(city.value === 'All' ? 0 : 1, country.value, city.value);
    }

    const getTitle = () => {
        const { country, city, caseType, caseStatus } = setting;
        let _title = '個案';
        if (city.value === 'All' && caseType.value === 'All' && caseStatus.value === 'All') {
            _title = `<span class='text--cyan-2'>${country.label}</span>全部收案別分佈`;
        } else if (city.value !== 'All' && caseType.value === 'All' && caseStatus.value === 'All') {
            _title = `<span class='text--cyan-2'>${city.label}</span>全部收案別分佈`;
        } else if (city.value !== 'All' && caseType.value !== 'All' && caseStatus.value === 'All') {
            _title = `<span class='text--cyan-2'>${caseType.label}</span>全部狀態個案`;
        } else if (city.value !== 'All' && caseType.value !== 'All' && caseStatus.value !== 'All') {
            _title = `<span class='text--cyan-2'>${caseType.label}${caseStatus.label}</span>個案`;
        } else if (city.value === 'All' && caseType.value !== 'All' && caseStatus.value === 'All') {
            _title = `<span class='text--cyan-2'>${country.label}${caseType.label}</span>全部狀態個案`;
        } else if (city.value === 'All' && caseType.value !== 'All' && caseStatus.value !== 'All') {
            _title = `<span class='text--cyan-2'>${country.label}${caseType.label}${caseStatus.label}</span>個案`;
        }
        setSetting({...setting, chartTitle: _title});
    }

    return (
        <div style={{position: 'absolute', width: '300px', right: '36px', top: '0'}}>
            <div className='bg--white padding-24' style={{height: '320px', borderRadius: '0 0 8px 8px', boxShadow: '0px 3px 6px #00000029'}} >
                <div className='text--cyan-2 text--xl'>
                    個案GIS地圖
                </div>
                <Row align='middle' justify='start' className='mt-16'>
                    <div className='text--sm' style={{width: '70px'}}>
                        國別
                    </div>
                    <Select bordered={false} labelInValue={true} value={setting.country} placeholder='請選擇國別' className='text--m' style={{width: '182px'}} onChange={changeCountry}>
                        <Select.Option key={'TW'} value='TW'>台灣</Select.Option>
                        <Select.Option key={'BR'} value='BR'>巴西</Select.Option>
                    </Select>
                </Row>
                <Row align='middle' justify='start' className='mt-16'>
                    <div className='text--sm' style={{width: '70px'}}>
                        區域
                    </div>
                    <Select bordered={false} labelInValue={true} value={setting.city} placeholder='請選擇區域' className='text--m' style={{width: '182px'}} onChange={(e) => setSetting({...setting, city: e})}>
                        <Select.Option key={'All'} value={'All'}>全部區域縣市</Select.Option>
                        {
                            cityList.map((item: any, i: number) => (
                                <Select.Option key={i} value={item.id}>{item.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Row>
                <Row align='middle' justify='start' className='mt-16'>
                    <div className='text--sm' style={{width: '70px'}}>
                        收案別
                    </div>
                    <Select bordered={false} labelInValue={true} value={setting.caseType} placeholder='請選擇收案別' className='text--m' style={{width: '182px'}} onChange={changeCaseType}>
                        <Select.Option key={'All'} value={'All'}>全部收案別</Select.Option>
                        {
                            caseTypeList.map((item: any, i: number) => (
                                <Select.Option key={i} value={item.id}>{item.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Row>
                <Row align='middle' justify='start' className='mt-16'>
                    <div className='text--sm' style={{width: '70px'}}>
                        收案狀態
                    </div>
                    <Select bordered={false} labelInValue={true} value={setting.caseStatus} placeholder='請選擇收案狀態' className='text--m' style={{width: '182px'}} onChange={(e) => setSetting({...setting, caseStatus: e})}>
                        <Select.Option key={'All'} value={'All'}>全部收案狀態</Select.Option>
                        {
                            caseStatusList.map((item: any, i: number) => (
                                <Select.Option key={i} value={item.id}>{item.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Row>
                <Row align='middle' justify='end' className='mt-16'>
                    <Button icon={<SearchOutlined />} className='is--primary' onClick={getInfo}>
                        查詢
                    </Button>
                </Row>
            </div>
            <div className='bg--white mt-36 padding-24' style={{height: 'calc(100vh - 406px)', borderRadius: '8px 8px 0 0', boxShadow: '0px 3px 6px #00000029'}}>
                <div dangerouslySetInnerHTML={{__html: setting.chartTitle}} className='text--grey-1 text--xl' />
                <PieChart />
            </div>
        </div>
    );
}

const MapTool = (props: any) => {
    return (
        <div style={{position: 'absolute', left: '0px', top: '70px', borderRadius: '0 4px 4px 0', width: '30px', height: '90px', cursor: 'pointer'}}>
            <ToolBtn justify='center' align='middle' onClick={() => gmap.zoomInHandler()} style={{borderRadius: '0 4px 0 0'}}>
                <PlusOutlined/>
            </ToolBtn>
            <ToolBtn justify='center' align='middle' onClick={() => gmap.zoomOutHandler()}>
                <MinusOutlined />
            </ToolBtn>
            <ToolBtn justify='center' align='middle' onClick={() => gmap.reSetCenter()} style={{borderRadius: '0 0 4px 0'}}>
                <img src={location} />
            </ToolBtn>
        </div>
    )
}

const ToolBtn = styled(Row)`
    color: #fff;
    background-color: rgba(0, 0, 0, .4);
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #ccc;
    &:last-child {
        border: none;
    }
`;