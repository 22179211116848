import React from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

const StyledRangePicker = styled(({ ...props }) => (
    <RangePicker {...props} />
))`
    &.ant-picker {
        position: relative;
        bottom: 1px;
        border-bottom: 1px solid #d9d9d9 !important;

        &.ant-picker-borderless{
            background: ${props => props.disabled ? '#F2F2F2 !important' : ''};
        }
    }
`;

export default StyledRangePicker;
