import React, { useState, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Modal, Radio, Checkbox } from 'antd';
import Button from 'components/button/button';
import { Input } from 'components/form';
import OptionButton from 'components/optionButton/optionButton';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    SortEndHandler
} from 'react-sortable-hoc';
import { GlobalContext } from '../../../../../index';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { icon_drag_handle } from 'components/image';

const Wrapper = styled.div`
    width: 100%;

    .header{
        width: 100%;
        display: flex;

        .left, .right {
            width: calc((100% - 60px) / 2);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 5px;
            color: #0083C1;
            font-size: 16px;
            font-weight: bold;
        }
    
        .add {
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export interface Option {
    id: number;
    prioritizedDeficitsCode: string;
    prioritizedDeficitsDetails: string;
    deficitsResolvedCode: string;
    deficitsResolvedNotes: string;
}

interface Props {
    value?: Option[];
    onChange?: (val: Option[]) =>void;
}

const PrioritizedDeficits: React.FC<Props> = (props: Props) => {
    
    const { formatMessage } = useIntl();
    const [showModal, setShowModal] = useState(false);
    const [options, setOptions] = useState<Option[]>(props.value || []);

    useEffect(()=>{
        setOptions(props.value || []);
    }, [props.value]);

    const handleValueChange = (val: Option) => {
        const newOptions = options.slice();
        const index = newOptions.indexOf(newOptions.find(d=>d.id === val.id) ?? {id: 0, prioritizedDeficitsCode: '',prioritizedDeficitsDetails: '', deficitsResolvedCode: '', deficitsResolvedNotes: ''});
        newOptions[index] = Object.assign({}, val);
        setOptions(newOptions);
        props.onChange?.(newOptions)
    }

    const handleOrderChange: SortEndHandler = (result, e) => {
        const { oldIndex, newIndex } = result;
        const newOrder = options.slice();
        newOrder.splice(newIndex < 0 ? newOrder.length + newIndex : newIndex, 0, newOrder.splice(oldIndex, 1)[0]);
        setOptions(newOrder);
        props.onChange?.(newOrder)
    }

    const Container = SortableContainer(()=>(
        <List
            listData={options}
            handleValueChange={handleValueChange}
        />
    ));

    return (
        <Wrapper>
            {showModal && <AddOptionModal onClose={()=>setShowModal(false)} data={options} onSave={val=>{setOptions(val);props.onChange?.(val)}} />}

            <div className="header">
                <div className="left">
                    {formatMessage({id:'PRIORITIZED_DEFICITS'})}
                    <div style={{width: '80%', borderBottom: '2px solid #E3E3E3'}}></div>
                </div>
                <div className="right">
                    {formatMessage({id:'DEFICITS_RESOLVED'})}
                    <div style={{width: '80%', borderBottom: '2px solid #E3E3E3'}}></div>
                </div>
                <div className="add">
                    <Button
                        icon={<PlusOutlined style={{fontSize: '22px', color: '#FFFFFF'}}/>}
                        onClick={()=>setShowModal(true)}
                        style={{borderRadius: '3rem', backgroundColor: '#0083C1', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none'}}
                    />
                </div>
            </div>
            <div className="content">
                <Container onSortEnd={handleOrderChange} useDragHandle />
            </div>
            
        </Wrapper>
    )
}

export default PrioritizedDeficits;

interface ListProps {
    listData: Option[];
    handleValueChange: (val: Option) =>void;
}

const List: React.FC<ListProps> = (props: ListProps) => {
    
    const Element = SortableElement<{data: Option}>((elementProps: {data: Option})=>(
        <DraggabledElement data={elementProps.data} onValueChange={val=>props.handleValueChange(val)}/>
    ))
    
    const elements = props.listData.map((d, i)=>(
        <Element data={d} index={i}/>
    ))

    return (
        <ul>
            {elements}
        </ul>
    )
}

const DraggableCard = styled.div`
    background-color: #F3F3F3;
    display: flex;
    margin: 10px 0;
    height: 90px;
    z-index: 1000;

    .left {
        width: calc((100% - 60px) / 2);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px;

        .name {
            font-weight: bold;
            font-size: 18px;
        }

        .desc {
            border: none;
            width: 80%;
        }
    }

    .right {
        width: calc((100% - 60px) / 2);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px;

        .note {
            border: none;
            width: 80%;
        }
    }

    .drag {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: grab;

        .icon {
            width: 40px;
            height: 40px;
        }
    }
`;

const RadioContainer = styled(Radio.Group)`
    &>* {
        border: none!important;
        font-weight: bold;
    }

    .ant-radio-button-wrapper {
        background-color: #F3F3F3;

        &.ant-radio-button-wrapper-checked {
            background-color: #2E63A3!important;
            color: #FFFFFF;

            &::before {
                display: none;
            }

            &:hover {
                color: #FFFFFF;
            }
        }

        &:hover {
            color: rgba(0, 0, 0, 0.65);
        }
    }
`;

interface DraggabledElementProps {
    data: Option;
    onValueChange: (val: Option) => void;
}

const DraggabledElement: React.FC<DraggabledElementProps> = (props: DraggabledElementProps) => {

    const { getOptions, nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const [inputString, setInputString] = useState(props.data.prioritizedDeficitsDetails);
    const [resolvedInputString, setResolvedInputString] = useState(props.data.deficitsResolvedNotes);
    const [resolved, setResolved] = useState(props.data.deficitsResolvedCode);

    const handleResolvedChange = (val: string) => {
        setResolved(val);
        props.onValueChange({...props.data, deficitsResolvedCode: val});
    }

    const radioOption = getOptions('DeficitsResolved').map((d: any)=>(
        <Radio.Button value={d.code}>{d.name}</Radio.Button>
    ))

    const DragHandle = SortableHandle(()=>(
        <img src={icon_drag_handle} width='100%' height='100%'/>
    ));
    
    return (
        <DraggableCard>
            <div className="left">
                <div className="name">
                    {nameMapping[props.data.prioritizedDeficitsCode]}
                </div>
                <Input maxLength={150} className='desc' placeholder='Details' value={inputString} onChange={e=>setInputString(e.target.value)} onBlur={()=>props.onValueChange({...props.data, prioritizedDeficitsDetails: inputString})}/>
            </div>
            <div className="right">
                <RadioContainer value={resolved} onChange={e=>handleResolvedChange(e.target.value)}>
                    {radioOption}
                </RadioContainer>
                <Input maxLength={150} className='note' placeholder='Details' value={resolvedInputString} onChange={e=>setResolvedInputString(e.target.value)} onBlur={()=>props.onValueChange({...props.data, deficitsResolvedNotes: resolvedInputString})}/>
            </div>
            <div className="drag">
                <div className="icon">
                    <DragHandle/>
                </div>
            </div>
        </DraggableCard>
    )
}

const CheckboxContainer = styled(Checkbox.Group)`
    display: block;

    .ant-checkbox-wrapper {
        margin: 12px 3px;

        .ant-checkbox {
            display: none;
        }

        span {
            padding: 10px;
            background-color: #F3F3F3;
            color: #000000;
            border-radius: 0.3rem;
            font-weight: normal;
            white-space: nowrap;
        }

        &.ant-checkbox-wrapper-checked {
            span {
                color: #FFFFFF;
                background-color: #2E63A3;
            }
        }
    }
`;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 0.4rem;

        .ant-modal-close {
            .ant-modal-close-x {
                .anticon-close {
                    font-size: 24px;
                    color: #000000;
                }
            }
        }

        .ant-modal-header {
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            border-radius: 0.4rem 0.4rem 0 0;
    
            .ant-modal-title {
                font-size: 22px;
                color: #2E63A3;
                font-weight: bold;
            }
        }

        .ant-modal-footer {
            border: none;
        }
    }
`;

interface ModalProps {
    onClose: () => void;
    onSave: (options: Option[]) => void;
    data: Option[];
}

const AddOptionModal: React.FC<ModalProps> = (props: ModalProps) => {

    const { getOptions, nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const { formatMessage } = useIntl();
    const [selectedOption, setSelectedOption] = useState<Option[]>(props.data);
    const [checkedValue, setCheckedValue] = useState<string[]>(props.data.map(d=>d.prioritizedDeficitsCode));

    const addOption = (newOption: string) => {
        if(selectedOption.length>=5){
            return;
        }

        let maxId = selectedOption.length;

        for(let i=0; i<selectedOption.length; ++i){
            if(selectedOption[i].id >= maxId){
                maxId = selectedOption[i].id + 1;
            }
        }

        setSelectedOption(
            selectedOption.concat([{
                id: maxId,
                prioritizedDeficitsCode: newOption,
                prioritizedDeficitsDetails: '',
                deficitsResolvedCode: 'DER01',
                deficitsResolvedNotes: ''
            }])
        );
    }

    const removeOption = (id: number) => {
        const optionToRemove = selectedOption.find(d=>d.id === id) ?? {id: 0, prioritizedDeficitsCode: '',prioritizedDeficitsDetails: '', deficitsResolvedCode: '', deficitsResolvedNotes: ''};

        const newOption = selectedOption.slice();
        const indexToRemove = newOption.indexOf(optionToRemove);

        if(indexToRemove >= 0){
            newOption.splice(indexToRemove, 1);
            setSelectedOption(newOption);
        }

        const newChecked = checkedValue.slice();
        const indexToRemoveChecked = newChecked.indexOf(optionToRemove.prioritizedDeficitsCode);
        if(indexToRemoveChecked >= 0){
            newChecked.splice(indexToRemoveChecked, 1);
            setCheckedValue(newChecked);
        }
    }
    
    const options = getOptions('Deficits').filter((d: any)=>d.code!=='DEF12').map((d: any)=>(
        <Checkbox 
            value={d.code} 
            onClick={checkedValue.indexOf(d.code)>=0 ? 
                ()=>removeOption(selectedOption.find(n=>n.prioritizedDeficitsCode===d.code)?.id??0) : 
                ()=>addOption(d.code) 
            }
        >{d.name}</Checkbox>
    ));
    
    const otherOptionName = nameMapping['DEF12'];
    options.push(
        <Button
            icon={<PlusOutlined style={{color: '#000000', fontSize: '18px'}}/>}
            onClick={()=>addOption('DEF12')}
            style={{
                borderRadius: '0.2rem',
                backgroundColor: '#F3F3F3',
                border: 'none',
                height: '40px',
                color: '#000000',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            {otherOptionName}
        </Button>
    )

    return (
        <StyledModal
            visible
            closable
            width='760px'
            title='Select Deficits'
            onCancel={props.onClose}
            footer={
                <div>
                    <Button
                        className='rect'
                        onClick={()=>{props.onSave(selectedOption);props.onClose();}}
                        size='large'
                        style={{
                            backgroundColor: '#2E63A3',
                            fontWeight: 'bold',
                            color: '#FFFFFF',
                            border: 'none'
                        }}
                    >{formatMessage({id:'ADD'})}</Button>
                </div>
            }
        >
            <div className="options">
                <CheckboxContainer value={checkedValue} onChange={(val: any[])=>{if(selectedOption.length<5){setCheckedValue(val);}}}>
                    {options}
                </CheckboxContainer>
            </div>
            <div className='mt-15 mb-15' style={{borderBottom: '2px solid #E3E3E3'}}/>
            <div>
                {selectedOption.map(d=>(
                    <OptionButton onRemove={removeOption} id={d.id} name={nameMapping[d.prioritizedDeficitsCode]}/>
                ))}
            </div>
        </StyledModal>
    )
}
