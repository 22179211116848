import React, { useEffect, useState, useContext } from 'react';
import Drawer from 'components/drawer/drawer';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import { Form, Input, Select, TextArea } from 'components/form';
import { Row, Col, Modal } from 'antd';
import { keeogo_diagram_wide, icon_info_black } from 'components/image';
import ModifiedRecord from '../ModifiedRecord';
import { editCaseProfile } from 'api/case';
import { apiCaller } from 'service/apiCaller';
import { routeMatch } from 'utils/webHelper';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../../index';
import BeltSizeModal from './BeltSizeModal';

interface Props {
    refetch: Function;
    data: any;
    onClose: Function;
    value?: PriceValue;
    onChange?: (value: PriceValue) => void;
}
interface PriceValue {
    number?: number;
  }

const KeeogoDrawer: React.FC<Props> = (props: Props) => {

    const { id } = routeMatch('/Patient/:id', useLocation().pathname);
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const { optionType, selectOption, getCode, getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const modifiedHistory = props.data.recordRemark?.sort((a: any,b: any)=>b.updatedAt-a.updatedAt).map((record: any)=><ModifiedRecord updatedAt={record.updatedAt} editorName={record.createName} content={record.recordRemark}/>) || [];

    const[heightNum,setHeightNum]=useState<string>("");
    const[weightNum,setWeightNum]=useState<string>("");
    const[femoralLengthNum,setFemoralLengthNum]=useState<string>("");
    const[tibialLengthNum,setTibialLengthNum]=useState<string>("");

    const [showBeltSizeModal, setShowBeltSizeModal] = useState(false);

    var reg = /^(([0-9]{1,3})|(([0-9]{1,3}\.[0-9]{0,2})))$/;

    const MSoptions = ['M', 'S'].map((d, i)=>(
        <Select.Option key={i} value={d}>{d}</Select.Option>
    ));

    const shaftLengthOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8].map((d, i)=>(
        <Select.Option key={i} value={d.toString()}>{d.toString()}</Select.Option>
    ));

    useEffect(()=>{
        form.setFieldsValue({
            waistBeltCode: getCode('WaistBelt', props.data.waistBeltCode) || '',
            height: props.data.height,
            weight: props.data.weight,
            femoralLength: props.data.femoralLength,
            tibialLength: props.data.tibialLength,
            memo: props.data.memo,
            keeogoContactAreaSizeUpperThigh: props.data.keeogoContactAreaSizeUpperThigh,
            keeogoContactAreaSizeLowerThigh: props.data.keeogoContactAreaSizeLowerThigh,
            keeogoContactAreaSizeTibia: props.data.keeogoContactAreaSizeTibia,
            keeogoFemoralShaftLengthLt: props.data.keeogoFemoralShaftLengthLt,
            keeogoFemoralShaftLengthRt: props.data.keeogoFemoralShaftLengthRt
        });
    }, []);

    const handleOk = () => {
        form.validateFields().then(values=>{
            const success = (res: any) => {
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel: ()=>{
                        props.refetch();
                        props.onClose();
                    }
                })
            };
            const reject = (err: any) => {
                console.error(err)
                Modal.error({
                    maskClosable:true,
                    title:'Error',
                    content: <div style={{textAlign:'center'}}> 
                        {
                            (()=>{
                                const msg = err?.data?.detail
                                if(typeof msg === 'string'){
                                    return msg
                                }else if(msg instanceof Array){
                                    return msg.join('\n')
                                }else{
                                    return JSON.stringify(msg)
                                }
                            })()   
                        }
                    </div> 
                })
            };
            apiCaller(
                editCaseProfile,
                {
                    userId: id,
                    device: 'web',
                    ...values,
                    waistBeltCode: values.waistBeltCode ?? '',
                    height: (values.height==="")?undefined:values.height,
                    weight: (values.weight==="")?undefined:values.weight,
                    femoralLength:(values.femoralLength==="")?undefined:values.femoralLength,
                    tibialLength: (values.tibialLength==="")?undefined:values.tibialLength,
                    keeogoContactAreaSizeUpperThigh: values.keeogoContactAreaSizeUpperThigh ?? '',
                    keeogoContactAreaSizeLowerThigh: values.keeogoContactAreaSizeLowerThigh ?? '',
                    keeogoContactAreaSizeTibia: values.keeogoContactAreaSizeTibia ?? '',
                    keeogoFemoralShaftLengthLt: values.keeogoFemoralShaftLengthLt ?? '',
                    keeogoFemoralShaftLengthRt: values.keeogoFemoralShaftLengthRt ?? ''
                },
                success,
                reject
            )
        });
    }
    return (
        <Drawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='60vw'
            title={formatMessage({id:'EDIT'})}
            footer={
                <div className="pull--right rect">
                    <Button className="mr-20 rect" onClick={props.onClose as any}>
                        {formatMessage({id:'CANCEL'})}
                    </Button>
                    <Button className="is--primary rect" onClick={handleOk}>
                        {formatMessage({id:'SAVE'})}
                    </Button>
                </div>
            }
        >
            {showBeltSizeModal && <BeltSizeModal onClose={()=>setShowBeltSizeModal(false)}/>}

            <Form form={form} layout='vertical' 
                onValuesChange={( changedValues,allValues)=>{
                  
                let heightInpute = allValues.height
                let weightInpute = allValues.weight
                let femoralLengthInpute = allValues.femoralLength
                let tibialLengthInpute = allValues.tibialLength
                //height
                if(heightInpute === ""){
                    setHeightNum(heightInpute)
                    form.setFieldsValue({ height:""})
                }else if(!reg.test(heightInpute)){
                    form.setFieldsValue({height:heightNum})
                }else if(reg.test(heightInpute)){
                    form.setFieldsValue({ height:allValues.height })
                    setHeightNum(heightInpute)
                }

                //weight
                if(weightInpute === ""){
                    setWeightNum(weightInpute)
                    form.setFieldsValue({weight:""})
                  
                }else if(!reg.test(weightInpute)){
                    form.setFieldsValue({ weight:weightNum})
                    
                }else{
                    form.setFieldsValue({ weight:allValues.weight})
                    setWeightNum(weightInpute)

                }
               
                //femoralLength
                if(femoralLengthInpute === ""){
                    form.setFieldsValue({ femoralLength:"" })
                    setFemoralLengthNum(femoralLengthInpute)

                }else if(!reg.test(femoralLengthInpute)){
                    form.setFieldsValue({femoralLength:femoralLengthNum})

                }else{
                    form.setFieldsValue({femoralLength:allValues.femoralLength})
                    setFemoralLengthNum(femoralLengthInpute)
                }

                //tibialLength

                if(tibialLengthInpute === ""){
                    form.setFieldsValue({tibialLength:""})
                    setTibialLengthNum(tibialLengthInpute)

                }else if(!reg.test(tibialLengthInpute)){
                    form.setFieldsValue({tibialLength:tibialLengthNum})

                }else{
                    form.setFieldsValue({ tibialLength:allValues.tibialLength })
                    setTibialLengthNum(tibialLengthInpute)
                }
            }}
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item 
                            label={
                                <div>
                                    {formatMessage({id:'WAIST_BELT'})}
                                    <Button icon={<img src={icon_info_black}/>} onClick={()=>setShowBeltSizeModal(true)} style={{border:'none'}}/>
                                </div>
                            } 
                            name='waistBeltCode'
                        >
                            <Select
                                allowClear={true} 
                                bordered={false}
                            >
                                {selectOption[optionType.indexOf('WaistBelt')]}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={formatMessage({id:'HEIGHT_CM'})} name='height' 
                         rules={[
                            {
                                required: true,
                                message: formatMessage({id:'INPUT_HEIGHT'}),
                            },
                        ]}>
                            <Input bordered={false} maxLength={6}  />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={formatMessage({id:'WEIGHT_KG'})} name='weight'rules={[
                            {
                                required: true,
                                message: formatMessage({id:'INPUT_WEIGHT'}),
                            },
                        ]}>
                            <Input bordered={false} maxLength={6}  />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={formatMessage({id:'FEMORAL_LENGTH'})} name='femoralLength'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({id:'INPUT_FEMORAL_LENGTH'}),
                            },
                        ]}>
                            <Input bordered={false} maxLength={6} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={formatMessage({id:'TIBIAL_LENGTH'})} name='tibialLength'
                        rules={[
                            {
                                required: true,
                                message: formatMessage({id:'INPUT_TIBIAL_LENGTH'}),
                            },
                        ]}>
                            <Input bordered={false} maxLength={6} />
                        </Form.Item>
                    </Col>
                </Row>
                <div className='text--bold text-16 mb-10'>{formatMessage({id:'CONTACT_AREA_SIZE'})}</div>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item label={formatMessage({id:'UPPER_THIGH'})} name='keeogoContactAreaSizeUpperThigh'>
                            <Select allowClear={true}  bordered={false} >
                                {MSoptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={formatMessage({id:'LOWER_THIGH'})} name='keeogoContactAreaSizeLowerThigh'>
                            <Select allowClear={true}  bordered={false} >
                                {MSoptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={formatMessage({id:'TIBIA'})} name='keeogoContactAreaSizeTibia'>
                            <Select allowClear={true}  bordered={false} >
                                {MSoptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div className='text--bold text-16 mb-10'>{formatMessage({id:'FEMORAL_SHAFT_LENGTH'})}</div>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={<div className='text--bold text-20' style={{color: '#0083C1'}}>{formatMessage({id:'LT'})}</div>} name='keeogoFemoralShaftLengthLt'>
                            <Select allowClear={true}  bordered={false} >
                                {shaftLengthOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={<div className='text--bold text-20' style={{color: '#0083C1'}}>{formatMessage({id:'RT'})}</div>} name='keeogoFemoralShaftLengthRt'>
                            <Select allowClear={true}  bordered={false} >
                                {shaftLengthOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <img src={keeogo_diagram_wide} style={{marginBottom: '10px'}} />
                <Form.Item label={formatMessage({id:'PROFILE_REMARK'})} name='memo'>
                    <TextArea rows={4}/>
                </Form.Item>
                <Form.Item label={formatMessage({id:'MODIFIED_REMARK'})} name='modifiedRemark'>
                    <TextArea rows={4}/>
                </Form.Item>
                <Form.Item label={formatMessage({id:'RECENT_ACT'})}>
                    {modifiedHistory}
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default KeeogoDrawer;