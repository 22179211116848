import { api, toQueryString } from './api';
import { useEnums } from './enums';

const baseUrl = '';

// get list
export const getRoleListApi = (queryParams?: {
    pageSize?:number,
    page?:number,
    keyword?:string,
    startDate?:string,
    endDate?:string,
    selectRole?: boolean
}) => {
    const queryString = {
        pageSize: queryParams?.pageSize ?? -1,
        page: queryParams?.page,
        name: queryParams?.keyword,
        startDate: queryParams?.startDate,
        endDate: queryParams?.endDate,
        selectRole: queryParams?.selectRole
    }
    return queryParams?
        api.get(`${baseUrl}/role?sort=updatedAt&desc=true&${toQueryString(queryString)}`):
        api.get(`${baseUrl}/role?sort=updatedAt&desc=true&pageSize=-1`);
}

// insert role
type I_premission = {
    function: string;
    read: boolean;
    write: boolean;
};

export type I_role = {
    id?: string;
    name: string;
    permission: any;//I_premission[];
};
export const insertRoleApi = (data: I_role) => api.post(`${baseUrl}/role`, data);

// edit role
export const editRoleApi = (data: I_role) => {
    const { id, ...other } = data;
    return api.put(`${baseUrl}/role/${id}`, other);
};

// delete role
export const deleteRoleApi = (id: string) => api.delete(`${baseUrl}/role/${id}`);

export const useAllRoles = () => {
    const { byId, ...others } = useEnums('/role', {
        baseURL: undefined,
        useCache: false,
    });
    return { ...others, roleById: byId };
};

// get permission
export const getPermissionApi = (id: string) => api.get(`${baseUrl}/role/permission/${id}`)

// set permission
export const setPermissionApi = ({id, permission, ...prop}: any) => api.put(`${baseUrl}/role/permission/${id}`, { permission });
