import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { GlobalContext } from '../../../../../index';
import { Radio, Row, Col, Divider } from 'antd';
import { Form } from 'components/form';
import { FormTitle } from './EvaluationDrawer';
import { BalanceData } from '../type';

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 3vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

interface Props {
    data: BalanceData;
    userInfoData: any;
}

const Balance: React.FC<Props> = ({ data, userInfoData }: Props) => {

    const { formatMessage } = useIntl();
    const { getOptions, nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const ConditionStatus01 = getOptions('ConditionStatus01').slice(0, -1).map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    return (
        <div style={userInfoData.mainDiagnosis1===nameMapping['MAI01']?{}:{pointerEvents: 'none', opacity: 0.4}}>
            <div className='text-24 text--bold'>{formatMessage({id:'BALANCE'})}</div>

            <Row gutter={24}>
                <Col span={11} className='d-flex is--column is--space-around'>
                    <div>
                        <FormTitle>{formatMessage({id:'STATIC_SITTING_BALANCE'})}</FormTitle>
                        <Form.Item name="balanceStaticSittingBalanceCode">
                            <Radio.Group buttonStyle="solid">{ConditionStatus01}</Radio.Group>
                        </Form.Item>
                    </div>
                    <div>
                        <FormTitle>{formatMessage({id:'STATIC_STANDING_BALANCE'})}</FormTitle>
                        <Form.Item name="balanceStaticStandingBalanceCode">
                            <Radio.Group buttonStyle="solid">{ConditionStatus01}</Radio.Group>
                        </Form.Item>
                    </div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <div className='mb-10'>
                        <div className='text-16 text--bold'>{nameMapping['CON01']}</div>
                        <p style={{fontSize: '12px', color: '#909090'}}>
                            {formatMessage({id:'STATIC_BALANCE_GOOD'})}
                        </p>
                    </div>
                    <div className='mb-10'>
                        <div className='text-16 text--bold'>{nameMapping['CON02']}</div>
                        <p style={{fontSize: '12px', color: '#909090'}}>
                            {formatMessage({id:'STATIC_BALANCE_FAIR'})}
                        </p>
                    </div>
                    <div className='mb-10'>
                        <div className='text-16 text--bold'>{nameMapping['CON03']}</div>
                        <p style={{fontSize: '12px', color: '#909090'}}>
                            {formatMessage({id:'STATIC_BALANCE_POOR'})}
                        </p>
                    </div>
                </Col>
            </Row>

            <Divider />

            <Row gutter={24}>
                <Col span={11} className='d-flex is--column is--space-around'>
                    <div>
                        <FormTitle>{formatMessage({id:'DYNAMIC_SITTING_BALANCE'})}</FormTitle>
                        <Form.Item name="balanceDynamicSittingBalanceCode">
                            <Radio.Group buttonStyle="solid">{ConditionStatus01}</Radio.Group>
                        </Form.Item>
                    </div>
                    <div>
                        <FormTitle>{formatMessage({id:'DYNAMIC_STANDING_BALANCE'})}</FormTitle>
                        <Form.Item name="balanceDynamicStandingBalanceCode">
                            <Radio.Group buttonStyle="solid">{ConditionStatus01}</Radio.Group>
                        </Form.Item>
                    </div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <div className='mb-10'>
                        <div className='text-16 text--bold'>{nameMapping['CON01']}</div>
                        <p style={{fontSize: '12px', color: '#909090'}}>
                            {formatMessage({id:'DYNAMIC_BALANCE_GOOD'})}
                        </p>
                    </div>
                    <div className='mb-10'>
                        <div className='text-16 text--bold'>{nameMapping['CON02']}</div>
                        <p style={{fontSize: '12px', color: '#909090'}}>
                            {formatMessage({id:'DYNAMIC_BALANCE_FAIR'})}
                        </p>
                    </div>
                    <div className='mb-10'>
                        <div className='text-16 text--bold'>{nameMapping['CON03']}</div>
                        <p style={{fontSize: '12px', color: '#909090'}}>
                            {formatMessage({id:'DYNAMIC_BALANCE_POOR'})}
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Balance;
