import React from 'react';
import { useIntl } from 'react-intl';
import ImageMapping from 'constants/imageMapping';
import { EditWrapper } from '../index';
import { Form, TextArea } from 'components/form';
import { FormTitle } from '../../EvaluationForms/EvaluationDrawer';
import KRAListTable from './KRAListTable';
import { KRAdata } from 'api/record';

interface SitToStandProps {
    kraData: KRAdata[];
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    };
}

const SitToStandEditKRA: React.FC<SitToStandProps> = (props: SitToStandProps) => {

    const { formatMessage } = useIntl();

    return (
        <EditWrapper>
            <div className="left">
                <div className="icon">
                    <img src={ImageMapping.KRAExamination.KEN01} width='100%' />
                </div>
                <div className="header">
                    {formatMessage({id:'KRA_SIT_TO_STAND'}, {br: <br/>})}
                </div>
            </div>
            <div className="right">
                {props.kraData.length > 0 &&
                <KRAListTable
                    data={props.kraData}
                    classData={props.classData}
                    className='mb-15'
                />}

                <Form.Item className='width--full' label={<FormTitle>{formatMessage({id:'OBSERVATION'})}</FormTitle>} name='keMakSitStandObservation'>
                    <TextArea rows={4} placeholder='(content)' maxLength={500}/>
                </Form.Item>
            </div>
        </EditWrapper>
    )
}

export default SitToStandEditKRA;
