export const cityList = [
    {
        'id': 'A',
        'name': '臺北市',
        'zoom': 12,
        'center': { lat: 25.09108, lng: 121.5598 },
        'districts': [
            { 'id': '100', 'name': '中正區', 'center': { lat: 25.025060500500047, lng: 121.52051648550008 } },
            { 'id': '103', 'name': '大同區', 'center': { lat: 25.05725907200008, lng: 121.51356339100005 } },
            { 'id': '104', 'name': '中山區', 'center': { lat: 25.069779224221985, lng: 121.53835826826034 } },
            { 'id': '105', 'name': '松山區', 'center': { lat: 25.05969521133678, lng: 121.55775067140063 } },
            { 'id': '106', 'name': '大安區', 'center': { lat: 25.026613853500066, lng: 121.54080784650006 } },
            { 'id': '108', 'name': '萬華區', 'center': { lat: 25.023572696000087, lng: 121.49801046000005 } },
            { 'id': '110', 'name': '信義區', 'center': { lat: 25.030691416799762, lng: 121.57178990379522 } },
            { 'id': '111', 'name': '士林區', 'center': { lat: 25.125558249344987, lng: 121.55073316480419 } },
            { 'id': '112', 'name': '北投區', 'center': { lat: 25.148273831838228, lng: 121.5179239261546 } },
            { 'id': '114', 'name': '內湖區', 'center': { lat: 25.083830426249115, lng: 121.59242123731823 } },
            { 'id': '115', 'name': '南港區', 'center': { lat: 25.038328495500053, lng: 121.60413963150009 } },
            { 'id': '116', 'name': '文山區', 'center': { lat: 24.988008905000044, lng: 121.57559458800006 } }
        ]
    },
    {
        'id': 'C',
        'name': '基隆市',
        'zoom': 13,
        'center': { lat: 25.10898, lng: 121.7199 },
        'districts': [
            { 'id': '200', 'name': '仁愛區', 'center': { lat: 25.12117600800005, lng: 121.74372583000002 } },
            { 'id': '201', 'name': '信義區', 'center': { lat: 25.12757485000006, lng: 121.76368133800005 } },
            { 'id': '202', 'name': '中正區', 'center': { lat: 25.1341337, lng: 121.73939 } },
            { 'id': '203', 'name': '中山區', 'center': { lat: 25.148058060500063, lng: 121.73320885100009 } },
            { 'id': '204', 'name': '安樂區', 'center': { lat: 25.141334229500046, lng: 121.70699705250001 } },
            { 'id': '205', 'name': '暖暖區', 'center': { lat: 25.08104130709011, lng: 121.7447842484362 } },
            { 'id': '206', 'name': '七堵區', 'center': { lat: 25.10965050685708, lng: 121.68369047745895 } }
        ]
    },
    {
        'id': 'F',
        'name': '新北市',
        'zoom': 11,
        'center': { lat: 24.91571, lng: 121.6739 },
        'districts': [
            { 'id': '207', 'name': '萬里區', 'center': { lat: 25.176050713041025, lng: 121.64443649598746 } },
            { 'id': '208', 'name': '金山區', 'center': { lat: 25.21936343300007, lng: 121.60646664100005 } },
            { 'id': '220', 'name': '板橋區', 'center': { lat: 25.01172269516384, lng: 121.45779782020291 } },
            { 'id': '221', 'name': '汐止區', 'center': { lat: 25.073433165497466, lng: 121.65458927461302 } },
            { 'id': '222', 'name': '深坑區', 'center': { lat: 24.997614323163415, lng: 121.62006892646646 } },
            { 'id': '223', 'name': '石碇區', 'center': { lat: 24.937667105500054, lng: 121.64657755950003 } },
            { 'id': '224', 'name': '瑞芳區', 'center': { lat: 25.098586488378803, lng: 121.82362636733966 } },
            { 'id': '226', 'name': '平溪區', 'center': { lat: 25.025676145500057, lng: 121.75273283850012 } },
            { 'id': '227', 'name': '雙溪區', 'center': { lat: 24.99855192700005, lng: 121.83023771100005 } },
            { 'id': '228', 'name': '貢寮區', 'center': { lat: 25.025588227438053, lng: 121.91918934185938 } },
            { 'id': '231', 'name': '新店區', 'center': { lat: 24.930654836441708, lng: 121.53162996494659 } },
            { 'id': '232', 'name': '坪林區', 'center': { lat: 24.911096337013543, lng: 121.72426579107182 } },
            { 'id': '233', 'name': '烏來區', 'center': { lat: 24.78352042700004, lng: 121.533571532 } },
            { 'id': '234', 'name': '永和區', 'center': { lat: 25.00811397808708, lng: 121.51688127297554 } },
            { 'id': '235', 'name': '中和區', 'center': { lat: 24.990983631490884, lng: 121.49396788583798 } },
            { 'id': '236', 'name': '土城區', 'center': { lat: 24.964389050592786, lng: 121.44571792888404 } },
            { 'id': '237', 'name': '三峽區', 'center': { lat: 24.882270970990277, lng: 121.41626239221819 } },
            { 'id': '238', 'name': '樹林區', 'center': { lat: 24.97989868395041, lng: 121.4011476017405 } },
            { 'id': '239', 'name': '鶯歌區', 'center': { lat: 24.95676715431217, lng: 121.34652357554799 } },
            { 'id': '241', 'name': '三重區', 'center': { lat: 25.0593667710001, lng: 121.48619026900002 } },
            { 'id': '242', 'name': '新莊區', 'center': { lat: 25.032018645500074, lng: 121.42967647450007 } },
            { 'id': '243', 'name': '泰山區', 'center': { lat: 25.05198, lng: 121.4103847 } },
            { 'id': '244', 'name': '林口區', 'center': { lat: 25.100507274342053, lng: 121.35252845730851 } },
            { 'id': '247', 'name': '蘆洲區', 'center': { lat: 25.089495799318655, lng: 121.47127391630245 } },
            { 'id': '248', 'name': '五股區', 'center': { lat: 25.096536000500066, lng: 121.43549534200002 } },
            { 'id': '249', 'name': '八里區', 'center': { lat: 25.13829903047314, lng: 121.41276571459213 } },
            { 'id': '251', 'name': '淡水區', 'center': { lat: 25.18206228750006, lng: 121.46342307750007 } },
            { 'id': '252', 'name': '三芝區', 'center': { lat: 25.23206372431863, lng: 121.51477280814892 } },
            { 'id': '253', 'name': '石門區', 'center': { lat: 25.26559988238722, lng: 121.56922254010195 } }
        ]
    },
    {
        'id': 'Z',
        'name': '連江縣',
        'zoom': 11,
        'center': { lat: 26.15343, lng: 119.9319 },
        'districts': [
            { 'id': '209', 'name': '南竿鄉', 'center': { lat: 26.154694, lng: 119.9377159 } },
            { 'id': '210', 'name': '北竿鄉', 'center': { lat: 26.2149631, lng: 119.9729809 } },
            { 'id': '211', 'name': '莒光鄉', 'center': { lat: 25.9716246, lng: 119.9393914 } },
            { 'id': '212', 'name': '東引鄉', 'center': { lat: 26.3671079, lng: 120.4880549 } }
        ]
    },
    {
        'id': 'G',
        'name': '宜蘭縣',
        'zoom': 10,
        'center': { lat: 24.69295, lng: 121.7195 },
        'districts': [
            { 'id': '260', 'name': '宜蘭市', 'center': { lat: 24.748918762000045, lng: 121.75810463550005 } },
            { 'id': '263', 'name': '壯圍鄉', 'center': { lat: 24.74583103800012, lng: 121.80461070300004 } },
            { 'id': '261', 'name': '頭城鎮', 'center': { lat: 24.8548387, lng: 121.8191302 } },
            { 'id': '262', 'name': '礁溪鄉', 'center': { lat: 24.81236076400006, lng: 121.75507916400005 } },
            { 'id': '264', 'name': '員山鄉', 'center': { lat: 24.738122039000046, lng: 121.64483783500006 } },
            { 'id': '265', 'name': '羅東鎮', 'center': { lat: 24.67896680948168, lng: 121.77024338298251 } },
            { 'id': '266', 'name': '三星鄉', 'center': { lat: 24.66785528208577, lng: 121.66435629868005 } },
            { 'id': '267', 'name': '大同鄉', 'center': { lat: 24.544587667000087, lng: 121.49523808800001 } },
            { 'id': '268', 'name': '五結鄉', 'center': { lat: 24.68909991736789, lng: 121.80616290309064 } },
            { 'id': '269', 'name': '冬山鄉', 'center': { lat: 24.64221756486828, lng: 121.76031523022448 } },
            { 'id': '270', 'name': '蘇澳鎮', 'center': { lat: 24.59140026750015, lng: 121.83254563350005 } },
            { 'id': '272', 'name': '南澳鄉', 'center': { lat: 24.441286661559626, lng: 121.66595494806484 } }
        ]
    },
    {
        'id': 'O',
        'name': '新竹市',
        'zoom': 12,
        'center': { lat: 24.80395, lng: 120.9647 },
        'districts': [
            { 'id': '300-1', 'name': '東區', 'center': { lat: 24.790483952177205, lng: 120.9926056814418 } },
            { 'id': '300-2', 'name': '北區', 'center': { lat: 24.825569235324412, lng: 120.94626810586948 } },
            { 'id': '300-3', 'name': '香山區', 'center': { lat: 24.771529651615523, lng: 120.92253106128749 } }
        ]
    },
    {
        'id': 'J',
        'name': '新竹縣',
        'zoom': 11,
        'center': { lat: 24.70328, lng: 121.1252 },
        'districts': [
            { 'id': '308', 'name': '寶山鄉', 'center': { lat: 24.742314288000046, lng: 121.00249064850004 } },
            { 'id': '302', 'name': '竹北市', 'center': { lat: 24.838624926482204, lng: 120.99395895177933 } },
            { 'id': '303', 'name': '湖口鄉', 'center': { lat: 24.88571875178909, lng: 121.05179469909095 } },
            { 'id': '304', 'name': '新豐鄉', 'center': { lat: 24.907402519732237, lng: 120.99478216431714 } },
            { 'id': '305', 'name': '新埔鎮', 'center': { lat: 24.836862081000078, lng: 121.05918274900009 } },
            { 'id': '306', 'name': '關西鎮', 'center': { lat: 24.785274778431262, lng: 121.18652432956672 } },
            { 'id': '307', 'name': '芎林鄉', 'center': { lat: 24.768875027000064, lng: 121.09806713600008 } },
            { 'id': '310', 'name': '竹東鎮', 'center': { lat: 24.72102305550004, lng: 121.0746237875 } },
            { 'id': '311', 'name': '五峰鄉', 'center': { lat: 24.577599771500047, lng: 121.1393208825001 } },
            { 'id': '312', 'name': '橫山鄉', 'center': { lat: 24.707960348743256, lng: 121.14703739943629 } },
            { 'id': '313', 'name': '尖石鄉', 'center': { lat: 24.55759387100011, lng: 121.28210478000005 } },
            { 'id': '314', 'name': '北埔鄉', 'center': { lat: 24.672409317000074, lng: 121.06531506200008 } },
            { 'id': '315', 'name': '峨眉鄉', 'center': { lat: 24.678198862804514, lng: 121.01098851199642 } }
        ]
    },
    {
        'id': 'H',
        'name': '桃園市',
        'zoom': 11,
        'center': { lat: 24.93759, lng: 121.2168 },
        'districts': [
            { 'id': '320', 'name': '中壢區', 'center': { lat: 24.98008212814877, lng: 121.21470251589582 } },
            { 'id': '324', 'name': '平鎮區', 'center': { lat: 24.91308206100007, lng: 121.21758170600003 } },
            { 'id': '325', 'name': '龍潭區', 'center': { lat: 24.850735312505748, lng: 121.21175307742092 } },
            { 'id': '326', 'name': '楊梅區', 'center': { lat: 24.918293822439036, lng: 121.12925318683926 } },
            { 'id': '327', 'name': '新屋區', 'center': { lat: 24.976286186000067, lng: 121.07385056800007 } },
            { 'id': '328', 'name': '觀音區', 'center': { lat: 25.027478851162353, lng: 121.1142583804682 } },
            { 'id': '330', 'name': '桃園區', 'center': { lat: 25.00048618107127, lng: 121.29960125363996 } },
            { 'id': '333', 'name': '龜山區', 'center': { lat: 25.024324368927623, lng: 121.35692778749905 } },
            { 'id': '334', 'name': '八德區', 'center': { lat: 24.947982747000054, lng: 121.29090946 } },
            { 'id': '335', 'name': '大溪區', 'center': { lat: 24.870961896500063, lng: 121.30061147850006 } },
            { 'id': '336', 'name': '復興區', 'center': { lat: 24.72961609620573, lng: 121.37541281402221 } },
            { 'id': '337', 'name': '大園區', 'center': { lat: 25.065067528453383, lng: 121.21112602035137 } },
            { 'id': '338', 'name': '蘆竹區', 'center': { lat: 25.06112628832836, lng: 121.28305199289402 } }
        ]
    },
    {
        'id': 'K',
        'name': '苗栗縣',
        'zoom': 11,
        'center': { lat: 24.48927, lng: 120.9417 },
        'districts': [
            { 'id': '350', 'name': '竹南鎮', 'center': { lat: 24.694473776000052, lng: 120.8709566120001 } },
            { 'id': '351', 'name': '頭份市', 'center': { lat: 24.676315452864152, lng: 120.91895038166213 } },
            { 'id': '352', 'name': '三灣鄉', 'center': { lat: 24.631317163000062, lng: 120.94463993700009 } },
            { 'id': '353', 'name': '南庄鄉', 'center': { lat: 24.569150455000056, lng: 121.01567555950004 } },
            { 'id': '354', 'name': '獅潭鄉', 'center': { lat: 24.51997591778505, lng: 120.92068289094212 } },
            { 'id': '356', 'name': '後龍鎮', 'center': { lat: 24.61719821651168, lng: 120.77887579683039 } },
            { 'id': '357', 'name': '通霄鎮', 'center': { lat: 24.486119620086097, lng: 120.71367262225513 } },
            { 'id': '358', 'name': '苑裡鎮', 'center': { lat: 24.411436375188217, lng: 120.68797366300254 } },
            { 'id': '360', 'name': '苗栗市', 'center': { lat: 24.563940197213768, lng: 120.81127493527734 } },
            { 'id': '361', 'name': '造橋鄉', 'center': { lat: 24.627841209500048, lng: 120.85169070350008 } },
            { 'id': '362', 'name': '頭屋鄉', 'center': { lat: 24.573556787757237, lng: 120.8827200265681 } },
            { 'id': '363', 'name': '公館鄉', 'center': { lat: 24.50262949097439, lng: 120.85061381998112 } },
            { 'id': '364', 'name': '大湖鄉', 'center': { lat: 24.393862991265642, lng: 120.86319263034507 } },
            { 'id': '365', 'name': '泰安鄉', 'center': { lat: 24.419274735105834, lng: 121.06827017277628 } },
            { 'id': '366', 'name': '銅鑼鄉', 'center': { lat: 24.45597935722117, lng: 120.79918368735808 } },
            { 'id': '367', 'name': '三義鄉', 'center': { lat: 24.381013685328742, lng: 120.77015496664126 } },
            { 'id': '368', 'name': '西湖鄉', 'center': { lat: 24.541612947950725, lng: 120.76144127230091 } },
            { 'id': '369', 'name': '卓蘭鎮', 'center': { lat: 24.32540765952491, lng: 120.8560176147782 } }
        ]
    },
    {
        'id': 'B',
        'name': '臺中市',
        'zoom': 11,
        'center': { lat: 24.23321, lng: 120.9417 },
        'districts': [
            { 'id': '400', 'name': '中區', 'center': { lat: 24.14166964384395, lng: 120.68053574020558 } },
            { 'id': '401', 'name': '東區', 'center': { lat: 24.136533036500055, lng: 120.69721791350008 } },
            { 'id': '402', 'name': '南區', 'center': { lat: 24.120005475000056, lng: 120.65908713200005 } },
            { 'id': '403', 'name': '西區', 'center': { lat: 24.14390515481306, lng: 120.66473806404316 } },
            { 'id': '404', 'name': '北區', 'center': { lat: 24.158758297558897, lng: 120.68062726111165 } },
            { 'id': '406', 'name': '北屯區', 'center': { lat: 24.188028815500047, lng: 120.75122658949999 } },
            { 'id': '407', 'name': '西屯區', 'center': { lat: 24.183092885793137, lng: 120.62664900891276 } },
            { 'id': '408', 'name': '南屯區', 'center': { lat: 24.139765123000046, lng: 120.6146521605001 } },
            { 'id': '411', 'name': '太平區', 'center': { lat: 24.114859268172545, lng: 120.7734592457407 } },
            { 'id': '412', 'name': '大里區', 'center': { lat: 24.09615892989671, lng: 120.69267986416587 } },
            { 'id': '413', 'name': '霧峰區', 'center': { lat: 24.043575769257416, lng: 120.72001600709747 } },
            { 'id': '414', 'name': '烏日區', 'center': { lat: 24.084093711679113, lng: 120.62928844637439 } },
            { 'id': '420', 'name': '豐原區', 'center': { lat: 24.25002729376019, lng: 120.73750223331463 } },
            { 'id': '421', 'name': '后里區', 'center': { lat: 24.30971937808176, lng: 120.71456950380873 } },
            { 'id': '422', 'name': '石岡區', 'center': { lat: 24.26276087900007, lng: 120.7819086370001 } },
            { 'id': '423', 'name': '東勢區', 'center': { lat: 24.24975129386517, lng: 120.84007422739688 } },
            { 'id': '424', 'name': '和平區', 'center': { lat: 24.275998218341044, lng: 121.14063388671516 } },
            { 'id': '426', 'name': '新社區', 'center': { lat: 24.15089317400009, lng: 120.83219604800007 } },
            { 'id': '427', 'name': '潭子區', 'center': { lat: 24.21197220039909, lng: 120.71096407027972 } },
            { 'id': '428', 'name': '大雅區', 'center': { lat: 24.227023920518572, lng: 120.64120431470188 } },
            { 'id': '429', 'name': '神岡區', 'center': { lat: 24.265788199913782, lng: 120.67329120214578 } },
            { 'id': '432', 'name': '大肚區', 'center': { lat: 24.14262892350004, lng: 120.55053917450005 } },
            { 'id': '433', 'name': '沙鹿區', 'center': { lat: 24.234465012665684, lng: 120.58391763768236 } },
            { 'id': '434', 'name': '龍井區', 'center': { lat: 24.202019173188418, lng: 120.52422654485211 } },
            { 'id': '435', 'name': '梧棲區', 'center': { lat: 24.250050114333714, lng: 120.52365273402903 } },
            { 'id': '436', 'name': '清水區', 'center': { lat: 24.296383663000086, lng: 120.54658633500003 } },
            { 'id': '437', 'name': '大甲區', 'center': { lat: 24.375735840000004, lng: 120.64699901500006 } },
            { 'id': '438', 'name': '外埔區', 'center': { lat: 24.335531713359995, lng: 120.66493541945776 } },
            { 'id': '439', 'name': '大安區', 'center': { lat: 24.364894723000077, lng: 120.58895867250004 } }
        ]
    },
    {
        'id': 'N',
        'name': '彰化縣',
        'zoom': 11,
        'center': { lat: 23.99297, lng: 120.4818 },
        'districts': [
            { 'id': '500', 'name': '彰化市', 'center': { lat: 24.075860613500055, lng: 120.56279566800004 } },
            { 'id': '502', 'name': '芬園鄉', 'center': { lat: 24.006346283517026, lng: 120.6294109190211 } },
            { 'id': '503', 'name': '花壇鄉', 'center': { lat: 24.029166721500047, lng: 120.5612551540001 } },
            { 'id': '504', 'name': '秀水鄉', 'center': { lat: 24.034289838500058, lng: 120.50678486350006 } },
            { 'id': '505', 'name': '鹿港鎮', 'center': { lat: 24.084106522387632, lng: 120.42630586197961 } },
            { 'id': '506', 'name': '福興鄉', 'center': { lat: 24.033004913949537, lng: 120.42299145234134 } },
            { 'id': '507', 'name': '線西鄉', 'center': { lat: 24.140027314000065, lng: 120.43600638500004 } },
            { 'id': '508', 'name': '和美鎮', 'center': { lat: 24.112402481500055, lng: 120.51349789750003 } },
            { 'id': '509', 'name': '伸港鄉', 'center': { lat: 24.170628378857423, lng: 120.47413528822297 } },
            { 'id': '510', 'name': '員林市', 'center': { lat: 23.95670587500004, lng: 120.59082890950009 } },
            { 'id': '511', 'name': '社頭鄉', 'center': { lat: 23.9055100668257, lng: 120.60216799864301 } },
            { 'id': '512', 'name': '永靖鄉', 'center': { lat: 23.918392831500057, lng: 120.55414207150011 } },
            { 'id': '513', 'name': '埔心鄉', 'center': { lat: 23.952905927252978, lng: 120.53434819163033 } },
            { 'id': '514', 'name': '溪湖鎮', 'center': { lat: 23.951580206000045, lng: 120.48082029200003 } },
            { 'id': '515', 'name': '大村鄉', 'center': { lat: 23.992340093000053, lng: 120.56881486600008 } },
            { 'id': '516', 'name': '埔鹽鄉', 'center': { lat: 23.99270922450006, lng: 120.44573243950003 } },
            { 'id': '520', 'name': '田中鎮', 'center': { lat: 23.857294435504404, lng: 120.59031847410851 } },
            { 'id': '521', 'name': '北斗鎮', 'center': { lat: 23.867572698415746, lng: 120.53309233735092 } },
            { 'id': '522', 'name': '田尾鄉', 'center': { lat: 23.90059346984069, lng: 120.52248893139925 } },
            { 'id': '523', 'name': '埤頭鄉', 'center': { lat: 23.8823347700049, lng: 120.46757200598277 } },
            { 'id': '524', 'name': '溪州鄉', 'center': { lat: 23.832178305500065, lng: 120.49369765650005 } },
            { 'id': '525', 'name': '竹塘鄉', 'center': { lat: 23.850575763467056, lng: 120.41356994091372 } },
            { 'id': '526', 'name': '二林鎮', 'center': { lat: 23.915794021500062, lng: 120.40410578950002 } },
            { 'id': '527', 'name': '大城鄉', 'center': { lat: 23.859600825564122, lng: 120.29523179287933 } },
            { 'id': '528', 'name': '芳苑鄉', 'center': { lat: 23.95803845468211, lng: 120.34087795295214 } },
            { 'id': '530', 'name': '二水鄉', 'center': { lat: 23.809248531071095, lng: 120.6286324373157 } }
        ]
    },
    {
        'id': 'M',
        'name': '南投縣',
        'zoom': 10,
        'center': { lat: 23.83876, lng: 120.9876 },
        'districts': [
            { 'id': '540', 'name': '南投市', 'center': { lat: 23.922033065500045, lng: 120.68084780200007 } },
            { 'id': '541', 'name': '中寮鄉', 'center': { lat: 23.9060150108074, lng: 120.78599179384966 } },
            { 'id': '542', 'name': '草屯鎮', 'center': { lat: 23.98244042050004, lng: 120.6737810305001 } },
            { 'id': '544', 'name': '國姓鄉', 'center': { lat: 24.03129195906591, lng: 120.86748612697909 } },
            { 'id': '545', 'name': '埔里鎮', 'center': { lat: 23.974284053000048, lng: 120.95737758800001 } },
            { 'id': '546', 'name': '仁愛鄉', 'center': { lat: 24.028672118159292, lng: 121.14445297009307 } },
            { 'id': '551', 'name': '名間鄉', 'center': { lat: 23.851185947324552, lng: 120.67729710266038 } },
            { 'id': '552', 'name': '集集鎮', 'center': { lat: 23.837148169895137, lng: 120.78518758866011 } },
            { 'id': '553', 'name': '水里鄉', 'center': { lat: 23.79630480559089, lng: 120.86235532510116 } },
            { 'id': '555', 'name': '魚池鄉', 'center': { lat: 23.876126572083386, lng: 120.92571744997946 } },
            { 'id': '556', 'name': '信義鄉', 'center': { lat: 23.655596205686667, lng: 121.02136180579053 } },
            { 'id': '557', 'name': '竹山鎮', 'center': { lat: 23.69815699145915, lng: 120.71012114034197 } },
            { 'id': '558', 'name': '鹿谷鄉', 'center': { lat: 23.737938353527568, lng: 120.7815651261748 } }
        ]
    },
    {
        'id': 'I',
        'name': '嘉義市',
        'zoom': 13,
        'center': { lat: 23.47545, lng: 120.4473 },
        'districts': [
            { 'id': '600-1', 'name': '西區', 'center': { lat: 23.479560215027536, lng: 120.42493401728345 } },
            { 'id': '600-2', 'name': '東區', 'center': { lat: 23.480926784500042, lng: 120.47093081200006 } }
        ]
    },
    {
        'id': 'Q',
        'name': '嘉義縣',
        'zoom': 10,
        'center': { lat: 23.45889, lng: 120.574 },
        'districts': [
            { 'id': '602', 'name': '番路鄉', 'center': { lat: 23.427744912797497, lng: 120.60748337267994 } },
            { 'id': '603', 'name': '梅山鄉', 'center': { lat: 23.555433508644736, lng: 120.63872734395613 } },
            { 'id': '604', 'name': '竹崎鄉', 'center': { lat: 23.503864678028034, lng: 120.59662759017297 } },
            { 'id': '605', 'name': '阿里山鄉', 'center': { lat: 23.440857415299703, lng: 120.75960292063391 } },
            { 'id': '606', 'name': '中埔鄉', 'center': { lat: 23.40425371309657, lng: 120.53650296754795 } },
            { 'id': '607', 'name': '大埔鄉', 'center': { lat: 23.290938044000058, lng: 120.59118125000003 } },
            { 'id': '608', 'name': '水上鄉', 'center': { lat: 23.426188824000064, lng: 120.38837077199997 } },
            { 'id': '611', 'name': '鹿草鄉', 'center': { lat: 23.406889182000043, lng: 120.30161297900008 } },
            { 'id': '612', 'name': '太保市', 'center': { lat: 23.47275046350009, lng: 120.33337307450009 } },
            { 'id': '613', 'name': '朴子市', 'center': { lat: 23.438814805500044, lng: 120.25044184550006 } },
            { 'id': '614', 'name': '東石鄉', 'center': { lat: 23.4821416, lng: 120.1631577 } },
            { 'id': '615', 'name': '六腳鄉', 'center': { lat: 23.51019636104954, lng: 120.27137417026712 } },
            { 'id': '616', 'name': '新港鄉', 'center': { lat: 23.546025702216873, lng: 120.34825226486575 } },
            { 'id': '621', 'name': '民雄鄉', 'center': { lat: 23.54271297164526, lng: 120.44417626217373 } },
            { 'id': '622', 'name': '大林鎮', 'center': { lat: 23.59898755440604, lng: 120.48084625422518 } },
            { 'id': '623', 'name': '溪口鄉', 'center': { lat: 23.593549505335254, lng: 120.40110759316532 } },
            { 'id': '624', 'name': '義竹鄉', 'center': { lat: 23.34896177050007, lng: 120.22127389250005 } },
            { 'id': '625', 'name': '布袋鎮', 'center': { lat: 23.37509238368282, lng: 120.17621270947531 } }
        ]
    },
    {
        'id': 'P',
        'name': '雲林縣',
        'zoom': 11,
        'center': { lat: 23.75585, lng: 120.3897 },
        'districts': [
            { 'id': '630', 'name': '斗南鎮', 'center': { lat: 23.677187039000046, lng: 120.47819183150006 } },
            { 'id': '631', 'name': '大埤鄉', 'center': { lat: 23.645604884368026, lng: 120.4254273551802 } },
            { 'id': '632', 'name': '虎尾鎮', 'center': { lat: 23.716661685354396, lng: 120.42924196260482 } },
            { 'id': '633', 'name': '土庫鎮', 'center': { lat: 23.682641872000033, lng: 120.36850213700006 } },
            { 'id': '634', 'name': '褒忠鄉', 'center': { lat: 23.716264236426877, lng: 120.31164125402924 } },
            { 'id': '635', 'name': '東勢鄉', 'center': { lat: 23.69622632613032, lng: 120.2565149913863 } },
            { 'id': '636', 'name': '臺西鄉', 'center': { lat: 23.716664870830787, lng: 120.1998291855446 } },
            { 'id': '637', 'name': '崙背鄉', 'center': { lat: 23.778676636487106, lng: 120.3339709796804 } },
            { 'id': '638', 'name': '麥寮鄉', 'center': { lat: 23.799928674500052, lng: 120.2317723970001 } },
            { 'id': '640', 'name': '斗六市', 'center': { lat: 23.705178984500066, lng: 120.54990331350004 } },
            { 'id': '643', 'name': '林內鄉', 'center': { lat: 23.75597422000286, lng: 120.61546535141392 } },
            { 'id': '646', 'name': '古坑鄉', 'center': { lat: 23.63311553850005, lng: 120.5700088435 } },
            { 'id': '647', 'name': '莿桐鄉', 'center': { lat: 23.76985202665004, lng: 120.5290220270863 } },
            { 'id': '648', 'name': '西螺鎮', 'center': { lat: 23.77946807400008, lng: 120.45788067300009 } },
            { 'id': '649', 'name': '二崙鄉', 'center': { lat: 23.792573985206484, lng: 120.39646982062243 } },
            { 'id': '651', 'name': '北港鎮', 'center': { lat: 23.592284185022788, lng: 120.29391307921605 } },
            { 'id': '652', 'name': '水林鄉', 'center': { lat: 23.568912454000042, lng: 120.23010345350008 } },
            { 'id': '653', 'name': '口湖鄉', 'center': { lat: 23.5827378, lng: 120.1836665 } },
            { 'id': '654', 'name': '四湖鄉', 'center': { lat: 23.646293628270136, lng: 120.19396148243696 } },
            { 'id': '655', 'name': '元長鄉', 'center': { lat: 23.643923960000052, lng: 120.326635969 } }
        ]
    },
    {
        'id': 'D',
        'name': '臺南市',
        'zoom': 11,
        'center': { lat: 23.1417, lng: 120.2513 },
        'districts': [
            { 'id': '700', 'name': '中西區', 'center': { lat: 22.995264331042836, lng: 120.19386067871713 } },
            { 'id': '701', 'name': '東區', 'center': { lat: 22.98167606165313, lng: 120.22821234309905 } },
            { 'id': '702', 'name': '南區', 'center': { lat: 22.95567172714897, lng: 120.18986405781067 } },
            { 'id': '704', 'name': '北區', 'center': { lat: 23.010019203625156, lng: 120.20596767604358 } },
            { 'id': '708', 'name': '安平區', 'center': { lat: 22.989921065499708, lng: 120.16414942594031 } },
            { 'id': '709', 'name': '安南區', 'center': { lat: 23.04417930750006, lng: 120.15479186350007 } },
            { 'id': '710', 'name': '永康區', 'center': { lat: 23.02736108103145, lng: 120.2543211261082 } },
            { 'id': '711', 'name': '歸仁區', 'center': { lat: 22.928028069500044, lng: 120.2942626555 } },
            { 'id': '712', 'name': '新化區', 'center': { lat: 23.036119769000067, lng: 120.33530013150005 } },
            { 'id': '713', 'name': '左鎮區', 'center': { lat: 23.026186734301866, lng: 120.41235303603376 } },
            { 'id': '714', 'name': '玉井區', 'center': { lat: 23.11490982372311, lng: 120.46091474931511 } },
            { 'id': '715', 'name': '楠西區', 'center': { lat: 23.178973171598418, lng: 120.51701328113293 } },
            { 'id': '716', 'name': '南化區', 'center': { lat: 23.115317959000038, lng: 120.54364165400006 } },
            { 'id': '717', 'name': '仁德區', 'center': { lat: 22.94129243942316, lng: 120.24190983608375 } },
            { 'id': '718', 'name': '關廟區', 'center': { lat: 22.955794397859467, lng: 120.33424326856354 } },
            { 'id': '719', 'name': '龍崎區', 'center': { lat: 22.94951113600007, lng: 120.39123136000006 } },
            { 'id': '720', 'name': '官田區', 'center': { lat: 23.19106372837078, lng: 120.3480028723211 } },
            { 'id': '721', 'name': '麻豆區', 'center': { lat: 23.18049158200006, lng: 120.24154598000001 } },
            { 'id': '722', 'name': '佳里區', 'center': { lat: 23.167114883958213, lng: 120.17862400387207 } },
            { 'id': '723', 'name': '西港區', 'center': { lat: 23.125126336155702, lng: 120.20028508284406 } },
            { 'id': '724', 'name': '七股區', 'center': { lat: 23.123148809687027, lng: 120.09924271061442 } },
            { 'id': '725', 'name': '將軍區', 'center': { lat: 23.21136580950005, lng: 120.1587659775 } },
            { 'id': '726', 'name': '學甲區', 'center': { lat: 23.25402277300006, lng: 120.18063209600004 } },
            { 'id': '727', 'name': '北門區', 'center': { lat: 23.278589870207103, lng: 120.12556462421276 } },
            { 'id': '730', 'name': '新營區', 'center': { lat: 23.301679143981925, lng: 120.29545990736092 } },
            { 'id': '731', 'name': '後壁區', 'center': { lat: 23.359406556000067, lng: 120.3452418390001 } },
            { 'id': '732', 'name': '白河區', 'center': { lat: 23.35051348600004, lng: 120.4434091520001 } },
            { 'id': '733', 'name': '東山區', 'center': { lat: 23.28081365150004, lng: 120.43186721100005 } },
            { 'id': '734', 'name': '六甲區', 'center': { lat: 23.22738886650504, lng: 120.37985761010599 } },
            { 'id': '735', 'name': '下營區', 'center': { lat: 23.2313330383165, lng: 120.26493662712137 } },
            { 'id': '736', 'name': '柳營區', 'center': { lat: 23.268971796389863, lng: 120.35499920458358 } },
            { 'id': '737', 'name': '鹽水區', 'center': { lat: 23.300770554500048, lng: 120.24518158000001 } },
            { 'id': '741', 'name': '善化區', 'center': { lat: 23.140624867500065, lng: 120.30037853250002 } },
            { 'id': '744', 'name': '新市區', 'center': { lat: 23.08328899033359, lng: 120.29237198019091 } },
            { 'id': '742', 'name': '大內區', 'center': { lat: 23.145036823116452, lng: 120.39880362694282 } },
            { 'id': '743', 'name': '山上區', 'center': { lat: 23.097131106599896, lng: 120.37074306271737 } },
            { 'id': '745', 'name': '安定區', 'center': { lat: 23.100797524000058, lng: 120.2283170120001 } }
        ]
    },
    {
        'id': 'E',
        'name': '高雄市',
        'zoom': 10,
        'center': { lat: 23.01087, lng: 120.666 },
        'districts': [
            { 'id': '800', 'name': '新興區', 'center': { lat: 22.629837131983745, lng: 120.30681378449049 } },
            { 'id': '801', 'name': '前金區', 'center': { lat: 22.62609713550006, lng: 120.29406842450004 } },
            { 'id': '802', 'name': '苓雅區', 'center': { lat: 22.62341701911108, lng: 120.31999236350555 } },
            { 'id': '803', 'name': '鹽埕區', 'center': { lat: 22.623660908530393, lng: 120.28444747493755 } },
            { 'id': '804', 'name': '鼓山區', 'center': { lat: 22.647957477506033, lng: 120.27406651686526 } },
            { 'id': '805', 'name': '旗津區', 'center': { lat: 22.5900263, lng: 120.2825213 } },
            { 'id': '806', 'name': '前鎮區', 'center': { lat: 22.58868864300007, lng: 120.30848006750006 } },
            { 'id': '807', 'name': '三民區', 'center': { lat: 22.649966921236867, lng: 120.31780890331373 } },
            { 'id': '811', 'name': '楠梓區', 'center': { lat: 22.724799290500073, lng: 120.28695664450004 } },
            { 'id': '812', 'name': '小港區', 'center': { lat: 22.548234418185004, lng: 120.35247855254806 } },
            { 'id': '813', 'name': '左營區', 'center': { lat: 22.685022683480494, lng: 120.29184171675018 } },
            { 'id': '814', 'name': '仁武區', 'center': { lat: 22.70569373400005, lng: 120.35131589100004 } },
            { 'id': '815', 'name': '大社區', 'center': { lat: 22.73781748400009, lng: 120.36272790000001 } },
            { 'id': '820', 'name': '岡山區', 'center': { lat: 22.805179692377585, lng: 120.29787958744268 } },
            { 'id': '821', 'name': '路竹區', 'center': { lat: 22.857346119014963, lng: 120.26599652237495 } },
            { 'id': '822', 'name': '阿蓮區', 'center': { lat: 22.87016495791489, lng: 120.32112979627372 } },
            { 'id': '823', 'name': '田寮區', 'center': { lat: 22.86225048050005, lng: 120.39589590150007 } },
            { 'id': '824', 'name': '燕巢區', 'center': { lat: 22.789523634500057, lng: 120.37265002800001 } },
            { 'id': '825', 'name': '橋頭區', 'center': { lat: 22.753051120000066, lng: 120.30033033550006 } },
            { 'id': '826', 'name': '梓官區', 'center': { lat: 22.74818358380625, lng: 120.25938121828136 } },
            { 'id': '827', 'name': '彌陀區', 'center': { lat: 22.779451478894163, lng: 120.23956803102466 } },
            { 'id': '828', 'name': '永安區', 'center': { lat: 22.822007756400875, lng: 120.22562880333143 } },
            { 'id': '829', 'name': '湖內區', 'center': { lat: 22.893334933046333, lng: 120.22584065673475 } },
            { 'id': '830', 'name': '鳳山區', 'center': { lat: 22.613936871833108, lng: 120.35550433274133 } },
            { 'id': '831', 'name': '大寮區', 'center': { lat: 22.5929730296695, lng: 120.41114550877357 } },
            { 'id': '832', 'name': '林園區', 'center': { lat: 22.50528771042142, lng: 120.39813520357565 } },
            { 'id': '833', 'name': '鳥松區', 'center': { lat: 22.66264421310538, lng: 120.37268046041696 } },
            { 'id': '840', 'name': '大樹區', 'center': { lat: 22.711086672681578, lng: 120.42530930310899 } },
            { 'id': '842', 'name': '旗山區', 'center': { lat: 22.865021589679166, lng: 120.47546418347854 } },
            { 'id': '843', 'name': '美濃區', 'center': { lat: 22.894897631500044, lng: 120.56177651450002 } },
            { 'id': '844', 'name': '六龜區', 'center': { lat: 23.008628718396658, lng: 120.65781356054705 } },
            { 'id': '845', 'name': '內門區', 'center': { lat: 22.956686699448177, lng: 120.47189338091863 } },
            { 'id': '846', 'name': '杉林區', 'center': { lat: 22.997118351680694, lng: 120.56221163452966 } },
            { 'id': '847', 'name': '甲仙區', 'center': { lat: 23.11675071215288, lng: 120.623402484726 } },
            { 'id': '848', 'name': '桃源區', 'center': { lat: 23.1592827, lng: 120.7619485 } },
            { 'id': '849', 'name': '那瑪夏區', 'center': { lat: 23.27006160050007, lng: 120.73084663050003 } },
            { 'id': '851', 'name': '茂林區', 'center': { lat: 22.918796548000074, lng: 120.75933997900006 } },
            { 'id': '852', 'name': '茄萣區', 'center': { lat: 22.873110410500033, lng: 120.19766584150005 } }
        ]
    },
    {
        'id': 'X',
        'name': '澎湖縣',
        'zoom': 10,
        'center': { lat: 23.56548, lng: 119.6151 },
        'districts': [
            { 'id': '880', 'name': '馬公市', 'center': { lat: 23.566159, lng: 119.5765033 } },
            { 'id': '881', 'name': '西嶼鄉', 'center': { lat: 23.601088, lng: 119.504796 } },
            { 'id': '882', 'name': '望安鄉', 'center': { lat: 23.3576577, lng: 119.4987053 } },
            { 'id': '883', 'name': '七美鄉', 'center': { lat: 23.208215900560077, lng: 119.42933911719442 } },
            { 'id': '884', 'name': '白沙鄉', 'center': { lat: 23.666538, lng: 119.5964503 } },
            { 'id': '885', 'name': '湖西鄉', 'center': { lat: 23.5860042, lng: 119.6245327 } }
        ]
    },
    {
        'id': 'W',
        'name': '金門縣',
        'zoom': 12,
        'center': { lat: 24.43679, lng: 118.3186 },
        'districts': [
            { 'id': '890', 'name': '金沙鎮', 'center': { lat: 24.4756078, lng: 118.4078762 } },
            { 'id': '891', 'name': '金湖鎮', 'center': { lat: 24.4408088, lng: 118.4083849 } },
            { 'id': '892', 'name': '金寧鄉', 'center': { lat: 24.45707618340179, lng: 118.32809778784242 } },
            { 'id': '893', 'name': '金城鎮', 'center': { lat: 24.415315683000046, lng: 118.30761053500007 } },
            { 'id': '894', 'name': '烈嶼鄉', 'center': { lat: 24.435179, lng: 118.2373842 } },
            { 'id': '896', 'name': '烏坵鄉', 'center': { lat: 24.9888493, lng: 119.4486538 } }
        ]
    },
    {
        'id': 'T',
        'name': '屏東縣',
        'zoom': 10,
        'center': { lat: 22.54951, lng: 120.62 },
        'districts': [
            { 'id': '900', 'name': '屏東市', 'center': { lat: 22.664829146891215, lng: 120.47996051167766 } },
            { 'id': '901', 'name': '三地門鄉', 'center': { lat: 22.798004754366186, lng: 120.6865301258855 } },
            { 'id': '902', 'name': '霧臺鄉', 'center': { lat: 22.759984623075745, lng: 120.80083677793301 } },
            { 'id': '903', 'name': '瑪家鄉', 'center': { lat: 22.67122999387385, lng: 120.67985574123566 } },
            { 'id': '904', 'name': '九如鄉', 'center': { lat: 22.731755912691046, lng: 120.4843882646556 } },
            { 'id': '905', 'name': '里港鄉', 'center': { lat: 22.798626077343876, lng: 120.5061223430817 } },
            { 'id': '906', 'name': '高樹鄉', 'center': { lat: 22.802505103000044, lng: 120.59637039100005 } },
            { 'id': '907', 'name': '鹽埔鄉', 'center': { lat: 22.742659224613977, lng: 120.56930214272303 } },
            { 'id': '908', 'name': '長治鄉', 'center': { lat: 22.69466399439008, lng: 120.55594125573504 } },
            { 'id': '909', 'name': '麟洛鄉', 'center': { lat: 22.64891997066105, lng: 120.52996485974407 } },
            { 'id': '911', 'name': '竹田鄉', 'center': { lat: 22.57424993150005, lng: 120.52609794750003 } },
            { 'id': '912', 'name': '內埔鄉', 'center': { lat: 22.65124304427774, lng: 120.58881407780962 } },
            { 'id': '913', 'name': '萬丹鄉', 'center': { lat: 22.588252794877132, lng: 120.47704706951625 } },
            { 'id': '920', 'name': '潮州鎮', 'center': { lat: 22.534678610500066, lng: 120.55714171550005 } },
            { 'id': '921', 'name': '泰武鄉', 'center': { lat: 22.604232880008322, lng: 120.69182061282017 } },
            { 'id': '922', 'name': '來義鄉', 'center': { lat: 22.50214022600005, lng: 120.67849129500007 } },
            { 'id': '923', 'name': '萬巒鄉', 'center': { lat: 22.582537130498608, lng: 120.60181806872194 } },
            { 'id': '924', 'name': '崁頂鄉', 'center': { lat: 22.515402971272866, lng: 120.50063897378554 } },
            { 'id': '925', 'name': '新埤鄉', 'center': { lat: 22.48687853421425, lng: 120.5847543836807 } },
            { 'id': '926', 'name': '南州鄉', 'center': { lat: 22.479918619436315, lng: 120.51813202814205 } },
            { 'id': '927', 'name': '林邊鄉', 'center': { lat: 22.440209233000076, lng: 120.51168939150006 } },
            { 'id': '928', 'name': '東港鎮', 'center': { lat: 22.46116096800006, lng: 120.46928097200009 } },
            { 'id': '929', 'name': '琉球鄉', 'center': { lat: 22.34042440357449, lng: 120.37119469976648 } },
            { 'id': '931', 'name': '佳冬鄉', 'center': { lat: 22.429161358028345, lng: 120.54697352816764 } },
            { 'id': '932', 'name': '新園鄉', 'center': { lat: 22.516233256632983, lng: 120.44979295816302 } },
            { 'id': '940', 'name': '枋寮鄉', 'center': { lat: 22.402729209096094, lng: 120.5967145820721 } },
            { 'id': '941', 'name': '枋山鄉', 'center': { lat: 22.24951915050005, lng: 120.66145331250004 } },
            { 'id': '942', 'name': '春日鄉', 'center': { lat: 22.404143802443993, lng: 120.69758308356337 } },
            { 'id': '943', 'name': '獅子鄉', 'center': { lat: 22.260949646811312, lng: 120.73567419533123 } },
            { 'id': '944', 'name': '車城鄉', 'center': { lat: 22.076176324000073, lng: 120.74025603450002 } },
            { 'id': '945', 'name': '牡丹鄉', 'center': { lat: 22.152859649500073, lng: 120.81606654150005 } },
            { 'id': '946', 'name': '恆春鎮', 'center': { lat: 21.98464997510545, lng: 120.76363324881724 } },
            { 'id': '947', 'name': '滿州鄉', 'center': { lat: 22.036023630000045, lng: 120.83637034800006 } }
        ]
    },
    {
        'id': 'V',
        'name': '臺東縣',
        'zoom': 10,
        'center': { lat: 22.98461, lng: 120.9876 },
        'districts': [
            { 'id': '950', 'name': '臺東市', 'center': { lat: 22.75149614700004, lng: 121.11353421250004 } },
            { 'id': '951', 'name': '綠島鄉', 'center': { lat: 22.65977251996399, lng: 121.49032039207492 } },
            { 'id': '952', 'name': '蘭嶼鄉', 'center': { lat: 22.0440905, lng: 121.515303 } },
            { 'id': '953', 'name': '延平鄉', 'center': { lat: 22.903479761015337, lng: 120.98346215695167 } },
            { 'id': '954', 'name': '卑南鄉', 'center': { lat: 22.765045005668053, lng: 121.00170012817496 } },
            { 'id': '955', 'name': '鹿野鄉', 'center': { lat: 22.95197702094025, lng: 121.15721676595662 } },
            { 'id': '956', 'name': '關山鎮', 'center': { lat: 23.05388503700007, lng: 121.17818770350004 } },
            { 'id': '957', 'name': '海端鄉', 'center': { lat: 23.1148572811969, lng: 121.01756025256601 } },
            { 'id': '958', 'name': '池上鄉', 'center': { lat: 23.092793494500057, lng: 121.22017134400005 } },
            { 'id': '959', 'name': '東河鄉', 'center': { lat: 22.979733836143023, lng: 121.25174651437746 } },
            { 'id': '961', 'name': '成功鎮', 'center': { lat: 23.12637402185672, lng: 121.35410153105582 } },
            { 'id': '962', 'name': '長濱鄉', 'center': { lat: 23.32866368100008, lng: 121.42647431450001 } },
            { 'id': '963', 'name': '太麻里鄉', 'center': { lat: 22.579633631000064, lng: 120.98679966800006 } },
            { 'id': '964', 'name': '金峰鄉', 'center': { lat: 22.549728, lng: 120.871138 } },
            { 'id': '965', 'name': '大武鄉', 'center': { lat: 22.377255567500043, lng: 120.90040214800007 } },
            { 'id': '966', 'name': '達仁鄉', 'center': { lat: 22.384670332226182, lng: 120.83524450471383 } }
        ]
    },
    {
        'id': 'U',
        'name': '花蓮縣',
        'zoom': 9,
        'center': { lat: 23.7569, lng: 121.3542 },
        'districts': [
            { 'id': '970', 'name': '花蓮市', 'center': { lat: 23.995041831000066, lng: 121.60274556750005 } },
            { 'id': '971', 'name': '新城鄉', 'center': { lat: 24.040083312000036, lng: 121.618116955 } },
            { 'id': '972', 'name': '秀林鄉', 'center': { lat: 24.123832384722235, lng: 121.48099181411811 } },
            { 'id': '973', 'name': '吉安鄉', 'center': { lat: 23.954824566000042, lng: 121.56194720950003 } },
            { 'id': '974', 'name': '壽豐鄉', 'center': { lat: 23.844961773610006, lng: 121.53451715584443 } },
            { 'id': '975', 'name': '鳳林鎮', 'center': { lat: 23.736447521500054, lng: 121.46517653250004 } },
            { 'id': '976', 'name': '光復鄉', 'center': { lat: 23.648739093000046, lng: 121.42218371200008 } },
            { 'id': '977', 'name': '豐濱鄉', 'center': { lat: 23.58948065700008, lng: 121.49435355300005 } },
            { 'id': '978', 'name': '瑞穗鄉', 'center': { lat: 23.52025734500006, lng: 121.40885262900008 } },
            { 'id': '979', 'name': '萬榮鄉', 'center': { lat: 23.726316875691207, lng: 121.31876198476208 } },
            { 'id': '981', 'name': '玉里鎮', 'center': { lat: 23.37181616725417, lng: 121.36082884591649 } },
            { 'id': '982', 'name': '卓溪鄉', 'center': { lat: 23.379599540000044, lng: 121.16848221850006 } },
            { 'id': '983', 'name': '富里鄉', 'center': { lat: 23.198675820500057, lng: 121.29534394950002 } }
        ]
    }
];
