import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Label } from 'components/form';
import './roleMgmt.sass';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import permissionList from './permissionList.json';


export const EditRole: React.FC<any> = (props: any) => {
    const intl = useIntl();
    const { initData, edit, close, errorMsg } = props;
    const [error, setError] = useState<string>();
    const [role, setRole] = useState({
            name: '',
            permission: {},
        });

    const handleRoleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setError(value === '個案' ? intl.formatMessage({id:'ROLE_NAME_CANT_BE_CASE'}) : '');
        setRole({ name: value, permission: permissionList });
    };

    const handleOk = () => {
        if (role.name === '個案') {
            setError(intl.formatMessage({id:'ROLE_NAME_CANT_BE_CASE'}));
            return;
        }
        edit(role);
    };

    return (
        <Drawer
            className='editRole'
            title={ intl.formatMessage({id: 'ROLE_ADD' }) } // 新增 or 修改 角色
            width={400}
            visible={true}
            maskClosable={true}
            closable={false}
            onClose={close}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                (
                    <>
                        <Button onClick={close} className="button rect" style={{ marginRight: 8 }}>
                            <FormattedMessage id="CANCEL" /> {/*取消*/}
                        </Button>
                        <Button
                            onClick={handleOk}
                            className='button is--primary rect'
                            disabled={role.name.length < 1}
                        >
                            <FormattedMessage id={edit ? 'SAVE' : 'ADD'} /> {/* 儲存 、新增 */}
                        </Button>
                    </>
                )
            }
        >
            <div>
                <h2 className='title'>
                    {
                        intl.formatMessage({id: 'ROLE_NAME'}) // 角色名稱
                    } 
                </h2>
                <Label 
                    required={true} 
                    name={intl.formatMessage({id: 'ROLE_NAME_LABEL'})} // 角色名稱
                />
            <Input
                    value={role.name}
                    onChange={handleRoleNameChange}
                    bordered={false}
                    maxLength={50}
                />
 
                {error ? <span style={{ color: 'var(--color-error)' }}>{error}</span> : null}
                {errorMsg && <span style={{ color: 'var(--color-error)' }}>{errorMsg}</span>}
            </div>
        </Drawer>
    );
};
