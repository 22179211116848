import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { KRAViewWrapper, KRAWrapper } from './index';
import { Row, Col } from 'antd';
import ImageMapping from 'constants/imageMapping';
import { KRAdata } from 'api/record';
import KRABlockView from './KRABlockView';
import { GlobalContext } from '../../../../../../index';

interface WalkComfortablyViewProps {
    kraData: KRAdata[];
    data: {
        fac: string;
        affected: string[];
        affectedOther: string;
        unaffected: string;
        unaffectedOther: string;
    };
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    };
}

const WalkComfortablyViewKRA: React.FC<WalkComfortablyViewProps> = (props: WalkComfortablyViewProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const kraList = props.kraData.map(datum=>(
        <KRABlockView data={datum} classData={props.classData}/>
    ));

    const otherText = props.data.affected.some(d=>d === nameMapping['GAS08']) ? (
        <div>
            {nameMapping['GAS08']} - {props.data.affectedOther.split('\n').map(d=><span>{d}<br/></span>)}
        </div>
    ) : undefined;

    const unaffectedText = props.data.unaffected === nameMapping['UNA01'] ? props.data.unaffected : 
                            props.data.unaffected === nameMapping['UNA02'] ? (nameMapping['UNA02'] as string) + ' - ' + props.data.unaffectedOther : '--';

    return (
        <KRAViewWrapper>
            <div className="header">
                {formatMessage({id:'KRA_WALK_COMFORTABLY'}, {br: ' '})}
            </div>
            <div className="content">
                <div className="icon">
                    <img src={ImageMapping.KRAExamination.KEN02} width='100%' />
                </div>
                
                <KRAWrapper>
                    {kraList}
                </KRAWrapper>

                <div className='label'>
                    <div className="name">{formatMessage({id:'FAC'})}</div>
                    <div className="text large">{props.data.fac || '--'}</div>
                </div>

                <div className='label'>
                    <div className="name mb-5">{formatMessage({id:'GAIT_PATTERN'})}</div>
                
                    <Row gutter={24}>
                        <Col span={12}>
                            <div className='label'>
                                <div className="name">{'● '+formatMessage({id:'AFFECTED_SIDE'})}</div>
                                <div className="text">{props.data.affected.filter(d=>d!==nameMapping['GAS08']).map(d=><div>{d}<br/></div>) || '--'}{otherText}</div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='label'>
                                <div className="name">{'● '+formatMessage({id:'UNAFFECTED_SIDE'})}</div>
                                <div className="text">{unaffectedText}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </KRAViewWrapper>
    )
}

export default WalkComfortablyViewKRA;
