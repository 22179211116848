import { pathOr, compose, filter, forEach } from 'ramda';
import { api } from './api';
import { Organization } from '../containers/organizationMgmt/type';
import { useQuery } from 'hooks/useAxios';
import { QueryVariables } from 'constants/types';
import { toQueryString } from './api'
import { FilterOption } from 'hooks/useFilter';

const apiBase = '/organization';
const settings = {}; // {baseURL : 'http://localhost:3004'};

// GET /organization?name=<string>


export const getOrgList = (query?: FilterOption) => api.get(`${apiBase}?sort=updatedAt&desc=true&${toQueryString(query)}`);

export const getOrgById = (id: number) => api.get(`${apiBase}/${id}`);

export const getOrganization = (queryParams?:{
    pageSize?:number,
    page?:number,
    keyword?:string,
    startDate?:string,
    endDate?:string,
    partOf?:number
}) => {
    const queryString = {
        pageSize: queryParams?.pageSize,
        page: queryParams?.page,
        name: queryParams?.keyword,
        startDate: queryParams?.startDate,
        endDate: queryParams?.endDate,
        partOf: queryParams?.partOf
    }
    return api.get( queryParams 
        ? 
        `${apiBase}?sort=updatedAt&desc=true&${toQueryString(queryString)}` 
        : 
        `${apiBase}?pageSize=-1&sort=updatedAt&desc=true`,
        settings
    );
}


export const getOrganizationByPid = (pid: string) => api.get(`${apiBase}?partOf=${pid}`, settings);

export const addOrganization = (row: Organization) => api.post(`${apiBase}`, row, settings);

export const updateOrganization = (row: Organization) =>
    api.put(`${apiBase}/${row.id}`, row, settings);

export const updateOrganizationActive = (row: Organization) =>
    api.put(`${apiBase}/${row.id}/activate`, row, settings);

export const deleteOrganization = (id: string) => api.delete(`${apiBase}/${id}`, settings);

export const getAllPlan = () => api.get(`/plan?pageSize=-1`, settings);

export const useOrganizations = (variables: QueryVariables = {}) => {
    const { data, ...others } = useQuery<Organization[]>('/organization', {
        ...variables,
    });

    const _data = compose(
        filter((x: Organization) => x.active),
        pathOr([], ['data']),
    )(data);

    const orgById: { [key: string]: Organization } = {};

    forEach((x: Organization) => (orgById[x.id] = x), _data);

    return {
        data,
        dataSource: {
            content: _data,
            total: pathOr(0, ['total'], data),
        },
        orgById,
        ...others,
    };
};
