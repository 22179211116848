import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import ProfileCard from 'components/profileCard/profileCard';
import Label from 'components/label/label';
import Button from 'components/button/button';
import { keeogo_diagram, icon_info_black } from 'components/image';
import { useIntl } from 'react-intl';
import KeeogoDrawer from './KeeogoDrawer';
import DeviceDrawer from './DeviceDrawer';
import ModifiedRecord from '../ModifiedRecord';
import BeltSizeModal from './BeltSizeModal';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .ant-row {
        margin: 20px 0;
    }
`;
interface Props {
    profileData: any;
    deviceData: any;
    refetch: Function;
}

const ProfileTab: React.FC<Props> = ({profileData, deviceData, refetch}: Props) => {

    const { formatMessage } = useIntl();
    const [drawerSetting, setDrawerSetting] = useState({
        showKeeogoDrawer: false,
        showDeviceDrawer: false
    });
    const [showBeltSizeModal, setShowBeltSizeModal] = useState(false);

    useEffect(()=>{
        refetch();
    }, []);

    const allRecordReamrk = (profileData.recordRemark ?? []).concat(deviceData.recordRemark ?? []).sort((a: any, b: any)=>b.updatedAt - a.updatedAt);
    const modifiedHistory = allRecordReamrk.map((record: any)=><ModifiedRecord updatedAt={record.updatedAt} editorName={record.createName} content={record.recordRemark}/>);

    return (
        <Wrapper>
            {drawerSetting.showKeeogoDrawer&&<KeeogoDrawer data={profileData} onClose={()=>setDrawerSetting({...drawerSetting, showKeeogoDrawer: false})} refetch={refetch}/>}
            {drawerSetting.showDeviceDrawer&&<DeviceDrawer data={deviceData} onClose={()=>setDrawerSetting({...drawerSetting, showDeviceDrawer: false})} refetch={refetch}/>}
            {showBeltSizeModal && <BeltSizeModal onClose={()=>setShowBeltSizeModal(false)}/>}

            <ProfileCard onEdit={()=>setDrawerSetting({...drawerSetting, showKeeogoDrawer: true})} title={formatMessage({id:'KEEOGO_PROFILE'})}>
                <Row gutter={24}>
                    <Col span={14}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Label 
                                    name={
                                        <div>
                                            {formatMessage({id:'WAIST_BELT'})}
                                            <Button 
                                                icon={<img src={icon_info_black}/>} 
                                                onClick={()=>setShowBeltSizeModal(true)} 
                                                style={{border:'none'}}
                                            />
                                        </div>
                                    } 
                                    text={profileData.waistBeltCode || '--'}
                                />
                            </Col>
                            <Col span={18}></Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}><Label name={formatMessage({id:'HEIGHT'})} text={`${profileData.height ?? '--'} cm`}/></Col>
                            <Col span={6}><Label name={formatMessage({id:'WEIGHT'})} text={`${profileData.weight ?? '--'} kg`}/></Col>
                            <Col span={12}></Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}><Label name={formatMessage({id:'FEMORAL_LENGTH'})} text={`${profileData.femoralLength ?? '--'} cm`}/></Col>
                            <Col span={6}><Label name={formatMessage({id:'TIBIAL_LENGTH'})} text={`${profileData.tibialLength ?? '--'} cm`}/></Col>
                        </Row>
                        <div className='text--bold text-16 mb-10'>{formatMessage({id:'CONTACT_AREA_SIZE'})}</div>
                        <Row gutter={24}>
                            <Col span={4}><Label name={formatMessage({id:'UPPER_THIGH'})} text={`${profileData.keeogoContactAreaSizeUpperThigh || '--'}`}/></Col>
                            <Col span={4}><Label name={formatMessage({id:'LOWER_THIGH'})} text={`${profileData.keeogoContactAreaSizeLowerThigh || '--'}`}/></Col>
                            <Col span={4}><Label name={formatMessage({id:'TIBIA'})} text={`${profileData.keeogoContactAreaSizeTibia || '--'}`}/></Col>
                            <Col span={12}></Col>
                        </Row>
                        <div className='text--bold text-16 mb-10'>{formatMessage({id:'FEMORAL_SHAFT_LENGTH'})}</div>
                        <Row gutter={24}>
                            <Col span={6}><Label name={<div className='text--bold text-16' style={{color: '#0083C1'}}>{formatMessage({id:'LT'})}</div>} text={profileData.keeogoFemoralShaftLengthLt ? `#${profileData.keeogoFemoralShaftLengthLt}` : '--'}/></Col>
                            <Col span={6}><Label name={<div className='text--bold text-16' style={{color: '#0083C1'}}>{formatMessage({id:'RT'})}</div>} text={profileData.keeogoFemoralShaftLengthRt ? `#${profileData.keeogoFemoralShaftLengthRt}` : '--'}/></Col>
                            <Col span={12}></Col>
                        </Row>
                        <Row>
                            <Label name={formatMessage({id:'OTHERS'})} text={profileData.memo || '--'}/>
                        </Row>
                    </Col>
                    <Col span={10}>
                        <img src={keeogo_diagram} />
                    </Col>
                </Row>
            </ProfileCard>
            <ProfileCard onEdit={()=>setDrawerSetting({...drawerSetting, showDeviceDrawer: true})} title={formatMessage({id:'DEVICE_PROFILE'})}>
                <Row gutter={24}>
                    <Col span={14}>
                        <Label name={formatMessage({id:'REMARK'})} text={deviceData.memo || '--'}/>
                    </Col>
                </Row>
            </ProfileCard>
            <ProfileCard editable={false} title={formatMessage({id:'MODIFIED_HISTORY'})}>
                {modifiedHistory}
            </ProfileCard>
        </Wrapper>
    )
}

export default ProfileTab;
