import { AxiosRequestConfig, AxiosResponse } from 'axios';

export type RequestConfig = AxiosRequestConfig & {
    useCache?: boolean;
};

export type DataSource<T> = {
    content: T[];
    total: number;
};

export type MutationOptions = {
    method?: 'GET' | 'PUT' | 'PATCH' | 'DELETE' | 'POST';
    baseURL?: string;
    onCompleted?: (data?: any) => any;
    onError?: (error?: Error) => any;
    msg?: boolean;
};

export type QueryVariables<T = any> = {
    [key: string]: any;
    skip?: boolean;
    baseURL?: string;
    useCache?: boolean;
    method?: 'GET' | 'PUT' | 'PATCH' | 'DELETE' | 'POST';
    onCompleted?: (data?: AxiosResponse<T>) => any;
    onError?: (error?: Error) => any;
};

export type RefetchQueriesFunction = (...args: any[]) => any;

export type ApiError = {
    detail: string | string[];
    code: number;
    data: string;
};

export type LazyQueryHook<T> = [
    (variables?: { [key: string]: any }) => Promise<void | AxiosResponse<T>>,
    { data: T; loading: boolean; error: ApiError },
];

export type Mutation = [
    boolean,
    (variables?: { [key: string]: any }) => Promise<void | AxiosResponse<any>>,
];

export type ProfileType = {
    personalInfoBasicId:number
    profileName:string
    Par0:string
    Par1:string
    Par2:string
    Par3:string
    Par4:string
    Par5:string
    Par6:string
    Par7:string
    Par8:string
    Par9:string
    Par10:string
    Par11:string
    Par12:string
    Par13:string
    Par14:string
    Par15:string
    Par16:string
    Par17:string
    Par18:string
    Par19:string
    Par20:string
    Par21:string
    Par22:string
    Par23:string
    Par24:string
    Par25:string
    Par26:string
    Par27:string
    Par28:string
    Par29:string
    Par30:string
    Par31:string
    Par32:string
    Par33:string
    Par34:string
    Par35:string
    Par36:string
    Par37:string
    Par38:string
    Par39:string
    Par40:string
    Par41:string
    Par42:string
    Par43:string
    Par44:string
    Par45:string
    Par46:string
    Par47:string
    Par48:string
    Par49:string
    Par50:string
    Par51:string
    Par52:string
    Par53:string
    Par54:string
    Par55:string
    Par56:string
    Par57:string
    Par58:string
    Par59:string
    Par60:string
    Par61:string
    Par62:string
    Par63:string
    Par64:string
    Par65:string
    Par66:string
    invalid:boolean
    memo:string
}

export const OrgType = {
    'sys':'level1',
    '系統':'level1',
    
    'prov':'level2',
    '醫療機構':'level2',

    'bus':'level3',
    '企業':'level3'
}

export type PermissionType = {
    accountMgmt?:any
    caseMgmt?:any
    organizationMgmt?:any
    roleMgmt?:any
}

export type RoleType = {
    amount: number
    createdAt: number
    description: string
    id: number
    name: string
    permission:PermissionType
    updatedAt:number
}

export interface OpenFhirOrganization {
    id: number;
    openFhirOrganization: {
        key: number;
        organization: string;
    }
}
