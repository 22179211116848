import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { menu_account, menu_authority, menu_category, menu_device, menu_therapy, menu_dashboard, menu_organization, menu_schedul, menu_therapy_reverse, menu_venue } from 'components/image';
import './navigation.sass';
import { find, propEq } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { GlobalContext } from '../../index';

/**
 * 
 * KEEOGO NAVGATORS
 *
 * 組織管理
 * 權限管理
 * 參數管理
 * 帳號管理
 * 個案管理
 *  個案列表、個案檢視、新增個案、個案編輯
 *  參數清單
 *  參數設定
 * 
 */

const menuItemAll = [
    // {
    //     id: 'Dashboard',
    //     name: <FormattedMessage id='NAV_DASHBOARD' />, // Dashboard
    //     icon: menu_dashboard,
    //     href: '/Dashboard',
    //     class: '',
    //     order: 1
    // },
    {
        id: 'Patient',
        name: <FormattedMessage id='NAV_CASEMGMT' />, // 個案管理 ; User
        icon: menu_account,
        href: '/Patient',
        class: '',
        order: 2
    },
    {
        id: 'Therapy',
        name: <FormattedMessage id='NAV_THERAPY' />, // Therapy
        icon: menu_therapy,
        href: '/Therapy',
        class: '',
        order: 3
    },
    {
        id: 'Account',
        name: <FormattedMessage id='NAV_ACCOUNTMGMT' />, // 帳號管理 ; Account
        icon: menu_category,
        href: '/Account',
        class: '',
        order: 4
    },
    {
        id: 'Access',
        name: <FormattedMessage id='NAV_ROLEMGMT' />, // 權限管理 ; Role
        icon: menu_authority,
        href: '/Access',
        class: '',
        order: 5
    },
    {
        id: 'Group',
        name: <FormattedMessage id='NAV_ORGANIZATIONMGMT' />, // 組織管理 ; Group
        icon: menu_organization,
        href: '/Group',
        class: '',
        order: 6
    },
    {
        id: 'Venue',
        name: <FormattedMessage id='NAV_VENUE' />, // 設備管理 ; Venue
        icon: menu_venue,
        href: '/Venue',
        class: '',
        order: 7
    }
];
/**
 * how about caculate menu by router guard 
 */
export const Navigation: React.FC<any> = (prop: any) => {

    const { menuCtx, setMenuCtx } = useContext(GlobalContext);

    const [menuItem, setMenuItem] = useState<any>([]);
    const { permission } = prop;

    useEffect(() => {
        setMenu();
    }, [permission]);


    const setMenu = () => {
        const permissionMenu = Object.keys(permission || {}).filter((item: any) => /*Object.keys(permission[item]).length > 0*/permission[item]);
        const menuItem = permissionMenu.map((item: string) => find(propEq('id', item))(menuItemAll)).filter((i: any) => i).sort((a: any, b: any) => a.order - b.order);

        setMenuItem(menuItem)
        setMenuCtx(menuItem)
    }

    return (
        !menuItem.length
            ?
            null
            :
            <Layout className='nav'>
                <ul className='drop-down-menu'>
                    {menuItem.map((x: any) => (
                        <li
                            key={x.id}
                            className={x.class +
                                (new RegExp(x.href, 'g').test(window.location.pathname)
                                    ?
                                    ' selected'
                                    :
                                    '')
                            }
                        >
                            <Link to={{ pathname: x.href, state: { prevPath: window.location.pathname } }}>
                                <img src={x.icon} className={`menuIcon${x.id === 'Therapy' ? ' therapy' : ''}`} alt='' />
                                {x.id === 'Therapy' && <img src={menu_therapy_reverse} className='menuIcon therapy-reverse' />}
                                {x.name}
                            </Link>
                            {
                                x.subMenu &&
                                <ul>
                                    {
                                        x.subMenu.map((y: any) => (
                                            <li
                                                key={y.id}
                                                className={
                                                    menuItemAll.some((x: any) => new RegExp(x.href, 'g').test(window.location.pathname))
                                                        ?
                                                        'selected'
                                                        :
                                                        ''
                                                }
                                            >
                                                <Link to={{
                                                    pathname: y.href,
                                                    state: { prevPath: window.location.pathname }
                                                }} />
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                        </li>
                    ))}
                </ul>
            </Layout>
    );
};
