import React, { useState, useEffect } from 'react';
import EvaluationList from './EvaluationList';
import { EvaluationBrief } from './type';

interface Props {
    data: EvaluationBrief[];
    refetch: (params?: {id?: number, page?: number, pageSize?: number}) => void;
    caseId: number;
    userInfoData: any;
    treatmentData: any;
    fetchUserInfoData: (id?: number) => void;
    fetchTreatment: (params?: { id?: number; inFinish?: boolean }) => void;
    evaluationDataTotal: number;
}

export interface PageProps extends Props {
    setPageContent: React.Dispatch<React.SetStateAction<JSX.Element>>;
}

const EvaluationTab: React.FC<Props> = (props: Props) => {

    const [pageContent, setPageContent] = useState<JSX.Element>(<div></div>);

    useEffect(()=>{
        props.fetchUserInfoData();
        props.fetchTreatment();
    }, []);

    useEffect(()=>{
        setPageContent(<EvaluationList {...props} setPageContent={setPageContent} />);
    }, [props.data]);

    return withProps(pageContent, {...props, setPageContent});
}

export default EvaluationTab;

const withProps = (Component: JSX.Element, props: PageProps) => {
    return React.cloneElement(Component, {...props});
}
