// tslint:disable no-duplicate-imports
import moment from 'moment';
import { MutationOptions } from 'constants/types';
import { compose, map, pathOr, hasPath, join } from 'ramda';
import { api, toQueryString } from './api';
import React from 'react';
import useAxios, { useQuery, useLazyQuery } from 'hooks/useAxios';
import { JSON_SERVER_URL } from 'constants/config';
import { PersonalCaseInfo, FamilyHistoryInfo, CaseTypeInfo , UserInfo } from '../containers/caseMgmt/caseMgmtType';
import { QueryVariables, Mutation, ProfileType, OpenFhirOrganization } from 'constants/types';
import { useCaseTypes } from './caseTypes';
import { Case, CaseProfile } from 'types/Case';
import { Response, ListResponse } from 'types/Api';

const apiBase = '/case';
const local = {
    /* baseURL: JSON_SERVER_URL */
};

export const getTherapy  = ({
    id,page,pageSize,...param
}:any = {}) => api.get<ListResponse<Case>>(`/keeogo/therapy?${toQueryString({page,pageSize,...param, sort: param.sort ?? 'treatmentDate', desc: param.desc ?? true, multilingualKey:localStorage.getItem('lang')||'EN'})}`, local);
export const getUser  = ({
    id,page,pageSize,...param
}:any = {}) => api.get(`/keeogo/personal_info_basic${id ? '/'+id : ''}?${toQueryString({page,pageSize,...param,multilingualKey:localStorage.getItem('lang')||'EN'})}`, local);
export const getCase  = ({
    id,page,pageSize,...param
}:any = {}) => api.get<ListResponse<Case>>(`/keeogo/personal_info_basic?${toQueryString({page,pageSize,...param, sort: param.sort ?? 'updatedAt', desc: param.desc ?? true, multilingualKey:localStorage.getItem('lang')||'EN'})}`, local);
export const getProfileListById = ({
    id,page,pageSize
}:any = {}) => api.get(`/keeogo/personal_profile_data/${id}?${toQueryString({page,pageSize})}`, local);
export const putUser = (payload: any) => api.put(`/keeogo/personal_info_basic/insert`, payload, local);

// keeogo user profile & data
export const importUser = (payload: FormData , config:any={}) => api.post(`/keeogo/import`, payload, config);
export const exportUser = (payload:{userId:string}, config:any={}) => api.post(`/keeogo/export`, payload, config);
export const importProfile = (payload:FormData, config:any={}) => api.post('/keeogo/personal_info_basic/import', payload, config);
export const exportProfile = (payload:{userId:string,profileName:string[]}, config:any={}) => api.post('/keeogo/personal_info_basic/export', payload, config);
export const putProfile = (payload:ProfileType) => api.put('/keeogo/personal_profile_data/insert', payload, local);
export const deleteProfile = (payload:{personalInfoBasicId:number,profileName:string}) => api.delete(`/keeogo/personal_profile_data/delete?${toQueryString(payload)}`);

// keeogo user cross-organization
export const addCrossOrganization = (orgs: OpenFhirOrganization, config:any={}) => api.put(`/keeogo/personal_info_basic/add-organization`, orgs, config);
export const deleteCrossOrganization = (org: {id: number; organization: string;}) => api.delete(`/keeogo/personal_info_basic/delete-organization`, {data: org});
export const getCrossOrganizationByCase = ({id, page, pageSize}: {id: number; page: number; pageSize: number;}) => api.get(`/keeogo/personal_info_basic/list-organization?${toQueryString({id, page, pageSize})}`);


export const getAllCase = (row: any) => api.get(`${apiBase}?${toQueryString(row)}`, local);
export const addCaseItem = (row: PersonalCaseInfo) => api.post(`${apiBase}`, row, local);
export const updateCaseItem = (row: PersonalCaseInfo) => api.put(`${apiBase}/${row.id}`, row, local);
export const getCaseById = (username: string) => api.get(`${apiBase}?username=${username}`, local);
export const getCaseType = () => api.get(`/caseType`, local);

export const useCreateCase = (options: MutationOptions = {}) => {
    const [, request] = useAxios(options);

    return (data: Partial<PersonalCaseInfo>) =>
        request('/cases', {
            data,
            method: 'POST',
            baseURL: JSON_SERVER_URL,
        });
};

export const useUpdateCase = (options: MutationOptions = {}) => {
    const [, request] = useAxios(options);

    return (data: Partial<PersonalCaseInfo>) =>
        request(`/cases/${data.username}`, {
            data,
            method: 'PATCH',
            baseURL: JSON_SERVER_URL,
        });
};

/**  deprecated */
export const useQueryCase = () => {
    const [data, setData] = React.useState<PersonalCaseInfo | undefined>();
    const [loading, request] = useAxios();

    const queryCase = async (id: React.Key) => {
        const response = await request<PersonalCaseInfo>(`/case/${id}`);

        if (!hasPath(['data'])(response)) {
            return;
        }

        setData({
            ...response.data,
            birthDate: moment(response.data.birthDate),
            updateAt: moment(response.data.updateAt),
            telecomPhone: (response.data.telecomPhone || '').split('#')[0],
            extension: (response.data.telecomPhone || '').split('#')[1],
            familyHistory: compose(
                map((x: FamilyHistoryInfo) => ({
                    ...x,
                    liveWithFamily:
                        typeof x.liveWithFamily === 'string'
                            ? x.liveWithFamily === 'true'
                            : x.liveWithFamily,
                    birthDate: moment(x.birthDate),
                })),
                pathOr([], ['data', 'familyHistory']),
            )(response),
            caseTypeList: compose(
                map((x: CaseTypeInfo) => ({
                    ...x,
                    receiveDate: moment(x.receiveDate),
                })),
                pathOr([], ['data', 'caseTypeList']),
            )(response),
            // role: pathOr<any[]>([], ['data', 'role'], response).map(roleId =>
            //     pathOr(roleId, [roleId], roleById),
            // ),
        } as any);
    };

    return { loading, queryCase, refetch: queryCase, caseItem: data };
};

export const useCases = ({ page = 1, pageSize = 10, ...variables }: QueryVariables = {}) => {
    const { data = [], ...others } = useQuery<PersonalCaseInfo[]>('/case', {
        levelOnly: true,
        page,
        pageSize,
        ...variables,
    });

    const { caseTypeById } = useCaseTypes();

    return {
        dataSource: {
            content: compose(
                map((x: PersonalCaseInfo) => ({
                    ...x,
                    caseTypeStr: compose(
                        join('、'),
                        map((c: CaseTypeInfo) => pathOr('', [c.caseType as any, 'name'], caseTypeById)),
                        pathOr([], ['caseTypeList']),
                    )(x),
                })),
                pathOr([], ['data']),
            )(data),
            total: +pathOr(0, ['total'])(data),
        },
        ...others,
    };
};

export const useExportCases = (variables: QueryVariables = {}) => {
    const [exportCases, { loading }] = useLazyQuery('/case/export', {
        method: 'POST',
        ...variables,
    });

    return [loading, exportCases] as Mutation;
};

export const deleteCase = (id: any) => api.delete(`/case/${id}`);

export const updateCase = (data: any) => api.put('/keeogo/personal_info_basic/update', data);

export const addCase = (data: any) => api.post('/keeogo/personal_info_basic/insert', data);

export const getCaseProfile = (id: number) => api.get<Response<CaseProfile>>(`/keeogo/personal_info_basic/get-profile?${toQueryString({id, multilingualKey: localStorage.getItem('lang')||'EN'})}`);

export const editCaseProfile = (data: any) => api.post('keeogo/personal_info_basic/insert-profile', data);

export const getCaseDevice = (id: number) => api.get(`/keeogo/personal_info_basic/get-device?${toQueryString({id, multilingualKey: localStorage.getItem('lang') || 'EN'})}`);

export const editCaseDevice = (data: any) => api.post('/keeogo/personal_info_basic/insert-device', data);

export const getTreatment = (id: number, inFinish?: boolean,desc?:boolean) => api.get(`/keeogo/personal_info_basic/treatment?${toQueryString({personalInfoBasicId: id, inFinish,desc, multilingualKey: localStorage.getItem('lang')||'EN'})}`);

interface TreatmentDetail {
    treatmentName: string;
    treatmentDate: string;
    treatmentHour: string;
    planTimeId: number;
    planTimeMomo: string;
    trainingId: number;
    abbreviation: string;
    treatmentDetailStatusCode: string;
    classNum: string;
}

export const getTreatmentDetail = (id: number) => api.get<Response<TreatmentDetail>>(`/keeogo/personal_info_basic/treatment/plan-time?${toQueryString({planTimeId: id,  multilingualKey: localStorage.getItem('lang')||'EN'})}`);

export const addPlan = (data: any) => api.post('keeogo/personal_info_basic/treatment/create', data);

export const addTreatment = (data: any) => api.post('keeogo/personal_info_basic/treatment/class', data);

export const addFinishRemark = (data: any) => api.post('keeogo/personal_info_basic/treatment/finish-remark', data);

export const checkIfFirstTreatment = (data: any) => api.post('keeogo/personal_info_basic/treatment/check-first', data);

export const cancelTreatment = (id: number) => api.put('keeogo/personal_info_basic/treatment/update', {planDetailId: id, treatmentDetailStatusCode: 'TDS04', device: 'web'});

export const editTreatment = (data: any) => api.put('keeogo/personal_info_basic/treatment/update', data);

export interface DropDownOption {
    id: number;
    createdAt: number;
    updatedAt: number;
    optionType: string;
    name: string;
    multilingualKey: string;
    code: string;
    comment: string;
    active: true;
    order: string;
}

interface DropDownOptionRes {
    noFind: string[];
    data: DropDownOption[][];
}

export const getDropDownOption = ({
    multilingualKey, 
    optionType
}: {
    multilingualKey: string, 
    optionType?: string[]
}) => api.post<Response<DropDownOptionRes>>('/keeogo/drop_down_options/list', {multilingualKey, optionType});

