// tslint:disable no-implicit-dependencies no-shadowed-variable
import React from 'react';
import { AxiosResponse, AxiosRequestConfig, AxiosPromise } from 'axios';
import { hasPath, pathOr } from 'ramda';
import {
    MutationOptions,
    QueryVariables,
    RequestConfig,
    ApiError,
    LazyQueryHook,
} from 'constants/types';
import { error } from 'utils/message';
import { api, replaceUrl, toQueryString } from '../api/api';

type MakeAxiosRequestProps = AxiosRequestConfig & {
    useCache?: boolean;
    onCompleted?: (data?: any) => any;
    onError?: (error?: Error) => any;
    msg?: boolean;
};

const keyValueCache: { [key: string]: any } = {};

function makeAxiosRequest({
    url = '',
    onCompleted,
    onError,
    msg = true,
    ...options
}: MakeAxiosRequestProps) {
    return api({ method: 'GET', url, ...options })
        .then(response => {
            if (options?.useCache) {
                keyValueCache[url] = response;
            }

            if (!options?.useCache && onCompleted) {
                onCompleted(response);
            }

            return response;
        })
        .catch(err => {
            if (onError) {
                onError(err);
            }

            return err;
        });
}

export function useMeta() {
    const [loading, setIsLoading] = React.useState(false);

    const withMeta = React.useCallback(<T = any>(handler: () => Promise<AxiosResponse<T>>) => {
        setIsLoading(true);

        return handler().then((response: any) => {
            setIsLoading(false);

            if (response.status !== 200) {
                return;
            }

            if (hasPath(['data', 'data', 'data'], response)) {
                return response.data.data;
            }

            if (hasPath(['data', 'data'], response)) {
                return response.data;
            }

            return response;
        });
    }, []);

    return [loading, withMeta] as [boolean, <T>(handler: () => any) => AxiosPromise<T>];
}

export function useAxios({ onCompleted, ...others }: MutationOptions = {}) {
    const [loading, withMeta] = useMeta();

    const makeRequest = (url: string, options: RequestConfig = {}) => {
        return makeAxiosRequest({
            url,
            onCompleted,
            ...others,
            ...options,
        });
    };

    return [
        loading,
        <T = any>(url: string, options: RequestConfig = {}) =>
            withMeta<T>(() => {
                // 如果打 API 時有指定要用 cache. TODO: add cache timeout
                if (options.useCache) {
                    if (keyValueCache[url] && keyValueCache[url].resolve) {
                        return keyValueCache[url];
                    }

                    if (!keyValueCache[url]) {
                        keyValueCache[url] = makeRequest(url, options);

                        return keyValueCache[url];
                    }

                    if (keyValueCache[url] && keyValueCache[url].success && onCompleted) {
                        onCompleted(keyValueCache[url]);
                    }

                    return new Promise(resolve => resolve(keyValueCache[url]));
                }

                return makeRequest(url, options);
            }),
    ] as [boolean, <T>(url: string, options?: AxiosRequestConfig) => Promise<AxiosResponse<T>>];
}

/**
 * 跟直接使用 useAxios 效果類似，但會將 API 錯誤和成功的資料用 state 儲存起來
 * @param {String} endpoint api endpoint
 * @param {Object} options Axios request config object
 * @return {Array} handler function, api response and meta state
 */
export function useMutation<T = any>(
    endpoint: string,
    { onCompleted, onError, ...options }: MutationOptions = {},
) {
    const [data, setData] = React.useState();
    const [error, setError] = React.useState<ApiError>();

    const [loading, request] = useAxios({ onCompleted, onError });

    const handler = (data: Partial<T>) =>
        request<T>(replaceUrl(endpoint, data), { data, ...options })
            .then(response => {
                if (response.status !== 200) {
                    setError(response.data as any);
                }

                if (response.status === 200) {
                    setData(response.data as any);
                }
                return response;
            })
            .catch(error => setError(error));

    return [
        handler,
        {
            error,
            loading,
            data,
        },
    ] as [
        (data: Partial<T>, options?: RequestConfig) => Promise<AxiosResponse<T>>,
        {
            error: Error | undefined;
            data: AxiosResponse<T> | undefined;
            loading: boolean;
        },
    ];
}

export function useQuery<T = any>(
    endpoint: string,
    {
        skip,
        baseURL,
        method = 'GET',
        useCache = false,
        onCompleted,
        onError,
        msg = true,
        ...variables
    }: QueryVariables = {},
) {
    const [data, setData] = React.useState<T>();
    const [error, setError] = React.useState<ApiError>();

    const [loading, request] = useAxios({ onCompleted, onError, msg });

    const config = Object.entries({
        useCache,
        method,
        baseURL,
    }).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});

    const refetch = React.useCallback(
        (_variables: { [key: string]: any } = {}) => {
            const params = { ...variables, ..._variables };
            return request<T>(
                `${replaceUrl(endpoint, params)}?${toQueryString(method === 'GET' ? params : {})}`,
                {
                    ...config,
                    data: params,
                },
            )
                .then(response => {
                    if (response.status !== 200 || !(response as any).success) {
                        setError(response as any);
                    }

                    if (response.status === 200 || (response as any).success) {
                        setData(response as any);
                    }

                    return response;
                })
                .catch(error => setError(error));
        },
        [endpoint], // eslint-disable-line
    );

    React.useEffect(() => {
        if (!skip) {
            refetch(variables);
        }
    }, [skip, JSON.stringify(variables)]); // eslint-disable-line

    return {
        refetch,
        data,
        loading,
        error,
    };
}

export function useLazyQuery<T = any>(
    endpoint: string,
    {
        skip,
        baseURL,
        method = 'GET',
        useCache = false,
        onCompleted,
        onError,
        ...variables
    }: QueryVariables = {},
) {
    const [data, setData] = React.useState<T>();
    const [error, setError] = React.useState<ApiError>();

    const [loading, request] = useAxios({ onCompleted, onError });

    const config = Object.entries({
        useCache,
        method,
        baseURL,
    }).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});

    const refetch = React.useCallback(
        (_variables: { [key: string]: any } = {}) => {
            const params = { ...variables, ..._variables };

            return request<T>(
                `${replaceUrl(endpoint, params)}?${toQueryString(method === 'GET' ? params : {})}`,
                {
                    ...config,
                    data: params,
                },
            )
                .then(response => {
                    if (response.status !== 200 || !(response as any).success) {
                        setError(response as any);
                    }

                    if (response.status === 200 || (response as any).success) {
                        setData(response as any);
                    }

                    return response;
                })
                .catch(error => setError(error));
        },
        [endpoint], // eslint-disable-line
    );

    return [
        refetch,
        {
            data,
            loading,
            error,
        },
    ] as LazyQueryHook<T>;
}

export default useAxios;
