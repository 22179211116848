import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Radio, Divider } from 'antd';
import { GlobalContext } from '../../../../../index';
import { Form, Input } from 'components/form';
import { FormTitle } from './EvaluationDrawer';
import { MovementAnalysisData, KEMovementAnalysisData } from '../type';
import SitToStandEdit from '../TrainingItemComponents/SitToStandEdit';
import GaitAnalysisEdit from '../TrainingItemComponents/GaitAnalysisEdit';
import StairAscentEdit from '../TrainingItemComponents/StairAscentEdit';
import StairDescentEdit from '../TrainingItemComponents/StairDescentEdit';
import SquattingEdit from '../TrainingItemComponents/SquattingEdit';
import SingleLegStanceEdit from '../TrainingItemComponents/SingleLegStanceEdit';

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 3vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

const StyledInput = styled(Input)`
    background-color: #F3F3F3;
    border: none;
`;

interface MovementAnalysis extends MovementAnalysisData, KEMovementAnalysisData {}

interface Props {
    data: MovementAnalysis;
}

const MovementAnalysis: React.FC<Props> = ({ data }: Props) => {
    
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const { formatMessage } = useIntl();

    const AssistiveDevice = getOptions('AssistiveDevice').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    return (
        <div>
            <div className='text-24 text--bold mb-15'>{formatMessage({id:'MOVEMENT_ANALYSIS'})}</div>

            <div className='d-flex v--bottom'>
                <Form.Item label={<FormTitle>{formatMessage({id:'ASSISTIVE_DEVICE'})}</FormTitle>} name="makAssistiveDeviceCode">
                    <Radio.Group buttonStyle="solid">
                        {AssistiveDevice}
                    </Radio.Group>
                </Form.Item>

                {data.makAssistiveDeviceCode==='ASS04'&&
                <Form.Item name="makAssistiveDeviceOther" className='ml-5'>
                        <StyledInput placeholder='Other' maxLength={500}/>
                </Form.Item>}
            </div>


            <Divider />

            <SitToStandEdit/>

            <Divider />

            <GaitAnalysisEdit data={data}/>

            <Divider />

            <StairAscentEdit/>

            <Divider />
            
            <StairDescentEdit/>

            <Divider />

            <SquattingEdit data={data}/>

            <Divider />

            <SingleLegStanceEdit data={data}/>
        </div>
    );
};

export default MovementAnalysis;
