import React from 'react';
import Button from 'components/button/button';
import { CalendarOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import moment from 'moment';

interface Props {
    setDate: Function;
}

const TodayButton: React.FC<Props> = ({setDate}: Props) => {

    const { formatMessage } = useIntl();

    return (
        <Button
            icon={<CalendarOutlined/>}
            type='link'
            onClick={()=>setDate(moment())}
            style={{color: '#2E2E2E'}}
        >
            {formatMessage({id:'TODAY'})}
        </Button>
    )
}

export default TodayButton;
