import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import PackagePreviewCard from './PackagePreviewCard';
import { GlobalContext } from '../../../../../index';
import { getPackageList, TrainingPackage } from 'api/summary';

interface PackageSelectProps {
    caseId: number;
    treatmentType: string;
    onClose: () => void;
    onOk: (packages: TrainingPackage[]) => void;
    selectedPackage: number[];
}

const PackageSelectDrawer: React.FC<PackageSelectProps> = (props: PackageSelectProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const [packageData, setPackageData] = useState<TrainingPackage[]>([]);
    const [selectedPackage, setSelectedPackage] = useState<number[]>(props.selectedPackage);

    useEffect(()=>{
        getPackageList({
            personalInfoBasicId: props.caseId,
            treatmentTypeCode: props.treatmentType
        }).then(res=>{
            setPackageData(res.data.data);
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }, []);

    const handleOK = () => {
        props.onOk(packageData.filter(d=>selectedPackage.some(p=>p===d.id)));
        props.onClose();
    }

    const handlePackageSelect = (packageId: number) => {
        if(selectedPackage.some(d=>d===packageId)){
            let newVal = selectedPackage.slice();
            newVal.splice(selectedPackage.indexOf(packageId), 1);
            setSelectedPackage(newVal);
        }
        else{
            setSelectedPackage(selectedPackage.concat([packageId]));
        }
    }

    const packages = packageData.map(d=>(
        <PackagePreviewCard 
            packageData={d} 
            checked={selectedPackage.some(p=>p===d.id)} 
            onSelect={()=>handlePackageSelect(d.id)} 
            style={(selectedPackage.length >= 3 && !selectedPackage.some(p=>p===d.id)) ? {
                pointerEvents: 'none',
                opacity: 0.4
            } : undefined}
        />
    ));

    return (
        <Drawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='30vw'
            title={formatMessage({id:'SELECT_PACKAGE'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect" onClick={handleOK}>
                        {formatMessage({id:'OK'})}
                    </Button>
                </div>
            }
        >
            <div className='d-flex is--column'>
                <div style={{color: '#5DD197', fontSize: '16px'}}>{formatMessage({id:'COMPLETED_PACKAGES'})}:</div>
                <div className='d-flex is--column'>
                    {packages}
                </div>
            </div>
        </Drawer>
    )
}

export default PackageSelectDrawer;
