import React, { useState, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Form, TextArea, Input } from 'components/form';
import { Divider, Radio, Row, Col } from 'antd';
import { GlobalContext } from '../../../../../index';
import { FormTitle } from './EvaluationDrawer';
import { GeneralConditionData } from '../type';

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 1.2vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

const StyledTextarea = styled(TextArea)`
    &.ant-input {
        resize: none !important;
        background-color: #F3F3F3;
        border: none;
    }
`;

const StyledInput = styled(Input)`
    background-color: #F3F3F3;
    border: none;
`;

interface Props {
    data: GeneralConditionData;
}

const GeneralCondition: React.FC<Props> = ({ data }: Props) => {

    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;

    const { formatMessage } = useIntl();

    const MentalStatusOptions = getOptions('MentalStatus').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));
    const ConditionStatus01 = getOptions('ConditionStatus01').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    const CommunicationStatus = getOptions('CommunicationStatus').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));
    const VisionStatus = getOptions('VisionStatus').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));
    const HearingStatus = getOptions('HearingStatus').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));
    const Location = getOptions('Location').map((d: any) => <StyledRadio value={d.code}>{d.name}</StyledRadio>);
    const ProprioceptionTouch = getOptions('ProprioceptionTouch').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));
    const LRBoth = getOptions('LRBoth').map((d: any) => <StyledRadio value={d.code}>{d.name}</StyledRadio>);

    return (
        <div>
            <div className='text-24 text--bold'>
                {formatMessage({ id: 'GENERAL_CONDITION' })}
            </div>

            <Form.Item label={<FormTitle>{formatMessage({ id: 'MENTAL_STATUS' })}</FormTitle>} name="gcMentalStatusCode">
                <Radio.Group buttonStyle="solid">{MentalStatusOptions}</Radio.Group>
            </Form.Item>

            <Form.Item label={<FormTitle>{formatMessage({ id: 'MOTIVATION' })}</FormTitle>} name="gcMotivationCode">
                <Radio.Group buttonStyle="solid">{ConditionStatus01}</Radio.Group>
            </Form.Item>

            <Form.Item label={<FormTitle>{formatMessage({ id: 'ATTENTION' })}</FormTitle>} name="gcAttentionCode">
                <Radio.Group buttonStyle="solid">{ConditionStatus01}</Radio.Group>
            </Form.Item>

            <Form.Item label={<FormTitle>{formatMessage({ id: 'COMMUNICATION' })}</FormTitle>} name="gcCommunicationCode">
                <Radio.Group buttonStyle="solid">
                    <div className='d-flex is--column'>
                        <span className='d-flex mt-5 mb-5'>{CommunicationStatus.slice(0, 4)}</span>
                        <span className='d-flex mt-5 mb-5'>{CommunicationStatus.slice(4, 7)}</span>
                        <span className='d-flex mt-5 mb-5'>{CommunicationStatus.slice(7)}</span>
                    </div>
                </Radio.Group>
            </Form.Item>

            {data.gcCommunicationCode==='COM09'&&<Form.Item name="gcCommunicationOther">
                <StyledTextarea
                    rows={3}
                    placeholder="(Other)"
                    maxLength={500}
                />
            </Form.Item>}

            <Form.Item label={<FormTitle>{formatMessage({ id: 'VISION' })}</FormTitle>} name="gcVisionCode">
                <Radio.Group buttonStyle="solid">
                    {VisionStatus}
                </Radio.Group>
            </Form.Item>

            {data.gcVisionCode==='VIS02' && 
            <Form.Item name="gcVisionNeglectCode">
                <Radio.Group buttonStyle="solid">
                    {LRBoth}
                </Radio.Group>
            </Form.Item>}

            <div className='d-flex'>
                {data.gcVisionCode==='VIS04' && 
                <Form.Item name="gcVisionOther">
                    <StyledInput placeholder="(Other)" maxLength={500}/>
                </Form.Item>}
            </div>

            <Form.Item label={<FormTitle>{formatMessage({ id: 'HEARING' })}</FormTitle>} name="gcHearingCode">
                <Radio.Group buttonStyle="solid">
                    {HearingStatus}
                </Radio.Group>
            </Form.Item>

            {(data.gcHearingCode==='HEA02' || data.gcHearingCode==='HEA03' || data.gcHearingCode==='HEA04') && 
            <Form.Item name="gcHearingImpairedHardHearingDeafCode">
                <Radio.Group defaultValue="LRB01" buttonStyle="solid">
                    {LRBoth}
                </Radio.Group>
            </Form.Item>}

            <div className='d-flex v--bottom'>
                <Form.Item label={<FormTitle>{formatMessage({ id: 'PAIN' })}</FormTitle>} name="gcPainCode">
                    <Radio.Group buttonStyle="solid">
                        {Location}
                    </Radio.Group>
                </Form.Item>

                {data.gcPainCode==='LOC02' && 
                <Form.Item name="gcPainLocationText" className='ml-5'>
                    <StyledInput placeholder='Location' maxLength={500} />
                </Form.Item>}

                <Form.Item name="gcPainVasText" className='ml-5'>
                    <StyledInput placeholder="VAS" maxLength={500} />
                </Form.Item>
            </div>

            <div className='d-flex v--bottom'>
                <Form.Item label={<FormTitle>{formatMessage({ id: 'SWELLING' })}</FormTitle>} name="gcSwellingCode">
                    <Radio.Group buttonStyle="solid">
                        {Location}
                    </Radio.Group>
                </Form.Item>

                {data.gcSwellingCode==='LOC02' && 
                <Form.Item name="gcSwellingLocationText">
                    <StyledInput placeholder='Location' maxLength={500} />
                </Form.Item>}
            </div>

            <FormTitle className='mb-10'>{formatMessage({ id: 'SENSATION' })}</FormTitle>
            <Row gutter={24}>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'LT'})}</div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'RT'})}</div>
                </Col>
            </Row>
            <Row gutter={24}><Col className='mt-10 mb-10 text-20 text--bold'>{formatMessage({ id: 'PROPRIOCEPTION' })}</Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="gcSensationLtProprioceptionCode">
                        <Radio.Group buttonStyle="solid">
                            {ProprioceptionTouch}
                        </Radio.Group>
                    </Form.Item>

                    {(data.gcSensationLtProprioceptionCode==='PRO02' || data.gcSensationLtProprioceptionCode==='PRO03') &&
                    <Form.Item name="gcSensationLtProprioceptionText">
                        <StyledInput placeholder='(Note)' maxLength={500}/>
                    </Form.Item>}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="gcSensationRtProprioceptionCode">
                        <Radio.Group buttonStyle="solid">
                            {ProprioceptionTouch}
                        </Radio.Group>
                    </Form.Item>

                    {(data.gcSensationRtProprioceptionCode==='PRO02' || data.gcSensationRtProprioceptionCode==='PRO03') &&
                    <Form.Item name="gcSensationRtProprioceptionText">
                        <StyledInput placeholder='(Note)' maxLength={500}/>
                    </Form.Item>}
                </Col>
            </Row>
            <Row gutter={24}><Col className='mt-10 mb-10 text-20 text--bold'>{formatMessage({ id: 'LIGHT_TOUCH' })}</Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="gcSensationLtLightTouchCode">
                        <Radio.Group buttonStyle="solid">
                            {ProprioceptionTouch}
                        </Radio.Group>
                    </Form.Item>

                    {(data.gcSensationLtLightTouchCode==='PRO02' || data.gcSensationLtLightTouchCode==='PRO03') &&
                    <Form.Item name="gcSensationLtLightTouchCodeText">
                        <StyledInput placeholder='(Note)' maxLength={500}/>
                    </Form.Item>}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="gcSensationRtLightTouchCode">
                        <Radio.Group buttonStyle="solid">
                            {ProprioceptionTouch}
                        </Radio.Group>
                    </Form.Item>

                    {(data.gcSensationRtLightTouchCode==='PRO02' || data.gcSensationRtLightTouchCode==='PRO03') &&
                    <Form.Item name="gcSensationRtLightTouchCodeText">
                        <StyledInput placeholder='(Note)' maxLength={500}/>
                    </Form.Item>}
                </Col>
            </Row>
        </div>
    );
};

export default GeneralCondition;
