const theme: { [key: string]: string } = {
    primary: 'rgb(15,137,203)',
    secondary: 'rgb(102,188,234)',
    warning: '#E3B004',
    error: '#ff4d4f',

    grey: '#535353',
    'grey-1': '#AEADA8',
    'light-grey': '#f2f2f2',

    cyan: '#0082C873',
    'cyan-1': '#0082C873',
    'cyan-2': '#0082C873',
    'cyan-3': '#0082C873',
    'cyan-4': '#0082C873',
    'cyan-5': '#0082C873',

    white: '#FFFFFF',
    tip: '#B9B9B9',
};
export default theme;
