import React from 'react';

type Props = {
    /**
     * 開關 Modal 時希望處理的事情
     */
    onRequestOpen?: () => any;
};

/**
 * 打開modal, drawer...等等彈窗的共用hooks函式
 * @param onRequestOpen - 打開視窗時可以做的事情
 */
export default function useVisible({ onRequestOpen }: Props = {}) {
    const [visible, setVisible] = React.useState(false);

    const handleVisible: any = (evt: React.MouseEvent<HTMLElement>) => {
        setVisible(prev => !prev);

        if (onRequestOpen) {
            onRequestOpen();
        }
    };

    const handleClose = () => {
        setVisible(false);
    };

    return [visible, handleVisible, handleClose];
}
