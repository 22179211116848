import { User, useUpdateUser, editPassword } from 'api/user';
import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Modal, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import { Input, Select, Password } from 'components/form';
import DatePicker from 'components/datePicker/datePicker';
import { useEditItem } from 'hooks/useEdit'
import { pathOr, omit, evolve } from 'ramda';
import moment from 'moment';
import { Enum } from 'api/enums';
import { apiCaller } from 'service/apiCaller';
import { GlobalContext } from '../../index';
const { compose } = require('ramda');

interface Props {
    item?: User;
    onClose: Function;
    refetchQueries: Function;
}

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var reg = /^([0-9]*)$/;
const EditMyAccountDrawer: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [showChangePasswordDrawer, setShowChangePasswordDrawer] = useState(false);
    const[phone,setPhone]=useState<string>("");
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;

    useEffect(()=>{console.log(props.item?.managingOrganizationName)
        if(props.item){
            form.setFieldsValue({
                username: props.item.username,
                organization: props.item.managingOrganizationName,
                name: props.item.name,
                gender: props.item.gender,
                birthDate: props.item.birthDate,
                telecomPhone: props.item.telecomPhone,
                telecomEmail: props.item.telecomEmail
            })
        }
    }, []);

    const mutationOptions = {
        onCompleted: (data: any) => {
            Modal.success({
                maskClosable:true,
                title:'Success',
                onCancel: ()=>{
                    props.refetchQueries()
                    props.onClose()
                }
            })
        },
        onError: (error?: any) => {
            console.error(error)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = error?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            })
        },
    };
    const [editItem, setEditItem] = useEditItem<User>({
        item: props.item,
        form,
        editType: 'edit',
        onChange: values => {
            form.setFieldsValue({ role: values?.role });
        },
    });

    const updateUser = useUpdateUser(mutationOptions);

    const handleOk = ()=>{
        form.validateFields().then(values=>{
            const handler = updateUser;
            handler(
                compose(
                    evolve({
                        managingOrganizationId: Number,
                    }),
                    omit(['updatedAt', 'createdAt', 'managingOrganizationName']),
                )({
                    ...editItem,
                    ...values,
                    managingOrganization: props.item?.managingOrganizationId,
                    ...((values.birthDate
                        ? {
                            birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
                        }
                        : {}) as any),
                    role: pathOr([], ['role'], editItem).map((x: Enum) => x.id),
                }),
            );  
        })
    }

    return (
        <React.Fragment>
            {showChangePasswordDrawer&&<ChangePasswordDrawer onClose={()=>setShowChangePasswordDrawer(false)} username={props.item?.username} />}
            <Drawer
                {...props}
                visible={true}
                maskClosable={true}
                closable={true}
                keyboard={false}
                width={450}
                title={formatMessage({id:'EDIT_MY_ACCOUNT'})}
                onClose={props.onClose as any}
                footer={
                    <div className="pull--right">
                        <Button className="is--primary rect" onClick={handleOk} >
                            {formatMessage({id:'SAVE'})}
                        </Button>
                    </div>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onValuesChange={( changedValues,allValues)=>{
                        console.log("allValues",allValues)
                        let phoneInpute = allValues.telecomPhone
                        if(phoneInpute === ""){
                            setPhone(phoneInpute)
                            form.setFieldsValue({ telecomPhone:""})
                        }else if(!reg.test(phoneInpute)){
                            form.setFieldsValue({telecomPhone:phone})
                        }else if(reg.test(phoneInpute)){
                            form.setFieldsValue({ telecomPhone:allValues.telecomPhone })
                            setPhone(phoneInpute)
                        }
                    }}
                >
                    {/* 基本資訊 */}
                    <h2 className='title'>
                        {formatMessage({id:'BASIC_INFO'})}
                    </h2>
                        {/* 帳號 */}
                        <Col span={24}>
                            <Form.Item
                                label={formatMessage({id:'ACCOUNT_ID'})}
                                name='username'
                                rules={[
                                    {
                                        required: true,
                                    }
                                ]}
                            >
                                <Input
                                    bordered={false}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        {/* 姓名 */}
                        <Col span={24}>
                            <Form.Item
                                label={formatMessage({id:'USERNAME'})}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({id:'INPUT_NAME'}),
                                    },
                                ]}
                            >
                                <Input bordered={false}  />
                            </Form.Item>
                        </Col>

                        {/* 性別 */}
                        <Col span={24}>
                            <Form.Item
                                label={formatMessage({id:'GENDER'})}
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage({id:'INPUT_GENDER'}) ,
                                    },
                                ]}
                            >
                                <Select
                                    allowClear={true} 
                                    bordered={false} 
                                    
                                >
                                    {getOptions('Gender').map((x: any) => (
                                        <Select.Option key={x.code} value={x.code}>
                                            {x.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* 出生日期 */}
                        <Col span={24}>
                            <Form.Item
                                label={formatMessage({id:'BIRTHDAY'})}
                                name="birthDate"
                            >
                                <DatePicker
                                    bordered={false}
                                    className="width--full"
                                    format="DD/MM/YYYY"
                                    
                                />
                            </Form.Item>
                        </Col>

                        {/* Organization */}
                        <Col span={24}>
                            <Form.Item
                                label={formatMessage({id:'ORG_NAME'})}
                                name="organization"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Input bordered={false} disabled={true} />
                            </Form.Item>
                        </Col>
                        {/* 電話 */}
                        <Col span={24}>
                            <Form.Item label={formatMessage({id:'TEL'})} name="telecomPhone"  rules={[ {required: true, message: `${formatMessage({id:'TEL'})} is required`} ]}>
                                <Input bordered={false}  />
                            </Form.Item>
                        </Col>

                        {/* 電子郵件信箱 */}
                        <Col span={24}>
                            <Form.Item
                                label={formatMessage({id:'MAIL'})}
                                name="telecomEmail"
                                rules={[
                                    {
                                        required: true,
                                        message: `${formatMessage({id:'MAIL'})} is required`,
                                    },
                                    {
                                        validator: (_: any, value: any) => {
                                            if (value && !emailRegex.test(value)) {
                                                return Promise.reject(
                                                    formatMessage({id:'INVALID_MAIL'})
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input bordered={false}  />
                            </Form.Item>
                        </Col>
                        <Row gutter={24}>
                            <Col span={12} style={{textAlign: 'left'}}>
                                <b>{formatMessage({id:'PASSWORD'})}</b>
                            </Col>
                            <Col span={12} style={{textAlign: 'right'}}>
                                <Button 
                                    style={{backgroundColor: '#2E63A3', color: 'white'}}
                                    onClick={()=>setShowChangePasswordDrawer(true)}
                                >
                                    {formatMessage({id:'CHANGE'})}
                                </Button>
                            </Col>
                        </Row>
                </Form>
            </Drawer>
        </React.Fragment>
    )
}

export default EditMyAccountDrawer;

interface ChangePasswordProps {
    onClose: Function;
    username?: string;
}

const ChangePasswordDrawer: React.FC<ChangePasswordProps> = (props: ChangePasswordProps) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [passwordValid, setPasswordValid] = useState(false);

    const checkIfPasswordValid = () => {
        const reg = new RegExp('((?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*()-_+=]+))([a-zA-Z0-9!@#$%^&*()-_+=]{8,20})');
        const match: RegExpMatchArray=reg.exec(form.getFieldValue('newPassword')) || [];
        if ( form.getFieldValue('newPassword') !== form.getFieldValue('repeatPassword') ||
            !form.getFieldValue('currentPassword') || form.getFieldValue('currentPassword') == '' ||
            (!reg.test(form.getFieldValue('newPassword')) || match[0]?.length!=form.getFieldValue('newPassword').length)
        ) {
            setPasswordValid(false);
            
        } else {
            setPasswordValid(true);
        }
    }

    const handleOk = () => {
        const success = (res: any) => {
            Modal.success({
                maskClosable:true,
                title:'Success',
                onCancel: ()=>{
                    props.onClose();
                }
            })
        };

        const reject = (err: any) => {
            console.error(err)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            })
        };

        apiCaller(
            editPassword,
            {
                username: props.username,
                oldPassword: form.getFieldValue('currentPassword'),
                newPassword: form.getFieldValue('newPassword')
            },
            success,
            reject
        );
    }

    return (
        <React.Fragment>
            <Drawer
                {...props}
                visible={true}
                maskClosable={true}
                closable={true}
                keyboard={false}
                width={450}
                title={<span><LeftOutlined className='mr-20' style={{color: '#909090', marginLeft: '-30px'}} onClick={props.onClose as any} />{formatMessage({id:'CHANGE_PASSWORD'})}</span>}
                onClose={props.onClose as any}
            >
                <Form
                    layout="vertical"
                    form={form}
                    style={{textAlign: 'right'}}
                >
                    <Form.Item
                        label={formatMessage({id:'NEW_PASSWORD'})}
                        name="newPassword"
                    >
                        <Password visibilityToggle={true} bordered={false} onChange={checkIfPasswordValid} />
                    </Form.Item>
                    <span style={{fontSize: '12px', position: 'relative', top: '-24px', left: '-20px'}}>{formatMessage({id:'NEW_PASSWORD_TIP'},{br:<br/>})}</span>
                    <Form.Item
                        label={formatMessage({id:'CHECK_PASSWORD'})}
                        name="repeatPassword"
                    >
                        <Password visibilityToggle={true} bordered={false} onChange={checkIfPasswordValid} />
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id:'CURRENT_PASSWORD'})}
                        name="currentPassword"
                    >
                        <Password visibilityToggle={true} bordered={false} onChange={checkIfPasswordValid} />
                    </Form.Item>
                    <Button
                        style={{backgroundColor: '#2E63A3', color: 'white', width: '50%'}}
                        onClick={handleOk}
                        disabled={!passwordValid}
                    >
                        {formatMessage({id:'SAVE_CHANGE'})}
                    </Button>
                </Form>
            </Drawer>
        </React.Fragment>
    )
}
