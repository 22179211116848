import React from 'react';
import { useIntl } from 'react-intl';
import { KRAViewWrapper, KRAWrapper } from './index';
import ImageMapping from 'constants/imageMapping';
import { KRAdata } from 'api/record';
import KRABlockView from './KRABlockView';

interface SitToStandViewProps {
    kraData: KRAdata[];
    data: {
        observation: string;
    };
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    };
}

const SitToStandViewKRA: React.FC<SitToStandViewProps> = (props: SitToStandViewProps) => {

    const { formatMessage } = useIntl();

    const observationWithNewline = props.data.observation?.split('\n').map(d=><span>{d}<br/></span>) || '--';
    const kraList = props.kraData.map(datum=>(
        <KRABlockView data={datum} classData={props.classData}/>
    ));

    return (
        <KRAViewWrapper>
            <div className="header">
                {formatMessage({id:'KRA_SIT_TO_STAND'}, {br: ' '})}
            </div>
            <div className="content">
                <div className="icon">
                    <img src={ImageMapping.KRAExamination.KEN01} width='100%' />
                </div>
                
                <KRAWrapper>
                    {kraList}
                </KRAWrapper>

                <div className='label'>
                    <div className="name">{formatMessage({id:'OBSERVATION'})}</div>
                    <div className="text">{observationWithNewline}</div>
                </div>
            </div>
        </KRAViewWrapper>
    )
}

export default SitToStandViewKRA;
