import React, { useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'antd';
import { apiCaller } from '../../service/apiCaller';
import { ResetPasswordRequest, ResetPasswordResponse } from '../../api/type';
import { resetPasswordApi } from '../../api/login';
import { Input } from 'components/form';
import './resetPasswordCard.sass';
import { useHistory } from 'react-router-dom';
import { icon_mail, icon_error, icon_account } from 'components/image';
import { AxiosResponse } from 'axios';

interface Props {
    onSuccess: (username:string, token: string) => void
}

const ResetPasswordCard: React.FC<Props> = (props: Props) => {
    const [setting, setSetting] = useState({
        showError: false,
        showReturn: false,
        errorCode: ''
    });
    const [account, setAccount] = useState('');
    const [email, setEmail] = useState('');

    const history = useHistory();

    const resetVerify = () => {
        const body: ResetPasswordRequest = {
            username: account,
            telecomEmail: email
        };
        const success = (res: AxiosResponse<ResetPasswordResponse>) => {
            const {status, data} = res;
            if (status === 200) {
                const token = data.data.passwordToken;
                props.onSuccess(account, token);
            }
        };
        const reject = (error: any) => {
            const {status, data} = error;
            if (status === 400) {
                setSetting({...setting, showError: true, showReturn: true, errorCode: ''});
            }
        };
        apiCaller(resetPasswordApi, body, success, reject);
    };

    const clearForm = () => {
        setAccount('');
        setEmail('');
        setSetting({
            ...setting,
            showReturn: false,
            showError: false
        });
    }

    return (
        <main className='reset_main'>

            {/*登入*/}
            <div className='reset_header'>
                <FormattedMessage id='RESET_PASSWORD'/>
            </div>

            <div>
                {/*帳號*/}
                <FormattedMessage id='ACCOUNT' />
                <Input
                    className='reset_input stubborn'
                    style={{border: 'none'}}
                    prefix={<img src={icon_account} style={{fontSize:'1.5rem', marginRight:'0.5rem'}} />}
                    value={account}
                    onChange={e=>setAccount(e.target.value)}
                />

                {/*Email*/}
                <FormattedMessage id='EMAIL' />
                <Input
                    className='reset_input stubborn'
                    style={{border: 'none', marginBottom: '5px'}}
                    prefix={<img src={icon_mail} style={{fontSize:'1.5rem', marginRight:'0.5rem'}} />}
                    value={email}
                    onChange={e=>setEmail(e.target.value)}
                />

                {/* 錯誤訊息 */}
                <div className='reset_error_tips'>
                    {setting.showError &&<span>
                        <img src={icon_error} className='mr-10'/>
                        <FormattedMessage id={'RESET_PASSWORD_ERROR'} />
                    </span>}
                </div>
            </div>

            <div>
                {/*下一步*/}
                {!setting.showReturn&&<Button className='reset_button' size='large' disabled={account.length < 1 || email.length < 1} onClick={resetVerify}>
                    <FormattedMessage id='NEXT' />
                </Button>}

                {/*回到登入頁*/}
                {!setting.showReturn&&
                    <Button className='no-bg' type='link' size='large' disabled={false} onClick={() => history.push('/login')}>
                        <FormattedMessage id='BACK_TO_LOGIN' />
                    </Button>
                }
            </div>

            {setting.showReturn&&<Button className='reset_button mt-50' size='large' onClick={clearForm} ><FormattedMessage id='RETURN'/></Button>}
            
        </main>
    );
};

export default ResetPasswordCard;
