import {
    icon_sit_to_stand, icon_walking, icon_squat, 
    icon_stair_ascent, icon_stair_descent, icon_lunge, icon_other,
    small_sit_to_stand, small_gait, small_ascent, small_descent,
    keeogo, mirror_hand, other_device, icon_kra_sit_to_stand,
    icon_kra_walk_comfortably, icon_kra_walk_fast, icon_kra_stair_ascent, icon_kra_stair_descent
} from 'components/image';

const ImageMapping = {
    TrainingItem: {
        TTE01: icon_squat,
        TTE02: icon_sit_to_stand,
        TTE03: icon_walking,
        TTE04: icon_lunge,
        TTE05: icon_stair_ascent,
        TTE06: icon_stair_descent,
        TTE07: icon_other
    },
    TreatmentType: {
        TRE01: keeogo,
        TRE02: mirror_hand,
        TRE03: other_device
    },
    KRAExamination: {
        KEN01: icon_kra_sit_to_stand,
        KEN02: icon_kra_walk_comfortably,
        KEN03: icon_kra_walk_fast,
        KEN04: icon_kra_stair_ascent,
        KEN05: icon_kra_stair_descent
    }
}

export default ImageMapping;
