import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';

import Button from '../button/button';

import { timeOut } from '../../components/image';
import { apiCaller } from '../../service/apiCaller';
import { logoutApi } from '../../api/personal';
import styled from 'styled-components';

export const TimeOut: React.FC<any> = (props: any) => {
    const { close } = props;
    const [count, setCount] = useState(180);
    let timeoutCount: number | undefined;
    useEffect(() => {
        timeoutCount = setInterval(timeout, 1000) as any;
        return () => clearInterval(timeoutCount);
    });
    // timeout
    const timeout = () => {
        if (count === 0) {
            const success = (res: any) => {
                const { status } = res;
                if (status === 200) {
                    window.localStorage.removeItem('token');
                    window.location.href = '/login';
                }
            };
            const reject = (error: any) => {
                window.localStorage.removeItem('token');
                window.location.href = '/login';
            };
            apiCaller(logoutApi, null, success, reject);
        } else {
            setCount(count - 1);
        }
    };

    return (
        <StyledModal visible={true} closable={false} footer={null} >
            <div style={{height: '345px', textAlign: 'center', padding: '24px 30px'}}>
                <img src={timeOut} />
                <br />
                <span style={{fontSize: '22px', marginTop: '20px'}}>
                    <FormattedMessage id='TIMEOUT' /> {/*作業逾時*/}
                </span>
                <br />
                <br />
                <span>
                    <FormattedMessage id='TIMEOUT_INFO' /> {/*您的作業逾時，請確認是否繼續使用或選擇登出後再重新登入！*/}
                </span>
                <br />
                <br />
                <span style={{color: '#F0002E'}}>
                    {count}
                    <FormattedMessage id='FORCE_LOGOUT_INFO' />
                </span>
                <br />
                <br />
                <Button style={{width:'100%'}} className='is--primary' onClick={() => {close(false); clearInterval(timeoutCount)}}>
                    <FormattedMessage id='DONT_LOGOUT' /> {/*繼續使用，不登出*/}
                </Button>
                <br />
                <br />
                <Button style={{width:'100%'}} className='is--plain' onClick={() => {close(true); clearInterval(timeoutCount)}}>
                    <FormattedMessage id='LOGOUT' /> {/*登出*/}
                </Button>
            </div>
        </StyledModal>
    );
};

const StyledModal = styled(Modal)`
    
    .ant-modal-content {
        border-radius: 1rem;        
        text-align:center;
    }

    .ant-modal-footer {
        border: 0px
    }

    .ant-form {
        width: 370px;
    }
`;