import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Router } from './router/router';
import GlobalStyles from './GlobalStyles';
import theme from './theme'

// window.getComputedStyle(document.documentElement).getPropertyValue('--color-primary-dark')
// document.documentElement.style.setProperty('--color-primary-dark', 'red')

export const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Router />
        </ThemeProvider>
    );
};
