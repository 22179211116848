import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import ImgCard from 'components/imgCard/imgCard';
import { Row, Modal } from 'antd';
import { GlobalContext } from '../../../../index';
import { getTreatment, editTreatment } from 'api/case';
import RadioWrapper from 'components/radioWrapper/radioWrapper';
import ColorCard from 'components/colorCard/colorCard';
import moment from 'moment';
import { createRecord } from 'api/record';
import ImageMapping from 'constants/imageMapping';
import RecordDetail from './RecordDetail';
import { PageProps } from './RecordTab';

interface Props extends PageProps{
    onClose: Function;
}

const AddRecordDrawer: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { getOptions, nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const [selectedTreatment, setSelectedTreatment] = useState('TRE01'); // only keeogo for now
    const [selectedPackage, setSelectedPackage] = useState<number>();
    const [selectedClass, setSelectedClass] = useState<number>();
    const [allTreatmet, setAllTreatment] = useState<any[]>([]);

    useEffect(()=>{
        getTreatment(
            props.caseId,
            false
        ).then(res=>{
            setAllTreatment(res.data.data);
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }, []);
   
    const treatmentOptions = getOptions('TreatmentType')/*only keeogo for now*/.filter(d=>d.code==='TRE01').map((d, i)=>(
        <ImgCard img={ImageMapping.TreatmentType[d.code]} text={d.name} key={i} value={d.code} />
    ));

    const packageOptions = allTreatmet.filter((d: any) => d.treatment_course.treatmentTypeCode === nameMapping[selectedTreatment] && d.treatment_course.classTypeCode !== nameMapping["CAS01"]).map((d: any, i:number)=>(
        <TrainingPackage key={i} value={d.id} className="mr-10">{d.treatment_course.name.split(' ').at(-1)}</TrainingPackage>
    ));

    const classOptions = (allTreatmet.find((d: any)=>d.id===selectedPackage)?.plan_time ?? []).filter((d: any)=>d.treatmentDetailStatusCode===nameMapping['TDS02']).map((d: any) => (
        <ColorCard
            value={d.id}
            color='#2E63A3'
            month={moment(d.treatmentDate).format('MMM') + '.'}
            date={moment(d.treatmentDate).format('D')}
            hour={d.treatmentHour}
            name={formatMessage({ id: 'CLASS' }) + (d.detailClassNum ?? '')}
            weekday={moment(d.treatmentDate).format('ddd').toUpperCase()}
        />
    ));

    const handleAdd = () => {
        createRecord({
            personalInfoBasicId: props.caseId,
            personalTreatmentPlanDetailId: selectedClass ?? -1
        }).then(res=>{
            Modal.success({
                maskClosable: true,
                title: 'Success',
                onCancel: () => {
                    const { onClose, ...pageProps } = props;
                    editTreatment({
                        treatmentDetailStatusCode: 'TDS03',
                        planDetailId: selectedClass,
                        device: 'web'
                    });
                    props.onClose();
                    props.setPageContent(<RecordDetail trainingId={res.data.data.id} {...pageProps}/>);
                }
            });
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }
    return (
        <StyledDrawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='30vw'
            title={formatMessage({ id: 'ADD_RECORD' })}
            footer={
                <div className="pull--right rect">
                    <StyledButton
                        className="is--primary rect"
                        onClick={handleAdd}
                        style={{ backgroundColor: '#47A6D8' }}
                        disabled={selectedClass===undefined}
                    >{formatMessage({ id: 'ADD_UPPERCASE' })}</StyledButton>
                </div>
            }
        >
            <Title>{formatMessage({ id: 'TYPE' })}</Title>
            <RadioWrapper onChange={()=>{}} defaultValue='TRE01'>
                {treatmentOptions}
            </RadioWrapper>

            <Title>{formatMessage({ id: 'PACKAGE' })}</Title>
            <RadioWrapper onChange={val=>setSelectedPackage(val)} column noFlex middleElement={<Row gutter={24} style={{ padding: '0 10px', fontSize:'100rem'}}></Row>} >
                {packageOptions}
            </RadioWrapper>

            <Title>{formatMessage({ id: 'CLASS_DATE' })}</Title>
            <RadioWrapper onChange={val=>setSelectedClass(val)} column noFlex middleElement={<Row gutter={24} style={{ padding: '0 10px', justifyContent: 'space-between' }}></Row>}>
                {classOptions}
            </RadioWrapper>
                    
        </StyledDrawer>
    )
}

export default AddRecordDrawer;

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        padding: 0 20px;
    }
`;

const Title = styled.div`
    color: #2E2E2E;
    font-size: 14px;
    margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
    &:disabled {
        background-color: #CDCDCD !important;
    }
`;

const StyledRadioButton = styled.div<{selected?: boolean, value?: any}>`  
    display: flex;
    padding-left:1.8rem;
    padding-top:1rem;
    font-weight:bold;
    flex-direction: row;
    font-size:20px;
    aspect-ratio: 7/ 5.5;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 0.4rem;
    overflow: hidden;;
    width:6rem;
    height:4rem;
    margin: 10px 1.5%;
    color:${props=> props.selected ? '#FFFFFF':'#0083C1'};
    background-color: ${props=> props.selected ? '#2E63A3':'#FFFFFF'} !important;
    &.ant-radio-button-wrapper {
        background-color: ${props=> props.selected ? '#2E63A3 !important':''};
        color: ${props=> props.selected ? '#FFFFFF':'#0083C1'};
        border-color: #2E63A3 !important;
        &::before {
            display: none;
        }
    }
`;

const TrainingPackage:React.FC<{value: any, onClick?: (t: any) => void, className?: string, style?: object}> = (props: {value: any, onClick?: (t: any) => void, className?: string, style?: object}) => {
    return <StyledRadioButton {...props} onClick={()=>props.onClick?.(props.value) }/>
}
