import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Button, Checkbox, Modal } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import { apiCaller } from '../../service/apiCaller';
import { SetNewPasswordRequest, SetNewPasswordResponse } from '../../api/type';
import { resetPasswordApi } from '../../api/login';
import { LoadingBar, LoginCheck, ForgotPassword } from '../../components/component';
import { Input, Password } from 'components/form';
import './setNewPasswordCard.sass';
import { icon_key, icon_error, icon_account } from 'components/image';
import { AxiosResponse } from 'axios';

interface Props {
    username: string;
    passwordToken: string;
    onSuccess: () => void;
}

const SetNewPasswordCard: React.FC<Props> = (props: Props) => {
    const [setting, setSetting] = useState({
        showError: false,
        errorCode: ''
    });
    
    const [pwd, setPwd] = useState('');
    const [repeatPwd, setRepeatPwd] = useState('');
    const [pwdValid, setPwdValid] = useState(true);

    useEffect(()=>{
        const reg = new RegExp('((?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*()-_+=]+))([a-zA-Z0-9!@#$%^&*()-_+=]{8,20})');
        const match: RegExpMatchArray=reg.exec(pwd) || [];
        if (
            pwd !== '' && 
            (!reg.test(pwd) || match[0]?.length!=pwd.length)
        ) {
            setPwdValid(false);
            setSetting({
                ...setting,
                showError: true,
                errorCode: '',
            });
        } else {
            setPwdValid(true);
            setSetting({
                ...setting,
                showError: false,
                errorCode: '',
            });
        }
    }, [pwd]);

    const setNewPassword = () => {
        const body: SetNewPasswordRequest = {
            username: props.username,
            newPassword: pwd,
            passwordToken: props.passwordToken
        };
        const success = (res: AxiosResponse<SetNewPasswordResponse>) => {
            const { status, data } = res;
            if (status === 200) {
                props.onSuccess();
            }
        };
        const reject = (error: any) => {
            const {status, data} = error;
            if (status === 400) {
                setSetting({...setting, showError: true, errorCode: ''});
            }
        };
        apiCaller(resetPasswordApi, body, success, reject);
    };
    return (
        <main className='setnew_main'>

            {/*登入*/}
            <div className='setnew_header'>
                <FormattedMessage id='CREATE_NEW_PASSWORD'/>
            </div>

            <div>
                {/*輸入密碼*/}
                <FormattedMessage id='PASSWORD' />
                <Password
                    className='setnew_input stubborn'
                    prefix={<img src={icon_account} style={{fontSize:'1.5rem', marginRight:'0.5rem'}} />}
                    visibilityToggle={true}
                    bordered={true}
                    style={{border: 'none'}}
                    value={pwd}
                    onChange={(e:any)=>setPwd(e.target.value)}
                />

                {/*確認密碼*/}
                <FormattedMessage id='CHECK_PASSWORD' />
                <Password
                    className='setnew_input stubborn'
                    prefix={<img src={icon_key} style={{fontSize:'1.5rem', marginRight:'0.5rem'}} />}
                    visibilityToggle={true}
                    bordered={true}
                    style={{border: 'none'}}
                    value={repeatPwd}
                    onChange={(e:any)=>setRepeatPwd(e.target.value)}
                />

                {/* 錯誤訊息 */}
                <div className='setnew_error_tips'>
                    {setting.showError &&<span>
                        <img src={icon_error} className='mr-10'/>
                        <FormattedMessage id={'RESET_PASSWORD_ERROR'} />
                    </span>}
                </div>
            </div>

            <div className='setnew_tips'>
                <FormattedMessage id='PASSWORD_RULE' values={{br:<br/>}} /> 
            </div>

            {/*重設密碼*/}
            <Button className='setnew_button' size='large' disabled={pwd.length < 1 || pwd!==repeatPwd || !pwdValid} onClick={setNewPassword}>
                <FormattedMessage id='RESET_PWD' />
            </Button>
            
        </main>
    );
};

export default SetNewPasswordCard;
