import React from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/es/input-number';
import styled, { css } from 'styled-components';

const borderLess = css`
    width: 100%;
    padding-left: 1px;
    border: none;
    border-bottom: 2px solid #d9d9d9;
    outline: none;
    box-shadow: none;
    transition: 0.2s ease border-color;
    
    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        border-color: var(--color-primary-dark);
    }

    .ant-input-number-handler-wrap {
            display: none;
    }
`;

type Props = InputNumberProps & {
    bordered?: boolean;
    center?: boolean;
    prefix?: string|React.ReactNode;
    suffix?: string|React.ReactNode;
};

const StyledInputNumber = styled(({ bordered, ...props }) => (
    <InputNumber {...props} />
)) <Props & { bordered?: boolean }>`
    &.ant-input-number {
        ${borderLess};
    }

    .ant-input-number-input{
        padding: 0px;
        text-align: ${props => props.center ? 'center' : ''};
    }
`;

function InputNumberComponent(props: Props) {
    const { bordered = true, prefix, suffix, ...others } = props;

    return (
        <div>
            {prefix && <span className='mr-8'>{prefix}</span>}
            <StyledInputNumber bordered={bordered} {...others} style={(prefix || suffix) && { display: 'inline-block', width: 'calc(100% - 25px)' }}/>
            {suffix && <span className='ml-8'>{suffix}</span>}
        </div>
    );
}

export { InputNumberComponent as InputNumber };
