// tslint:disable quotemark
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { Form , Progress , Row, Col, Tooltip, Radio, Spin, Modal, Dropdown, Menu } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { PlusOutlined } from '@ant-design/icons';

import useUser from 'hooks/useUser';
import usePermission  from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';


import Button from 'components/button/button';
import styled from 'styled-components';
import Table from 'components/table/table';
import Pagination from 'components/pagination/pagination';
import { AvatarComponent } from 'components/avatar/avatar';
import Filter from 'components/filter/filter';
import { getUserInfoApi } from 'api/personal';
import { icon_no_patient } from 'components/image';
import { SorterResult } from 'antd/lib/table/interface';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .ant-table {
        .ant-table-body {
            table {
                tbody {
                    tr {
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;

const JSzip = require("jszip")

const StyledProgress = styled(Progress)`
    & {
        .ant-progress-text {
            color: white;
        }
    }
`

export default function CaseList({
    list,
    funcs,
    form,
    refetch,
    caseMap,
    getCaseTypeOpts,
    getCaseTypeStatusOpts,
    loading,
    onSearch
}: any) {

    const [myPermission] = usePermission('Patient');
    const intl = useIntl();
    const user = useUser();
    const history = useHistory();

    const [isExport,setIsExport] = useState(false);
    const [exportData,setExportData] = useState<any[]>([]);
    const [keyword, setKeyword] = useState<string>();
    const [sort, setSort] = useState<string>();
    const [desc, setDesc] = useState(true);
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [pagination, setPagination] = useState({
        pageSize:10,
        page:1
    });

    useEffect(()=>{
        getUserInfoApi().then((res: any)=>{
            if(res.data.data.role.some((d: number)=>d==5)){// Business User
                history.push(`/Patient/${res.data.data.username}`);
            }
        });
    }, []);

    useEffect(()=>{
        refetch({
            page: pagination.page, 
            pageSize: pagination.pageSize,
            name: keyword,
            startDate: startDate,
            endDate: endDate,
            sort: sort,
            desc: desc
        })
    }, [pagination, sort, desc]);

    const handleProgressBar = (bool:boolean, percent?:number )=>{

        const loadingspin = document.getElementById('loadingspin')

        if(!loadingspin) return

        const spin:any = loadingspin.firstChild
        spin.style.display = bool ? 'none' : 'block'

        let progressBar = document.getElementById('progressBar')
        if(!progressBar){
            progressBar = document.createElement('div')
            progressBar.id = 'progressBar'
            progressBar.style.cssText = `
                width: 70%;
                max-width: 500px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            `
        }

        if(bool){
            ReactDOM.render(
                <>
                    <StyledProgress percent={percent} />
                </>,
                progressBar
            )
            loadingspin.append(progressBar)
        }else{
            progressBar.remove()
        }

    }

    const columns = [
        //avatar
        {
            key: 'avatar',
            width: 50,
            render: (text: any, record: any) => {
                const imgPath = record.imgPath?.data?`data:image/png;base64,${btoa(record.imgPath.data.reduce((data: any, byte: any) => data + String.fromCharCode(byte), ''))}`:undefined;
                return (
                    <AvatarComponent src={imgPath} />
                )
            }
        },
        // 姓名
        {
            dataIndex: 'name',
            title: intl.formatMessage({id:'NAME'}),
            render: (text:string, record:any)=>text?text:'--',
            sorter: true,
            width: 145,
            ellipsis: true
        },
        // 方案
        {
            dataIndex: 'caseTypeCode',
            title: intl.formatMessage({id:'TYPE'}),
            render: (text:string, record:any)=>text || '--',
            sorter: true,
            width: 85,
            ellipsis: true
        },
        // 年齡
        {
            dataIndex: 'birthday',
            title: intl.formatMessage({id:'AGE'}),
            render: (text:string, record:any)=>text?moment().diff(text, 'year'):'--',
            sorter: true,
            width: 75,
            ellipsis: true
        },
        // IC Number
        {
            dataIndex: 'icNumber',
            title: intl.formatMessage({id:'IC_NUMBER'}),
            render: (text:string, record:any)=>text?text:'--',
            sorter: true,
            width: 120,
            ellipsis: true,
            align: 'right' as 'right'
        },
        // 病歷
        {
            key: 'mainDiagnosis1',
            title: intl.formatMessage({id:'MAIN_DIAGNOSIS'}),
            render: (text:string, record:any)=>{
                const diagnosis1 = record.mainDiagnosis1_abbr || '--';
                const diagnosis2 = record.mainDiagnosis2_abbr?('-'+record.mainDiagnosis2_abbr):'';
                return diagnosis1+diagnosis2;
            },
            sorter: true,
            width: 150,
            ellipsis: true
        },
        // Keeogo裝置
        {
            dataIndex: 'waistBeltCode',
            title: intl.formatMessage({id:'BELT_SIZE'}),
            render: (text:string, record:any)=>text?'Belt '+text.slice(1):'--',
            sorter: true,
            width: 110,
            ellipsis: true
        },
        // 組織縮寫
        {
            dataIndex: 'abbreviation',
            title: intl.formatMessage({id:'ORG_NAME'}),
            sorter: true,
            render: (text:string, record:any)=>text?text:'--',
            width: 140,
            ellipsis: true
        },
        // 建立日期
        {
            dataIndex: 'createdAt',
            title: intl.formatMessage({id:'ADDED_DATE'}),
            sorter: true,
            render: (text:any)=>text?moment(text).format('DD/MM/YYYY'):'--',
            width: 135,
            ellipsis: true
        }
    ];

    const onFilterSubmit = (form: FormInstance) => {
        const formKeyword = form.getFieldValue('search');
        const rangeDate = form.getFieldValue('date') || [moment(''), moment('')];
        const formStartDate = rangeDate[0]?.isValid()?rangeDate[0]:undefined;
        const formEndDate = rangeDate[1]?.isValid()?rangeDate[1]:undefined;

        setKeyword(formKeyword);
        setStartDate(formStartDate?.format('YYYY/MM/DD'));
        setEndDate(formEndDate?.format('YYYY/MM/DD'))

        setPagination({page: 1, pageSize: pagination.pageSize});
    }

    const getListView = ()=>{
        let res: any[] = list.content

        return res.map(row=>Object.assign({}, row, {key: row.id}));
    }

    let onRowClick = isExport? undefined : (record:any)=>{
        return {
            onClick: () => history.push(`/Patient/${record.userId}`)
        }
    };

    let rowSelection = isExport? {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setExportData(selectedRows);
        }
    } : undefined;

    return (
        <Wrapper>
            <input type="file" id="filepicker" name="fileList" hidden />

            <Form
                className='main'
                form={form}
                initialValues={{ caseType: '', caseStatus: '' }}
            >
                <div className="header">
                    <div className="d-flex v--center">
                        {/* 個案列表 */}
                        <b><strong><FormattedMessage id='PATIENT_LIST'/></strong></b>
                    </div>
                    <div className="d-flex">
                        {checkIfAvailable(myPermission, 'List', 'Search') && <Filter className='mr-15' onSubmit={onFilterSubmit} searchBy={intl.formatMessage({id:'PATIENT_NAME'}).toLowerCase()} dateWording={intl.formatMessage({id:'ADDED_DATE'})} />}

                        {/* 新增 */}
                        {checkIfAvailable(myPermission, 'List', 'Add') && <Button
                            className="is--primary rect"
                            
                            icon={<PlusOutlined />}
                            onClick={() => {
                                funcs.setPersonalCase({})
                                funcs.setDrawerSetting({type:'add',visible:true})
                            }}
                        />}

                    </div>

                </div>

                <Spin spinning={loading} >
                    <Table 
                        columns={columns}
                        dataSource={getListView()}
                        pagination={false}
                        onRow={onRowClick}
                        rowSelection={rowSelection}
                        onChange={(pagination, filters, sorter, extra)=>{
                            const sorterDetail = sorter as SorterResult<any>;
                            if(sorterDetail.column){
                                setSort((sorterDetail.field || sorterDetail.columnKey) as string);
                                setDesc(sorterDetail.order === 'descend' ? true : false);
                            }
                            else{
                                setSort(undefined);
                                setDesc(true);
                            }
                        }}
                        footer={getListView().length>0 ? ()=> <Pagination 
                            pagination={pagination} 
                            setPagination={setPagination} 
                            paginationTotal={list.total || 1}
                        /> : undefined}
                        locale={{
                            emptyText: (
                                <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={icon_no_patient} style={{width: '140px', height: '140px', objectFit: 'contain'}} />
                                    <div style={{fontSize: '24px', color: '#2E2E2E', fontWeight: 'bold'}}>
                                        <FormattedMessage id='PATIENT_NOT_FOUND'/>
                                    </div>
                                </div>
                            )
                        }}
                    />

                </Spin>
            </Form>
            
        </Wrapper>
    );
}
