import React, { useState, useContext, useEffect } from 'react';
import Drawer from 'components/drawer/drawer';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import { Radio, Checkbox, Modal, Row, Col } from 'antd';
import { Form, Select, Input } from 'components/form';
import NumberInput from 'components/numberInput/numberInput';
import DatePicker from 'components/datePicker/datePicker';
import WeekdaySelector from 'components/weekdaySelector/weekdaySelector';
import styled from 'styled-components';
import { GlobalContext } from '../../../../index';
import { addPlan, checkIfFirstTreatment } from 'api/case';
import { apiCaller } from 'service/apiCaller';
import moment from 'moment';
import { getOrgById } from 'api/organizationMgmtApi';
import { icon_info_outlined } from 'components/image';
import ImageMapping from 'constants/imageMapping';

const StyledDrawer = styled(Drawer)`

    .ant-form {
        height: 100%;
    }

    .page1 {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .page2 {
        padding-bottom: 15px;
    }

    .ant-form-item-label {
        .ant-form-item-required {
            &::before {
                content: '';
            }
        }
    }

    .planType {

        .ant-radio-group{

            .ant-radio-button-wrapper {
                margin: 0 10px;
                background-color: #E3E3E3;
                border: none;
                font-weight: 600;
                width: 100px;
                text-align: center;

                &:first-child {
                    margin-left: 0px;
                }

                &:last-child {
                    margin-right: 0px;
                }

                &:hover {
                    color: #47A6D8;
                }

                &::before {
                    display: none;
                }
            }

            .ant-radio-button-wrapper-checked {
                background-color: #47A6D8;

                &:hover {
                    color: #FFFFFF;
                }
            }
        }
    }

    .classType {
        
        padding: 15px 0;

        .ant-form-item, .ant-form-item-control, .ant-form-item-control-input, .ant-form-item-control-input-content {
            height: 100%;
        }

        .ant-radio-group {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .ant-radio-button-wrapper {
                height: auto;
                wdith: 100%;
                aspect-ratio: 2.25;
                border-radius: 0.5rem;
                font-weight: 600;
                font-size: 16px;
                border: none;
                padding: 0;

                img {
                    width: 100%;
                    aspect-ratio: 2.25;
                    object-fit: cover;
                    border-radius: 0.3rem;
                }

                .text {
                    position: absolute;
                    bottom: 10px;
                    left: 15px;
                    color: #FFFFFF;
                    text-shadow: 0px 2px 4px #00000080;
                    size: 5px;
                }

                &:hover {
                    color: #FFFFFF;
                }

                &::before {
                    display: none;
                }
            }

            .ant-radio-button-wrapper-checked {
                border: solid 3px #47A6D8;
                color: #47A6D8;
            }
        }

        
    }

    .typeOfClass {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
            // width: 100%;
            // height: 120px;
            width: 100%;
            aspect-ratio: 1.78;
            // object-fit: contain;
            background-color: #F3F3F3;
            border-radius: 0.5rem;
        }
    }

    .remainingClassNum {
        color: #2E63A3;
        font-size: 12px;
    }

    .repeatOption {
        margin-bottom: 15px;
    }

    .trainingGoals {
        font-size: 10px;
    }
`;

const StyledRadioButton = styled(Radio.Button)`
    &.ant-radio-button-wrapper-disabled:hover {
        color: rgba(0, 0, 0, 0.25) !important;
    }
`

interface Props {
    onClose: Function;
    defaultClassNum: number;
    caseId: number;
    refetch: Function;
    orgId: number;
    firstPlan?: boolean;
}

const AddNewPlanDrawer: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const dateFormat = 'DD/MM/YYYY';
    const [form] = Form.useForm();
    const [page, setPage] = useState(0);
    const [repeat, setRepeat] = useState(false);
    const [classNum, setClassNum] = useState(props.defaultClassNum);
    const [businessHours, setBusinessHours] = useState<string[]>([]);
    const [trainOption, setTrainOption] = useState<string[]>([]);
    const [othersContent, setOtherContent] = useState("");
    const [showTrialModal, setShowTrialModal] = useState(false);
    const [treatmentType, setTreatmentType] = useState(false);
    const [classType, setClassType] = useState(false);
    const { getOptions, nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    useEffect(()=>{
        getOrgById(
            props.orgId
        ).then((res:any)=>{
            let startTime = moment(res.data.data.businessHourS, 'HH:mm');
            const endTime = moment(res.data.data.businessHourE, 'HH:mm');

            const  hours = [];
            while(startTime<=endTime){
                hours.push(startTime.format('HH:mm'));
                startTime = startTime.add(30, 'minutes');
            }
            setBusinessHours(hours);

        }).catch((error: any)=>{
            console.error(error);
            throw(error);
        });
        form.setFieldsValue({
            trainingGoalsToImprove: trainOption,
            classTypeCode: props.firstPlan ? 'CLA01' : 'CLA02' 
        })
    }, []);

    const planTypeOptions = getOptions('ClassType').sort((a: any,b: any)=>a.order-b.order).map((d: any)=>(
        <StyledRadioButton value={d.code}   disabled={props.firstPlan && d.code==='CLA02'} >{d.name}</StyledRadioButton>
    ));

    const classTypeOptions = getOptions('TreatmentType').map((d: any)=>(
        <Radio.Button value={d.code}>
            <img src={ImageMapping.TreatmentType[d.code]}/>
            <span className='text'>{d.name}</span>
        </Radio.Button>
    ));

    const timeOptions = businessHours.map((time: string, i: number)=>(
        <Select.Option key={i+1} value={time}>{time}</Select.Option>
    ));
    
    const onChange = (checkedValue: any) => {
        setTrainOption(checkedValue);
    }

    const onOthersEdit: React.ChangeEventHandler<HTMLInputElement> = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newVal = form.getFieldValue('trainingGoalsToImprove')
                        .filter((d: string)=>!d.startsWith('PTG11'))
                        .concat(['PTG11']);
        form.setFieldsValue({trainingGoalsToImprove: newVal});
        if(trainOption.indexOf('PTG11') < 0){
            setTrainOption(trainOption.concat('PTG11'))
        }
        setOtherContent(e.target.value);
    }
    
    const trainGoalsItem = getOptions('PlanTrainingGoal').sort((a: any,b: any)=>a.order-b.order).filter((i: any)=>i.code !== 'PTG11').map(d=>(
        <Col span={12}>
            <Checkbox value={d.code} className="trainingGoals">{d.name}</Checkbox>
        </Col>
    ));

    const trainGoalsItem_Other = getOptions('PlanTrainingGoal').sort((a: any,b: any)=>a.order-b.order).filter((i: any)=>i.code == 'PTG11').map(d=>(
        <Col span={24}>
            <Checkbox value={d.code} className="trainingGoals">{d.name}</Checkbox>
            <Input bordered={false} style={{width:'100px'}} onChange={onOthersEdit} value={othersContent}/>
        </Col>
    ));

    trainGoalsItem.push(
        ...trainGoalsItem_Other
    );

    const trainGoalsOptions = [];
    for(let i = 0; i < trainGoalsItem.length; i+=2){
        trainGoalsOptions.push(
            <Row gutter={24}>
                {trainGoalsItem.slice(i,i+2)}
            </Row>
        )
    }

    const formPages = [
        (
            <div className='page1'>
                <div className="planType">
                    <Form.Item label={formatMessage({id:'PLAN_TYPE'})} name='classTypeCode' rules={[{required: true, message: `${formatMessage({id:'PLAN_TYPE'})} is required`}]}>
                        <Radio.Group buttonStyle='solid'>
                            {planTypeOptions}
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className="classType">
                    <Form.Item name='treatmentTypeCode' rules={[{required: true, message: `${formatMessage({id:'TREATMENT_TYPE'})} is required`}]}>
                        <Radio.Group>
                            {classTypeOptions}
                        </Radio.Group>
                    </Form.Item>
                </div>
            </div>
        ),
        (
            <div className='page2'>
                <div className="typeOfClass">
                    <img src={ImageMapping.TreatmentType[form.getFieldValue('treatmentTypeCode')]} alt={form.getFieldValue('treatmentTypeCode')} />
                </div>
                {(treatmentType===true&&classType===true)?
                    <div>
                        <Form.Item name="trainingGoalsToImprove" label={formatMessage({id:'TRAING_GOAL'})+':'}>
                            <Checkbox.Group onChange={onChange}>
                                {trainGoalsOptions}
                            </Checkbox.Group>

                        </Form.Item>
                    </div>:
                    <div></div>
                }
                <div className='mt-20'>
                    <Form.Item label={formatMessage({id:'TREATMENT_NUMBER'})} name='classNum'>
                        <NumberInput defaultValue={form.getFieldValue('classTypeCode')=='CLA01' ? 1 : props.defaultClassNum} fixed={form.getFieldValue('classTypeCode')=='CLA01'} onChange={n=>setClassNum(n)} />
                    </Form.Item>
                </div>
                <Form.Item label={formatMessage({id:'TIME'})} name='treatmentDate' rules={[{required: true, message: `${formatMessage({id:'DATE'})} is required`}]}>
                    <DatePicker bordered={false} placeholder={dateFormat} format={dateFormat} style={{width: '100%'}}  disabledDate={current=>current<moment().startOf('day')} />
                </Form.Item>
                <Form.Item name='treatmentHour' rules={[{required: true, message: `${formatMessage({id:'TIME'})} is required`}]}>
                    <Select bordered={false}>
                        {timeOptions}
                    </Select>
                </Form.Item>
                {form.getFieldValue('classTypeCode')=='CLA02'&&<div className='repeatOption'>
                    <Form.Item name='repeat' valuePropName='checked'>
                        <Checkbox onChange={r=>setRepeat(r.target.checked)}>{formatMessage({id:'REPEAT'})}</Checkbox>
                    </Form.Item>
                    {repeat&&<Form.Item name='week'>
                        <WeekdaySelector/>
                    </Form.Item>}
                </div>}
                <Form.Item noStyle name='classTypeCode'><span></span></Form.Item>
                <Form.Item noStyle name='treatmentTypeCode'><span></span></Form.Item>
            </div>
        )
    ]

    const handleNext = async () => {
        await form.validateFields().then(async (values)=>{
            if(values.classTypeCode=='CLA01'){
                await checkIfFirstTreatment({
                    personalInfoBasicId: props.caseId,
                    treatmentTypeCode: values.treatmentTypeCode,
                    classTypeCode: values.classTypeCode
                }).then((res:any)=>{
                    if(res.data.success){
                        setPage(1);
                        if(form.getFieldValue('classTypeCode') === 'CLA01'){ // trial
                            form.setFieldsValue({
                                classNum: 1,
                                treatmentDate: moment(),
                                treatmentHour: (parseInt(moment().format('HH'))+1).toString()+':00'
                            });
                        }
                        else{
                            form.setFieldsValue({
                                classNum: props.defaultClassNum
                            });
                        }
                    }
                }).catch((error: any)=>{
                    console.error(error);
                    setShowTrialModal(true);
                });
            }
            else{
                setPage(1);
            }
        });

    }

    const handleAdd = () => {
        form.validateFields().then(values=>{
            const success = (res: any) =>{
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel: ()=>{
                        props.refetch();
                        props.onClose()
                    }
                });
            };

            const reject = (error: any) => {
                console.error(error);
                throw(error);
            }

            apiCaller(
                addPlan,
                {
                    ...values,
                    treatmentDate: values.treatmentDate.format('YYYY/MM/DD'),
                    personalInfoBasicId: props.caseId,
                    device: 'web',
                    trainingGoalsToImproveCode: trainOption,
                    trainingGoalsToImproveOther: trainOption.indexOf('PTG11') < 0 ? "": othersContent
                },
                success,
                reject
            );
        })
    }

    return (
        <StyledDrawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='30vw'
            title={formatMessage({id:'ADD_PLAN'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect" onClick={page==0?handleNext:handleAdd}>
                        {page==0?formatMessage({id:'NEXT'}):formatMessage({id:'ADD_UPPERCASE'})}
                    </Button>
                </div>
            }
        >
            {showTrialModal && <TreatmentNotAvailableModal onClose={()=>setShowTrialModal(false)} treatmentType={nameMapping[form.getFieldValue('treatmentTypeCode')]} />}
            <Form layout='vertical' form={form}  
            onValuesChange={( changedValues,allValues)=>{
                let formTreatmentType = allValues.treatmentTypeCode
                let formclassType = allValues.classTypeCode

                if(formTreatmentType ==="TRE01"){
                    setTreatmentType(true)
                }else{
                    setTreatmentType(false)
                }

                if(formclassType ==="CLA02"){
                    setClassType(true)
                }
                else{
                    setClassType(false)
                }
            }}>
                {formPages[page]}
            </Form>
        </StyledDrawer>
    )
}

export default AddNewPlanDrawer;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 0.5rem;

        .ant-modal-body {
            padding: 50px 8%;
        }
    }
`;

interface NotAvailableModalProps {
    onClose: () => void;
    treatmentType: string;
}

const TreatmentNotAvailableModal: React.FC<NotAvailableModalProps> = (props: NotAvailableModalProps) => {

    const { formatMessage } = useIntl();

    return (
        <StyledModal
            visible
            centered
            closable={false}
            width='360px'
            onCancel={props.onClose}
            footer={null}
        >
            <div className='d-flex is--column v--center'>
                <img src={icon_info_outlined} className='mb-30'/>
                <div className='text-24 text--bold mb-30' style={{color: '#47A6D8'}}>
                    {formatMessage({id:'INVALID_TREATMENT'})}
                </div>
                <div className='text-10 mb-40 text--center'>
                    {formatMessage({id:'INVALID_TREATMENT_DETAIL'}, {treatmentType: props.treatmentType, br: <br/>})}
                </div>
                <Button
                    onClick={props.onClose}
                    style={{
                        backgroundColor: '#47A6D8',
                        width: '90%',
                        height: '40px',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        border: 'none'
                    }}
                >
                    {formatMessage({id:'OK'})}
                </Button>
            </div>
        </StyledModal>
    )
}
