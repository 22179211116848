import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { routeMatch } from 'utils/webHelper';
import { AvatarComponent } from 'components/avatar/avatar';
import { apiCaller } from 'service/apiCaller';
import { Spin, Tooltip, Tabs } from 'antd';
import { useIntl } from 'react-intl';
import { getUser, getCaseProfile, getCaseDevice, getTreatment} from 'api/case';
import { getParameterList, ParameterProfile } from 'api/parameter';
import { getListRecord, getOneRecord } from 'api/record';
import { getEvaluation } from 'api/eavluation';
import { EvaluationBrief } from './Evaluation/type';
import moment from 'moment';
import UserInfoTab from './UserInfo/UserInfoTab';
import ProfileTab from './Profile/ProfileTab';
import PlanTab from './Plan/PlanTab';
import EvaluaionTab from './Evaluation/EvaluationTab';
import ParameterTab from './Parameter/ParameterTab';
import RecordTab from './Record/RecordTab';
import SummaryTab from './Summary/SummaryTab';
import { useLocation } from 'react-router-dom';
import usePermission from 'hooks/usePermission';
import PromptNoteModal, { PromptRecord } from './PromptNoteModal';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    background-color: #f3f3f3;

    .topbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 10px;

        .avatar {
            display: flex;
            align-items: center;

            .name {
                font-size: 20px;
                font-weight: bold;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .info {
            display: flex;
            width: 50%;

            .block {
                display: flex;
                flex-direction: column;
                margin: 0 20px;

                .up {
                    font-size: 14px;
                    font-weight: bold;
                    border-bottom: 1px #909090 solid;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .down {
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }

    .ant-tabs-nav {
        background-color: #ffffff;
        font-weight: bold;
        padding: 0 15px;
    }

    .ant-tabs-tabpane {
        width: auto;
        flex-shrink: 1;
    }

    .ant-tabs-tabpane-active {
        height: calc(100vh - 80px - 60px - 42px - 40px);
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }
    }
`;

const CaseProfile: React.FC<RouteComponentProps> = ({ location }) => {
    const { id } = routeMatch('/Patient/:id', location.pathname);
    const edit = new URLSearchParams(useLocation().search).get('edit');
    const urlTabKey = new URLSearchParams(useLocation().search).get('tabKey');

    const { formatMessage } = useIntl();
    const [permission] = usePermission('Patient');

    const [editView, setEditView] = useState(edit==='true');

    const [userInfoData, setUserInfoData] = useState<any>({});
    const [profileData, setProfileData] = useState<any>({});
    const [deviceData, setDeviceData] = useState<any>({});
    const [treatmentData, setTreatmentData] = useState<any[]>([]);
    const [parameterData, setParameterData] = useState<ParameterProfile[]>([]);
    const [evaluationData, setEvaluationData] = useState<EvaluationBrief[]>([])
    const [evaluationDataTotal, setEvaluationDataTotal] = useState(0);

    const [tabKey, setTabKey] = useState('Information');
    const [createdRecord, setCreatedRecord] = useState<number>(-1);
    const [key, setKey] = useState(Date.now().toString());

    const [loading, setLoading] = useState(false);
    const [caseId, setCaseId] = useState(0);
    const [orgId, setOrgId] = useState(0);

    const [remarkData, setRemarkData] = useState<any[]>([]);

    const [promptModalSetting, setPromptModalSetting] = useState({
        show: false,
        recordRemark: ''
    });

    const imgPath = userInfoData.imgPath?.data
        ? `data:image/png;base64,${btoa(
            userInfoData.imgPath.data.reduce(
                (data: any, byte: any) => data + String.fromCharCode(byte),
                '',
            ),
        )}`
        : undefined;

    useEffect(() => {
        getData();
    }, []);

    useEffect(()=>{
        if(remarkData.length > 0){
            const promptRecord = JSON.parse(localStorage.getItem('promptRecord') || '{}') as PromptRecord;
            const record = promptRecord[localStorage.getItem('uid') || '']?.find(d=>d.caseId===caseId);
            if(!record){
                setPromptModalSetting({
                    show: true,
                    recordRemark: remarkData[remarkData.length-1].recordRemark
                });
            }
            else if(moment(remarkData[remarkData.length-1].createdAt).diff(record.lastPrompt) > 0){
                setPromptModalSetting({
                    show: true,
                    recordRemark: remarkData[remarkData.length-1].recordRemark
                });
            }
        }
    }, [remarkData]);

    const getData = async () => {
        try {
            setLoading(true);
            await apiCaller(
                getUser,
                { id },
                (res: any) => {
                    setCaseId(res.data.data[0].id);
                    setUserInfoData(res.data.data[0]);
                    setRemarkData(res.data.data[0].recordRemark);
                    setOrgId(res.data.data[0].fhirOrganizationId);

                    setTabKey(urlTabKey ?? 'Information');

                    fetchCaseProfile(res.data.data[0].id);
                    // fetchCaseDevice(res.data.data[0].id);
                    // fetchTreatment({ id: res.data.data[0].id, inFinish: false });
                    // fetchParameterList({ caseId: res.data.data[0].id, invalid: false});
                    // fetchEvaluationList(res.data.data[0].id);
                },
                (error: Error) => {
                    throw error;
                },
            );
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserInfoData = async () => {
        try {
            setLoading(true);
            await getUser({
                id,
            }).then((res: any) => {
                setUserInfoData(res.data.data[0]);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchCaseProfile = async (id?: number) => {
        try {
            setLoading(true);
            await getCaseProfile(id ?? caseId).then((res: any) => {
                setProfileData(res.data.data);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchCaseDevice = async (id?: number) => {
        try {
            setLoading(true);
            await getCaseDevice(id ?? caseId).then((res: any) => {
                setDeviceData(res.data.data);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchTreatment = async (params?: { id?: number; inFinish?: boolean ;desc?:boolean}) => {
        try {
            setLoading(true);
            await getTreatment(params?.id ?? caseId, params?.inFinish ?? false,params?.desc??false).then((res: any) => {
                setTreatmentData(res.data.data);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchParameterList = async (params?: { caseId?: number; invalid?: boolean }) => {
        try {
            setLoading(true);
            await getParameterList({ caseId: params?.caseId ?? caseId, invalid: params?.invalid ?? false }).then(res => {
                setParameterData(res.data.data);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchEvaluationList = async (params?: { id?: number, page?: number, pageSize?: number, isPreliminary?: boolean }) => {
        try {
            setLoading(true);
            await getEvaluation({
                personalInfoBasicId: params?.id ?? caseId,
                page: params?.page ?? 1,
                pageSize: params?.pageSize ?? 10,
                isPreliminary: params?.isPreliminary ?? false
            }).then(res => {
                setEvaluationData(res.data.data);
                setEvaluationDataTotal(res.data.total);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // deprecated
    // const fetchRecordList = async (params?: { caseId?: number,trainingTypeCode?:string, page?: number, pageSize?: number ,desc?:boolean,personalTreatmentPlanMasterId?:number}) => {
    //     try {
    //             getData();
    //             setLoading(true);
    //             await getListRecord({
    //                 personalInfoBasicId: params?.caseId ?? caseId,
    //                 trainingTypeCode: params?.trainingTypeCode ??'',
    //                 page:params?.page ?? 1,
    //                 pageSize: params?.pageSize ?? 10,
    //                 desc: params?.desc ?? true,
    //                 personalTreatmentPlanMasterId:params?.personalTreatmentPlanMasterId?? 0
    //             }).then(res => {

    //             });
    //     } catch (e) {
    //         console.error(e);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    const jumpToRecordTab = (recordId: number) => {
        setCreatedRecord(recordId);
        setTabKey('Record');
    };

    const tabPanes = [
        {
            id: 'Information',
            tab: formatMessage({ id: 'USER_INFO' }),
            component: UserInfoTab,
            prop: {
                data: userInfoData,
                refetch: fetchUserInfoData,
                edit: editView,
                setEditView
            },
        },
        {
            id: 'Profile',
            tab: formatMessage({ id: 'PROFILE' }),
            component: ProfileTab,
            prop: {
                profileData,
                deviceData,
                refetch: () => {
                    fetchCaseDevice();
                    fetchCaseProfile();
                },
            },
        },
        {
            id: 'Plan',
            tab: formatMessage({ id: 'PLAN' }),
            component: PlanTab,
            prop: {
                caseId,
                refetch: fetchTreatment,
                data: treatmentData,
                orgId,
                jumpToRecordTab,
                remarkData
            },
        },
        {
            id: 'Examination',
            tab: formatMessage({ id: 'EXAMINATION' }),
            component: EvaluaionTab,
            prop: {
                caseId,
                refetch: fetchEvaluationList,
                data: evaluationData,
                userInfoData,
                treatmentData,
                fetchUserInfoData,
                fetchTreatment,
                evaluationDataTotal
            },
        },
        {
            id: 'Record',
            tab: formatMessage({ id: 'RECORD' }),
            component: RecordTab,
            prop: {
                createdRecord,
                caseId
            },
        },
        {
            id: 'Parameter',
            tab: formatMessage({ id: 'PARAMETER' }),
            component: ParameterTab,
            prop: {
                caseId,
                refetch: fetchParameterList,
                data: parameterData,
                caseData: userInfoData,
                fetchUserInfoData,
                profileData,
                fetchCaseProfile
            },
        },
        {
            id: 'Summary',
            tab: formatMessage({ id: 'SUMMARY' }),
            component: SummaryTab,
            prop: {
                caseId
            },
        },
    ];
    return (
        <Wrapper>
            {/* {promptModalSetting.show && <PromptNoteModal caseId={caseId} recordRemark={promptModalSetting.recordRemark} onClose={()=>setPromptModalSetting({show: false, recordRemark: ''})}/>} */}

            <Spin spinning={loading}>
                <div className="topbar">
                    <div className="avatar">
                        <div> <AvatarComponent size="large" className="mr-15" src={imgPath} /></div>
                        <div className="name">{userInfoData.name || '--'}</div>
                    </div>
                    <div className="info">
                        <div className="block" style={{ width: '20%' }}>
                            <Tooltip placement="top" title={userInfoData.abbreviation}>
                                <span className="up">{userInfoData.abbreviation || '--'}</span>
                            </Tooltip>
                            <Tooltip placement="bottom" title={userInfoData.caseTypeCode}>
                                <span className="down">{userInfoData.caseTypeCode || '--'}</span>
                            </Tooltip>
                        </div>
                        <div className="block" style={{ width: '20%' }}>
                            <Tooltip placement="top" title={userInfoData.icNumber}>
                                <span className="up">{userInfoData.icNumber || '--'}</span>
                            </Tooltip>
                            <Tooltip
                                placement="bottom"
                                title={`${userInfoData.genderCode || '--'}, ${userInfoData.birthday
                                    ? moment().diff(userInfoData.birthday, 'years') + 'y'
                                    : '--'
                                    }`}
                            >
                                <span className="down">
                                    {userInfoData.genderCode || '--'},{' '}
                                    {userInfoData.birthday
                                        ? moment().diff(userInfoData.birthday, 'years') + 'y'
                                        : '--'}
                                </span>
                            </Tooltip>
                        </div>
                        <div className="block" style={{ width: '15%' }}>
                            <Tooltip placement="top" title={userInfoData.mainDiagnosis1}>
                                <span className="up">{userInfoData.mainDiagnosis1_abbr || '--'}</span>
                            </Tooltip>
                            <Tooltip placement="bottom" title={userInfoData.mainDiagnosis2}>
                                <span className="down">{userInfoData.mainDiagnosis2_abbr || '--'}</span>
                            </Tooltip>
                        </div>
                        <div className="block" style={{ width: '25%' }}>
                            <Tooltip
                                placement="top"
                                title={`${formatMessage({id:'BELT'})} ${profileData.waistBeltCode || '--'}, `+
                                `${formatMessage({id:'LT'})} ${profileData.keeogoFemoralShaftLengthLt ? `#${profileData.keeogoFemoralShaftLengthLt}` : '--'}, `+
                                `${formatMessage({id:'RT'})} ${profileData.keeogoFemoralShaftLengthRt ? `#${profileData.keeogoFemoralShaftLengthRt}` : '--'}`}
                            >
                                <span className="up">
                                    {`${formatMessage({id:'BELT'})} ${profileData.waistBeltCode || '--'}, `+
                                    `${formatMessage({id:'LT'})} ${profileData.keeogoFemoralShaftLengthLt ? `#${profileData.keeogoFemoralShaftLengthLt}` : '--'}, `+
                                    `${formatMessage({id:'RT'})} ${profileData.keeogoFemoralShaftLengthRt ? `#${profileData.keeogoFemoralShaftLengthRt}` : '--'}`}
                                </span>
                            </Tooltip>
                            <Tooltip
                                placement="bottom"
                                title={`${formatMessage({
                                    id: 'LAST_TREATMENT',
                                })}: ${userInfoData.lastTreatmentDate? moment(userInfoData.lastTreatmentDate).format('DD/MM/YYYY') : '--'}`}
                            >
                                <span className="down">{`${formatMessage({
                                    id: 'LAST_TREATMENT',
                                })}: ${userInfoData.lastTreatmentDate? moment(userInfoData.lastTreatmentDate).format('DD/MM/YYYY') : '--'}`}</span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="tab-container">
                    <Tabs
                        activeKey={tabKey}
                        onTabClick={(key: string) => setTabKey(key)}
                        onChange={key => {
                            if (key !== 'Record') {
                                setCreatedRecord(-1);
                            }
                            setKey(Date.now().toString());
                        }}
                        key={key}
                    >
                        {tabPanes
                            .filter(d => permission.children?.find(n => n.name == d.id)?.available)
                            .map((item: any, i: number) => (
                                <Tabs.TabPane tab={item.tab} key={item.id} >
                                    <item.component {...item.prop} />
                                </Tabs.TabPane>
                            ))}
                    </Tabs>
                </div>
            </Spin>
        </Wrapper>
    );
};

export default CaseProfile;
