import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { icon_delete, icon_device } from 'components/image';
import Button from 'components/button/button';
import QRcodeModal from './QRcodeModal';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    width: 160px;
    aspect-ratio: 0.8;
    margin-bottom: 20px;
`

interface Props {
    id: number;
    name: string;
    channelId: number;
    channelName: string;
    onRemove: () => void;
}

const DeviceCard: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const [showQrCode, setShowQrCode] = useState(false);

    return (
        <Wrapper className='d-flex is--column v--center padding-15'>
            {showQrCode && 
            <QRcodeModal
                payload={{
                    deviceId: props.id,
                    deviceName: props.name,
                    channelId: props.channelId,
                    channelName: props.channelName
                }}
                title={props.name}
                onClose={()=>setShowQrCode(false)}
            />}

            <div className='cursor--pointer d-flex is--column v--center' onClick={()=>setShowQrCode(true)}>
                <div className='icon mb-10'>
                    <img src={icon_device} />
                </div>
                <div className='mb-15'>
                    {props.name}
                </div>
            </div>
            <Button
                type='link'
                icon={<img src={icon_delete} className='mr-5' />}
                onClick={props.onRemove}
                style={{
                    color: '#FF3B30',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >{formatMessage({id:'REMOVE'})}</Button>
        </Wrapper>
    )
}

export default DeviceCard;
