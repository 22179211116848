import React, { MouseEventHandler } from 'react';
import { icon_edit_blue } from 'components/image';
import styled from 'styled-components';

interface Props {
    editable?: boolean;
    onEdit?: MouseEventHandler<HTMLImageElement>;
    title: string | JSX.Element;
    children?: JSX.Element | JSX.Element[];
}

const Wrapper = styled.div`
    background-color: #FFFFFF;
    padding: 15px;
    margin-bottom: 20px;

    .card_title {
        display: flex;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 30px;
    }

    .card_content {
        padding: 0 20px;
    }
`;

const ProfileCard: React.FC<Props> = ({editable=true, onEdit, title, children}: Props) => {
    return (
        <Wrapper>
            <div className="card_title">
                {title}
                {editable?<span className='ml-15' style={{cursor: 'pointer'}}>
                    <img src={icon_edit_blue} onClick={onEdit} />
                </span>:null}
            </div>
            <div className="card_content">
                {children}
            </div>
        </Wrapper>
    )
}

export default ProfileCard;
