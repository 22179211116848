export interface EvaluationBrief {
    id: number;
    personalTreatmentPlanDetailId: number;
    isPreliminary: boolean;
    evaluationDate: string;
    therapist: string;
    remark: string[] | null;
    status: boolean;
    planDate: string;
    treatmentName: string;
}

export interface Evaluation {
    id: number;
    createdAt: number;
    updatedAt: number;
    personalInfoBasicId: number;
    personalTreatmentPlanDetailId: number;
    status: boolean;
    device: string;
    isPreliminary: false;
    evaluationDate: string;
    createId: number;
    modifyId: number;
    fhirOrganizationId: number;
    evaluation_detail: EvaluationDetail;
    remark: string;
    therapist: string;
    trainingId: number | null;
    treatmentDate: string;
    treatmentHour: string;
    packageName: string;
    className: string;
}

export interface EvaluationDetail extends EvaluationData {
    id: number;
    createdAt: number;
    updatedAt: number;
    personalEvaluationMasterId: number;
    createId: number;
    modifyId: number;
    fhirOrganizationId: number;
}

export interface EvaluationData extends LLData, ULData, KEData {}

export interface LLData extends GeneralConditionData, ActiveRangeMotionData, MuscleToneData, MuscleStrengthData, BalanceData, MovementAnalysisData {}

export interface GeneralConditionData {
    gcMentalStatusCode: string;
    gcMotivationCode: string;
    gcAttentionCode: string;
    gcCommunicationCode: string;
    gcCommunicationOther: string;
    gcVisionCode: string;
    gcVisionNeglectCode: string;
    gcVisionOther: string;
    gcHearingCode: string;
    gcHearingImpairedHardHearingDeafCode: string;
    gcPainCode: string;
    gcPainLocationText: string;
    gcPainVasText: string;
    gcSwellingCode: string;
    gcSwellingLocationText: string;
    gcSensationLtProprioceptionCode: string;
    gcSensationLtProprioceptionText: string;
    gcSensationRtProprioceptionCode: string;
    gcSensationRtProprioceptionText: string;
    gcSensationLtLightTouchCode: string;
    gcSensationLtLightTouchCodeText: string;
    gcSensationRtLightTouchCode: string;
    gcSensationRtLightTouchCodeText: string;
}

export interface ActiveRangeMotionData {
    armUlLtShoulderFlexCode: string;
    armUlLtShoulderFlexLimit: number;
    armUlLtShoulderExtCode: string;
    armUlLtShoulderExtLimit: number;
    armUlLtShoulderAbdCode: string;
    armUlLtShoulderAbdLimit: number;
    armUlLtShoulderAddCode: string;
    armUlLtShoulderAddLimit: number;
    armUlLtShoulderErCode: string;
    armUlLtShoulderErLimit: number;
    armUlLtShoulderIrCode: string;
    armUlLtShoulderIrLimit: number;
    armUlRtShoulderFlexCode: string;
    armUlRtShoulderFlexLimit: number;
    armUlRtShoulderExtCode: string;
    armUlRtShoulderExtLimit: number;
    armUlRtShoulderAbdCode: string;
    armUlRtShoulderAbdLimit: number;
    armUlRtShoulderAddCode: string;
    armUlRtShoulderAddLimit: number;
    armUlRtShoulderErCode: string;
    armUlRtShoulderErLimit: number;
    armUlRtShoulderIrCode: string;
    armUlRtShoulderIrLimit: number;
    armUlLtElbowFlexCode: string;
    armUlLtElbowFlexLimit: number;
    armUlLtElbowExtCode: string;
    armUlLtElbowExtLimit: number;
    armUlLtElbowPronationCode: string;
    armUlLtElbowPronationLimit: number;
    armUlLtElbowSupinationCode: string;
    armUlLtElbowSupinationLimit: number;
    armUlRtElbowFlexCode: string;
    armUlRtElbowFlexLimit: number;
    armUlRtElbowExtCode: string;
    armUlRtElbowExtLimit: number;
    armUlRtElbowPronationCode: string;
    armUlRtElbowPronationLimit: number;
    armUlRtElbowSupinationCode: string;
    armUlRtElbowSupinationLimit: number;
    armUlLtFinerGrippingCode: string;
    armUlRtFinerGrippingCode: string;
    armLlLtHipFlexCode: string;
    armLlLtHipFlexLimit: number;
    armLlLtHipExtCode: string;
    armLlLtHipExtLimit: number;
    armLlLtHipAbdCode: string;
    armLlLtHipAbdLimit: number;
    armLlLtHipAddCode: string;
    armLlLtHipAddLimit: number;
    armLlLtHipErCode: string;
    armLlLtHipErLimit: number;
    armLlLtHipIrCode: string;
    armLlLtHipIrLimit: number;
    armLlRtHipFlexCode: string;
    armLlRtHipFlexLimit: number;
    armLlRtHipExtCode: string;
    armLlRtHipExtLimit: number;
    armLlRtHipAbdCode: string;
    armLlRtHipAbdLimit: number;
    armLlRtHipAddCode: string;
    armLlRtHipAddLimit: number;
    armLlRtHipErCode: string;
    armLlRtHipErLimit: number;
    armLlRtHipIrCode: string;
    armLlRtHipIrLimit: number;
    armLlLtKneeFlexCode: string;
    armLlLtKneeFlexLimit: number;
    armLlLtKneeExtCode: string;
    armLlLtKneeExtLimit: number;
    armLlRtKneeFlexCode: string;
    armLlRtKneeFlexLimit: number;
    armLlRtKneeExtCode: string;
    armLlRtKneeExtLimit: number;
    armLlLtAnkleDfCode: string;
    armLlLtAnkleDfLimit: number;
    armLlLtAnklePfCode: string;
    armLlLtAnklePfLimit: number;
    armLlLtAnkleInversionCode: string;
    armLlLtAnkleInversionLimit: number;
    armLlLtAnkleEversionCode: string;
    armLlLtAnkleEversionLimit: number;
    armLlRtAnkleDfCode: string;
    armLlRtAnkleDfLimit: number;
    armLlRtAnklePfCode: string;
    armLlRtAnklePfLimit: number;
    armLlRtAnkleInversionCode: string;
    armLlRtAnkleInversionLimit: number;
    armLlRtAnkleEversionCode: string;
    armLlRtAnkleEversionLimit: number;
}

export interface MuscleToneData {
    mtUlLtShoulderFlexorCode: string;
    mtUlLtShoulderAdductorCode: string;
    mtUlLtElbowFlexorCode: string;
    mtUlLtWristFlexorCode: string;
    mtUlRtShoulderFlexorCode: string;
    mtUlRtShoulderAdductorCode: string;
    mtUlRtElbowFlexorCode: string;
    mtUlRtWristFlexorCode: string;
    mtLlLtHipFlexorCode: string;
    mtLlLtHipExtensorCode: string;
    mtLlLtHipAdductorCode: string;
    mtLlLtKneeFlexorCode: string;
    mtLlLtKneeExtensorCode: string;
    mtLlLtAnklePlantarflexorCode: string;
    mtLlRtHipFlexorCode: string;
    mtLlRtHipExtensorCode: string;
    mtLlRtHipAdductorCode: string;
    mtLlRtKneeFlexorCode: string;
    mtLlRtKneeExtensorCode: string;
    mtLlRtAnklePlantarFlexorCode: string;
}

export interface MuscleStrengthData {
    msUlLtGeneralCode: string;
    msUlRtGeneralCode: string;
    msLlLtHipFlexorCode: string;
    msLlLtHipFlexorOther: string;
    msLlLtHipAbductorCode: string;
    msLlLtHipAbductorOther: string;
    msLlLtHipAdductorCode: string;
    msLlLtHipAdductorOther: string;
    msLlRtHipFlexorCode: string;
    msLlRtHipFlexorOther: string;
    msLlRtHipAbductorCode: string;
    msLlRtHipAbductorOther: string;
    msLlRtHipAdductorCode: string;
    msLlRtHipAdductorOther: string;
    msLlLtKneeFlexorCode: string;
    msLlLtKneeFlexorOther: string;
    msLlLtKneeExtensorCode: string;
    msLlLtKneeExtensorOther: string;
    msLlLtAnkleDorsiflexorCode: string;
    msLlLtAnkleDorsiflexorOther: string;
    msLlLtAnklePlantarflexorCode: string;
    msLlLtAnklePlantarflexorOther: string;
    msLlRtKneeFlexorCode: string;
    msLlRtKneeFlexorOther: string;
    msLlRtKneeExtensorCode: string;
    msLlRtKneeExtensorOther: string;
    msLlRtAnkleDorsiflexorCode: string;
    msLlRtAnkleDorsiflexorOther: string;
    msLlRtAnklePlantarflexorCode: string;
    msLlRtAnklePlantarflexorOther: string;
}

export interface BalanceData {
    balanceStaticSittingBalanceCode: string;
    balanceStaticStandingBalanceCode: string;
    balanceDynamicSittingBalanceCode: string;
    balanceDynamicStandingBalanceCode: string;
}

export interface MovementAnalysisData {
    makAssistiveDeviceCode: string;
    makAssistiveDeviceOther: string;
    makSitStandTime: string;
    makSitStandObservation: string;
    makGaitAnalysisTime: string;
    makGaitAnalysisGaltSpeed: number;
    makGaitAnalysisFacScoreCode: string;
    makGaitAnalysisAffectedSide: string[];
    makGaitAnalysisAffectedSideOther: string;
    makGaitAnalysisUnaffectedSideCode: string;
    makGaitAnalysisUnaffectedSideOther: string;
    makStairAscentTime: string;
    makStairAscentStairs: number;
    makStairAscentObservation: string;
    makStairDescentTime: string;
    makStairDescentStairs: number;
    makStairDescentObservation: string;
    makOtherSquattingAssistiveDeviceCode: string;
    makOtherSquattingAssistiveDeviceOther: string;
    makOtherSquattingAffectdSide: string[];
    makOtherSquattingAffectdSideOther: string;
    makOtherSingleLegStanceAssistiveDeviceCode: string;
    makOtherSingleLegStanceAssistiveDeviceOther: string;
    makOtherLtSingleLegStanceAffectdSide: string[];
    makOtherLtSingleLegStanceAffectdSideOther: string;
    makOtherRtSingleLegStanceAffectdSide: string[];
    makOtherRtSingleLegStanceAffectdSideOther: string;
}

export interface ULData {
    llDescriptionRemark: string;
}

export interface KEData extends UserFittingData, KEMovementAnalysisData {
    reviewAndPrioritizeBaselineData: PrioritizeBaselineData[];
}

export interface UserFittingData {
    keUfiKeeogoBeltSize: string;
    keUfiUserWaistSizeInch: string;
    keUfiUserWaistSizeCm: string;
    keUfiKeeogoContactAreaSizePropriocption: string;
    keUfiKeeogoContactAreaSizesLowerThigh: string;
    keUfiKeeogoContactAreaSizesTibia: string;
    keUfiKeeogoFemoralShaftLengthLt: string;
    keUfiKeeogoFemoralShaftLengthRt: string;
    keUfiFittingChecklistComAttFile: boolean;
    keUfiTurningValidationRecordComAttFile: boolean;
}

export interface KEMovementAnalysisData {
    keMakSitStandObservation: string;
    keMakWalkComfortablyFacScoreCode: string;
    keMakWalkComfortablyAffectedSideCode: string[];
    keMakWalkComfortablyAffectedSideOther: string;
    keMakWalkComfortablyUnaffectedSideCode: string;
    keMakWalkComfortablyUnaffectedSideOther: string;
    keMakWalkFastFacScoreCode: string;
    keMakWalkFastAffectedSideCode: string[];
    keMakWalkFastAffectedSideOther: string;
    keMakWalkFastUnaffectedSideCode: string;
    keMakWalkFastUnaffectedSideOther: string;
    keMakStairAscentStairs: number;
    keMakStairAscentObservation: string;
    keMakStairDescentStairs: number;
    keMakStairDescentObservation: string;
    keMakOtherSquattingAssistiveDeviceCode: string;
    keMakOtherSquattingAssistiveDeviceOther: string;
    keMakOtherSquattingAffectdSide: string[];
    keMakOtherSquattingAffectdSideOther: string;
    keMakOtherSingleLegStanceAssistiveDeviceCode: string;
    keMakOtherSingleLegStanceAssistiveDeviceOther: string;
    keMakOtherLtSingleLegStanceAffectdSide: string[];
    keMakOtherLtSingleLegStanceAffectdSideOther: string;
    keMakOtherRtSingleLegStanceAffectdSide: string[];
    keMakOtherRtSingleLegStanceAffectdSideOther: string;
}

export interface PrioritizeBaselineData {
    prioritizedDeficitsCode: string;
    prioritizedDeficitsDetails: string;
    deficitsResolvedCode: string;
    deficitsResolvedNotes: string;
}

export const defaultValue: EvaluationData = {
    gcMentalStatusCode: 'MSA01',
    gcMotivationCode: 'CON01',
    gcAttentionCode: 'CON01',
    gcCommunicationCode: 'COM01',
    gcCommunicationOther: '',
    gcVisionCode: 'VIS01',
    gcVisionNeglectCode: '',
    gcVisionOther: '',
    gcHearingCode: 'HEA01',
    gcHearingImpairedHardHearingDeafCode: '',
    gcPainCode: 'LOC01',
    gcPainLocationText: '',
    gcPainVasText: '',
    gcSwellingCode: 'LOC01',
    gcSwellingLocationText: '',
    gcSensationLtProprioceptionCode: 'PRO01',
    gcSensationLtProprioceptionText: '',
    gcSensationRtProprioceptionCode: 'PRO01',
    gcSensationRtProprioceptionText: '',
    gcSensationLtLightTouchCode: 'PRO01',
    gcSensationLtLightTouchCodeText: '',
    gcSensationRtLightTouchCode: 'PRO01',
    gcSensationRtLightTouchCodeText: '',
    armUlLtShoulderFlexCode: 'ARM01',
    armUlLtShoulderFlexLimit: 0,
    armUlLtShoulderExtCode: 'ARM01',
    armUlLtShoulderExtLimit: 0,
    armUlLtShoulderAbdCode: 'ARM01',
    armUlLtShoulderAbdLimit: 0,
    armUlLtShoulderAddCode: 'ARM01',
    armUlLtShoulderAddLimit: 0,
    armUlLtShoulderErCode: 'ARM01',
    armUlLtShoulderErLimit: 0,
    armUlLtShoulderIrCode: 'ARM01',
    armUlLtShoulderIrLimit: 0,
    armUlRtShoulderFlexCode: 'ARM01',
    armUlRtShoulderFlexLimit: 0,
    armUlRtShoulderExtCode: 'ARM01',
    armUlRtShoulderExtLimit: 0,
    armUlRtShoulderAbdCode: 'ARM01',
    armUlRtShoulderAbdLimit: 0,
    armUlRtShoulderAddCode: 'ARM01',
    armUlRtShoulderAddLimit: 0,
    armUlRtShoulderErCode: 'ARM01',
    armUlRtShoulderErLimit: 0,
    armUlRtShoulderIrCode: 'ARM01',
    armUlRtShoulderIrLimit: 0,
    armUlLtElbowFlexCode: 'ARM01',
    armUlLtElbowFlexLimit: 0,
    armUlLtElbowExtCode: 'ARM01',
    armUlLtElbowExtLimit: 0,
    armUlLtElbowPronationCode: 'ARM01',
    armUlLtElbowPronationLimit: 0,
    armUlLtElbowSupinationCode: 'ARM01',
    armUlLtElbowSupinationLimit: 0,
    armUlRtElbowFlexCode: 'ARM01',
    armUlRtElbowFlexLimit: 0,
    armUlRtElbowExtCode: 'ARM01',
    armUlRtElbowExtLimit: 0,
    armUlRtElbowPronationCode: 'ARM01',
    armUlRtElbowPronationLimit: 0,
    armUlRtElbowSupinationCode: 'ARM01',
    armUlRtElbowSupinationLimit: 0,
    armUlLtFinerGrippingCode: 'FIN01',
    armUlRtFinerGrippingCode: 'FIN01',
    armLlLtHipFlexCode: 'ARM01',
    armLlLtHipFlexLimit: 0,
    armLlLtHipExtCode: 'ARM01',
    armLlLtHipExtLimit: 0,
    armLlLtHipAbdCode: 'ARM01',
    armLlLtHipAbdLimit: 0,
    armLlLtHipAddCode: 'ARM01',
    armLlLtHipAddLimit: 0,
    armLlLtHipErCode: 'ARM01',
    armLlLtHipErLimit: 0,
    armLlLtHipIrCode: 'ARM01',
    armLlLtHipIrLimit: 0,
    armLlRtHipFlexCode: 'ARM01',
    armLlRtHipFlexLimit: 0,
    armLlRtHipExtCode: 'ARM01',
    armLlRtHipExtLimit: 0,
    armLlRtHipAbdCode: 'ARM01',
    armLlRtHipAbdLimit: 0,
    armLlRtHipAddCode: 'ARM01',
    armLlRtHipAddLimit: 0,
    armLlRtHipErCode: 'ARM01',
    armLlRtHipErLimit: 0,
    armLlRtHipIrCode: 'ARM01',
    armLlRtHipIrLimit: 0,
    armLlLtKneeFlexCode: 'ARM01',
    armLlLtKneeFlexLimit: 0,
    armLlLtKneeExtCode: 'ARM01',
    armLlLtKneeExtLimit: 0,
    armLlRtKneeFlexCode: 'ARM01',
    armLlRtKneeFlexLimit: 0,
    armLlRtKneeExtCode: 'ARM01',
    armLlRtKneeExtLimit: 0,
    armLlLtAnkleDfCode: 'ARM01',
    armLlLtAnkleDfLimit: 0,
    armLlLtAnklePfCode: 'ARM01',
    armLlLtAnklePfLimit: 0,
    armLlLtAnkleInversionCode: 'ARM01',
    armLlLtAnkleInversionLimit: 0,
    armLlLtAnkleEversionCode: 'ARM01',
    armLlLtAnkleEversionLimit: 0,
    armLlRtAnkleDfCode: 'ARM01',
    armLlRtAnkleDfLimit: 0,
    armLlRtAnklePfCode: 'ARM01',
    armLlRtAnklePfLimit: 0,
    armLlRtAnkleInversionCode: 'ARM01',
    armLlRtAnkleInversionLimit: 0,
    armLlRtAnkleEversionCode: 'ARM01',
    armLlRtAnkleEversionLimit: 0,
    mtUlLtShoulderFlexorCode: 'MUT01',
    mtUlLtShoulderAdductorCode: 'MUT01',
    mtUlLtElbowFlexorCode: 'MUT01',
    mtUlLtWristFlexorCode: 'MUT01',
    mtUlRtShoulderFlexorCode: 'MUT01',
    mtUlRtShoulderAdductorCode: 'MUT01',
    mtUlRtElbowFlexorCode: 'MUT01',
    mtUlRtWristFlexorCode: 'MUT01',
    mtLlLtHipFlexorCode: 'MUT01',
    mtLlLtHipExtensorCode: 'MUT01',
    mtLlLtHipAdductorCode: 'MUT01',
    mtLlLtKneeFlexorCode: 'MUT01',
    mtLlLtKneeExtensorCode: 'MUT01',
    mtLlLtAnklePlantarflexorCode: 'MUT01',
    mtLlRtHipFlexorCode: 'MUT01',
    mtLlRtHipExtensorCode: 'MUT01',
    mtLlRtHipAdductorCode: 'MUT01',
    mtLlRtKneeFlexorCode: 'MUT01',
    mtLlRtKneeExtensorCode: 'MUT01',
    mtLlRtAnklePlantarFlexorCode: 'MUT01',
    msUlLtGeneralCode: 'MSG04',
    msUlRtGeneralCode: 'MSG04',
    msLlLtHipFlexorCode: 'MSL05',
    msLlLtHipFlexorOther: '',
    msLlLtHipAbductorCode: 'MSL05',
    msLlLtHipAbductorOther: '',
    msLlLtHipAdductorCode: 'MSL05',
    msLlLtHipAdductorOther: '',
    msLlRtHipFlexorCode: 'MSL05',
    msLlRtHipFlexorOther: '',
    msLlRtHipAbductorCode: 'MSL05',
    msLlRtHipAbductorOther: '',
    msLlRtHipAdductorCode: 'MSL05',
    msLlRtHipAdductorOther: '',
    msLlLtKneeFlexorCode: 'MSL05',
    msLlLtKneeFlexorOther: '',
    msLlLtKneeExtensorCode: 'MSL05',
    msLlLtKneeExtensorOther: '',
    msLlLtAnkleDorsiflexorCode: 'MSL05',
    msLlLtAnkleDorsiflexorOther: '',
    msLlLtAnklePlantarflexorCode: 'MSL05',
    msLlLtAnklePlantarflexorOther: '',
    msLlRtKneeFlexorCode: 'MSL05',
    msLlRtKneeFlexorOther: '',
    msLlRtKneeExtensorCode: 'MSL05',
    msLlRtKneeExtensorOther: '',
    msLlRtAnkleDorsiflexorCode: 'MSL05',
    msLlRtAnkleDorsiflexorOther: '',
    msLlRtAnklePlantarflexorCode: 'MSL05',
    msLlRtAnklePlantarflexorOther: '',
    balanceStaticSittingBalanceCode: 'CON01',
    balanceStaticStandingBalanceCode: 'CON01',
    balanceDynamicSittingBalanceCode: 'CON01',
    balanceDynamicStandingBalanceCode: 'CON01',
    makAssistiveDeviceCode: '',
    makAssistiveDeviceOther: '',
    makSitStandTime: '00:00',
    makSitStandObservation: '',
    makGaitAnalysisTime: '00:00',
    makGaitAnalysisGaltSpeed: 0,
    makGaitAnalysisFacScoreCode: '',
    makGaitAnalysisAffectedSide: [],
    makGaitAnalysisAffectedSideOther: '',
    makGaitAnalysisUnaffectedSideCode: '',
    makGaitAnalysisUnaffectedSideOther: '',
    makStairAscentTime: '00:00',
    makStairAscentStairs: 0,
    makStairAscentObservation: '',
    makStairDescentTime: '00:00',
    makStairDescentStairs: 0,
    makStairDescentObservation: '',
    makOtherSquattingAssistiveDeviceCode: '',
    makOtherSquattingAssistiveDeviceOther: '',
    makOtherSquattingAffectdSide: [],
    makOtherSquattingAffectdSideOther: '',
    makOtherSingleLegStanceAssistiveDeviceCode: '',
    makOtherSingleLegStanceAssistiveDeviceOther: '',
    makOtherLtSingleLegStanceAffectdSide: [],
    makOtherLtSingleLegStanceAffectdSideOther: '',
    makOtherRtSingleLegStanceAffectdSide: [],
    makOtherRtSingleLegStanceAffectdSideOther: '',
    llDescriptionRemark: '',
    keUfiKeeogoBeltSize: '',
    keUfiUserWaistSizeInch: '',
    keUfiUserWaistSizeCm: '',
    keUfiKeeogoContactAreaSizePropriocption: 'M',
    keUfiKeeogoContactAreaSizesLowerThigh: 'S',
    keUfiKeeogoContactAreaSizesTibia: 'S',
    keUfiKeeogoFemoralShaftLengthLt: '',
    keUfiKeeogoFemoralShaftLengthRt: '',
    keUfiFittingChecklistComAttFile: true,
    keUfiTurningValidationRecordComAttFile: true,
    keMakSitStandObservation: '',
    keMakWalkComfortablyFacScoreCode: '',
    keMakWalkComfortablyAffectedSideCode: [],
    keMakWalkComfortablyAffectedSideOther: '',
    keMakWalkComfortablyUnaffectedSideCode: '',
    keMakWalkComfortablyUnaffectedSideOther: '',
    keMakWalkFastFacScoreCode: '',
    keMakWalkFastAffectedSideCode: [],
    keMakWalkFastAffectedSideOther: '',
    keMakWalkFastUnaffectedSideCode: '',
    keMakWalkFastUnaffectedSideOther: '',
    keMakStairAscentStairs: 0,
    keMakStairAscentObservation: '',
    keMakStairDescentStairs: 0,
    keMakStairDescentObservation: '',
    keMakOtherSquattingAssistiveDeviceCode: '',
    keMakOtherSquattingAssistiveDeviceOther: '',
    keMakOtherSquattingAffectdSide: [],
    keMakOtherSquattingAffectdSideOther: '',
    keMakOtherSingleLegStanceAssistiveDeviceCode: '',
    keMakOtherSingleLegStanceAssistiveDeviceOther: '',
    keMakOtherLtSingleLegStanceAffectdSide: [],
    keMakOtherLtSingleLegStanceAffectdSideOther: '',
    keMakOtherRtSingleLegStanceAffectdSide: [],
    keMakOtherRtSingleLegStanceAffectdSideOther: '',
    reviewAndPrioritizeBaselineData: []
}