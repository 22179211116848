import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import { Input } from 'components/form';
import { Channel } from 'api/venue';
import { icon_delete } from 'components/image';
import { PlusOutlined } from '@ant-design/icons';
import { addChannel, deleteChannel } from 'api/venue';
import { Modal } from 'antd';

interface Props {
    currentOrgId: number;
    currentOrgName: string;
    channelList: Channel[];
    onClose: () => void;
    refetch: () => void;
}

const EditDrawer: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const [modifiedChannels, setModifiedChannels] = useState<string[]>(props.channelList.map(d=>d.channelName));
    const [showInput, setShowInput] = useState(false);

    const handleSave = async () => {
        let channelsToAdd: string[] = [];
        for(let i = 0; i < modifiedChannels.length; ++i){
            if(!props.channelList.some(d=>d.channelName===modifiedChannels[i])){
                channelsToAdd.push(modifiedChannels[i]);
            }
        }

        let channelsToDelete: number[] = [];
        for(let i = 0; i < props.channelList.length; ++i){
            if(!modifiedChannels.some(d=>d===props.channelList[i].channelName)){
                channelsToDelete.push(props.channelList[i].id);
            }
        }

        for(let i = 0; i < channelsToDelete.length; ++i){
            await deleteChannel(channelsToDelete[i]);
        }

        addChannel({
            channelList: channelsToAdd,
            organizationId: props.currentOrgId
        }).then(res=>{
            if(res.data.success){
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel: ()=>{
                        props.onClose();
                        props.refetch();
                    }
                });
            }
        }).catch(error=>{
            console.error(error);
            Modal.error({
                title: formatMessage({id:'ERROR'}),
                maskClosable: true,
                content: (
                    <div style={{ wordBreak:'break-word', whiteSpace: 'break-spaces' , textAlign:'center' }}>
                        {
                            typeof (error?.data?.detail)  === 'string'
                            ?
                            error.data.detail
                            :
                            (error?.data?.detail) instanceof Array
                            ?
                            error.data.detail.join('\n')
                            :
                            null
                        }
                    </div>
                )
            });
        })
    }

    const removeChannelFromBuffer = (channelName: string) => {
        let newBuffer = modifiedChannels.slice();
        let removeIndex = newBuffer.indexOf(channelName);
        if(removeIndex >= 0){
            newBuffer.splice(removeIndex, 1);
            setModifiedChannels(newBuffer);
        }
    }

    const addChannelIntoBuffer = (channelName: string) => {
        if(channelName){
            let newBuffer = modifiedChannels.slice();
            newBuffer.push(channelName);
            setModifiedChannels(newBuffer);
        }
    }

    const channels = modifiedChannels.map(d=>(
        <div className='d-flex is--space-between v--center width--full text-16 text--500 mb-10' style={{paddingBottom: '10px', borderBottom: '2px solid #C4C4C4'}}>
            <div>{d}</div>
            <img src={icon_delete} className='cursor--pointer' onClick={()=>removeChannelFromBuffer(d)}/>
        </div>
    ))

    return (
        <Drawer
            visible
            closable
            maskClosable
            onClose={props.onClose as any}
            width='30vw'
            title={formatMessage({id:'EDIT_CHANNEL'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect" onClick={handleSave}>
                        {formatMessage({id:'SAVE'})}
                    </Button>
                </div>
            }
        >
            <div className='d-flex is--space-between v--center mb-15'>
                <div className='text--bold text-24'>{formatMessage({id:'CHANNELS_IN'})} {props.currentOrgName}</div>
                <Button
                    className='rect'
                    icon={<PlusOutlined style={{fontSize: '20px', color: '#FFFFFF'}}/>}
                    onClick={()=>setShowInput(true)}
                    style={{
                        border: 'none',
                        backgroundColor: '#2E63A3'
                    }}
                />
            </div>
            <div className='d-flex is--column v--center'>
                {channels}
                {showInput && 
                    <Input 
                        className='text-16 text--500' 
                        autoFocus 
                        placeholder='Type in ...' 
                        bordered={false} 
                        onBlur={e=>{addChannelIntoBuffer(e.target.value);setShowInput(false);}} 
                    />
                }
            </div>
        </Drawer>
    )
}

export default EditDrawer;
