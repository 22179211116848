import React from 'react';
import { Drawer } from 'antd';
import { DrawerProps } from 'antd/es/drawer';
import styled from 'styled-components';

const StyledDrawer: any = styled(Drawer)`
    .ant-drawer-header,.ant-drawer-body,.ant-drawer-footer {
        padding: 0 45px;
        box-sizing: border-box;
    }

    .ant-drawer-header {
        padding: 1.6rem 45px;
        display: flex;
        background: white;
        align-items:center;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    }

    .ant-drawer-body {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .ant-drawer-footer {
        height: 84px;
        display:flex;
        background: var(--color-gray-light-100);
        align-items:center;
        justify-content:flex-end;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .ant-drawer-close,.ant-drawer-title {
        color: var(--color-primary-dark);
        font-weight: bold;
        font-size: var(--font-size-large)
    }

    .ant-drawer-close{
        height: 100%;
        color: black;
    }

    .ant-form-item-label label{
        color: var(--color-gray-dark);
        font-weight: 500;
        font-size: 1rem;
    }

    .ant-form-item input {
        color: black;
    }

    .ant-form-vertical .ant-form-item-label {
        padding-bottom: 4px;
    }

    textarea.ant-input {
        resize: none;
    }
`;

type Props = DrawerProps & {
    children?: React.ReactNode;
};

export default function DrawerComponent(props: Props) {
    return <StyledDrawer {...props} />;
}
