import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Divider, Radio, Row, Col, Input } from 'antd';
import { GlobalContext } from '../../../../../index';
import { Form } from 'components/form';
import { FormInstance } from 'antd/lib/form';
import { FormTitle } from './EvaluationDrawer';
import { MuscleStrengthData } from '../type';

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 0.7vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

const StyledInput = styled(Input)`
    background-color: #F3F3F3;
    border: none;
`;

interface Props {
    data: MuscleStrengthData;
    form: FormInstance;
    userInfoData: any;
    firstAdd: boolean;
}

const MuscleStrength: React.FC<Props> = ({ data, form, userInfoData, firstAdd }: Props) => {
    
    const { getOptions, nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const { formatMessage } = useIntl();

    useEffect(()=>{
        if(firstAdd){
            if(
                (userInfoData.brunnstormStageUL === 2 || userInfoData.brunnstormStageUL === 8) && 
                (userInfoData.brunnstormStageLL === 4 || userInfoData.brunnstormStageLL === 10)
            ){
                form.setFieldsValue({
                    msUlLtGeneralCode: 'MSG07',
                    msUlRtGeneralCode: 'MSG07'
                });
            }
            else if(
                userInfoData.mainDiagnosis1 === nameMapping['MAI01'] && userInfoData.mainDiagnosis2 === nameMapping['NEU01'] &&
                ((userInfoData.brunnstormStageUL >= 0 && userInfoData.brunnstormStageUL <= 3) || (userInfoData.brunnstormStageUL >= 6 && userInfoData.brunnstormStageUL <= 9) ||
                (userInfoData.brunnstormStageLL >= 0 && userInfoData.brunnstormStageLL <= 3) || (userInfoData.brunnstormStageLL >= 6 && userInfoData.brunnstormStageLL <= 9))
            ){
                form.setFieldsValue({
                    msUlLtGeneralCode: 'MSG07',
                    msUlRtGeneralCode: 'MSG07',
                    msLlLtHipFlexorCode: 'MSL09',
                    msLlLtHipAbductorCode: 'MSL09',
                    msLlLtHipAdductorCode: 'MSL09',
                    msLlRtHipFlexorCode: 'MSL09',
                    msLlRtHipAbductorCode: 'MSL09',
                    msLlRtHipAdductorCode: 'MSL09',
                    msLlLtKneeFlexorCode: 'MSL09',
                    msLlLtKneeExtensorCode: 'MSL09',
                    msLlLtAnkleDorsiflexorCode: 'MSL09',
                    msLlLtAnklePlantarflexorCode: 'MSL09',
                    msLlRtKneeFlexorCode: 'MSL09',
                    msLlRtKneeExtensorCode: 'MSL09',
                    msLlRtAnkleDorsiflexorCode: 'MSL09',
                    msLlRtAnklePlantarflexorCode: 'MSL09'
                });
            }
        }
    }, []);

    const MuscleStrengthGeneral = getOptions('MuscleStrengthGeneral').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    const MuscleStrengthLL = getOptions('MuscleStrengthLL').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    return (
        <div>
            <div className='text-24 text--bold'>{formatMessage({id:'MUSCLE_STRENGTH'})}<span style={{fontWeight: 500}}> - {formatMessage({id:'MMT_SCALE'})}</span></div>
            
            
            <div className='text-20 text--bold mb-15'>{formatMessage({id:'UPPER_LIMBS'})}</div>

            <Row gutter={24}>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'LT'})}</div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'RT'})}</div>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'GENERAL'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="msUlLtGeneralCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthGeneral}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="msUlRtGeneralCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthGeneral}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>



            <div className='text-20 text--bold mb-15 mt-30'>{formatMessage({id:'LOWER_LIMBS'})}</div>

            <Row gutter={24}>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'LT'})}</div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'RT'})}</div>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'HIP_FLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="msLlLtHipFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlLtHipFlexorCode==='MSL10'&&<Form.Item name="msLlLtHipFlexorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="msLlRtHipFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlRtHipFlexorCode==='MSL10'&&<Form.Item name="msLlRtHipFlexorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'HIP_ABDUCTOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="msLlLtHipAbductorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlLtHipAbductorCode==='MSL10'&&<Form.Item name="msLlLtHipAbductorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="msLlRtHipAbductorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlRtHipAbductorCode==='MSL10'&&<Form.Item name="msLlRtHipAbductorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'HIP_ADDUCTOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="msLlLtHipAdductorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlLtHipAdductorCode==='MSL10'&&<Form.Item name="msLlLtHipAdductorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="msLlRtHipAdductorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlRtHipAdductorCode==='MSL10'&&<Form.Item name="msLlRtHipAdductorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'KNEE_FLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="msLlLtKneeFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlLtKneeFlexorCode==='MSL10'&&<Form.Item name="msLlLtKneeFlexorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="msLlRtKneeFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlRtKneeFlexorCode==='MSL10'&&<Form.Item name="msLlRtKneeFlexorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'KNEE_EXTENSOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="msLlLtKneeExtensorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlLtKneeExtensorCode==='MSL10'&&<Form.Item name="msLlLtKneeExtensorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="msLlRtKneeExtensorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlRtKneeExtensorCode==='MSL10'&&<Form.Item name="msLlRtKneeExtensorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'ANKLE_DORSIFLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="msLlLtAnkleDorsiflexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlLtAnkleDorsiflexorCode==='MSL10'&&<Form.Item name="msLlLtAnkleDorsiflexorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="msLlRtAnkleDorsiflexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlRtAnkleDorsiflexorCode==='MSL10'&&<Form.Item name="msLlRtAnkleDorsiflexorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'ANKLE_PLANTARFLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="msLlLtAnklePlantarflexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlLtAnklePlantarflexorCode==='MSL10'&&<Form.Item name="msLlLtAnklePlantarflexorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="msLlRtAnklePlantarflexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleStrengthLL}</Radio.Group>
                    </Form.Item>

                    {data.msLlRtAnklePlantarflexorCode==='MSL10'&&<Form.Item name="msLlRtAnklePlantarflexorOther">
                        <StyledInput placeholder='Others' maxLength={500} />
                    </Form.Item>}
                </Col>
            </Row>
        </div>
    );
};

export default MuscleStrength;
