import React from 'react';
import { useIntl } from 'react-intl';
import { EditWrapper } from './index';
import { stair_descent } from 'components/image';
import { Form, TextArea } from 'components/form';
import { FormTitle } from '../EvaluationForms/EvaluationDrawer';
import TimeInput from 'react-advanced-time-input';
import NumberInput from 'components/numberInput/numberInput';

interface Props {
    KE?: boolean;
}

const StairDescentEdit: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();

    return (
        <EditWrapper>
            <div className="left">
                <div className="icon">
                    <img src={stair_descent} width='100%' />
                </div>
                <div className="header">
                    {formatMessage({id:'ITEM_STAIR_DESCENT'})}
                </div>
            </div>
            <div className="right">
                <div className='d-flex'>
                    <Form.Item className='mr-50' label={<FormTitle>{formatMessage({id:'TIME'})}</FormTitle>} name={props.KE ? 'keMakStairDescentTime' : 'makStairDescentTime'}>
                        <TimeInput 
                            maxHours={99} 
                            style={{
                                width: '60px', 
                                border: 'none', 
                                backgroundColor: '#F3F3F3', 
                                textAlign: 'center', 
                                fontSize: '20px',
                                fontWeight: 'bold'
                            }} 
                        />
                    </Form.Item>
                    <Form.Item label={<FormTitle>{formatMessage({id:'STAIRS'})}</FormTitle>} name={props.KE ? 'keMakStairDescentStairs' : 'makStairDescentStairs'}>
                        <NumberInput bold allowZero editable/>
                    </Form.Item>
                </div>
                <Form.Item className='width--full' label={<FormTitle>{formatMessage({id:'OBSERVATION'})}</FormTitle>} name={props.KE ? 'keMakStairDescentObservation' : 'makStairDescentObservation'}>
                    <TextArea rows={4} placeholder='(content)' maxLength={500}/>
                </Form.Item>
            </div>
        </EditWrapper>
    )
}

export default StairDescentEdit;
