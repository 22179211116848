import {
    OverView,
    Login,
    RoleMgmt,
    UserMgmt,
    OrganizationMgmt,
    CaseMgmt,
    Keeogo,
    CaseProfile,
    Policy,
    GeoMap,
    ParameterMgmt,
    ForgetPassword,
    TherapyList,
    VenueList

} from '../components/component';

export const routerConfig = [
    {
        path: '/login',
        component: Login,
        auth: false,
        exact: false,
    },
    {
        path: '/forgetPassword',
        component: ForgetPassword,
        auth: false,
        exact: false,
    },
    {
        path: '/',
        component: OverView,
        auth: true,
        exact: true,
    },
    {
        path: '/overView',
        component: OverView,
        auth: true,
        exact: true,
    },
    {
        path: '/Access',
        component: RoleMgmt,
        auth: true,
        exact: true,
    },
    {
        path: '/Account',
        component: UserMgmt,
        auth: true,
        exact: true,
    },
    {
        path: '/Group',
        component: OrganizationMgmt,
        auth: true,
        exact: true,
    },
    {
        path: '/Patient/:id',
        component: CaseProfile,
        auth: true,
        exact: true,
    },
    {
        path: '/Patient',
        component: CaseMgmt,
        auth: true,
        exact: true,
    },
    {
        path: '/policy',
        component: Policy,
        auth: false,
        exact: true,
    },
    {
        path: '/geoMap',
        component: GeoMap,
        auth: true,
        exact: true,
    },
    {
        path: '/parameterMgmt',
        component: ParameterMgmt,
        auth: true,
        exact: true,
    },
    {
        path: '/Therapy',
        component: TherapyList,
        auth: true,
        exact: true,
    },
    {
        path: '/Venue',
        component: VenueList,
        auth: true,
        exact: true,
    },
];
