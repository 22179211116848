import styled from 'styled-components';
import { Switch } from 'antd';

const StyledSwitch = styled(Switch)`
    &.ant-switch-checked {
        background-color: #2E63A3;
    }
`;

export const DisactiveSpan = styled.span`
        display: inline-block;
        border: 1px solid #0DA5AB;
        border-radius: 14px;
        width: 58px;
        color: #0DA5AB;
        text-align: center;`

export const ActiveSpan = styled.span`
        display: inline-block;
        border: 1px solid #B9B9B9;
        border-radius: 14px;
        width: 58px;
        color: #B9B9B9;
        background-color: #F2F2F2;
        text-align: center;`

export default StyledSwitch;
