import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Modal, Row, Col } from 'antd';
import Button from 'components/button/button';
import ReactPlayer from 'react-player';
import { Video } from '../SummaryEdit';

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 1rem;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 10px;

            .Title {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .name {
                    font-weight: bold;
                    font-size: 18px;
                    color: #000000;
                }

                .datetime {
                    font-size: 12px;
                    color: #2E2E2E;
                }
            }
        }

        .player {
            aspect-ratio: 1.3;
            width: 100%;
            background-color: #2E2E2E;
            display: flex;
            align-items: center;
            padding: 10px;
        }
    }
`;

interface DetailProps {
    onClose: () => void;
    video: Video;
    selected: boolean;
    onSelect: (id: string) => void;
}

const VideoCompareDetail: React.FC<DetailProps> = (props: DetailProps) => {

    const { formatMessage } = useIntl();
    const [selected, setSelected] = useState(props.selected);

    return (
        <StyledModal
            visible
            maskClosable
            closable={false}
            footer={null}
            onCancel={props.onClose}
            width='30vw'
            mask={false}
            centered
            style={{
                transform: 'translateX(20vw)',
                animation: 0
            }}
        >
            <div className='header'>
                <div className='Title'>
                    <div className='name'>{`${props.video.packageName} - ${props.video.className}`}</div>
                    <div className='datetime'>{props.video.timestamp.format('DD/MM/YYYY HH:mm')}</div>
                </div>
                <Button
                    type='link'
                    onClick={()=>{props.onSelect(props.video.id);setSelected(!selected);}}
                    style={{
                        color: selected ? '#0083C1' : '#2E2E2E'
                    }}
                >{formatMessage({id: selected ? 'SELECTED' : 'SELECT'})}</Button>
            </div>
            <div className='player'>
                <Row gutter={24}>
                    {props.video.upload ? (
                        <Col span={24}>
                            <ReactPlayer url={props.video.uploadView} controls width='100%' height='100%' />
                        </Col>
                    ) : (
                        <div className='d-flex width--full'>
                            <Col span={12}>
                                <ReactPlayer url={props.video.frontalView} controls width='100%' height='100%' />
                            </Col>
                            <Col span={12}>
                                <ReactPlayer url={props.video.sagittalView} controls width='100%' height='100%' />
                            </Col>
                        </div>
                    )}
                </Row>
            </div>
        </StyledModal>
    )
}

export default VideoCompareDetail;
