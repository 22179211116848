import React, { useState, useEffect ,useContext} from 'react';
import styled from 'styled-components';
import { Radio } from 'antd';
import { useIntl } from 'react-intl';
import { PageProps } from './RecordTab';
import RecordCard from './RecordCard';
import Button from 'components/button/button';
import { PlusOutlined } from '@ant-design/icons';
import { Select } from 'components/form';
import AddRecordDrawer from './AddRecordDrawer';
import { getListRecord, Record } from 'api/record';
import { getTreatment } from 'api/case';
import { icon_record_package_left,icon_record_package_right} from 'components/image';
import { GlobalContext } from '../../../../index';
import { icon_add_new_plan } from 'components/image';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const Wrapper = styled.div`
    width: 100%;
    background-color: #FFFFFF;
    padding: 15px 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .Title {
            color: #2E2E2E;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .headerPackage{
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top:10px;

        .aVerticalSeparator { 
            border-left: 2px solid #909090; 
             width: 1px;
             height: 2.1rem;
         } 

         .packageType {
            width:100%;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                height: 0;
            }

            .ant-radio-group{
                .ant-radio-button-wrapper {
                    border: none;
                    text-align: center;
                    background-color:#F3F3F3;
                    color: #000000;
                    border-radius: 0.1rem;
                    font-weight: normal;
                    white-space: nowrap;
                    margin-right:10px;
    
                    &:first-child {
                        margin-left: 0px;
                    }
    
                    &:last-child {
                        margin-right: 0px;
                    }
    
                    &::before {
                        display: none;
                    }
                }
    
                .ant-radio-button-wrapper-checked {
                    background-color: #5DD197;
                    color: #FFFFFF;
                    
                }
            }
        }
        .stateType {
            .ant-radio-group{
                .ant-radio-button-wrapper {
                    border: none;
                    text-align: center;
                    background-color:#FFFFFF;
                    color: #000000;
                    border-radius: 0.1rem;
                    font-weight: normal;
                    white-space: nowrap;
    
                    &:first-child {
                        margin-left: 0px;
                    }
    
                    &:last-child {
                        margin-right: 0px;
                    }
    
                    &::before {
                        display: none;
                    }
                }
    
                .ant-radio-button-wrapper-checked {
                    background-color: #2E2E2E;
                    color: #FFFFFF;
                    
                }
            }
        }
    }

    .headerPackage{
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top:10px;
    }
`;

const RecordList: React.FC<PageProps> = (props: PageProps) => {
    const { formatMessage } = useIntl();
    const [showAddDrawer, setShowAddDrawer] = useState(false);
    const [allTreatment, setAllTreatment] = useState<any[]>([]);
    const [packageFilter, setPackageFilter] = useState({
        finish: false,
        treatmentType: 'TRE01' //  only Keeogo for now
    });
    const [selectedPackage, setSelectedPackage] = useState(-1);
    const [recordFilter, setRecordFilter] = useState({
        trainingItem: '',
        desc: true
    });
    const [listData, setListData] = useState<Record[]>([]);
    const [packageListScroll, setPackageListScroll] = useState(0);
    const [packageListScrollWidth, setPackageListScrollWidth] = useState(0);
    const [packageListClientWidth, setPackageListClientWidth] = useState(0);

    const { getOptions, nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const [permission] = usePermission('Record');

    useEffect(()=>{
        setPackageListScrollWidth(document.getElementById('packageList')?.scrollWidth ?? 0);
        setPackageListClientWidth(document.getElementById('packageList')?.clientWidth ?? 0);
    });

    useEffect(()=>{
        getTreatment(
            props.caseId,
            packageFilter.finish
        ).then(res=>{
            setAllTreatment(res.data.data.filter((d:any)=>d.treatment_course.treatmentTypeCode===nameMapping[packageFilter.treatmentType]));
            setSelectedPackage(res.data.data.filter((d:any)=>
                d.treatment_course.treatmentTypeCode===nameMapping[packageFilter.treatmentType] &&
                d.treatment_course.classTypeCode===nameMapping['CLA02']
            )?.[0]?.id ?? -1);
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }, [packageFilter]);
    
    useEffect(() => {
        if(selectedPackage >= 0){
            getListRecord(
                {
                    personalInfoBasicId: props.caseId,
                    trainingTypeCode: recordFilter.trainingItem,
                    desc: recordFilter.desc,
                    personalTreatmentPlanMasterId: selectedPackage
                }
            ).then((res: any) => {
                setListData(res.data.data);
            }).catch((error: any) => {
                console.error(error);
                throw (error);
            });
        }
    }, [recordFilter, selectedPackage]);

    const listMoveLeft = () => {
        const element = document.getElementById('packageList');
        const limit = 0;
        if(element?.scrollLeft === limit){
            return;
        }
        element?.scroll({
            left: element.scrollLeft - 300 < limit ? limit : element.scrollLeft - 300,
            behavior: 'smooth'
        })
    }

    const listMoveRight = () => {
        const element = document.getElementById('packageList');
        const limit = (element?.scrollWidth ?? 0) - (element?.clientWidth ?? 0)
        if(element?.scrollLeft === limit){
            return;
        }
        element?.scroll({
            left: element.scrollLeft + 300 > limit ? limit : element.scrollLeft + 300,
            behavior: 'smooth'
        })
    }

    const packageList = allTreatment.map((d: any, i:number)=>( 
        <StyledRadioButton 
            value={d.id}
            style={{
                backgroundColor: selectedPackage === d.id ? '#5DD197' : '#FFFFFF',
                color: selectedPackage === d.id ? '#FFFFFF' : '#000000'
            }}
        >
            {d.treatment_course.classTypeCode === nameMapping['CLA01'] ? nameMapping['CLA01'] : d.treatment_course.name.replace(nameMapping[packageFilter.treatmentType],'')}
        </StyledRadioButton>      
    )); 

    const trainingItemFilterOptions = getOptions('TrainingType').map((d: any, i: number)=>(
        <Select.Option key={i+1} value={d.code}>{d.name}</Select.Option>
    ));
    trainingItemFilterOptions.unshift(
        <Select.Option key={0} value=''>{formatMessage({ id: 'ALL' })}</Select.Option>
    );
 
    const recordCardData = listData.filter((d: any) => d.treatmentTypeCode ==='TRE01').map(function (record) {
        return <RecordCard key={record.trainingId} recordData={record} pageProps={props} className='cursor--pointer mb-15' />
    });
    
    return (
        <Wrapper>
            {showAddDrawer && <AddRecordDrawer onClose={() => setShowAddDrawer(false)} {...props} />}
            <div style={{ position: 'sticky', top: 0, backgroundColor: '#FFFFFF', marginTop: '-15px', padding: '15px 0', zIndex: 1000 }}>
                <div className="header v--center">
                    <div className="d-flex Title">
                        <b><strong>{formatMessage({ id: 'DAILY_NOTES' })}</strong></b>
                    </div>
                    <div className="d-flex">
                        {checkIfAvailable(permission, 'Filter') && 
                        <div className='d-flex'>
                            <div>
                                <strong><b className='mr-5'>{formatMessage({ id: 'TRAINING_ITEMS' })}</b></strong>
                                <Select
                                    style={{ width: '120px' }}
                                    className='mr-10'
                                    allowClear={false}
                                    bordered={false}
                                    value={recordFilter.trainingItem}
                                    onChange={e => setRecordFilter({...recordFilter, trainingItem: e})}
                                >
                                    {trainingItemFilterOptions}
                                </Select>
                            </div>
                            <div>
                                <strong><b className='mr-5'>{formatMessage({ id: 'SORT_BY' })}</b></strong>
                                <Select
                                    style={{ width: '120px' }}
                                    className='mr-10'
                                    allowClear={false}
                                    bordered={false}
                                    value={+recordFilter.desc}
                                    onChange={e => setRecordFilter({...recordFilter, desc: Boolean(e)})}
                                >
                                    <Select.Option key={1} value={1}>{formatMessage({ id: 'TIME_DESC' })}</Select.Option>
                                    <Select.Option key={2} value={0}>{formatMessage({ id: 'TIME_ASC' })}</Select.Option>
                                </Select>
                            </div>
                        </div>}
                        {checkIfAvailable(permission, 'Add') && <Button
                            className="rect ml-5 d-flex v--center"
                            icon={<PlusOutlined style={{ fontSize: '18px' }} />}
                            onClick={()=>setShowAddDrawer(true)}
                            style={{ backgroundColor: '#0083C1', color: '#FFFFFF' }}
                        >{formatMessage({ id: 'ADD' })} 
                        </Button>}
                    </div>
                </div>
                <div  className="headerPackage v--center">
                    <Label style={{ backgroundColor: '#2E63A3', color: '#FFFFFF' }}>{nameMapping['TRE01']}</Label>
                    <div className='aVerticalSeparator'></div>
                    <img 
                        src={icon_record_package_left} 
                        alt='left' 
                        onClick={packageListScroll === 0 ? ()=>{} : listMoveLeft} 
                        style={{ 
                            opacity: packageListScroll === 0 ? 0.3 : 1,
                            cursor: packageListScroll === 0 ? 'not-allowed' : 'pointer'
                        }}
                    />
                    <div className="packageType" id='packageList' onScroll={()=>setPackageListScroll(document.getElementById('packageList')?.scrollLeft ?? 0)}>
                        <Radio.Group buttonStyle='solid' onChange={(e)=>{ setSelectedPackage(e.target.value)}} value={selectedPackage}>
                            {packageList} 
                        </Radio.Group>
                    </div>
                    <img 
                        src={icon_record_package_right} 
                        alt='right' 
                        onClick={packageListScroll === (packageListScrollWidth - packageListClientWidth) ? ()=> {} : listMoveRight}
                        style={{
                            opacity: packageListScroll === (packageListScrollWidth - packageListClientWidth) ? 0.3 : 1,
                            cursor: packageListScroll === (packageListScrollWidth - packageListClientWidth) ? 'not-allowed' : 'pointer'
                        }}
                    />
                    <div className='aVerticalSeparator  ml-10 mr-10'></div>
                    <div className="stateType">
                        <Radio.Group buttonStyle='solid' onChange={e=>setPackageFilter({...packageFilter, finish: e.target.value})} value={packageFilter.finish}>
                            <StyledRadioButton value={false}>{formatMessage({ id: 'IN_PROCESS' })}</StyledRadioButton>
                            <StyledRadioButton value={true}>{formatMessage({ id: 'CAPITAL_LETTER_FINISHED' })}</StyledRadioButton>
                        </Radio.Group>
                    </div>
                </div>
                <div className="headerPackage2 v--center" style={{display:recordCardData.length!==0?'none':''}}>
                    <Wrapper style={{height: '150rem'}}>
                        <StyledNoData>
                            <div className='noData'>
                                <img src={icon_add_new_plan} alt="add new record" />
                                <div className="desc">
                                    {formatMessage({id:'FILTER_NOT_FOUND'})}
                                </div>
                            </div>
                        </StyledNoData>
                    </Wrapper>
                </div>
            </div>
            <div style={{display:recordCardData.length===0?'none':''}}>
                {recordCardData}
            </div>
        </Wrapper>
    )
}

export default RecordList;

const Label = styled.label` 
    padding:3px 5px 4px 5px;
    background-color:#2E63A3;
    color: #000000;
    border-radius: 0.1rem;
    font-weight: normal;
    white-space: nowrap;
    margin-right:10px;
`;

const StyledRadioButton = styled(Radio.Button)`
    padding:1px 8px 5px 8px;

    &.ant-radio-button-wrapper-disabled:hover {
        color: rgba(0, 0, 0, 0.25) !important;
    }
`

const StyledNoData = styled.div`
    .noData {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top:10rem;

        img {
            width: 100px;
            height: 100px;
        }

        .desc {
            font-size: 18px;
            color: #909090;
        }

        .newPlanBtn {
            font-size: 32px;
            color: #0083C1;
            font-weight: bold;
            margin-top: 20px;
        }
    }
`;

