import React, { useState } from 'react';
import { Modal } from 'antd';
import Table from 'components/table/table';
import roleList from './roleList';
import './roleMgmt.sass';
import styled from 'styled-components';
import Button from 'components/button/button';
import { useIntl } from 'react-intl';

export const EditFunc: React.FC<any> = (props: any) => {
    const intl = useIntl();
    const { onCancel, defaultFunc, setMenu } = props;
    const [selectedRowKeys, setSelectedRowKeys] = useState(defaultFunc);
    const columns = [
        {
            title: intl.formatMessage({id:'ROLE_FN_NAME'}), // 功能名稱
            dataIndex: 'name',
            className: 'column-center-align'
        }
    ];

    const list = roleList.map(({id, name}) => ({id, name}));

    const rowSelection = {
        selectedRowKeys,
        onChange: (key: any) => {
            setSelectedRowKeys(key);
        },
    }

    return (
        <StyledModal
            className='editFunc'
            title={intl.formatMessage({id:'ROLE_RIGHT_SELECT_FUNCTION'})}
            visible={true}
            style={{ left:'37%',top:'3%' }}
            width='20%'
            centered={true}
            onCancel={onCancel}
            footer={
                <div style={{
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems:'center',
                    padding: '1.2rem 1rem'
                }}>
                    <Button
                    className='rect'
                        onClick={onCancel}
                        style={{ minWidth:'40%',marginRight:'10%',fontSize:16 }}
                    >
                        {intl.formatMessage({id:'CANCEL'})}
                    </Button>
                    <Button
                        className='is--gray-dark rect'
                        onClick={() => setMenu(selectedRowKeys)}
                        style={{ minWidth:'40%' ,background:'#2E63A3',marginRight:'-3%',fontSize:16}}
                    >
                        {intl.formatMessage({id:'ROLE_RIGHT_SELECT'})}
                    </Button>
                </div>}
        >
            <Table
                rowKey='id'
                rowSelection={rowSelection}
                columns={columns}
                dataSource={list}
                pagination={false}
                limitScroll=''
            />
        </StyledModal>
    );
};

const StyledModal = styled(Modal)`
    .ant-modal {
        right: 400px;
        top: 50%;
    }
    .ant-table tbody > tr > td{
        padding: 9px 0px !important;
    }

    .ant-table thead > tr > th{
        padding: 9px 0px !important;
    }
    .ant-modal-header {
        background: white;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
        font-weight: bold
    }
    .ant-modal-content {
        width: 97%;
      }
    .ant-modal-body {
        height: 40vh;
        overflow-y: scroll;
    }

    .ant-modal-close-x{
        color: black;
        font-weight: bold;
        font-size: 22px;
        span {
            color: black; !important;
        }
    }
    .ant-modal-title {
        color: rgb(46, 99, 163) !important;
        font-weight: bold;
        font-size: 22px;
    }

    .ant-modal-footer {
        padding: 1px 41px 20px 0;
        height:64px
    }

`;


