import React, { useState, useEffect } from 'react';
import { Tooltip, Col, Row, Input, Drawer, Spin, Dropdown, Menu, Modal } from 'antd';
import Pagination from 'components/pagination/pagination';
import Button from 'components/button/button';
import Table from 'components/table/table';
import { EditFilled, BranchesOutlined, PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { icon_edit_blue, warning } from 'components/image';
import { StyleProgress } from '../../components/component';

import { OrganizationMgmtDrawer } from './organizationMgmtDrawer';
import moment from 'moment';

import { apiCaller } from '../../service/apiCaller';

import { getOrganization, getAllPlan, deleteOrganization } from '../../api/organizationMgmtApi';
import { Organization, Plan } from './type'
import usePermission  from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

import { statusTag } from '../../components/tag/tag';

import CardList from 'components/card/cardList';
import { useIntl } from 'react-intl';
import { Search } from 'components/form/input';

import { OrgType } from '../../constants/types'
import styled from 'styled-components';
import Filter from 'components/filter/filter';
import { FormInstance } from 'antd/lib/form';


type DetailType = {
    visible: boolean,
    type: 'edit'|'add'
    data: Organization | {}
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const OrganizationMgmt: React.FC<any> = (props: any) => {
    const intl = useIntl();

    const [myPermission] = usePermission('Group');
    const [data, setData] = useState<Organization[]>([]);
    const [loading,setLoading] = useState(false);

    const [detail,setDetail] = useState<DetailType>({
        visible: false,
        type:'edit',
        data: {}
    });

    const [setting, setSetting] = useState({
        showDeleteModal: false,
        organizationToDelete: null
    });

    const [paginationTotal, setPaginationTotal] = useState(0)
    const [pagination, setPagination] = useState({
        pageSize:10,
        page:1
    })

    useEffect(() => {
        getList({
            page: pagination.page,
            pageSize: pagination.pageSize
        });
    }, [pagination]);

    const getList = async (queryParams: {
        page: number,
        pageSize: number,
        keyword?: string,
        startDate?: string,
        endDate?: string
    }) => {

        try{
            setLoading(true)

            await apiCaller(getAllPlan, '', async (result: any) => {
                await apiCaller(getOrganization, queryParams, (res: any) => {
                    const rows = res.data.data;
                    rows.forEach((r: Organization) => {
                        if (!r.planName && r.planId) {
                            const pln = result.data.filter((s: any) => s.id === r.planId);
                            if (pln) {
                                r.planName = pln[0].name;
                            }
                        }
                    });
                    setPaginationTotal(res.data.total)
                    setData(rows)
                }, (error: any) => {throw(error)});
            }, (error: any) =>{throw(error)});

        }catch(e){
            console.error(e)
        }finally{
            setLoading(false)
        }

    }

    const columns = [
        // 組織單位名稱
        {
            dataIndex: 'name',
            title: intl.formatMessage({id:'ORG_NAME'}),
            sorter: (a: any, b: any)=>{
                const _a = `${a.name}`
                const _b = `${b.name}`
                return _a.localeCompare(_b)
            },
            width: 160,
            textWrap: 'word-break',
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        // 名稱縮寫 - abbreviation
        {
            dataIndex:'abbreviation',
            title:intl.formatMessage({id:'ORG_ABBRE'}),
            sorter: (a: any, b: any)=>{
                const _a = `${a.abbreviation}`
                const _b = `${b.abbreviation}`
                return _a.localeCompare(_b)
            },
            width: 160,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        // 類型
        {
            dataIndex:'type',
            title:intl.formatMessage({id:'TYPE'}),
            render: (type:string)=>type?OrgType[type]:'--',
            sorter: (a: any, b: any)=>{
                const _a = OrgType[a.type]
                const _b = OrgType[b.type]
                return _a.localeCompare(_b)
            },
            width: 110,
            ellipsis: true
        },
        // 統一編號
        {
            dataIndex:'tax',
            title:intl.formatMessage({id:'VAT'}),
            sorter: (a: any, b: any)=>{
                const _a = a.tax
                const _b = b.tax
                return Number(_a) - Number(_b)
            },
            width: 100,
            align: 'right' as 'right',
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        // 授權人數
        // {
        //     dataIndex:'planLimit',
        //     title:`${intl.formatMessage({id:'ORG_AUTHED_USERS'})}`,
        //     sorter: (a: any, b: any)=>{
        //         const _a = a.planLimit
        //         const _b = b.planLimit
        //         return Number(_a) - Number(_b)
        //     },
        //     width: 150,
        //     render: (text:string, record:any)=>text?text:'--',
        //     ellipsis: true
        // },
        // 更新日期
        {
            dataIndex:'updatedAt',
            title:intl.formatMessage({id:'UPDATE_DATE'}),
            render: (updatedAt:any)=>updatedAt?moment(updatedAt).format('DD/MM/YYYY'):'--',
            sorter: (a: any, b: any)=>{
                const _a = moment(a.updatedAt).unix()
                const _b = moment(b.updatedAt).unix()
                return Number(_a) - Number(_b)
            },
            width: 150,
            ellipsis: true
        },
        // 狀態
        {
            dataIndex:'active',
            title:intl.formatMessage({id:'STATUS'}),
            render: (active:boolean)=>StatusTag(active),
            width: 100,
            ellipsis: true
        },
        {
            key: 'action',
            width: 50,
            render: (text:any, record:any) => checkIfAvailable(myPermission, 'Edit') && (
                <Button
                    type='link'
                    style={{display: 'flex', alignItems: 'center'}}
                    icon={<img src={icon_edit_blue} className='mr-10'/>}
                    data-id={record.id}
                    onClick={()=>setDetail(prv=>({
                        ...prv,
                        type:'edit',
                        visible:true,
                        data:record
                    }))}
                />
            )
        }
    ]

    const onFilterSubmit = (form: FormInstance) => {
        const keyword = form.getFieldValue('search');
        const rangeDate = form.getFieldValue('date') || [moment(''), moment('')];
        const startDate = rangeDate[0]?.isValid()?rangeDate[0].format('YYYY/MM/DD'):undefined;
        const endDate = rangeDate[1]?.isValid()?rangeDate[1].format('YYYY/MM/DD'):undefined;

        getList({
            page: pagination.page,
            pageSize: pagination.pageSize,
            keyword: keyword,
            startDate: startDate,
            endDate: endDate
        })
    }
    
    // data 's getter
    // const getListView = ()=>{
    //     let result = data

    //     // keyword
    //     if(keyword){
    //         result = data.filter((t: Organization) => {
    //             const reg = new RegExp(keyword,'g')
    //             return t.name && reg.test(t.name) || t.abbreviation && reg.test(t.abbreviation)
    //         })
    //     }
    //     return result
    // }

    const handleDeleteOrganization = (org: Organization) => {
        const success = (res: any) => {
            const { status, data } = res;
            if (status === 200) {
                getList({
                    page: 1,
                    pageSize: pagination.pageSize
                });
                setPagination({page: 1, pageSize: pagination.pageSize});
                setSetting({ ...setting, showDeleteModal: false, organizationToDelete: null })
            }
        };
        const reject = (error: any) => {
            const { status } = error;
            console.log(status);
        };
        apiCaller(deleteOrganization, org.id, success, reject);
    };
    
    return (
        <Wrapper>

            {setting.showDeleteModal&&<DeleteOrganization initData={setting.organizationToDelete} del={handleDeleteOrganization} close={()=>setSetting({showDeleteModal: false, organizationToDelete: null})} />}

            <div className='main'>
                <div className="header">
                    {/* 組織單位管理 */}
                    <b><strong>{intl.formatMessage({id:'ORG_MGMT'})}</strong></b>
                    <div>
                        {/*checkIfAvailable(myPermission, 'Search') && <Filter onSubmit={onFilterSubmit} searchBy={intl.formatMessage({id:'ORG_NAME'})}/>*/}
                        {/* 新增 */}
                        {
                            checkIfAvailable(myPermission, 'Add') &&
                            <Button
                                icon={<PlusOutlined />}
                                className='is--primary ml-16  rect'
                                onClick={()=>setDetail(prv=>({
                                    ...prv,
                                    visible:true,
                                    type:'add',
                                    data:{}
                                }))}
                            >
                            </Button>
                        }
                    </div>
                </div>
                
                <Spin spinning={loading}>
                    <Table
                        dataSource={data}
                        columns={columns}
                        pagination={false}
                        footer={()=>
                            <Pagination 
                                pagination={pagination} 
                                setPagination={setPagination} 
                                paginationTotal={paginationTotal}
                            />
                        }
                    />

                </Spin>
                {
                    detail.visible && <OrganizationMgmtDrawer
                        onClose={()=>setDetail(prv => ({...prv,visible:false}))}
                        onRefresh={()=>{
                            getList({
                                page: 1,
                                pageSize: pagination.pageSize
                            });
                            setPagination({page: 1, pageSize: pagination.pageSize});
                        }}
                        data={detail.data as Organization}
                        type={detail.type}
                    />
                }
            </div>
        </Wrapper>
    );
}

const DeleteOrganization: React.FC<any> = (props: any) => {
    const intl = useIntl();
    const { close, initData, del } = props;
    const StyledModal = styled(Modal)`
        .ant-modal-content {
            border-radius: 1rem;
        }
    `;

    return (
        <StyledModal
            visible={true}
            closable={false}
            footer={null}
            width={350}
        >
            <div style={{
                textAlign: 'center',
                display:'flex',
                flexDirection:'column',
                justifyContent: 'space-around',
                minHeight: '400px'
            }}>

                <img
                    style={{
                        width: '100px',
                        display: 'block',
                        margin: '0 auto'
                    }}
                    src={warning}
                />

                <div>
                    <h1 className='mt-10 mb-10 text--lg' >
                        {intl.formatMessage({id:'DELETE_USER'})} {/*刪除角色*/}
                    </h1>

                    <p>
                        {intl.formatMessage({id:'DELETE_CONFIRM'})} {/*是否確定刪除*/}
                        {initData.name} ?
                    </p>
                </div>

                <div>
                    <Button
                        className='is--warning mt-10 mb-10'
                        onClick={() => del(initData)}
                        style={{width:'100%'}}
                    >
                        {intl.formatMessage({id:'DELETE'})} {/*刪除*/}
                    </Button>

                    <Button
                        className='is--plain mt-10 mb-10'
                        onClick={close}
                        style={{width:'100%'}}
                    >
                        {intl.formatMessage({id:'CANCEL'})} {/*取消*/}
                    </Button>
                </div>

            </div>
        </StyledModal>
    );
};

const StatusTag = (enable: boolean) => {
    const style = {
        backgroundColor: enable?'#2E63A3':'#C4C4C4',
        color: 'white',
        borderRadius: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3px 10px',
        fontSize: ''
    };

    return (
        <div style={style}>
            {enable?'Enable':'Disable'}
        </div>
    )
}
