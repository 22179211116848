import React from 'react';
import { Modal, Radio } from 'antd';
import { icon_info_outlined } from 'components/image';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import Drawer from 'components/drawer/drawer';
import { Form, TextArea } from 'components/form';
import { addFinishRemark } from 'api/case';
import { apiCaller } from 'service/apiCaller';

const StyledModal = styled(Modal)`
    .ant-modal-content{
        border-radius:1rem;
    }
`;

interface ToggleFinishModalProps {
    onClose: Function;
    handleOk: Function;
    nextFinishStatus: boolean;
}

export const ToggleFinishModal: React.FC<ToggleFinishModalProps> = (props: ToggleFinishModalProps) => {

    const { formatMessage } = useIntl();

    return (
        <StyledModal
            visible={true}
            closable={false}
            footer={null}
            width={400}
        >
            <div style={{
                textAlign: 'center',
                display:'flex',
                flexDirection:'column',
                justifyContent: 'space-around',
                minHeight: '400px'
            }}>

                <img
                    style={{
                        width: '60px',
                        display: 'block',
                        margin: '50px auto 30px auto'
                    }}
                    src={icon_info_outlined}
                />

                <div>
                    <h1 className='mb-10 text--lg' style={{color: '#47A6D8', fontWeight: 'bold', fontSize: '22px'}} >
                        {formatMessage({id:'MODIFIED_REQUIRED'})}
                    </h1>

                    <p style={{padding: '10px 10%', fontSize: '12px'}}>
                        {formatMessage({id:'MODIFIED_DESCRIPTION'}, {isFinish: props.nextFinishStatus?formatMessage({id:'FINISHED'}):formatMessage({id:'UNFINISHED'})})}
                    </p>
                </div>

                <div>
                    <Button
                        className='is--plain mt-10 mb-20'
                        onClick={()=>props.handleOk()}
                        style={{width:'75%', height:'40px', backgroundColor: '#47A6D8', color: 'white', fontWeight: 'bold', border: 'none'}}
                    >
                        {formatMessage({id:'CONTINUE'})}
                    </Button>
                    <Button
                        className='is--plain mt-10 mb-20'
                        type='link'
                        onClick={()=>props.onClose()}
                        style={{width:'75%', height:'40px', backgroundColor: 'white', color: '#727272', fontWeight: 'bold', border: 'none'}}
                    >
                        {formatMessage({id:'CANCEL'})}
                    </Button>
                </div>

            </div>
         </StyledModal>
    )
}

const StyledDrawer = styled(Drawer)`
    .ant-form-item-label {
        .ant-form-item-required {
            &::before {
                content: '';
            }
        }
    }

    .ant-drawer-body {
        .ant-form {
            .label {
                font-size: 14px;
            }

            .ant-radio-group {
                display: flex;
                flex-direction: column;

                .ant-radio-wrapper {
                    margin: 5px 0;
                }
            }
        }
    }
`;

interface ModifiedRecordDrawerProps {
    onClose: Function;
    nextFinishStatus: boolean;
    planId: number;
    afterDone: Function; 
    refetch: Function; 
    finishLength:number
}

export const ModifiedRecordDrawer: React.FC<ModifiedRecordDrawerProps> = (props: ModifiedRecordDrawerProps) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();

    const handleOk = () => {
        form.validateFields().then(values=>{
            const success = (res: any) => {
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel: ()=>{
                        props.onClose();
                        props.afterDone();
                        if(props.nextFinishStatus===false&&props.finishLength===1){
                            props.refetch({inFinish: false,desc:false})
                        }else if(props.nextFinishStatus===false){
                            props.refetch({inFinish: true,desc:true})
                        }
                    }
                });
               
            };

            const reject = (err: any) => {
                console.error(err);
                throw(err);
            };

            apiCaller(
                addFinishRemark,
                {
                    ...values,
                    planMasterId: props.planId,
                    device: 'web'
                },
                success,
                reject
            );
        })
       
    }
    
    return (
        <StyledDrawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='30vw'
            title={formatMessage({id:'ADD_MODIFIED'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect" onClick={handleOk}>
                        {formatMessage({id:'FINISH_UPPERCASE'})}
                    </Button>
                </div>
            }
        >
            {
                props.nextFinishStatus?
                <Form form={form} layout='vertical'>
                    <div className="label">{formatMessage({id:'FINISH_REASON'})}</div>
                    <Form.Item  name='finishReason' rules={[{required: true}]}>
                        <Radio.Group>
                            <Radio value='completed'>{formatMessage({id:'COMPLETED'})}</Radio>
                            <Radio value='refund'>{formatMessage({id:'REFUND'})}</Radio>
                            <Radio value='other'>{formatMessage({id:'OTHER'})}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item 
                        name='recordRemark'
                        rules={[{required: true}]}
                    >
                        <TextArea rows={4} placeholder="(Content)"/>
                    </Form.Item>
                </Form>:
                <Form form={form} layout='vertical'>
                    <Form.Item 
                        label={formatMessage({id:'MODIFIED_RECORD'})} 
                        name='recordRemark' 
                        rules={[{required: true}]}
                    >
                        <TextArea rows={4} placeholder="(Content)"/>
                    </Form.Item>
                </Form>
            }
        </StyledDrawer>
    )
}
