import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/button/button';
import Drawer from 'components/drawer/drawer';
import { Radio, Form } from 'antd';
import { GlobalContext } from '../../../../index';
import SummaryEdit from './SummaryEdit';
import { SummaryProps } from './SummaryTab';
import ImageMapping from 'constants/imageMapping';

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .ant-form {
        height: 100%;
    }

    .ant-form-item, .ant-form-item-control, .ant-form-item-control-input, .ant-form-item-control-input-content {
        height: 100%;
    }

    .ant-radio-group {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .ant-radio-button-wrapper {
            height: auto;
            wdith: 100%;
            aspect-ratio: 2.25;
            border-radius: 0.5rem;
            font-weight: 600;
            font-size: 16px;
            border: none;
            padding: 0;

            img {
                width: 100%;
                aspect-ratio: 2.25;
                object-fit: cover;
                border-radius: 0.3rem;
            }

            .text {
                position: absolute;
                bottom: 10px;
                left: 15px;
                color: #FFFFFF;
                text-shadow: 0px 2px 4px #00000080;
                size: 5px;
            }

            &:hover {
                color: #FFFFFF;
            }

            &::before {
                display: none;
            }
        }

        .ant-radio-button-wrapper-checked {
            border: solid 3px #47A6D8;
            color: #47A6D8;
        }
    }
`;

interface AddReportProps {
    setPageContent: React.Dispatch<React.SetStateAction<JSX.Element>>;
    onClose: () => void;
    summaryProps: SummaryProps;
}

const AddReportDrawer: React.FC<AddReportProps> = (props: AddReportProps) => {

    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();

    const classTypeOptions = getOptions('TreatmentType').map((d: any)=>(
        <Radio.Button value={d.code}>
            <img src={ImageMapping.TreatmentType[d.code]}/>
            <span className='text'>{d.name}</span>
        </Radio.Button>
    ));

    const handleNext = () => {
        form.validateFields().then(values=>{
            props.setPageContent(<SummaryEdit {...props.summaryProps} treatmentType={values.treatmentTypeCode}/>)
        });
    }

    return (
        <StyledDrawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='30vw'
            title={formatMessage({id:'NEW_PLAN_REPORT'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect" onClick={handleNext}>
                        {formatMessage({id:'NEXT'})}
                    </Button>
                </div>
            }
        >
            <Form form={form} layout='vertical'>
                <Form.Item name='treatmentTypeCode' rules={[{required: true, message: `${formatMessage({id:'TREATMENT_TYPE'})} is required`}]}>
                    <Radio.Group>
                        {classTypeOptions}
                    </Radio.Group>
                </Form.Item>
            </Form>
        </StyledDrawer>
        
    )
}

export default AddReportDrawer;
