import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { gait_analysis } from 'components/image';
import { ViewWrapper } from './index';
import { GlobalContext } from '../../../../../index';

interface Props {
    data: {
        time: string;
        fac: string;
        affected: string[];
        affectedOther: string;
        unaffected: string;
        unaffectedOther: string;
    };
}

const GaitAnalysisView: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const time = props.data.time;
    const minute = parseInt(time.split(':')[0]);
    const second = parseInt(time.split(':')[1]);
    const totalTime = minute * 60 + second;
    const speed = (10 / totalTime).toFixed(1);

    const otherText = props.data.affected.some(d=>d === nameMapping['GAS08']) ? (
        <div>
            {nameMapping['GAS08']} - {props.data.affectedOther.split('\n').map(d=><span>{d}<br/></span>)}
        </div>
    ) : undefined;

    const unaffectedText = props.data.unaffected === nameMapping['UNA01'] ? props.data.unaffected : 
                            props.data.unaffected === nameMapping['UNA02'] ? (nameMapping['UNA02'] as string) + ' - ' + props.data.unaffectedOther : '--';

    return (
        <ViewWrapper>
            <div className="header">
                {formatMessage({id:'ITEM_GAIT_ANALYSIS'}, {br: ' '})}
            </div>
            <div className="content">
                <div className="left">
                    <div className="icon">
                        <img src={gait_analysis} width='100%' />
                    </div>
                </div>
                <div className="right">
                    <div className='d-flex mb-15'>
                        <div className='label' style={{width: '50%'}}>
                            <div className="name">{formatMessage({id:'TIME'})}</div>
                            <div className="text large">{props.data.time || '--'}</div>
                        </div>
                        <div className='label' style={{width: '50%'}}>
                            <div className="name">{formatMessage({id:'GAIT_SPEED'})}</div>
                            <div className="text large">{(totalTime > 0 ? speed : '--') + ' m/s'}</div>
                        </div>
                    </div>
                    <div className='label'>
                        <div className="name">{formatMessage({id:'FAC'})}</div>
                        <div className="text large">{props.data.fac || '--'}</div>
                    </div>
                    <div className='label'>
                        <div className="name mb-5">{formatMessage({id:'GAIT_PATTERN'})}</div>
                    </div>
                    <div className='label'>
                        <div className="name">{'● '+formatMessage({id:'AFFECTED_SIDE'})}</div>
                        <div className="text">{props.data.affected.filter(d=>d!==nameMapping['GAS08']).join(', ') || '--'}<br/>{otherText}</div>
                    </div>
                    <div className='label'>
                        <div className="name">{'● '+formatMessage({id:'UNAFFECTED_SIDE'})}</div>
                        <div className="text">{unaffectedText}</div>
                    </div>
                </div>
            </div>
        </ViewWrapper>
    )
}

export default GaitAnalysisView;
