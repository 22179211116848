import { api } from './api';
import { ResetPasswordRequest, SetNewPasswordRequest } from './type';

const baseUrl = '';

// login
export enum E_device {
    pc = 'pc',
    mobile = 'mobile'
}
export type I_login = {
    username: string;
    password: string;
    device: E_device
}

export const loginApi = (data: I_login) => api.post(`${baseUrl}/user/login`, data);

// Repeat login
export const repeatLoginApi = (sequenceId: string) => api.post(`${baseUrl}/user/login/${sequenceId}`, {});

// first login
export const firstLoginApi = (sequenceId: string) => api.post(`${baseUrl}/user/login/eula/${sequenceId}`, {});

// get premission
export const getPermissionApi = (userId: string) => api.get(`${baseUrl}/user/permission/${userId}`);

// logout
export const logoutApi = (device: E_device) => api.post(`${baseUrl}/user/logout`, {device});

/**
 * 忘記密碼Api
 *
 * @param {ResetPasswordRequest | SetNewPasswordRequest} req
 */
export const resetPasswordApi = (req: ResetPasswordRequest|SetNewPasswordRequest) => api.post(`${baseUrl}/user/resetPassword`, req);
