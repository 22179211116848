import React, { useState, useEffect, useContext } from 'react';
import KraChartTab from './KRAChartTab';
import { GlobalContext } from '../../../../../index';
import ImageMapping from 'constants/imageMapping';
import { icon_remove_black } from 'components/image';
import { KraConfig } from '../SummaryEdit';
import { ChartData } from './KRAChartTab';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getConfig } from 'api/record';

interface KRABlockProps {
    removeKraConfig: (trainingType: string) => void;
    kraConfig: KraConfig;
    edit?: boolean;
}

const KRABlock: React.FC<KRABlockProps> = (props: KRABlockProps) => {

    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const [chartData, setChartData] = useState<ChartData[]>([]);
    const [chartLoading, setChartLoading] = useState(true);

    useEffect(()=>{
        Promise.all(props.kraConfig.configId.map(id=>(
            getConfig(id)
        ))).then(values=>{
            setChartData(values.map(res=>({
                level: res.data.data.level,
                assistDevice: res.data.data.assistDevice,
                configId: res.data.data.id,
                flexionHipAngleLeft: res.data.data.flexionHipAngleLeft,
                flexionHipAngleRight: res.data.data.flexionHipAngleRight,
                extensionHipAngleLeft: res.data.data.extensionHipAngleLeft,
                extensionHipAngleRight: res.data.data.extensionHipAngleRight,
                flexionKneeAngleLeft: res.data.data.flexionKneeAngleLeft,
                flexionKneeAngleRight: res.data.data.flexionKneeAngleRight,
                extensionKneeAngleLeft: res.data.data.extensionKneeAngleLeft,
                extensionKneeAngleRight: res.data.data.extensionKneeAngleRight,
                timeStep: res.data.data.timeStep,
                avgStepLength: res.data.data.avgStepLength,
                kraDateTime: res.data.data.dateTime
            })));
            setChartLoading(false);
        }).catch(err=>{
            console.error(err);
        })
    }, []);

    return (
        <div>
            <div className='d-flex v--center'>
                <img src={ImageMapping.KRAExamination[props.kraConfig.trainingType]} className='mr-20'/>
                <div className='mr-20 text-20 text--bold'>{nameMapping[props.kraConfig.trainingType]}</div>
                {props.edit ? <img src={icon_remove_black} style={{cursor: 'pointer'}} onClick={()=>props.removeKraConfig(props.kraConfig.trainingType)}/> : undefined}
            </div>
            <Spin indicator={<LoadingOutlined style={{fontSize: '24px'}} spin/>} spinning={chartLoading}>
                <KraChartTab canvasId={`view-chart-${props.kraConfig.trainingType}`} examItem={props.kraConfig.trainingType} data={chartData} />
            </Spin>
        </div>
    )
}

export default KRABlock;
