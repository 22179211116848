import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Table from 'components/table/table';
import Button from 'components/button/button';
import Pagination, {PaginationState} from 'components/pagination/pagination';
import { AvatarComponent } from 'components/avatar/avatar';
import Filter from 'components/filter/filter';
import { TodayButton, LastDate, NextDate } from 'components/dateController';
import { Spin } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { PlusOutlined } from '@ant-design/icons';
import usePermission  from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DataSource } from 'constants/types';
import { Case } from 'types/Case';
import { getTherapy } from 'api/case';
import { apiCaller } from 'service/apiCaller';
import { icon_no_patient } from 'components/image';
import { SorterResult } from 'antd/lib/table/interface';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .ant-table {
        .ant-table-body {
            table {
                tbody {
                    tr {
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;

const TherapyList: React.FC = () => {

    const { formatMessage } = useIntl();
    const history = useHistory();
    const [myPermission] = usePermission('Therapy');

    const [date, setDate] = useState(moment());
    const [keyword, setKeyword] = useState<string|undefined>();
    const [sort, setSort] = useState<string>();
    const [desc, setDesc] = useState(true);
    const [startDate, setStartDate] = useState<string|undefined>();
    const [endDate, setEndDate] = useState<string|undefined>();
    const [displayDate, setDisplayDate] = useState(date.format('dddd, DD/MM/YYYY'));
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize:10,
        page:1
    });
    const [list, setList] = useState<DataSource<Case>>({
        content: [],
        total: 0
    });

    useEffect(()=>{
        getListData({
            page: pagination.page,
            pageSize: pagination.pageSize,
            startDate: date.format('YYYY/MM/DD')
        });
    }, []);

    useEffect(()=>{
        getListData({
            page: pagination.page,
            pageSize: pagination.pageSize,
            name: keyword,
            startDate: startDate,
            endDate: endDate,
            sort: sort,
            desc: desc
        });
    }, [pagination, sort, desc]);

    const getListData = async (args: {
        page: number, pageSize: number,
        username?: string, name?: string,
        startDate?: string, endDate?: string,
        sort?: string, desc?: boolean
    }) => {
        try{
            setLoading(true);
            await apiCaller(
                getTherapy,
                args,
                (res: any) => {
                    setList({
                        content: res.data.data,
                        total: res.data.total
                    })
                },
                (error: any) => {
                    throw(error);
                }
            )
        }
        catch(err){
            console.error(err);
            throw(err);
        }
        finally{
            setLoading(false);
        }
    }

    const onDateChange = (date: moment.Moment) => {
        setDate(date);
        setStartDate(date.format('YYYY/MM/DD'));
        setEndDate(undefined);
        setDisplayDate(date.format('dddd, DD/MM/YYYY'));

        setPagination({page: 1, pageSize: pagination.pageSize});
    }

    const onFilterSubmit = (form: FormInstance) => {
        const formKeyword = form.getFieldValue('search');
        const rangeDate = form.getFieldValue('date') || [moment(''), moment('')];
        const startDate = rangeDate[0]?.isValid()?rangeDate[0]:undefined;
        const endDate = rangeDate[1]?.isValid()?rangeDate[1]:undefined;
        
        setKeyword(formKeyword);
        setDate(moment());
        setStartDate(startDate?.format('YYYY/MM/DD'));
        setEndDate(endDate?.format('YYYY/MM/DD'));
        if(startDate && endDate){
            setDisplayDate((startDate?.format('dddd, DD/MM/YYYY')||'--')+(endDate?(' - '+endDate?.format('dddd, DD/MM/YYYY')):''));
        }

        setPagination({page: 1, pageSize: pagination.pageSize});
    }

    const getListView = ()=>{
        let res: any[] = list.content

        return res.map(row=>Object.assign({}, row, {key: row.id}));
    }

    let onRowClick = (record:any)=>{
        return {
            onClick: () => history.push(`/Patient/${record.userId}?tabKey=Plan`)
        }
    };

    const columns = [
        //avatar
        {
            key: 'avatar',
            width: 50,
            render: (text: any, record: any) => {
                const imgPath = record.imgPath?.data?`data:image/png;base64,${btoa(record.imgPath.data.reduce((data: any, byte: any) => data + String.fromCharCode(byte), ''))}`:undefined;
                return (
                    <AvatarComponent src={imgPath} />
                )
            }
        },
        // 姓名
        {
            dataIndex: 'name',
            title: formatMessage({id:'NAME'}),
            render: (text:string, record:any)=>text?text:'--',
            sorter: true,
            width: 145,
            ellipsis: true
        },
        // 方案
        {
            dataIndex: 'caseTypeCode',
            title: formatMessage({id:'TYPE'}),
            render: (text:string, record:any)=>text || '--',
            sorter: true,
            width: 85,
            ellipsis: true
        },
        // Age
        {
            dataIndex: 'birthday',
            title: formatMessage({id:'AGE'}),
            render: (text:string, record:any)=>text?moment().diff(text, 'year'):'--',
            sorter: true,
            width: 75,
            ellipsis: true
        },
        // IC Number
        {
            dataIndex: 'icNumber',
            title: formatMessage({id:'IC_NUMBER'}),
            render: (text:string, record:any)=>text?text:'--',
            sorter: true,
            width: 120,
            ellipsis: true,
            align: 'right' as 'right'
        },
        // 病歷
        {
            key: 'mainDiagnosis1',
            title: formatMessage({id:'MAIN_DIAGNOSIS'}),
            render: (text:string, record:any)=>{
                const diagnosis1 = record.mainDiagnosis1_abbr || '--';
                const diagnosis2 = record.mainDiagnosis2_abbr?('-'+record.mainDiagnosis2_abbr):'';
                return diagnosis1+diagnosis2;
            },
            sorter: true,
            width: 150,
            ellipsis: true
        },
        // Keeogo裝置
        {
            dataIndex: 'waistBeltCode',
            title: formatMessage({id:'DEVICE'}),
            render: (text:string, record:any)=>text?'Belt '+text:'--',
            sorter: true,
            width: 110,
            ellipsis: true
        },
        // 組織縮寫
        // {
        //     dataIndex: 'abbreviation',
        //     title: formatMessage({id:'ORG_NAME'}),
        //     sorter: (a:any,b:any)=> a.abbreviation?.localeCompare(b.abbreviation),
        //     render: (text:string, record:any)=>text?text:'--',
        //     width: 140,
        //     ellipsis: true
        // },
        // 療程日期
        {
            dataIndex: 'treatmentDate',
            title: formatMessage({id:'TREATMENT_DATE'}),
            sorter: true,
            render: (text:any, record:any)=>(text?moment(text).format('DD/MM/YYYY'):'--') + (` ${record.treatmentHour}` ?? ''),
            width: 200,
            ellipsis: true
        }
    ];

    return (
        <Wrapper className='main'>
            <React.Fragment>
                <div className="header">
                    <div className="d-flex v--center">
                        {/* 個案列表 */}
                        <b><strong>{formatMessage({id:'THERAPY_LIST'})}</strong></b>
                        <TodayButton setDate={onDateChange} />
                        <LastDate setDate={onDateChange} date={date}/>
                        <NextDate setDate={onDateChange} date={date}/>
                        {displayDate}
                    </div>
                    <div className="d-flex">
                        {checkIfAvailable(myPermission, 'Search') && 
                            <Filter 
                                onSubmit={onFilterSubmit} 
                                searchBy={formatMessage({id:'PATIENT_NAME'}).toLowerCase()} 
                                dateWording={formatMessage({id:'TREATMENT_DATE'})}
                            />}
                    </div>

                </div>

                <Spin spinning={loading} >
                    <Table 
                        columns={columns}
                        dataSource={getListView()}
                        pagination={false}
                        onRow={onRowClick}
                        onChange={(pagination, filters, sorter, extra)=>{
                            const sorterDetail = sorter as SorterResult<any>;
                            if(sorterDetail.column){
                                setSort((sorterDetail.field || sorterDetail.columnKey) as string);
                                setDesc(sorterDetail.order === 'descend' ? true : false);
                            }
                            else{
                                setSort(undefined);
                                setDesc(true);
                            }
                        }}
                        footer={getListView().length>0 ? ()=><Pagination 
                            pagination={pagination} 
                            setPagination={setPagination} 
                            paginationTotal={list.total || 1}
                        /> : undefined}
                        locale={{
                            emptyText: (
                                <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={icon_no_patient} style={{width: '140px', height: '140px', objectFit: 'contain'}} />
                                    <div style={{fontSize: '24px', color: '#2E2E2E', fontWeight: 'bold'}}>
                                        {formatMessage({id:'PATIENT_NOT_FOUND'})}
                                    </div>
                                </div>
                            )
                        }}
                    />

                </Spin>
            </React.Fragment>
        </Wrapper>
    )
}

export default TherapyList;
