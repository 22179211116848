import React from 'react';
import styled from 'styled-components';
import { css } from '@emotion/core';
import { FadeLoader } from 'react-spinners';

const Loadingspin = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .3);
  z-index: 20000;
`;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top: calc(50vh - 30px);
    left: calc(50vw - 30px);
`;

export const LoadingBar = () => {
    return (
        <Loadingspin id='loadingspin'>
          <FadeLoader
            css={override}
            color={'#fff'}
            loading={true}
          />
        </Loadingspin>
    );
};
