import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';
import Button from 'components/button/button';
import SeparatedTable from 'components/table/seperatedTable';
import {
    add_full, gary_cross, copy_btn, icon_success, foot,
    hand, machine_foot, small_success, icon_info_outlined
} from 'components/image';
import { PageProps } from './EvaluationTab';
import EvaluationPage from './EvaluationPage';
import moment from 'moment';
import { PageOption } from './EvaluationPage';
import styled from 'styled-components';
import EvaluationDrawer from './EvaluationForms/EvaluationDrawer';
import { PlusOutlined } from '@ant-design/icons';
import RoundButton from 'components/roundedButton/roundedButton';
import { findOneEvaluation, getEvaluation } from 'api/eavluation';
import { EvaluationBrief, EvaluationDetail } from './type';
import Pagiantion from 'components/pagination/pagination';
import { GlobalContext } from '../../../../index';
import ImageMapping from 'constants/imageMapping';
import PlanDateSelectDrawer from './PlanDateSelectDrawer';

const StyledSeparatedTable = styled(SeparatedTable)`
    .ant-table-row:hover {
        td {
            background-color: #FFFFFF!important;
        }
    }

    .suspend {
        background-color: #F3F3F3!important;
        color: #909090!important;

        &:hover {
            td {
                background-color: #F3F3F3!important;
            }
        }
        
        .ant-table-column-sort {
            background-color: #F3F3F3!important;
        }
    }
`;

const ExaminationCard = styled.div<{border?: boolean, bgc?: string}>`
    width: 30%;
    aspect-ratio: 3;
    background-color: ${props => props.bgc ?? '#FFFFFF'};
    border: ${props => props.border ? '1px solid #707070' : 'none' };
    border-radius: 0.5rem;
    padding: 10px;

    .left {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .header {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .icons {
            display: flex;

            img {
                width: 80%;
                height: 80%;
            }
        }
    }

    .right {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const RoundedModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 0.5rem;
    }
`;

const EvaluationList: React.FC<PageProps> = (props: PageProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const [drawerSetting, setDrawerSetting] = useState<{
        showDateDrawer: boolean, 
        showEvaluationDrawer: boolean,
        drawerData?: EvaluationDetail, 
        planDetailId: number, 
        copy: boolean,
        treatmentDate: string,
        treatmentHour: string,
        packageName: string,
        className: string
    }>({
        showDateDrawer: false,
        showEvaluationDrawer: false,
        drawerData: undefined,
        planDetailId: 0,
        copy: false,
        treatmentDate: '',
        treatmentHour: '',
        packageName: '',
        className: ''
    });
    const [copySetting, setCopySetting] = useState({
        showModal: false,
        copyId: 0
    });

    const [pagination, setPagination] = useState({
        pageSize: 10,
        page: 1
    });
    const [preliminaryRecord, setPreliminaryRecord] = useState<EvaluationBrief>();
    const [hasPreliminary, setHasPreliminary] = useState(false);

    const fetchPreliminaryRecord = () => {
        getEvaluation({
            personalInfoBasicId: props.caseId,
            page: 1,
            pageSize: 10,
            isPreliminary: true
        }).then(res=>{
            setPreliminaryRecord(res.data.data[0]);
            setHasPreliminary(res.data.data.length > 0);
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }

    useEffect(()=>{
        fetchPreliminaryRecord();
    }, []);

    useEffect(()=>{
        props.refetch({page: pagination.page, pageSize: pagination.pageSize});
    }, [pagination]);

    const confirmCopy = () => {
        findOneEvaluation(
            copySetting.copyId
        ).then(res=>{
            setDrawerSetting({
                showDateDrawer: true,
                showEvaluationDrawer: false,
                drawerData: res.data.data.evaluation_detail,
                planDetailId: 0,
                copy: true,
                treatmentDate: '',
                treatmentHour: '',
                packageName: '',
                className: ''
            });
            setCopySetting({
                showModal: false,
                copyId: 0
            });
        }).catch(err=>{
            console.error(err)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        });
    };

    const columns = [
        {
            title: formatMessage({id:'CLASS'}),
            dataIndex: 'treatmentName',
            width: 150,
            render: (text: any, record: any) => text ? text : '--'
        },
        // Deprecated
        // {
        //     title: formatMessage({id:'ADDED_DATE'}),
        //     dataIndex: 'evaluationDate',
        //     key: 'evaluationDate',
        //     width: 150,
        //     render: (text: any) => text ? moment(text).format('DD/MM/YYYY') : '--',
        //     sorter: (a:any,b:any)=> (moment(a.evaluationDate).isValid()&&moment(b.evaluationDate).isValid())?moment(b.evaluationDate).diff(a.evaluationDate):-1
        // },
        {
            title: formatMessage({id:'THERAPIST'}),
            dataIndex: 'therapist',
            key: 'therapist',
            width: 120,
            render: (text: any) => text ? text : '--'
        },
        // Deprecated
        // {
        //     title: formatMessage({id:'CLASS_DATE'}),
        //     dataIndex: 'planDate',
        //     key: 'planDate',
        //     width: 120,
        //     render: (text: any) => text ? moment(text).format('DD/MM/YYYY') : '--'
        // },
        {
            title: formatMessage({id:'REMARK'}),
            dataIndex: 'kraList',
            key: 'kraList',
            width: 500,
            render: (text: any, record: any) => (
                <div className='d-flex width--full'>
                    {text ? text.slice(0, 3).map((d: string)=>(
                        <div className="d-flex v--center" style={{marginRight: '7%'}}>
                            <div className='d-flex h--center v--center mr-5' style={{backgroundColor: '#F3F3F3'}}>
                                <img src={ImageMapping.KRAExamination[d]} width='30px' height='30px' />
                            </div>
                            <span>{nameMapping[d]}</span>
                        </div>
                    )) : undefined}
                    {text ? (text.length > 3 &&<span style={{position:'relative', right: 0, color: '#47A6D8'}}>+{text.length-3}</span>) : undefined}
                </div>
            ),
        },
        {
            title: formatMessage({id:'MODIFY_DATE'}),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: 120,
            render: (text: any) => moment(text).format('DD/MM/YYYY')
        },
        {
            title: '',
            dataIndex: 'copy',
            key: 'copy',
            width: 50,
            render: (text: any, record: any) => (
                <div style={{ display: record.status ? 'block' : 'none' }}>
                    <img src={copy_btn} onClick={e=>{e.stopPropagation();setCopySetting({showModal: true, copyId: record.id});}} alt="" />
                </div>
            ),
        },
    ];

    const onRowClick = (record: any) => {
        return {
            onClick: () => {
                props.setPageContent(<EvaluationPage {...props} hasPreliminary={hasPreliminary} pageType={PageOption.View} evaluationData={record} />)
            },
        };
    };

    return (
        <div>
            {drawerSetting.showEvaluationDrawer && 
                <EvaluationDrawer 
                    tabKey='GeneralCondition' 
                    copy={drawerSetting.copy} 
                    editData={drawerSetting.drawerData} 
                    evaluationDate={moment().format('YYYY/MM/DD')} 
                    planId={drawerSetting.planDetailId}
                    onClose={()=>setDrawerSetting({
                        showDateDrawer: false,
                        showEvaluationDrawer: false,
                        drawerData: undefined, 
                        planDetailId: 0,
                        copy: false,
                        treatmentDate: '',
                        treatmentHour: '',
                        packageName: '',
                        className: ''
                    })} 
                    userInfoData={props.userInfoData} 
                    treatmentData={props.treatmentData} 
                    refetch={()=>{fetchPreliminaryRecord();props.refetch();}} 
                    caseId={props.caseId}
                    hasPreliminary={hasPreliminary}
                    kraData={[]}
                    treatmentDate={drawerSetting.treatmentDate}
                    treatmentHour={drawerSetting.treatmentHour}
                    packageName={drawerSetting.packageName}
                    className={drawerSetting.className}
                />
            }
            {
                drawerSetting.showDateDrawer && 
                <PlanDateSelectDrawer
                    treatmentData={props.treatmentData}
                    onSet={(planDetail: {
                        planId: number,
                        treatmentDate: string,
                        treatmentHour: string,
                        packageName: string,
                        className: string
                    })=>setDrawerSetting({
                        ...drawerSetting, 
                        showDateDrawer: false,
                        showEvaluationDrawer: true,
                        planDetailId: planDetail.planId,
                        treatmentDate: planDetail.treatmentDate,
                        treatmentHour: planDetail.treatmentHour,
                        packageName: planDetail.packageName,
                        className: planDetail.className
                    })}
                    onClose={()=>setDrawerSetting({
                        showDateDrawer: false,
                        showEvaluationDrawer: false,
                        drawerData: undefined,
                        planDetailId: 0,
                        copy: false,
                        treatmentDate: '',
                        treatmentHour: '',
                        packageName: '',
                        className: ''
                    })} 
                />
            }
            {copySetting.showModal&&
            <RoundedModal
                visible
                closable={false}
                width='350px'
                footer={null}
                style={{borderRadius: '0.5rem'}}
            >
                <div className='d-flex is--column v--center'>
                    <img className='mb-15' src={icon_info_outlined}/>
                    <div className='text--bold text-20 mb-20' style={{color: '#47A6D8'}}>
                        {formatMessage({id:'DUPLICATE_CONFIRM'})}
                    </div>
                    <Button
                        onClick={confirmCopy}
                        style={{
                            width: '60%',
                            color: '#FFFFFF',
                            backgroundColor: '#47A6D8',
                            fontWeight: 'bold',
                            marginBottom: '5px'
                        }}
                    >{formatMessage({id:'YES'})}</Button>
                    <Button
                        type='link'
                        onClick={()=>{setCopySetting({showModal:false, copyId:0});}}
                        style={{color: '#2E2E2E', fontWeight: 'bold'}}
                    >{formatMessage({id:'CANCEL'})}</Button>
                </div>
            </RoundedModal>}

            <div style={{backgroundColor: '#FFFFFF', padding: '15px', marginBottom: '20px'}}>
                <div className='text-24 text--bold mb-30 d-flex'>
                    {formatMessage({id:'PRELIMINARY_EXAMINATION'})}
                </div>
                <div className='d-flex is--space-between'>
                    <ExaminationCard className='cursor--pointer' bgc='#F3F3F3' onClick={
                        hasPreliminary ? 
                            ()=>props.setPageContent(<EvaluationPage {...props} hasPreliminary={hasPreliminary} pageType={PageOption.View} evaluationData={preliminaryRecord} />) : 
                            ()=>setDrawerSetting({showDateDrawer: true, showEvaluationDrawer: false, drawerData: undefined, planDetailId: 0, copy: false, treatmentDate: '', treatmentHour: '', packageName: '', className: ''})
                    }>
                        {hasPreliminary ? (
                            <div className='d-flex is--space-between v--center height--full'>
                                <div className="left">
                                    <div className="header">
                                        <span className='mr-10'>
                                            {preliminaryRecord?.evaluationDate ? moment(preliminaryRecord?.evaluationDate).format('DD/MM/YYYY') : '--'}
                                        </span>
                                        <span>
                                            ({preliminaryRecord?.therapist || '--'})
                                        </span>
                                    </div>
                                    <div className="icons">
                                        <RoundButton className='mr-5 cursor--pointer' icon={<img src={foot}/>} text={<span className='d-flex v--center'><img src={small_success} className='mr-2'/>{formatMessage({id:'LL'})}</span>} bgc='#FFFFFF' textColor='#000000' width='4vw' height='4vw' />
                                        <RoundButton className='mr-5 cursor--pointer' icon={<img src={hand}/>} text={<span className='d-flex v--center'><img src={small_success} className='mr-2'/>{formatMessage({id:'UL'})}</span>} bgc='#FFFFFF' textColor='#000000' width='4vw' height='4vw' />
                                        <RoundButton className='cursor--pointer' icon={<img src={machine_foot}/>} text={<span className='d-flex v--center'><img src={small_success} className='mr-2'/>{formatMessage({id:'KE'})}</span>} bgc='#FFFFFF' textColor='#000000' width='4vw' height='4vw' />
                                    </div>
                                </div>
                                <div className="right cursor--pointer">
                                    <img src={copy_btn} onClick={e=>{e.stopPropagation();setCopySetting({showModal:true, copyId:preliminaryRecord?.id ?? 0});}} />
                                </div>
                            </div>
                        ) : (
                            <div className='d-flex h--center v--center width--full height--full'>
                                <div className='d-flex v--center'>
                                    <img src={add_full} className='mr-10' width='30px' height='30px' />
                                    <span className='text--bold text-16'>{formatMessage({id:'ADD_PRELIMINARY'})}</span>
                                </div>
                            </div>
                        )}
                    </ExaminationCard>

                    <ExaminationCard border className={hasPreliminary ? 'cursor--pointer' : ''} onClick={hasPreliminary ? () => props.setPageContent(<EvaluationPage {...props} hasPreliminary={hasPreliminary} pageType={PageOption.TUNING} />) : ()=>{}}>
                        <div className='d-flex h--center v--center width--full height--full'>
                            <div className='d-flex v--center'>
                                <img src={hasPreliminary ? icon_success : gary_cross} className='mr-10' width='40px' height='40px' />
                                <span className='text--bold text-18'>{formatMessage({id:'TUNING_RECORD'})}</span>
                            </div>
                        </div>
                    </ExaminationCard>
                    
                    <ExaminationCard border className={hasPreliminary ? 'cursor--pointer' : ''} onClick={hasPreliminary ? () => props.setPageContent(<EvaluationPage {...props} hasPreliminary={hasPreliminary} pageType={PageOption.CLINICIAN} />) : ()=>{}}>
                        <div className='d-flex h--center v--center width--full height--full'>
                            <div className='d-flex v--center'>
                                <img src={hasPreliminary ? icon_success : gary_cross} className='mr-10' width='40px' height='40px' />
                                <span className='text--bold text-18'>{formatMessage({id:'CLINCIAN_CHECKLIST'})}</span>
                            </div>
                        </div>
                    </ExaminationCard>
                </div>
            </div>

            <div style={{backgroundColor: '#FFFFFF', padding: '15px'}}>
                <div className='text-24 text--bold mb-30 d-flex is--space-between v--center'>
                    <span>{formatMessage({ id: 'DAILY_NOTE' })}</span>
                    {(hasPreliminary && props.data.filter(d=>!d.isPreliminary).length > 0) &&
                    <Button
                        className='rect'
                        icon={<PlusOutlined style={{fontSize: '24px', color: '#FFFFFF'}}/>}
                        style={{backgroundColor: '#0083C1', border: 'none', borderRadius: '0.1rem'}}
                        onClick={() => setDrawerSetting({showDateDrawer: true, showEvaluationDrawer: false, drawerData: undefined, planDetailId: 0, copy: false, treatmentDate: '', treatmentHour: '', packageName: '', className: ''})}
                    />}
                </div>
                <StyledSeparatedTable
                    columns={columns}
                    dataSource={props.data.filter(d=>!d.isPreliminary)}
                    onRow={onRowClick}
                    rowClassName={(record: any) => !record.status ? 'suspend' : ''}
                    pagination={false}
                    footer={props.data.filter(d=>!d.isPreliminary).length > 0 ? () =>
                        <Pagiantion
                            pagination={pagination}
                            setPagination={setPagination}
                            paginationTotal={props.evaluationDataTotal}
                        /> : undefined
                    }
                    locale={{
                        emptyText: (
                            ( hasPreliminary && props.data.filter(d=>!d.isPreliminary).length < 1 ) ?
                            <div className='d-flex width--full v--center padding-15 text--bold cursor--pointer' onClick={()=>setDrawerSetting({showDateDrawer: true, showEvaluationDrawer: false, drawerData: undefined, planDetailId: 0, copy: false, treatmentDate: '', treatmentHour: '', packageName: '', className: ''})}>
                                <PlusOutlined style={{fontSize: '24px', color: '#0083C1', marginRight: '15px'}}/>
                                {formatMessage({id:'ADD_DAILY_NOTE'})}
                            </div> :
                            <div className='d-flex width--full h--center mt-30 mb-30 text-24 text--bold'>{formatMessage({id:'NO_DATA'})}</div>
                        )
                    }}
                />
            </div>
        </div>
    )
}

export default EvaluationList;
