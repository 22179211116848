// tslint:disable quotemark
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Radio, Tooltip, Tabs, Slider, Row, Col, Checkbox } from 'antd';
import Drawer from 'components/drawer/drawer';
import { DrawerProps } from 'antd/lib/drawer';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import Button from 'components/button/button';
import { Input, Select, Label, InputNumber } from 'components/form';
import usePermission  from 'hooks/usePermission';
import { useIntl } from 'react-intl';
import Switch from 'components/switch/switch';

const StyledAntdGridTable = styled.div`
    & {
        border-right: 1px solid var(--color-gray-light);
        overflow: hidden;
        .ant-row {
            .ant-col {
                padding: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                &:nth-of-type(1),&:nth-of-type(2){
                    border-right: 1px solid var(--color-gray-light);
                }
            }
            &:nth-of-type(1){
                border-bottom : 1px solid var(--color-gray-light);
            }
        }
        .hightlight {
            color : var(--color-primary-dark);
        }
    }
`

const FormItemVerticalSlider = styled(Form.Item)`
    & {

        .ant-form-item-control-input-content {
            height: 170px;
            margin: 1rem auto;
            display: flex;
            justify-content: center;
        }

        .ant-slider{
            margin-left: -0.5rem;
            &:hover {
                .ant-slider-track {
                    background-color: var(--color-warning);
                }
            }
            .ant-slider-rail {
                background: linear-gradient(90deg,var(--color-gray-light) 0%, var(--color-gray) 100%);
                width: 1.2rem;
                border-radius: 3px;
            }

            .ant-slider-track {
                background-color: var(--color-warning);
                width: 1.2rem;
                border-radius: 3px;
            }

            .ant-slider-handle {
                width: 1.8rem;
                border-width: 0 !important;
                border-radius: 5px !important;
                background: var(--color-gray-light);
            }
        }

        &.reverse{
            .ant-slider-track {
                background: linear-gradient(90deg,var(--color-gray-light) 0%, var(--color-gray) 100%) !important;
            }           
            .ant-slider-rail {
                background: var(--color-warning) !important;
            }
            .ant-slider-handle {
                margin-top: -100% !important;
            }
        }

    }
`

const FormItemHorizontalSlider = styled(Form.Item)`
    & {
        margin:0;

        .ant-form-item-control-input-content {
            width: 180px;
        }

        .ant-slider {
            margin: -0.5rem 0 0 0;
            &:hover {
                .ant-slider-track {
                    background-color: var(--color-warning);
                }
            }
            .ant-slider-rail {
                background: linear-gradient(0deg,var(--color-gray-light) 0%, var(--color-gray) 100%);
                height: 1.2rem;
                border-radius: 3px;
            }

            .ant-slider-track {
                background-color: var(--color-warning);
                height: 1.2rem;
                border-radius: 3px;
            }
            .ant-slider-handle {
                height: 1.8rem;
                border-width: 0 !important;
                border-radius: 5px !important;
                background: var(--color-gray-light);
            }
        }

        &.reverse{
            .ant-slider-track {
                background: linear-gradient(0deg,var(--color-gray-light) 0%, var(--color-gray) 100%) !important;
            }           
            .ant-slider-rail {
                background: var(--color-warning) !important;
            }
        }

    }
`

const TabsStyled = styled(Tabs)`
    & {       
        background: #F3F3F3;
        .ant-tabs-content {
            padding: 1rem !important;
        }

        .ant-tabs-nav-wrap {
            background: white;
            box-sizing: border-box;
            margin-right: 0;
            border: 1px solid var(--color-gray-light);

            .ant-tabs-ink-bar {
                display: none !important;
            }


            .ant-tabs-tab {
                border: 1px solid var(--color-gray-light-100) !important;
                box-sizing: border-box;
                padding: 1rem;
                margin: 0;

                &:hover {
                    color: var(--color-primary-dark);
                }
            }

            .ant-tabs-tab-active {
                background: var(--color-primary-dark) !important;
                color: white !important;
            }
        }

    }
`

// STEP7 Advanced Parameters
type propsTypeStep7 = {
    // Continues flexion(-) /extension(+) assistance
    // leftKneeOffsetTorque: number // 8
    // rightKneeOffsetTorque: number // 9

    leftDampIntensity: number // 30
    rightDampIntensity: number // 31
    torqueTransitionSpeed: number // 32

    // beeper
    buzzerState: number // 50
    onChange:(e:any)=>void
}
const Step7 = (props: propsTypeStep7) => {
    const { formatMessage } = useIntl()
    const [form] = Form.useForm()
    
    console.log('[ step6 props ]', props)
    
    const {
        // leftKneeOffsetTorque,
        // rightKneeOffsetTorque,
        leftDampIntensity,
        rightDampIntensity,
        torqueTransitionSpeed,
        buzzerState
    } = props

    React.useEffect(()=>{
        form.setFieldsValue({
            leftKneeOffsetTorque:50,
            rightKneeOffsetTorque:50,
            leftDampIntensity,
            rightDampIntensity,
            torqueTransitionSpeed,
            buzzerState:Boolean(buzzerState)
        })
    }, [props])

    return (
        <Form 
            form={form} 
            layout="vertical"
            onValuesChange={(cv,av)=>{

                console.log("[ step7 - onValuesChange - cv ]", cv)
                
                if(cv.hasOwnProperty('buzzerState')){
                    av.buzzerState = Number(cv.buzzerState)
                }
                
                delete av.leftKneeOffsetTorque
                delete av.rightKneeOffsetTorque
                
                console.log("[ step7 - onValuesChange - av ]", av)

                props.onChange(av)
            }}
        >
            <Row gutter={24} align='middle' style={{padding:'1rem 0', borderBottom:'1px solid var(--color-gray-light)'}}>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left' }}>Continues flexion(-) /extension(+) assistance</h1>
                    Adds a persistent torque in either flexion or extension. This may alter the desired angle for motor initiation in step 2 and assistance levels in step 3 (to compensate for the applied torque).
                </Col>
                <Col span={12}>
                    <Row gutter={5} align='middle' style={{margin:'1rem 0'}}>
                        <Col span={6}>Left Torque Offset</Col>
                        <Col span={14}>
                            <FormItemHorizontalSlider name='leftKneeOffsetTorque'>
                                <Slider tooltipVisible={false}/>
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>0 Nm</Col>
                    </Row>
                    <Row gutter={5} align='middle' style={{margin:'1rem 0'}}>
                        <Col span={6}>Left Torque Offset</Col>
                        <Col span={14}>
                            <FormItemHorizontalSlider name='rightKneeOffsetTorque'>
                                <Slider tooltipVisible={false}/>
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>0 Nm</Col>
                    </Row>           
                </Col>
            </Row>

            <Row gutter={24} align='middle' style={{padding:'1rem 0', borderBottom:'1px solid var(--color-gray-light)'}}>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left' }}>Motor ramp up</h1>
                    Regulates the rate at which the assistance levels set in step 3 are reached. Adjusting this value is likely to be perceived by the user in the initial phase of their movement. Faster moving users may require a higher value.
                </Col>
                <Col span={12}>
                    <Row gutter={5} align='middle'>
                        <Col span={6}>Level</Col>
                        <Col span={14}>
                            <FormItemHorizontalSlider name='torqueTransitionSpeed'>
                                <Slider tooltipVisible={false} min={1} max={10}/>
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>{torqueTransitionSpeed}</Col>
                    </Row>
                </Col>
            </Row>

            <Row gutter={24} align='middle' style={{padding:'1rem 0'}}>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left' }}>Beeper</h1>
                    An audible beep signals when Keeogo no longer detects user motion (neutral stance timeout and stair exit timeout) and is idle in anticipation of the user's next movement.
                </Col>
                <Col span={12}>
                    <FormItemHorizontalSlider name='buzzerState' valuePropName='checked'>
                        <Checkbox>
                            <strong style={{ fontSize: '1.1rem' }}>Beeper</strong>
                        </Checkbox>
                    </FormItemHorizontalSlider>
                </Col>
            </Row>

        </Form>
    )
}


// STEP6 slopes
type propsTypeStep6 = {
    eccentricAscent:number,
    eccentricFlat:number,
    eccentricDecent:number,

    extensionAscent:number,
    extensionFlat:number,

    kneeExtensionAscent:number, // no match column 
    onChange:(e:any)=> void
}
const Step6 = (props: propsTypeStep6) => {

    const { formatMessage } = useIntl()
    const [form] = Form.useForm()

    const {
        eccentricAscent,
        eccentricFlat,
        eccentricDecent,
        extensionAscent,
        extensionFlat,
        kneeExtensionAscent
    } = props

    
    React.useEffect(()=>{
        form.setFieldsValue({
            eccentricAscent,
            eccentricFlat,
            eccentricDecent,
            extensionAscent,
            extensionFlat,
            kneeExtensionAscent:10
        })
    }, [props])

    return (
        <Form
            form={form}
            layout="vertical"
            onValuesChange={(cv,av)=>{
                console.log("[ step6 - onValuesChange ]", cv)
                delete av.kneeExtensionAscent
                props.onChange(av)
            }}
        >
            {/* Eccentric Control in WB Relative Intensity */}
            <Row gutter={24} align='middle' style={{padding:'1rem 0', borderBottom:'1px solid var(--color-gray-light)'}}>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left', margin: '0.5rem 0' }}>Eccentric Control in WB Relative Intensity</h1>
                    Allows for adjustment of baseline Assistance Levels (0 %-100% ) on slopes.
                </Col>
                <Col span={12}>
                    <Row gutter={5} align='middle' justify='space-between'>
                        <Col span={4}>Ascent</Col>
                        <Col span={16}>
                            <FormItemHorizontalSlider name='eccentricAscent'>
                                <Slider tooltipVisible={false} min={0} max={100} />                       
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>{eccentricAscent}%</Col>
                    </Row>
                    <Row gutter={5} align='middle' style={{marginTop:'1rem'}}>
                        <Col span={4}>Flat</Col>
                        <Col span={16}>
                            <FormItemHorizontalSlider name='eccentricFlat'>
                                <Slider tooltipVisible={false} min={0} max={100} />
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>{eccentricFlat}%</Col>
                    </Row>
                    <Row gutter={5} align='middle' style={{marginTop:'1rem'}}>
                        <Col span={4}>Decent</Col>
                        <Col span={16}>
                            <FormItemHorizontalSlider name='eccentricDecent'>
                                <Slider tooltipVisible={false} min={0} max={100} />
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>{eccentricDecent}%</Col>
                    </Row>
                </Col>
            </Row>
            
            {/* Extension in WB Relative Intensity */}
            <Row gutter={24} align='middle' style={{padding:'1rem 0', borderBottom:'1px solid var(--color-gray-light)'}}>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left', margin: '0.5rem 0' }}>Extension in WB Relative Intensity</h1>
                    Allows for adjustment of baseline Assistance Levels (0 %-100% ) on slopes.
                </Col>
                <Col span={12}>
                    <Row gutter={10} align='middle'>
                        <Col span={4}>Ascent</Col>
                        <Col span={16}>
                            <FormItemHorizontalSlider name='extensionAscent'>
                                <Slider tooltipVisible={false} min={0} max={100} />                       
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>{extensionAscent}%</Col>
                    </Row>
                    <Row gutter={10} align='middle' style={{marginTop:'1rem'}}>
                        <Col span={4}>Flat</Col>
                        <Col span={16}>
                            <FormItemHorizontalSlider name='extensionFlat'>
                                <Slider tooltipVisible={false} min={0} max={100} />
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>{extensionFlat}%</Col>
                    </Row>
                </Col>
            </Row>

            {/* Knee Extension Relative Intensity */}
            <Row gutter={24} align='middle' style={{padding:'1rem 0'}}>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left', margin: '0.5rem 0' }}>Knee Extension Relative Intensity</h1>
                    Allows for fine tuning the level of knee extension assistance, as a ratio between 0% and 100% ( based on the generic knee extension ) for the ascent slop.
                </Col>
                <Col span={12}>
                    <Row gutter={10} align='middle'>
                        <Col span={4}>Ascent</Col>
                        <Col span={16}>
                            <FormItemHorizontalSlider name='kneeExtensionAscent'>
                                <Slider tooltipVisible={false} min={0} max={100} />
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>{kneeExtensionAscent}%</Col>
                    </Row>
                </Col>
            </Row>

        </Form>
    )
}

// STEP5 Stairs Descent
type propsTypeStep5 = {
    // fron leg
    leftKneeStartAngleStairsDescent: number // 44	LeftKneeStartAngleStairsDescent
    rightKneeStartAngleStairsDescent: number // 45	RightKneeStartAngleStairsDescent
    // back leg
    leftKneeEndAngleStairDescent: number // 46	LeftKneeEndAngleStairDescent
    rightKneeEndAngleStairDescent: number // 47	RightKneeEndAngleStairDescent
    // motor ramp down
    leftDampDecayTimeDescent: number // 37	LeftDampDecayTimeDescent
    rightDampDecayTimeDescent: number // 38	RightDampDecayTimeDescent
    // Neutral Position/Stairs descent timeout
    timeoutToEnterFumble: number // 48
    timeoutToExitStairDescent: number // 49
    stairsDescentExitLargeStepEnable: number // 54 Short-None
    stairsDescentExitSmallStepEnable: number // 55 Short-Normal
    stairsDescentDampInhibitorEnable: number // 56 Short-Connected

    onChange:(e:any)=> void
}
const Step5 = (props: propsTypeStep5) => {
    const { formatMessage } = useIntl()
    const [form] = Form.useForm()
    const [isSync, setIsSync] = React.useState({
        KneeStartAngleStairsDescent:false,
        KneeEndAngleStairDescent:false,
        DampDecayTimeDescent:false,
    })
    console.log(' [ Step5 props ] ', props)
    const {
        leftKneeStartAngleStairsDescent,
        rightKneeStartAngleStairsDescent,

        leftKneeEndAngleStairDescent,
        rightKneeEndAngleStairDescent,

        leftDampDecayTimeDescent,
        rightDampDecayTimeDescent,
        // Neutral Position/Stairs descent timeout
        timeoutToEnterFumble,
        timeoutToExitStairDescent,
        stairsDescentExitLargeStepEnable,
        stairsDescentExitSmallStepEnable,
        stairsDescentDampInhibitorEnable
    } = props
    
    React.useEffect(() => {

        const stairsDescent = (
            stairsDescentExitLargeStepEnable ? 'stairsDescentExitLargeStepEnable' :
            stairsDescentExitSmallStepEnable ? 'stairsDescentExitSmallStepEnable' :
            stairsDescentDampInhibitorEnable ? 'stairsDescentDampInhibitorEnable' :
            ''
        )

        form.setFieldsValue({
            leftKneeStartAngleStairsDescent,
            rightKneeStartAngleStairsDescent,

            leftKneeEndAngleStairDescent,
            rightKneeEndAngleStairDescent,

            leftDampDecayTimeDescent,
            rightDampDecayTimeDescent,

            timeoutToEnterFumble,
            timeoutToExitStairDescent,

            stairsDescent
        })
    }, [props])


    return (
        <Form 
            form={form} 
            layout="vertical"
            onValuesChange={(cv,av)=>{
                if (isSync.KneeStartAngleStairsDescent) {
                    const KeyOfKneeStartAngleStairsDescent = Object.keys(cv).find(k => /KneeStartAngleStairsDescent/g.test(k))
                    if (KeyOfKneeStartAngleStairsDescent) {
                        av.leftKneeStartAngleStairsDescent = cv[KeyOfKneeStartAngleStairsDescent]
                        av.rightKneeStartAngleStairsDescent = cv[KeyOfKneeStartAngleStairsDescent]
                    }
                }
                
                if (isSync.KneeEndAngleStairDescent) {
                    const KeyOfKneeEndAngleStairDescent = Object.keys(cv).find(k => /KneeEndAngleStairDescent/g.test(k))
                    if (KeyOfKneeEndAngleStairDescent) {
                        av.leftKneeEndAngleStairDescent = cv[KeyOfKneeEndAngleStairDescent]
                        av.rightKneeEndAngleStairDescent = cv[KeyOfKneeEndAngleStairDescent]
                    }
                }

                if (isSync.DampDecayTimeDescent) {
                    const KeyOfDampDecayTimeDescent = Object.keys(cv).find(k => /DampDecayTimeDescent/g.test(k))
                    if (KeyOfDampDecayTimeDescent) {
                        av.leftDampDecayTimeDescent = cv[KeyOfDampDecayTimeDescent]
                        av.rightDampDecayTimeDescent = cv[KeyOfDampDecayTimeDescent]
                    }
                }
               
                av.stairsDescentExitLargeStepEnable = Number(av.stairsDescent === 'stairsDescentExitLargeStepEnable')
                av.stairsDescentExitSmallStepEnable = Number(av.stairsDescent === 'stairsDescentExitSmallStepEnable')
                av.stairsDescentDampInhibitorEnable = Number(av.stairsDescent === 'stairsDescentDampInhibitorEnable')

                props.onChange({
                    leftKneeStartAngleStairsDescent,
                    rightKneeStartAngleStairsDescent,
                    leftKneeEndAngleStairDescent,
                    rightKneeEndAngleStairDescent,
                    leftDampDecayTimeDescent,
                    rightDampDecayTimeDescent,
                    ...av
                })
            }}
        >
            {/* Front leg WB start angle */}
            
            <Row gutter={24} align='middle' style={{padding:'1rem 0', borderBottom:'1px solid var(--color-gray-light)'}}>
                <Col span={10}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left', margin: '0.5rem 0' }}>Front leg WB start angle</h1>
                    Knee angle at witch Keeogo can begin to provide weight bearing assistance to user in stance. Set value LARGER than user's true terminal extension angle to ensure safety. The assigned angle for motor initiation (step 2) is a constant reference (0°) for this setting.
                </Col>
                <Col span={4}>
                    <Checkbox checked={isSync.KneeStartAngleStairsDescent} onChange={() => setIsSync(prv => ({ ...prv, KneeStartAngleStairsDescent: !prv.KneeStartAngleStairsDescent }))}>Symmetry</Checkbox>
                </Col>
                <Col span={10} style={{textAlign:'center'}}>
                    <Row gutter={5} align='middle'>
                        <Col span={20}>
                            <FormItemHorizontalSlider name='leftKneeStartAngleStairsDescent'>
                                <Slider tooltipVisible={false} min={15} max={30} />                       
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>Left<br/>{leftKneeStartAngleStairsDescent}°</Col>
                    </Row>
                    <Row gutter={5} align='middle' style={{marginTop:'1rem'}}>
                        <Col span={20}>
                            <FormItemHorizontalSlider name='rightKneeStartAngleStairsDescent'>
                                <Slider tooltipVisible={false} min={15} max={30} />
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>Right<br/>{rightKneeStartAngleStairsDescent}°</Col>
                    </Row>
                </Col>
            </Row>
            {/* Back leg WB release angle */}
            <Row gutter={24} align='middle' style={{padding:'1rem 0', borderBottom:'1px solid var(--color-gray-light)'}}>
                <Col span={10}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left', margin: '0.5rem 0' }}>Back leg WB release angle</h1>
                    Knee angle at witch Keeogo releases weight bearing assistance to allow for free swing of the limb. Set value SMALLER than user's true terminal flexion angle to ensure safety. The assigned angle for motor initiation (step 2) is a constant reference (0°) for this setting.
                </Col>
                <Col span={4}>
                    <Checkbox checked={isSync.KneeEndAngleStairDescent} onChange={() => setIsSync(prv => ({ ...prv, KneeEndAngleStairDescent: !prv.KneeEndAngleStairDescent }))}>Symmetry</Checkbox>
                </Col>
                <Col span={10} style={{textAlign:'center'}}>
                    <Row gutter={5} align='middle'>
                        <Col span={20}>
                            <FormItemHorizontalSlider name='leftKneeEndAngleStairDescent'>
                                <Slider tooltipVisible={false} min={50} max={80} />
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>Left<br/>{leftKneeEndAngleStairDescent}°</Col>
                    </Row>
                    <Row gutter={5} align='middle' style={{marginTop:'1rem'}}>
                        <Col span={20}>
                            <FormItemHorizontalSlider name='rightKneeEndAngleStairDescent'>
                                <Slider tooltipVisible={false} min={50} max={80} />
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>Right<br/>{rightKneeEndAngleStairDescent}°</Col>
                    </Row>
                </Col>
            </Row>
            {/* Motor ramp down */}
            <Row gutter={24} align='middle' style={{padding:'1rem 0', borderBottom:'1px solid var(--color-gray-light)'}}>
                <Col span={10}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left', margin: '0.5rem 0' }}>Motor ramp down</h1>
                    Time for motor torque to ramp down to 0nm. A smaller value for will make torque leave the system faster making it more responsive. Increase value for slow walkers.
                </Col>
                <Col span={4}>
                    <Checkbox checked={isSync.DampDecayTimeDescent} onChange={() => setIsSync(prv => ({ ...prv, DampDecayTimeDescent: !prv.DampDecayTimeDescent }))}>Symmetry</Checkbox>
                </Col>
                <Col span={10} style={{textAlign:'center'}}>
                    <Row gutter={5} align='middle'>
                        <Col span={18}>
                            <FormItemHorizontalSlider name='leftDampDecayTimeDescent'>
                                <Slider tooltipVisible={false} min={1} max={1000} />
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={6}>Left<br/>{leftDampDecayTimeDescent}ms</Col>
                    </Row>
                    <Row gutter={5} align='middle' style={{marginTop:'1rem'}}>
                        <Col span={18}>
                            <FormItemHorizontalSlider name='rightDampDecayTimeDescent'>
                                <Slider tooltipVisible={false} min={1} max={1000} />
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={6}>Right<br/>{rightDampDecayTimeDescent}ms</Col>
                    </Row>
                </Col>
            </Row>
            {/* Neutral Position/Stairs descent timeout */}
            <Row gutter={24} style={{padding:'1rem 0'}}>
                <Col span={10}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'left', margin: '0.5rem 0' }}>Neutral Position/Stairs descent timeout</h1>
                    Time for motor torque to ramp down to 0nm. A smaller value for will make torque leave the system faster making it more responsive. Increase value for slow walkers.
                </Col>
                <Col span={14} style={{textAlign:'center'}}>
                    <Row gutter={5} align='middle'>
                        <Col span={8}>Neutral Position timeout</Col>
                        <Col span={12}>
                            <FormItemHorizontalSlider name='timeoutToEnterFumble'>
                                <Slider tooltipVisible={false} min={200} max={500}/>
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>{timeoutToEnterFumble}ms</Col>
                    </Row>
                    <Row gutter={5} align='middle' style={{marginTop:'1rem'}}>
                        <Col span={8}>Stairs descent timeout</Col>
                        <Col span={12}>
                            <FormItemHorizontalSlider name='timeoutToExitStairDescent'>
                                <Slider tooltipVisible={false} min={300} max={3000}/>
                            </FormItemHorizontalSlider>
                        </Col>
                        <Col span={4}>{timeoutToExitStairDescent}ms</Col>
                    </Row>
                    <Row gutter={5} align='middle' style={{marginTop:'1rem'}}>
                        <Form.Item noStyle name=''>
                            <Checkbox>Long</Checkbox>
                        </Form.Item>
                        <span style={{margin:'1rem'}}> Short : </span>
                        <Form.Item noStyle name='stairsDescent'>
                            <Radio.Group>
                                <Radio value="stairsDescentExitLargeStepEnable">None</Radio>
                                <Radio value="stairsDescentExitSmallStepEnable">Normal</Radio>
                                <Radio value="stairsDescentDampInhibitorEnable">Corrected</Radio>
                            </Radio.Group>
                        </Form.Item>

                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

// STEP4 Assisted range of motion
type propsTypeStep4 = {
    leftPushIntensity: number,  // 26
    rightPushIntensity: number,  // 27
    leftPushLimitExtensionAngle: number,  // 28
    rightPushLimitExtensionAngle: number,  // 29
    leftDampIntensity: number,  // 30
    rightDampIntensity: number,  // 31
    fumbleToSquatKneeAngleTrigger: number,  // 53
    
    onChange:(e:any)=> void
}
const Step4 = (props: propsTypeStep4) => {
    const { formatMessage } = useIntl()
    const [form] = Form.useForm()
    const [isSync, setIsSync] = React.useState({
        PushIntensity:false,
        PushLimitExtensionAngle:false,
        DampIntensity:false,
    })
    console.log(' [ Step4 props ] ', props)

    const {
        leftPushIntensity,
        rightPushIntensity,
        leftPushLimitExtensionAngle,
        rightPushLimitExtensionAngle,
        leftDampIntensity,
        rightDampIntensity,
        fumbleToSquatKneeAngleTrigger
    } = props

    React.useEffect(() => {
        form.setFieldsValue({
            leftPushIntensity,
            rightPushIntensity,
            leftPushLimitExtensionAngle,
            rightPushLimitExtensionAngle,
            leftDampIntensity,
            rightDampIntensity,
            fumbleToSquatKneeAngleTrigger
        })
    }, [props])

    return (
        <Form
            form={form}
            layout="vertical"
            onValuesChange={(cv,av)=>{
                
                if (isSync.PushIntensity) {
                    const KeyOfPushIntensity = Object.keys(cv).find(k => /PushIntensity/g.test(k))
                    if (KeyOfPushIntensity) {
                        av.leftPushIntensity = cv[KeyOfPushIntensity]
                        av.rightPushIntensity = cv[KeyOfPushIntensity]
                    }
                }
                
                if (isSync.PushLimitExtensionAngle) {
                    const KeyOfPushLimitExtensionAngle = Object.keys(cv).find(k => /PushLimitExtensionAngle/g.test(k))
                    if (KeyOfPushLimitExtensionAngle) {
                        av.leftPushLimitExtensionAngle = cv[KeyOfPushLimitExtensionAngle]
                        av.rightPushLimitExtensionAngle = cv[KeyOfPushLimitExtensionAngle]
                    }
                }

                if (isSync.DampIntensity) {
                    const KeyOfDampIntensity = Object.keys(cv).find(k => /DampIntensity/g.test(k))
                    if (KeyOfDampIntensity) {
                        av.leftDampIntensity = cv[KeyOfDampIntensity]
                        av.rightDampIntensity = cv[KeyOfDampIntensity]
                    }
                }

                props.onChange({
                    leftPushIntensity,
                    rightPushIntensity,
                    leftPushLimitExtensionAngle,
                    rightPushLimitExtensionAngle,
                    leftDampIntensity,
                    rightDampIntensity,
                    fumbleToSquatKneeAngleTrigger,
                    ...av
                })
            }}
        >
            <Row gutter={24} justify='center' style={{textAlign:'center'}}>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)', margin: '1rem 0' }}>Swing Phase</h1>
                    <Row gutter={24} justify='center' align='stretch'>
                        <Col span={12} style={{borderRight:'1px solid var(--color-gray-light)'}}>
                            <Row gutter={24} justify='center'>
                                <p><strong>Flexion Assistance</strong></p>
                                <Col span={12}>
                                    <strong>Left<br/>{leftPushIntensity}°</strong>
                                    <FormItemVerticalSlider name='leftPushIntensity'>
                                        <Slider vertical tooltipVisible={false} min={40} max={60} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Col span={12}>
                                    <strong>Right<br/>{rightPushIntensity}°</strong>
                                    <FormItemVerticalSlider name='rightPushIntensity'>
                                        <Slider vertical tooltipVisible={false} min={40} max={60} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Checkbox checked={isSync.PushIntensity} onChange={() => setIsSync(prv => ({ ...prv, PushIntensity: !prv.PushIntensity }))}>Symmetry</Checkbox>
                            </Row>
                        </Col>
                        <Col span={12} style={{borderRight:'1px solid var(--color-gray-light)'}}>
                            <Row gutter={24} justify='center'>
                                <p><strong>Weight Bearing</strong></p>
                                <Col span={12}>
                                    <strong>Left<br/>{leftPushLimitExtensionAngle}°</strong>
                                    <FormItemVerticalSlider name='leftPushLimitExtensionAngle' className='reverse'>
                                        <Slider vertical tooltipVisible={false} reverse min={20} max={40} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Col span={12}>
                                    <strong>Right<br/>{rightPushLimitExtensionAngle}°</strong>
                                    <FormItemVerticalSlider name='rightPushLimitExtensionAngle' className='reverse'>
                                        <Slider vertical tooltipVisible={false} reverse min={20} max={40} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Checkbox checked={isSync.PushLimitExtensionAngle} onChange={() => setIsSync(prv => ({ ...prv, PushLimitExtensionAngle: !prv.PushLimitExtensionAngle }))}>Symmetry</Checkbox>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)',  margin: '1rem 0'}}>Weight Bearing</h1>
                    <Row gutter={24} align='stretch'>
                        <Col span={12} style={{borderRight:'1px solid var(--color-gray-light)'}}>
                            <Row gutter={24} justify='center'>
                                <p><strong>End of extension assistance</strong></p>
                                <Col span={12}>
                                    <strong>Left<br/>{leftDampIntensity}°</strong>
                                    <FormItemVerticalSlider name='leftDampIntensity' className='reverse' >
                                        <Slider vertical tooltipVisible={false} reverse min={0} max={20} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Col span={12}>
                                    <strong>Right<br/>{rightDampIntensity}°</strong>
                                    <FormItemVerticalSlider name='rightDampIntensity' className='reverse' >
                                        <Slider vertical tooltipVisible={false} reverse min={0} max={20} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Checkbox checked={isSync.DampIntensity} onChange={() => setIsSync(prv => ({ ...prv, DampIntensity: !prv.DampIntensity }))}>Symmetry</Checkbox>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row gutter={24} justify='center'>
                                <p><strong> Stairs/Squat Detection Threshold </strong></p>
                                <Col span={24}>
                                    <strong>{fumbleToSquatKneeAngleTrigger}°</strong>
                                    <FormItemVerticalSlider name='torqueTransitionSpeed'  className='reverse'>
                                        <Slider vertical tooltipVisible={false} reverse min={10} max={45} />
                                    </FormItemVerticalSlider>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Form>
    )
}

// STEP3 Assistance Level
type propsTypeStep3 = {

    // swing phase
    leftSwingIntensity: number // 22
    rightSwingIntensity: number // 23

    leftSwingLimitExtensionAngle: number // 24
    rightSwingLimitExtensionAngle: number // 25

    // weight bearing
    leftPullIntensity: number // 18
    rightPullIntensity: number // 19

    leftPullLimitFlexionAngle: number // 20
    rightPullLimitFlexionAngle: number // 21

    onChange: (e: any) => void
}

const Step3 = (props: propsTypeStep3) => {

    const { formatMessage } = useIntl()
    const [form] = Form.useForm()
    const [isSync, setIsSync] = React.useState({
        // swing phase
        SwingIntensity: false,
        SwingLimitExtensionAngle: false,
        // weight bearing
        PullIntensity: false,
        PullLimitFlexionAngle: false
    })

    const {
        leftSwingIntensity,
        rightSwingIntensity,

        leftSwingLimitExtensionAngle,
        rightSwingLimitExtensionAngle,

        leftPullLimitFlexionAngle,
        rightPullLimitFlexionAngle,

        leftPullIntensity,
        rightPullIntensity
    } = props

    React.useEffect(() => {
        form.setFieldsValue({
            leftSwingIntensity,
            rightSwingIntensity,
            leftSwingLimitExtensionAngle,
            rightSwingLimitExtensionAngle,
            leftPullLimitFlexionAngle,
            rightPullLimitFlexionAngle,
            leftPullIntensity,
            rightPullIntensity
        })
    }, [props])

    return (
        <Form
            form={form}
            layout="vertical"
            onValuesChange={(cv, av) => {

                if (isSync.SwingIntensity) {
                    const KeyOfSwingIntensity = Object.keys(cv).find(k => /SwingIntensity/g.test(k))
                    if (KeyOfSwingIntensity) {
                        av.leftSwingIntensity = cv[KeyOfSwingIntensity]
                        av.rightSwingIntensity = cv[KeyOfSwingIntensity]
                    }
                }
                if (isSync.SwingLimitExtensionAngle) {
                    const KeyOfSwingLimitExtensionAngle = Object.keys(cv).find(k => /SwingLimitExtensionAngle/g.test(k))
                    if (KeyOfSwingLimitExtensionAngle) {
                        av.leftSwingLimitExtensionAngle = cv[KeyOfSwingLimitExtensionAngle]
                        av.rightSwingLimitExtensionAngle = cv[KeyOfSwingLimitExtensionAngle]
                    }
                }
                if (isSync.PullLimitFlexionAngle) {
                    const KeyOfPullLimitFlexionAngle = Object.keys(cv).find(k => /PullLimitFlexionAngle/g.test(k))
                    if (KeyOfPullLimitFlexionAngle) {
                        av.leftPullLimitFlexionAngle = cv[KeyOfPullLimitFlexionAngle]
                        av.rightPullLimitFlexionAngle = cv[KeyOfPullLimitFlexionAngle]
                    }
                }
                if (isSync.PullIntensity) {
                    const KeyOfPullIntensity = Object.keys(cv).find(k => /PullIntensity/g.test(k))
                    if (KeyOfPullIntensity) {
                        av.leftPullIntensity = cv[KeyOfPullIntensity]
                        av.rightPullIntensity = cv[KeyOfPullIntensity]
                    }
                }

                props.onChange({
                    leftSwingIntensity,
                    rightSwingIntensity,
                    leftSwingLimitExtensionAngle,
                    rightSwingLimitExtensionAngle,
                    leftPullLimitFlexionAngle,
                    rightPullLimitFlexionAngle,
                    leftPullIntensity,
                    rightPullIntensity,
                    ...av
                })

            }}
        >
            <Row gutter={24} justify='center' style={{ textAlign: 'center' }}>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'center', margin: '0.5rem 0' }}>Swing Phase</h1>
                    <Row gutter={24} justify='center'>
                        <Col span={12} style={{borderRight:'1px solid var(--color-gray-light)'}}>
                            <Row gutter={24} justify='center'>
                                <p>
                                    <strong>Flexion Assistance</strong>
                                </p>
                                {/* SwingIntensity */}
                                <Col span={12}>
                                    <strong> Left <br />{leftSwingIntensity} Nm </strong>
                                    <FormItemVerticalSlider name='leftSwingIntensity'>
                                        <Slider vertical tooltipVisible={false} min={0} max={20} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Col span={12}>
                                    <strong> Right <br />{rightSwingIntensity} Nm </strong>
                                    <FormItemVerticalSlider name='rightSwingIntensity'>
                                        <Slider vertical tooltipVisible={false} min={0} max={20} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Checkbox checked={isSync.SwingIntensity} onChange={() => setIsSync(prv => ({ ...prv, SwingIntensity: !prv.SwingIntensity }))}>Symmetry</Checkbox>
                            </Row>
                        </Col>
                        <Col span={12} style={{borderRight:'1px solid var(--color-gray-light)'}}>
                            <Row gutter={24} justify='center'>
                                <p>
                                    <strong>Weight Bearing</strong>
                                </p>
                                {/* SwingLimitExtensionAngle */}
                                <Col span={12}>
                                    <strong> Left <br />{leftSwingLimitExtensionAngle} Nm </strong>
                                    <FormItemVerticalSlider name='leftSwingLimitExtensionAngle'>
                                        <Slider vertical tooltipVisible={false} min={0} max={20} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Col span={12}>
                                    <strong> Right <br />{rightSwingLimitExtensionAngle} Nm </strong>
                                    <FormItemVerticalSlider name='rightSwingLimitExtensionAngle'>
                                        <Slider vertical tooltipVisible={false} min={0} max={20} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Checkbox checked={isSync.SwingLimitExtensionAngle} onChange={() => setIsSync(prv => ({ ...prv, SwingLimitExtensionAngle: !prv.SwingLimitExtensionAngle }))}>Symmetry</Checkbox>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <h1 style={{ color: 'var(--color-primary-light)', textAlign: 'center', margin: '0.5rem 0' }}>Weight Bearing</h1>
                    <Row gutter={24} justify='center'>
                        <Col span={12} style={{borderRight:'1px solid var(--color-gray-light)'}}>
                            <Row gutter={24} justify='center'>
                                <p>
                                    <strong>Eccentric Control</strong>
                                </p>
                                {/* PullIntensity */}
                                <Col span={12}>
                                    <strong> Left <br />{leftPullIntensity} Nm </strong>
                                    <FormItemVerticalSlider name='leftPullIntensity'>
                                        <Slider vertical tooltipVisible={false} min={15} max={40} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Col span={12}>
                                    <strong> Right <br />{rightPullIntensity} Nm </strong>
                                    <FormItemVerticalSlider name='rightPullIntensity'>
                                        <Slider vertical tooltipVisible={false} min={15} max={40} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Checkbox checked={isSync.PullIntensity} onChange={() => setIsSync(prv => ({ ...prv, PullIntensity: !prv.PullIntensity }))}>Symmetry</Checkbox>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row gutter={24} justify='center'>
                                <p>
                                    <strong>Extension Assistance</strong>
                                </p>
                                {/* PullLimitFlexionAngle */}
                                <Col span={12}>
                                    <strong> Left <br />{leftPullLimitFlexionAngle} Nm </strong>
                                    <FormItemVerticalSlider name='leftPullLimitFlexionAngle'>
                                        <Slider vertical tooltipVisible={false} min={15} max={40} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Col span={12}>
                                    <strong> Right <br />{rightPullLimitFlexionAngle} Nm </strong>
                                    <FormItemVerticalSlider name='rightPullLimitFlexionAngle'>
                                        <Slider vertical tooltipVisible={false} min={15} max={40} />
                                    </FormItemVerticalSlider>
                                </Col>
                                <Checkbox checked={isSync.PullLimitFlexionAngle} onChange={() => setIsSync(prv => ({ ...prv, PullLimitFlexionAngle: !prv.PullLimitFlexionAngle }))}>Symmetry</Checkbox>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

// STEP2 - type Calibrate Sensors
type propsTypeStep2 = {
    leftLegHipAngel: number // 0
    rightLegHipAngel: number // 1
    leftLegPelvicTilt: number // 2
    rightLegPelvicTilt: number // 3
    leftLegKneeAngle: number // 4
    rightLegKneeAngle: number // 5
    leftLegThighPitch: number // 6
    rightLegThighPitch: number // 7
    // assign angle for monitor initiation
    leftKneeAssistanceOffsetAngle: number // 16 LeftAssignAngelForMotorInitination
    rightKneeAssistanceOffsetAngle: number // 17 RightAssignAngelForMotorInitination
    onChange: (e: any) => void
}
const Step2 = (props: propsTypeStep2) => {

    const { formatMessage } = useIntl()
    const [form] = Form.useForm()
    const [isSync, setIsSync] = React.useState(false)

    console.log(' [ step2 rendered ，props is ]', props)

    const parseVal = (val: number, fix: number = 2) => {
        const fv = parseFloat(String(val))
        if (fv !== NaN) {
            return `${fv.toFixed(fix)}°`
        } else {
            return 'n/a'
        }
    }

    const {
        leftLegHipAngel,
        rightLegHipAngel,
        leftLegPelvicTilt,
        rightLegPelvicTilt,
        leftLegKneeAngle,
        rightLegKneeAngle,
        leftLegThighPitch,
        rightLegThighPitch,
        leftKneeAssistanceOffsetAngle, rightKneeAssistanceOffsetAngle
    } = props

    React.useEffect(() => {
        form.setFieldsValue({ leftKneeAssistanceOffsetAngle, rightKneeAssistanceOffsetAngle })
    }, [props])

    return (
        <Form
            form={form}
            layout="vertical"
            onValuesChange={(cv, av) => {

                const k = Object.keys(cv).find(k => /KneeAssistanceOffsetAngle/g.test(k))
                if (k && isSync) {
                    av.leftKneeAssistanceOffsetAngle = av[k]
                    av.rightKneeAssistanceOffsetAngle = av[k]
                }
                props.onChange({
                    leftLegHipAngel,
                    rightLegHipAngel,
                    leftLegPelvicTilt,
                    rightLegPelvicTilt,
                    leftLegKneeAngle,
                    rightLegKneeAngle,
                    leftLegThighPitch,
                    rightLegThighPitch,
                    ...av
                })

            }}
        >
            <Row>
                <Col span={15}>
                    <StyledAntdGridTable>
                        <Row gutter={20}>
                            <Col span={8}>Angle</Col>
                            <Col span={8}>Left Leg Reference</Col>
                            <Col span={8}>Right Leg Reference</Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={8}>Hip Angle</Col>
                            <Col className="hightlight" span={8} >{parseVal(leftLegHipAngel)}</Col>
                            <Col className="hightlight" span={8} >{parseVal(rightLegHipAngel)}</Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={8}>Pelvic Tilt</Col>
                            <Col className="hightlight" span={8} >{parseVal(leftLegPelvicTilt)}</Col>
                            <Col className="hightlight" span={8} >{parseVal(rightLegPelvicTilt)}</Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={8}>Knee Angle</Col>
                            <Col className="hightlight" span={8} >{parseVal(leftLegKneeAngle)}</Col>
                            <Col className="hightlight" span={8} >{parseVal(rightLegKneeAngle)}</Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={8}>Thigh Pitch</Col>
                            <Col className="hightlight" span={8} >{parseVal(leftLegThighPitch)}</Col>
                            <Col className="hightlight" span={8} >{parseVal(rightLegThighPitch)}</Col>
                        </Row>
                    </StyledAntdGridTable>
                </Col>

                <Col span={9}>
                    <Row gutter={24} align='middle' justify='center' style={{ textAlign: 'center' }}>
                        <p>Assign angle for motor initiation</p>
                        <Col span={12}>
                            <strong> Left <br />{parseVal(leftKneeAssistanceOffsetAngle)} </strong>
                            <FormItemVerticalSlider name="leftKneeAssistanceOffsetAngle" className='reverse'>
                                <Slider vertical tooltipVisible={false} reverse min={0} max={30} />
                            </FormItemVerticalSlider>
                        </Col>
                        <Col span={12}>
                            <strong> Right <br />{parseVal(rightKneeAssistanceOffsetAngle)} </strong>
                            <FormItemVerticalSlider name="rightKneeAssistanceOffsetAngle" className='reverse'>
                                <Slider vertical tooltipVisible={false} reverse min={0} max={30} />
                            </FormItemVerticalSlider>
                        </Col>
                        <Checkbox checked={isSync} onChange={() => setIsSync(!isSync)}>Symmetry</Checkbox>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

// STEP1 - set profileName、remark
type propsTypeStep1 = {
    profileName: string,
    remark: string,
    onChange: (e: any) => void
}
const Step1 = (props: propsTypeStep1) => {

    const { formatMessage } = useIntl()
    const [form] = Form.useForm()

    console.log(' [ Step1 props ] ', props)

    const { profileName = '', remark = '', onChange } = props

    React.useEffect(() => {
        form.setFieldsValue({ profileName, remark })
    }, [props])

    return (
        <Form
            form={form}
            layout="vertical"
            onValuesChange={(cv: any, av: any) => onChange(av)}
        >
            <Row gutter={24}>
                {/* profileName */}
                <Col span={12}>
                    <Form.Item
                        label='profileName'
                        name="profileName"
                        rules={[{ required: true }]}
                    >
                        <Input
                            bordered={false}
                            disabled={false}
                        />
                    </Form.Item>
                </Col>
                {/* remark */}
                <Col span={12}>
                    <Form.Item
                        label='remark'
                        name="remark"
                        rules={[{ required: false }]}
                    >
                        <Input
                            bordered={false}
                            disabled={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

type StepsTabType = Array<{
    title: string | JSX.Element,
    content: (props: any) => JSX.Element,
    description?: JSX.Element
}>

const StepsTab: StepsTabType = [
    {
        title: 'User Profile Management',
        content: Step1
    },
    {
        title: 'Calibrate Sensors',
        content: Step2,
        description: (
            <React.Fragment>
                <p>The keeogo belt should rest evenly on the user's waist. Hip sensors should be in line and articulate sagittally in accordance with the hip. The angle for motor initiation is the angle of kneee flexion, in standing, where weight bearing (WB)assistance begins to ramp up. Refer to Calibrated Knee Angles to help choose assigned angle. Have the user adopt the following posture and then calibrate Keeogo:</p>

                <br />

                <ul>
                    <li>-Standing upright, as if about to walk </li>
                    <li>-Heels hip width apart </li>
                    <li>-Feet in line and oriented in neutral stance</li>
                </ul>

                <br />
            </React.Fragment>
        )
    },
    {
        title: 'Assistance Level',
        content: Step3,
        description: (
            <React.Fragment>
                <br />
                <p>Set maximum potential assistance (nm). Adjust while the Keeogo control unit is set to the 3rd assistance level (submaximal). Assistance in swing phase is proportional to cycle speed.</p>
                <br />
            </React.Fragment>
        )
    },
    {
        title: 'Assisted range of motion',
        content: Step4,
        description: (
            <React.Fragment>
                <br />
                <p>The user's knee will be assisted through this portion of their full range of motion. Adjust settings to be specific to the user and desired clinical outcome. The assigned angle for motor initiation (step 2) is a constant reference (0°) for all range of motion settings.</p>
                <br />
            </React.Fragment>
        )
    },
    {
        title: 'Stairs Descent',
        content: Step5
    },
    {
        title: 'Slopes',
        content: Step6,
        description: (
            <React.Fragment>

            </React.Fragment>
        )
    },
    {
        title: 'Advanced Parameters',
        content: Step7
    },

]

export default function ProfileSteps(props: {
    data: any,
    step: number,
    onChange: (e: any) => void
}) {

    // console.log('[ProfileSteps props]', props)

    return (
        <div
            className='main pt-0 pr-0 pl-0 pb-0'
            style={{ height: 'auto' }}
        >
            <TabsStyled
                activeKey={String(props.step)}
                onChange={step => props.onChange({ step: Number(step) })}
                tabPosition='left'
            >
                {StepsTab.map((tab, key) => (
                    <Tabs.TabPane
                        key={String(key + 1)}
                        tab={(
                            <div style={{ textAlign: 'left' }}>
                                <strong style={{ fontWeight: 'bolder' }}>
                                    <big>Step {String(key + 1)}</big>
                                </strong>
                                <br />
                                {tab.title}
                            </div>
                        )}
                    >
                        <h1 style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>
                            Step : {String(key + 1)} {tab.title}
                        </h1>
                        {tab.description}
                        <tab.content
                            {...props.data}
                            onChange={(data: any) => props.onChange({ data })}
                        />
                    </Tabs.TabPane>
                ))}
            </TabsStyled>
        </div>
    )

}
