import { Checkbox, Radio } from 'antd';
import { useIntl } from 'react-intl';
import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../../../../index';
import styled from 'styled-components';
import { Form } from 'components/form';
import { FormTitle } from './EvaluationDrawer';
import { UserFittingData } from '../type';
import { FormInstance } from 'antd/lib/form';

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 1.5vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

const StyledCheckbox = styled(Checkbox)`
    display: flex;
    line-height: 1!important;
    align-items: flex-end;

    span {
        color: #2E2E2E;
    }

    .ant-checkbox {
        display: flex;
        align-items: center;

        .ant-checkbox-inner {
            border: 2px solid #707070!important;
        }
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: #707070!important;
            border: 2px solid #707070!important;
        }
    }
`;

interface Props {
    data: UserFittingData;
    form: FormInstance;
    showPreliminary: boolean;
}

const UserFitting: React.FC<Props> = ({ data, form, showPreliminary }: Props) => {

    const { formatMessage } = useIntl();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;

    const beltSizeOptions = getOptions('WaistBelt').sort((a: any, b: any)=>a.order-b.order);
    const waistlineInch = [[22,23,24], [25,26,27], [28,29,30], [31,32,33], [34,35,36], [37,38,39], [40,41,42], [43,44,45]];
    const waistlineCm = [[56,57,58,59,60,61], [64,65,66,67,68,69], [71,72,73,74,75,76], [78,79,80,81,82,83,84], [86,87,88,89,90,91], [94,95,96,97,98,99], [101,102,103,104,105,106,107], [109,110,111,112,113,114]];

    const beltOptions = beltSizeOptions.map((d: any)=>(
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    const inchOptions = waistlineInch[beltSizeOptions.indexOf(beltSizeOptions.find((d: any)=>d.code===data.keUfiKeeogoBeltSize) || beltSizeOptions[0])].map(d=>(
        <StyledRadio value={d.toString()}>{d.toString()}</StyledRadio>
    ));

    const cmOptions = waistlineCm[beltSizeOptions.indexOf(beltSizeOptions.find((d: any)=>d.code===data.keUfiKeeogoBeltSize) || beltSizeOptions[0])].map(d=>(
        <StyledRadio value={d.toString()}>{d.toString()}</StyledRadio>
    ));

    const MSoptions = ['M', 'S'].map(d=>(
        <StyledRadio value={d}>{d}</StyledRadio>
    ));

    const shaftLengthOptions = [1, 2, 3, 4, 5, 6, 7].map(d=>(
        <StyledRadio value={d.toString()}>{d.toString()}</StyledRadio>
    ));

    return (
        <div>
            <div className='text-24 text--bold mb-15'>{formatMessage({id:'USER_FITTING'})}</div>

            {/* <Form.Item label={<FormTitle>{formatMessage({id:'KEEOGO_BELT_SIZE'})}</FormTitle>} name='keUfiKeeogoBeltSize'>
                <Radio.Group buttonStyle="solid">{beltOptions}</Radio.Group>
            </Form.Item>

            <FormTitle>User waist size</FormTitle>

            <Form.Item label={<div className='text--bold' style={{color: '#2E2E2E'}}>inch</div>} name='keUfiUserWaistSizeInch'>
                <Radio.Group buttonStyle="solid" onChange={e=>form.setFieldsValue({keUfiUserWaistSizeCm: Math.round(parseInt(e.target.value)*2.54).toString()})}>{inchOptions}</Radio.Group>
            </Form.Item>

            <Form.Item label={<div className='text--bold' style={{color: '#2E2E2E'}}>cm</div>} name='keUfiUserWaistSizeCm'>
                <Radio.Group buttonStyle="solid" onChange={e=>form.setFieldsValue({keUfiUserWaistSizeInch: Math.round(parseInt(e.target.value)/2.54).toString()})}>{cmOptions}</Radio.Group>
            </Form.Item>

            <FormTitle>{formatMessage({id:'CONTACT_AREA_SIZE'})}</FormTitle>

            <div className='d-flex'>
                <Form.Item className='mr-50' label={<div className='text--bold' style={{color: '#2E2E2E'}}>{formatMessage({id:'UPPER_THIGH'})}</div>} name='keUfiKeeogoContactAreaSizePropriocption'>
                    <Radio.Group buttonStyle="solid">{MSoptions}</Radio.Group>
                </Form.Item>

                <Form.Item className='mr-50' label={<div className='text--bold' style={{color: '#2E2E2E'}}>{formatMessage({id:'LOWER_THIGH'})}</div>} name='keUfiKeeogoContactAreaSizesLowerThigh'>
                    <Radio.Group buttonStyle="solid">{MSoptions}</Radio.Group>
                </Form.Item>

                <Form.Item label={<div className='text--bold' style={{color: '#2E2E2E'}}>{formatMessage({id:'TIBIA'})}</div>} name='keUfiKeeogoContactAreaSizesTibia'>
                    <Radio.Group buttonStyle="solid">{MSoptions}</Radio.Group>
                </Form.Item>
            </div>

            <FormTitle>{formatMessage({id:'FEMORAL_SHAFT_LENGTH'})}</FormTitle>

            <div className='d-flex'>
                <Form.Item className='mr-30' label={<div className='text--bold text-20' style={{color: '#0083C1'}}>{formatMessage({id:'LT'})}</div>} name='keUfiKeeogoFemoralShaftLengthLt'>
                    <Radio.Group buttonStyle="solid">{shaftLengthOptions}</Radio.Group>
                </Form.Item>

                <Form.Item label={<div className='text--bold text-20' style={{color: '#0083C1'}}>{formatMessage({id:'RT'})}</div>} name='keUfiKeeogoFemoralShaftLengthRt'>
                    <Radio.Group buttonStyle="solid">{shaftLengthOptions}</Radio.Group>
                </Form.Item>
            </div> */}

            {showPreliminary &&
                <div>
                    <FormTitle>{formatMessage({id:'FITTING_CHECKLIST'})}</FormTitle>
                    <Form.Item name="keUfiFittingChecklistComAttFile">
                        <StyledCheckbox className='text--bold' checked={true}>{formatMessage({id:'COMPLETED_AND_ATTACHED_FILE'})}</StyledCheckbox>
                    </Form.Item>

                    <FormTitle>{formatMessage({id:'TUNING_RECORD'})}</FormTitle>
                    <Form.Item name="keUfiTurningValidationRecordComAttFile">
                        <StyledCheckbox className='text--bold' checked={true}>{formatMessage({id:'COMPLETED_AND_ATTACHED_FILE'})}</StyledCheckbox>
                    </Form.Item>
                </div>
            }
        </div>
    );
};

export default UserFitting;
