import React from 'react';
import styled from 'styled-components';
import { icon_share_white, icon_share_gray} from 'components/image';
import { Col, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { shareFile, unshareFile } from 'api/record';

interface Video {
    videoUrl: string;
    thumbnailUrl: string;
}

interface SharableContainerProps {
    bgc?: string;
    share?: boolean;
    tags?: string[];
    img?: string;
    className?: string;
    noShareIcon?: boolean;
    courseRecord?: any;
    frontalView?: Video;
    sagittalView?: Video;
    singleView?: Video;
    onClick?: () => void;
    fileId: number;
    updateVideoData: () => void;
    pending?: boolean;
}

const SharableContainer: React.FC<SharableContainerProps> = (props: SharableContainerProps) => {


    const tags = props.tags?.map(d => (
        <Tag>{d}</Tag>
    ))
    
    return (
        <ContainerWrapper bgc={props.pending ? '#2E2E2E' : props.bgc} onClick={props.pending ? ()=>{} : props.onClick}>
            {props.pending ? (
                <div className='d-flex h--center v--center height--full'>
                    <LoadingOutlined style={{fontSize: '30px', color: '#FFFFFF'}} />
                </div>
            ) : (
                <Row gutter={24} className='height--full width--full mt-0 ml-0 mr-0 mb-0' style={{justifyContent:'center'}}>
                
                    {props.singleView ? (
                        <Col span={24} className='height--full'>
                            {!props.noShareIcon && 
                                <img 
                                    className='icon cursor--pointer' 
                                    src={props.share ? icon_share_white : icon_share_gray} 
                                    onClick={props.share ? 
                                        e => {e.stopPropagation();unshareFile(props.fileId).then(props.updateVideoData).catch(err=>console.error(err));} :
                                        e => {e.stopPropagation();shareFile(props.fileId).then(props.updateVideoData).catch(err=>console.error(err));}
                                    }
                                />
                            }
                            <img src={props.singleView?.thumbnailUrl} width='100%' height='100%' style={{opacity: 0.9, objectFit: 'contain'}} />
                        </Col>
                    ) : (
                        <div className='d-flex'>
                            {!props.noShareIcon && 
                                <img 
                                    className='icon cursor--pointer' 
                                    src={props.share ? icon_share_white : icon_share_gray} 
                                    onClick={props.share ? 
                                        e => {e.stopPropagation();unshareFile(props.fileId).then(props.updateVideoData).catch(err=>console.error(err));} :
                                        e => {e.stopPropagation();shareFile(props.fileId).then(props.updateVideoData).catch(err=>console.error(err));}
                                    }
                                />
                            }
                            <Col span={12} className='height--full'>
                                <img src={props.frontalView?.thumbnailUrl} width='100%' height='100%' style={{opacity: 0.9, objectFit: 'contain'}} />
                            </Col>
                            <Col span={12} className='height--full'>
                                <img src={props.sagittalView?.thumbnailUrl} width='100%' height='100%' style={{opacity: 0.9, objectFit: 'contain'}} />
                            </Col>
                        </div>
                    )}
                    <div className="tags">
                        {tags}
                    </div>
                </Row>
            )}
            
        </ContainerWrapper>
    )
}

export default SharableContainer;

const ContainerWrapper = styled.div<{ bgc?: string }>`
   
    object-fit: cover;
    background-color: ${props => props.bgc ? props.bgc : '#E3E3E3'};
    border-radius: 0.2rem;
    overflow: hidden;
    border: none;
    cursor: pointer;
    aspect-ratio: 1.66;
    height: 100%;
    
    .icon {
        position: absolute;
        z-index:1;
        right: 10px;
    }
    
    .tags {
        position: absolute;
        bottom: 15px;
        left: 10px;
        display: flex;
        padding: 0 10px;

        >*:not(:last-child) {
            margin-right: 10px;
        }
    }
`;

const Tag = styled.div`
    border-radius: 2rem;
    background-color: #FFFFFF;
    color: #000000;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
`;

