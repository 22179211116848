import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { icon_remove, icon_expand } from 'components/image';
import ReactPlayer from 'react-player';
import moment from 'moment';

const VieoOptionWrapper = styled.div<{iconOffset?: string, timestampOffset?: string, tagOffset?: string}>`
    background-color: #000000;
    width: 100%;
    aspect-ratio: 1.8;
    border-radius: 0.3rem;
    cursor: ${props => props.onClick ? 'pointer' : 'default'};

    .videos {
        padding: 10px 15%;
        height: 100%;
    }

    .removeIcon, .expandIcon {
        position: absolute;
        top: 1%;
        right: ${props => props.iconOffset ? `calc(2% + ${props.iconOffset})` : '2%'};
        cursor: pointer;
    }

    .tags {
        position: absolute;
        bottom: 1%;
        left: ${props => props.tagOffset ? `calc(2% + ${props.tagOffset})` : '2%'};
        display: flex;
    }

    .timestamp {
        position: absolute;
        bottom: 1%;
        right: ${props => props.timestampOffset ? `calc(2% + ${props.timestampOffset})` : '2%'};
        color: #FFFFFF;
    }
`;

const Tag = styled.div`
    border-radius: 2rem;
    background-color: #FFFFFF;
    color: #000000;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 0 5px;
`;

interface VideoOptionProps {
    onClick?: Function
    removable?: boolean;
    onRemove?: () => void;
    expandable?: boolean;
    onExpand?: () => void;
    leftView?: string;
    rightView?: string;
    singleView?: string;
    labels?: string[];
    timestamp?: string;
    style?: object;
    iconOffset?: string;
    timestampOffset?: string;
    tagOffset?: string;
    videoUrl?: string;
    duration?: boolean;
}

const VideoOption: React.FC<VideoOptionProps> = (props: VideoOptionProps) => {

    const [videoDuration, setVideoDuration] = useState(0);

    const tags = props.labels?.map(d=>(
        <Tag>{d}</Tag>
    ));

    return (
        <VieoOptionWrapper 
            onClick={()=>props.onClick?.()} 
            style={props.style} 
            iconOffset={props.iconOffset} 
            timestampOffset={props.timestampOffset} 
            tagOffset={props.tagOffset}
        >
            <Row gutter={24} className='videos'>
                {props.singleView ? (
                    <Col span={24} className='height--full'>
                        <img src={props.singleView} width='100%' height='100%' style={{objectFit: 'contain'}} />
                    </Col>
                ) : (
                    <div className='d-flex width--full height--full'>
                        <Col span={12} className='height--full'>
                            <img src={props.leftView} width='100%' height='100%' style={{objectFit: 'contain'}} />
                        </Col>
                        <Col span={12} className='height--full'>
                            <img src={props.rightView} width='100%' height='100%' style={{objectFit: 'contain'}} />
                        </Col>
                    </div>
                )}
            </Row>
            {props.removable && 
            <div className='removeIcon' onClick={e=>{e.stopPropagation();props.onRemove?.();}}>
                <img src={icon_remove}/>
            </div>}
            {props.expandable && 
            <div className='expandIcon' onClick={e=>{e.stopPropagation();props.onExpand?.();}}>
                <img src={icon_expand}/>
            </div>}
            <div className='tags'>
                {tags}
            </div>
            <div className='timestamp'>
                {props.duration ? moment(0).add(videoDuration, 'seconds').format('mm:ss') : props.timestamp}
            </div>
            <ReactPlayer url={props.videoUrl} style={{display:'none'}} onReady={player=>setVideoDuration(player.getDuration())}/>
        </VieoOptionWrapper>
    )
}

export default VideoOption;
