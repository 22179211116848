import React from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form';
import styled from 'styled-components';

type Props = FormItemProps & {
    mb?: number;
};

export const FormItem = styled(({ mb = 32, ...props }) => (
    <Form.Item {...props} />
))<Props>`
    &.ant-form-item {
        margin-bottom: ${p => p.mb || 24}px;

        &.label--right > .ant-form-item-label {
            text-align: right;
        }

        &.label__width--full .ant-form-item-label > label {
            width: 100%;
        }
    }
`;
