import React from 'react';
import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/es/select';
import styled, { StyledComponentProps } from 'styled-components';

export type StyledSelectProps<T = SelectValue> = SelectProps<T> &
    StyledComponentProps<typeof StyledSelect, any, {}, never> & {
        ref?: any;
    };

const StyledSelect: any = styled(Select)`
    &.ant-select {
        &.ant-select-borderless .ant-select-selector {
            position: relative;
            bottom: 1px;
            padding-left: 1px;
            border-bottom: 1px solid #d9d9d9 !important;
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            background: ${props => (props.disabled ? '#F2F2F2 !important' : '')};
            color: ${props => (props.disabled ? props.theme.grey : '')};
        }

        &.border-bottom--cyan .ant-select-selector {
            height: auto;
            bottom: -5px;
            padding-bottom: 3px;
            border-bottom: 1px solid ${p => p.theme['cyan-4']} !important;
        }

        &.is-border-bottom--hide .ant-select-selector {
            border-bottom: none !important;
        }

        &.is--center .ant-select-dropdown .ant-select-item-option {
            text-align: center;
        }

        .ant-select-dropdown {
            * ::-webkit-scrollbar {
                display: none !important;
            }
        }
    }
`;

class SelectComponent<T = SelectValue> extends React.Component<StyledSelectProps<T>, any> {
    public static OptGroup = Select.OptGroup;
    public static Option = Select.Option;

    public static displayName = 'Select';

    render() {
        return <StyledSelect {...this.props} />;
    }
}

export { SelectComponent as Select };
