// tslint:disable quotemark
import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'antd';
import { compose, pathOr, map } from 'ramda';
import useSearch from 'hooks/useSearch';
// import { getAllCase, getCaseType, deleteCase } from 'api/case';
import { getCase, putUser } from 'api/case';
import { Case } from 'types/Case';
import CaseDrawer from './caseDrawer';
import { apiCaller } from 'service/apiCaller';
import { Select } from 'components/form';
import { useOrganizations } from 'api/organizationMgmtApi';
import { PersonalInfo, CaseType, CaseMap } from './caseMgmtType';
import CaseList from './caseList';
import { DataSource } from 'constants/types';
import { confirmToDelete, success as successMsg, error as errorMsg } from 'utils/message';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export default function CaseManagement() {
    const intl = useIntl();
    const [list, setList] = useState<DataSource<Case>>({
        content: [],
        total: 0,
    } as any)
    const [form] = Form.useForm()
    const [loading,setLoading] = useState(false)

    const getList = async ({
        page = 1,
        pageSize = 10,
        ...params
    }: {[key: string]: any;} = {}) => {
        try{
            setLoading(true)
            // get all users ( case )
            await apiCaller(
                getCase,
                {
                    page,
                    pageSize,
                    ...params
                },
                (res: any) => {
                    setList({
                        content: res.data.data,
                        total: res.data.total,
                    });
                },
                (e:any)=>{throw(e)},
            );
        }catch(e){
            console.error(e)
        }finally{
            setLoading(false)
        }
    };

    const [personalCase, setPersonalCase] = useState({});
    const [drawerSetting, setDrawerSetting] = useState<{type:'add'|'edit',visible:boolean}>({type:'add',visible:false});

    const {
        handleSearch,
        handleTableChange,
        getQueries,
        pageNum,
        pageSize,
        sorter,
        handlePaginationChange,
    } = useSearch<PersonalInfo>({
        refetch: getList,
        getFieldsValue: form.getFieldsValue,
    });

    useEffect(() => {
        getList({page:pageNum, pageSize});
    }, []);

    return (
        <Wrapper>
            <React.Fragment>
                <CaseDrawer
                    visible={drawerSetting.visible}
                    item={personalCase}
                    refetchQueries={(_: string) => getList(getQueries())}
                    onClose={() => setDrawerSetting({
                        type:drawerSetting.type,
                        visible:false
                    })}
                    key={Date.now().toString()}
                />
                <CaseList
                    loading={loading}
                    refetch={getList}
                    list={list}
                    form={form}
                    onSearch={(e:any)=>{
                        getList({page:1, pageSize:pageSize ,name:e,userId:e})
                    }}
                    funcs={{
                        setPersonalCase,
                        setDrawerSetting,
                        handleSearch,
                        handleTableChange,
                        handlePaginationChange,
                        pageNum,
                        pageSize,
                        sorter
                    }}
                />
            </React.Fragment>
        </Wrapper>
    );
}
