import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import { icon_export } from 'components/image';
import { LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { PageProps } from './ParameterTab';
import { getParameter, ParameterProfile } from 'api/parameter';
import ParameterList from './ParameterList';
import ParameterArchive from './ParameterArchive';
import styled from 'styled-components';
import Label from 'components/label/label';
import ModifiedRecord from '../ModifiedRecord';
import { Spin, Checkbox, Radio, Modal, Progress } from 'antd';
import Slider from 'components/slider/slider';
import { AxiosResponse } from 'axios';
import { Response } from 'types/Api';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const Wrapper = styled.div`
    width: 100%;
    background-color: #FFFFFF;
    padding: 15px 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .Title {
            color: #2E2E2E;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .ant-slider, .ant-slider-handle,
    .ant-checkbox-wrapper, .ant-checkbox, .ant-checkbox-input,
    .ant-radio-group, .ant-radio-wrapper, .ant-radio, .ant-radio-input {
        cursor: not-allowed;
    }
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 35px;
    color: #2E2E2E;
`;

const Steps = styled.div`
    .step {
        margin-bottom: 40px;
    }
`;

interface DetailProps extends PageProps {
    parameterId: number;
    parameterData: ParameterProfile;
}

const ParameterDetail: React.FC<DetailProps> = (props: DetailProps) => {

    const { formatMessage } = useIntl();
    const [currentData, setCurrentData] = useState<ParameterProfile>(props.parameterData);
    const [loading, setLoading] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);
    const [modalSetting, setModalSetting] = useState({
        showProgressModal: false,
        showFailModal: false
    });
    const [permission] = usePermission('Parameter');

    const { parameterId, ...pageProps } = props;

    useEffect(()=>{
        getCurrentParameter();
    }, []);

    const getCurrentParameter = () => {
        setLoading(true);
        try{
            getParameter(
                parameterId
            ).then((res: AxiosResponse<Response<ParameterProfile>>)=>{
                setCurrentData(res.data.data);
            });
        }
        catch(error){
            console.error(error);
            throw(error);
        }
        finally{
            setLoading(false);
        }
    }

    const formatExportData = (data: ParameterProfile) => {
        const prefixData = 'Type:UserProfile;\nVersion:3;\n';
        
        let fileString = prefixData;    
        const parameterKeys = Object.keys(data).filter(d=>d.startsWith('Par'));
        for(let i=0; i<parameterKeys.length; ++i){
            fileString = fileString.concat(`Par:${parameterKeys[i].substring(3)}=${data[parameterKeys[i]]};\n`);
        }
        const fileName = data.profileName;
        const fileData = Buffer.from(fileString, 'utf-8');

        return { fileName, fileData };
    }

    const handleExport = () => {
        const zip = new JSZip();
        const folderName = `${props.caseData.abbreviation}-${props.caseData.name.replaceAll(' ', '')}${props.caseData.birthday ? ('-'+moment(props.caseData.birthday).format('YYYYMMDD')) : ''}`;
        
        let infoData = '';
        infoData = infoData.concat(`firstName = ${props.caseData.name}\n`);
        infoData = infoData.concat(`lastName = \n`);
        infoData = infoData.concat(`dateOfBirth = ${props.caseData.birthday ? moment(props.caseData.birthday).format('YYYY-MM-DD') : ''}\n`);
        infoData = infoData.concat(`waistBelt = ${props.profileData.waistBeltCode || ''}\n`);
        infoData = infoData.concat(`waistlineInch = ${props.profileData.waistlinlnch || ''}\n`);
        infoData = infoData.concat(`waistlineCM = ${props.profileData.waistlineCm || ''}\n`);
        infoData = infoData.concat(`height = ${props.profileData.height || ''}\n`);
        infoData = infoData.concat(`weight = ${props.profileData.weight || ''}\n`);
        infoData = infoData.concat(`femoralLength = ${props.profileData.femoralLength || ''}\n`);
        infoData = infoData.concat(`tibialLength = ${props.profileData.tibialLength || ''}\n`);
        zip.file(`${folderName}/${folderName}.data`, Buffer.from(infoData, 'utf-8'));

        const { fileName, fileData } = formatExportData(currentData);
        zip.file(`${folderName}/${fileName}.profile`, fileData);

        zip.generateAsync({type: 'blob'}, metadata => {
            setModalSetting({...modalSetting, showProgressModal: true});
            setProgressPercent(Math.round(metadata.percent));
        }).then(blob => {
            setModalSetting({...modalSetting, showProgressModal: false});
            saveAs(blob, `${folderName}.zip`);
            Modal.success({
                title: <div className='text--bold text-30'>{formatMessage({id:'SUCCESSFUL'})}</div>,
                content: (
                    <div className='d-flex is--column is--center'>
                        <p className='text--center'>{formatMessage({id:'SUCCESS_EXPORT_MSG'})}</p>
                        <p className='mb-50 text--500'>{`${formatMessage({id:'PARAM_NUM'})}: 1`}</p>
                        <Button
                            style={{width: '80%', height: '40px', backgroundColor: '#9EC25B', color: '#FFFFFF'}}
                            onClick={()=>Modal.destroyAll()}
                        >{formatMessage({id:'GOOD'})} !</Button>
                    </div>
                )
            });
        }, (error: any) => {
            console.error(error);
            Modal.error({
                title: <div className='text--bold text-30'>{formatMessage({id:'FAIL_TITLE'})}</div>,
                content: (
                    <div className='d-flex is--column is--center'>
                        <p className='text--center mb-50'>{formatMessage({id:'FAIL_EXPORT_MSG'}, {br:<br/>})}</p>
                        <Button
                            style={{width: '80%', height: '40px', backgroundColor: '#DD4242', color: '#FFFFFF', marginBottom: '10px'}}
                            onClick={()=>{handleExport();Modal.destroyAll();}}
                        >{formatMessage({id:'TRY_AGAIN'})}</Button>
                        <Button
                            type='link'
                            style={{width: '80%', height: '40px', color: '#2E2E2E', fontWeight: 'bold'}}
                            onClick={()=>Modal.destroyAll()}
                        >{formatMessage({id:'DISMISS'})}</Button>
                    </div>
                )
            });
        });
    }

    const tableData = [
        {
            rowName: formatMessage({id:'HIP_ANGLE'}), 
            leftLeg: '-5.00°', 
            leftCalibrated: currentData.Par16, 
            rightLeg: '-5.00°', 
            rightCalibrated: currentData.Par17
        },
        {
            rowName: formatMessage({id:'PELVIC_TILT'}), 
            leftLeg: '0.00°', 
            leftCalibrated: currentData.Par16,
            rightLeg: '0.00°', 
            rightCalibrated: currentData.Par17
        },
        {
            rowName: formatMessage({id:'KNEE_ANGLE'}), 
            leftLeg: '4.00°', 
            leftCalibrated: currentData.Par16,
            rightLeg: '4.00°', 
            rightCalibrated: currentData.Par17
        },
        {
            rowName: formatMessage({id:'THIGH_PITCH'}), 
            leftLeg: 'n/a', 
            leftCalibrated: currentData.Par16,
            rightLeg: 'n/a', 
            rightCalibrated: currentData.Par17
        }
    ];

    const modifiedHistory = currentData.modifyRecord?.sort((a, b)=>b.LastModified-a.LastModified).map(record=><ModifiedRecord updatedAt={record.LastModified} editorName={record.createName} status={record.logType} content={record.modifyMemo}/>) || [];

    return (
        <Wrapper>
            <Modal visible={modalSetting.showProgressModal} footer={null} closable={false} maskClosable={true} onCancel={()=>setModalSetting({...modalSetting, showProgressModal: false})} >
                <Progress percent={progressPercent} strokeColor='#66BCEA' strokeWidth={15} status='active' />
                <span className='width--full d-flex is--center text-18 mt-20 text--500'>Exporting...</span>
            </Modal>
            <Spin spinning={loading}>
                <div className="header d-flex v--center mb-15 width--full" style={{position: 'sticky', top: 0, backgroundColor: '#FFFFFF', marginTop: '-15px', padding: '15px 0', zIndex: 1000}}>
                    <div className="Title">
                        <LeftOutlined onClick={()=>{props.refetch();props.setPageContent(currentData?.invalid ? <ParameterArchive {...pageProps}/> : <ParameterList {...pageProps} />)}} />
                        <span className='ml-15'><b><strong>{formatMessage({id:'PARAMETERS'})}</strong></b></span>
                    </div>
                    <div className="d-flex">
                        {checkIfAvailable(permission, 'Export') && <Button
                            className="rect ml-5"
                            icon={<img src={icon_export} className='mr-5' />}
                            onClick={handleExport}
                            style={{backgroundColor: '#0083C1', color: '#FFFFFF', border: 'none'}}
                        >{formatMessage({id:'EXPORT'})}</Button>}
                    </div>
                </div>
                <Steps>
                    <div className="step">
                        <Title>Step 1: Information</Title>
                        <div className="stepContent">
                            <Label className='mb-40' name={formatMessage({id:'USER_PROFILENAME'})} text={currentData?.profileName || '--'}/>
                            <Label name={formatMessage({id:'REMARK'})} text={currentData?.memo || '--'}/>
                        </div>
                    </div>
                    <div className="step">
                        <Title>Step 2: Calibrate Sensors</Title>
                        <div className="stepContent">
                            <p>The keeogo belt should rest evenly on the user's waist. Hip sensors should be in line and articulate sagittally in accordance with the hip. The angle for motor initiation is the angle of kneee flexion, in standing, where weight bearing (WB)assistance begins to ramp up. Refer to Calibrated Knee Angles to help choose assigned angle. Have the user adopt the following posture and then calibrate Keeogo:</p>
                            <ul>
                                <li>- Standing upright, as if about to walk </li>
                                <li>- Heels hip width apart </li>
                                <li>- Feet in line and oriented in neutral stance</li>
                            </ul>
                            <div className="d-flex is--space-between" style={{padding: '0 15%'}}>
                                <Panel width='60%' height='350px'>
                                    <div className="Title">
                                        Angle for motor initiation
                                    </div>
                                    <div className="d-flex is--center width--full height--full">
                                        <AngleTable data={tableData} />
                                    </div>
                                </Panel>
                                <Panel width='30%' height='350px'>
                                    <div className="Title">Angle Calibrator</div>
                                    <div className='width--full d-flex is--row is--space-around' style={{height: '80%'}}>
                                        <Slider reverse vertical max={30} min={0} value={parseInt(currentData.Par16)} label='Left' indicator unit='°'/>
                                        <Slider reverse vertical max={30} min={0} value={parseInt(currentData.Par17)} label='Right' indicator unit='°'/>
                                    </div>
                                </Panel>
                            </div>
                        </div>
                    </div>
                    <div className="step">
                        <Title>Step 3: Assistance Level</Title>
                        <div className="stepContent">
                            <p>Set maximum potential assistance (nm). Adjust while the Keeogo control unit is set to the 3rd assistance level (submaximal). Assistance in swing phase is proportional to cycle speed.</p>
                        </div>
                        <div className="d-flex is--space-between" style={{padding: '0 15%'}}>
                            <Panel width='45%' height='350px'>
                                <div className="Title">Swing Phase</div>
                                <div className='width--full d-flex is--row is--space-between height--full'>
                                    <div className='d-flex is--column height--full' style={{width: '45%'}}>
                                        <span style={{fontSize: '14px', textAlign: 'center'}}>Flexion Assistance</span>
                                        <div className='d-flex is--row is--space-around height--full'>
                                            <Slider vertical max={20} min={0} value={parseInt(currentData.Par18)} label='Left' indicator unit='Nm'/>
                                            <Slider vertical max={20} min={0} value={parseInt(currentData.Par19)} label='Right' indicator unit='Nm'/>
                                        </div>
                                    </div>
                                    <div className='d-flex is--column height--full' style={{width: '45%'}}>
                                        <span style={{fontSize: '14px', textAlign: 'center'}}>Extension Assistance</span>
                                        <div className='d-flex is--row is--space-around height--full'>
                                            <Slider vertical max={20} min={0} value={parseInt(currentData.Par22)} label='Left' indicator unit='Nm'/>
                                            <Slider vertical max={20} min={0} value={parseInt(currentData.Par23)} label='Right' indicator unit='Nm'/>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                            <Panel width='45%' height='350px'>
                                <div className="Title">Weight Bearing</div>
                                <div className='width--full d-flex is--row is--space-between height--full'>
                                    <div className='d-flex is--column height--full' style={{width: '45%'}}>
                                        <span style={{fontSize: '14px', textAlign: 'center'}}>Eccentric Control</span>
                                        <div className='d-flex is--row is--space-around height--full'>
                                            <Slider vertical max={40} min={15} value={parseInt(currentData.Par30)} label='Left' indicator unit='Nm'/>
                                            <Slider vertical max={40} min={15} value={parseInt(currentData.Par31)} label='Right' indicator unit='Nm'/>
                                        </div>
                                    </div>
                                    <div className='d-flex is--column height--full' style={{width: '45%'}}>
                                        <span style={{fontSize: '14px', textAlign: 'center'}}>Extension Assistance</span>
                                        <div className='d-flex is--row is--space-around height--full'>
                                            <Slider vertical max={40} min={15} value={parseInt(currentData.Par26)} label='Left' indicator unit='Nm'/>
                                            <Slider vertical max={40} min={15} value={parseInt(currentData.Par27)} label='Right' indicator unit='Nm'/>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                    <div className="step">
                        <Title>Step 4: Assisted range of motion</Title>
                        <div className="stepContent">
                            <p>The user’s knee will be assisted through this portion of their full range of motion. Adjust settings to be specific to the user and desired clinical outcome. The assigned angle for motor initiation (step 2) is a constant reference (0°) for all range of motion settings.</p>
                        </div>
                        <div className="d-flex is--space-between" style={{padding: '0 15%'}}>
                            <Panel width='45%' height='350px'>
                                <div className="Title">Swing Phase</div>
                                <div className='width--full d-flex is--row is--space-between height--full'>
                                    <div className='d-flex is--column height--full' style={{width: '45%'}}>
                                        <span style={{fontSize: '14px', textAlign: 'center'}}>Flexion</span>
                                        <div className='d-flex is--row is--space-around height--full'>
                                            <Slider vertical max={60} min={40} value={parseInt(currentData.Par20)} label='Left' indicator unit='°'/>
                                            <Slider vertical max={60} min={40} value={parseInt(currentData.Par21)} label='Right' indicator unit='°'/>
                                        </div>
                                    </div>
                                    <div className='d-flex is--column height--full' style={{width: '45%'}}>
                                        <span style={{fontSize: '14px', textAlign: 'center'}}>Extension</span>
                                        <div className='d-flex is--row is--space-around height--full'>
                                            <Slider reverse vertical max={40} min={20} value={parseInt(currentData.Par24)} label='Left' indicator unit='°'/>
                                            <Slider reverse vertical max={40} min={20} value={parseInt(currentData.Par25)} label='Right' indicator unit='°'/>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                            <Panel width='45%' height='350px'>
                                <div className="Title">Weight Bearing</div>
                                <div className='width--full d-flex is--row is--space-around height--full'>
                                    <div className='d-flex is--column height--full' style={{width: '45%'}}>
                                        <span style={{fontSize: '14px', textAlign: 'center'}}>End of<br/>extension assistance</span>
                                        <div className='d-flex is--row is--space-around height--full'>
                                            <Slider reverse vertical max={20} min={0} value={parseInt(currentData.Par28)} label='Left' indicator unit='°'/>
                                            <Slider reverse vertical max={20} min={0} value={parseInt(currentData.Par29)} label='Right' indicator unit='°'/>
                                        </div>
                                    </div>
                                    <div className='d-flex is--column height--full' style={{width: '45%'}}>
                                        <span style={{fontSize: '14px', textAlign: 'center'}}>Stairs/Squat<br/>Detection Threshold</span>
                                        <div className='d-flex is--row is--space-around height--full'>
                                            <Slider reverse vertical max={45} min={10} value={parseInt(currentData.Par53)} indicator unit='°'/>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                    <div className="step">
                        <Title>Step 5: Stairs Descent</Title>
                        <div className="d-flex is--column" style={{padding: '0 15%'}}>
                            <Panel width='100%' className='mb-20'>
                                <div className="Title">Front leg WB start angle</div>
                                <div className="content d-flex is--space-between">
                                    <div className="description" style={{width: '45%'}}>
                                        Knee angle at witch Keeogo can begin to provide weight bearing assistance to user in stance. Set value LARGER than user's true terminal extension angle to ensure safety. The assigned angle for motor initiation (step 2) is a constant reference (0°) for this setting.
                                    </div>
                                    <div className="d-flex is--column is--space-around" style={{width: '45%'}}>
                                        <Slider max={30} min={15} value={parseInt(currentData.Par44)} label='Left' indicator unit='°'/>
                                        <Slider max={30} min={15} value={parseInt(currentData.Par45)} label='Right' indicator unit='°'/>
                                    </div>
                                </div>
                            </Panel>
                            <Panel width='100%' className='mb-20'>
                                <div className="Title">Back leg WB release angle</div>
                                <div className="content d-flex is--space-between">
                                    <div className="description" style={{width: '45%'}}>
                                        Knee angle at witch Keeogo releases weight bearing assistance to allow for free swing of the limb. Set value SMALLER than user’s true terminal flexion angle to ensure safety. The assigned angle for motor initiation (step 2) is a constant reference (0°) for this setting.
                                    </div>
                                    <div className="d-flex is--column is--space-around" style={{width: '45%'}}>
                                        <Slider max={80} min={50} value={parseInt(currentData.Par46)} label='Left' indicator unit='°'/>
                                        <Slider max={80} min={50} value={parseInt(currentData.Par47)} label='Right' indicator unit='°'/>
                                    </div>
                                </div>
                            </Panel>
                            <Panel width='100%' className='mb-20'>
                                <div className="Title">Motor ramp down</div>
                                <div className="content d-flex is--space-between">
                                    <div className="description" style={{width: '45%'}}>
                                        Time for motor torque to ramp down to 0nm. A smaller value for will make torque leave the system faster making it more responsive. Increase value for slow walkers.
                                    </div>
                                    <div className="d-flex is--column is--space-around" style={{width: '45%'}}>
                                        <Slider max={1000} min={1} value={parseInt(currentData.Par37)} label='Left' indicator unit='ms'/>
                                        <Slider max={1000} min={1} value={parseInt(currentData.Par38)} label='Right' indicator unit='ms'/>
                                    </div>
                                </div>
                            </Panel>
                            <Panel width='100%' className='mb-20'>
                                <div className="Title">Neutral Position/Stairs descent timeout</div>
                                <div className="content d-flex is--space-between">
                                    <div className="description" style={{width: '45%'}}>
                                        Neutral stance/Stair exit timeout is the time Keeogo associates with a user being inactive (standing with both feet parallel) leading it to become idle. Increase values for slow movers. Use your professional judgement to ensure that these values are appropriate for the user’s safety and needs.
                                    </div>
                                    <div className="d-flex is--column is--space-around" style={{width: '45%', height: '120px'}}>
                                        <Slider max={500} min={200} value={parseInt(currentData.Par48)} indicator unit='ms'/>
                                        <Slider max={3000} min={300} value={parseInt(currentData.Par49)} indicator unit='ms'/>
                                        <div className="d-flex is--column align-items--start ml-50">
                                            <Checkbox checked={parseInt(currentData.Par54) > 0}>Long</Checkbox>
                                            <div>
                                                <span className='mr-10'>Short:</span>
                                                <Radio checked={parseInt(currentData.Par55) === 0 && parseInt(currentData.Par56) === 0}>None</Radio>
                                                <Radio checked={parseInt(currentData.Par55) > 0}>Normal</Radio>
                                                <Radio checked={parseInt(currentData.Par56) > 0}>Corrected</Radio>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                    <div className="step">
                        <Title>Step 6: Advanced Parameters</Title>
                        <div className="d-flex is--column" style={{padding: '0 15%'}}>
                            <Panel width='100%' className='mb-20'>
                                <div className="Title">Continues flexion(-) /<br/>Extension(+) assistance</div>
                                <div className="content d-flex is--space-between">
                                    <div className="description" style={{width: '45%'}}>
                                        Adds a persistent torque in either flexion or extension. This may alter the desired angle for motor initiation in step 2 and assistance levels in step 3 (to compensate for the applied torque).
                                    </div>
                                    <div className="d-flex is--column is--space-around" style={{width: '45%'}}>
                                        <Slider max={5} min={-5} value={parseInt(currentData.Par8)} label={<span style={{whiteSpace: 'nowrap', minWidth: '90px'}}>Left Torque<br/>Offset</span>} indicator unit='Nm'/>
                                        <Slider max={5} min={-5} value={parseInt(currentData.Par9)} label={<span style={{whiteSpace: 'nowrap', minWidth: '90px'}}>Right Torque<br/>Offset</span>} indicator unit='Nm'/>
                                    </div>
                                </div>
                            </Panel>
                            <Panel width='100%' className='mb-20'>
                                <div className="Title">Motor ramp up</div>
                                <div className="content d-flex is--space-between">
                                    <div className="description" style={{width: '45%'}}>
                                        Regulates the rate at which the assistance levels set in step 3 are reached. Adjusting this value is likely to be perceived by the user in the initial phase of their movement. Faster moving users may require a higher value.
                                    </div>
                                    <div className="d-flex is--column is--space-around" style={{width: '45%'}}>
                                        <Slider max={10} min={1} value={parseInt(currentData.Par32)} label='Level' indicator/>
                                    </div>
                                </div>
                            </Panel>
                            <Panel width='100%' className='mb-20'>
                                <div className="Title">Beeper</div>
                                <div className="content d-flex is--space-between">
                                    <div className="description" style={{width: '45%'}}>
                                        An audible beep signals when Keeogo no longer detects user motion (neutral stance timeout and stair exit timeout) and is idle in anticipation of the user’s next movement.
                                    </div>
                                    <div className="d-flex is--column is--space-around" style={{width: '45%'}}>
                                        <Checkbox checked={parseInt(currentData.Par50) > 0}>Beeper</Checkbox>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                </Steps>
                <Title>{formatMessage({id:'MODIFIED_RECORD'})}</Title>
                <div className="record">
                    {modifiedHistory}
                </div>
            </Spin>
        </Wrapper>
    );
}

export default ParameterDetail;

const Panel = styled.div<{width?: string, height?: string}>`
    border-radius: 0.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    width: ${props => props.width ? props.width : 'auto'};
    height: ${props => props.height ? props.height : 'auto'};
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .Title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
    }
`;

interface Column {
    rowName: string;
    leftLeg: string;
    leftCalibrated: string;
    rightLeg: string;
    rightCalibrated: string;
}

interface TableProps {
    data: Column[];
}

const StyledTable = styled.table`
    font-size: 1.2vw;
    width: 100%;
    border-spacing: 0 20px;

    td {
        text-align: center;
        padding: 10px 0;

        &:nth-child(3) {
            border-right: 2px solid #707070;
        }

        &:nth-child(4) {
            border-left: 2px solid #707070;
        }
    }

    tbody {
        td {
            &:not(:first-child) {
                color: #0083C1;
            }
        }
    }
`;

const AngleTable: React.FC<TableProps> = (props: TableProps) => {

    const { formatMessage } = useIntl();

    const dataRow = props.data.map(datum=> (
            <tr>
                <td colSpan={4}>{datum.rowName}</td>
                <td colSpan={3}>{datum.leftLeg}</td>
                <td colSpan={3}>--</td>
                <td colSpan={3}>{datum.rightLeg}</td>
                <td colSpan={3}>--</td>
            </tr>
        )
    );

    return (
        <StyledTable>
            <thead>
                <tr>
                    <td colSpan={4}></td>
                    <td colSpan={3}><b>{formatMessage({id:'LEFT_LEG'})}</b></td>
                    <td colSpan={3}>{formatMessage({id:'CALIBRATED'})}</td>
                    <td colSpan={3}><b>{formatMessage({id:'RIGHT_LEG'})}</b></td>
                    <td colSpan={3}>{formatMessage({id:'CALIBRATED'})}</td>
                </tr>
            </thead>
            <tbody>
                {dataRow}
            </tbody>
        </StyledTable>
    )
}
