import './index.sass';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { mergeDeepRight } from 'ramda';
import zhTW from 'antd/es/locale-provider/zh_TW';
import en_US from 'antd/es/locale-provider/en_US';
import Pagination from 'rc-pagination/lib/locale/zh_TW';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import flat from 'flat';
import 'dotenv/config';

// 匯入語系檔，如果語系太多，則獨立成一個檔案，以便管理
import { enLang } from './assets/i18n/en';
import { twLang } from './assets/i18n/tw';

// import enLangTest from './assets/i18n/en.json';
// import zhLangTest from './assets/i18n/zh.json';

import { GlobalContextType, DropdownOptionCtxType } from 'types/GlobalContextType';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GA_API_KEY,
  authDomain: process.env.REACT_APP_GA_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_GA_PROJECT_ID,
  storageBucket: process.env.REACT_APP_GA_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_GA_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_GA_APP_ID,
  measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const GlobalContext = React.createContext<GlobalContextType>({} as GlobalContextType);

const Root = () => {
    // https://kentcdodds.com/blog/should-i-usestate-or-usereducer
    // 設定GlobalContext也可以用useReducer
    const [permissionCtx, setPermissionCtx] = useState({});
    const [menuCtx , setMenuCtx] = useState([]);
    const [dropdownOptionCtx, setDropdownOptionCtx] = useState<DropdownOptionCtxType>({
        optionType: [],
        selectOption: [],
        nameMapping: {},
        getOptions: (type: string) => [],
        getCode: (type: string | string[], nameValue: string) => ''
    });
    const langMapping = {
        EN: flat(enLang),
        TW: flat(twLang)
    };

    let lang = localStorage.getItem('lang')//navigator.language;

    if(lang !== 'EN' && lang !== 'TW'){
        localStorage.setItem('lang', 'EN');
        lang = 'EN';
    }

    const messages = langMapping[lang];
    
    return (
        <ConfigProvider locale={{ ...en_US }}>
            <IntlProvider locale={lang} key={lang} messages={messages} defaultLocale="tw">
                <GlobalContext.Provider value={{ permissionCtx, setPermissionCtx , menuCtx , setMenuCtx, dropdownOptionCtx, setDropdownOptionCtx}}>
                    <App />
                </GlobalContext.Provider>
            </IntlProvider>
        </ConfigProvider>
    );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
