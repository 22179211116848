import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Avatar, Dropdown, Menu, Modal } from 'antd';
import { AvatarProps } from 'antd/es/avatar';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import './avatar.sass';
import { apiCaller } from 'service/apiCaller';
import { logoutApi } from 'api/personal';
import { useQueryUser, importAvatar } from 'api/user';
import { useIntl } from 'react-intl';
import EditMyAccountDrawer from './editMyAccountDrawer';
import { GlobalContext } from '../../index';
import { icon_camera } from '../image';

interface Props {
    avatarSrc?: string;
}

export const DropdownAvatar: React.FC<Props> = ({avatarSrc}: Props) => {

    const { formatMessage } = useIntl();
    const {loading, queryUser, userItem} = useQueryUser();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;

    const [setting, setSetting] = useState({
        showUploadModal: false,
        showEditSuccessModal: false,
        showEditDrawer: false
    });

    useEffect(()=>{
        const success = (res:any) => {
            
        }
        const reject = (err: Error) => {
            throw(err);
        }
        
        apiCaller(
            queryUser,
            window.localStorage.getItem('uid'),
            success,
            reject
        );
    }, []);

    const logout = () => {
        const success = (res: any) => {
            const { status } = res;
            if (status === 200) {
                window.localStorage.removeItem('token');
                window.location.href = '/login';
            }
        };
        const reject = (error: any) => {
            window.localStorage.removeItem('token');
            window.location.href = '/login';
        };
        apiCaller(logoutApi, null, success, reject);
    };

    const uploadAvatar = () => {
        const dom = (document.querySelector('input#filepicker') as any)
        dom.value = ""
        dom.mutiple = false
        dom.click()
        dom.onchange = (e:any)=>onImport(e)
    }

    const onImport = async (e:any) => {
        const file = e.target.files[0];
        const form = new FormData();
        form.append('fhirid', userItem?.id.toString() || '');
        form.append('image', file, file.name);
        await importAvatar(
            form
        ).then((res: any)=>{
            Modal.success({
                maskClosable:true,
                title:'Success'
            })
        }).catch((error: any)=>{
            console.error(error)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = error?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            })
        });
    }

    const openEditDrawer = () => {
        const success = (res:any) => {
            
        }
        const reject = (err: Error) => {
            throw(err);
        }
        
        apiCaller(
            queryUser,
            window.localStorage.getItem('uid'),
            success,
            reject
        );
        setSetting({...setting, showEditDrawer: true});
    }

    const multilingualKeyMapping = {
        LAN01: 'EN',
        LAN02: 'TW'
    }

    const langItems = getOptions('Language').map((d: any)=>(
        <Menu.Item onClick={()=>{localStorage.setItem('lang', multilingualKeyMapping[d.code]);window.location.reload();}}>
            {d.name}
        </Menu.Item>
    ));
    
    const dropdownOption = (
        <Menu>
            <div className='profile'>
                <Avatar_menu className='profile-avatar'>
                    <AvatarComponent onClick={uploadAvatar} src={avatarSrc}/>
                    <Camera className='camera' onClick={uploadAvatar}><img src={icon_camera} /></Camera>
                </Avatar_menu>
                <div className='profile-text'>
                    <span className='username'>{userItem?.name || 'PT Name'}</span>
                    <span className='org'>{userItem?.managingOrganizationName  || 'Organization Name'}</span>
                </div>
            </div>
            <Menu.Item onClick={openEditDrawer}>
                {formatMessage({id: 'EDIT_ACCOUNT'})}
            </Menu.Item>
            {/* <Menu.Item>
                Settings
            </Menu.Item> */}
            <Menu.SubMenu title={formatMessage({id:'LANG'})}>
                {langItems}
            </Menu.SubMenu>
            <hr/>
            <Menu.Item onClick={logout}>
                {formatMessage({id: 'LOGOUT'})}
            </Menu.Item>
        </Menu>
    );

    return (
        <div className='dropdown_avatar' >
            <input type="file" id="filepicker" name="avatarSelector" accept='image/png, image/jpeg' hidden />
            {setting.showEditDrawer&&<EditMyAccountDrawer item={userItem} onClose={()=>setSetting({...setting, showEditDrawer: false})} refetchQueries={()=>{}} />}

            <Dropdown
                overlay={dropdownOption}
                placement='bottomRight'
                trigger={['click']}
            >
                <AvatarComponent src={avatarSrc}/>
            </Dropdown>
        </div>
        
    );
}

interface Props extends AvatarProps {
    onClick?: Function;
    gender?: string;
}

export const AvatarComponent: React.FC<Props> = ({...props}: Props) => {
    const customProps = {
        ...props,
        icon: props.src?null:<UserOutlined/>,
        src: props.src
    }
    return (
        <StyledAvatar {...customProps}/>
    );
}

const StyledAvatar = styled(({gender, ...props})=><Avatar {...props} />)`
    border: ${
        props=>
            props.gender?
                `${props.gender == 'Male'?'#0083C1'
                    :props.gender == 'Female'?'#F287B8'
                        :'none'} 2px solid`
            :'none'
    }
`;

const Avatar_menu = styled.div`
    position: relative;
`;

const Camera = styled.div`
    position: absolute;
    right : 1px;
    top: 12px;
`;