import { Tag } from 'antd';
import styled from 'styled-components';

export const Block = styled.div`
    background: #fff;
    box-shadow: 0px 1px 10px #0000001a;
    border-radius: 0.5rem;
    &.green-gradient {
        background: transparent linear-gradient(180deg, #06d4c5 0%, #007793 100%) 0%
            0% no-repeat padding-box;
    }
    &.blue-gradient {
        background: transparent linear-gradient(180deg, #2dccda 0%, #006a91 100%) 0%
            0% no-repeat padding-box;
    }
`;

export const RoleTag = styled(Tag)`
    margin-right: 10px;
    padding-left: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 32px;
    border: 1px solid var(--color-primary-light);
    font-size: 14px;
    background: transparent;
    .anticon-close {
        margin-left: 18px;
        font-size: 16px;
        color: var(--color-primary-light);
    }
`;
