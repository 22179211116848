import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import TrainingItemCard from './TrainingItemCard';
import { PageProps } from './RecordTab';
import { LeftOutlined, PlusOutlined, UploadOutlined, CheckCircleFilled } from '@ant-design/icons';
import Button from 'components/button/button';
import RecordList from './RecordList';
import RoundedButton from 'components/roundedButton/roundedButton';
import { icon_upload, icon_discard_inform } from 'components/image';
import { TextArea } from 'components/form';
import { Row, Col, Modal } from 'antd';
import SharableContainer from './SharableContainer';
import { updateRecord, getOneRecord, RecordData, TrainingNote, shareAllFile } from 'api/record';
import { getParameterList, ParameterProfile } from 'api/parameter';
import moment from 'moment';
import { GlobalContext } from '../../../../index';
import RecordVideosUploadDrawer from './RecordVideosUploadDrawer';
import VideoView from './VideoView';
import VideoList from './VideoList';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const Wrapper = styled.div`
    width: 100%;
    background-color: #FFFFFF;
    padding: 15px 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        background-color: #FFFFFF;
        margin-top: -15px;
        margin-left: -5px;
        width: 101%;
        padding: 15px 10px 15px 0;
        z-index: 1000;

        .Title {
            color: #2E2E2E;
            font-size: 20px;
        }
    }

    .subheader {
        margin: 20px 0 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Title {
            font-size: 18px;
            font-weight: bold;
            color: #2E2E2E;
        }
    }
  
`;

const SubheaderButton = styled(Button)`
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
    color: #0083C1;
    background-color: #FFFFFF;
    font-size: 12px;
    padding: 5px;
    display: flex;
    align-items: center;
    transform: scale(0.9);
`;

const SubheaderLabel = styled.label` 
    margin-left:-5px;
    border-radius: 0.5rem;
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: 'default';
    color:#000000;
    font-size: 13px;
    transform: scale(0.8);
    white-space: nowrap;
    padding:35px 9px;
`;

interface DetailProps extends PageProps {
    className?: string;
    trainingId: number;
}

const RecordDetail: React.FC<DetailProps> = (props: DetailProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const { className, trainingId, ...pageProps } = props;
    const [permission] = usePermission('Record');
    const isBusinessUser = localStorage.getItem('role') === '5';

    const [trainingData, setTrainingData] = useState<RecordData>();
    const [profileList, setProfileList] = useState<ParameterProfile[]>([]);
    const [trainingNotes, setTrainingNotes] = useState<TrainingNote[]>([]);
    const [originalTrainingNotes, setOriginalTrainingNotes] = useState<TrainingNote[]>([]);
    const [noteId, setNoteId] = useState<number[]>([]);
    const [homeProgramInput, setHomeProgramInput] = useState('');
    const [remark, setRemark] = useState('');
    const [showUploadDrawer, setShowUploadDrawer] = useState(false);
    const [showVideoView, setShowVideoView] = useState(false);
    const [videoViewSetting, setVideoViewSetting] = useState<{
        videoUrl: string;
        videoUrl2?: string;
    }>();
    
    useEffect(()=>{
        fetchTrainingData();
        fetchParameterList();
    }, []);

    const fetchTrainingData = () => {
        getOneRecord(
            props.trainingId
        ).then(res=>{
            setTrainingData(res.data.data);
            setTrainingNotes(res.data.data.notes);
            setOriginalTrainingNotes(res.data.data.notes);
            setNoteId(res.data.data.notes.map((d,i)=>i+1));
            setHomeProgramInput(res.data.data.homeProgram);
            setRemark(res.data.data.remark);
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }

    const fetchParameterList = () => {
        getParameterList(
            {
                caseId: props.caseId,
                invalid: false
            }
        ).then(res => {
            setProfileList(res.data.data);
        }).catch((error: any) => {
            console.error(error);
            throw (error);
        });
    }

    const updateVideoData = () => {
        getOneRecord(
            props.trainingId
        ).then(res=>{
            setTrainingData(res.data.data);
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }

    const handleTrainingComplete = () => {
        updateRecord({
            personalTrainingRecordId: props.trainingId,
            notes: trainingNotes,
            device: 'web',
            homeProgram: homeProgramInput,
            remark: remark,
            status: true
        }).then(res=>{
            Modal.success({
                maskClosable: true,
                title: 'Success',
                onCancel: () => {
                    fetchTrainingData();
                }
            });
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }

    const handleSaveOnClick = (leavePage?: boolean) => {
        updateRecord({
            personalTrainingRecordId: props.trainingId,
            notes: trainingNotes,
            device: 'web',
            homeProgram: homeProgramInput,
            remark: remark
        }).then(res=>{
            Modal.success({
                maskClosable: true,
                title: 'Success',
                onCancel: () => {
                    if(leavePage){
                        props.setPageContent(<RecordList {...pageProps} />);
                    }
                    else {
                        fetchTrainingData();
                    }
                }
            });
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }
    
    const addTrainingNote = () => {
        const newNote = {
            trainingTypeCode: 'TTE02',
            trainingTypeName: nameMapping['TTE02'],
            profile: profileList[0]?.id ?? -1,
            reps: '0',
            sets: '0',
            mins: '00:00',
            comments: '' ,
            assistDevice: []
        };
        const newTrainingNotes = trainingNotes.slice();
        newTrainingNotes.push(newNote);
        
        setTrainingNotes(newTrainingNotes);
        setNoteId(noteId.concat([Math.max(...(noteId.length > 0 ? noteId : [0])) + 1]));
    }

    const deleteTrainingNote = (index: number) => {
        const newTrainingNotes = trainingNotes.slice();
        newTrainingNotes.splice(index, 1);
        setTrainingNotes(newTrainingNotes);
        const newNoteId = noteId.slice();
        newNoteId.splice(index, 1);
        setNoteId(newNoteId);
    };

    const editTrainingNote = (index: number, newNote: TrainingNote) => {
        const newTrainingNotes = trainingNotes.slice();
        newTrainingNotes.splice(index, 1, newNote);
        setTrainingNotes(newTrainingNotes);
    }

    const RecordVideosUploadClick = () => {
        setShowUploadDrawer(true);
    }

    const handleBackOnClick = () => {
        if(JSON.stringify(originalTrainingNotes) === JSON.stringify(trainingNotes)){
            props.setPageContent(<RecordList {...pageProps} />);
        }
        else{
            Modal.info({
                icon: <img src={icon_discard_inform} className='mb-20'/>,
                title: <div className='text--bold text-30 mb-30' style={{color: '#47A6D8'}}>{formatMessage({id:'DISCARD_EDIT'}) + ' ?'}</div>,
                maskClosable: false,
                centered: true,
                style: {
                    padding: '50px 30px'
                },
                content: (
                    <div className='d-flex is--column v--center'>
                        <Button 
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                width: '85%',
                                height: '40px',
                                border: 'none',
                                borderRadius: '2rem',
                                marginBottom: '15px',
                                color: '#FFFFFF',
                                backgroundColor: '#47A6D8'
                            }}
                            onClick={() => {Modal.destroyAll();handleSaveOnClick(true);}}
                        >{formatMessage({id:'SAVE_EDIT'})}</Button>
                        <Button
                            type='link'
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#2E2E2E'
                            }}
                            onClick={() => {Modal.destroyAll();props.setPageContent(<RecordList {...pageProps} />);}}
                        >{formatMessage({id:'DISCARD'})}</Button>
                    </div>
                )
            })
        }
    }

    const trainingFileSource = (
        isBusinessUser ? (
            trainingData?.trainingFiles?.filter(d=>d.share) ?? []
        ) : (
            trainingData?.trainingFiles ?? []
        )
    ).filter(file=>file.frontalViewVideo || file.sagittalViewVideo);
    const trainingFiles = trainingFileSource.slice(0, 4).map(file => (
        <Col span={6} style={{margin:'15px 0'}}>
            <SharableContainer 
                fileId={file.id}
                bgc='#2E2E2E'
                frontalView={{ videoUrl: file.frontalViewVideo, thumbnailUrl: file.frontalViewImage }}
                sagittalView={{ videoUrl: file.sagittalViewVideo, thumbnailUrl: file.sagittalViewImage }}
                tags={[file.trainingType, file.classification].filter(d=>d)}
                share={file.share}
                noShareIcon={!checkIfAvailable(permission, 'InstantRecord', 'Share')}
                onClick={()=>{
                    setVideoViewSetting({
                        videoUrl: file.frontalViewVideo,
                        videoUrl2: file.sagittalViewVideo
                    });
                    setShowVideoView(true);
                }}
                updateVideoData={updateVideoData}
            />
        </Col>
    ));

    const uploadedVideoSource = (
        isBusinessUser ? (
            trainingData?.uploadedVideos?.filter(d=>d.share) ?? []
        ) : (
            trainingData?.uploadedVideos ?? []
        )
    ).filter(video=>video.videoUrl || video.pending);
    const uploadedVideos = uploadedVideoSource.slice(0,4).map(video => (
        <Col span={6} style={{margin:'15px 0'}}>
            <SharableContainer
                fileId={video.id}
                bgc='#2E2E2E'
                singleView={{ videoUrl: video.videoUrl, thumbnailUrl: video.thumbnailUrl }}
                tags={[video.trainingTypeName, video.classification].filter(d=>d)}
                share={video.share}
                noShareIcon={!checkIfAvailable(permission, 'UploadVideo', 'Share')}
                onClick={()=>{
                    setVideoViewSetting({
                        videoUrl: video.videoUrl
                    });
                    setShowVideoView(true);
                }}
                updateVideoData={updateVideoData}
                pending={video.pending}
            />
        </Col>
    ));
    
    return (
        <Wrapper>
            {showUploadDrawer && <RecordVideosUploadDrawer onClose={()=>{setShowUploadDrawer(false);updateVideoData();}} caseId={props.caseId} trainingId={props.trainingId}/>}
            {showVideoView &&  <VideoView videoUrl={videoViewSetting?.videoUrl} videoUrl2={videoViewSetting?.videoUrl2} onClose={()=>setShowVideoView(false)}/>}

            <div className="header d-flex v--center mb-15 width--full">
                <div className="Title d-flex v--center">
                    <LeftOutlined onClick={handleBackOnClick} />
                    <span className='ml-15'>
                        <b>
                            {trainingData?.treatmentPlanMasterClassTypeCode === "CLA01" ? `${nameMapping[trainingData.treatmentTypeCode]} ${nameMapping[trainingData.treatmentPlanMasterClassTypeCode]}` : trainingData?.treatmentPlanMasterName ?? '--'}
                        </b>
                    </span>
                    <span className='ml-15 pr-15'>
                        {trainingData?.treatmentPlanMasterClassTypeCode === "CLA01" ? "":('Class ' + trainingData?.classNum?.toString().padStart(2, '0'))}
                    </span>
                    <div className='pl-15 d-flex is--column text-12' style={{ borderLeft: '2px solid #707070' }}>
                        <span>{`${trainingData?.treatmentHour} - ${trainingData?.treatmentHour ? moment(trainingData?.treatmentHour, 'HH:mm').add(50, 'minutes').format('HH:mm') : ''}`}</span>
                        <span>{`${formatMessage({ id: 'START_DATE' })}: ${trainingData?.treatmentDate} - ${(trainingData?.treatmentDate) ? moment(trainingData?.treatmentDate).format('ddd').toUpperCase() : ''}`}</span>
                    </div>
                </div>
                <div className="d-flex">
                    {trainingData?.treatmentDetailStatusCode === 'TDS05' && 
                        <div className='mr-10 d-flex v--center text--bold text-18' style={{color: '#EDB754'}}>
                            ({formatMessage({id:'SUPENDED'})})
                        </div>
                    }
                    <Button
                        className="rect"
                        onClick={() => handleSaveOnClick()}
                        style={{ color: '#2E2E2E', border: 'none', fontWeight: 'bold' }}
                        type='link'
                    >{formatMessage({ id: 'SAVE_UPPERCASE' })}</Button>
                    {trainingData?.treatmentDetailStatusCode === 'TDS05' ? undefined :
                        trainingData?.status ? (
                            <Button
                                icon={<CheckCircleFilled className=' mr-10' style={{ color: '#5DD197' }} />}
                                className="rect ml-10"
                                style={{ cursor: 'default', backgroundColor: '#FFFFFF', color: '#5DD197', borderColor: '#5DD197', borderWidth: 'revert', fontWeight: 'bold' }}
                            >{formatMessage({ id: 'COMPLETE_UPPERCASE' })}</Button>
                        ) : (
                            <Button
                                className="rect ml-10"
                                onClick={handleTrainingComplete}
                                style={{ backgroundColor: '#5DD197', color: '#FFFFFF', border: 'none', fontWeight: 'bold' }}
                            >{formatMessage({ id: 'COMPLETE_UPPERCASE' })}</Button>
                        )
                    }
                </div>
            </div>

            {/*notes*/}
            {checkIfAvailable(permission, 'Note') && 
            <div>
                <div className="subheader">
                    <div className='d-flex v--center'>
                        <div className="Title">{formatMessage({ id: 'NOTE' })}</div>
                        {checkIfAvailable(permission, 'Note', 'Add') && 
                            (trainingNotes.length > 0 ? (
                                <SubheaderButton
                                    className='rect ml-10'
                                    size='small'
                                    icon={<PlusOutlined style={{ fontSize: '18px' }} />}
                                    onClick={addTrainingNote}
                                >{formatMessage({ id: 'ADD_TRAINING' })}</SubheaderButton>
                            ) : undefined)
                        }
                    </div>
                </div>
                <div className="d-flex is--column">
                    {trainingNotes.length > 0 ? (
                        trainingNotes.map((note, i) => (
                            <TrainingItemCard 
                                key={noteId[i]} 
                                noteData={note}
                                caseId={props.caseId} 
                                index={i} 
                                onDelete={deleteTrainingNote} 
                                onEdit={editTrainingNote} 
                                profileList={profileList}
                            />
                        ))
                    ) : (
                        checkIfAvailable(permission, 'Note', 'Add') && <RoundedButton text={formatMessage({id:'ADD_TRAINING'})} onClick={addTrainingNote}/>
                    )}
                </div>
            </div>}

            {/*影片*/}
            {checkIfAvailable(permission, 'InstantRecord') && 
            <div>
                <div className="subheader">
                    <div className='d-flex v--center'>
                        <div className="Title">{formatMessage({ id: 'VIDEOS' })}</div>
                        {checkIfAvailable(permission, 'InstantRecord', 'Share') && 
                        ((trainingFileSource.length ?? 0) > 0 ? (
                            <Button
                                className='ml-10'
                                type='link'
                                onClick={() => shareAllFile({
                                    personalTrainingRecordId: props.trainingId,
                                    type: 'instantRecord'
                                }).then(updateVideoData).catch(err=>console.error(err))}
                                style={{
                                    color: '#545454',
                                    fontSize: '12px',
                                    fontWeight: 'bold'
                                }}
                            >{formatMessage({ id: 'SHARE_ALL' })}</Button>
                        ) : undefined)}
                    </div>
                    {(trainingFileSource.length ?? 0) > 0 ? (
                        <Button
                            className='ml-10'
                            type='link'
                            onClick={() => props.setPageContent(
                                <VideoList 
                                    videos={trainingFileSource.map(file=>({
                                        id: file.id,
                                        frontalViewVideo: file.frontalViewVideo,
                                        frontalViewImage: file.frontalViewImage,
                                        sagittalViewVideo: file.sagittalViewVideo,
                                        sagittalViewImage: file.sagittalViewImage,
                                        share: file.share,
                                        tags: [file.trainingType, file.classification].filter(d=>d)
                                    })) ?? []} 
                                    trainingId={props.trainingId}
                                    {...pageProps}
                                />
                            )}
                            style={{
                                color: '#545454',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            }}
                        >{formatMessage({ id: 'SEE_ALL' })}</Button>
                    ) : undefined}
                </div>
                <div className="d-flex is--column">
                    {(trainingFileSource.length ?? 0) > 0 ? (
                        <Row gutter={24} className='width--full'>
                            {trainingFiles}
                        </Row>
                    ) : (
                        <div className="d-flex mt-10">
                            <SubheaderLabel >{formatMessage({ id: 'TABLET_ONLY' }) }</SubheaderLabel>
                        </div>
                    )}
                </div>
            </div>}

            {/*上傳的影片*/}
            {checkIfAvailable(permission, 'UploadVideo') && 
            <div>
                <div className="subheader">
                    <div className='d-flex v--center'>
                        <div className="Title">{formatMessage({ id: 'VIDEOS_UPLOAD' })}</div>
                        {(uploadedVideoSource.length ?? 0) > 0 ? (
                            <div className='d-flex v--center'>
                                {checkIfAvailable(permission, 'UploadVideo', 'Add') && 
                                <SubheaderButton
                                    className='rect ml-10'
                                    size='small'
                                    icon={<UploadOutlined style={{ fontSize: '18px' }} />}
                                    onClick={RecordVideosUploadClick}
                                >{formatMessage({ id: 'UPLOAD' })}</SubheaderButton>}
                                {checkIfAvailable(permission, 'UploadVideo', 'Share') && 
                                <Button
                                    className='ml-10'
                                    type='link'
                                    onClick={() => shareAllFile({
                                        personalTrainingRecordId: props.trainingId,
                                        type: 'uploadVideo'
                                    }).then(updateVideoData).catch(err=>console.error(err))}
                                    style={{
                                        color: '#545454',
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                    }}
                                >{formatMessage({ id: 'SHARE_ALL' })}</Button>}
                            </div>
                        ) : undefined}
                    </div>
                    {(uploadedVideoSource.length ?? 0) > 0 ? (
                        <Button
                            className='ml-10'
                            type='link'
                            onClick={() => props.setPageContent(
                                <VideoList 
                                    upload
                                    videos={uploadedVideoSource.map(video=>({
                                        id: video.id,
                                        videoUrl: video.videoUrl,
                                        thumbnailUrl: video.thumbnailUrl,
                                        share: video.share,
                                        tags: [video.trainingTypeName, video.classification].filter(d=>d),
                                        pending: video.pending
                                    })) ?? []} 
                                    trainingId={props.trainingId}
                                    {...pageProps}
                                />
                            )}
                            style={{
                                color: '#545454',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            }}
                        >{formatMessage({ id: 'SEE_ALL' })}</Button>
                    ) : undefined}
                </div>
                <div className="d-flex is--column">
                    <div className="d-flex mt-10">
                        {(uploadedVideoSource.length ?? 0) > 0 ? (
                            <Row gutter={24} className='width--full'>
                                {uploadedVideos}
                            </Row>
                        ) : (
                            checkIfAvailable(permission, 'UploadVideo', 'Add') && <RoundedButton icon={<img src={icon_upload} />} text={formatMessage({ id: 'UPLOAD' })}  onClick={RecordVideosUploadClick} />
                        )}
                    </div>
                    <div className='text-12 mt-10'>{formatMessage({id:'UPLOAD_VIDEO_HINT'})}</div>
                </div>
            </div>}

            {checkIfAvailable(permission, 'HomeProgram') && 
            <div>
                <div className="subheader">
                    <div className="Title">{formatMessage({ id: 'HOME_PROGRAM' })}</div>
                </div>
                <TextArea disabled={!checkIfAvailable(permission, 'HomeProgram', 'Edit')} rows={4} maxLength={1000} value={homeProgramInput} onChange={(e: any)=>setHomeProgramInput(e.target.value)}/>
            </div>}
            
            {checkIfAvailable(permission, 'Remark') && 
            <div>
                <div className="subheader">
                    <div className="Title">{formatMessage({ id: 'REMARK' })}</div>
                </div>
                <TextArea disabled={!checkIfAvailable(permission, 'Remark', 'Edit')} rows={4} maxLength={1000} value={remark} onChange={(e: any)=>setRemark(e.target.value)}/>
            </div>}
            
        </Wrapper >
    )
}

export default RecordDetail;
