import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import styled from 'styled-components';

const StyledButton: any = styled(({ forwardRef, ...props }) => (
    <Button {...props} />
))`
    &.ant-btn {
        border-radius: 32px;

        &.rect {
            border-radius: 0px;
        }

        &.is--outline {
            border: 1px solid var(--color-primary-dark);
            background: transparent;
            color: var(--color-primary-dark);
        }

        &.is--plain {
            border: 1px solid var(--color-gray);
            background: transparent;
            color: var(--color-gray-dark);
        }

        &.is--gray-dark {
            border: none;
            background: var(--color-gray-dark);
            color: white;
        }

        &.is--primary {
            border: 1px solid white;
            background: var(--color-primary);
            color: #fff;
            &.light{
                background: var(--color-primary-light);
            }
            &:disabled {
                border: 1px solid #bbbbbb;
                background: #cdcdcd;
                color: #fff;
            }
        }

        &.is--secondary {
            border: 1px solid ${p => p.theme.secondary};
            background: ${p => p.theme.secondary};
            color: #fff;

            &:hover,
            &:focus,
            &:active {
                border: 1px solid ${p => p.theme.secondary};
                background: ${p => p.theme.secondary};
                color: #fff;
            }
            &:disabled {
                border: 1px solid #bbbbbb;
                background: #cdcdcd;
                color: #fff;
            }
        }

        &.is--danger {
            border: 1px solid white;
            background: var(--color-error);
            color: #fff;
            &:disabled {
                border: 1px solid #bbbbbb;
                background: #cdcdcd;
                color: #fff;
            }
        }

        &.is--warning {
            border: 1px solid var(--color-warning);
            background: var(--color-warning);
            color: #fff;
            &:disabled {
                border: 1px solid #bbbbbb;
                background: #cdcdcd;
                color: #fff;
            }
        }

        &:disabled {
            background: #cdcdcd;
            color: #fff;
            border: 1px solid #bbbbbb;

            &:focus,
            &:active,
            &:hover {
                background: #cdcdcd;
                color: #fff;
                border: 1px solid #bbbbbb;
                cursor: not-allowed;
            }
        }
    }
`;

const ButtonComponent = React.forwardRef<typeof Button, ButtonProps>(   
    (props: ButtonProps, ref): any => {
        return <StyledButton forwardRef={ref} {...props} />;
    },
);

/**
 * Fix Tooltip'popupover will not be closed which the btn wrapper by Tooltip
 * And then the disabled style was defined in styled component ( &:disabled )
 */
(ButtonComponent as any).__ANT_BUTTON = true;

export default ButtonComponent;
