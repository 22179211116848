import React, { useState, useEffect } from 'react';
import RecordList from './RecordList';
import RecordDetail from './RecordDetail';
import { getOneRecord } from 'api/record';

interface Props {
    caseId: number;
    createdRecord: number;
}

export interface PageProps extends Props {
    setPageContent: React.Dispatch<React.SetStateAction<JSX.Element>>;
}

const RecordTab: React.FC<Props> = (props: Props) => {
    const [pageContent, setPageContent] = useState<JSX.Element>(<div></div>);

    useEffect(() => {
        setPageContent(<RecordList {...props}  setPageContent={setPageContent} />);
    }, []);

    useEffect(() => {
        if (props.createdRecord >= 0) {
            getOneRecord(
                props.createdRecord
            ).then(res=>{
                setPageContent(<RecordDetail {...props} {...res.data.data} trainingId={props.createdRecord} setPageContent={setPageContent} />)
            }).catch(error=>{
                console.error(error);
                throw(error);
            });
        }
    }, [props.createdRecord,])

    return withProps(pageContent, { ...props, setPageContent });
}

const withProps = (Component: JSX.Element, props: PageProps) => {
    return React.cloneElement(Component, { ...props, key: Date.now().toString() });
}

export default RecordTab;
