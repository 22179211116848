import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Modal } from 'antd';
import Button from 'components/button/button';
import { icon_note } from 'components/image';

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 0.5rem;

        .ant-modal-body {
            padding: 30px;
            color: #2E2E2E;
        }

        .ant-modal-footer {
            border: none;
            font-size: 24px;
            padding: 20px;
        }
    }
`;

export interface PromptRecord {
    [uid: string]: {
        caseId: number;
        lastPrompt: string; // ISO Date string
    }[]
}

interface ModalProps {
    onClose: () => void;
    caseId: number;
    recordRemark: string;
}

const PromptNoteModal: React.FC<ModalProps> = (props: ModalProps) => {

    const { formatMessage } = useIntl();

    const handleNoPrompt = () => {
        let promptRecord = JSON.parse(localStorage.getItem('promptRecord') || '{}') as PromptRecord;
        let curUser = promptRecord[localStorage.getItem('uid') || ''];
        let record = curUser?.find(d=>d.caseId===props.caseId);
        if(record){
            record.lastPrompt = new Date().toISOString();
        }
        else if(curUser){
            curUser.push({
                caseId: props.caseId,
                lastPrompt: new Date().toISOString()
            });
        }
        else{
            promptRecord[localStorage.getItem('uid') || ''] = [{
                caseId: props.caseId,
                lastPrompt: new Date().toISOString()
            }];
        }
        
        localStorage.setItem('promptRecord', JSON.stringify(promptRecord));
        props.onClose();
    }

    return (
        <StyledModal
            visible
            maskClosable
            centered
            width='30vw'
            closable={false}
            footer={
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                    <Button
                        type='link'
                        style={{
                            color: '#2E2E2E',
                            marginRight: '10px',
                            fontWeight: 'bold',
                            fontSize: '20px'
                        }}
                        onClick={handleNoPrompt}
                    >{formatMessage({id:'NO_PROMPT'})}</Button>
                    <Button
                        type='link'
                        style={{
                            color: '#47A6D8',
                            fontWeight: 'bold',
                            fontSize: '20px'
                        }}
                        onClick={props.onClose}
                    >{formatMessage({id:'GOT_IT'})}</Button>
                </div>
            }
        >
            <div className='d-flex is--column'>
                <img src={icon_note} width='50px' height='50px' />
                <div className='text--bold text-24 mt-20 mb-20'>{formatMessage({id:'REMINDER'})}</div>
                <div className='text--bold text-16 mb-20'>
                    {props.recordRemark || '--'}
                </div>
            </div>
        </StyledModal>
    )
}

export default PromptNoteModal;
