import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/button/button';
import { Modal } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { icon_make_invalid } from 'components/image';
import SummaryList from './SummaryList';
import { SummaryProps } from './SummaryTab';
import { getOneReport, Summary } from 'api/summary';
import moment from 'moment';
import { GlobalContext } from '../../../../index';
import TrainingItemBlock from './TrainingItem/TrainingItemBlock';
import KRABlock from './KRA/KRABlock';
import InvalidSummaryDrawer from './InvalidSummaryDrawer';
import ModifiedHistoryDrawer from './ModifiedHistoryDrawer';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const PackageBlock = styled.div`
    color: #FFFFFF;
    background-color: #2E63A3;
    margin: 5px;
    height: 40px;
    border: none;
    border-radius: 2rem;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    width: 100%;
    background-color: white;
    padding: 15px;

    .Title {
        font-size: 1.3rem;
        color: black;
        font-weight: bolder;
    }
`;

interface SummaryViewProps extends SummaryProps {
    reportId: number;
    treatmentType: string;
}

const SummaryView: React.FC<SummaryViewProps> = (props: SummaryViewProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping, getOptions, getCode } = useContext(GlobalContext).dropdownOptionCtx;
    const [permission] = usePermission('Summary');

    const [detailData, setDetailData] = useState<Summary>();
    const [showInvalidDrawer, setShowInvalidDrawer] = useState(false);
    const [showHistoryDrawer, setShowHistoryDrawer] = useState(false);

    useEffect(()=>{
        fetchReportData();
    }, []);

    const fetchReportData = () => {
        getOneReport(
            props.reportId
        ).then(res=>{
            setDetailData(res.data.data)
        }).catch(err=>{
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        });
    }

    const isBusinessUser = localStorage.getItem('role') === '5';

    const packages = detailData?.selectedPackages.map(d=>(
        <PackageBlock>{d.name}</PackageBlock>
    ));

    const trainingGoals = detailData?.selectedPackages?.map(p=>{
        let goals = p.trainingGoalsToImproveCode.filter(d=>d!=='PTG11')?.map(d=>nameMapping[d]) ?? [];
        if(p.trainingGoalsToImproveCode.some(d=>d==='PTG11')){
            goals.push(`${nameMapping['PTG11']}: ${p.trainingGoalsToImproveOther}`);
        }

        return (
            <div className='d-flex is--column mb-20'>
                <div className='text-16 text--bold mb-10'>{p.name}</div>
                <div className='text-16'>{goals.join('/')}</div>
            </div>
        )
    });

    const trainingItems = getOptions('TrainingType').filter(d=>d.code!=='TTE07').map(d=>{
        let trainingItem = detailData?.trainingItem.find(item=>item.trainingTypeCode===d.code);
        
        return (
            <TrainingItemBlock
                trainingType={d.code} 
                packages={detailData?.selectedPackages ?? []} 
                video={[
                    trainingItem?.videoWithKeeogo ? { // video comparison with keeogo
                        videos: [
                            trainingItem?.videoWithKeeogo[0] ? {
                                fileId: trainingItem.videoWithKeeogo[0].id,
                                id: trainingItem.videoWithKeeogo[0].id.toString(),
                                [trainingItem.videoWithKeeogo[0].upload ? 'uploadView' : trainingItem.videoWithKeeogo[0].videoDirection]: trainingItem.videoWithKeeogo[0].filePath,
                                [trainingItem.videoWithKeeogo[0].upload ? 'uploadThumbnail' : trainingItem.videoWithKeeogo[0].videoDirection.replace('View','Thumbnail')]: trainingItem.videoWithKeeogo[0].thumbnail,
                                timestamp: moment(trainingItem.videoWithKeeogo[0].createdAt),
                                packageName: trainingItem.videoWithKeeogo[0].packageName,
                                className: `${formatMessage({id:'CLASS'})} ${trainingItem.videoWithKeeogo[0].classNum?.padStart(2, '0')}`,
                                upload: trainingItem.videoWithKeeogo[0].upload
                            } : undefined,
                            trainingItem?.videoWithKeeogo[1] ? {
                                fileId: trainingItem.videoWithKeeogo[1].id,
                                id: trainingItem.videoWithKeeogo[1].id.toString(),
                                [trainingItem.videoWithKeeogo[1].upload ? 'uploadView' : trainingItem.videoWithKeeogo[1].videoDirection]: trainingItem.videoWithKeeogo[1].filePath,
                                [trainingItem.videoWithKeeogo[1].upload ? 'uploadThumbnail' : trainingItem.videoWithKeeogo[1].videoDirection.replace('View','Thumbnail')]: trainingItem.videoWithKeeogo[1].thumbnail,
                                timestamp: moment(trainingItem.videoWithKeeogo[1].createdAt),
                                packageName: trainingItem.videoWithKeeogo[1].packageName,
                                className: `${formatMessage({id:'CLASS'})} ${trainingItem.videoWithKeeogo[1].classNum?.padStart(2, '0')}`,
                                upload: trainingItem.videoWithKeeogo[1].upload
                            } : undefined
                        ],
                        memo: trainingItem?.videoWithKeeogo[0]?.memo ?? ''
                    } : undefined,
                    trainingItem?.videoWithoutKeeogo ? { // video comparison without keeogo
                        videos: [
                            trainingItem?.videoWithoutKeeogo[0] ? {
                                fileId: trainingItem.videoWithoutKeeogo[0].id,
                                id: trainingItem.videoWithoutKeeogo[0].id.toString(),
                                [trainingItem.videoWithoutKeeogo[0].upload ? 'uploadView' : trainingItem.videoWithoutKeeogo[0].videoDirection]: trainingItem.videoWithoutKeeogo[0].filePath,
                                [trainingItem.videoWithoutKeeogo[0].upload ? 'uploadThumbnail' : trainingItem.videoWithoutKeeogo[0].videoDirection.replace('View','Thumbnail')]: trainingItem.videoWithoutKeeogo[0].thumbnail,
                                timestamp: moment(trainingItem.videoWithoutKeeogo[0].createdAt),
                                packageName: '',
                                className: '',
                                upload: trainingItem.videoWithoutKeeogo[0].upload
                            } : undefined,
                            trainingItem?.videoWithoutKeeogo[1] ? {
                                fileId: trainingItem.videoWithoutKeeogo[1].id,
                                id: trainingItem.videoWithoutKeeogo[1].id.toString(),
                                [trainingItem.videoWithoutKeeogo[1].upload ? 'uploadView' : trainingItem.videoWithoutKeeogo[1].videoDirection]: trainingItem.videoWithoutKeeogo[1].filePath,
                                [trainingItem.videoWithoutKeeogo[1].upload ? 'uploadThumbnail' : trainingItem.videoWithoutKeeogo[1].videoDirection.replace('View','Thumbnail')]: trainingItem.videoWithoutKeeogo[1].thumbnail,
                                timestamp: moment(trainingItem.videoWithoutKeeogo[1].createdAt),
                                packageName: '',
                                className: '',
                                upload: trainingItem.videoWithoutKeeogo[1].upload
                            } : undefined
                        ],
                        memo: trainingItem?.videoWithoutKeeogo[0]?.memo ?? ''
                    } : undefined
                ]}
                setVideo={()=>{}}
            />
        )
    });

    const kraReports = detailData?.kraReport.filter((d, i, arr)=>arr.findIndex(t=>t.type===d.type)===i).map(d=>(
        <KRABlock removeKraConfig={()=>{}} kraConfig={{
            trainingType: getCode('KeeogoExamination', d.type),
            configId: detailData.kraReport.filter(k=>k.type===d.type).map(k=>k.id)
        }}/>
    ))

    return (
        <Wrapper>
            {showInvalidDrawer && <InvalidSummaryDrawer onClose={()=>setShowInvalidDrawer(false)} refetch={fetchReportData} reportId={detailData?.id ?? 0}/>}
            {showHistoryDrawer && <ModifiedHistoryDrawer onClose={()=>setShowHistoryDrawer(false)} reportId={props.reportId} createdAt={detailData?.createdAt ?? 0}/>}

            <div className='d-flex v--center is--space-between mb-30 width--full padding-15' style={{position: 'sticky', top: 0, zIndex: 1000, backgroundColor: '#FFFFFF', marginTop: '-15px'}}>
                <div className='d-flex v--center text-20 text--bold'>
                    <LeftOutlined className='mr-15 text-20' onClick={()=>props.setPageContent(<SummaryList {...props}/>)}/>
                    {detailData?.reportName}
                </div>
                <div className='d-flex v--center'>
                    <div className='cursor--pointer' onClick={()=>setShowHistoryDrawer(true)}>{detailData ? moment(detailData?.createdAt).format('DD/MM/YYYY HH:mm') : '--'}</div>
                    {checkIfAvailable(permission, 'MakeInvalid') && !detailData?.invalid && <div className='ml-15'>
                        <Button
                            icon={<img src={icon_make_invalid}/>}
                            onClick={()=>setShowInvalidDrawer(true)}
                            className='rect'
                            style={{border: 'none', boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.2)'}}
                        />
                    </div>}
                </div>
            </div>

            <div className='padding-15'>
                {(!isBusinessUser || (isBusinessUser && detailData?.packageShare)) &&
                <div>
                    {detailData?.invalid ? (
                        <div>
                            <div className='d-flex is--space-between'>
                                <div className='mb-15 text-24 text--bold'>{formatMessage({id:'INVALID_RECORD'})}</div>
                                <div className='text-12'><span className='text--bold'>{formatMessage({id:'INVALID_TIME'})}:</span> {moment(detailData.updatedAt).format('DD/MM/YYYY HH:mm')}</div>
                            </div>
                            <div className='text-16'>
                                {detailData?.invalidRecord}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='mb-15 text-24 text--bold'>{formatMessage({id:'SELECTED_PACKAGES'})}</div>
                            <div className='d-flex'>
                                {packages}
                            </div>
                        </div>
                    )}

                    <div className='mb-15 mt-20 text-24 text--bold'>{formatMessage({id:'TRAINING_GOAL'})}</div>
                    <div>
                        {trainingGoals}
                    </div>
                </div>}

                {checkIfAvailable(permission, 'VideoCompare') && 
                (!isBusinessUser || (isBusinessUser && detailData?.trainingShare)) && 
                <div>
                    <div className='mb-15 mt-20 text-24 text--bold'>{formatMessage({id:'TRAINING_ITEM'})}</div>
                    <div>
                        {trainingItems}
                    </div>
                </div>}

                {checkIfAvailable(permission, 'KeeogoReport') && 
                (!isBusinessUser || (isBusinessUser && detailData?.kraShare)) && 
                <div>
                    <div className='mb-15 mt-20 text-24 text--bold'>{formatMessage({id:'KRA_REPORT'})}</div>
                    <div style={{width:'80%'}}>
                        {kraReports}
                    </div>
                </div>}

                {checkIfAvailable(permission, 'Comment') &&
                (!isBusinessUser || (isBusinessUser && detailData?.commentShare)) && 
                <div>
                    <div className='mb-15 mt-20 text-24 text--bold'>{formatMessage({id:'COMMENTS'})}</div>
                    <div className='text-16'>
                        {detailData?.comment}
                    </div>
                </div>}

            </div>
        </Wrapper>
    )
}

export default SummaryView;
