import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { icon_warning, icon_info } from 'components/image';
import styled from 'styled-components';
import Button from 'components/button/button';

const StyledModal = styled(Modal)`
    .ant-modal-content{
        border-radius:1rem;
    }
`

export const LoginCheck: React.FC<any> = (props: any) => {
    const { login } = props;

    return (
        <StyledModal
            visible={true}
            closable={false}
            footer={null}
            width={400}
        >
            <div style={{
                textAlign: 'center',
                display:'flex',
                flexDirection:'column',
                justifyContent: 'space-around',
                minHeight: '400px'
            }}>

                <img
                    style={{
                        width: '60px',
                        display: 'block',
                        margin: '50px auto 30px auto'
                    }}
                    src={icon_info}
                />

                <div>
                    <h1 className='mb-10 text--lg' style={{color: '#47A6D8', fontWeight: 'bold', fontSize: '30px'}} >
                        <FormattedMessage id='HAS_LOGINED' />
                    </h1>

                    <p style={{padding: '10px 10%'}}>
                        <FormattedMessage id='HAS_LOGINED_INFO' />
                    </p>
                </div>

                <div>
                    <Button
                        className='is--plain mt-10 mb-20'
                        onClick={() => login(true)}
                        style={{width:'75%', height:'40px', backgroundColor: '#47A6D8', color: 'white', fontWeight: 'bold', borderRadius: '1.5rem'}}
                    >
                        <FormattedMessage id='CONTINUE' />
                    </Button>
                    <Button
                        className='is--plain mt-10 mb-20'
                        type='link'
                        onClick={() => login(false)}
                        style={{width:'75%', height:'40px', backgroundColor: 'white', color: '#727272', fontWeight: 'bold', border: 'none'}}
                    >
                        <FormattedMessage id='CANCEL' />
                    </Button>
                </div>

            </div>
         </StyledModal>
    );
};

export const BlockedModal: React.FC<any> = (props: any) => {
    const { close } = props;

    return (
        <StyledModal
            visible={true}
            closable={false}
            footer={null}
            width={400}
        >
            <div style={{
                textAlign: 'center',
                display:'flex',
                flexDirection:'column',
                justifyContent: 'space-around',
                minHeight: '400px'
            }}>

                <img
                    style={{
                        width: '60px',
                        display: 'block',
                        margin: '50px auto 30px auto'
                    }}
                    src={icon_warning}
                />

                <div>
                    <h1 className='mb-10 text--lg' style={{color: '#EDB754', fontWeight: 'bold', fontSize: '30px'}} >
                        <FormattedMessage id='LOGIN_LOCK' />
                    </h1>

                    <p style={{padding: '10px 10%'}}>
                        <FormattedMessage id='LOGIN_LOCK_INFO' />
                    </p>
                </div>

                <div>
                    <Button
                        className='is--plain mt-10 mb-20'
                        onClick={close}
                        style={{width:'75%', height:'40px', backgroundColor: 'white', color: '727272', fontWeight: 'bold', border: 'none'}}
                    >
                        <FormattedMessage id='DISMISS' />
                    </Button>
                </div>

            </div>
        </StyledModal>
    );
};
