import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { other_single_leg } from 'components/image';
import { ViewWrapper } from './index';
import { GlobalContext } from '../../../../../index';

interface Props {
    data: {
        assistDevice: string;
        assistDeviceOther: string;
        affectedLt: string[];
        affectedLtOther: string;
        affectedRt: string[];
        affectedRtOther: string;
    };
}

const SingleLegStanceView: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    
    const assistOther = props.data.assistDevice === nameMapping['ASS04'] ? (
        props.data.assistDeviceOther ? props.data.assistDeviceOther.split('\n').map(d=><div>{d}<br/></div>) : '--'
    ) : undefined;

    const ltOtherText = props.data.affectedLt.some(d=>d === nameMapping['SLA05']) ? (
        <div>
            {nameMapping['SLA05']} - {props.data.affectedLtOther.split('\n').map(d=><span>{d}<br/></span>)}
        </div>
    ) : undefined;

    const rtOtherText = props.data.affectedRt.some(d=>d === nameMapping['SLA05']) ? (
        <div>
            {nameMapping['SLA05']} - {props.data.affectedRtOther.split('\n').map(d=><span>{d}<br/></span>)}
        </div>
    ) : undefined;

    return (
        <ViewWrapper>
            <div className="header">
                {formatMessage({id:'ITEM_OTHER_SINGLE_LEG_STANCE'})}
            </div>
            <div className="content">
                <div className="left">
                    <div className="icon">
                        <img src={other_single_leg} width='100%' />
                    </div>
                </div>
                <div className="right">
                    <div className='label'>
                        <div className="name">{formatMessage({id:'ASSIST_DEVICES'})}</div>
                        <div className="text large">{props.data.assistDevice || '--'}</div>
                        <div className='text'>{assistOther}</div>
                    </div>
                    <div className='label mt-15'>
                        <div className="name">{'● '+formatMessage({id:'AFFECTED_SIDE'})}</div>
                        <div className="text blue">{formatMessage({id:'LT'})}</div>
                        <div className="text">{props.data.affectedLt.filter(d=>d!==nameMapping['SLA05']).join(', ') || '--'}<br/>{ltOtherText}</div>
                        <div className="text blue">{formatMessage({id:'RT'})}</div>
                        <div className="text">{props.data.affectedRt.filter(d=>d!==nameMapping['SLA05']).join(', ') || '--'}<br/>{rtOtherText}</div>
                    </div>
                </div>
            </div>
        </ViewWrapper>
    )
}

export default SingleLegStanceView;
