import { Checkbox, Button, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { apiCaller } from 'service/apiCaller';
import { checklistFindOne, putChecklist } from 'api/eavluation';
import { Form } from 'components/form';
import { ArrowLeftOutlined } from '@ant-design/icons';
import EvaluationList from './EvaluationList';
import { PageProps } from './EvaluationTab';


const SmallGrayTitle = styled.p`
    font-size: 14px ;
    color: #909090 ;
    margin-top: 30px;
}
`;

const StyledCheckbox = styled(Checkbox)`
    display: flex;
    line-height: 1!important;
    align-items: flex-end;

    .ant-checkbox {
        display: flex;
        align-items: center;

        .ant-checkbox-inner {
            border: 2px solid #707070!important;
        }
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: #707070!important;
            border: 2px solid #707070!important;
        }
    }

    &.star {
        &::before {
            content: '★';
            color: #000000;
            font-size: 16px;
            margin-right: 10px;
        }
    }
`;

const ItemWrapper = styled.div<{checkbox?: boolean}>`
    margin-bottom: 10px;

    &.star {
        margin-left: -27px;

        .starBinder::before {
            content: '★';
            color: #000000;
            font-size: 16px;
            margin-right: 10px;
        }
    }

    &.blueBack {
        .content {
            background-color: #E0F2FB;
        }
    }

    .content {
        display: flex;
        align-items: flex-start;
        padding: 5px;
        width: 100%;
        padding-left: ${props => props.checkbox ? '31px' : ''};
    }

    .ant-form-item {
        margin-bottom: 0;
    }
`;

interface ItemProps {
    checkbox?: boolean;
    formName?: string;
    blueBack?: boolean;
    star?: boolean;
    children?: JSX.Element | JSX.Element[];
}

const Item: React.FC<ItemProps> = (props: ItemProps) => {

    const checkbox = props.checkbox && (
        <Form.Item noStyle name={props.formName} valuePropName='checked'>
            <StyledCheckbox className='mr-10 mt-5'/>
        </Form.Item>
    );

    return (
        <ItemWrapper checkbox={!props.checkbox} className={(props.star?'star':'')+(props.blueBack?' blueBack':'')}>
            <div style={{display: 'flex', alignItems: 'flex-start'}}>
                <div className='starBinder'></div>
                <div className='d-flex v--center width--full'>
                    <div className='content'>
                        {checkbox}
                        {props.children}
                    </div>
                </div>
            </div>
        </ItemWrapper>
    )
}

const ClinicianPage: React.FC<PageProps> = (props: PageProps) => {

    const [form] = Form.useForm();
    const { formatMessage } = useIntl();

    useEffect(() => {
        getCecklist();
    }, []);

    
    const getCecklist = () => {
        const success = (res: any) => {
            form.setFieldsValue(res.data.data || {});
        };
        const reject = (error: any) => {
            console.error(error);
            throw error;
        };
        apiCaller(checklistFindOne, { Id: props.caseId }, success, reject);
    };

    const editChecklist = () => {
        form.validateFields().then(values => {
            const success = (res: any) => {
                form.setFieldsValue(res.data.data);
                Modal.success({
                    maskClosable: true,
                    title: 'Success',
                    onCancel: ()=>props.setPageContent(<EvaluationList {...props}/>)
                });
            };
            const reject = (error: any) => {
                console.error(error);
                throw error;
            };
            apiCaller(
                putChecklist,
                {
                    ...values,
                    device: 'web',
                    personalInfoBasicId: props.caseId,
                },
                success,
                reject,
            );
        });
    };

    return (
        <div style={{ background: 'white' }}>
            <Form form={form}>
                <div style={{ padding: '30px 10%' }}>
                    <span style={{ fontSize: '22px', fontWeight: 'bold' }}>Normal Fitting Procedure</span>
                    <span className='ml-30'>
                        Before Fitting ( ★= Key Point; <span style={{ fontSize: 12 }}>|| </span> =
                        Parallel; A-P = Anterior-Posterior):
                    </span>


                    <SmallGrayTitle> Before fitting</SmallGrayTitle>

                    <Item checkbox formName='chooseContactAreas'>
                        <div>
                            <span className='text--bold'>1. Choose contact areas </span>
                            (Typically: medium-small-small from top down)
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='loosenScrews'>
                        <div>
                            <span className='text--bold'>2. Loosen screws </span> 
                            for tibial and lower thigh contact area; Adjust lower thigh notches to 0
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>3. </span>
                            Lengthen glute belt if needed
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='chooseBeltSize'>
                        <div>
                            <span className='text--bold'>4. Choose belt size </span> 
                            and pre-installed hip hangers at 3rd line
                        </div>
                    </Item>


                    <SmallGrayTitle> While standing upright</SmallGrayTitle>

                    <Item>
                        <div>
                            <span className='text--bold'>5. </span>
                            Position belt → cover iliac crests & top of pants. Tighten belt
                        </div>
                    </Item>

                    <Item blueBack>
                        <div>
                            <span className='text--bold'>6. </span>
                            Position hip-hangers → front of clips over greater trochanter. Attach straps
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>7. </span>
                            Attach Keeogo frame to clips
                        </div>
                    </Item>

                    <Item checkbox blueBack star formName='verifyHipSensorAlignment'>
                        <div>
                            <span className='text--bold'>8. Verify hip sensor alignment → </span> 
                            to axis of rotation, same height on both sides; ​Hip sensors should be aligned with greater trochanter
                        </div>
                    </Item>

                    <Item checkbox star formName='positionKneeMotor'>
                        <div>
                            <span className='text--bold'>9. Position knee motor → </span> 
                            motor axis through midline of patella (adjust using femoral shaft); ​Centre of motor should 
                            <span className='text--bold'> be aligned with femoral condyles</span>
                        </div>
                    </Item>

                    <Item blueBack>
                        <div>
                            <span className='text--bold'>10. </span>
                            Verify femoral shaft height → same length on both sides (unless known anatomical issue)
                        </div>
                    </Item>

                    <Item checkbox formName='verifyFrameAlignment'>
                        <div>
                            <span className='text--bold'>11. Verify frame alignment → </span>
                            to femur from front and side view (no int/external rotation)
                            <span style={{ fontSize: 12 }}>|| </span>
                        </div>
                    </Item>
            
                    <Item blueBack>
                        <div>
                            <span className='text--bold'>12. </span>
                            Attach clips on contact areas; Tighten Velcro® straps (keep them aligned)
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>13. </span>
                            Adjust upper thigh contact area position if needed
                        </div>
                    </Item>

                    <Item blueBack>
                        <div>
                            <span className='text--bold'>14. </span>
                            Tighten screws on tibial & lower thigh contact areas (angles should be ideal for comfort/fit)
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>15. </span>
                            Slightly tighten glute belt if needed
                        </div>
                    </Item>

                    <Item blueBack>
                        <div>
                            <span className='text--bold'>16. </span>
                            Clip control unit and battery to waist belt
                        </div>
                    </Item>
                    

                    <SmallGrayTitle>While sitting at 90°</SmallGrayTitle>

                    <Item checkbox formName='adjustLowerThighNotches'>
                        <div>
                            <span className='text--bold'>17. Adjust lower thigh notches → </span>
                            Adjust lower thigh notches → correct A-P position of motor. Contact area flush w/ thigh
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='verifyBetweenFemoralShaft'>
                        <div>
                            <span className='text--bold'>18. Verify θ between femoral shaft & hip-hanger ≈ 90°; </span>
                            Else: adjust belt & fem. shaft height
                        </div>
                    </Item>

                    <Item >
                        <div>
                            <span className='text--bold'>19. </span>
                            Verify motor & knee axis alignment →
                            <span style={{ fontSize: 12 }}>|| </span> (not rotated inwards/outwards)
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='verifyTibialContactAreaFit'>
                        <div>
                            <span className='text--bold'>20. Verify tibial contact area fit → </span>
                            conforms to leg (no gap), starts under patellar tendon
                        </div>
                    </Item>



                
                    <div className='mt-50 text--bold'>
                        Adapted Procedure For Fitting In A Seated Position
                    </div>

                    <SmallGrayTitle>Before fitting</SmallGrayTitle>

                    <Item checkbox formName='fittingChooseContactAreas'>
                        <div>
                            <span className='text--bold'>1. Choose contact areas </span>
                            (Typically: medium-small-small from top down)
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='fittingLoosenScrews'>
                        <div>
                            <span className='text--bold'>2. Loosen screws </span>
                            for tibial and lower thigh contact area; Adjust lower thigh notches to 0
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>3. </span>
                            Lengthen glute belt if needed
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='fittingChooseBeltSize'>
                        <div>
                            <span className='text--bold'>4. Choose belt size </span>
                            and pre-installed hip hangers at 3rd line
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>5. </span>
                            If possible while standing, <span className='text--bold'>position belt → </span>cover iliac crests & top of pants. Tighten belt
                        </div>
                    </Item>

                    <Item blueBack>
                        <div>
                            <span className='text--bold'>6. </span>
                            Position hip-hangers → front of clips over greater trochanter. Attach straps
                        </div>
                    </Item>


                    <SmallGrayTitle>While sitting at 90°</SmallGrayTitle>

                    <Item>
                        <div>
                            <span className='text--bold'>7. </span>
                            Attach Keeogo frame to clips; position contact areas on legs
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='fittingVerifyBetweenFemoralShaft'>
                        <div>
                            <span className='text--bold'>8. Verify θ between femoral shaft & hip-hanger ≈ 90°; </span>
                            Else: adjust belt & fem. shaft height
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>9. </span>
                            ​Verify motor & knee axis alignment →
                            <span style={{ fontSize: 12 }}>|| </span> (not rotated inwards/outwards);
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='fittingPositionCentreOfKneeMotor'>
                        <div>
                            <span className='text--bold'>10. Position centre of knee motor → </span>
                            slightly higher than quadriceps tendon by adjusting ​ femoral shaft;
                            <span className='text--bold'>
                                frame must stay aligned<span style={{ fontSize: 12 }}>|| </span> with thigh to maintain correct motor height;
                            </span>
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>11. </span>
                            ​Verify femoral shaft height → same length on both sides (unless known anatomical issue)
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='fittingAdjustLowerThighNotches'>
                        <div>
                            <span className='text--bold'>12. Adjust lower thigh notches → </span>
                            ​correct A-P position of motor. Verify that the frame is ​aligned with the midline of the thigh in the sagittal plane
                        </div>
                    </Item>

                    <Item checkbox formName='fittingVerifyTibialContactAreaFit'>
                        <div>
                            <span className='text--bold'>13.Verify tibial contact area fit → </span>
                            ​conforms to leg (no gap), starts under patellar tendon
                        </div>
                    </Item>

                    <Item blueBack>
                        <div>
                            <span className='text--bold'>14. </span>
                            ​Attach clips on contact areas; Tighten Velcro® straps (keep them aligned)
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>15. </span>
                            ​Tighten screws on tibial & lower thigh contact areas (angles should be ideal for comfort/fit)
                        </div>
                    </Item>

                    <Item blueBack>
                        <div>
                            <span className='text--bold'>16. </span>
                            ​Clip control unit and battery to waist belt
                        </div>
                    </Item>




                    <SmallGrayTitle>While standing upright</SmallGrayTitle>

                    <Item checkbox formName='fittingVerifyHipSensorAlignment'>
                        <div>
                            <span className='text--bold'>17. Verify hip sensor alignment → </span>
                            <span style={{ fontSize: 12 }}>|| </span>
                            ​to axis of rotation, same height on both sides
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='fittingVerifyKneeMotorAlignment'>
                        <div>
                            <span className='text--bold'>18. Verify knee motor alignment → </span>
                            ​motor axis through midline of patella or between midline ​of patella and tibial plateau
                        </div>
                    </Item>

                    <Item checkbox formName='fittingVerifyFrameAlignment'>
                        <div>
                            <span className='text--bold'>19. </span>
                            Verify frame alignment →
                            <span style={{ fontSize: 12 }}>|| </span>
                            to femur from front and side view (no int/external rotation)
                        </div>
                    </Item>

                    <Item blueBack>
                        <div>
                            <span className='text--bold'>20. </span>
                            Adjust upper thigh contact area position if needed
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>21. </span>
                            ​Slightly tighten glute belt if needed
                        </div>
                    </Item>


                    
                    
                    
                    
                    <div className='mt-50 text--bold'>
                        Final Fitting Check Before Tuning
                    </div>
                    
                    <SmallGrayTitle>Battery check</SmallGrayTitle>

                    <Item>
                        <div>
                            <span className='text--bold'>1. </span>
                            ​Take battery out of storage mode (if not done so yet)
                        </div>
                    </Item>

                    <Item checkbox blueBack formName='finalVerifyBatteryCharge'>
                        <div>
                            <span className='text--bold'>2. Verify battery charge → </span>
                            ​determine if current charge fits need
                        </div>
                    </Item>

                    <Item checkbox formName='finalConnectBatteryToKeeogo'>
                        <div>
                            <span className='text--bold'>3. Connect battery </span>
                            to Keeogo;
                            <span className='text--bold'> Verify connector is locked </span>
                        </div>
                    </Item>

                    <Item blueBack>
                        <div>
                            <span className='text--bold'>4. </span>
                            Verify proper attachment of battery and control unit on belt
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>5. </span>
                            Verify Keeogo is OFF & yellow LED shows on the control unit
                        </div>
                    </Item>

                    

                    <SmallGrayTitle>Belt fit in upright stance</SmallGrayTitle>

                    <Item checkbox blueBack formName='finalTightenTheBeltInUprightStance'>
                        <div>
                            <span className='text--bold'>6. Tighten the belt </span>
                            in upright stance →
                            <span className='text--bold'>ensure Keeogo cannot slide down</span>
                        </div>
                    </Item>

                    <Item checkbox formName='finalVerifyBeltKeeogoFit'>
                        <div>
                            <span className='text--bold'>7. Verify belt & Keeogo fit → not slanted or asymmetrical </span>
                            (unless posture or anatomical abnormality). Adjust fit if needed
                        </div>
                    </Item>



                    <SmallGrayTitle>Keeogo fit during use</SmallGrayTitle>

                    <Item checkbox blueBack formName='finalAfterSeveralMinutes'>
                        <div>
                            <span className='text--bold'>8. </span>
                            After several minutes of use, 
                            <span className='text--bold'> check Keeogo fit in upright stance → </span>
                            if Keeogo slid down ​significantly or slanted in use
                            <span className='text--bold'> → verify using hip sensor & knee motor alignment </span>
                        </div>
                    </Item>

                    <Item>
                        <div>
                            <span className='text--bold'>9. </span>
                            Readjust Keeogo and correct fitting issue if needed before continuing use
                        </div>
                    </Item>



                    <div className='mt-50 mb-30 text--bold'>
                        After 1st session
                    </div>
                    
                    
                    <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        <span className='mr-10'>★</span>
                        <div>
                            Take Note Of Sizes & Adjustments For Next Session With User (Belt Size, Hip-Hanger Position, Fem. Shaft Length, Contact Areas, Screw & Notch Positions)
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            onClick={editChecklist}
                            style={{ backgroundColor: '#47A6D8', color: 'white' }}
                        >
                            {formatMessage({id:'SAVE_UPPERCASE'})}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default ClinicianPage;
