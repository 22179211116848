import React, { useState, useEffect } from 'react';
import { Form, InputNumber, Row, Col, Empty, Table } from 'antd';
import Drawer from 'components/drawer/drawer';
import { Input  } from 'components/form';
import Button from 'components/button/button';
import moment from 'moment';
import { apiCaller } from 'service/apiCaller';
import usePermission  from 'hooks/usePermission';
import { useIntl } from 'react-intl';
import Modal from 'antd/es/modal';
import styled from 'styled-components';
import { addCrossOrganization, getCrossOrganizationByCase } from 'api/case';
import { getOrganization } from 'api/organizationMgmtApi';
import { Organization } from 'containers/organizationMgmt/type';
import { UserInfo } from 'containers/caseMgmt/caseMgmtType';
import { data } from 'containers/roleMgmt/roleList';

const StyledTable = styled(Table)`
    thead tr th {
        border-bottom: 3px solid #0083C1;
        color: #0083C1;
    }
`;

type Props = {
    type:'edit'|'add';
    data:UserInfo;
    onClose:()=>void;
    onRefresh:()=>void;
}

export const AddCrossOrganizationDrawer: React.FC<Props> = (props) => {
    const intl = useIntl();

    const [form] = Form.useForm();
    const [organizations, setOrganizations] = useState<Array<Organization>>([]);
    
    const {
        data:item,
        type, 
        onRefresh,
        onClose
    } = props

    const [selectedOrg, setSelectedOrg] = useState<Array<{key: number; organization: string;}>>([]);
    const [originalOrganizations, setOriginalOrganizations] = useState<number[]>([item.fhirOrganizationId]);

    const getAllOrganizations = async () => {
        await apiCaller(
            getOrganization,
            {},
            (res: any)=>{
                let orgName = res.data.data.find((org: Organization)=>org.id == item.fhirOrganizationId)?.name;
                setOrganizations(res.data.data.map((datum: Organization)=>Object.assign({}, datum, {key: datum.id})));
                form.setFieldsValue({
                    mainOrg: orgName
                });
            },
            (err: Error)=>{
                throw(err);
            }
        );
    };

    const getCasesOrganizations = async () => {
        await apiCaller(
            getCrossOrganizationByCase,
            {
                id: item.id
            },
            (res: any) => {
                let caseOrgIds = res.data.data.map((row: any)=>row.fhirOrgId);
                setOriginalOrganizations((prv: number[])=>prv.concat(caseOrgIds));
            },
            (err: Error) => {
                throw(err);
            }
        );
    }
    
    useEffect(()=>{
        getAllOrganizations();
        getCasesOrganizations();
    }, []);

    const handleOk = async () => {
        form.validateFields().then(async values => {
            const reqBody = {
                id: item.id,
                openFhirOrganization: selectedOrg
            };

            const doSuccess = (res: any) => {
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel:()=>{
                        handleClose()
                        onRefresh()
                    },
                    centered: true
                })
            };
            const doReject = (err: any) => {
                Modal.error({
                    maskClosable:true,
                    title:'Error',
                    content:<p style={{textAlign:'center'}}>{err?.data?.detail ?? ''}</p>
                })
            };
            
            console.log('[ payload before req ]', values)
            
            await apiCaller(
                addCrossOrganization,
                reqBody,
                doSuccess,
                doReject
            );
        });
    };

    const handleClose = () => {
        form.resetFields();
        form.setFieldsValue({});
        onClose();
    };

    const columns = [
        {
            title: 'Organization Name',
            dataIndex: 'name'
        }
    ];

    return (
        <Drawer
            visible={true}
            getContainer={false}
            maskClosable={true}
            closable={false}
            keyboard={false}
            width={500}
            title={intl.formatMessage({id:'ORG_ADD'})}
            onClose={handleClose}
            footer={
                <div className="pull--right rect">
                    <Button className="mr-20 rect" onClick={handleClose}>
                        {intl.formatMessage({id:'CANCEL'})}
                    </Button>
                    <Button className="is--primary rect" onClick={handleOk}>
                        {intl.formatMessage({id:'SAVE'})}
                    </Button>
                </div>
            }
        >       
            <Form form={form} layout="vertical">
                
                <h2 className='title'>{intl.formatMessage({id:'MAIN_ORG'})}</h2>
                <Form.Item
                    label={intl.formatMessage({id:'ORG_NAME'})}
                    name="mainOrg"
                    rules={[{ required: true }]}
                >
                    <Input disabled={true} readOnly={true} />
                </Form.Item>

                <h2 className='title'>{intl.formatMessage({id:'CHOOSE_ORG'})}</h2>
                <StyledTable
                    columns={columns}
                    dataSource={organizations.filter((org: Organization)=>!originalOrganizations.includes(org.id))}
                    pagination={false}
                    rowSelection={{
                        type:'checkbox',
                        onChange: (selectedRowKeys: React.Key[], selectedRows: Array<any>) => {
                            setSelectedOrg(
                                selectedRows.map(row=>({
                                    organization: row.name,
                                    key: row.key
                                }))
                            );
                        }
                    }}
                />

            </Form>
        </Drawer>
    );
};
