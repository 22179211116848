import React from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

const Wrapper = styled.div<{bgc: string, textColor: string, width?: string, height?: string}>`
    border-radius: 0.5rem;
    background-color: ${props => props.bgc};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props => props.width ?? '80px'};
    height: ${props => props.height ?? '80px'};
    cursor: ${props => props.onClick ? 'pointer' : 'default'};

    .text {
        color: ${props => props.textColor};
        font-size: 12px;
        transform: scale(0.8);
        white-space: nowrap;
    }
`;

interface Props {
    icon?: JSX.Element;
    text?: string | JSX.Element;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    bgc?: string;
    textColor?: string;
    width?: string;
    height?: string;
    className?: string;
}

const RoundedButton: React.FC<Props> = (props: Props) => {

    const { text, onClick, bgc = '#F3F3F3', textColor = '#0083C1', icon = <PlusOutlined style={{color: '#0083C1', fontSize: '20px'}} /> } = props;

    return (
        <Wrapper className={props.className} onClick={onClick} bgc={bgc} textColor={textColor} width={props.width} height={props.height}>
            {icon}
            <div className="text">
                {text}
            </div>
        </Wrapper>
    )
}

export default RoundedButton;