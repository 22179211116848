import { DatePicker } from 'antd';
import styled from 'styled-components';

const StyledDatePicker = styled(DatePicker)`
    &.ant-picker {
        position: relative;
        bottom: 1px;
        padding-left: 1px;
        border-bottom: 1px solid #d9d9d9 !important;

        &.ant-picker-borderless{
            background: ${props => props.disabled ? '#F2F2F2 !important' : ''};
        }
    }

    .ant-picker-input > input[disabled] {
        color: ${props => props.disabled ? '#535353' : ''};
    }
`;

export default StyledDatePicker;
