// tslint:disable quotemark no-implicit-dependencies
import React from 'react';
import { SelectProps } from 'antd/es/select';
import { pathOr, merge, map, path, hasPath, mergeAll } from 'ramda';
import useMountedState from 'hooks/useMountedState';
import { useAxios } from 'hooks/useAxios';
import { Select } from 'components/form';
import { RequestConfig } from 'constants/types';
import { JSON_SERVER_URL } from 'constants/config';
const { compose } = require('ramda');

export type Enum = {
    id: React.Key;
    name: string;
};

export function useEnums<T = Enum>(endpoint: string, options: RequestConfig = {}) {
    const [data, setData] = React.useState<T[]>([] as T[]);
    const [byId, setById] = React.useState<{ [key: string]: T }>({});
    const [loading, request] = useAxios();

    const isMounted = useMountedState();

    const listAll = async () => {
        const response = await request<T[]>(
            `${endpoint}?pageSize=-1`,
            merge(
                {
                    baseURL: JSON_SERVER_URL,
                    useCache: true,
                },
                options,
            ),
        );

        // 在 setState 前先檢查元件是否 unmount
        if (!isMounted()) {
            return;
        }

        // check has data or response status code == 200
        if (
            (hasPath(['data'])(response) && hasPath(['success'])(response)) ||
            path(['status'])(response) === 200
        ) {
            setData(compose(pathOr([], ['data']))(response));
            setById(
                Object.assign(
                    {},
                    ...compose(
                        map((x: any) => ({ [x.id]: x })),
                        pathOr([], ['data']),
                    )(response),
                ),
            );
        }
    };

    React.useEffect(() => {
        listAll();
    }, []); // eslint-disable-line

    return {
        loading,
        byId,
        refetch: listAll,
        dataSource: data,
    };
}

/**
 * [{ id: 1, name: cc}, { id: 2, name: gg}]
 * dataSource: [{xxx},{xxx}]
 * byId: {
 *   1: {
 *     id: 1,
 *     name: cc
 *   },
 *   2: {
 *     id: 2,
 *     name: gg
 *   }
 * }
 */

export const useAllTrainingTypes = () => {
    const dataSource = [
        {
            id: 1,
            name: '復能',
        },
        {
            id: 2,
            name: '快篩',
        },
    ];

    return {
        trainingTypeById: compose(
            mergeAll,
            map((x: Enum) => ({ [x.id]: x })),
        )(dataSource),
        dataSource,
    };
};

export const useAllOrgs = () => {
    const { byId, ...others } = useEnums('/organization', {
        baseURL: undefined,
    });
    return { ...others, orgById: byId };
};

export function TrainingTypeSelect<T = Enum>(props: SelectProps<T>) {
    const { dataSource } = useAllTrainingTypes();

    return (
        <Select allowClear={true} placeholder="請選擇訓練類型" {...props}>
            {dataSource.map(x => (
                <Select.Option key={x.id} value={x.id}>
                    {x.name}
                </Select.Option>
            ))}
        </Select>
    );
}

export function OrganizationSelect<T = Enum>(props: SelectProps<T>) {
    const { dataSource } = useAllOrgs();

    return (
        <Select allowClear={true} {...props}>
            {dataSource.map(x => (
                <Select.Option key={x.id} value={x.id}>
                    {x.name}
                </Select.Option>
            ))}
        </Select>
    );
}

export function GenderSelect<T = Enum>(props: SelectProps<T>) {
    return (
        <Select allowClear={true} placeholder="請選擇性別" {...props}>
            <Select.Option value={''}>全部</Select.Option>
            <Select.Option value={'M'}>男性</Select.Option>
            <Select.Option value={'F'}>女性</Select.Option>
        </Select>
    );
}

// export function CourseTypeSelect<T = Enum>(props: SelectProps<T>) {
//     return (
//         <Select allowClear={true} placeholder="請選擇課程類型" {...props}>
//             {Object.values(CourseType)
//                 .filter(x => x.value !== '體驗')
//                 .map(x => (
//                     <Select.Option key={x.key} value={x.key}>
//                         {x.value}
//                     </Select.Option>
//                 ))}
//         </Select>
//     );
// }
