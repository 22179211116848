// tslint:disable no-implicit-dependencies
import React from 'react';
import { path, equals } from 'ramda';
import { FormInstance } from 'antd/lib/form';
import usePrevious from 'hooks/usePrevious';

type Props<T> = {
    dataSource: T[];
    key?: string;
    onRequestOpen?: (item: T) => any;
};

/**
 * 從列表項目中查找出要編輯的該筆資料並打開編輯表單
 * @param dataSource - 列表資料
 * @param key - 用來從dataSource中查找目標資料的索引值名稱，預設是 id
 * @param onRequestOpen - 展開右側編輯表單時可以做的事情，例如打 API 取得明細
 */
export default function useEdit<T = any>({
    dataSource,
    key = 'id',
    onRequestOpen,
}: Props<T>) {
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [item, setItem] = React.useState<T | undefined>();

    const handleEditModalVisible = (evt?: React.MouseEvent<HTMLElement>) => {
        setEditModalVisible(prev => !prev);
        const target = dataSource.find(
            (x: { [key: string]: any }) =>
                `${path([key], x)}` === evt?.currentTarget?.dataset.id,
        );

        setItem(target);

        if (onRequestOpen && target) {
            onRequestOpen(target);
        }
    };

    const handleCloseEditModal = () => {
        setEditModalVisible(false);
    };

    return [
        editModalVisible,
        handleEditModalVisible,
        {
            item,
            handleCloseEditModal,
        },
    ] as [
        boolean,
        (evt?: React.MouseEvent<HTMLElement>) => any,
        {
            item: T;
            handleCloseEditModal: (evt?: React.MouseEvent<HTMLElement>) => any;
        },
    ];
}

/**
 * 展開 Drawer 或 Modal 時重設表單和設定編輯物件
 * @param {Object} item form edit item object
 * @param {Boolean} visible toggle state
 * @param {Object} form antd form instance
 */
export function useEditItem<T = any>({
    item,
    form,
    editType,
    visible,
    onChange,
}: {
    form: FormInstance;
    item?: T;
    visible?: boolean,
    editType?: string
    onChange?: (value: T | undefined) => any;
}) {   
    const [editItem, setEditItem] = React.useState<T | null>();

    const prev = usePrevious(item);

    React.useEffect(() => {
        if (visible) {
            requestAnimationFrame(() => form.resetFields());
        }
    }, [visible]); // eslint-disable-line

    React.useEffect(() => {
        
        if (!equals(item, prev) || item === undefined && prev === undefined) {
            
            let fieldsVal = item
            if(editType === 'add'){
                fieldsVal = {...fieldsVal, active:true } as any
            }

            requestAnimationFrame(()=>form.setFieldsValue({...fieldsVal as any}))

            setEditItem(item as any)

            if (onChange) {
                requestAnimationFrame(() => onChange(item));
            }
        }
    }, [item, prev]); // eslint-disable-line

    return [editItem, setEditItem] as [
        T | undefined,
        React.Dispatch<React.SetStateAction<T | null | undefined>>,
    ];
}
