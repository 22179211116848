import React from 'react';
import { Slider } from 'antd';
import { SliderProps } from 'antd/lib/slider';
import styled from 'styled-components';

const Wrapper = styled.div<{vertical?: boolean, reverse?: boolean}>`
    display: flex;
    flex-direction: ${props => props.vertical ? 'column' : 'row'};
    align-items: flex-start;
    width: ${props => props.vertical ? 'auto' : '100%'};

    .indicator {
        color: #0083C1;
        font-size: 14px;
        ${props => props.vertical ? 'width' : 'height'}: 100%;
        text-align: center;
        ${props => props.vertical ? '' : 'margin-left: 10px;'}
    }
`;

export const SliderLabel = styled.label<Props>`
    height: 22px;
    ${props => props.vertical ? 'margin-top: 5px;' : 'margin-right: 5px;'}
    min-width: ${props => props.vertical ? 'auto' : '50px'};
    text-align: ${props => props.vertical ? 'center' : 'right'};
`;

const StyledSlider = styled(Slider)`
    &.ant-slider {
        ${props => props.vertical ? '' : 'width: 100%'};
        margin: ${props => props.vertical ? '5px 0' : '0 5px'};

        .ant-slider-rail {
            ${props => props.vertical ? 'width' : 'height'}: 20px;
            background-color: ${props => props.reverse ? '#EDB754' : '#FFFFFF'}!important;
            box-shadow: ${props => props.reverse ? 'none' : '-1px 1px 8px rgba(0, 0, 0, 0.2) inset'};
        }
    
        .ant-slider-track {
            background-color: ${props => props.reverse ? '#FFFFFF' : '#EDB754'}!important;
            ${props => props.vertical ? 'width' : 'height'}: 20px;
            box-shadow: ${props => props.reverse ? '-1px 1px 8px rgba(0, 0, 0, 0.2) inset' : 'none'};
        }
    
        .ant-slider-handle {
            ${props => props.vertical ? 'width' : 'height'}: 30px;
            ${props => props.vertical ? 'height' : 'width'}: 15px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            background-color: #FFFFFF!important;
            border: none;
            border-radius: 0.1rem;
            transform: ${props => props.reverse ? 'none!important' : ''};
        }
    }
`;

interface Props extends SliderProps {
    label?: string | JSX.Element;
    indicator?: boolean;
    unit?: string;
}

const CustomSlider: React.FC<Props> = (props: Props) => {

    const { label = '', indicator = false, unit = '', ...sliderProps } = props;

    let labelElement = label;
    if(typeof label === 'string'){
        labelElement = <SliderLabel vertical={props.vertical} >{label}</SliderLabel>
    }

    return (
        <Wrapper vertical={props.vertical} reverse={sliderProps.reverse}>
            {props.vertical ? (
                indicator&&
                    <div className="indicator">
                        {`${props.value ?? props.defaultValue}${unit}`}
                    </div>
                ) : labelElement
            }
            <StyledSlider {...sliderProps} />
            {props.vertical ? labelElement : (
                indicator&&
                    <div className="indicator">
                        {`${props.value ?? props.defaultValue}${unit}`}
                    </div>
                )
            }
        </Wrapper>    
    )
}

export default CustomSlider;
