import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/button/button';
import { Modal } from 'antd';
import { TextArea, Select, Form } from 'components/form';
import { editEvaluation, findOneEvaluation } from 'api/eavluation';
import EvaluationList from './EvaluationList';
import ViewPage from './EvaluationPages/ViewPage';
import TuningPage from './TuningPage';
import ClinicianPage from './ClinicianPage';
import { PageProps } from './EvaluationTab';
import { icon_make_invalid, icon_info_outlined } from 'components/image';
import { EvaluationBrief } from './type';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ViewPageOption } from './EvaluationPages/ViewPage';
import moment from 'moment';

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 0.4rem;

        .ant-modal-close {
            .ant-modal-close-x {
                .anticon-close {
                    font-size: 24px;
                    color: #000000;
                }
            }
        }

        .ant-modal-header {
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            border-radius: 0.4rem 0.4rem 0 0;

            .ant-modal-title {
                font-size: 22px;
                color: #2E63A3;
                font-weight: bold;
            }
        }

        .ant-modal-body {
            textarea {
                resize: none !important;
                background-color: #F3F3F3;
                border: none;
            }
        }

        .ant-modal-footer {
            border: none;
        }
    }
`;

const CustomSelector =  styled(Select)`
    .ant-select-selector {
        background-color: transparent!important;
        border-radius: 2rem!important;
        width: 150px!important;
        border: 1px solid #909090!important;
    }

    .ant-select-arrow {
        font-weight: bold;
        font-size: 14px;
        color: #909090;
    }
`;

export enum PageOption {
    TUNING,
    CLINICIAN,
    View
}

interface Props extends PageProps {
    evaluationData?: EvaluationBrief;
    pageType: PageOption;
    hasPreliminary: boolean;
}

const EvaluationPage: React.FC<Props> = (props: Props) => {

    const { evaluationData, pageType, ...pageProps } = props;

    const [form] = Form.useForm();
    const { formatMessage } = useIntl();

    const [viewPage, setViewPage] = useState(ViewPageOption.LLPage);
    const [showModal, setShowModal] = useState(false);
    const [planDate, setPlanDate] = useState(evaluationData?.planDate);

    const refetchPlanDate = () => {
        findOneEvaluation(evaluationData?.id ?? 0).then(res=>{
            const newPlanDate = props.treatmentData.map((d: any)=>d.plan_time).flat().find((d: any)=>d.id === res.data.data.personalTreatmentPlanDetailId)?.treatmentDate;
            setPlanDate(newPlanDate);
        }).catch(err=>{
            console.error(err);
            throw(err);
        });
    }

    const pageConfig: {
        [type: number]:{
            component: JSX.Element,
            header: JSX.Element | string
        }
    } = {
        [PageOption.TUNING]: {
            component: <TuningPage {...pageProps} />,
            header: (
                <div style={{fontSize: 16, fontWeight: 'bold'}} >
                    {formatMessage({id:'8007_TUNING_RECORD'})}
                </div>
            )
        },
        [PageOption.CLINICIAN]: {
            component: <ClinicianPage {...pageProps} />,
            header: (
                <div style={{fontSize: 16, fontWeight: 'bold'}} >
                    {formatMessage({id:'CLINCIAN_CHECKLIST'})}
                </div>
            )
        },
        [PageOption.View]: {
            component: <ViewPage {...pageProps} hasPreliminary={props.hasPreliminary} isPreliminary={props.evaluationData?.isPreliminary ?? false} pageType={viewPage} evaluationId={evaluationData?.id ?? 0} suspend={!evaluationData?.status ?? false} refetchPlanDate={refetchPlanDate} />,
            header: (
                <CustomSelector
                    bordered={true}
                    allowClear={false}
                    defaultValue="Phys.LL"
                    onChange={(val: ViewPageOption)=>setViewPage(val)}
                >
                    <Select.Option value={ViewPageOption.LLPage}>Phys.LL</Select.Option>
                    <Select.Option value={ViewPageOption.ULPage}>Phys.UL</Select.Option>
                    <Select.Option value={ViewPageOption.KeeogoPage}>Keeogo Exam</Select.Option>
                    {/* {!props.evaluationData?.status && <Select.Option value={ViewPageOption.InvalidRecord}>Invalid Record</Select.Option>} */}
                </CustomSelector>
            )
        }
    }

    const handleInvalidModalSubmit = () => {
        form.validateFields().then(values=>{
            findOneEvaluation(evaluationData?.id ?? 0).then(findOneRes=>{
                editEvaluation({
                    id: findOneRes.data.data.evaluation_detail.id,
                    personalEvaluationDetailId: findOneRes.data.data.evaluation_detail.id,
                    evaluationDate: evaluationData?.evaluationDate,
                    device: 'web',
                    status: false,
                    ...values
                }).then(res=>{
                    Modal.success({
                        maskClosable:true,
                        title:'Success',
                        onCancel: ()=>{
                            props.refetch();
                            setShowModal(false);
                        }
                    });
                }).catch(err=>{
                    console.error(err)
                    Modal.error({
                        maskClosable:true,
                        title:'Error',
                        content: <div style={{textAlign:'center'}}> 
                            {
                                (()=>{
                                    const msg = err?.data?.detail
                                    if(typeof msg === 'string'){
                                        return msg
                                    }else if(msg instanceof Array){
                                        return msg.join('\n')
                                    }else{
                                        return JSON.stringify(msg)
                                    }
                                })()   
                            }
                        </div> 
                    });
                })
            })
            
        });
    }

    return (
        <div>
            {showModal&&
            <StyledModal
                visible={showModal}
                closable={false}
                width='360px'
                onCancel={()=>{setShowModal(false);form.setFieldsValue({remark:''});}}
                footer={
                    <div>
                        <Button
                            className='rect'
                            type='link'
                            onClick={()=>{setShowModal(false);form.setFieldsValue({remark:''});}}
                            style={{
                                fontWeight: 'bold',
                                color: '#2E2E2E'
                            }}
                        >{formatMessage({id:'CANCEL_UPPERCASE'})}</Button>
                        <Button
                            className='rect'
                            type='link'
                            onClick={handleInvalidModalSubmit}
                            style={{
                                fontWeight: 'bold',
                                color: '#47A6D8'
                            }}
                        >{formatMessage({id:'YES_UPPERCASE'})}</Button>
                    </div>
                }
            >
                <div className='d-flex is--column'>
                    <Form form={form} layout='vertical'>
                        <div className='icon'>
                            <img src={icon_info_outlined}/>
                        </div>
                        <div className='text--bold text-24 mb-15'>
                            {formatMessage({id:'MAKE_INVALID'})}
                        </div>
                        <Form.Item label='Make record to confirm invalid' name='remark' rules={[{required: true, message: `${formatMessage({id:'REMARK'})} is required`}]}>
                            <TextArea rows={5} placeholder='(content)'/>
                        </Form.Item>
                    </Form>
                </div>
            </StyledModal>}

            <div className='d-flex is--space-between v--center' style={{position: 'sticky', top: 0, backgroundColor: '#F3F3F3', padding: '10px 0', zIndex: 500}}>
                <div className='d-flex v--center'>
                    <ArrowLeftOutlined className='mr-15 text-20' onClick={()=>props.setPageContent(<EvaluationList {...props} />)}/>
                    {pageConfig[pageType].header}
                </div>
                <div className='d-flex v--center'>
                    {evaluationData?.therapist || '--'} | {formatMessage({id:'ADDED_DATE'})}: {moment(evaluationData?.evaluationDate).format('DD/MM/YYYY')} | {formatMessage({id:'CLASS_DATE'})}: {planDate ? moment(planDate).format('DD/MM/YYYY') : '--'}
                    {/* {!evaluationData?.isPreliminary && evaluationData?.status && pageType !== PageOption.TUNING && pageType !== PageOption.CLINICIAN &&
                    <Button
                        style={{backgroundColor: '#FFFFFF', border: 'none'}}
                        className='rect ml-10'
                        icon={<img src={icon_make_invalid} alt='' />}
                        onClick={() => setShowModal(true)}
                    />} */}
                </div>
            </div>
            {pageConfig[pageType].component}
        </div>
    )
}

export default EvaluationPage;
