import React, { useState, useEffect } from 'react';
import { Table, Checkbox, Form, Modal } from 'antd';
import Drawer from 'components/drawer/drawer';

import roleList from './roleList';

import './roleMgmt.sass';
import { CaretRightOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { EditFunc } from '../../components/component';
import { Input, Label } from 'components/form';
import { apiCaller } from '../../service/apiCaller';
import { editRoleApi } from '../../api/role';
import { find, propEq } from 'ramda';
import { makePermissionNode, PermissionNode, RoleType, nodeToJson } from 'service/permission';
import Button from 'components/button/button';
import { useIntl } from 'react-intl';
import { PermissionType } from '../../constants/types';
import styled from 'styled-components';
import permissionList from './permissionList.json';

type Props = {
    close:()=>void
    refetch:()=>void
    initData?:RoleType
}

export const EditRight = (props: Props) => {
    const intl = useIntl();
    const [editFunc, setEditFunc] = useState(false);

    const [roleListView, setRoleListView] = useState<PermissionNode[]>([]);
    const { close, initData , refetch } = props;
    
    useEffect(() => {
        setRoleListView(makePermissionNode(permissionList, props.initData?.permission || {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props]);

    const setPermission = (roleList: any) => {
        const permission = nodeToJson(roleList);
        const success = (res: any) => {
            const { status, data } = res;
            Modal.success({
                title: intl.formatMessage({id:'SUCCESS'}),
                maskClosable:true,
                onCancel:()=>{
                    refetch()
                    close();
                }
            })
        };
        const reject = (error: any) => {
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = error?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            })
        };
        apiCaller(editRoleApi, { id: initData?.id, name: initData?.name, permission}, success, reject);
    }

    const setMenu = (menu: any) => {
        setEditFunc(false);
        const dtNew = menu.map((item: string) => find(propEq('id', item))(roleList));
        const rows = dtNew.map((x: any)=>{
            const row = roleListView.find((r: any)=> r.id === x.id)
            if (row) {
                return row;
            }
            return x;
        })
        setRoleListView(rows);
    }

    const renderHandler = (record: any, prop: string, act: (e: any, r: any) => void):any => {
        const ifChildrenChecked = (node: PermissionNode): boolean => {
            if(node.children){
                for(let i = 0; i<node.children.length; ++i){
                    if(node.children[i].available || node.available){
                        return true;
                    }
                    
                    if(node.children[i].children){
                        if(ifChildrenChecked(node.children[i])){
                            return true;
                        }
                    }
                }
            }
            return false;
        }

        const setCheckedTilLeaf = (node: PermissionNode, ev: any): void => {
            if(node.children){
                node.children.forEach((child)=>{
                    act(ev, child);
                    child[prop] = ev.target.checked;
                    setCheckedTilLeaf(child, ev);
                })
            }
        }
        
        return (
            record.children
            ?
            <Checkbox
                key={record.key}
                checked={ifChildrenChecked(record)}
                onChange={(ev) => {
                    act(ev, record);
                    record[prop] = ev.target.checked;
                    setCheckedTilLeaf(record, ev);
                    while(record.parent){
                        record=record.parent;
                        if(ev.target.checked){
                            record[prop] = ev.target.checked;
                        }
                    }
                    
                    setRoleListView([...roleListView]);
                }}
                
            />
            :
            <Checkbox
                key={record.key}
                checked={record[prop]}
                onChange={(ev) => {
                    act(ev, record);
                    record[prop] = ev.target.checked;
                    setRoleListView([...roleListView]);
                }}
                
            />
        )

    }
    
    const columns = [
        // 功能名稱
        {
            dataIndex: 'name',
            title: intl.formatMessage({id:'ROLE_NAME_LABEL'}),
            className: 'column-center-align'
        },
        // access
        {
            dataIndex: 'available',
            title: intl.formatMessage({id:'ACCESS'}),
            className: 'column-center-align',
            render: (text: any, record: any, index: number) => renderHandler(record, 'available', (e, r) => {
                if (e.target.checked) {
                    r.available = e.target.checked;
                }
            }),
        }
    ];

    const customExpandIcon = (p:any) => {
        return (
            p.expandable && (
                <a
                    style={{
                        color: 'black',
                        fontSize: '1rem'
                    }}
                    onClick={(e: any) => {
                        p.onExpand(p.record, e);
                    }}
                >
                    {
                        p.expanded
                        ?
                        <CaretDownOutlined style={{ color: 'var(--color-primary-light)' }} />
                        :
                        <CaretRightOutlined style={{ color: 'var(--color-primary-light)' }} />
                    }
                </a>
            )
        )
    }

    return (
        <Drawer
            className='editRight'
            title={intl.formatMessage({id:'ROLE_SETTING'})}
            width='35vw'
            visible={true}
            onClose={close}
            maskClosable={true}
            closable={true}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <>
                    {/* 取消 */}
                    <Button onClick={close} className='rect' style={{ marginRight: 16 ,width: 100,height:36 }}>
                        {intl.formatMessage({id:'CANCEL'})}
                    </Button>
                    {/* 儲存 */}
                    <Button
                        className='is--primary rect'
                        style={{width: 100,height:36 }}
                        onClick={() => setPermission(roleListView)}
                    >
                        {intl.formatMessage({id:'SAVE'})}
                    </Button>
                </>
            }
        >
            {/* {
                editFunc && (
                    <EditFunc
                        onCancel={() => setEditFunc(false)}
                        defaultFunc={roleListView.map(item => item.name)}
                        setMenu={setMenu}
                    />
                )
            } */}

            <Form layout='vertical' initialValues={{...initData}}>
                {/* 角色名稱 */}
                <h2 className='title'>{intl.formatMessage({id:'ROLE_NAME'})}</h2>
                {/* 功能權限 */}
                <Form.Item
                    name='name'
                    label={<Label required={true} name={intl.formatMessage({id:'ROLE_NAME_LABEL'})}/>}
                >
                    <Input bordered={false} disabled={true}/>
                </Form.Item>

                <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    margin: '1rem 0'
                }}>
                    {/* 功能權限 */}
                    <h2 className='title'>{intl.formatMessage({id:'ROLE_RIGHT'})}</h2>
                    {/* 選擇功能 */}
                    {/* {
                        myPermission.write && (
                            <Button
                                type='primary'
                                shape="round"
                                style={{background: "#2E63A3",color: 'white'}}
                                onClick={() => setEditFunc(true)}
                            >
                                + {intl.formatMessage({id:'ROLE_RIGHT_SELECT'})}
                            </Button>
                        )
                    } */}
                </div>

                <StyledTable
                    rowKey='name'
                    columns={columns}
                    dataSource={roleListView}
                    pagination={false}
                    expandIcon={customExpandIcon}
                />
            </Form>
        </Drawer >

    );
};

const StyledTable = styled(Table)`
 svg{
    color: black;
}
span{
    span{
        background-color: black  !important;
         border-color: black  !important;
        margin-right: 18px;
    }
}
`;
