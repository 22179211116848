import React, { useContext,useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { GlobalContext } from '../../../../index';

export interface TreatmentProps {
    id: number;
    name: string;
    treatmentDate: string;
    treatmentHour: string;
    treatmentTypeCode: string;
    classTypeCode: string;
    treatmentDetailStatusCode: string;
    className?: string;
    memo?:string;
    planName?: string;
    isNext?: boolean;
    treatmentOrg?: string;
    selected?: boolean;
    state?:number;
    resultTime?:string
}

const TreatmentWrapper = styled.div<{isNext?: boolean, StatusCode: string}>`
    display: flex;
    aspect-ratio: 5 / 2;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border-radius: 0.3rem;
    overflow: hidden;

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        width: 25%;
        color: #FFFFFF;
        justify-content: space-between;

        .month {
            font-size: 1.2vw;
        }

        .date {
            font-size: 1.4vw;
            line-height: 1.4vw;
        }

        .weekday {
            font-size: 1vw;
        }
    }

    .right {
        padding: 5px 10px;
        width: 75%;
        height: 100%;
        color: #000000;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        background-color: #FFFFFF;

        .hour {
            width: 100%;
            text-align: left;
            font-size: ${props => props.isNext ? '1vw' : props.StatusCode !== 'TDS02' ? '1vw' : '1.2vw'};
        }

        .type {
            width: 100%;
            text-align: left;
            font-size: 1.4vw;
            font-weight: bold;
        }

        .name {
            width: 100%;
            text-align: left;
            font-size: 1vw;
        }

        .status {
            font-size: 1vw;
            width: 100%;
            text-align: left;
            display: ${props => props.isNext ? 'flex' : props.StatusCode !== 'TDS02' ? 'flex' : 'none'};
        }
    }
`;

const TreatmentCard: React.FC<TreatmentProps & {onClick: (treatment: TreatmentProps)=>void}> = (props) => {

    const { formatMessage } = useIntl();
    const { getCode,nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const { onClick, ...treatmentProps } = props; 
    const treatmentDate = moment(treatmentProps.treatmentDate);
    //延後50分鐘
    var times =moment(props.treatmentHour, 'HH:mm').add(50, 'minutes').format("HH:mm") ;

    const isTrial = props.classTypeCode === nameMapping['CAS01'];
    const colorMapping = {
        TDS01: '#2E63A3',
        TDS02: '#2E63A3',
        TDS03: '#8A5DD1',
        TDS04: '#2E63A3',
        TDS05: '#EDB754',
        TDS06: '#5DD197'
    };
   
    return (
        <TreatmentWrapper isNext={props.isNext} StatusCode={getCode('TreatmentDetailStatus', props.treatmentDetailStatusCode)} onClick={()=>onClick(treatmentProps)} className={props.className + (props.selected ? ' selected' : '') } >
           
            <div className="left" style={{
                backgroundColor: colorMapping[getCode('TreatmentDetailStatus', props.treatmentDetailStatusCode)] || '#2E63A3'
            }}>
                <div>
                    <div className="month">
                        {treatmentDate?treatmentDate.format('MMM')+'.':'--'}
                    </div>
                    <div className="date">
                        {treatmentDate?treatmentDate.format('D'):'--'}
                    </div>
                </div>
                <div className="weekday">
                    {treatmentDate?treatmentDate.format('ddd').toUpperCase():'--'}
                </div>
            </div>
            <div className="right">
                <div className='d-flex is--column is--space-around ml-5' style={{height: '100%'}}>
                    <div className="hour">
                        {isTrial ? treatmentProps.treatmentHour : treatmentProps.treatmentHour +"-"+times}
                    </div>
                    <div className="type">
                        {treatmentProps.planName ? treatmentProps.planName : isTrial ? treatmentProps.treatmentTypeCode : treatmentProps.name}
                    </div>
                    {props.planName && 
                    <div className="name">
                        {treatmentProps.name}
                    </div> }
                </div>
                <div className="status ml-5" style={{
                    color: colorMapping[getCode('TreatmentDetailStatus', props.treatmentDetailStatusCode)]
                }}>
                    {props.isNext ? formatMessage({id:'NEXT_ON'}) : treatmentProps.treatmentDetailStatusCode}
                </div>
         </div>
        </TreatmentWrapper>
    )
}

export default TreatmentCard;