// tslint:disable no-implicit-dependencies quotemark
import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/es/modal';
import styled from 'styled-components';
import Table from 'components/table/table';
import Button from 'components/button/button';
import useTableRowSelection from 'hooks/useTableRowSelection';
import { Enum } from 'api/enums';
import { getRoleListApi } from 'api/role';
import { useIntl } from 'react-intl';

type Props = Omit<ModalProps, 'onOk' | 'onCancel'> & {
    selectedItems: Enum[];
    onOk(selectedRows: Enum[]): any;
    onCancel: () => any;
};

export default function UserRoleModal({
    onOk,
    onCancel,
    selectedItems = [],
}: Props) {
    const {formatMessage} = useIntl()
    const [allRoles, setAllRoles] = useState<any[]>([]);

    useEffect(()=>{
        getRoleListApi({
            selectRole: true
        }).then(res=>{
            setAllRoles(res.data.data);
        }).catch(error=>{
            console.error(error);
        });
    }, []);

    const { rowSelection, selectedRows } = useTableRowSelection<Enum>({
        defaultRowKeys: selectedItems.map(x => x.id),
        defaultRows: selectedItems,
        multiple: false,
        limit: 1
    });

    const handleOk = () => {
        onOk(selectedRows);
        onCancel();
    };

    return (
        <StyledModal
            visible={true}
            title={formatMessage({id:'ROLE_SELECT'})}
            maskClosable={false}
            keyboard={false}
            width={'20%'}
            style={{ left:'20%',top:300 }}
            onCancel={onCancel}
            bodyStyle={{
                paddingBottom: 40,
            }}
            footer={[
                <Button
                    key="btn-cancel"
                    className=" rect"
                    style={{ minWidth:'40%',marginRight:'10%',fontSize:16 }}
                    onClick={onCancel}
                >{formatMessage({id:'CANCEL'})}
                </Button>,
                <Button
                    key="btn-ok"
                    className="is--gray-dark  rect"
                    style={{ minWidth:'40%' ,background:'#2E63A3',marginRight:'-3%',fontSize:16}}
                    onClick={handleOk}
                >{formatMessage({id:'ROLE_RIGHT_SELECT'})}
                </Button>,
            ]}
        >
            <Table<Enum>
                rowKey="id"
                rowSelection={rowSelection}
                columns={[
                    {
                        dataIndex: 'name',
                        title: formatMessage({id:'ROLE_NAME'}),
                    },
                ]}
                pagination={false}
                dataSource={allRoles}
                limitScroll=''
            />
        </StyledModal>
    );
}

const StyledModal = styled(Modal)`
    .ant-modal-header {
        background: white;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
        font-weight: bold
    }
    .ant-table tbody > tr > td{
        padding: 9px 0px !important;
    }

    .ant-table thead > tr > th{
        padding: 9px 0px !important;
    }
    .ant-modal-body {
        height: 40vh;
        overflow-y: scroll;
    }
    .ant-modal-content {
        width: 97%;
      }
    .ant-modal-close-x{
        color: black;
        font-weight: bold;
        font-size: 22px;
    }
    .ant-modal-title {
        color: rgb(46, 99, 163);
        font-weight: bold;
        font-size: 22px;
    }

    .ant-modal-footer {
        padding: 15px 41px 20px 0;
    }
`;
