/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect, useContext } from 'react';
import Drawer from 'components/drawer/drawer';
import { useIntl } from 'react-intl';
import Label from 'components/label/label';
import styled from 'styled-components';
import moment from 'moment';
import { getTreatment } from 'api/case';
import { GlobalContext } from '../../../../index';
import { color } from '@storybook/theming';
const ModifiedHistory = styled.div`
    margin-bottom: 16px;

    .metadata {
        font-size: 16px;
        margin-bottom: 5px;

        .timestamp {
            color: #0083C1;
        }

        .divider {
            color: #000000;
            font-weight: bold;
            margin: 0 10px;
        }

        .subtitle {
            color: #000000;
            font-weight: bold;
        }
    }

    .content {
        font-size: 14px;
        color: #2E2E2E;
        width: 100%;
        font-weight :normal;
        color: #2E2E2E;
    }
`;
interface Props {
    onClose: Function;
    planName: string;
    startDate: string;
    status: string;
    classNum: number;
    modifiedHistory: any[];
    caseId:number;
    planId:number;
    
}

const PlanInfoDrawer: React.FC<Props> = (props: Props) => {
    const [trainingGoalsToImprove, setTrainingGoalsToImprove] = useState<any[]>([]);
    const [trainingGoalsToImproveOther, setTrainingGoalsToImproveOther] = useState<any[]>([]);
    const [therapist, setTherapist] = useState<string>("");
    const { getCode, nameMapping,getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const { formatMessage } = useIntl();
    useEffect(()=>{
        getTreatment(props.caseId, false).then((res: any)=>{
            const Therapist = res.data.data.find((d: any)=>d.id===props.planId).treatment_course.createName;
            setTherapist(Therapist);
            const trainingGoals= res.data.data.find((d: any)=>d.id===props.planId).treatment_course.trainingGoalsToImproveCode;
            setTrainingGoalsToImprove(trainingGoals)
            const trainingGoalsOther= res.data.data.find((d: any)=>d.id===props.planId).treatment_course.trainingGoalsToImproveOther;
            setTrainingGoalsToImproveOther(trainingGoalsOther)
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }, []);

    const option=trainingGoalsToImprove.map((e:string)=>(
        <div>
            {(nameMapping[e]==="Others")?
            <div  style={{fontSize:'15px',fontWeight:'bold' ,color:'black'} }>{nameMapping[e]+" : "+trainingGoalsToImproveOther}</div>
            :<div  style={{fontSize:'15px',fontWeight:'bold' ,color:'black'} }>{nameMapping[e]}</div>}
        </div>
    ))
     
    const history = props.modifiedHistory.map((record: any) => (
        <ModifiedHistory>
            <div className="metadata">
                <span className="timestamp">{moment(record.createdAt).format('DD/MM/YYYY')}</span>
                <span className="divider"> - </span>
                <span className="subtitle">{record.finishReason?`${record.finishReason.charAt(0).toUpperCase()}${record.finishReason.slice(1)} & ${formatMessage({id:'LOCK'})}`:formatMessage({id:'UNLOCK'}) || '--'}</span>
            </div>
            <div className="content">{record.recordRemark || '--'}</div>
        </ModifiedHistory>
    ))

    return (
        <Drawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='30vw'
            title={props.planName}
            // footer={
            //     <div className="pull--right rect">
            //         <Button className="is--primary rect">
            //             {formatMessage({id:'EDIT'})}
            //         </Button>
            //     </div>}
        >
            <div className="d-flex is--column">
                <Label className='mb-15' name={formatMessage({id:'START_DATE'})} text={(props.startDate)} />
                <Label className='mb-15' name={formatMessage({id:'STATUS'})} text={(props.status)} />
                <Label className='mb-15' name={formatMessage({id:'TREATMENT_NUMBER'})} text={(props.classNum.toString())} />
                <Label className='mb-15' name={formatMessage({id:'THERAPIST'})} text={therapist} />
                <Label className='mb-15' name={formatMessage({id:'TRAING_GOAL'})} text={option} />
                <Label className='mb-15' name={formatMessage({id:'MODIFIED_HISTORY'})} text={history} />
            </div>
        </Drawer>
    )
}

export default PlanInfoDrawer;
