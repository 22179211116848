/** @jsx jsx */
// tslint:disable: quotemark no-implicit-dependencies no-shadowed-variable no-var-requires
import React from 'react';
import { jsx } from '@emotion/core';
import { Form, Row, Col, Radio, Spin } from 'antd';
import cx from 'classnames';
import { PlusOutlined } from '@ant-design/icons'; // tslint:disable-line
import { DrawerProps } from 'antd/es/drawer';
import moment from 'moment';
import { pathOr, omit, length, evolve, isNil } from 'ramda';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import { useIntl } from 'react-intl';
import { Input, Select } from 'components/form';

type DataType = {
    active: boolean
    comment: string
    createDateTime: string
    createId: number
    createdAt: number
    desc: string
    fhirOrganizationId: number
    id: number
    modifyDateTime: string
    modifyId: number
    name: string
    sequence: string
    step: number
    updatedAt: number
    valueType: string
}

type PropsType = {
    data: DataType
    onClose:()=>void
}

export default function ParameterDrawer(props:PropsType) {

    const {formatMessage} = useIntl()
    const [form] = Form.useForm()

    console.log(' [ ParameterDrawer props ] ', props)

    const {
        onClose,
        data
    }= props

    const {
        active:status,
        createId:id = '--',
        name = '--',
        desc:description = '--',
        comment = '--',
        step
    } = data

    return (
        <Drawer
            visible={true}
            maskClosable={true}
            closable={false}
            keyboard={false}
            width={500}
            title={formatMessage({id:'VIEW_PARAMETER'})}
            onClose={onClose}
            footer={
                <div className="pull--right">
                    <Button 
                        className='is--primary rect' 
                        onClick={onClose}
                    >
                        OK
                    </Button>
                </div>
            }
        >
            <h2 className='title'>
                {formatMessage({id:'BASIC_INFO'})}
            </h2>
            <Form layout="vertical" form={form}>
                {/* ID */}
                <Form.Item label='ID'>
                    <strong>{id}</strong>
                </Form.Item>
                {/* Name */}
                <Form.Item label='Name'>
                    <strong>{name}</strong>
                </Form.Item>
                {/* Step */}
                <Form.Item label='Step'>
                    <strong>{step}</strong>
                </Form.Item>
                {/* Description */}
                <Form.Item label='Description'>
                    <strong>{description}</strong>
                </Form.Item>
                {/* Conment */}
                <Form.Item label='Conment'>
                    <strong>{comment}</strong>
                </Form.Item>
                {/* Status */}
                <Form.Item label='Status'>
                    <strong style={{color:'var(--color-primary-light)'}}>
                        { status ? 'Active' : 'DeActive' }
                    </strong>
                </Form.Item>
            </Form>
        </Drawer>
    );
}
