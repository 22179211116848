import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{width?: string}>`
    display: flex;
    flex-direction: row;
    aspect-ratio: 5 / 2;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border-radius: 0.3rem;
    overflow: hidden;
    width: ${props => props.width ?? '45%'};
    margin: 10px 1.5%;

    &.selected {
        border: 3px solid #0083C1;
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px;
        width: 25%;
        color: #FFFFFF;
        justify-content: space-between;

        .month {
            font-size: 12px;
        }

        .date {
            font-size: 12px;
        }

        .weekday {
            font-size: 12px;
        }
    }

    .right {
        padding: 5px 10px;
        width: 75%;
        height: 100%;
        color: #000000;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .hour {
            width: 100%;
            text-align: left;
        }

        .type {
            width: 100%;
            text-align: left;
        }

        .name {
            width: 100%;
            text-align: left;
        }
    }
`;

interface Props {
    id?: number;
    color?: string;
    month?: string;
    date?: string;
    weekday?: string;
    hour?: string;
    type?: string;
    name?: string;
    onClick?: (val: string) => void;
    value?: string;
    selected?: boolean;
    width?: string;
}

const ColorCard: React.FC<Props> = (props: Props) => {

    return (
        <Wrapper width={props.width} onClick={()=>props.onClick?.(props.value || '')} className={props.selected ? 'selected' : ''}>
            <div className="left" style={{ backgroundColor: props.color || '#000000' }}>
                <div>
                    <div className="month">
                        {props.month || '--'}
                    </div>
                    <div className="date">
                        {props.date || '--'}
                    </div>
                </div>
                <div className="weekday">
                    {props.weekday || '--'}
                </div>
            </div>
            <div className="right">
                <div className='d-flex is--column is--space-around' style={{height: '100%'}}>
                    <div className="hour" >
                        {props.hour || ''}
                    </div>
                    <div className="type" >
                        {props.type || ''}
                    </div>
                    <div className="name" >
                        {props.name || ''}
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default ColorCard;
