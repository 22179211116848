import React from 'react';
import { useIntl } from 'react-intl';
import { EditWrapper } from './index';
import { sit_to_stand } from 'components/image';
import { Form, TextArea } from 'components/form';
import { FormTitle } from '../EvaluationForms/EvaluationDrawer';
import TimeInput from 'react-advanced-time-input';

interface Props {
    KE?: boolean;
}

const SitToStandEdit: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();

    return (
        <EditWrapper>
            <div className="left">
                <div className="icon">
                    <img src={sit_to_stand} width='100%' />
                </div>
                <div className="header">
                    {formatMessage({id:'ITEM_SIT_TO_STAND'}, {br: <br/>})}
                </div>
            </div>
            <div className="right">
                <Form.Item label={<FormTitle>{formatMessage({id:'TIME'})}</FormTitle>} name={props.KE ? 'keMakSitStandTime' : 'makSitStandTime'}>
                    <TimeInput 
                        maxHours={99} 
                        style={{
                            width: '60px', 
                            border: 'none', 
                            backgroundColor: '#F3F3F3', 
                            textAlign: 'center', 
                            fontSize: '20px',
                            fontWeight: 'bold'
                        }} 
                    />
                </Form.Item>
                <Form.Item className='width--full' label={<FormTitle>{formatMessage({id:'OBSERVATION'})}</FormTitle>} name={props.KE ? 'keMakSitStandObservation' : 'makSitStandObservation'}>
                    <TextArea rows={4} placeholder='(content)' maxLength={500}/>
                </Form.Item>
            </div>
        </EditWrapper>
    )
}

export default SitToStandEdit;
