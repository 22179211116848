import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ParameterList from './ParameterList';
import ParameterArchive from './ParameterArchive';
import { ParameterProfile } from 'api/parameter';

interface Props {
    caseId: number;
    data: ParameterProfile[];
    caseData: any;
    profileData: any;
    refetch: (params?: { caseId?: number, invalid?: boolean}) => void;
    fetchUserInfoData: (id?: number) => void;
    fetchCaseProfile: (id?: number) => void;
}

export interface PageProps extends Props {
    setPageContent: React.Dispatch<React.SetStateAction<JSX.Element>>;
}

const ParameterTab: React.FC<PageProps> = (props: PageProps) => {
    
    const [pageContent, setPageContent] = useState<JSX.Element>(<div></div>);

    useEffect(()=>{
        props.fetchUserInfoData();
        props.fetchCaseProfile();
        props.refetch({ caseId: props.caseId, invalid: false });
    }, []);

    useEffect(()=>{
        if(pageContent.key === 'archive'){
            setPageContent(<ParameterArchive key='archive' {...props}/>);
        }
        else{
            setPageContent(<ParameterList {...props} />);
        }
    }, [props.data]);

    return withProps(pageContent, {...props, setPageContent});
}

export default ParameterTab;

const withProps = (Component: JSX.Element, props: PageProps) => {
    return React.cloneElement(Component, {...props});
}

export const Wrapper = styled.div`
    width: 100%;
    background-color: #FFFFFF;
    padding: 15px 20px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .Title {
            color: #2E2E2E;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .ant-table {
        .ant-table-body {
            .ant-checkbox-inner::after {
                left: 0!important;
            }
        }
    }
`;
