import React, { useState, useContext, useEffect } from 'react';
import Button from 'components/button/button';
import { Input } from 'antd';
import { GlobalContext } from '../../../../../index';

export interface ActiveRangeValue {
    code: string;
    limit: number;
}

interface Props {
    value?: ActiveRangeValue;
    onChange?: (val: ActiveRangeValue) => void;
    title: string;
    width?: string;
}

const ActiveRangeButton:React.FC<Props> = (props: Props) => {

    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const [limit, setLimit] = useState(props.value?.limit.toString() || '');
    const [code, setCode] = useState(props.value?.code || 'ARM01');

    useEffect(()=>{
        setCode(props.value?.code ?? code);
    }, [props.value?.code]);

    useEffect(()=>{
        setLimit(props.value?.limit.toString() ?? limit);
    }, [props.value?.limit]);

    const handleLimitChange = (val: string) => {
        let newCharIndex: number = val.length - 1;
        
        if(val.length > limit.length){
            for(let i=0; i< limit.length; ++i){
                if(val[i] !== limit[i]){
                    newCharIndex = i;
                    break;
                }
            }
        }

        if(val.length < limit.length){
            for(let i=0; i< val.length; ++i){
                if(val[i] !== limit[i]){
                    newCharIndex = i;
                    break;
                }
            }
        }

        if((val.charCodeAt(newCharIndex)>=48 && val.charCodeAt(newCharIndex)<=57) || val === ''){
            setLimit(val);
        }
    }

    return (
        <div className='d-flex is--column' style={{width: props.width ?? '120px'}}>
            <Button
                className='rect'
                onClick={()=>{
                    if(code==='ARM03'){
                        setCode('ARM01');
                        props.onChange?.({ code: 'ARM01', limit: 0});
                    }
                    else{
                        setCode('ARM03');
                        props.onChange?.({ code: 'ARM03', limit: 0});
                    }
                }}
                style={{
                    backgroundColor: code==='ARM03'?'#5DD197':'#F3F3F3', 
                    color: code==='ARM03'?'#FFFFFF':'#4EBC85',
                    marginBottom: '2px',
                    border: 'none'
                }}
            >
                {props.title}
            </Button>
            {code!=='ARM03'&&
                <div className='d-flex'>
                    {code==='ARM02' ? 
                        <div className='d-flex padding-5' style={{backgroundColor: '#2E63A3', height: '50px'}}>
                            <Button
                                className='rect'
                                onClick={()=>{setCode('ARM01');props.onChange?.({code:'ARM01', limit: 0});}}
                                style={{
                                    backgroundColor:'#2E63A3', 
                                    color: '#FFFFFF', 
                                    width: '100%', 
                                    border: 'none', 
                                    height: '100%',
                                    padding: '0 10px',
                                    transition: 'none'
                                }}
                            >
                                {nameMapping['ARM02']}
                            </Button>
                            <Input 
                                value={limit} 
                                onChange={e=>handleLimitChange(e.target.value)} 
                                onBlur={()=>{props.onChange?.({code: code, limit: (limit === '' ? 0 : parseInt(limit))});if(limit===''){setLimit('0');}}}
                                style={{borderRadius: 0, border: 'none', padding: '0 5px', textAlign: 'center'}}
                                maxLength={4}
                            />
                        </div> : 
                        <div className='d-flex is--space-between width--full' style={{height: '50px'}}>
                            <Button
                                className='rect'
                                onClick={()=>{setCode('ARM01');props.onChange?.({code:'ARM01', limit: 0});}}
                                style={{
                                    backgroundColor: code==='ARM01'?'#5DD197':'#F3F3F3', 
                                    color: code==='ARM01'?'#FFFFFF':'#2E2E2E', 
                                    width: '99%', 
                                    border: 'none', 
                                    height: '50px',
                                    transition: 'none',
                                    padding: 0
                                }}
                            >
                                {nameMapping['ARM01']}
                            </Button>
                            <Button
                                className='rect'
                                onClick={()=>{setCode('ARM02');props.onChange?.({code:'ARM02', limit: 0});}}
                                style={{
                                    backgroundColor: '#F3F3F3', 
                                    color: '#2E2E2E', 
                                    width: '99%', 
                                    border: 'none', 
                                    height: '50px',
                                    transition: 'none',
                                    padding: 0
                                }}
                            >
                                {nameMapping['ARM02']}
                            </Button>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default ActiveRangeButton;
