import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/button/button';
import OptionButton from 'components/optionButton/optionButton';
import { Input } from 'components/form';
import { Modal } from 'antd';
import { icon_export, icon_info_outlined, icon_share_gray, icon_share_green } from 'components/image';
import SummaryList from './SummaryList';
import ExamItemSelectDrawer from './KRA/ExamItemSelectDrawer';
import PackageSelectDrawer from './Package/PackageSelectDrawer';
import { SummaryProps } from './SummaryTab';
import TrainingItemBlock from './TrainingItem/TrainingItemBlock';
import { TrainingPackage, createReport } from 'api/summary';
import { GlobalContext } from '../../../../index';
import moment from 'moment';
import KRABlock from './KRA/KRABlock';
import RoundedButton from 'components/roundedButton/roundedButton';
import { PlusOutlined } from '@ant-design/icons';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const SubheaderButton = styled(Button)`
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
    color: #0083C1;
    background-color: #FFFFFF;
    font-size: 12px;
    padding: 5px;
    display: flex;
    align-items: center;
    transform: scale(0.9);
`;

export interface Video {
    fileId: number;
    id: string;
    sagittalView?: string;
    frontalView?: string;
    sagittalThumbnail?: string;
    frontalThumbnail?: string;
    uploadView?: string;
    uploadThumbnail?: string;
    timestamp: moment.Moment;
    labels?: string[];
    packageName: string;
    className: string;
    upload: boolean;
}

export interface VideoComparison {
    videos: [Video | undefined, Video | undefined];
    memo: string;
}

interface TrainingItem {
    trainingType: string;
    video: [VideoComparison | undefined, VideoComparison | undefined];
}

export interface KraConfig {
    trainingType: string;
    configId: number[];
}

const Wrapper = styled.div`
    width: 100%;
    background-color: white;

    .Title {
        font-size: 1.3rem;
        color: black;
        font-weight: bolder;
    }
`;

interface SummaryEditProps extends SummaryProps {
    treatmentType: string;
}

const SummaryEdit: React.FC<SummaryEditProps> = (props: SummaryEditProps) => {

    const { treatmentType, ...summaryProps } = props;

    const { formatMessage } = useIntl();
    const { nameMapping, getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const [permission] = usePermission('Summary');

    const [reportName, setReportName] = useState<string>();
    const [editName, setEditName] = useState(false);
    const [showPackageSelectDrawer, setShowPackageSelectDrawer] = useState(false);
    const [showKRADrawer, setShowKRADrawer] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);

    const [selectedPackage, setSelectedPackage] = useState<TrainingPackage[]>([]);
    const [trainingItems, setTrainingItems] = useState<TrainingItem[]>(getOptions('TrainingType').filter(d=>d.code!=='TTE07').map(d=>({
        trainingType: d.code,
        video: [undefined, undefined]
    })));
    const [kraConfig, setKraConfig] = useState<KraConfig[]>([]);
    const [comment, setComment] = useState('');
    const [packageShare, setPackageShare] = useState(false);
    const [trainingShare, setTrainingShare] = useState(false);
    const [kraShare, setKraShare] = useState(false);
    const [commentShare, setCommentShare] = useState(false);

    const handleRemovePackage = (id: number) => {
        let newVal = selectedPackage.slice();
        let removePackage = selectedPackage.find(d=>d.id===id);
        if(removePackage){
            newVal.splice(newVal.indexOf(removePackage), 1);
        }
        setSelectedPackage(newVal);
    }

    const handleVideoChange = (trainingType: string, videos: [VideoComparison | undefined, VideoComparison | undefined]) => {
        let newVal = trainingItems.slice();
        let modifiedItem = newVal.find(d=>d.trainingType===trainingType);// by reference
        if(modifiedItem){
            modifiedItem.video = videos;
            setTrainingItems(newVal);
        }
    }

    const handleAddKraConfig = (addItem: KraConfig) => {
        setKraConfig(prv=>prv.concat([addItem]));
    }

    const handleRemoveKraConfig = (removeTrainingType: string) => {
        let removeIndex = kraConfig.findIndex(config=>config.trainingType === removeTrainingType);
        if(removeIndex >= 0){
            let newConfig = kraConfig.slice();
            newConfig.splice(removeIndex, 1);
            setKraConfig(newConfig);
        }
    }
    
    const handleExportSummary = () => {
        createReport({
            personalInfoBasicId: props.caseId,
            reportName: reportName ?? moment().format('DD/MM/YYYY').concat(' Summary'),
            selectedPackages: selectedPackage.map(pack=>pack.id),
            trainingItem: trainingItems.map(item=>
                item.video.map(comparison=>
                    comparison?.videos.map(video=>
                            video ? ({trainingRecordFileId: video?.fileId, memo: comparison.memo}) : undefined
                    )
                ).flat()
            ).flat().filter(d=>d) as {
                trainingRecordFileId: number;
                memo: string;
            }[],
            kraReport: kraConfig.map(kra=>kra.configId).flat(),
            comment: comment,
            packageShare,
            trainingShare,
            kraShare,
            commentShare,
            device: 'pc'
        }).then(res=>{
            Modal.success({
                maskClosable:true,
                title:'Success',
                onCancel: () => {
                    props.setPageContent(<SummaryList {...summaryProps} />)
                }
            });
        }).catch(err=>{
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        });
    }

    const packageButtons = selectedPackage.map(d=>(
        <OptionButton id={d.id} name={d.name} bgc='#2E63A3' style={{height: '40px', border: 'none', margin: '5px'}} onRemove={handleRemovePackage}/>
    ));

    const trainingGoals = selectedPackage.map(d=>{
        let goals = d.trainingGoalsToImproveCode.filter(d=>d!=='PTG11').map(d=>nameMapping[d]);
        if(d.trainingGoalsToImproveCode.some((d: string)=>d==='PTG11')){
            goals.push(`${nameMapping['PTG11']}: ${d.trainingGoalsToImproveOther}`);
        }

        return (
            <div className='d-flex is--column mb-20'>
                <div className='text-16 text--bold mb-10'>{d.name}</div>
                <div className='text-16'>{goals.join('/')}</div>
            </div>
        )
    });

    const trainingItemBlocks = trainingItems.map(d=>(
        <TrainingItemBlock
            edit
            trainingType={d.trainingType} 
            packages={selectedPackage} 
            video={d.video}
            setVideo={videos=>handleVideoChange(d.trainingType, videos)}
        />
    ));

    const kraCharts = kraConfig.map(config=>(
        <div style={{width: '80%'}}>
            <KRABlock edit removeKraConfig={handleRemoveKraConfig} kraConfig={config}/>
        </div>
    ));

    return (
        <Wrapper>
            {showPackageSelectDrawer && <PackageSelectDrawer caseId={props.caseId} treatmentType={props.treatmentType} selectedPackage={selectedPackage.map(d=>d.id)} onOk={packages=>setSelectedPackage(packages)} onClose={()=>setShowPackageSelectDrawer(false)}/>}
            {showKRADrawer && <ExamItemSelectDrawer addKra={handleAddKraConfig} selectedPackage={selectedPackage} disabledItems={kraConfig.map(config=>config.trainingType)} onClose={()=>setShowKRADrawer(false)}/>}
            {showDiscardModal && <DiscardConfirmModal onClose={()=>setShowDiscardModal(false)} setPageContent={props.setPageContent} summaryProps={summaryProps} />}

            {/* header */}
            <div className='d-flex v--center is--space-between width--full padding-15' style={{position: 'sticky', top: 0, zIndex: 1000, backgroundColor: '#FFFFFF'}}>
                <div className='d-flex v--center text-18 text--bold' style={{color: '#909090'}}>
                    {
                        editName ? 
                            <Input autoFocus className='text-18 text--bold' style={{color: '#909090'}} value={reportName ?? 'Add Report Name'} onChange={e=>setReportName(e.target.value)} onBlur={()=>setEditName(false)} /> : 
                            <div className='cursor--pointer' onClick={()=>setEditName(true)}>{reportName || 'Add Report Name'}</div>
                    }
                    <Button
                        type='link'
                        className='ml-10'
                        onClick={()=>setShowPackageSelectDrawer(true)}
                        style={{color: '#0083C1'}}
                    >{formatMessage({id:'SELECT_PACKAGE'})}</Button>
                </div>
                <div className='d-flex v--center'>
                    <Button
                        type='link'
                        className='mr-10'
                        onClick={()=>setShowDiscardModal(true)}
                        style={{color: '#2E2E2E'}}
                    >{formatMessage({id:'CANCEL_UPPERCASE'})}</Button>
                    <Button
                        icon={<img src={icon_export} className='mr-5' />}
                        onClick={handleExportSummary}
                        className='rect'
                        style={{backgroundColor: '#0083C1', color: '#FFFFFF'}}
                    >{formatMessage({id:'EXPORT'})}</Button>
                </div>
            </div>

            {/* content */}
            {selectedPackage.length > 0 && <div className='padding-15'>
                <div className='d-flex v--center is--space-between'>
                    <div className='mb-15 text-24 text--bold'>{formatMessage({id:'SELECTED_PACKAGES'})}</div>
                    {checkIfAvailable(permission, 'Share') &&
                    <Button
                        type='link'
                        style={{
                            color: packageShare ? '#5DD197' : '#909090',
                            transition: 'none'
                        }}
                        icon={<img className='mr-5' src={packageShare ? icon_share_green : icon_share_gray}/>}
                        onClick={()=>setPackageShare(prv=>!prv)}
                    >{formatMessage({id: packageShare ? 'SHARED' : 'SHARE'})}</Button>}
                </div>
                <div>
                    {packageButtons}
                </div>

                <div className='mb-15 mt-20 text-24 text--bold'>{formatMessage({id:'TRAINING_GOAL'})}</div>
                <div>
                    {trainingGoals}
                </div>

                {checkIfAvailable(permission, 'VideoCompare') &&
                <div>
                    <div className='d-flex v--center is--space-between'>
                        <div className='mb-15 mt-20 text-24 text--bold'>{formatMessage({id:'TRAINING_ITEM'})}</div>
                        {checkIfAvailable(permission, 'Share') &&
                        <Button
                            type='link'
                            style={{
                                color: trainingShare ? '#5DD197' : '#909090',
                                transition: 'none'
                            }}
                            icon={<img className='mr-5' src={trainingShare ? icon_share_green : icon_share_gray}/>}
                            onClick={()=>setTrainingShare(prv=>!prv)}
                        >{formatMessage({id: trainingShare ? 'SHARED' : 'SHARE'})}</Button>}
                    </div>
                    <div>
                        {trainingItemBlocks}
                    </div>
                </div>}

                {checkIfAvailable(permission, 'KeeogoReport') && 
                <div>
                    <div className='d-flex v--center is--space-between'>
                        <div className='mb-15 mt-20 d-flex v--center'>
                            <div className='text--bold text-24'>{formatMessage({id:'KRA_REPORT'})}</div>
                            {kraConfig.length > 0 ? <SubheaderButton
                                className='rect ml-10'
                                size='small'
                                icon={<PlusOutlined style={{ fontSize: '18px' }} />}
                                onClick={()=>setShowKRADrawer(true)}
                            >{formatMessage({id:'ADD_ITEM'})}</SubheaderButton> : undefined}
                        </div>
                        {checkIfAvailable(permission, 'Share') &&
                        <Button
                            type='link'
                            style={{
                                color: kraShare ? '#5DD197' : '#909090',
                                transition: 'none'
                            }}
                            icon={<img className='mr-5' src={kraShare ? icon_share_green : icon_share_gray}/>}
                            onClick={()=>setKraShare(prv=>!prv)}
                        >{formatMessage({id: kraShare ? 'SHARED' : 'SHARE'})}</Button>}
                    </div>
                    {kraConfig.length > 0 ? (
                        kraCharts
                    ) : (
                        <RoundedButton icon={<PlusOutlined style={{ fontSize: '18px', color: '#0083C1' }} />} text={formatMessage({id:'ADD_ITEM'})} onClick={()=>setShowKRADrawer(true)}/>
                    )}
                </div>}

                {checkIfAvailable(permission, 'Comment') && 
                <div>
                    <div className='d-flex v--center is--space-between'>
                        <div className='mb-15 mt-20 text-24 text--bold'>{formatMessage({id:'COMMENTS'})}</div>
                        {checkIfAvailable(permission, 'Share') &&
                        <Button
                            type='link'
                            style={{
                                color: commentShare ? '#5DD197' : '#909090',
                                transition: 'none'
                            }}
                            icon={<img className='mr-5' src={commentShare ? icon_share_green : icon_share_gray}/>}
                            onClick={()=>setCommentShare(prv=>!prv)}
                        >{formatMessage({id: commentShare ? 'SHARED' : 'SHARE'})}</Button>}
                    </div>
                    <Input.TextArea rows={4} maxLength={500} value={comment} onChange={e=>setComment(e.target.value)} />
                </div>}
                
            </div>}
        </Wrapper>
    )
}

export default SummaryEdit;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 0.5rem;

        .ant-modal-body {
            padding: 50px 8%;
        }
    }
`;

interface DiscardConfirmProps {
    onClose: () => void;
    setPageContent: React.Dispatch<React.SetStateAction<JSX.Element>>;
    summaryProps: SummaryProps;
}

const DiscardConfirmModal: React.FC<DiscardConfirmProps> = (props: DiscardConfirmProps) => {

    const { formatMessage } = useIntl();

    return (
        <StyledModal
            visible
            centered
            maskClosable
            closable={false}
            width='360px'
            onCancel={props.onClose}
            footer={null}
        >
            <div className='d-flex is--column v--center'>
                <img src={icon_info_outlined} className='mb-30'/>
                <div className='text-24 text--bold mb-30' style={{color: '#47A6D8'}}>
                    {formatMessage({id:'DISCARD_EDIT'})} ?
                </div>
                <Button
                    onClick={props.onClose}
                    style={{
                        backgroundColor: '#47A6D8',
                        width: '90%',
                        height: '40px',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        border: 'none',
                        marginBottom: '20px'
                    }}
                >{formatMessage({id:'CONTINUE_EDIT'})}</Button>
                <Button
                    type='link'
                    style={{
                        color: '#2E2E2E',
                        fontWeight: 'bold'
                    }}
                    onClick={()=>props.setPageContent(<SummaryList {...props.summaryProps} />)}
                >{formatMessage({id:'DISCARD'})}</Button>
            </div>
        </StyledModal>
    )
}
