import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled-base';
import { useIntl } from 'react-intl';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import Label from 'components/label/label';
import { Modal } from 'antd';
import moment from 'moment';
import { getModifiedHistory } from 'api/summary';

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        padding-top: 24px;
        padding-bottom: 24px;
    }
`;

interface History {
    createdAt: string;
    packageName: string[];
}

interface ModifiedHistoryProps {
    onClose: () => void;
    createdAt: number;
    reportId: number;
}

const ModifiedHistoryDrawer: React.FC<ModifiedHistoryProps> = (props: ModifiedHistoryProps) => {

    const { formatMessage } = useIntl();
    const [historyData, setHistoryData] = useState<History[]>([]);

    useEffect(()=>{
        getModifiedHistory(
            props.reportId
        ).then(res=>{
            const history: History[] = [];
            for(let i = 0; i<res.data.data.length; ++i){
                let createDatetime = moment(res.data.data[i].createdAt).format('DD/MM/YYYY HH:mm');
                if(history.findIndex(d=>d.createdAt == createDatetime) < 0){
                    history.push({
                        createdAt: createDatetime,
                        packageName: [`${res.data.data[i].treatmentName} - ${formatMessage({id:'CLASS'})} ${res.data.data[i].classNum.padStart(2, '0')}`]
                    })
                }
                else{
                    history.find(d=>d.createdAt === createDatetime)?.packageName.push(
                        `${res.data.data[i].treatmentName} - ${formatMessage({id:'CLASS'})} ${res.data.data[i].classNum.padStart(2, '0')}`
                    );
                }
            }
            setHistoryData(history);
        }).catch(err=>{
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        })
    }, []);

    const updatedHistory = historyData.map(data=>(
        <div className='mb-15'>
            <div className='text--bold text-16' style={{color: '#000000'}}>{data.createdAt}</div>
            {data.packageName.filter((d, i, arr)=>arr.indexOf(d)===i).map(name=>(
                <div>{name}</div>
            ))}
        </div>
    ));

    return (
        <StyledDrawer
            visible
            closable
            maskClosable
            onClose={props.onClose}
            width='30vw'
            title={formatMessage({id:'MODIFIED_HISTORY'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect text--bold" onClick={props.onClose} >
                        {formatMessage({id:'OK'})}
                    </Button>
                </div>
            }
        >
            <div className='d-flex is--column'>
                <Label name={formatMessage({id:'MODIFIED_HISTORY'})} text={moment(props.createdAt).format('DD/MM/YYYY HH:mm')} />
                <Label name={formatMessage({id:'DATA_UPDATED'})}/>
                {updatedHistory}
            </div>
        </StyledDrawer>
    )
}

export default ModifiedHistoryDrawer;
