import React, { useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Button, Checkbox, Modal } from 'antd';
import { LoadingBar, LoginCheck, ForgotPassword } from '../../components/component';
import logo from '../../assets/image/login/logo_Keeogo_solid-en.svg';
import useVisible from '../../hooks/useVisible';
import styled from 'styled-components';
import SetNewPasswordCard from './setNewPasswordCard';
import ResetPasswordCard from './resetPasswordCard';
import { icon_success } from 'components/image';
import { useHistory } from 'react-router-dom';

const ForgetPassword: React.FC = () => {
    const history = useHistory();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [cardContent, setCardContent] = useState(
        <ResetPasswordCard onSuccess={(username: string, token:string)=>{
            setCardContent(
                <SetNewPasswordCard username={username} passwordToken={token} onSuccess={()=>setShowSuccessModal(true)}/>
            );
        }}/>
    );

    return (
        <Row className='login_wrapper'>
            <LoadingBar />
            {showSuccessModal&&<SuccessModal close={()=>history.push('/login')} />}

            <Col span={2}></Col>
            <Col span={8} className='left_col'>
                <img  className='login_logo' src={logo} />
                <div className='login_copyright'>Copyright © {new Date().getFullYear()} Wistron Corporation <br/>All Rights Reserved</div>
            </Col>
            <Col span={2}></Col>

            <Col span={12} className='right_col'>
                {cardContent}
            </Col>
        </Row>
    );
};

const SuccessModal: React.FC<any> = (props: any) => {
    const { close } = props;
    const StyledModal = styled(Modal)`
        .ant-modal-content {
            border-radius: 1rem;
        }
    `;

    return (
        <StyledModal
            visible={true}
            closable={false}
            footer={null}
            width={400}
        >
            <div style={{
                textAlign: 'center',
                display:'flex',
                flexDirection:'column',
                justifyContent: 'space-around',
                minHeight: '400px'
            }}>

                <img
                    style={{
                        width: '60px',
                        display: 'block',
                        margin: '50px auto 30px auto'
                    }}
                    src={icon_success}
                />

                <div>
                    <h1 className='mb-10 text--lg' style={{color: '#9EC25B', fontWeight: 'bold', fontSize: '30px'}} >
                        <FormattedMessage id='PASSWORD_CHANGED' />
                    </h1>

                    <p style={{padding: '10px 10%'}}>
                        <FormattedMessage id='PASSWORD_CHANGED_DETAIL' />
                    </p>
                </div>

                <div>
                    <Button
                        className='is--plain mt-10 mb-20'
                        onClick={close}
                        style={{width:'75%', height:'40px', backgroundColor: '#9EC25B', color: 'white', fontWeight: 'bold', borderRadius: '1.5rem'}}
                    >
                        <FormattedMessage id='GOOD' /> !
                    </Button>
                </div>

            </div>
        </StyledModal>
    );
};

export default ForgetPassword;
