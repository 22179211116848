import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import QRCode from 'qrcode.react';

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 0.5rem;

        .ant-modal-body {
            padding: 50px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .Title {
                font-size: 24px;
                font-weight: bold;
                color: #0083C1;
                margin-bottom: 30px;
            }
        }
    }
`;

interface Props {
    payload: object;
    title: string;
    onClose: () => void;
}

const QRcodeModal: React.FC<Props> = (props: Props) => {

    const [QRcodePayload, setQRcodePayload] = useState<string>('{}');

    useEffect(()=>{
        setQRcodePayload(
            JSON.stringify(props.payload)
        );
    }, [props.payload]);

    return (
        <StyledModal
            visible
            maskClosable
            closable={false}
            centered
            width='360px'
            onCancel={props.onClose}
            footer={null}
        >
            <div className='Title'>
                {props.title}
            </div>
            <QRCode value={QRcodePayload} size={240} />
        </StyledModal>
    )
}

export default QRcodeModal;
