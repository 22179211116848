import React, { useState, useEffect } from 'react';
import Drawer from 'components/drawer/drawer';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import { Form, Select } from 'components/form';
import DatePicker from 'components/datePicker/datePicker';
import styled from 'styled-components';
import { addTreatment } from 'api/case';
import { getOrgById } from 'api/organizationMgmtApi';
import { apiCaller } from 'service/apiCaller';
import { Modal } from 'antd';
import moment from 'moment';
import TreatmentCard from './TreatmentCard';

const StyledDrawer = styled(Drawer)`
    .treatmentCards{
        width: 80%;

    }

    .typeOfClass {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
            width: 100%;
            aspect-ratio: 1.78;
            object-fit: contain;
            background-color: #F3F3F3;
            border-radius: 0.5rem;
        }
    }

    .remainingClassNum {
        color: #2E63A3;
        font-size: 12px;
    }

    .ant-form-item-label {
        .ant-form-item-required {
            &::before {
                content: '';
            }
        }
    }
`;
interface Props {
    onClose: Function;
    remainingClass: number;
    treatmentType: string;
    refetch: Function;
    planId: number;
    orgId: number;
    numberClass:number;
    title:string;
}

const BookTreatmentDrawer: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY';
    const [businessHours, setBusinessHours] = useState<string[]>([]);
    const [businessDay, setBusinessDay] = useState<string>(moment().format('YYYY/MM/DD'));
    const [orgStartHour, setOrgStartHour] = useState<string>("");

    const timeOptions = businessHours.map((time: string, i: number)=>(
        <Select.Option key={i+1} value={time} >{time}</Select.Option>
    ));
  
    useEffect(()=>{
        getOrgById(
            props.orgId
        ).then((res:any)=>{
            let startTime = moment(res.data.data.businessHourS, 'HH:mm');
            const endTime = moment(res.data.data.businessHourE, 'HH:mm');
            form.setFieldsValue({
                treatmentHour:res.data.data.businessHourS,
                treatmentDate:moment()
            })
            const  hours = [];
            while(startTime<=endTime){
                hours.push(startTime.format('HH:mm'));
                startTime = startTime.add(30, 'minutes');
            }
            setOrgStartHour(res.data.data.businessHourS);
            setBusinessHours(hours);
        }).catch((error: any)=>{
            console.error(error);
            throw(error);
        });
    }, []);
   
    const handleOk = () => {
        form.validateFields().then(values=>{
            const success = (res: any) =>{
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel: ()=>{
                        props.refetch();
                        props.onClose()
                    }
                })
            };

            const reject = (error: any) => {
                console.error(error);
                throw(error);
            }

            apiCaller(
                addTreatment, 
                {
                    ...values,
                    treatmentDate: values.treatmentDate.format('YYYY/MM/DD'),
                    planMasterId: props.planId,
                    device: 'web'
                }, 
                success, 
                reject
            );
        })
    }
    
    const treatmentCards = (
        <TreatmentCard 
            id={0}
            name={"Class  "+((props.numberClass+1<10)?"0"+(props.numberClass+1).toString():(props.numberClass+1).toString())}
            treatmentDate={businessDay}
            treatmentHour={orgStartHour}
            classTypeCode={""}
            treatmentTypeCode={props.title}
            planName={props.title}
            treatmentDetailStatusCode={""}
            treatmentOrg={""}
            onClick={()=>{}}
            memo={""}
            isNext={false}
        />
    );
    return (
        <StyledDrawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='30vw'
            title={formatMessage({id:'BOOK_TREATMENT'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect" onClick={handleOk}>
                        {formatMessage({id:'SCHEDULE'})}
                    </Button>
                </div>
            }
        >
           <div className='treatmentCards'>{treatmentCards}</div>
           <span className='remainingClassNum'>{`${formatMessage({id:'REMAINING_TREATMENT'})}: ${props.remainingClass}`}</span>
            <Form form={form} layout='vertical' className='mt-50' 
                onValuesChange={( changedValues,allValues)=>{
                let  treatmentHour= allValues.treatmentHour
                if(treatmentHour!==undefined){
                    setOrgStartHour(treatmentHour)
                }
            }}>
                {/* <div className="typeOfClass">
                    <img src={ImageMapping.TreatmentType[getCode('TreatmentType', props.treatmentType)]} alt={props.treatmentType} />
                    
                </div> */}
                <Form.Item label={formatMessage({id:'DATE'})} name='treatmentDate' rules={[{required: true, message: `${formatMessage({id:'DATE'})} is required`}]}>
                    <DatePicker bordered={false}  format={dateFormat} style={{width: '100%'}} disabledDate={current=>current<moment().startOf('day')} onChange={(date:any, dateString:any)=>{setBusinessDay(date.format('YYYY/MM/DD'))}}/>
                </Form.Item>
                <Form.Item label={formatMessage({id:'TIME'})} name='treatmentHour' rules={[{required: true, message: `${formatMessage({id:'TIME'})} is required`}]}>
                    <Select bordered={false}  >
                        {timeOptions}
                    </Select>
                </Form.Item>
            </Form>
        </StyledDrawer>
    )
}

export default BookTreatmentDrawer;
