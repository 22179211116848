import React, { useState, useEffect } from 'react';
import SummaryList from './SummaryList';

export interface SummaryProps {
    setPageContent: React.Dispatch<React.SetStateAction<JSX.Element>>;
    caseId: number;
}

const SummaryTab: React.FC<SummaryProps> = (props: SummaryProps) => {
    
    const [pageContent, setPageContent] = useState<JSX.Element>(<div></div>);

    useEffect(()=>{
        setPageContent(<SummaryList {...props} />)
    }, []);

    return withProps(pageContent, {...props, setPageContent});
}

const withProps = (Component: JSX.Element, props: SummaryProps) => {
    return React.cloneElement(Component, {...props});
}

export default SummaryTab;
