import styled from 'styled-components';
import SitToStandView from "./SitToStandView";
import GaitAnalysisView from "./GaitAnalysisView";
import StairAscentView from "./StairAscentView";
import StairDescentView from "./StairDescentView";
import SquattingView from "./SquattingView";
import SingleLegStanceView from "./SingleLegStanceView";

export const ViewWrapper = styled.div`
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1.16;

    .header {
        font-size: 1vw;
        font-weight: bold;
        color: #000000;
        margin-bottom: 20px;
        margin-top: 5px;
        line-height: 1;
    }

    .content {
        display: flex;
        height: calc(100% - 1vw - 25px);

        .left {
            width: 25%;
            display: flex;
            padding-right: 10px;

            .icon {
                width: 100%;
                height: fit-content;
                background-color: #FFFFFF;
                padding: 5px;
                border-radius: 0.5rem;
                overflow: hidden;
                

                img {
                    object-fit: contain;
                    aspect-ratio: 1;
                }
            }
        }

        .right {
            width: 75%;
            display: flex;
            flex-direction: column;
            padding: 0 10px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0px;
            }

            .label {
                display: flex;
                flex-direction: column;

                .name {
                    color: #909090;
                    font-size: 0.7vw;
                    font-weight: 500;
                    line-height: 1;
                }

                .text {
                    font-weight: bold;
                    color: #000000;
                    font-size: 0.7vw;
                    word-break: break-all;

                    &.large {
                        font-size: 1.2vw;
                    }

                    &.blue {
                        color: #0083C1;
                    }
                }
            }
        }
    }
`;

export const EditWrapper = styled.div`
    display: flex;
    padding: 15px 20px 15px 0;
    width: 100%;

    .left {
        width: 20%;
        display: flex;
        flex-direction: column;
        padding-right: 3vw;

        .header {
            font-size: 1vw;
            font-weight: bold;
            color: #000000;
            margin-top: 10px;
            text-align: center;
        }

        .icon {
            width: 100%;
            height: fit-content;
            background-color: #F3F3F3;
            padding: 5px;
            border-radius: 0.5rem;
            overflow: hidden;
            

            img {
                object-fit: contain;
                aspect-ratio: 1;
            }
        }
    }

    .right {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 10px;

        .label {
            display: flex;
            flex-direction: column;

            .name {
                color: #909090;
                font-size: 0.9vw;
                font-weight: 500;
                line-height: 1;
            }

            .text {
                font-weight: bold;
                color: #000000;
                font-size: 0.9vw;

                &.large {
                    font-size: 1.4vw;
                }

                &.blue {
                    color: #0083C1;
                }
            }
        }

        textarea {
            resize: none !important;
            background-color: #F3F3F3;
            border: none;
        }
    }
`;

export { 
    SitToStandView, GaitAnalysisView, StairAscentView, 
    StairDescentView, SquattingView, SingleLegStanceView
};
