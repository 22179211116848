import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

const CircleWrapper = styled.div<{active?: boolean}>`
    border-radius: 2rem;
    width: 30px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    aling-items: center; 
    background-color: ${props => props.active ? '#0083C1' : '#F3F3F3'};
    color: ${props => props.active ? '#FFFFFF' : '#909090'};

    .tooltip {
        .ant-tooltip-content {
            .ant-tooltip-arrow {
                display: none;
            }

            .ant-tooltip-inner {
                width: 240px;
                border-radius: 0.3rem;
                background-color: #FFFFFF;
                padding: 10px;
                box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
            }
        }
    }
`;

const HoverItem = styled.div`
    width: fit-content;
    background-color: #2E63A3;
    color: #FFFFFF;
    font-weight: bold;
    padding: 5px 10px;
    margin: 3px;
    border-radius: 2rem;
    display: inline-block;
    font-size: 12px;
`;

interface Props {
    icon: string | JSX.Element;
    hoverItem: string[];
}

const HoverIcon: React.FC<Props> = (props: Props) => {

    const hoverItems = props.hoverItem.map(d=>(
        <HoverItem>{d}</HoverItem>
    ));

    const content = props.hoverItem.length > 0 ? (
        <Tooltip title={hoverItems} placement='bottomRight' overlayClassName='tooltip' getTooltipContainer={triggerNode=>triggerNode}>
            <CircleWrapper active>
                <div className='d-flex h--center v--center'>{props.icon}</div>
            </CircleWrapper>
        </Tooltip>
    ) : (
        <CircleWrapper>
            <div className='d-flex h--center v--center'>{props.icon}</div>
        </CircleWrapper>
    );

    return content;
}

export default HoverIcon;