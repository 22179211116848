import React, { useState, useEffect, useRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Form, Select, Input, TextArea } from 'components/form';
import { FormInstance } from 'antd/lib/form';
import Switch from 'components/switch/switch';
import { Row, Col, Checkbox, Radio, Modal } from 'antd';
import DatePicker from 'components/datePicker/datePicker';
import styled from 'styled-components';
import moment from 'moment';
import ModifiedRecord from '../ModifiedRecord';
import { GlobalContext } from '../../../../index';
import { InfoCircleFilled } from '@ant-design/icons';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var reg = /^([0-9]*)$/;
const Wrapper = styled.div`

    .form_title {
        font-size: 20px;
        font-weight: bold;
        margin-top: -15px;
    }
`;

const StyledRadioButton = styled(Radio.Button)`
    font-weight: bold;

    &:hover {
        color: #2E63A3;
    }

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3 !important;
        border-color: #2E63A3 !important;

        &::before {
            display: none;
        }
    }
`;

interface Props {
    data: any;
    form: FormInstance;
}

export const UserInfoForm: React.FC<Props> = ({data, form}: Props) => {

    const { formatMessage } = useIntl();
    const { optionType, selectOption, getCode} = useContext(GlobalContext).dropdownOptionCtx;
    const[phone,setPhone]=useState<string>("");

    useEffect(()=>{
        const occuptionStatus_former = data.occuptionStatus?.split(', ')[0];
        const occuptionStatus_latter = data.occuptionStatus?.split(', ')[1] || '';
        form.setFieldsValue({
            caseTypeCode: getCode('CaseType', data.caseTypeCode) || '',
            name: data.name,
            genderCode: getCode('Gender', data.genderCode) || '',
            birthday: data.birthday?moment(data.birthday):'',
            age: data.birthday?moment().diff(data.birthday, 'year'):'',
            icNumber: data.icNumber,
            caseCode: data.caseCode,
            phone: data.phone,
            emergencyContactPhone: data.emergencyContactPhone,
            emergencyContactName: data.emergencyContactName,
            emergencyContactRelationship: data.emergencyContactRelationship,
            telecomEmail: data.telecomEmail,
            address: data.address,
            caseClose: data.caseClose,
            occuptionStatus_former: getCode('OccuptionStatus', occuptionStatus_former) || '',
            occuptionStatus_latter
        })
    }, []);

    const onOccupationFormerEdit = (value: any) =>{
        const latter = form.getFieldValue('occuptionStatus_latter');
        form.setFieldsValue({
            occuptionStatus: (value || '')+(latter?', '+latter:'')
        });
    }

    const onOccupationLatterEdit = (e: any) =>{
        const former = form.getFieldValue('occuptionStatus_former');
        form.setFieldsValue({
            occuptionStatus: former+', '+e.target.value
        });
    }
    
    return (
        <Wrapper>
            <div className='form_title'><b>{formatMessage({id:'USER_INFO'})}</b></div>
            <Form form={form} layout='vertical'
             onValuesChange={( changedValues,allValues)=>{
                let phoneInpute = allValues.phone
                if(phoneInpute === ""){
                    setPhone(phoneInpute)
                    form.setFieldsValue({ phone:""})
                }else if(!reg.test(phoneInpute)){
                    form.setFieldsValue({phone:phone})
                }else if(reg.test(phoneInpute)){
                    form.setFieldsValue({ phone:allValues.phone })
                    setPhone(phoneInpute)
                }
            }}
            >
            <Row gutter={24}>
                <Col span={4}>
                    <Form.Item label={formatMessage({id:'TYPE'})} name='caseTypeCode' rules={[{required: true, message: `${formatMessage({id:'TYPE'})} is required`}]} >
                        <Select
                            allowClear={false}
                            bordered={false}
                        >
                            {selectOption[optionType.indexOf('CaseType')]}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={20}></Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={formatMessage({id:'NAME'})} name='name' rules={[{required: true, message: `${formatMessage({id:'NAME'})} is required`}]}>
                        <Input bordered={false} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label={formatMessage({id:'GENDER'})} name='genderCode' rules={[{required: true, message: `${formatMessage({id:'GENDER'})} is required`}]}>
                        <Select
                            allowClear={true}
                            bordered={false}
                        >
                            {selectOption[optionType.indexOf('Gender')]}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={formatMessage({id:'BIRTHDAY'})} name='birthday'>
                        <DatePicker 
                            bordered={false} 
                            className="width--full" 
                            format="DD/MM/YYYY" 
                            onChange={date=>form.setFieldsValue({age: date?moment().diff(date, 'year'):''})}
                            disabledDate={current=>current>moment().endOf('day')}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={formatMessage({id:'AGE'})} name='age'>
                        <Input bordered={false} disabled={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={formatMessage({id:'IC_NUMBER'})} name='icNumber'>
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={formatMessage({id:'CASECODE'})} name='caseCode'>
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label={formatMessage({id:'OCCUPATION'})} name='occuptionStatus_former'>
                        <Select
                            allowClear={true}
                            bordered={false}
                            onChange={onOccupationFormerEdit}
                        >
                            {selectOption[optionType.indexOf('OccuptionStatus')]}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label=' ' name='occuptionStatus_latter'>
                        <Input bordered={false} onChange={onOccupationLatterEdit}/>
                    </Form.Item>
                </Col>
                <Form.Item name='occuptionStatus'><span></span></Form.Item>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item label={formatMessage({id:'TEL'})} name='phone' rules={[ {required: true, message: `${formatMessage({id:'TEL'})} is required`} ]}>
                        <StyledInput  bordered={false}/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={`${formatMessage({id:'EMERGENCY_CONTACT'})}-${formatMessage({id:'TEL'})}`} name='emergencyContactPhone'>
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={`-${formatMessage({id:'NAME'})}`} name='emergencyContactName'>
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label={`-${formatMessage({id:'RELATIONSHIP'})}`} name='emergencyContactRelationship'>
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
            </Row>
            <Row gutter={24}>
                <Col span={10}>
                    <Form.Item label={formatMessage({id:'MAIL'})} name='telecomEmail' rules={[
                                        {
                                            required: true,
                                            message: `${formatMessage({id:'MAIL'})} is required`,
                                        },
                                        {
                                            validator: (_: any, value: any) => {
                                                if (value && !emailRegex.test(value)) {
                                                    return Promise.reject(
                                                        formatMessage({id:'INVALID_MAIL'})
                                                    );
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}>
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
                <Col span={14}>
                    <Form.Item label={formatMessage({id:'ADDRESS'})} name='address'>
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label={formatMessage({id:'DISCHARGED'})} name='caseClose'>
                <Radio.Group buttonStyle='solid'>
                    <StyledRadioButton value={false}>{formatMessage({id:'NO'})}</StyledRadioButton>
                    <StyledRadioButton value={true}>{formatMessage({id:'YES'})}</StyledRadioButton>
                </Radio.Group>
            </Form.Item>
            </Form>
        </Wrapper>
    )
}

const StyledInput = styled(Input)`
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }    
`;

const StyledModal = styled(Modal)`
    .ant-modal-body {
        display: flex;
        flex-direction: column;

        .title {
            margin-bottom: 20px;
        }

        p {
            font-size: 12px;
            color: #000000;
        }

        .up {
            width: 100%;
        }

        .down {
            display: flex;
            flex-direction: row;

            .content {
                display: flex;
                flex-direction: column;

                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                }
            }

            .left {
                width: 50%;
            }

            .right {
                width: 50%;
            }
        }
    }
`;

interface diagnosis {
    display: boolean;
    title: string;
    optionCode: string;
    value: string;
}

export const DiagnosisForm: React.FC<Props> = ({data, form}: Props) => {

    const { optionType, selectOption, getCode} = useContext(GlobalContext).dropdownOptionCtx;
    const { formatMessage } = useIntl();
    const [mainDiagnosis1, setMainDiagnosis1] = useState<string>('');
    const [mainDiagnosis2, setMainDiagnosis2] = useState<diagnosis>({
        display:false,
        title: ' ',
        optionCode: '',
        value: ''
    });
    const [mainDiagnosis3, setMainDiagnosis3] = useState<diagnosis>({
        display: false,
        title: ' ',
        optionCode: '',
        value: ''
    });
    const [mainDiagnosis4, setMainDiagnosis4] = useState<diagnosis>({
        display: false,
        title: ' ',
        optionCode: '',
        value: ''
    });
    const [showBrunnstormStage, setShowBrunnstormStage] = useState(false);
    const [showBrunnstormStageModal, setShowBrunnstormStageModal] = useState(false);
    const initialRender1 = useRef(true);
    const initialRender2 = useRef(true);
    useEffect(()=>{
        switch(getCode('MainDiagnosis', data.mainDiagnosis1)){
            case 'MAI01':
                setMainDiagnosis2({value: getCode(['Neurological', 'Musculoskeleta'], data.mainDiagnosis2), display: true, title: ' ', optionCode: 'Neurological'});
                break;
            case 'MAI02':
                setMainDiagnosis2({value: getCode(['Neurological', 'Musculoskeleta'], data.mainDiagnosis2), display: true, title: ' ', optionCode: 'Musculoskeleta'});
                break;
            case 'MAI03':
                setMainDiagnosis2({...mainDiagnosis2, display: false});
                break;
            default:

        }

        setTimeout(()=>form.setFieldsValue({
            dateOfOnset: data.dateOfOnset?moment(data.dateOfOnset):'',
            mainDiagnosis1: getCode('MainDiagnosis', data.mainDiagnosis1) || '',
            mainDiagnosis2: getCode(['Neurological', 'Musculoskeleta'], data.mainDiagnosis2) || '',
            mainDiagnosis3: getCode(['CerebrovascularAccident','ScoreInfo','SpinalCordInjury','MeniscalInjury','LigamentInjury','Amputation'], data.mainDiagnosis3) || '',
            mainDiagnosis4: (getCode('Hemiplegia', data.mainDiagnosis4) || data.mainDiagnosis4) || '',
            brunnstormStageUL: data.brunnstormStageUL,
            brunnstormStageLL: data.brunnstormStageLL,
            mainDiagnosisRemark: data.mainDiagnosisRemark,
            mainDiagnosisOtherDescription: data.mainDiagnosisOtherDescription
        }), 200);
    }, []);

    useEffect(()=>{
        if(initialRender1.current){
            initialRender1.current = false;
        }
        else{
            switch(mainDiagnosis1){
                case 'MAI01':
                    setMainDiagnosis2({value: '', display: true, title: ' ', optionCode: 'Neurological'});
                    form.setFieldsValue({
                        mainDiagnosis2: '',
                        mainDiagnosis3: '',
                        mainDiagnosis4: ''
                    });
                    setMainDiagnosis3({...mainDiagnosis3, display: false});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                case 'MAI02':
                    setMainDiagnosis2({value: '', display: true, title: ' ', optionCode: 'Musculoskeleta'});
                    form.setFieldsValue({
                        mainDiagnosis2: '',
                        mainDiagnosis3: '',
                        mainDiagnosis4: ''
                    });
                    setMainDiagnosis3({...mainDiagnosis3, display: false});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                case 'MAI03':
                    setMainDiagnosis2({...mainDiagnosis2, value: '', display: false});
                    form.setFieldsValue({
                        mainDiagnosis2: '',
                        mainDiagnosis3: '',
                        mainDiagnosis4: ''
                    });
                    setMainDiagnosis3({...mainDiagnosis3, display: false});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                default:
                    form.setFieldsValue({
                        mainDiagnosis1: '',
                        mainDiagnosis2: '',
                        mainDiagnosis3: '',
                        mainDiagnosis4: ''
                    });
                    setMainDiagnosis2({...mainDiagnosis2, display: false});
                    setMainDiagnosis3({...mainDiagnosis3, display: false});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
            }
        }
    }, [mainDiagnosis1]);

    useEffect(()=>{
        if(initialRender2.current){
            initialRender2.current = false;
        }
        else{
            form.setFieldsValue({
                mainDiagnosis3: '',
                mainDiagnosis4: ''
            });
            if(mainDiagnosis2.value!=='NEU01'){
                form.setFieldsValue({
                    brunnstormStageUL: -1,
                    brunnstormStageLL: -1
                });
                setShowBrunnstormStage(false);
            }
            switch(mainDiagnosis2.value){
                case 'NEU01':
                    setShowBrunnstormStage(true);
                    setMainDiagnosis3({...mainDiagnosis3, display: true, title: ' ', optionCode: 'CerebrovascularAccident'});
                    setMainDiagnosis4({...mainDiagnosis4, display: true, title: ' ', optionCode: 'Hemiplegia'});
                    break;
                case 'NEU02':
                    setMainDiagnosis3({...mainDiagnosis3, display: true, title: formatMessage({id:'H&Y'}), optionCode: 'ScoreInfo'});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                case 'NEU03':
                    setMainDiagnosis3({...mainDiagnosis3, display: true, title: ' ', optionCode: 'SpinalCordInjury'});
                    setMainDiagnosis4({...mainDiagnosis4, display: true, title: formatMessage({id:'ASIA_SCORE'}), optionCode: ''});
                    break;
                case 'NEU04':
                    setMainDiagnosis3({...mainDiagnosis3, display: true, title: formatMessage({id:'LOCF_SCORE'}), optionCode: 'ScoreInfo'});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                case 'NEU05':
                    setMainDiagnosis3({...mainDiagnosis3, display: true, title: formatMessage({id:'EDSS_SCORE'}), optionCode: 'ScoreInfo'});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                case 'MUS01':
                    setMainDiagnosis3({...mainDiagnosis3, display: false});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                case 'MUS02':
                    setMainDiagnosis3({...mainDiagnosis3, display: false});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                case 'MUS03':
                    setMainDiagnosis3({...mainDiagnosis3, display: true, title: ' ', optionCode: 'MeniscalInjury'});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                case 'MUS04':
                    setMainDiagnosis3({...mainDiagnosis3, display: true, title: ' ', optionCode: 'LigamentInjury'});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                case 'MUS05':
                    setMainDiagnosis3({...mainDiagnosis3, display: true, title: ' ', optionCode: 'Amputation'});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
                    break;
                default:
                    setMainDiagnosis3({...mainDiagnosis3, display: false});
                    setMainDiagnosis4({...mainDiagnosis4, display: false});
            }
        }
    }, [mainDiagnosis2.value]);

    const BrunnstormStageModal = (
        <StyledModal width='60%' visible={true} centered={true} footer={null} onCancel={()=>setShowBrunnstormStageModal(false)}>
            <div className="up">
                <div className="title">{formatMessage({id:'BRUNNSTROM_STAGE'})}</div>
                <p>
                    {formatMessage({id:'BRUNNSTROM_DESC'})}
                </p>
            </div>
            <div className="down">
                <div className="left">
                    <div className="title">{formatMessage({id:'UPPER_LIMBS'})}</div>
                    <div className="content">
                        <div className="item">
                            <CircleLabel>I</CircleLabel>
                            <p>{formatMessage({id:'UPPER_STAGE_1'})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>II</CircleLabel>
                            <p>{formatMessage({id:'UPPER_STAGE_2'})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>III</CircleLabel>
                            <p>{formatMessage({id:'UPPER_STAGE_3'})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>IV</CircleLabel>
                            <p>{formatMessage({id:'UPPER_STAGE_4'}, {br:<br/>})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>V</CircleLabel>
                            <p>{formatMessage({id:'UPPER_STAGE_5'}, {br:<br/>})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>VI</CircleLabel>
                            <p>{formatMessage({id:'UPPER_STAGE_6'}, {br:<br/>})}</p>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="title">{formatMessage({id:'LOWER_LIMBS'})}</div>
                    <div className="content">
                    <div className="item">
                            <CircleLabel>I</CircleLabel>
                            <p>{formatMessage({id:'LOWER_STAGE_1'})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>II</CircleLabel>
                            <p>{formatMessage({id:'LOWER_STAGE_2'})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>III</CircleLabel>
                            <p>{formatMessage({id:'LOWER_STAGE_3'})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>IV</CircleLabel>
                            <p>{formatMessage({id:'LOWER_STAGE_4'}, {br:<br/>})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>V</CircleLabel>
                            <p>{formatMessage({id:'LOWER_STAGE_5'}, {br:<br/>})}</p>
                        </div>
                        <div className="item">
                            <CircleLabel>VI</CircleLabel>
                            <p>{formatMessage({id:'LOWER_STAGE_6'}, {br:<br/>})}</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyledModal>
    )

    enum stage {
        'lt_stage_1',
        'lt_stage_2',
        'lt_stage_3',
        'lt_stage_4',
        'lt_stage_5',
        'lt_stage_6',
        'rt_stage_1',
        'rt_stage_2',
        'rt_stage_3',
        'rt_stage_4',
        'rt_stage_5',
        'rt_stage_6'
    }

    const brunnstormRadioGroup = (
        <Radio.Group buttonStyle='solid' style={{display: 'flex'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <span>{formatMessage({id:'LT'})}</span>
                <div>
                    <StyledRadioButton value={stage.lt_stage_1}>I</StyledRadioButton>
                    <StyledRadioButton value={stage.lt_stage_2}>II</StyledRadioButton>
                    <StyledRadioButton value={stage.lt_stage_3}>III</StyledRadioButton>
                    <StyledRadioButton value={stage.lt_stage_4}>IV</StyledRadioButton>
                    <StyledRadioButton value={stage.lt_stage_5}>V</StyledRadioButton>
                    <StyledRadioButton value={stage.lt_stage_6}>VI</StyledRadioButton>
                </div>
            </div>
            <span style={{width:'20px'}}></span>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <span>{formatMessage({id:'RT'})}</span>
                <div>
                    <StyledRadioButton value={stage.rt_stage_1}>I</StyledRadioButton>
                    <StyledRadioButton value={stage.rt_stage_2}>II</StyledRadioButton>
                    <StyledRadioButton value={stage.rt_stage_3}>III</StyledRadioButton>
                    <StyledRadioButton value={stage.rt_stage_4}>IV</StyledRadioButton>
                    <StyledRadioButton value={stage.rt_stage_5}>V</StyledRadioButton>
                    <StyledRadioButton value={stage.rt_stage_6}>VI</StyledRadioButton>
                </div>
            </div>
        </Radio.Group>
    );


    return (
        <Wrapper>
            {showBrunnstormStageModal&&BrunnstormStageModal}
            <div className='form_title'><b>{formatMessage({id:'MAIN_DIAGNOSIS'})}</b></div>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item style={{marginTop: '20px'}}    label={formatMessage({id:'ONSET_DATE'})} name='dateOfOnset' >
                        <DatePicker bordered={false} className="width--full" format="DD/MM/YYYY"/>
                    </Form.Item>
                </Col>
                <Col span={18}></Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={formatMessage({id:'MAIN_DIAGNOSIS'})} name='mainDiagnosis1'>
                        <Select
                            allowClear={true}
                            bordered={false}
                            onChange={(e: string)=>setMainDiagnosis1(e)}
                        >
                            {selectOption[optionType.indexOf('MainDiagnosis')]}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    {mainDiagnosis2.display&&
                    <Form.Item 
                        label={mainDiagnosis2.title} 
                        name='mainDiagnosis2' 
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_: any, value: any) {
                                    const diag1 = getFieldValue('mainDiagnosis1');
                                    if((diag1 === 'MAI01' || diag1 === 'MAI02') && !value){
                                        return Promise.reject(`${formatMessage({id:'DIAGNOSIS'})} is required`);
                                    }
                                    return Promise.resolve();
                                }
                            })
                        ]}
                    >
                        <Select
                            allowClear={false}
                            bordered={false}
                            onChange={(e: string)=>setMainDiagnosis2({...mainDiagnosis2, value: e})}
                        >
                            {selectOption[optionType.indexOf(mainDiagnosis2.optionCode)]}
                        </Select>
                    </Form.Item>}
                </Col>
                <Col span={8}>
                    {mainDiagnosis3.display&&
                    <Form.Item 
                        label={mainDiagnosis3.title} 
                        name='mainDiagnosis3'
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_: any, value: any) {
                                    const diag1 = getFieldValue('mainDiagnosis1');
                                    const diag2 = getFieldValue('mainDiagnosis2');
                                    if((diag1 === 'MAI01' || diag2 === 'MUS03' || diag2 === 'MUS04' || diag2 === 'MUS05') && !value){
                                        return Promise.reject(`${formatMessage({id:'DIAGNOSIS'})} is required`);
                                    }
                                    return Promise.resolve();
                                }
                            })
                        ]}
                    >
                        <Select
                            allowClear={false}
                            bordered={false}
                            onChange={(e: string)=>setMainDiagnosis3({...mainDiagnosis3, value: e})}
                        >
                            {selectOption[optionType.indexOf(mainDiagnosis3.optionCode)]}
                        </Select>
                    </Form.Item>}
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    {mainDiagnosis4.display&&
                    <Form.Item 
                        label={mainDiagnosis4.title} 
                        name='mainDiagnosis4'
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_: any, value: any) {
                                    const diag2 = getFieldValue('mainDiagnosis2');
                                    if((diag2 === 'NEU01' || diag2 === 'NEU03') && !value){
                                        return Promise.reject(`${formatMessage({id:'DIAGNOSIS'})} is required`);
                                    }
                                    return Promise.resolve();
                                }
                            })
                        ]}
                    >
                        {mainDiagnosis4.optionCode.length>0?
                        <Select
                            allowClear={false}
                            bordered={false}
                            onChange={(e: string)=>setMainDiagnosis4({...mainDiagnosis4, value: e})}
                        >
                            {selectOption[optionType.indexOf(mainDiagnosis4.optionCode)]}
                        </Select>:<Input bordered={false} />}
                    </Form.Item>}
                </Col>
                <Col span={16}></Col>
            </Row>
            <Form.Item noStyle name='mainDiagnosis2'><span></span></Form.Item>
            <Form.Item noStyle name='mainDiagnosis3'><span></span></Form.Item>
            <Form.Item noStyle name='mainDiagnosis4'><span></span></Form.Item>
            {showBrunnstormStage && <div>
                {formatMessage({id:'BRUNNSTROM_STAGE'})}
                <InfoCircleFilled style={{fontSize: '18px', marginLeft: '10px'}} onClick={()=>setShowBrunnstormStageModal(true)}/>
                <Row>{formatMessage({id:'UPPER_LIMBS'})}</Row>
                <Row gutter={24}>
                    <Col span={20}>
                        <Form.Item name='brunnstormStageUL'>
                            {brunnstormRadioGroup}
                        </Form.Item>
                    </Col>
                    <Col span={4}></Col>
                </Row>
                <Row>{formatMessage({id:'LOWER_LIMBS'})}</Row>
                <Row gutter={24}>
                    <Col span={20}>
                        <Form.Item name='brunnstormStageLL'>
                            {brunnstormRadioGroup}
                        </Form.Item>
                    </Col>
                    <Col span={4}></Col>
                </Row>
            </div>}
            <Form.Item noStyle name='brunnstormStageUL'><span></span></Form.Item>
            <Form.Item noStyle name='brunnstormStageLL'><span></span></Form.Item>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name='mainDiagnosisOtherDescription'>
                        <TextArea rows={4}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={formatMessage({id:'REHABIT_HISTORY'})} name='mainDiagnosisRemark'>
                        <TextArea rows={4}/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item noStyle name='caseTypeCode'><span></span></Form.Item>
            <Form.Item noStyle name='name'><span></span></Form.Item>
            <Form.Item noStyle name='genderCode'><span></span></Form.Item>
            <Form.Item noStyle name='phone'><span></span></Form.Item>
            <Form.Item noStyle name='telecomEmail'><span></span></Form.Item>
            <Form.Item noStyle name='caseClose'><span></span></Form.Item>
        </Wrapper>
    )
}

export const BackgroundForm: React.FC<Props> = ({data, form}: Props) => {

    const { optionType, selectOption, getCode} = useContext(GlobalContext).dropdownOptionCtx;
    const { formatMessage } = useIntl();
    const [caregiverOther, setCaregiverOther] = useState('');
    const [floorDisabled, setFloorDisabled] = useState(getCode('HomeEnvironmentStairs', data.homeEnvironment)=='HOM01');

    useEffect(()=>{
        const caregiverOptions = [
            formatMessage({id:'SPOUSE'}),
            formatMessage({id:'CHILDREN'}),
            formatMessage({id:'SIBLINGS'}),
            formatMessage({id:'CARE_TAKER'}),
            formatMessage({id:'NURSE'})
        ];
        const reg = new RegExp(',(?!(\\w|,)*")');
        const other = data.mainCaregiver?.split(reg).filter((d: string)=>caregiverOptions.indexOf(d)<0&&d);
        setCaregiverOther(other?.length>0?other[0].substring(8).replaceAll('"',''):'');
        form.setFieldsValue({
            startDate: data.startDate?moment(data.startDate):'',
            keeogoTrainingfirst: data.keeogoTrainingfirst?moment(data.keeogoTrainingfirst):'',
            keeogoTrainingTime: data.keeogoTrainingTime,
            homeEnvironment: getCode('HomeEnvironmentStairs', data.homeEnvironment) || '',
            floor: getCode('HomeEnvironmentStairs', data.homeEnvironment)=='HOM01'?'':data.floor,
            toiletCondition: getCode('ToiletCondition', data.toiletCondition) || '',
            mainCaregiver: data.mainCaregiver?.split(reg).filter((d: string)=>d)
        })
    }, []);

    const onOthersEdit: React.ChangeEventHandler<HTMLInputElement> = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newVal = form.getFieldValue('mainCaregiver')
                        .filter((d: string)=>!d.startsWith(formatMessage({id:'OTHERS'})))
                        .concat([`${formatMessage({id:'OTHERS'})}: "${e.target.value}"`]);
        form.setFieldsValue({mainCaregiver: newVal});
        setCaregiverOther(e.target.value);
    }

    return (
        <Wrapper>
            <div className='form_title'><b>{formatMessage({id:'BACKGROUND'})}</b></div>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label={formatMessage({id:'REHABIT_START'})} name='startDate'>
                        <DatePicker bordered={false} className="width--full" format="DD/MM/YYYY"/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={formatMessage({id:'FIRST_TRAINING'})} name='keeogoTrainingfirst'>
                        <DatePicker bordered={false} className="width--full" format="MM/YYYY" picker="month"
                         onChange={date=>form.setFieldsValue({keeogoTrainingTime: date?moment().diff(date, 'month')+"  Month":''})}
                         disabledDate={current=>current>moment().endOf('day')}
                         />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={formatMessage({id:'UNTIL_FIRST_TRAINING'})} name='keeogoTrainingTime'>
                        <Input bordered={false} disabled={true} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item label={formatMessage({id:'HOME_ENV'})} name='homeEnvironment'>
                        <Select
                            allowClear={true}
                            bordered={false}
                            onChange={v=>{
                                form.setFieldsValue({floor: ''});
                                if(v=='HOM01'){// No Stairs
                                    setFloorDisabled(true);
                                }
                                else{
                                    setFloorDisabled(false);
                                }
                            }}
                        >
                            {selectOption[optionType.indexOf('HomeEnvironmentStairs')]}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label={formatMessage({id:'FLOORS'})} name='floor'>
                        <Input bordered={false} disabled={floorDisabled}/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={formatMessage({id:'TOILET_CONDITION'})} name='toiletCondition'>
                        <Select
                            allowClear={true}
                            bordered={false}
                        >
                            {selectOption[optionType.indexOf('ToiletCondition')]}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}></Col>
            </Row>
            <Form.Item label={formatMessage({id:'MAIN_CAREGIVER'})} name='mainCaregiver' >
                <Checkbox.Group style={{width: '100%'}}>
                    <Row gutter={24}>
                        <Col span={4}><Checkbox value={formatMessage({id:'SPOUSE'})}>{formatMessage({id:'SPOUSE'})}</Checkbox></Col>
                        <Col span={5}><Checkbox value={formatMessage({id:'CHILDREN'})}>{formatMessage({id:'CHILDREN'})}</Checkbox></Col>
                        <Col span={5}><Checkbox value={formatMessage({id:'SIBLINGS'})}>{formatMessage({id:'SIBLINGS'})}</Checkbox></Col>
                        <Col span={6}><Checkbox value={formatMessage({id:'CARE_TAKER'})}>{formatMessage({id:'CARE_TAKER'})}</Checkbox></Col>
                        <Col span={4}><Checkbox value={formatMessage({id:'NURSE'})}>{formatMessage({id:'NURSE'})}</Checkbox></Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Checkbox value={`${formatMessage({id:'OTHERS'})}: "${caregiverOther}"`}>{formatMessage({id:'OTHERS'})}</Checkbox>
                            <Input bordered={false} onChange={onOthersEdit} style={{width:'200px'}} value={caregiverOther} />
                        </Col>
                    </Row>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item noStyle name='caseTypeCode'><span></span></Form.Item>
            <Form.Item noStyle name='name'><span></span></Form.Item>
            <Form.Item noStyle name='genderCode'><span></span></Form.Item>
            <Form.Item noStyle name='phone'><span></span></Form.Item>
            <Form.Item noStyle name='telecomEmail'><span></span></Form.Item>
            <Form.Item noStyle name='caseClose'><span></span></Form.Item>
            <Form.Item noStyle name='precautions'><span></span></Form.Item>
        </Wrapper>
    )
}

export const DescriptionForm: React.FC<Props> = ({data, form}: Props) => {

    const { formatMessage } = useIntl();
    const [poorSkin, setPoorSkin] = useState('');
    const [others, setOthers] = useState('');

    useEffect(()=>{
        const precautionsOptions = [
            formatMessage({id:'DM'}),
            formatMessage({id:'PAOD'}),
            formatMessage({id:'HTN'}),
            formatMessage({id:'CHF'}),
            formatMessage({id:'AF'}),
            formatMessage({id:'AMI'}),
            formatMessage({id:'DVT'}),
            formatMessage({id:'COPD'}),
            formatMessage({id:'POST_COVID'})
        ];
        const reg = new RegExp(',(?!(\\w|,)*")');
        const additionalValues = data.precautions?.split(reg).filter((d: string)=>precautionsOptions.indexOf(d)<0&&d);
        const poorSkinValue = additionalValues?.filter((d: string)=>d.startsWith(formatMessage({id:'POOR_SKIN'})));
        const otherValue = additionalValues?.filter((d: string)=>d.startsWith(formatMessage({id:'OTHERS'})));
        setPoorSkin(poorSkinValue?.length>0?poorSkinValue[0].substring(21).replaceAll('"', ''):'');
        setOthers(otherValue?.length>0?otherValue[0].substring(8).replaceAll('"', ''):'');
        form.setFieldsValue({
            precautions: data.precautions?.split(reg).filter((d: string)=>d),
            patientExpectations: data.patientExpectations,
            descriptionRemark: data.descriptionRemark
        })
    }, []);

    const onPoorSkinEdit: React.ChangeEventHandler<HTMLInputElement> = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newVal = form.getFieldValue('precautions')
                        .filter((d:string)=>!d.startsWith(formatMessage({id:'POOR_SKIN'})))
                        .concat([`${formatMessage({id:'POOR_SKIN'})}: "${e.target.value}"`]);
        form.setFieldsValue({precautions: newVal});
        setPoorSkin(e.target.value);
    }

    const onOthersEdit: React.ChangeEventHandler<HTMLInputElement> = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newVal = form.getFieldValue('precautions')
                        .filter((d:string)=>!d.startsWith(formatMessage({id:'OTHERS'})))
                        .concat([`${formatMessage({id:'OTHERS'})}: "${e.target.value}"`]);
        form.setFieldsValue({precautions: newVal});
        setOthers(e.target.value);
    }

    return (
        <Wrapper>
            <div className='form_title'><b>{formatMessage({id:'DESCRIPTION'})}</b></div>
            <Form.Item label={formatMessage({id:'PRECAUTIONS'})} name='precautions'>
                <Checkbox.Group style={{width: '100%'}}>
                    <Row gutter={24}>
                        <Col span={4}><Checkbox value={formatMessage({id:'DM'})}>{formatMessage({id:'DM'})}</Checkbox></Col>
                        <Col span={4}><Checkbox value={formatMessage({id:'PAOD'})}>{formatMessage({id:'PAOD'})}</Checkbox></Col>
                        <Col span={4}><Checkbox value={formatMessage({id:'HTN'})}>{formatMessage({id:'HTN'})}</Checkbox></Col>
                        <Col span={4}><Checkbox value={formatMessage({id:'CHF'})}>{formatMessage({id:'CHF'})}</Checkbox></Col>
                        <Col span={4}><Checkbox value={formatMessage({id:'AF'})}>{formatMessage({id:'AF'})}</Checkbox></Col>
                        <Col span={4}><Checkbox value={formatMessage({id:'AMI'})}>{formatMessage({id:'AMI'})}</Checkbox></Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={4}><Checkbox value={formatMessage({id:'DVT'})}>{formatMessage({id:'DVT'})}</Checkbox></Col>
                        <Col span={4}><Checkbox value={formatMessage({id:'COPD'})}>{formatMessage({id:'COPD'})}</Checkbox></Col>
                        <Col span={6}><Checkbox value={formatMessage({id:'POST_COVID'})}>{formatMessage({id:'POST_COVID'})}</Checkbox></Col>
                        <Col span={10}></Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Checkbox value={`${formatMessage({id:'POOR_SKIN'})}: "${poorSkin}"`}>{formatMessage({id:'POOR_SKIN'})}</Checkbox>
                            <Input style={{width:'200px'}} bordered={false} onChange={onPoorSkinEdit} value={poorSkin} />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Checkbox value={`${formatMessage({id:'OTHERS'})}: "${others}"`}>{formatMessage({id:'OTHERS'})}</Checkbox>
                            <Input style={{width:'200px'}} bordered={false} onChange={onOthersEdit} value={others} />
                        </Col>
                    </Row>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item label={formatMessage({id:'GOAL_EXPECTATION'})} name='patientExpectations'>
                <TextArea rows={4} />
            </Form.Item>
            <Form.Item label={formatMessage({id:'REMARK'})} name='descriptionRemark'>
                <TextArea rows={4} />
            </Form.Item>
            <Form.Item noStyle name='caseTypeCode'><span></span></Form.Item>
            <Form.Item noStyle name='name'><span></span></Form.Item>
            <Form.Item noStyle name='genderCode'><span></span></Form.Item>
            <Form.Item noStyle name='phone'><span></span></Form.Item>
            <Form.Item noStyle name='telecomEmail'><span></span></Form.Item>
            <Form.Item noStyle name='caseClose'><span></span></Form.Item>
            <Form.Item noStyle name='mainCaregiver'><span></span></Form.Item>
        </Wrapper>
    )
}

export const ModifiedHistoryForm: React.FC<Props> = ({data, form}: Props) => {

    const { formatMessage } = useIntl();
    const modifiedHistory = data.recordRemark?.sort((a: any,b: any)=>b.updatedAt-a.updatedAt).map((record: any)=><ModifiedRecord updatedAt={record.updatedAt} editorName={record.createName} content={record.recordRemark}/>) || [];

    return (
        <Wrapper>
            <div className='form_title'><b>{formatMessage({id:'REMINDER'})}</b></div>
            <Form.Item label={formatMessage({id:'REMINDER'})} name='modifyRecord'>
                <TextArea rows={4} />
            </Form.Item>
            <Form.Item label={formatMessage({id:'RECENT_REMINDERS'})}>
                {modifiedHistory}
            </Form.Item>
            <Form.Item noStyle name='caseTypeCode'><span></span></Form.Item>
            <Form.Item noStyle name='name'><span></span></Form.Item>
            <Form.Item noStyle name='genderCode'><span></span></Form.Item>
            <Form.Item noStyle name='phone'><span></span></Form.Item>
            <Form.Item noStyle name='telecomEmail'><span></span></Form.Item>
            <Form.Item noStyle name='caseClose'><span></span></Form.Item>
            <Form.Item noStyle name='mainCaregiver'><span></span></Form.Item>
        </Wrapper>
    )
}

const CircleLabel = styled.div`
    width: 20px;
    height: 20px;
    background-color: #2E2E2E;
    border-radius: 50%;
    color: #FFFFFF;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
`;