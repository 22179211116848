import React, { useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Divider, Radio, Row, Col } from 'antd';
import { GlobalContext } from '../../../../../index';
import { Form } from 'components/form';
import { FormTitle } from './EvaluationDrawer';
import { MuscleToneData } from '../type';

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 1.2vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

interface Props {
    data: MuscleToneData;
    userInfoData: any;
}

const MuscleTone: React.FC<Props> = ({ data, userInfoData }: Props) => {

    const { getOptions, nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const { formatMessage } = useIntl();
    
    const MuscleTone = getOptions('MuscleTone').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    return (
        <div style={userInfoData.mainDiagnosis1===nameMapping['MAI01']?{}:{pointerEvents: 'none', opacity: 0.4}}>
            <div className='text-24 text--bold'>{formatMessage({id:'MUSCLE_TONE'})}<span style={{fontWeight: 500}}> - {formatMessage({id:'MAS_SCORE'})}</span></div>

            <div className='text-20 text--bold mb-15'>{formatMessage({id:'UPPER_LIMBS'})}</div>

            <Row gutter={24}>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'LT'})}</div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'RT'})}</div>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'SHOULDER_FLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtUlLtShoulderFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtUlRtShoulderFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'SHOULDER_ADDUCTOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtUlLtShoulderAdductorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtUlRtShoulderAdductorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'ELBOW_FLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtUlLtElbowFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtUlRtElbowFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'WRIST_FLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtUlLtWristFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtUlRtWristFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>


            <div className='text-20 text--bold mb-15 mt-30'>{formatMessage({id:'LOWER_LIMBS'})}</div>
            
            <Row gutter={24}>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'LT'})}</div>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9'}}>{formatMessage({id:'RT'})}</div>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'HIP_FLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtLlLtHipFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtLlRtHipFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'HIP_EXTENSOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtLlLtHipExtensorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtLlRtHipExtensorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'HIP_ADDUCTOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtLlLtHipAdductorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtLlRtHipAdductorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'KNEE_FLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtLlLtKneeFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtLlRtKneeFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'KNEE_EXTENSOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtLlLtKneeExtensorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtLlRtKneeExtensorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}><Col><FormTitle>{formatMessage({id:'ANKLE_PLANTARFLEXOR'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11}>
                    <Form.Item name="mtLlLtAnklePlantarflexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Form.Item name="mtLlRtAnklePlantarFlexorCode">
                        <Radio.Group buttonStyle="solid">{MuscleTone}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default MuscleTone;
