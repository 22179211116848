// tslint:disable quotemark
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useContext,useState } from 'react';
import { pathOr, head, identity, map, join, compose, ifElse, is, toLower } from 'ramda';
import { Form, Radio, Tooltip, Modal } from 'antd';
import Drawer from 'components/drawer/drawer';
import { DrawerProps } from 'antd/lib/drawer';

import moment from 'moment';
import Button from 'components/button/button';
import DatePicker from 'components/datePicker/datePicker';
import { addCase } from 'api/case';
import { getOrganization } from 'api/organizationMgmtApi';

import { RefetchQueriesFunction } from 'constants/types';
import { error } from 'utils/message';
import { apiCaller } from 'service/apiCaller';
import { Input, Select, Label, InputNumber } from 'components/form';

import { useIntl } from 'react-intl';
import Switch from 'components/switch/switch';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../index';
import { checkIfAvailable } from 'service/permission';

type Props = Omit<DrawerProps, 'onClose'> & {
    onClose: (evt?: React.MouseEvent<HTMLElement>) => any
    refetchQueries: RefetchQueriesFunction
    item: any
};

export default function CaseDrawer({
    item,
    refetchQueries,
    onClose,
    visible,
    ...props
}: Props) {

    const { optionType, selectOption} = useContext(GlobalContext).dropdownOptionCtx;
    const {formatMessage} = useIntl()
    const [form] = Form.useForm()
    const history = useHistory();
    const [organizations, setOrganizations] = useState<any[]>([]);
    const[phone,setPhone]=useState<string>("");
    var reg = /^([0-9]*)$/;


    useEffect(()=>{
        form.setFieldsValue({caseTypeCode: 'CAS02'});

        getOrganization({
            // no args
        }).then(res=>{
            const orgLevel3 = res.data.data.filter((d: any)=>d.type==='bus');
            setOrganizations(orgLevel3);
            const orgOfCurrentUser = localStorage.getItem('managingOrganizationId') ? parseInt(localStorage.getItem('managingOrganizationId') as string) : undefined;
            form.setFieldsValue({
                managingOrganization: orgLevel3.some((d: any)=>d.id===orgOfCurrentUser) && orgLevel3.length > 0 ? orgOfCurrentUser : orgLevel3[0]?.id
            });
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }, []);

    const orgOptions = organizations.map(d=>(
        <Select.Option value={d.id}>{d.name}</Select.Option>
    ));

    // close Drawer
    const handleClose = () => onClose()

    const handleOk = async () =>{
        form.validateFields().then(values=>{
            const success = (res: any) => {
                Modal.success({
                    title: 'Create Success',
                    maskClosable: true,
                    onCancel:()=>{
                        refetchQueries()
                        handleClose()
                    },
                    okButtonProps: {
                        onClick: ()=>{
                            refetchQueries()
                            handleClose()
                        },
                        style:{
                            backgroundColor: '#9EC25B'
                        }
                    },
                    okText: `${formatMessage({id:'GOOD'})} !`,
                    content: (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '75%',
                            margin: '10px auto'
                        }}>
                            <Button
                                onClick={()=>{refetchQueries();handleClose();Modal.destroyAll();}}
                                style={{color: 'white', backgroundColor: '#9EC25B', fontWeight: 'bold'}}
                            >{formatMessage({id:'GOOD'})} !</Button>
                            <Button
                                onClick={()=>{handleClose();Modal.destroyAll();history.push(`/Patient/${form.getFieldValue('userId')}?edit=true`);}}
                                type='link'
                                style={{color: '#2E2E2E', border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', marginTop: '15px'}}
                            >{formatMessage({id:'EDIT_IN_PROFILE'})}</Button>
                        </div>
                    )
                })
            };

            const reject = (err: any) => {
                console.error(err)
                Modal.error({
                    title: formatMessage({id:'ERROR'}),
                    maskClosable: true,
                    content: (
                        <div style={{ wordBreak:'break-word', whiteSpace: 'break-spaces' , textAlign:'center' }}>
                            {
                                typeof (err?.data?.detail)  === 'string'
                                ?
                                err.data.detail
                                :
                                (err?.data?.detail) instanceof Array
                                ?
                                err.data.detail.join('\n')
                                :
                                null
                            }
                        </div>
                    )
                })
            };
            
            apiCaller(
                addCase,
                {
                    device: 'web',
                    ...values
                },
                success,
                reject
            );
        })
    }
    
    return (
        <Drawer
            {...props}
            visible={visible}
            maskClosable={true}
            closable={true}
            keyboard={false}
            destroyOnClose={true}
            width={500}
            getContainer={false}
            forceRender={true}
            title={formatMessage({id:'ADD_PATIENT'})}
            onClose={handleClose as any}
            footer={
                (
                    <div>
                        <Button className="mr-20 rect" onClick={handleClose}>
                            {formatMessage({id: 'CANCEL' }) }
                        </Button>
                        <Button className="is--primary rect" onClick={handleOk}>
                            {formatMessage({id: 'SAVE' }) }
                        </Button>
                    </div>
                )
            }
        >
            <Form
                form={form} layout="vertical"
                onValuesChange={( changedValues,allValues)=>{
                    let phoneInpute = allValues.phone
                    if(phoneInpute === ""){
                        setPhone(phoneInpute)
                        form.setFieldsValue({ phone:""})
                    }else if(!reg.test(phoneInpute)){
                        form.setFieldsValue({phone:phone})
                    }else if(reg.test(phoneInpute)){
                        form.setFieldsValue({ phone:allValues.phone })
                        setPhone(phoneInpute)
                    }
                }}
            >
                {/* Type */}
                <Form.Item label={formatMessage({id:'TYPE'})} name='caseTypeCode' style={{width: '130px'}} rules={[{required: true, message: `${formatMessage({id:'TYPE'})} is required`}]}>
                    <Select
                        allowClear={false} 
                        bordered={false}
                        defaultValue='CAS02'
                    >
                        {selectOption[optionType.indexOf('CaseType')]}
                    </Select>
                </Form.Item>

                {/* Organization */}
                <Form.Item label={formatMessage({id:'ORG_NAME'})} name='managingOrganization' rules={[{required: true, message: `${formatMessage({id:'ORG_NAME'})} is required`}]}>
                    <Select
                        allowClear={false}
                        bordered={false}
                    >
                        {orgOptions}
                    </Select>
                </Form.Item>

                {/* User ID */}
                <Form.Item 
                    label={formatMessage({id:'ACCOUNT'})} 
                    name='userId' 
                    rules={[{
                        required: true, 
                        validator: async (_: any, value: any) => {
                            if(!value){
                                throw(`${formatMessage({id:'ACCOUNT'})} is required`)
                            }
                            if (value && !/(?=[a-zA-Z0-9])[a-zA-Z0-9]{8,20}/.test(value)) {
                                throw(formatMessage({id:'INVALID_ACCOUNT_ID'}))
                            }
                        }
                    }]}
                >
                    <Input bordered={false}  />
                </Form.Item>

                {/* Name */}
                <Form.Item label={formatMessage({id:'PATIENT_NAME'})} name='name' rules={[{required: true, message: `${formatMessage({id:'NAME'})} is required`}]}>
                    <Input bordered={false}  />
                </Form.Item>

                {/* Gender */}
                <Form.Item label={formatMessage({id:'GENDER'})} name='genderCode' style={{width: '160px'}} rules={[{required: true, message: `${formatMessage({id:'GENDER'})} is required`}]}>
                    <Select
                        allowClear={true} 
                        bordered={false} 
                    >
                        {selectOption[optionType.indexOf('Gender')]}
                    </Select>
                </Form.Item>

                {/* Phone */}
                <Form.Item label={formatMessage({id:'TEL'})} name='phone' rules={[{required: true, message: `${formatMessage({id:'TEL'})} is required`}]}>
                    <Input bordered={false} />
                </Form.Item>

                {/* E-mail */}
                <Form.Item 
                    label={formatMessage({id:'MAIL'})} 
                    name='telecomEmail' 
                    rules={[
                        {
                            required: true,
                            message: `${formatMessage({id:'MAIL'})} is required`,
                        },
                        {
                            validator: (_: any, value: any) => {
                                const emailRegex = new RegExp('\\w+((-\\w+)|(\\.\\w+))*@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+')
                                if (value && !emailRegex.test(value)) {
                                    return Promise.reject(
                                        formatMessage({id:'INVALID_MAIL'})
                                    );
                                }
                                return Promise.resolve();
                            },
                        }
                    ]}
                >
                    <Input bordered={false} />
                </Form.Item>

            </Form>
        </Drawer>
    );
}
