import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';
import { icon_sync } from 'components/image';

const Wrapper = styled.div`
    aspect-ratio: 5/2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 0.2rem;
`;

const StatusTag = styled.div<{color: string}>`
    border-radius: 1rem;
    padding: 3px 10px;
    color: #FFFFFF;
    background-color: ${props => props.color};
    font-size: 12px;
`;

interface Props {
    name: string;
    date: string;
    time: string;
    share?: boolean;
    invalid?: boolean;
    modified?: boolean;
    onClick: () => void;
}

const ReportCard: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();

    const tags = (
        <div style={{display: 'flex', position: 'absolute', top: '10px', right: '20px'}}>
            {props.invalid && <StatusTag color='#C4C4C4'>{formatMessage({id:'INVALID'})}</StatusTag>}
            {props.share && <StatusTag className='ml-5' color='#5DD197'>{formatMessage({id:'SHARED'})}</StatusTag>}
            {props.modified && !props.invalid && <img className='ml-5' src={icon_sync} />}
        </div>
    )

    return (
        <Wrapper onClick={props.onClick}>
            {tags}

            <div className='text-24 text--bold'>
                {props.name}
            </div>
            <div className='d-flex text-16'>
                <div className='mr-10'>{props.date}</div>
                <div>{props.time}</div>
            </div>
        </Wrapper>
    )
}

export default ReportCard;
