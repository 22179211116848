import Table from './table';
import styled from 'styled-components';

const SeparatedTable = styled(Table)`
    .ant-table {
        .ant-table-body {

            &::-webkit-scrollbar {
                width: 0;
            }

            table {
                border-spacing: 0 10px;
                padding: 0 3px;

                tbody {
                    tr {
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                        cursor: pointer;

                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
`;

export default SeparatedTable;
