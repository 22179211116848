import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Form, TextArea } from 'components/form';
import { FormTitle } from './EvaluationDrawer';
import { ULData } from '../type';

const StyledTextarea = styled(TextArea)`
    &.ant-input {
        resize: none !important;
        background-color: #F3F3F3;
        border: none;
    }
`;

interface Props {
    data: ULData;
}

const Description: React.FC<Props> = ({ data }: Props) => {

    const { formatMessage } = useIntl();
    
    return (
        <div>
            <div className='text-24 text--bold mb-15'>{formatMessage({id:'DESCRIPTION'})}</div>

            <Form.Item label={<FormTitle>{formatMessage({id:'REMARK'})}</FormTitle>} name="llDescriptionRemark">
                <StyledTextarea
                    rows={7}
                    placeholder="content"
                    maxLength={500}
                />
            </Form.Item>
        </div>
    );
};

export default Description;
