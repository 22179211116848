import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { getUserById } from 'api/user';

const Wrapper = styled.div`
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-bottom: 15px;

    .metadata {
        font-size: 16px;
        margin-bottom: 10px;

        .timestamp {
            color: #0284C1;
        }

        .divider {
            color: #C4C4C4;
            margin: 0 10px;
        }

        .editor {
            color: black;
            font-weight: bold;
        }
    }

    .content {
        font-size: 16px;
        font-weight: bold;
        color: black;
        width: 100%;
    }
`;

interface Props {
    updatedAt: string | number;
    editor?: string;
    editorName?: string;
    status?: string;
    content: string;
}

const ModifiedRecord: React.FC<Props> = ({updatedAt, editor, editorName, content, status}: Props) => {

    const [username, setUsername] = useState('');

    useEffect(()=>{
        if(!editorName && editor){
            getUserById(
                editor
            ).then((res: any)=>{
                setUsername(res.data.data.name)
            }).catch((error: any)=>{
                console.error(error);
                throw(error);
            });
        }
    }, [editor]);

    const contentWithNewline = content?.split('\n')?.map(d=>(<div>{d}<br/></div>)) || '--';

    return (
        <Wrapper>
            <div className="metadata">
                <span className="timestamp">{updatedAt ? moment(updatedAt).format('DD/MM/YYYY HH:mm') : '--'}</span>
                <span className="divider"> | </span>
                <span className="editor">{editorName??(username || '--')}</span>
                {status&&<span>
                    <span className="divider"> | </span>
                    <span className="editor">{status}</span>
                </span>}
            </div>
            <div className="content">{contentWithNewline}</div>
        </Wrapper>
    )
}

export default ModifiedRecord;
