import React from 'react';
import styled from 'styled-components';

/**
 * @param {number} value
 * @param {string} color(hex)
 */

export const BarDiv: React.FC<any> = (props: any) => {
    const { value, color } = props;
    return (
        <Bar>
            <Bar style={{width: `${parseDash(value)}%`, backgroundColor: color}} />
        </Bar>
    );
};

const parseDash = (value: number): number => {
    return value > 100 ? 100 : value < 0 ? 0 : value;
}

const Bar: any = styled.div`
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: #CDCDCD;
`;
