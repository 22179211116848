import { api, toQueryString } from './api';
import { Response, ListResponse } from 'types/Api';
import { AxiosRequestConfig } from 'axios';

const baseURL = '/keeogo/personal_info_basic/training';

export interface TrainingNote {
    profile: number;
    reps: string;
    sets: string;
    mins: string;
    comments: string;
    assistDevice: string[];
    trainingTypeCode: string;
    trainingTypeName: string;
}

export interface KRAdata {
    id: number;
    createdAt: number;
    updatedAt: number;
    profile: string;
    level: string;
    timeConsumed: number;
    maxHipAngleLeft: number;
    maxHipAngleRight: number;
    video: boolean;
    videoStatus: boolean;
    csvStatus: boolean;
    typeName: string;
    typeCode: string;
    assistDevice: string[];
    assistDeviceOther: string;
}

interface KRAConfig {
    id: number;
    createdAt: number;
    updatedAt: number;
    type: string;
    dateTime: string;
    assistDevice: string[];
    assistDeviceOther: string;
    level: string;
    profile: string;
    remark: string;
    timeConsumed: number;
    maxHipAngleLeft: number;
    maxHipAngleRight: number;
    leadingLeg: string;
    timeStep: number;
    timeStepLeft: number;
    timeStepRight: number;
    stepCountLeft: number;
    stepCountRight: number;
    avgStepLength: number;
    avgStepLengthLeft: number;
    avgStepLengthRight: number;
    avgStepWidth: number;
    avgStepWidthLeft: number;
    avgStepWidthRight: number;
    flexionHipAngleLeft: number;
    flexionHipAngleRight: number;
    extensionHipAngleLeft: number;
    extensionHipAngleRight: number;
    flexionKneeAngleLeft: number;
    flexionKneeAngleRight: number;
    extensionKneeAngleLeft: number;
    extensionKneeAngleRight: number;
    personalTrainingRecordId: number;
    videoStatus: boolean;
    csvStatus: boolean;
    fileName: string;
    createId: number;
    modifyId: number;
    video: boolean;
    fhirOrganizationId: number;
}

export interface RecordData {
    trainingId: number;
    trainingEditDate: string;
    trainingEditTime: string;
    treatmentPlanID: number;
    treatmentPlanMasterName: string;
    treatmentPlanMasterClassTypeCode: string;
    treatmentTypeCode: string;
    treatmentDate: string;
    treatmentHour: string;
    treatmentDetailStatusCode: string;
    classNum: string;
    status: boolean;
    notes: TrainingNote[];
    homeProgram: string;
    trainingFiles: TrainingFile[];
    uploadedVideos: UploadVideo[];
    KRA: KRAdata[];
    remark: string;
}

export const getOneRecord = (personalTrainingRecordId: number) => api.get<Response<RecordData>>(`${baseURL}/${personalTrainingRecordId}?${toQueryString({multilingualKey: localStorage.getItem('lang')})}`);

interface CreateRecordParams {
    personalInfoBasicId: number;
    personalTreatmentPlanDetailId: number;
    device?: string;
}

interface CreateRecordRes {
    id: number;
    createdAt: number;
    updatedAt: number;
    personalInfoBasicId: number;
    personalTreatmentPlanDetailId: number;
    note: null;
    createId: number;
    modifyId: number;
    fhirOrganizationId: number;
    homeProgram: string;
    remark: string;
}

export const createRecord = (data: CreateRecordParams) => api.post<Response<CreateRecordRes>>(`${baseURL}/create-training`, { ...data, device: data.device ?? 'web' })

interface UpdateRecordParams {
    personalTrainingRecordId: number;
    notes?: TrainingNote[];
    homeProgram?: string;
    remark?:string;
    status?: boolean;
    device: string;
}

export const updateRecord = (data: UpdateRecordParams) => api.put<Response<any>>(`${baseURL}/edit-note`, { ...data, device: data.device ?? 'web' });


interface GetListRecordParams {
    personalInfoBasicId: number;
    trainingTypeCode: string;
    page?: number;
    pageSize?: number;
    desc?: boolean;
    personalTreatmentPlanMasterId?:number;
}

interface TrainingFile {
    id: number;
    fileKey: number;
    frontalViewVideo: string;
    frontalViewImage: string;
    classification: string;
    sagittalViewVideo: string;
    sagittalViewImage: string;
    image: any;
    trainingType: string;
    share: boolean;
}

interface UploadVideo {
    id: number;
    classification: string;
    fileKey: number;
    thumbnailUrl: string;
    trainingTypeCode: string;
    trainingTypeName: string;
    videoUrl: string;
    share: boolean;
    pending: boolean;
}

export interface Record {
    trainingId: number;
    trainingEditDate: string;
    trainingEditTime: string;
    treatmentPlanID: number;
    treatmentPlanMasterName: string;
    treatmentPlanMasterClassTypeCode: string;
    treatmentTypeCode: string;
    treatmentDate: string;
    treatmentHour: string;
    classNum: string;
    status: boolean;
    notes: { 
        trainingTypeCode: string;
        count: number;
    }[];
    videoNum: number;
    imageNum: number;
    trainingFiles: TrainingFile[];
    uploadedVideos: UploadVideo[];
    KRANum: number;
    examinationCompleted: boolean;
}

export interface KRAReport {
    id: number;
    profile: string;
    level: string;
    dateTime: string;
    remark: string;
    timeConsumed: number;
    maxHipAngleLeft: number;
    maxHipAngleRight: number;
    fileName: string;
    video: boolean;
    videoStatus: boolean;
    frontalViewVideo?: string;
    sagittalViewVideo?: string;
    type: string;
    assistDevice: string[];
    assistDeviceOther: string;
    KRADetailRecords: KRADetailData;
}

interface COGReport {
    profile: string;
    level: string;
    dateTime: string;
    remark: string;
    timeConsumed: number;
    maxHipAngleLeft: number;
    maxHipAngleRight: number;
    type: string;
    assistDevice: string[];
    assistDeviceOther: string;
    KRADetailRecords: COGData;
}

interface KRADetailData {
    LeftHipAngle: number[];
    LeftKneeAngle: number[];
    RightHipAngle: number[];
    RightKneeAngle: number[];
    HipCOG: number[];
    time: string[];
}

export interface COGData {
    HipCOG: number[];
    time: string[];
}

export const getConfig = (kraConfigId: number) => api.get<Response<KRAConfig>>(`${baseURL}/find-one-config?${toQueryString({ kraConfigId })}`);

export const getKRAData = (kraConfigId: number) => api.get<Response<KRAReport>>(`${baseURL}/kra-data?${toQueryString({ kraConfigId, multilingualKey: localStorage.getItem('lang') })}`);

export const getCOGData = (kraConfigId: number) => api.get<Response<COGReport>>(`${baseURL}/kra-cog?${toQueryString({ kraConfigId, multilingualKey: localStorage.getItem('lang') })}`);

export const getListRecord = (data: GetListRecordParams) => api.get<ListResponse<Record>>(`${baseURL}/list?${toQueryString({...data, multilingualKey: localStorage.getItem('lang')})}`);

export const uploadVideo = (data: FormData, config?: AxiosRequestConfig) => api.post<Response<any>>(`${baseURL}/upload-video`, data, { ...config, timeout: 1000*60*2 });

export const shareFile = (fileId: number) => api.post<Response<any>>(`${baseURL}/share-file/${fileId}`);

export const unshareFile = (fileId: number) => api.post<Response<any>>(`${baseURL}/unshare-file/${fileId}`);

export const shareAllFile = (data: {
    personalTrainingRecordId: number, 
    type: 'instantRecord' | 'uploadVideo'
}) => api.post<ListResponse<any>>(`${baseURL}/share-all-file`, data);
