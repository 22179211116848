import React, { useState, useEffect } from 'react';
import Drawer from 'components/drawer/drawer';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import { Form } from 'components/form';
import { getOrgById } from 'api/organizationMgmtApi';
import { editTreatment } from 'api/case';
import styled from 'styled-components';
import { apiCaller } from 'service/apiCaller';
import { Input, Modal } from 'antd';

const { TextArea } = Input;

interface Props {
    onClose: Function;
    changeState: Function;
    treatmentId: number;
    orgId: number;
    
}

const StyleNewDrawer: any = styled(Drawer)`
    .ant-drawer-header,.ant-drawer-body,.ant-drawer-footer {
        padding: 0 45px;
        box-sizing: border-box;
    }

    .ant-drawer-header {
        padding: 1.6rem 45px;
        display: flex;
        background: white;
        align-items:center;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    }

    .ant-drawer-body {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .ant-drawer-footer {
        height: 84px;
        display:flex;
        background: var(--color-gray-light-100);
        align-items:center;
        justify-content:flex-end;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .ant-drawer-close,.ant-drawer-title {
        color:#EDB754;
        font-weight: bold;
        font-size: var(--font-size-large)
    }

    .ant-drawer-close{
        height: 100%;
        color: black;
    }

    .ant-form-item-label label{
        color: var(--color-gray-dark);
        font-weight: 500;
        font-size: 1rem;
    }

    .ant-form-item input {
        color: black;
    }

    .ant-form-vertical .ant-form-item-label {
        padding-bottom: 4px;
    }

    textarea.ant-input {
        resize: none;
    }
`;

const SuspendTreatmentDrawer: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [pageContent, setPageContent] = useState();

    const handleOk = () => {
        form.validateFields().then(values=>{
            const success = (res: any) =>{
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel: ()=>{
                        props.changeState()
                        props.onClose()
                    }
                })
            };

            const reject = (error: any) => {
                console.error(error)
                Modal.error({
                    maskClosable:true,
                    title:'Error',
                    content: <div style={{textAlign:'center'}}> 
                        {
                            (()=>{
                                const msg = error?.data?.detail
                                if(typeof msg === 'string'){
                                    return msg
                                }else if(msg instanceof Array){
                                    return msg.join('\n')
                                }else{
                                    return JSON.stringify(msg)
                                }
                            })()   
                        }
                    </div> 
                });
            }

            apiCaller(
                editTreatment, 
                {
                    ...values,
                    treatmentDetailStatusCode: 'TDS05',
                    planDetailId: props.treatmentId,
                    device: 'web',
                    memo:pageContent,
                }, 
                success, 
                reject
            );
        })
    }


    return (
             <StyleNewDrawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='30vw'
           
            title={formatMessage({id:'ADD_SUSPEND'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect" onClick={handleOk} style={{background: '#EDB754'}}>
                        {formatMessage({id:'CONFIRM_UPPERCASE'})}
                    </Button>
                </div>
            }
        >
            <Form form={form} layout='vertical' >
                    <Form.Item 
                        label={formatMessage({id:'MODIFIED_RECORD'})} 
                        name='recordRemark' 
                        rules={[{required: true}]}
                    >
                         <TextArea rows={4} style={{ height: 300 }}  value={pageContent} onChange={(e: any)=>setPageContent(e.target.value)}/>
                    </Form.Item>
            </Form>
        </StyleNewDrawer>
    )
}

export default SuspendTreatmentDrawer;
