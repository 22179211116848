import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { other_squatting } from 'components/image';
import { ViewWrapper } from './index';
import { GlobalContext } from '../../../../../index';

interface Props {
    data: {
        assistDevice: string;
        assistDeviceOther: string;
        affected: string[];
        affectedOther: string;
    };
}

const SquattingView: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const assistOther = props.data.assistDevice === nameMapping['ASS04'] ? (
        props.data.assistDeviceOther ? props.data.assistDeviceOther.split('\n').map(d=><div>{d}<br/></div>) : '--'
    ) : undefined;

    const otherText = props.data.affected.some(d=>d === nameMapping['SAS06']) ? (
        <div>
            {nameMapping['SAS06']} - {props.data.affectedOther.split('\n').map(d=><span>{d}<br/></span>)}
        </div>
    ) : undefined;

    return (
        <ViewWrapper>
            <div className="header">
                {formatMessage({id:'ITEM_OTHER_SQUATTING'})}
            </div>
            <div className="content">
                <div className="left">
                    <div className="icon">
                        <img src={other_squatting} width='100%' />
                    </div>
                </div>
                <div className="right">
                    <div className='label'>
                        <div className="name">{formatMessage({id:'ASSIST_DEVICES'})}</div>
                        <div className="text large">{props.data.assistDevice || '--'}</div>
                        <div className='text'>{assistOther}</div>
                    </div>
                    <div className='label mt-15'>
                        <div className="name">{'● '+formatMessage({id:'AFFECTED_SIDE'})}</div>
                        <div className="text">{props.data.affected.filter(d=>d!==nameMapping['SAS06']).join(', ') || '--'}<br/>{otherText}</div>
                    </div>
                </div>
            </div>
        </ViewWrapper>
    )
}

export default SquattingView;
