import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import ProfileCard from 'components/profileCard/profileCard';
import Label from 'components/label/label';
import { useIntl } from 'react-intl';
import moment from 'moment';
import UserInfoDrawer from './UserInfoDrawer';
import ModifiedRecord from '../ModifiedRecord';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .ant-row {
        margin: 20px 0;
    }

    .ant-col>.ant-row:first-child {
        margin-top: 0px;
    }

    .ant-col>.ant-row:last-child {
        margin-bottom: 0px;
    }
`;

interface Props {
    data: any;
    refetch: Function;
    edit: boolean;
    setEditView: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserInfoTab: React.FC<Props> = ({data, refetch, edit, setEditView}:Props) => {

    const { formatMessage } = useIntl();
    const [drawerSetting, setDrawerSetting] = useState({
        showDrawer: edit,
        tabKey: 1
    });

    useEffect(()=>{
        setEditView(false);
    });

    const [permission] = usePermission('Information');

    const diagnosis1 = data.mainDiagnosis1 || '--' ;
    const diagnosis2 = data.mainDiagnosis2?(' - '+data.mainDiagnosis2) : '';
    const diagnosis3 = data.mainDiagnosis3?(' - '+data.mainDiagnosis3) : '';
    const diagnosis4 = data.mainDiagnosis4?(' - '+(data.mainDiagnosis4 || data.mainDiagnosis4)):'';

    const occupationFormer = data.occuptionStatus?data.occuptionStatus.split(', ')[0] : '--';
    const occupationLatter = data.occuptionStatus?.split(', ')[1]?', '+data.occuptionStatus.split(', ')[1]:'';
    const occupation = occupationFormer+occupationLatter;
    
    const modifiedHistory = data.recordRemark?.slice().sort((a: any,b: any)=>b.updatedAt-a.updatedAt).map((record: any)=><ModifiedRecord updatedAt={record.updatedAt} editorName={record.createName} content={record.recordRemark}/>) || [];
    
    const stageNum = ['I', 'II', 'III', 'IV', 'V', 'VI'];
    const brunnstormStage = [];

    if(data.brunnstormStageUL>=0){
        brunnstormStage.push(`${data.brunnstormStageUL<6?formatMessage({id:'LT'}):formatMessage({id:'RT'})} ${formatMessage({id:'UL'})} ${formatMessage({id:'STAGE'})} ${stageNum[data.brunnstormStageUL%6]}`);
    }
    if(data.brunnstormStageLL>=0){
        brunnstormStage.push(`${data.brunnstormStageLL<6?formatMessage({id:'LT'}):formatMessage({id:'RT'})} ${formatMessage({id:'LL'})} ${formatMessage({id:'STAGE'})} ${stageNum[data.brunnstormStageLL%6]}`);
    }
    var precautions=[]
    for(let j=0;j<12;j++){
        const num=(data.precautions?.split(',')[j])
        if(data.precautions?.split(',')[j]!==undefined){
        ( (num==='DM')?precautions[0]='DM':(num==='PAOD')? precautions[1]='PAOD':(num==='HTN')?precautions[2]='HTN':(num==='CHF')?precautions[3]='CHF':(num==='AF')?precautions[4]='AF':(num==='AMI')? 
        precautions[5]='AMI':(num==='DVT')?precautions[6]='DVT':(num==='COPD')?precautions[7]='COPD':(num==='POST_COVID')?precautions[8]='POST_COVID':
            (/Poor/.test(num)===true)?precautions[9]=num:(/Others/.test(num)===true)?precautions[10]=num:precautions[11]=undefined)
        }
    }
     for(let x=0;x<precautions.length;x++)
    {
        if(precautions[x] === "" || typeof(precautions[x]) === "undefined") {
            precautions.splice(x,1);
            x = x - 1;
        }
    }
    
    var mainCaregiver=[]
    for(let j=0;j<7;j++){
        const num=(data.mainCaregiver?.split(',')[j])
        if(data.mainCaregiver?.split(',')[j]!==undefined){
        ( (num==='Spouse')?mainCaregiver[0]='Spouse':(num==='Children')? mainCaregiver[1]='Children':(num==='Siblings')?mainCaregiver[2]='Siblings':(num==='Caretaker')?mainCaregiver[3]='Caretaker':(num==='Nurse')?mainCaregiver[4]='Nurse':
        (/Others/.test(num)===true)?mainCaregiver[5]=num:mainCaregiver[6]=undefined)
        }
    }
     for(let x=0;x<mainCaregiver.length;x++)
    {
        if(mainCaregiver[x] === "" || typeof(mainCaregiver[x]) === "undefined") {
            mainCaregiver.splice(x,1);
            x = x - 1;
        }
    }
    
    return (
        <Wrapper>
            {drawerSetting.showDrawer&&
            <UserInfoDrawer
                edit={edit} 
                tabKey={drawerSetting.tabKey}
                data={data}
                onClose={()=>setDrawerSetting({...drawerSetting, showDrawer: false})}
                refetch={refetch}
             />}

            {checkIfAvailable(permission, 'UserInformation')&&

            <ProfileCard
            editable={checkIfAvailable(permission, 'UserInformation', 'Edit')}
            onEdit={()=>setDrawerSetting({showDrawer: true, tabKey: 1}) }

            title={formatMessage({id:'PATIENT_INFO'})}>
                <Row gutter={24}>
                    <Col span={6}><Label name={formatMessage({id:'ORGANIZATION'})} text={data.abbreviation || '--'}/></Col>
                    <Col span={6}><Label name={formatMessage({id:'TYPE'})} text={data.caseTypeCode || '--'}/></Col>
                    <Col span={6}><Label name={formatMessage({id:'DISCHARGED'})} text={data.caseClose?'Yes':'No' || '--'}/></Col>
                    <Col span={6}><Label name={formatMessage({id:'EMERGENCY_CONTACT'})} text={(data.emergencyContactPhone||data.emergencyContactName||data.emergencyContactRelationship)?`${data.emergencyContactPhone}, ${data.emergencyContactName}, ${data.emergencyContactRelationship}`:'--'}/></Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}><Label name={formatMessage({id:'NAME'})} text={data.name || '--'}/></Col>
                    <Col span={6}><Label name={formatMessage({id:'GENDER'})} text={data.genderCode || '--'}/></Col>
                    <Col span={6}><Label name={formatMessage({id:'OCCUPATION'})} text={occupation}/></Col>
                    <Col span={6}><Label name={formatMessage({id:'ADDRESS'})} text={data.address || '--'}/></Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}><Label name={formatMessage({id:'BIRTHDAY'})} text={data.birthday?moment(data.birthday).format('DD/MM/YYYY'):'--'}/></Col>
                    <Col span={6}><Label name={formatMessage({id:'AGE'})} text={data.birthday?moment().diff(data.birthday, 'years').toString():'--'}/></Col>
                    <Col span={12}><Label name={formatMessage({id:'MAIL'})} text={data.telecomEmail || '--'}/></Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}><Label name={formatMessage({id:'IC_NUMBER'})} text={data.icNumber || '--'}/></Col>
                    <Col span={6}><Label name={formatMessage({id:'CASECODE'})} text={data.caseCode || '--'}/></Col>
                    <Col span={12}><Label name={formatMessage({id:'TEL'})} text={data.phone || '--'}/></Col>
                </Row>
            </ProfileCard>}

            {checkIfAvailable(permission, 'MainDiagnosis')&&<ProfileCard editable={checkIfAvailable(permission, 'MainDiagnosis', 'Edit')} onEdit={()=>setDrawerSetting({showDrawer: true, tabKey: 2})} title={formatMessage({id:'MAIN_DIAGNOSIS'})}>
                <Row gutter={24}>
                    <Col span={10}>
                        <Row><Label name={formatMessage({id:'ONSET_DATE'})} text={data.dateOfOnset?moment(data.dateOfOnset).format('DD/MM/YYYY'):'--'}/></Row>
                        <Row><Label name={formatMessage({id:'DIAGNOSIS'})} text={diagnosis1+diagnosis2+diagnosis3+diagnosis4}/></Row>
                        {data.mainDiagnosisOtherDescription && <Row><Label name={formatMessage({id:'OTHER_DESC'})} text={data.mainDiagnosisOtherDescription || '--'}/></Row>}
                        <Row><Label name={formatMessage({id:'BRUNNSTROM_STAGE'})} text={brunnstormStage.length>0?brunnstormStage.join(', '):'--'}/></Row>
                    </Col>
                    <Col span={14}>
                        <Label name={formatMessage({id:'REHABIT_HISTORY'})} text={data.mainDiagnosisRemark || '--'}/>
                    </Col>
                </Row>
            </ProfileCard>}
            {checkIfAvailable(permission, 'Background')&&<ProfileCard editable={checkIfAvailable(permission, 'Background', 'Edit')} onEdit={()=>setDrawerSetting({showDrawer: true, tabKey: 3})} title={formatMessage({id:'BACKGROUND'})}>
                <Row gutter={24}>
                    <Col span={4}><Label name={formatMessage({id:'REHABIT_START'})} text={data.startDate?moment(data.startDate).format('DD/MM/YYYY'):'--'}/></Col>
                    <Col span={4}><Label name={formatMessage({id:'FIRST_TRAINING'})} text={data.keeogoTrainingfirst?moment(data.keeogoTrainingfirst).format('DD/MM/YYYY'):'--'}/></Col>
                    <Col span={4}><Label name={formatMessage({id:'UNTIL_FIRST_TRAINING'})} text={data.keeogoTrainingTime?data.keeogoTrainingTime:'--'}/></Col>
                    <Col span={12}></Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}><Label name={formatMessage({id:'HOME_ENV'})} text={data.homeEnvironment || '--'}/></Col>
                    <Col span={4}><Label name={formatMessage({id:'TOILET_CONDITION'})} text={data.toiletCondition || '--'}/></Col>
                    <Col span={4}><Label name={formatMessage({id:'MAIN_CAREGIVER'})} text={mainCaregiver.toString()?mainCaregiver.toString().replace(/"/g,''):'--'}/></Col>
                    <Col span={12}></Col>
                </Row>
            </ProfileCard>}
            {checkIfAvailable(permission, 'Description')&&<ProfileCard editable={checkIfAvailable(permission, 'Description', 'Edit')} onEdit={()=>setDrawerSetting({showDrawer: true, tabKey: 4})} title={formatMessage({id:'DESCRIPTION'})}>
                <Row gutter={24}>
                    <Col span={10}>
                        <Row><Label name={formatMessage({id:'PRECAUTIONS'})} text={precautions.toString()?precautions.toString().replace(/"/g,''):'--'}/></Row>
                        <Row><Label name={formatMessage({id:'GOAL_EXPECTATION'})} text={data.patientExpectations || '--'}/></Row>
                    </Col>
                    <Col span={14}><Label name={formatMessage({id:'REMARK'})} text={data.descriptionRemark || '--'}/></Col>
                </Row>
            </ProfileCard>}
            {checkIfAvailable(permission, 'ModifiedHistory')&&<ProfileCard editable={checkIfAvailable(permission, 'ModifiedHistory', 'Edit')} onEdit={()=>setDrawerSetting({showDrawer: true, tabKey: 5})} title={formatMessage({id:'REMINDER'})}>
                {modifiedHistory}
            </ProfileCard>}
        </Wrapper>
    )
}

export default UserInfoTab;
