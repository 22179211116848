import React, { useEffect, useState, useContext } from 'react';
import Drawer from 'components/drawer/drawer';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import { Tabs, Modal } from 'antd';
import { UserInfoForm, DiagnosisForm, BackgroundForm, DescriptionForm, ModifiedHistoryForm } from './forms';
import { Form } from 'components/form';
import styled from 'styled-components';
import { updateCase, getUser, getDropDownOption } from 'api/case';
import { apiCaller } from 'service/apiCaller';
import { routeMatch } from 'utils/webHelper';
import { useLocation } from 'react-router-dom';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';
import { GlobalContext } from '../../../../index';

const DrawerContent = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F3F3F3;
    padding: 20px;

    .ant-tabs-tabpane-active {
        background-color: #FFFFFF;
        padding: 20px;
        height: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    .ant-tabs {
        height: 100%;
    }

    .ant-tabs-tab {
        padding: 10px;
        font-weight: bold;

        :hover {
            color: #2E63A3;
        }

        &::after {
            content: '●';
            margin-left: 10px;
        }
    }

    .ant-tabs-tab-active {
        color: #2E63A3;
    }

    .ant-tabs-ink-bar {
        display: none!important;
    }

    .ant-tabs-content {
        padding-left: 0;
        height: 100%;
    }
`;

interface Props {
    refetch: Function;
    tabKey?: number;
    data: any;
    onClose: Function;
    edit: boolean;
}

const UserInfoDrawer: React.FC<Props> = (props: Props) => {

    const { id } = routeMatch('/Patient/:id', useLocation().pathname);
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [data, setData] = useState(props.data);
    const [permission] = usePermission('Information');
    const [tabKey, setTabKey] = useState(props.tabKey?.toString() ?? '1')
    const { getCode } = useContext(GlobalContext).dropdownOptionCtx;

    useEffect(()=>{
        if(props.edit){
            getDropDownOption({
                multilingualKey: localStorage.getItem('lang') || 'EN',
                optionType: ['Gender', 'CaseType']
            }).then((optionRes: any)=>{
                const genderOption = optionRes.data.data.data[0];
                const caseTypeOption = optionRes.data.data.data[1];
                getUser({id}).then((res: any)=>{
                    setData(res.data.data[0]);
                    form.setFieldsValue({
                        name: res.data.data[0].name,
                        phone: res.data.data[0].phone,
                        telecomEmail: res.data.data[0].telecomEmail,
                        caseTypeCode: caseTypeOption.find((d: any)=>d.name==res.data.data[0].caseTypeCode).code,
                        genderCode: genderOption.find((d: any)=>d.name==res.data.data[0].genderCode).code,
                        caseClose: false
                    });
                }).catch((err: Error)=>{
                    throw(err);
                });
            }).catch((err: Error)=>{
                throw(err);
            });            
        }

        const reg = new RegExp(',(?!(\\w|,)*")');

        // fill required props into form
        form.setFieldsValue({
            caseTypeCode: getCode('CaseType', data.caseTypeCode),
            name: data.name,
            genderCode: getCode('Gender', data.genderCode),
            phone: data.phone,
            telecomEmail: data.telecomEmail,
            caseClose: data.caseClose,
            mainCaregiver: data.mainCaregiver?.split(reg).filter((d: string)=>d)||[],
            precautions: data.precautions?.split(reg).filter((d: string)=>d)||[]
        });
    }, []);

    const handleOk = () => {
        form.validateFields().then(values=>{
            const success = (res: any) => {
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel: ()=>{
                        props.refetch();
                        props.onClose();
                    }
                })
            };

            const reject = (err: any) => {
                console.error(err)
                Modal.error({
                    maskClosable:true,
                    title:'Error',
                    content: <div style={{textAlign:'center'}}> 
                        {
                            (()=>{
                                const msg = err?.data?.detail
                                if(typeof msg === 'string'){
                                    return msg
                                }else if(msg instanceof Array){
                                    return msg.join('\n')
                                }else{
                                    return JSON.stringify(msg)
                                }
                            })()   
                        }
                    </div> 
                })
            };
            
            apiCaller(
                updateCase,
                {
                    userId: data.userId,
                    device: 'web',
                    ...values
                },
                success,
                reject
            )
        }).catch(err=>{
            if(err.errorFields[0].name[0].startsWith('mainDiagnosis')){
                setTabKey('2');
            }
            else{
                setTabKey('1');
            }
        });
    }

    const tabPanes = [
        {
            id:'UserInformation',
            tab: formatMessage({id:'USER_INFO'}), 
            component: UserInfoForm, 
            canRead: true ,//|| myPermissionState.read, 
            prop:{
                data: data,
                form
            }
        },
        {
            id:'MainDiagnosis',
            tab: formatMessage({id:'MAIN_DIAGNOSIS'}), 
            component: DiagnosisForm, 
            canRead: true ,//|| myPermissionState.read, 
            prop:{
                data: data,
                form
            }
        },
        {
            id:'Background',
            tab: formatMessage({id:'BACKGROUND'}), 
            component: BackgroundForm, 
            canRead: true ,//|| myPermissionState.read, 
            prop:{
                data: data,
                form
            }
        },
        {
            id:'Description',
            tab: formatMessage({id:'DESCRIPTION'}), 
            component: DescriptionForm, 
            canRead: true ,//|| myPermissionState.read, 
            prop:{
                data: data,
                form
            }
        },
        {
            id:'ModifiedHistory',
            tab: formatMessage({id:'REMINDER'}), 
            component: ModifiedHistoryForm, 
            canRead: true ,//|| myPermissionState.read, 
            prop:{
                data: data,
                form
            }
        }
    ];
    
    return (
        <Drawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='80vw'
            title={formatMessage({id:'EDIT_PROFILE'})}
            footer={
                <div className="pull--right rect">
                    <Button className="mr-20 rect" onClick={props.onClose as any}>
                        {formatMessage({id:'CANCEL'})}
                    </Button>
                    <Button className="is--primary rect" onClick={handleOk}>
                        {formatMessage({id:'SAVE'})}
                    </Button>
                </div>
            }
        >
            <DrawerContent>
                <Tabs defaultActiveKey={props.tabKey?.toString()} tabPosition='left' activeKey={tabKey} onTabClick={(key: string)=>setTabKey(key)}>
                    {
                        tabPanes.filter(p=>checkIfAvailable(permission, p.id, 'Edit')).map((item: any, i: number) => (
                            <Tabs.TabPane tab={item.tab} key={(i+1).toString()} >
                                <Form form={form} layout='vertical'>
                                    <item.component {...item.prop}/>
                                </Form>
                            </Tabs.TabPane>
                        ))
                    }
                </Tabs>
            </DrawerContent>
        </Drawer>
    )
}

export default UserInfoDrawer;
