import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Divider } from 'antd';
import { Form } from 'components/form';
import { MovementAnalysisData, KEMovementAnalysisData } from '../type';
import SitToStandEditKRA from '../TrainingItemComponents/KRA/SitToStandEditKRA';
import WalkComfortablyEditKRA from '../TrainingItemComponents/KRA/WalkComfortablyEditKRA';
import WalkFastEditKRA from '../TrainingItemComponents/KRA/WalkFastEditKRA';
import StairAscentEditKRA from '../TrainingItemComponents/KRA/StairAscentEditKRA';
import StairDescentEditKRA from '../TrainingItemComponents/KRA/StairDescentEditKRA';
import SquattingEdit from '../TrainingItemComponents/SquattingEdit';
import SingleLegStanceEdit from '../TrainingItemComponents/SingleLegStanceEdit';
import PrioritizedDeficits from '../components/PrioritizedDeficits';
import { KRAdata } from 'api/record';

const RoundedBlock = styled.label`
    border-radius: 0.5rem;
    background-color: #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    color: #000000;
    margin-top: 10px;
    width: 90px;
    aspect-ratio: 1;
`;

interface MovementAnalysis extends MovementAnalysisData, KEMovementAnalysisData {}

interface Props {
    data: MovementAnalysis;
    showPreliminary: boolean;
    kraData: KRAdata[];
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    };
}

const KEMovementAnalysis: React.FC<Props> = ({ data, showPreliminary, kraData, classData }: Props) => {

    const { formatMessage } = useIntl();

    return (
        <div>
            <div className='text-24 text--bold mb-15'>{formatMessage({id:'MOVEMENT_ANALYSIS_WITH'})}</div>

            <div className='d-flex is--column'>
                <div style={{fontSize: '18px', fontWeight: 'bold'}}>{formatMessage({id:'KRA'})}</div>
                <RoundedBlock className='text-12'>{formatMessage({id:'TABLET_ONLY'})}</RoundedBlock>
            </div>

            <Divider />

            <SitToStandEditKRA kraData={kraData.filter(d=>d.typeCode === 'KEN01')} classData={classData}/>

            <Divider />

            <WalkComfortablyEditKRA kraData={kraData.filter(d=>d.typeCode === 'KEN02')} data={data} classData={classData}/>

            <Divider />

            <WalkFastEditKRA kraData={kraData.filter(d=>d.typeCode === 'KEN03')} data={data} classData={classData}/>

            <Divider />

            <StairAscentEditKRA kraData={kraData.filter(d=>d.typeCode === 'KEN04')} classData={classData}/>

            <Divider />

            <StairDescentEditKRA kraData={kraData.filter(d=>d.typeCode === 'KEN05')} classData={classData}/>

            <Divider />

            <SquattingEdit KE data={data}/>

            <Divider />

            <SingleLegStanceEdit KE data={data}/>

            {showPreliminary &&
                <Form.Item label={formatMessage({id:'REVIEW_AND_PRIORITIZE'})} name='reviewAndPrioritizeBaselineData'>
                    <PrioritizedDeficits/>
                </Form.Item>
            }
       </div>
    )
}


export default KEMovementAnalysis;
