import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';

const StyledModal = styled(Modal)`
    .ant-modal-content {
        background-color: #2E2E2E;
        box-shadow: none;

        .ant-modal-body {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

interface VideoProps {
    onClose: () => void;
    videoUrl?: string;
    videoUrl2?: string;
}

const VideoView: React.FC<VideoProps> = (props: VideoProps) => {
    return (
        <StyledModal
            visible
            centered
            closable={false}
            maskClosable
            width='auto'
            onCancel={props.onClose}
            footer={null}
        >
            <ReactPlayer url={props.videoUrl} controls height='90vh' style={{margin:'0 10px'}}/>
            {props.videoUrl2 ? (
                <ReactPlayer url={props.videoUrl2} controls height='90vh' style={{margin:'0 10px'}}/>
            ) : undefined}
        </StyledModal>
    )
}

export default VideoView;
