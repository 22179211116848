import React, { useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Button, Checkbox, Modal } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import { icon_account, icon_key, icon_error } from 'components/image';
import { apiCaller } from '../../service/apiCaller';
import { E_device, I_login, loginApi, repeatLoginApi, firstLoginApi, getPermissionApi } from '../../api/login';
import { LoadingBar, LoginCheck, ForgotPassword, BlockedModal } from '../../components/component';
import logo from '../../assets/image/login/logo_Keeogo_solid-en.svg';
import { Input, Password } from 'components/form';
import './login.sass';
import useVisible from '../../hooks/useVisible';
import styled from 'styled-components';
import { GlobalContext } from '../../index';
import { useHistory } from 'react-router-dom';

export const Login: React.FC = () => {
    console.log(process.env.REACT_APP_VERSION); // 印出版號
    const intl = useIntl();
    const rm = window.localStorage.getItem('rememberMe') === 'true';
    const [setting, setSetting] = useState({
        repeatLogin: false,
        rememberMe: rm || false,
        showError: false,
        showBlock: false,
        errorCode: '',
        login: false,
        showPolicy: false,
        isFirstLogin: false
    });
    const [user, setUser] = useState({
        account: rm ?  window.localStorage.getItem('account') || '' : '',
        pwd: '',
        sequenceId: ''
    });
    const history = useHistory();
    const { permissionCtx, setPermissionCtx } = useContext(GlobalContext);
    const login = (account: string, pwd: string) => {
        const body: I_login = {
            username: account,
            password: pwd,
            device: E_device.pc
        };
        const success = (res: any) => {
            const { status, data } = res;
            if (status === 200) {
                localStorage.setItem('account', account);
                localStorage.setItem('uid', data.data.id);
                localStorage.setItem('token', data.data.token);
                localStorage.setItem('rememberMe', setting.rememberMe.toString());
                getPermission(data.data.id);
            }
        };
        const reject = (error: any) => {console.log(error)
            const {status, data} = error;
            if (status === 400) {
                switch (data.code) {
                    case 101:
                        setSetting({...setting, showError: true, errorCode: ''});
                        break;
                    case 102:
                        setSetting({...setting, showError: true, errorCode: ''});
                        break;
                    case 103:
                        setUser({...user, sequenceId: data.detail.sequenceId})
                        setSetting({...setting, repeatLogin: true, errorCode: ''});
                        break;
                    case 105:
                        setUser({...user, sequenceId: data.detail.sequenceId})
                        setSetting({...setting, showPolicy: true, isFirstLogin: true, errorCode: ''});
                        break;
                    case 107:
                        setSetting({...setting, showError: true, errorCode:'DISABLED_ACCOUNT'});
                        break;
                    case 108:
                        setSetting({...setting, showBlock: true, errorCode: ''});
                        break;
                    default:
                        setSetting({...setting, showError: true, errorCode: ''});
                        break;
                }
            }
            console.log(setting)
        };
        apiCaller(loginApi, body, success, reject);
    };
    const repeatLogin = (isLogin: boolean) => {
        if (isLogin) {
            setSetting({...setting, repeatLogin: false, errorCode: ''});
            const success = (res: any) => {
                const { status, data } = res;
                if (status === 200) {
                    localStorage.setItem('uid', data.data.id);
                    if (user.account) {
                        localStorage.setItem('account', user.account);
                    }
                    localStorage.setItem('token', data.data.token);
                    localStorage.setItem('rememberMe', setting.rememberMe.toString());
                    getPermission(data.data.id);
                }
            };
            const reject = (error: any) => {
                const {status, data} = error;
                if (status === 400) {
                    switch (data.code) {
                        case 104:
                            setUser({...user, sequenceId: data.detail.sequenceId})
                            setSetting({...setting, repeatLogin: true, errorCode: ''});
                            break;
                        default:
                            setSetting({...setting, showError: true, errorCode: ''});
                            break;
                    }
                }
            };
            apiCaller(repeatLoginApi, user.sequenceId, success, reject);
        } else {
            setSetting({...setting, repeatLogin: false, errorCode: ''});
        }
    };
    const firstLogin = (isLogin: boolean) => {
        if (isLogin) {
            setSetting({...setting, showPolicy: false, isFirstLogin: false, errorCode: ''});
            const success = (res: any) => {
                const { status, data } = res;
                if (status === 200) {
                    localStorage.setItem('uid', data.data.id);
                    if (user.account) {
                        localStorage.setItem('account', user.account);
                    }
                    localStorage.setItem('token', data.data.token);
                    localStorage.setItem('rememberMe', setting.rememberMe.toString());
                    getPermission(data.data.id);
                }
            };
            const reject = (error: any) => {
                const {status, data} = error;
                if (status === 400) {
                    switch (data.code) {
                        case 104:
                            setUser({...user, sequenceId: data.detail.sequenceId})
                            setSetting({...setting, repeatLogin: true, errorCode: ''});
                            break;
                        default:
                            setSetting({...setting, showError: true, errorCode: ''});
                            break;
                    }
                }
            };
            apiCaller(firstLoginApi, user.sequenceId, success, reject);
        } else {
            setSetting({...setting, showPolicy: false, isFirstLogin: false, errorCode: ''});
        }
    };
    const getPermission = (userId: string) => {
        const success = (res: any) => {
            const { status, data: { data: { permission } } } = res;
            setPermissionCtx(permission);
            const { Patient= {}, Group= {} } = permission;
            if (Object.keys(Patient).length > 0) {
                window.location.href = '/Patient';
            } else if (Object.keys(Group).length > 0) {
                window.location.href = '/Group';
            } else {
                window.location.href = '/overView';
            }
        }
        const reject = (error: any) => {
            console.log(error);
        }
        apiCaller(getPermissionApi, userId, success, reject);
    }
    const changeLang = (lang: string) => {
        localStorage.setItem('lang', lang);
        window.location.reload();
    };
    return (
        <Row className='login_wrapper'>
            <LoadingBar />
            {setting.repeatLogin && <LoginCheck login={repeatLogin}/>}
            {setting.showPolicy && <Policy close={() => setSetting({...setting, showPolicy: false})} isFirst={setting.isFirstLogin} firstLogin={firstLogin} />}
            {setting.showBlock&& <BlockedModal close={()=>setSetting({...setting, showBlock: false})} />}

            <Col span={2}></Col>
            <Col span={8} className='left_col'>
                <img  className='login_logo' src={logo} />
                <div className='login_copyright'>Copyright © {new Date().getFullYear()} Wistron Corporation <br/>All Rights Reserved</div>
            </Col>
            <Col span={2}></Col>

            <Col span={12} className='right_col'>
                <main className='login_main'>

                    {/*登入*/}
                    <div className="login_header">
                        <FormattedMessage id='SIGNIN'/>
                    </div>

                    {/*帳號*/}
                    <div>
                        <FormattedMessage id='ACCOUNT' />
                        <Input
                            className='login_input stubborn'
                            style={{border: 'none'}}
                            value={user.account}
                            prefix={<img src={icon_account} style={{fontSize:'1.5rem', marginRight:'0.5rem'}} />}
                            onChange={(e: any) => setUser({...user, account: e.target.value})}
                        />

                        {/*密碼*/}
                        <FormattedMessage id='PASSWORD' />
                        <Password
                            className='login_input stubborn'
                            value={user.pwd}
                            prefix={<img src={icon_key} style={{fontSize:'1.5rem', marginRight:'0.5rem'}} />}
                            onChange={(e: any) => setUser({...user, pwd: e.target.value})}
                            visibilityToggle={true}
                            bordered={true}
                            style={{border: 'none'}}
                            onPressEnter={()=>login(user.account, user.pwd)}
                        />
                    

                        {/* 錯誤訊息 */}
                        {
                            setting.showError &&
                            <div className='error_tips'>
                                <img src={icon_error} className='mr-5'/>
                                <span>
                                    <FormattedMessage id={setting.errorCode || 'LOGIN_ERROR'} />
                                </span>
                            </div>
                        }
                    </div>

                    <div>
                        <StyledCheckbox checked={setting.rememberMe} onChange={() => setSetting({...setting, rememberMe: !setting.rememberMe})}>
                            <FormattedMessage id='AUTO_REMEMBER_ACCOUNT' /> {/*自動記錄帳號*/}
                        </StyledCheckbox>
                        <span className='login_tips'>
                            <FormattedMessage id='AUTO_REMEMBER_TIPS' /> {/*提醒您！若您使用公共電腦，請勿勾選「自動記錄帳號」，並於使用後記得「登出系統」。*/}
                        </span>
                    </div>

                    <div>
                        {/*登入*/}
                        <Button className='login_button' size='large' disabled={user.account.length < 1 || user.pwd.length < 1} onClick={() => login(user.account, user.pwd)}>
                            <FormattedMessage id='LOGIN' />
                        </Button>

                        <div className='login_other'>
                            {/*忘記密碼*/}
                            <span className='other_link' onClick={()=> history.push('/forgetPassword')}>
                                <b><FormattedMessage id='FORGET_PWD'/></b>
                            </span>
                            {/*服務條款及隱私權政策*/}
                            <span className='other_link' onClick={() => setSetting({...setting, showPolicy: true})}>
                                <FormattedMessage id='PRIVACY'/>
                            </span>
                        </div>
                    </div>
                </main>
            </Col>
        </Row>
    );
};

const StyledCheckbox = styled(Checkbox)`
    &.ant-checkbox-wrapper {
        * {
            color: black;
        }
        .ant-checkbox-inner {
            width: 1.2rem;
            height: 1.2rem;
            background-color: transparent;
            border-width: 2px;
            border-color: #0083C1;
        }
    }

    &.ant-checkbox-wrapper-checked {
        .ant-checkbox-checked 
            .ant-checkbox-inner {
                width: 1.2rem;
                height: 1.2rem;
                background-color: #0083C1;
                border-width: 2px;
                border-color: #0083C1;
            }
        }
    }
`;

const Policy : React.FC<any> = (props: any) => {
    const { close, isFirst, firstLogin } = props
    const [disabledBtn, setDisableBtn] = useState(true)
    const handleScroll = (event: any) => {
        const { scrollHeight, clientHeight , scrollTop } = event.target
        if( Math.round(scrollTop + clientHeight) >= scrollHeight ) setDisableBtn(false)
    }
    return (
        <StyledModal visible={true} closable={isFirst ? false : true} footer={null} onCancel={close} maskClosable={false}>
            <div
                className='text--xl text--center'
                style={{
                    color: 'var(--color-primary-dark)',
                    height: '30px',
                    lineHeight: '30px'
                }}
            >
                <strong><FormattedMessage id='PRIVACY'/></strong>
            </div>

            <div
                className='text--lg text--justify'
                onScroll={handleScroll}
                style={{
                    whiteSpace: 'break-spaces',
                    margin: '1.2rem',
                    padding : '1rem',
                    height: '60vh',
                    overflowY: 'auto'
                }}
            >
                <FormattedMessage id='PRIVACY_TERM_CONTENT_CAPTION_1'/>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_LI_1'/></strong>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_LI_2'/></strong>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_LI_3'/></strong>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_LI_4'/></strong>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_LI_5'/></strong>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_LI_6'/></strong>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_LI_7'/></strong>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_LI_8'/></strong>
                <FormattedMessage id='PRIVACY_TERM_CONTENT_CAPTION_2'/>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_H1'/></strong>
                <FormattedMessage id='PRIVACY_TERM_CONTENT_P1'/>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_H2'/></strong>
                <FormattedMessage id='PRIVACY_TERM_CONTENT_P2'/>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_H3'/></strong>
                <FormattedMessage id='PRIVACY_TERM_CONTENT_P3'/>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_H4'/></strong>
                <FormattedMessage id='PRIVACY_TERM_CONTENT_P4'/>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_H5'/></strong>
                <FormattedMessage id='PRIVACY_TERM_CONTENT_P5'/>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_H6'/></strong>
                <FormattedMessage id='PRIVACY_TERM_CONTENT_P6'/>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_H7'/></strong>
                <FormattedMessage id='PRIVACY_TERM_CONTENT_P7'/>
                <strong><FormattedMessage id='PRIVACY_TERM_CONTENT_H8'/></strong>
                <FormattedMessage id='PRIVACY_TERM_CONTENT_P8'/>
            </div>

            {
                isFirst && (
                    <div className='mt-12' style={{display:'flex', alignItems:'center' , justifyContent: 'center' }}>
                        <Button className='login_button mt-0 mb-10' style={{width: '360px'}} disabled={disabledBtn} onClick={() => firstLogin(true)}>
                            <FormattedMessage id='IAGGRE'/> {/* 同意 */}
                        </Button>
                    </div>
                )
            }
        </StyledModal>
    );

}

const StyledModal = styled(Modal)`
    &.ant-modal{
        max-width: 1200px !important;
        width: 90% !important;
        .ant-modal-close-x {
            color: #333;
        }
    }
`;
