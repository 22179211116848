import React, { useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { EditWrapper } from './index';
import { other_single_leg } from 'components/image';
import { Form, TextArea } from 'components/form';
import { Checkbox, Radio } from 'antd';
import { FormTitle } from '../EvaluationForms/EvaluationDrawer';
import { GlobalContext } from '../../../../../index';

const StyledCheckbox = styled(Checkbox)`
    margin: 11px 2px !important;

    .ant-checkbox {
        display: none;
    }

    span {
        padding: 10px;
        background-color: #F3F3F3;
        color: #000000;
        border-radius: 0.3rem;
        font-weight: normal;
        white-space: nowrap;
    }

    &.ant-checkbox-wrapper-checked {
        span {
            color: #FFFFFF;
            background-color: #2E63A3;
        }
    }
`;

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 3vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

interface Props {
    data: {
        makOtherSingleLegStanceAssistiveDeviceCode: string,
        makOtherLtSingleLegStanceAffectdSide: string[],
        makOtherLtSingleLegStanceAffectdSideOther: string,
        makOtherRtSingleLegStanceAffectdSide: string[],
        makOtherRtSingleLegStanceAffectdSideOther: string,
        keMakOtherSingleLegStanceAssistiveDeviceCode: string,
        keMakOtherLtSingleLegStanceAffectdSide: string[],
        keMakOtherLtSingleLegStanceAffectdSideOther: string,
        keMakOtherRtSingleLegStanceAffectdSide: string[],
        keMakOtherRtSingleLegStanceAffectdSideOther: string
    };
    KE?: boolean;
}

const SingleLegStanceEdit: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;

    const assistiveDevice = getOptions('AssistiveDevice').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    const affectOptions = getOptions('SingleLegAffectedSide').map((d: any)=>(
        <StyledCheckbox value={d.code}>{d.name}</StyledCheckbox>
    ));

    return (
        <EditWrapper>
            <div className="left">
                <div className="icon">
                    <img src={other_single_leg} width='100%' />
                </div>
                <div className="header">
                    {formatMessage({id:'ITEM_OTHER_SINGLE_LEG_STANCE'}, {br: <br/>})}
                </div>
            </div>
            <div className="right">
                <Form.Item label={<FormTitle>{formatMessage({id:'ASSISTIVE_DEVICE'})}</FormTitle>} name={props.KE ? 'keMakOtherSingleLegStanceAssistiveDeviceCode' : 'makOtherSingleLegStanceAssistiveDeviceCode'}>
                    <Radio.Group buttonStyle="solid">
                        {assistiveDevice}
                    </Radio.Group>
                </Form.Item>

                {props.data[props.KE ? 'keMakOtherSingleLegStanceAssistiveDeviceCode' : 'makOtherSingleLegStanceAssistiveDeviceCode']==='ASS04'&&
                <Form.Item name={props.KE ? 'keMakOtherSingleLegStanceAssistiveDeviceOther' : 'makOtherSingleLegStanceAssistiveDeviceOther'} className='width--full'>
                    <TextArea rows={4} placeholder='Other' maxLength={500}/>
                </Form.Item>}
                
                <FormTitle className='mb-10'>{formatMessage({id:'AFFECTED_SIDE'})}</FormTitle>

                <div style={{
                    color: '#0083C1', 
                    fontSize: '20px', 
                    fontWeight: 'bold', 
                    paddingBottom: '10px', 
                    borderBottom: '1.5px solid #E9E9E9',
                    width: '100%',
                    marginBottom: '5px'
                }}>{formatMessage({id:'LT'})}</div>
                <Form.Item className='mb-10' name={props.KE ? 'keMakOtherLtSingleLegStanceAffectdSide' : 'makOtherLtSingleLegStanceAffectdSide'}>
                    <Checkbox.Group>{affectOptions}</Checkbox.Group>
                </Form.Item>

                {props.data[props.KE ? 'keMakOtherLtSingleLegStanceAffectdSide' : 'makOtherLtSingleLegStanceAffectdSide'].some(d=>d==='SLA05')&&
                <Form.Item className='width--full' name={props.KE ? 'keMakOtherLtSingleLegStanceAffectdSideOther' : 'makOtherLtSingleLegStanceAffectdSideOther'}>
                    <TextArea rows={4} placeholder='Other' maxLength={500}/>
                </Form.Item>}


                <div style={{
                    color: '#0083C1', 
                    fontSize: '20px', 
                    fontWeight: 'bold', 
                    paddingBottom: '10px', 
                    borderBottom: '1.5px solid #E9E9E9',
                    width: '100%',
                    marginBottom: '5px'
                }}>{formatMessage({id:'RT'})}</div>
                <Form.Item className='mb-10' name={props.KE ? 'keMakOtherRtSingleLegStanceAffectdSide' : 'makOtherRtSingleLegStanceAffectdSide'}>
                    <Checkbox.Group>{affectOptions}</Checkbox.Group>
                </Form.Item>

                {props.data[props.KE ? 'keMakOtherRtSingleLegStanceAffectdSide' : 'makOtherRtSingleLegStanceAffectdSide'].some(d=>d==='SLA05')&&
                <Form.Item className='width--full' name={props.KE ? 'keMakOtherRtSingleLegStanceAffectdSideOther' : 'makOtherRtSingleLegStanceAffectdSideOther'}>
                    <TextArea rows={4} placeholder='Other' maxLength={500}/>
                </Form.Item>}
            </div>
        </EditWrapper>
    )
}

export default SingleLegStanceEdit;
