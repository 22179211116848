import React, { useState, useEffect ,useContext} from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import ImgNumber from 'components/imgNumber/imgNumber';
import { Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { PageProps } from './RecordTab';
import RecordDetail from './RecordDetail';
import { GlobalContext } from '../../../../index';
import ImageMapping from 'constants/imageMapping';
import { Record } from 'api/record';
import SharableContainer from './SharableContainer';

const Wrapper = styled.div`
    background-color: #F3F3F3;
    padding: 15px;
    width: 100%;

    .infoBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        border-bottom: 1px solid #909090;

        .left {
            display: flex;
            align-items: center;

            .icon {
                border: solid 1px rgba(0,0,0,0.65);
                border-radius: 0.5rem;
                margin-right: 5px;
                width: 25px;
                height: 25px;
            }

            .name {
                font-weight: bold;
                font-size: 20px;
                color: #2E2E2E;
                margin-right: 15px;
                cursor: pointer;
            }

            .startDate {
                color: #2E2E2E;
                font-size: 11px;
            }
        }

        .right {
            display: flex;
            align-items: center;
            color: #000000;

            .aVerticalSeparator { 
                border-left: 2px solid #E3E3E3; 
                 width: 1px;
                 height: 1.5rem;
             } 
        }
    }

    .recordPreview {
        padding: 15px 0;
        
    }
`;

interface RecordProps {
    className?: string;
    recordData: Record;
    pageProps: PageProps;
}

const RecordCard: React.FC<RecordProps> = (props: RecordProps) => {
    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const isBusinessUser = localStorage.getItem('role') === '5';

    const [videoListScroll, setVideoListScroll] = useState(0);
    const [videoListScrollWidth, setVideoListScrollWidth] = useState(0);
    const [videoListClientWidth, setVideoListClientWidth] = useState(0);
    
    useEffect(()=>{
        setVideoListScrollWidth(document.getElementById('videoList'+props.recordData.trainingId.toString())?.scrollWidth ?? 0);
        setVideoListClientWidth(document.getElementById('videoList'+props.recordData.trainingId.toString())?.clientWidth ?? 0);
    });

    const listMoveLeft = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        const element = document.getElementById('videoList'+props.recordData.trainingId.toString());
        const limit = 0;
        if(element?.scrollLeft === limit){
            return;
        }
        element?.scroll({
            left: element.scrollLeft - 300 < limit ? limit : element.scrollLeft - 300,
            behavior: 'smooth'
        });
    }

    const listMoveRight = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        const element = document.getElementById('videoList'+props.recordData.trainingId.toString());
        const limit = (element?.scrollWidth ?? 0) - (element?.clientWidth ?? 0)
        if(element?.scrollLeft === limit){
            return;
        }
        element?.scroll({
            left: element.scrollLeft + 300 > limit ? limit : element.scrollLeft + 300,
            behavior: 'smooth'
        });
    }

    const trainingItems = props.recordData.notes.map(obj => (
        <ImgNumber className='ml-10 mr-10 mb-10' img={ImageMapping.TrainingItem[obj.trainingTypeCode]} number={obj.count} label={nameMapping[obj.trainingTypeCode]} />
    ));

    const trainingFileSource = (
        isBusinessUser ? 
            props.recordData.trainingFiles.filter(d=>d.share) : 
            props.recordData.trainingFiles
        ).filter(file=>file.frontalViewVideo || file.sagittalViewVideo);
    const trainingFiles = trainingFileSource.map(file => (
        <div className='ml-10 mr-10'>
            <SharableContainer
                fileId={file.id}
                noShareIcon
                frontalView={{ videoUrl: file.frontalViewVideo, thumbnailUrl: file.frontalViewImage }}
                sagittalView={{ videoUrl: file.sagittalViewVideo, thumbnailUrl: file.sagittalViewImage }}
                updateVideoData={()=>{}}
            />
        </div>
    ));

    const uploadedVideoSource = (
        isBusinessUser ? 
            props.recordData.uploadedVideos.filter(d=>d.share) :
            props.recordData.uploadedVideos
    ).filter(video=>video.videoUrl || video.pending);
    const uploadedVideos = uploadedVideoSource.map(video => (
        <div className='ml-10 mr-10'>
            <SharableContainer
                fileId={video.id}
                noShareIcon
                singleView={{ videoUrl: video.videoUrl, thumbnailUrl: video.thumbnailUrl }}
                updateVideoData={()=>{}}
                pending={video.pending}
            />
        </div>
    ));

    return (
        <Wrapper className={props.className} onClick={() => props.pageProps.setPageContent(<RecordDetail trainingId={props.recordData.trainingId} {...props.pageProps}  />)}>
            <div className="infoBar">
                <div className="left">
                    <div className="name">
                        <b>{props.recordData.treatmentPlanMasterClassTypeCode==="CLA01" ? nameMapping['CLA01'] : formatMessage({ id: 'CLASS' })+" "+props.recordData.classNum}</b>
                    </div>
                    {/* <div className="time mr-15">
                        <b>{props.recordData.treatmentHour}</b>
                    </div>
                    <div className="startDate">
                        {`${formatMessage({ id: 'START_DATE' })}: `}{props.recordData.treatmentDate}
                    </div> */}
                </div>
                <div className="right">
                    <span className='mr-8'>
                        <b>{formatMessage({ id: 'TRAINING_ITEM' })+" x"+Object.keys(props.recordData.notes).length} </b>
                    </span>
                    <div className='aVerticalSeparator mr-8'></div>
                    <span className='mr-8'>
                        <b>{formatMessage({ id: 'VIDEO' })+" x"+(trainingFileSource.length+uploadedVideoSource.length)}</b>
                    </span>
                    {/* <div className='aVerticalSeparator mr-8'></div>
                    <span  className='mr-8'>
                        <b>{formatMessage({ id: 'PHOTO' })+" x"+props.recordData.imageNum}</b>
                    </span>
                    <div className='aVerticalSeparator mr-8'></div>
                    <span  className='mr-8'>
                        <b>{formatMessage({ id: 'KRA' })+" x"+props.recordData.KRANum}</b>
                    </span> */}
                </div>
            </div>
            <div className="recordPreview" >
                <div className='d-flex '>
                    <div style={{ width: '300px', marginRight: '15px' }} className='mb-15'><b >{formatMessage({ id: 'TRAINING_ITEMS' })}</b></div>
                    <div className='mb-15'><b>{formatMessage({ id: 'COURSE_RECORD' })}</b></div>
                </div>
                <div className='d-flex ' >
                    <div style={{ width: '300px', marginRight: '15px' }} className='d-flex is--column is--space-between'>
                        <Row gutter={24} style={{ padding: '0 10px', fontSize:'0.5rem'}}>
                            {trainingItems}
                        </Row>
                    </div>
                    <NoScrollBar id={'videoList'+props.recordData.trainingId.toString()} onScroll={()=>setVideoListScroll(document.getElementById('videoList'+props.recordData.trainingId.toString())?.scrollLeft ?? 0)}>
                        {trainingFiles.concat(uploadedVideos).slice(0, 8).length > 0 &&
                        <LeftOutlined
                            onClick={videoListScroll === 0 ? e => e.stopPropagation() : listMoveLeft} 
                            style={{ 
                                position: 'sticky',
                                left: 0,
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '30px',
                                zIndex: 500,
                                opacity: videoListScroll === 0 ? 0.3 : 1,
                                cursor: videoListScroll === 0 ? 'not-allowed' : 'pointer'
                            }}
                        />}
                        {trainingFiles.concat(uploadedVideos).slice(0, 8)}
                        {trainingFiles.concat(uploadedVideos).slice(0, 8).length > 0 &&
                        <RightOutlined 
                            onClick={videoListScroll === (videoListScrollWidth - videoListClientWidth) ? e => e.stopPropagation() : listMoveRight}
                            style={{
                                position: 'sticky',
                                right: 0,
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '30px',
                                zIndex: 500,
                                opacity: videoListScroll === (videoListScrollWidth - videoListClientWidth) ? 0.3 : 1,
                                cursor: videoListScroll === (videoListScrollWidth - videoListClientWidth) ? 'not-allowed' : 'pointer'
                            }}
                        />}
                    </NoScrollBar>
                </div>
            </div>
        </Wrapper >
    )
}

export default RecordCard;

const NoScrollBar = styled.div`
    display: flex;
    overflow-x: scroll;
    width: calc(100% - 300px);
    height: 170px;
    position: relative;

    &::-webkit-scrollbar {
        height: 0;
    }
`;

