import React, { useState, useEffect } from 'react';
import { Tooltip, Col, Row, Input, Drawer, Spin , Modal, Pagination } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import CardList from 'components/card/cardList';
import Button from 'components/button/button';
import { Search } from 'components/form/input';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

import { getProfileListById } from 'api/case';
import { apiCaller } from 'service/apiCaller';
import usePermission  from 'hooks/usePermission';
import { warning } from 'components/image';

import DateRangePicker from 'components/datePicker/dateRangePicker';
import {
    DeleteOutlined,
    PlusOutlined,
    ExportOutlined,
    ImportOutlined,
    EllipsisOutlined,
    EditOutlined,
    CopyOutlined
} from '@ant-design/icons';

import KeeogoEditProfile from './keeogoEditProfile';
import { importProfile, exportProfile, putProfile, deleteProfile} from 'api/case';
import { PaginationState } from 'components/pagination/pagination';

type ProfileSetting = {
    visible:Boolean
    type:'edit'|'add'|'copy'
    data:any
}

const JSzip = require("jszip")

type Props = {
    data:{
        addType?:string
        birthday?:string
        caseClose?: boolean
        createDateTime?:string
        createId?: number
        createdAt?: number
        fhirOrganizationId?: number
        firstName?:string
        id?: number
        lastName?:string
        medicalRecordNumber?: number
        memo?:string
        modifyDateTime?:string
        modifyId?: number
        updatedAt?: number
        userId?:string
    };
    pagination: PaginationState;
    setPaginationTotal: Function;
}

export function KeeogoProfileList(props: Props) {

    const intl = useIntl();
    const [list, setList] = useState([]);

    const [loading, setLoading]  = useState(false);
    const [sort,setSort] = useState<{
        key:string,
        sort: 'descend' | 'ascend' | null }
    >({key:'', sort: null})

    const [keyword,setKeyword] = useState('')
    const [dateRange,setDateRange] = useState([null,null])
    const [profileSetting, setProfileSetting] = useState<ProfileSetting>({visible:false, type:'edit',data:null})

    const [isExport, setIsExport] = useState(false)
    const [exportData,setExportData] = useState([])
 
    React.useEffect(()=>{
        getList()
    },[props.data, props.pagination])

    const getList = async()=>{
        const id = String(props.data.id || '')

        if(!id) return

        try{
            setLoading(true)
            await apiCaller(
                getProfileListById,
                {
                    id,
                    pageSize:props.pagination.pageSize,
                    page:props.pagination.page
                },
                (res: any) => {
                    setList(res.data.data)
                    props.setPaginationTotal(res.data.total)
                },
                (error: Error) => {throw(error)},
            )
        }catch(e){
            console.error(e)
        }finally{
            setLoading(false)
        }
    }

    const getListView = ()=>{
        let result = list

        // keyword & date filting
        const [start , end] = dateRange.map((date:any)=>date && date.unix())

        const reg = new RegExp(keyword,'g')
        result = list.filter((x:any)=>{
            const kf = reg.test(x.profileName) || reg.test(x.memo)
            if(start && end){
                const df = x.updatedAt >= start && x.updatedAt <= end
                return df && kf
            }
            return kf
        })

        // sorting
        if(sort.key === 'profileName'){
            result = result.sort((a:any,b:any)=> sort.sort === 'ascend' ? a.profileName.localeCompare(b.profileName) : b.profileName.localeCompare(a.profileName) )
        }

        if(sort.key === 'memo'){
            result = result.sort((a:any,b:any)=> sort.sort === 'ascend' ? a.memo.localeCompare(b.memo) : b.memo.localeCompare(a.memo) )
        }

        if(sort.key === 'updatedAt'){
            result = result.sort((a:any,b:any)=> sort.sort === 'ascend' ? b.updatedAt - a.updatedAt : a.updatedAt - b.updatedAt )
        }

        return result
    }

    const fields = [
        // Profile Name
        {key:'profileName', head:intl.formatMessage({id:'USER_PROFILENAME'})},
        // Remark
        {key:'memo', head:intl.formatMessage({id:'REMARK'})},
        // Update
        {key:'updatedAt', head:intl.formatMessage({id:'UPDATE_DATE'}),
            content: (x:any)=> moment(x.updatedAt).format('DD/MM/YYYY'),
        },
        {key:'action', head:'',
            content:(x:any)=>(
                <div>
                    {/* edit */}
                    <Button
                        title={intl.formatMessage({id:'EDIT'})}
                        className="is--primary light mr-8"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={()=>setProfileSetting(prv=>({...prv, visible:true ,type: 'edit', data:x}))}
                    />
                    {/* copy */}
                    <Button
                        title={intl.formatMessage({id:'COPY'})}
                        shape="circle"
                        className="is--primary light mr-8"
                        icon={<CopyOutlined />}
                        onClick={()=>setProfileSetting(prv=>({...prv , visible:true ,type: 'copy', data:x}))}
                    />
                    {/* delete */}
                    <Button
                        title={intl.formatMessage({id:'DELETE'})}
                        shape="circle"
                        className="is--primary light"
                        disabled={false}
                        icon={<DeleteOutlined />}
                        onClick={()=>{
                            Modal.warning({
                                icon: <img style={{margin:'1rem'}} src={warning} />,
                                content: <div
                                    style={{
                                        display:'flex',
                                        flexDirection:'column',
                                        alignItems:'center'
                                    }}
                                >
                                    <h1 style={{fontSize:'1.3rem'}}>
                                        {intl.formatMessage({id:'DELETE_CHECK'})}
                                    </h1>
                                    <br/>
                                    <br/>
                                    <h2>
                                        {intl.formatMessage({id:'DELETE_CHECK_INFO'})}{x.profileName}
                                    </h2>
                                    <br/>
                                    <br/>
                                    <Button
                                        className='is--warning mb-10'
                                        style={{width:'100%'}}
                                        title={intl.formatMessage({id:'YES'})}
                                        onClick={
                                            async ()=>{
                                                if(loading){
                                                    return
                                                }
                                                Modal.destroyAll()
                                                try{
                                                    setLoading(true)
                                                    const {profileName, personalInfoBasicId} = x
                                                    await deleteProfile({profileName,personalInfoBasicId})
                                                    getList()
                                                    Modal.success({
                                                        maskClosable:true,
                                                        title:`${intl.formatMessage({id:'SUCCESS'})}`
                                                    })
                                                }catch(e){
                                                    console.error(e)
                                                    Modal.error({
                                                        maskClosable:true,
                                                        title:`${intl.formatMessage({id:'ERROR'})}`,
                                                        content:e
                                                    })
                                                }finally{
                                                    setLoading(false)
                                                }
                                            }
                                        }
                                    >{intl.formatMessage({id:'YES'})}</Button>
                                    <Button
                                        className='is--plain mb-10'
                                        style={{width:'100%'}}
                                        title={intl.formatMessage({id:'CANCEL'})}
                                        onClick={()=>{
                                            Modal.destroyAll()
                                        }}
                                    >{intl.formatMessage({id:'CANCEL'})}</Button>
                                </div>
                            })
                        }}
                    />
                </div>
            )
        },
    ]


    const onImport = async (changEvt:any)=>{

        const files:File[] = Array.from(changEvt.target.files)
        if(!files.length) return

        try{

            const payload = new FormData()
            payload.append('userId',String(props.data.userId))

            for (const file of files) {

                console.log('file' , file)

                if(!/\.profile$/.test(file.name)) {
                    throw( `${file.name} type error` )
                }

                payload.append('file', file ,file.name)
            }

            await importProfile(payload)

            Modal.success ({
                title: 'Success Imported',
                maskClosable:true,
                onCancel: e => getList(),
                content:(
                    <div style={{textAlign:'left'}}>
                        <p>{intl.formatMessage({id:'FINISHED_IMPORT_PROFILES'})}</p>
                        <br/>
                        <strong>{intl.formatMessage({id:'PROFILES_NUM'})} {files.length}</strong>
                    </div>                                   
                )
            })

        }catch(e){
            console.error(e)
            Modal.error({
                title: intl.formatMessage({id:'ERROR'}),
                maskClosable:true,
                content: (
                    <div style={{ wordBreak:'break-word' , whiteSpace: 'break-spaces' ,textAlign:'center' }}>
                        {JSON.stringify(e)}
                    </div>
                )
            })
        }finally{
            // no op
        }
    }

    const onExport = async ()=>{

        const userId = String(props.data.userId)
        const profileName = exportData.map(({profileName}) => profileName)

        try{

            const res = await exportProfile({userId, profileName})
            const data = res?.data?.data ?? []

            const zip = new JSzip()
            const dir = zip.folder(userId)
            for (const { base64,name } of data) {
                dir.file(`${name}.profile`,base64,{base64: true})
            }

            const content = await zip.generateAsync({type:"blob"})

            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(content)
            a.download= `${userId || ''}-${data.length}(${moment().format('YYYYMMDDHHmmss')}).zip`

            a.click()
            window.URL.revokeObjectURL(content)

            Modal.success ({
                title: intl.formatMessage({id:'SUCCESS'}),
                maskClosable:true,
                onCancel: e => {
                    getList()
                    setExportData([])
                },
                content:(
                    <div style={{textAlign:'left'}}>
                        <p>{intl.formatMessage({id:'FINISHED_EXPORT_PROFILES'})}</p>
                        <br/>
                        <strong>{intl.formatMessage({id:'PROFILES_NUM'})} {data.length}</strong>
                    </div>
                )
            })

        }catch(e){
            console.error(e)
            Modal.error({
                title: intl.formatMessage({id:'ERROR'}),
                maskClosable:true,
                content: (
                    <div style={{ wordBreak:'break-word' , whiteSpace: 'break-spaces' ,textAlign:'center' }}>
                        {JSON.stringify(e)}
                    </div>
                ),
                onCancel: e=> setExportData([])
            })
        }finally{
            setIsExport(false)
        }
    }

    return (
        <>

            <input type="file" id="filepicker" name="fileList" hidden multiple/>

            {
                profileSetting.visible && <KeeogoEditProfile
                    basicId={String(props.data.id)}
                    type={profileSetting.type}
                    item={profileSetting.data}
                    onRefresh={()=> getList()}
                    onClose={()=>setProfileSetting(prv=>({...prv,visible:false}))}
                />
            }

            <div className="header">

                {/* 搜尋 */}
                <DateRangePicker
                    style={{
                        width: '300px'
                    }}
                    bordered={false}
                    placeholder={['start', 'end']}
                    defaultValue={dateRange}
                    inputReadOnly={true}
                    format="DD/MM/YYYY"
                    allowClear={true}
                    onChange={(e:any)=>setDateRange(e || [null, null])}
                />

                <div style={{display:'flex' , flex:'1 1 80%' , justifyContent:'flex-end'}}>
                    {/* 搜尋 */}
                    <Search
                        style={{
                            flex: '0 1 250px',
                            marginRight: '1rem'
                        }}
                        placeholder={intl.formatMessage({ id: 'SEARCH_BY_KEYWORD' })}
                        value={keyword}
                        onChange={(e: any) => {
                            setKeyword(e.target.value)
                        }}
                    />

                        {/* 選擇 匯入 & 匯出  / 取消 & 確定 */}
                        <div className='mr-20'>
                            {
                                !isExport
                                ?
                                <>
                                    <Button
                                        className="is--primary rect mr-10"
                                        icon={<ImportOutlined />}
                                        onClick={() => {
                                            // do import function from select file
                                            const dom = (document.querySelector('input#filepicker') as any)
                                            dom.value = ""
                                            dom.mutiple = true
                                            dom.click()
                                            dom.onchange = (e:any)=>onImport(e)
                                        }}
                                    >
                                        {intl.formatMessage({id:'IMPORT'})}
                                    </Button>
                                    <Button
                                        className="is--primary rect"
                                        icon={<ExportOutlined />}
                                        onClick={()=>setIsExport(true)}
                                    >
                                        {intl.formatMessage({id:'EXPORT'})}
                                    </Button>
                                </>
                                :
                                <>
                                    <Button
                                        className="is--plain rect mr-10"
                                        icon={<CloseOutlined />}
                                        onClick={()=>setIsExport(false)}
                                    >
                                        {intl.formatMessage({id:'CANCEL'})}
                                    </Button>
                                    <Button
                                        className="is--primary rect"
                                        icon={<ExportOutlined />}
                                        disabled={ !exportData.length }
                                        onClick={()=>onExport()}
                                    >
                                        {intl.formatMessage({id:'CONFIRM'})}
                                    </Button>
                                </>
                            }
                        </div>

                    {/* 新增 */}
                    <Button
                        className="is--primary rect"
                        disabled={false}
                        icon={<PlusOutlined />}
                        onClick={()=>setProfileSetting(prv=>({...prv, visible:true , type:'add' }))}
                    >
                        {intl.formatMessage({id:'ADD_UPPERCASE'})}
                    </Button>
                </div>

            </div>

            <Spin spinning={loading}>
                <CardList
                    flex={['1 1 300px', '0 1 300px', '0 1 180px' , '0 1 180px']}
                    data={getListView()}
                    fields={fields}

                    isCheckAble={isExport}
                    checkedList={exportData}
                    onCheck={(e:any)=>setExportData(e)}

                    onSort={(e:any)=>setSort(e)}
                    sort={sort}
                />
            </Spin>

        </>
    );
}
