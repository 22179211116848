import React, { useEffect, useState, useContext } from 'react';
import { TextArea } from 'components/form';
import { Button, Checkbox, Modal, Row, Col } from 'antd';
import { icon_info } from 'components/image';
import { useIntl } from 'react-intl';
import { apiCaller } from 'service/apiCaller';
import { putRecord, recordFindOne } from 'api/eavluation';
import { Form } from 'components/form';
import styled from 'styled-components';
import PrioritizedDeficits from './components/PrioritizedDeficits';
import { PageProps } from './EvaluationTab';
import { SitToStandView, GaitAnalysisView, StairAscentView, StairDescentView, SquattingView, SingleLegStanceView } from './TrainingItemComponents';
import EvaluationList from './EvaluationList';
import { GlobalContext } from '../../../../index';

const TuningPage: React.FC<PageProps> = (props: PageProps) => {

    const { formatMessage } = useIntl();
    const { getCode } = useContext(GlobalContext).dropdownOptionCtx;
    const [form] = Form.useForm();

    const [recordData, setRecordData] = useState<any>();

    useEffect(() => {
        getRecord();
    }, []);

    const getRecord = () => {
        const success = (res: any) => {
            form.setFieldsValue({
                ...res.data.data,
                part4Checklist_1: res.data.data.part4Checklist.split(',')[0] ? res.data.data.part4Checklist.split(',')[0] == 'true' : '',
                part4Checklist_2: res.data.data.part4Checklist.split(',')[1] ? res.data.data.part4Checklist.split(',')[1] == 'true' : '',
                part7Checklist_1: res.data.data.part7Checklist.split(',')[0] ? res.data.data.part7Checklist.split(',')[0] == 'true' : '',
                part7Checklist_2: res.data.data.part7Checklist.split(',')[1] ? res.data.data.part7Checklist.split(',')[1] == 'true' : '',
                reviewAndPrioritizeBaselineData: res.data.data.reviewAndPrioritizeBaselineData.map((d: any)=>({
                    ...d,
                    prioritizedDeficitsCode: getCode('Deficits', d.prioritizedDeficitsCode),
                    deficitsResolvedCode: getCode('DeficitsResolved', d.deficitsResolvedCode)
                }))
            });
            setRecordData(res.data.data);
        };
        const reject = (error: any) => {
            console.error(error);
            throw error;
        };
        apiCaller(recordFindOne, { Id: props.caseId }, success, reject);
    };

    const editRecord = () => {
        form.validateFields().then(values => {
            const success = (res: any) => {
                form.setFieldsValue(res.data.data);
                Modal.success({
                    maskClosable: true,
                    title: 'Success',
                    onCancel: ()=>props.setPageContent(<EvaluationList {...props}/>)
                });
            };
            const reject = (error: any) => {
                console.error(error);
                throw error;
            };
            apiCaller(
                putRecord,
                {
                    ...values,
                    part4Checklist: (values.part4Checklist_1 ?? '') + ',' + (values.part4Checklist_2 ?? ''),
                    part7Checklist: (values.part7Checklist_1 ?? '') + ',' + (values.part7Checklist_2 ?? ''),
                    device: 'web',
                    personalInfoBasicId: props.caseId,
                },
                success,
                reject,
            );
        });
    };

    return (
        <div style={{ background: 'white' }}>
            <Form form={form}>
                <div style={{ padding: '30px 10%', fontSize: '13px' }}>
                    <p className='mb-20'>
                        ​This document is a record sheet intended for use by clinicians when validating a tuning profile as providing the intended benefit to a user.
                    </p>
                    
                    <p className='text--bold'>Instructions: </p>
                    <p>
                        1. Follow the instructions provided in the guided tuning mode of the application or EDU-8013 to EDU-8020.
                    </p>
                    <p className='mb-30'>
                        ​2. Additional pages may be attached for any reason (obtain consent and omit identifiable data).
                    </p>
                    <p className='d-flex v--center mb-50'>
                        <img style={{ width: 16, marginRight: 10 }} src={icon_info} alt="" />
                        Validation of the tuning profile is mandatory to ensure safe, effective use of Keeogo.
                    </p>
                    <p className='text-18'>
                        <span className='text--bold'>Part 1:</span>
                        Observe functional mobility of user and summarize deficits
                    </p>
                    <p className='text-16'>
                        <span className='text--bold'>Summary of user deficits:</span>
                        (based on initial assessment)
                    </p>
                    <div className='mt-10 mb-20'>
                        <Form.Item name="part1Comment">
                            <TextArea rows={5} placeholder="(Comment)" />
                        </Form.Item>
                    </div>
                    {/* <p style={{ color: '#909090' }}>Assistive device</p>
                    <p style={{ fontWeight: 'bold', fontSize: 18 }}>Cane</p>
                    <Row gutter={24} className='mb-24'>
                        <Col span={8}>
                            <SitToStandView 
                                data={{
                                    time: recordData?.makSitStandTime || '--', 
                                    observation: recordData?.makSitStandObservation || '--'
                                }} 
                            />
                        </Col>
                        <Col span={8}>
                            <GaitAnalysisView 
                                data={{
                                    time: recordData?.makGaitAnalysisTime || '--', 
                                    fac: recordData?.makGaitAnalysisFacScoreCode || '--', 
                                    affected: recordData?.makGaitAnalysisAffectedSide || [], 
                                    affectedOther: recordData?.makGaitAnalysisAffectedSideOther || '',
                                    unaffected: recordData?.makGaitAnalysisUnaffectedSideCode || '--',
                                    unaffectedOther: recordData?.makGaitAnalysisUnaffectedSideOther || ''
                                }} 
                            />
                        </Col>
                        <Col span={8}>
                            <StairAscentView 
                                data={{
                                    time: recordData?.makStairAscentTime || '--', 
                                    stairs: recordData?.makStairAscentStairs.toString() || '--', 
                                    observation: recordData?.makStairAscentObservation || '--'
                                }} 
                            />
                        </Col>
                    </Row>
                    <Row gutter={24} className='mb-50'>
                        <Col span={8}>
                            <StairDescentView 
                                data={{
                                    time: recordData?.makStairDescentTime || '--', 
                                    stairs: recordData?.makStairDescentStairs.toString() || '--', 
                                    observation: recordData?.makStairDescentObservation || '--'
                                }} 
                            />
                        </Col>
                        <Col span={8}>
                            <SquattingView 
                                data={{
                                    assistDevice: recordData?.makOtherSquattingAssistiveDeviceCode || '',
                                    assistDeviceOther: recordData?.makOtherSquattingAssistiveDeviceOther || '',
                                    affected: recordData?.makOtherSquattingAffectdSide || [],
                                    affectedOther: recordData?.makOtherSquattingAffectdSideOther || ''
                                }} 
                            />
                        </Col>
                        <Col span={8}>
                            <SingleLegStanceView 
                                data={{
                                    assistDevice: recordData?.makOtherSingleLegStanceAssistiveDeviceCode || '',
                                    assistDeviceOther: recordData?.makOtherSingleLegStanceAssistiveDeviceOther || '',
                                    affectedLt: recordData?.makOtherLtSingleLegStanceAffectdSide || [],
                                    affectedLtOther: recordData?.makOtherLtSingleLegStanceAffectdSideOther || '',
                                    affectedRt: recordData?.makOtherRtSingleLegStanceAffectdSide || [],
                                    affectedRtOther: recordData?.makOtherRtSingleLegStanceAffectdSideOther || ''
                                }} 
                            />
                        </Col>
                    </Row> */}
                    <p className='text-18'>
                        <span className='text--bold'>Part 2: </span>Set clinical goals for use of Keeogo
                    </p>
                    <p className='text-16'>
                        <span className='text--bold'>
                            Clinical goals based on user needs: 
                        </span>
                        (may be vague or precise)
                    </p>
                    <p style={{ color: '#909090' }}>Patient goals and family expectations</p>
                    <div className='mb-50'>
                        <Form.Item name="part2Comment">
                            <TextArea rows={5} placeholder="(Comment)" />
                        </Form.Item>
                    </div>
                    <p className='text-18'>
                        <span className='text--bold'> Part 3: </span>Select an analysis method for detecting deficits related clinical goals
                    </p>
                    <p className='text-16'>
                        <span className='text--bold'>
                            Analysis methods chosen:
                        </span>
                    </p>
                    <p> Refer to Keeogo Case Record.</p>
                    <p className='mb-50' style={{fontStyle: 'italic'}}>
                        Examples: written instruments (e.g., MAS, BBS, Fugl-Meyer, etc.), questionnaires, gait observation checklists, gait mats/walkways, timed functional testing, motion-capture, or direct clinical observations.
                    </p>
                    <p className='text-18'>
                        <span className='text--bold'>Part 4:</span>
                        Collect baseline data using chosen methods
                    </p>
                    <Form.Item name="part4Checklist_1" className='mb-50'>
                            <CustomCheckbox text='Baseline data from chosen methods attached or in patient file?'/>
                    </Form.Item>

                    <Form.Item name="part4Checklist_2" className='mb-50'>
                            <CustomCheckbox text='Deficits inked toclinical goals listed in Part 2 present in baseline data?'/>
                    </Form.Item>
                    <div className='width--full d-flex'>
                        <div style={{width: 'calc((100% - 60px) / 2)'}}>
                            <div style={{width: '80%'}}>
                                <p className='text-18'>
                                    <span className='text--bold'> Part 5: </span>
                                </p>
                                <p>Review and prioritize baseline data Prioritize and list the deficits in order of planned intervention with Keeogo</p>
                            </div>
                        </div>
                        
                        <div style={{width: 'calc((100% - 60px) / 2)'}}>
                            <div style={{width: '80%'}}>
                                <p className='text-18' style={{width: '80%'}}>
                                    <span className='text--bold'> Part 6: </span>
                                </p>
                                <p> Fit Keoogo, Fine tune for deficits and fill-in the table below </p>
                            </div>
                        </div>
                    </div>

                    <div className='mb-50'>
                        <Form.Item name='reviewAndPrioritizeBaselineData'>
                            <PrioritizedDeficits/>
                        </Form.Item>
                    </div>
                    
                    <p className='text-18'>
                        <span className='text--bold'> Part 7: </span> 
                        Confirm benefit
                    </p>
                    <Form.Item name="part7Checklist_1">
                            <CustomCheckbox text='Has the clinical goals been met ?'/>
                    </Form.Item>

                    <Form.Item name="part7Checklist_2">
                            <CustomCheckbox text='Is fine tuning complete ?'/>
                    </Form.Item>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            onClick={editRecord}
                            style={{ backgroundColor: '#47A6D8', color: 'white' }}
                        >
                            {formatMessage({id:'SAVE_UPPERCASE'})}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default TuningPage;

interface CustomCheckboxProps {
    value?: boolean;
    onChange?: (val: boolean) => void;
    options?: [string, string];
    text?: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = (props: CustomCheckboxProps) => {

    const [value, setValue] = useState<boolean[]>(props.value !== undefined ? [props.value] : []);

    useEffect(()=>{
        setValue(props.value !== undefined ? [props.value] : []);
    }, [props.value]);
    
    const handleChange = (val: any[]) => {
        const newVal = val[val.length-1];
        setValue([newVal]);
        props.onChange?.(newVal);
    }

    return (
        <div>
            <Checkbox.Group value={value} onChange={handleChange}>
                <StyledCheckbox value={true}>{props.options?.[0] ?? 'YES'}</StyledCheckbox>
                <StyledCheckbox value={false}>{props.options?.[1] ?? 'NO'}</StyledCheckbox>
                <span className='ml-10'>{props.text}</span>
            </Checkbox.Group>
        </div>
    )
}

const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox {
        .ant-checkbox-inner {
            border: 2px solid #707070!important;
        }
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: #707070!important;
            border: 2px solid #707070!important;
        }
    }
`;
