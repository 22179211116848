import styled from 'styled-components';
import SitToStandViewKRA from './SitToStandViewKRA';
import SitToStandEditKRA from './SitToStandEditKRA';
import WalkComfortablyViewKRA from './WalkComfortablyViewKRA';
import WalkComfortablyEditKRA from './WalkComfortablyEditKRA';
import WalkFastViewKRA from './WalkFastViewKRA';
import WalkFastEditKRA from './WalkFastEditKRA';
import StairAscentViewKRA from './StairAscentViewKRA';
import StairAscentEditKRA from './StairAscentEditKRA';
import StairDescentViewKRA from './StairDescentViewKRA';
import StairDescentEditKRA from './StairDescentEditKRA';

export {
    SitToStandViewKRA, SitToStandEditKRA,
    WalkComfortablyViewKRA, WalkComfortablyEditKRA,
    WalkFastViewKRA, WalkFastEditKRA,
    StairAscentViewKRA, StairAscentEditKRA,
    StairDescentViewKRA, StairDescentEditKRA
}

export const KRAViewWrapper = styled.div`
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
    height: 100%;

    .header {
        font-size: 1vw;
        font-weight: bold;
        color: #000000;
        margin-bottom: 20px;
        margin-top: 5px;
        line-height: 1;
    }

    .content {
        display: flex;
        flex-direction: column;

        .icon {
            width: 25%;
            height: fit-content;
            background-color: #FFFFFF;
            padding: 5px;
            border-radius: 0.5rem;
            overflow: hidden;
            margin-bottom: 15px;

            img {
                object-fit: contain;
                aspect-ratio: 1;
            }
        }

        .label {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            .name {
                color: #909090;
                font-size: 0.7vw;
                font-weight: 500;
                line-height: 1;
            }

            .text {
                font-weight: bold;
                color: #000000;
                font-size: 0.7vw;
                word-break: break-all;

                &.large {
                    font-size: 1.2vw;
                }

                &.blue {
                    color: #0083C1;
                }
            }
        }
    }
`;

export const KRAWrapper = styled.div`
    overflow-y: scroll;
    max-height: 320px;
    margin-bottom: 15px;

    &::-webkit-scrollbar {
        width: 0px;
    }
`;
