import React from 'react';
import { overViewImage } from 'components/image';
import './overView.sass';

export const OverView: React.FC = () => {
    return (
        <div className='main' style={{width: '100%', height: '100%'}}>
            <div className='overViewImage'>
                {/* <img src={overViewImage} alt=''/> */}
            </div>
        </div>
    );
};
