import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { icon_archive, icon_import, icon_export, icon_export_blue, icon_no_parameter } from 'components/image';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/button/button';
import { PageProps, Wrapper } from './ParameterTab';
import SeparatedTable from 'components/table/seperatedTable';
import moment from 'moment';
import ParamterArchive from './ParameterArchive';
import ParameterDetail from './ParameterDetail';
import { ParameterProfile } from 'api/parameter';
import { importProfile } from 'api/case';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Modal, Progress } from 'antd';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const ParameteList: React.FC<PageProps> = (props: PageProps) => {

    const { formatMessage } = useIntl();
    const [isExport, setIsExport] = useState(false);
    const [exportData, setExportData] = useState<ParameterProfile[]>([]);
    const [progressPercent, setProgressPercent] = useState(0);
    const [showProgressModal, setShowProgressModal] = useState(false);
    const [permission] = usePermission('Parameter');

    const handleCancelExport = () => {
        setIsExport(false);
    }

    const formatExportProfile = (data: ParameterProfile[]) => {
        let fileData = [];
        let fileName = [];
        const prefixData = 'Type:UserProfile;\nVersion:3;\n';

        for(let i=0; i<data.length; ++i){
            let fileString = prefixData;    
            const parameterKeys = Object.keys(data[i]).filter(d=>d.startsWith('Par'));
            for(let j=0; j<parameterKeys.length; ++j){
                fileString = fileString.concat(`Par:${parameterKeys[j].substring(3)}=${data[i][parameterKeys[j]]};\n`);
            }
            fileName.push(data[i].profileName);
            fileData.push(
                Buffer.from(fileString, 'utf-8')
            );
        }

        return { fileName, fileData };
    }

    const handleExport = () => {
        const zip = new JSZip();
        const folderName = `${props.caseData.abbreviation}-${props.caseData.name.replaceAll(' ', '')}${props.caseData.birthday ? ('-'+moment(props.caseData.birthday).format('YYYYMMDD')) : ''}`;

        let infoData = '';
        infoData = infoData.concat(`firstName = ${props.caseData.name}\n`);
        infoData = infoData.concat(`lastName = \n`);
        infoData = infoData.concat(`dateOfBirth = ${props.caseData.birthday ? moment(props.caseData.birthday).format('YYYY-MM-DD') : ''}\n`);
        infoData = infoData.concat(`waistBelt = ${props.profileData.waistBeltCode || ''}\n`);
        infoData = infoData.concat(`waistlineInch = ${props.profileData.waistlinlnch || ''}\n`);
        infoData = infoData.concat(`waistlineCM = ${props.profileData.waistlineCm || ''}\n`);
        infoData = infoData.concat(`height = ${props.profileData.height || ''}\n`);
        infoData = infoData.concat(`weight = ${props.profileData.weight || ''}\n`);
        infoData = infoData.concat(`femoralLength = ${props.profileData.femoralLength || ''}\n`);
        infoData = infoData.concat(`tibialLength = ${props.profileData.tibialLength || ''}\n`);
        zip.file(`${folderName}/${folderName}.data`, Buffer.from(infoData, 'utf-8'));

        const { fileName, fileData } = formatExportProfile(exportData);

        for(let i=0; i<fileName.length; ++i){
            zip.file(`${folderName}/${fileName[i]}.profile`, fileData[i]);
        }

        zip.generateAsync({type: 'blob'}, metadata => {
            setShowProgressModal(true);
            setProgressPercent(Math.round(metadata.percent));
        }).then(blob => {
            setShowProgressModal(false);
            saveAs(blob, `${folderName}.zip`);
            Modal.success({
                title: <div className='text--bold text-30'>{formatMessage({id:'SUCCESSFUL'})}</div>,
                content: (
                    <div className='d-flex is--column is--center'>
                        <p className='text--center'>{formatMessage({id:'SUCCESS_EXPORT_MSG'})}</p>
                        <p className='mb-50 text--500'>{`${formatMessage({id:'PARAM_NUM'})}: ${exportData.length}`}</p>
                        <Button
                            style={{width: '80%', height: '40px', backgroundColor: '#9EC25B', color: '#FFFFFF'}}
                            onClick={()=>{setIsExport(false);Modal.destroyAll();}}
                        >{formatMessage({id:'GOOD'})} !</Button>
                    </div>
                )
            });
        }, (error: any) => {
            console.error(error);
            Modal.error({
                title: <div className='text--bold text-30'>{formatMessage({id:'FAIL_TITLE'})}</div>,
                content: (
                    <div className='d-flex is--column is--center'>
                        <p className='text--center mb-50'>{formatMessage({id:'FAIL_EXPORT_MSG'}, {br:<br/>})}</p>
                        <Button
                            style={{width: '80%', height: '40px', backgroundColor: '#DD4242', color: '#FFFFFF', marginBottom: '10px'}}
                            onClick={()=>{handleExport();Modal.destroyAll();}}
                        >{formatMessage({id:'TRY_AGAIN'})}</Button>
                        <Button
                            type='link'
                            style={{width: '80%', height: '40px', color: '#2E2E2E', fontWeight: 'bold'}}
                            onClick={()=>{setIsExport(false);Modal.destroyAll();}}
                        >{formatMessage({id:'DISMISS'})}</Button>
                    </div>
                )
            });
        });
    }

    const handleImport = () => {
        const dom = (document.querySelector('input#profilepicker') as any);
        dom.value = "";
        dom.mutiple = false;
        dom.click();
        dom.onchange = (e:any)=>onImport(e.target.files);
    }

    const onImport = async (files:any) => {
        const form = new FormData();

        form.append('userId', props.caseData.userId || '');
        for(let i=0; i<files.length; ++i){
            form.append('file', files[i], files[i].name);
        }

        await importProfile(
            form
        ).then((res: any)=>{
            Modal.success({
                title: <div className='text--bold text-30'>{formatMessage({id:'SUCCESSFUL'})}</div>,
                content: (
                    <div className='d-flex is--column is--center'>
                        <p className='text--center'>{formatMessage({id:'SUCCESS_IMPORT_MSG'})}</p>
                        <p className='mb-50 text--500'>{`${formatMessage({id:'PARAM_NUM'})}: ${files.length}`}</p>
                        <Button
                            style={{width: '80%', height: '40px', backgroundColor: '#9EC25B', color: '#FFFFFF'}}
                            onClick={()=>{props.refetch();Modal.destroyAll();}}
                        >{formatMessage({id:'GOOD'})} !</Button>
                    </div>
                )
            });
        }).catch((error: any)=>{
            console.error(error)
            Modal.error({
                title: <div className='text--bold text-30'>{formatMessage({id:'FAIL_TITLE'})}</div>,
                content: (
                    <div className='d-flex is--column is--center'>
                        <p className='text--center mb-50'>{formatMessage({id:'FAIL_IMPORT_MSG'}, {br:<br/>})}</p>
                        <Button
                            style={{width: '80%', height: '40px', backgroundColor: '#DD4242', color: '#FFFFFF', marginBottom: '10px'}}
                            onClick={()=>{onImport(files);Modal.destroyAll();}}
                        >{formatMessage({id:'TRY_AGAIN'})}</Button>
                        <Button
                            type='link'
                            style={{width: '80%', height: '40px', color: '#2E2E2E', fontWeight: 'bold'}}
                            onClick={()=>Modal.destroyAll()}
                        >{formatMessage({id:'CANCEL'})}</Button>
                    </div>
                )
            });
        });
    }

    const columns = [
        {
            dataIndex: 'profileName',
            title: formatMessage({id:'USER_PROFILENAME'}),
            render: (text:string)=>text?text:'--',
            sorter: (a:any,b:any)=> a.profileName?.localeCompare(b.profileName),
            width: 240,
            ellipsis: true
        },
        {
            dataIndex: 'memo',
            title: formatMessage({id:'REMARK'}),
            render: (text:string)=>text || '--',
            width: 500,
            ellipsis: true
        },
        {
            dataIndex: 'updatedAt',
            title: formatMessage({id:'UPDATE_DATE'}),
            render: (text:string)=>text?moment(text).format('DD/MM/YYYY'):'--',
            sorter: (a:any,b:any)=> b.updatedAt - a.updatedAt,
            width: 160,
            ellipsis: true
        }
    ];

    const NoData = (
        <div style={{width: '100%', height: 'calc(100vh - 80px - 60px - 42px - 40px - 75px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <img src={icon_no_parameter} width='100px' height='100px' />
            <Button
                icon={<PlusOutlined />}
                type='link'
                onClick={handleImport}
                style={{
                    color: '#0083C1',
                    fontSize: '24px',
                    fontWeight: 'bold'
                }}
            >{formatMessage({id:'IMPORT_PARAMETER'})}</Button>
        </div>
    );

    const onRowClick = isExport ? undefined : (record: any)=>{
        return {
            onClick: () => props.setPageContent(<ParameterDetail parameterId={record.id} parameterData={record} {...props}/>)
        }
    };

    const rowSelection = isExport ? {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setExportData(selectedRows);
        }
    } : undefined;

    return (
        <>
            <input type="file" id="profilepicker" name="fileList" hidden multiple/>
            <Modal visible={showProgressModal} footer={null} closable={false} maskClosable={true} onCancel={()=>setShowProgressModal(false)} style={{borderRadius: '0.5rem'}} >
                <Progress percent={progressPercent} strokeColor='#66BCEA' strokeWidth={15} status='active' />
                <span className='width--full d-flex is--center text-18 mt-20 text--500'>Exporting...</span>
            </Modal>

            <Wrapper>
                <div className="header v--center mb-15">
                    <div className="d-flex Title">
                        <b><strong>{formatMessage({id:'PARAMETER_LIST'})}</strong></b>
                    </div>
                    <div className="d-flex">
                        {/* Deprecated */}
                        {/* {checkIfAvailable(permission, 'Archive') && (
                            !isExport && 
                            <Button
                                className="rect"
                                icon={<img src={icon_archive} />}
                                onClick={()=>props.setPageContent(<ParamterArchive key='archive' {...props} />)}
                                style={{backgroundColor: '#F3F3F3'}}
                            />
                        )} */}
                        {checkIfAvailable(permission, 'Import') && (
                            !isExport && 
                            <Button
                                className="rect ml-5"
                                icon={<img src={icon_import} className='mr-5' />}
                                onClick={handleImport}
                                style={{backgroundColor: '#0083C1', color: '#FFFFFF'}}
                            >{formatMessage({id:'IMPORT'})}</Button>
                        )}
                        {checkIfAvailable(permission, 'Export') && (
                            props.data.length>0 && 
                            <Button
                                className="rect ml-5"
                                icon={<img src={isExport ? icon_export_blue : icon_export} className='mr-5' />}
                                onClick={() => {setIsExport(!isExport);setExportData([]);}}
                                style={isExport ? {backgroundColor: '#FFFFFF', color: '#0083C1', border: '2px solid #0083C1'} : {backgroundColor: '#0083C1', color: '#FFFFFF', border: 'none'}}
                            >{formatMessage({id:'EXPORT'})}</Button>
                        )}
                    </div>
                </div>
                {props.data.length>0 ? 
                <SeparatedTable
                    limitScroll={`calc(100vh - 80px - 60px - 42px - 40px - 75px - 55px${isExport?' - 52px':''})`}
                    columns={columns}
                    onRow={onRowClick}
                    rowSelection={rowSelection}
                    dataSource={props.data.map(d=>({...d, key: d.id}))}
                    pagination={false}
                /> : NoData}
            </Wrapper>
            {isExport && <div className="footer d-flex h--center" style={{position: 'relative', bottom: '0px', left: '0px', right: '0px', padding: '10px', width: '100%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#FFFFFF'}}>
                <Button
                    className='rect mr-20'
                    onClick={handleCancelExport}
                    type='link'
                    style={{color: '#2E2E2E'}}
                >{formatMessage({id:'CANCEL_UPPERCASE'})}</Button>
                <Button
                    className='rect'
                    onClick={handleExport}
                    style={{color: '#FFFFFF', backgroundColor: '#47A6D8'}}
                >{formatMessage({id:'EXPORT_UPPERCASE'})}</Button>
            </div>}
        </>
    );
}

export default ParameteList;
