import React, { useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';
import { GlobalContext } from '../../../../index';

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 0.5rem;

        .ant-modal-body {
            padding: 25px;
            color: #000000;
        }
    }
`;

const StyledTable = styled.table`
    width: 100%;
    font-size: 18px;
    color: #000000;

    td {
        border: 1px #000000 solid;
    }

    thead {
        background-color: #F3F3F3;
        
        tr>td {
            padding: 10px 20px;
            font-weight: bold;

            &:first-child {
                text-align: center;
            }
        }
    }

    tbody {
        tr>td {
            padding: 10px 20px;

            &:first-child {
                text-align: center;
            }
        }
    }
`;

interface ModalProps {
    onClose: () => void;
}

const BeltSizeModal: React.FC<ModalProps> = (props: ModalProps) => {

    const { formatMessage } = useIntl();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;

    const waistlineOption = getOptions('WaistBelt').sort((a: any, b: any)=>a.order-b.order).map((d: any)=>d.name);
    const waistlineInch = [[22,23,24], [25,26,27], [28,29,30], [31,32,33], [34,35,36], [37,38,39], [40,41,42], [43,44,45]];
    const waistlineCm = [[56,57,58,59,60,61], [64,65,66,67,68,69], [71,72,73,74,75,76], [78,79,80,81,82,83,84], [86,87,88,89,90,91], [94,95,96,97,98,99], [101,102,103,104,105,106,107], [109,110,111,112,113,114]];

    const beltRows = waistlineOption.map((d: string, i: number)=>(
        <tr>
            <td>{`${formatMessage({id:'BELT'})} ${d}`}</td>
            <td>
                {`${waistlineInch[i][0]}-${waistlineInch[i][waistlineInch[i].length-1]} ${formatMessage({id:'INCHES'})} (${waistlineCm[i][0]}-${waistlineCm[i][waistlineCm[i].length-1]}${formatMessage({id:'CM'})})`}
            </td>
        </tr>
    ))

    return (
        <StyledModal
            visible
            maskClosable
            closable
            centered
            width='50vw'
            onCancel={props.onClose}
            footer={null}
        >
            <div className='text--bold text-24 mb-15'>
                {formatMessage({id:'CIRCUMFERENCE_SIZE'})}
            </div>
            <StyledTable>
                <thead>
                    <tr>
                        <td>{formatMessage({id:'BELT_HASH'})}</td>
                        <td>{formatMessage({id:'WAIST_CIRCUMFERENCE'})}</td>
                    </tr>
                </thead>
                <tbody>
                    {beltRows}
                </tbody>
            </StyledTable>
        </StyledModal>
    )
}

export default BeltSizeModal;
