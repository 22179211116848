import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<Props>`
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: ${props => props.width ?? '120px'};
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    margin: 0 10px;

    &:first-child {
        margin-left: 0px;
    }

    &:last-child {
        margin-right: 0px;
    }

    &.selected {
        border: 3px solid #0083C1;
    }

    .img {
        width: 100%;
        aspect-ratio: 1.78;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .text {
        font-size: 10px;
        font-weight: bold;
        padding: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;

interface Props {
    img: string;
    text: string;
    value?: string;
    className?: string;
    selected?: boolean;
    width?: string;
    height?: string;
    onClick?: (val: string) => void;
}

const ImgCard: React.FC<Props> = (props: Props) => {

    return (
        <Wrapper {...props} className={props.className + (props.selected ? ' selected' : '')} onClick={()=>props.onClick?.(props.value||'')}>
            <div className="img">
                <img src={props.img}/>
            </div>
            <div className="text">
                {props.text}
            </div>
        </Wrapper>
    )
}

export default ImgCard;
