import React, { useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { EditWrapper } from './index';
import { other_squatting } from 'components/image';
import { Form, TextArea } from 'components/form';
import { Checkbox, Radio } from 'antd';
import { FormTitle } from '../EvaluationForms/EvaluationDrawer';
import { GlobalContext } from '../../../../../index';

const StyledCheckbox = styled(Checkbox)`
    margin: 11px 2px !important;

    .ant-checkbox {
        display: none;
    }

    span {
        padding: 10px;
        background-color: #F3F3F3;
        color: #000000;
        border-radius: 0.3rem;
        font-weight: normal;
        white-space: nowrap;
    }

    &.ant-checkbox-wrapper-checked {
        span {
            color: #FFFFFF;
            background-color: #2E63A3;
        }
    }
`;

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 3vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

interface Props {
    data: {
        makOtherSquattingAssistiveDeviceCode: string,
        makOtherSquattingAffectdSide: string[],
        makOtherSquattingAffectdSideOther: string,
        keMakOtherSquattingAssistiveDeviceCode: string,
        keMakOtherSquattingAffectdSide: string[],
        keMakOtherSquattingAffectdSideOther: string
    };
    KE?: boolean;
}

const SquattingEdit: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;

    const assistiveDevice = getOptions('AssistiveDevice').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    const affectOptions = getOptions('SquattingAffectedSide').map((d: any)=>(
        <StyledCheckbox value={d.code}>{d.name}</StyledCheckbox>
    ));

    return (
        <EditWrapper>
            <div className="left">
                <div className="icon">
                    <img src={other_squatting} width='100%' />
                </div>
                <div className="header">
                    {formatMessage({id:'ITEM_OTHER_SQUATTING'}, {br: <br/>})}
                </div>
            </div>
            <div className="right">
                <Form.Item label={<FormTitle>{formatMessage({id:'ASSISTIVE_DEVICE'})}</FormTitle>} name={props.KE ? 'keMakOtherSquattingAssistiveDeviceCode' : 'makOtherSquattingAssistiveDeviceCode'}>
                    <Radio.Group buttonStyle="solid">
                        {assistiveDevice}
                    </Radio.Group>
                </Form.Item>

                {props.data[props.KE ? 'keMakOtherSquattingAssistiveDeviceCode' : 'makOtherSquattingAssistiveDeviceCode']==='ASS04'&&
                <Form.Item name={props.KE ? 'keMakOtherSquattingAssistiveDeviceOther' : 'makOtherSquattingAssistiveDeviceOther'} className='width--full'>
                    <TextArea rows={4} placeholder='Other' maxLength={500}/>
                </Form.Item>}

                <Form.Item className='mb-10' label={<FormTitle>{formatMessage({id:'AFFECTED_SIDE'})}</FormTitle>} name={props.KE ? 'keMakOtherSquattingAffectdSide' : 'makOtherSquattingAffectdSide'}>
                    <Checkbox.Group>{affectOptions}</Checkbox.Group>
                </Form.Item>

                {props.data[props.KE ? 'keMakOtherSquattingAffectdSide' : 'makOtherSquattingAffectdSide'].some(d=>d==='SAS06')&&
                <Form.Item className='width--full' name={props.KE ? 'keMakOtherSquattingAffectdSideOther' : 'makOtherSquattingAffectdSideOther'}>
                    <TextArea rows={4} placeholder='Other' maxLength={500}/>
                </Form.Item>}
            </div>
        </EditWrapper>
    )
}

export default SquattingEdit;
