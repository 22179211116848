import React from 'react';
import { useIntl } from 'react-intl';
import ProfileCard from 'components/profileCard/profileCard';
import { ULData } from '../type';
import Label from 'components/label/label';

interface Props {
    setDrawerSetting: React.Dispatch<React.SetStateAction<{
        showDrawer: boolean;
        tabKey: string;
    }>>;
    data?: ULData;
    suspend: boolean;
}

const ULPage: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();

    return (
        <div>
            <ProfileCard editable={!props.suspend} title="Description" onEdit={()=>props.setDrawerSetting({showDrawer: true, tabKey: 'Description'})}>
                <Label name={formatMessage({id:'REMARK'})} text={<div style={{backgroundColor:'#F3F3F3',minHeight:'200px', padding:'5px', marginBottom: '10px', wordBreak: 'break-all'}}>{props.data?.llDescriptionRemark || '--'}</div>} width='100%'/>
            </ProfileCard>
        </div>
    )
}

export default ULPage;
