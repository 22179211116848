/** @jsx jsx */
// tslint:disable: quotemark no-implicit-dependencies no-shadowed-variable
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import cx from 'classnames';
import { Row, Col, Form, Tooltip, Empty, Dropdown, Menu, Modal } from 'antd';
// import { statusTag } from 'components/tag/tag';
import { Search } from 'components/form/input';

import { 
    PlusOutlined,
    EditFilled,
    MoreOutlined
} from '@ant-design/icons'; // tslint:disable-line
import { icon_edit, icon_delete, warning, icon_edit_blue } from 'components/image';
import { User, useUsers, useQueryUser, deleteUser } from 'api/user';
import useEdit from 'hooks/useEdit';
import useSearch from 'hooks/useSearch';
import Button from 'components/button/button';

import UserEditDrawer from './userMgmtEditDrawer';
import usePermission  from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';
import { FormattedMessage } from 'react-intl';
import CardList from 'components/card/cardList';
import { useIntl } from 'react-intl';
import moment from 'moment';
import styled from 'styled-components';
import Table from 'components/table/table';
import Pagination, {PaginationState} from 'components/pagination/pagination';
import { apiCaller } from 'service/apiCaller';
import { AvatarComponent } from 'components/avatar/avatar';
import Filter from 'components/filter/filter';
import { FormInstance } from 'antd/lib/form';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export default function UserManagement() {
    const [myPermission] = usePermission('Account');
    const [form] = Form.useForm();
    const {formatMessage} = useIntl();

    const { dataSource, refetch } = useUsers({
        sort: 'updatedAt',
        desc: true,
    });

    const { queryUser, userItem } = useQueryUser();

    const [editDrawerVisible, handleEditDrawerVisible, { item }] = useEdit<User>({
        dataSource: dataSource.content,
        onRequestOpen: data => {
            if (data) {
                queryUser(data.id);
            }
        },
    });

    // 分頁、排序、篩選 trigger Table onChange
    const {
        handleSearch,
        getQueries,
        handleTableChange,
        handlePaginationChange,
        pageNum,
        pageSize,
        sorter
    } = useSearch<User>({
        refetch,
        getFieldsValue: form.getFieldsValue,
        searchFields: ['name','username'],
    });

    const onFilterSubmit = (form: FormInstance) => {
        const keyword = form.getFieldValue('search');
        const rangeDate = form.getFieldValue('date') || [moment(''), moment('')];
        const startDate = rangeDate[0]?.isValid()?rangeDate[0].format('YYYY/MM/DD'):undefined;
        const endDate = rangeDate[1]?.isValid()?rangeDate[1].format('YYYY/MM/DD'):undefined;

        refetch({
            page:pageNum, 
            pageSize: pageSize,
            username: keyword,
            name: keyword,
            startDate,
            endDate
        });
    }

    const columns = [
        {
            key: 'avatar',
            width: 50,
            render: (text: any, record: any) => {
                const imgPath = record.imgPath?.data?`data:image/png;base64,${btoa(record.imgPath.data.reduce((data: any, byte: any) => data + String.fromCharCode(byte), ''))}`:undefined;
                return (
                    <AvatarComponent src={imgPath} />
                )
            }
        },
        {
            dataIndex: 'username',
            title: formatMessage({id:'ACCOUNT_ID'}),
            sorter: (a:any,b:any)=> a.username.localeCompare(b.username),
            width: 130,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'name',
            title: formatMessage({id:'NAME'}),
            sorter: (a:any,b:any)=> a.name.localeCompare(b.name),
            width: 110,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'telecomPhone',
            title: formatMessage({id:'TEL'}),
            sorter: (a:any,b:any)=> a.telecomPhone.localeCompare(b.telecomPhone),
            width: 120,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true,
            align: 'right' as 'right'
        },
        {
            dataIndex: 'telecomEmail',
            title: formatMessage({id:'MAIL'}),
            sorter: (a:any,b:any)=> a.telecomEmail.localeCompare(b.telecomEmail),
            width: 150,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'managingOrganizationName',
            title: formatMessage({id:'ORG_NAME'}),
            sorter: (a:any,b:any)=> a.managingOrganizationName.localeCompare(b.managingOrganizationName),
            width: 130,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'type',
            title: formatMessage({id:'TYPE'}),
            sorter: (a:any,b:any)=> a.type.localeCompare(b.type),
            width: 90,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'updatedAt',
            title: formatMessage({id:'UPDATE_DATE'}),
            render: (text:any)=>text?moment(text).format('DD/MM/YYYY'):'--',
            sorter: (a:any,b:any)=> b.updatedAt - a.updatedAt,
            width: 160,
            ellipsis: true
        },
        {
            dataIndex: 'active',
            title: formatMessage({id:'STATUS'}),
            render: (active: any) => StatusTag(active),
            width: 100,
            ellipsis: true
        },
        {
            key: 'action',
            width: 50,
            render: (text:any, record:any) => checkIfAvailable(myPermission, 'Edit') && (
                <Button
                    type='link'
                    style={{display: 'flex', alignItems: 'center'}}
                    icon={<img src={icon_edit_blue} className='mr-10'/>}
                    data-id={record.id}
                    onClick={handleEditDrawerVisible}
                />
            )
        }
    ];

    return (
        <Wrapper>

            <Form form={form} className="main" name="users">
                {editDrawerVisible && <UserEditDrawer
                    item={item ? userItem : undefined}
                    refetchQueries={() => refetch(getQueries())}
                    onClose={handleEditDrawerVisible}
                    editType={item ? 'edit' : 'add'}
                />}
                <div className="header">
                    <b><strong>
                        <FormattedMessage id='USER_MGMT' />{/* 帳號管理 */}
                    </strong></b>
                    <div>
                        {checkIfAvailable(myPermission, 'Search') && <Filter onSubmit={onFilterSubmit} searchBy={`${formatMessage({id:'ACCOUNT_ID'})}, ${formatMessage({id:'NAME'})}`} />}
                        {
                            checkIfAvailable(myPermission, 'Add') && (
                                <Button
                                    icon={<PlusOutlined />}
                                    className="is--primary rect ml-16"
                                    onClick={handleEditDrawerVisible}
                                />
                            )
                        }
                    </div>
                </div>
                
                <Table
                    columns={columns}
                    dataSource={dataSource.content}
                    pagination={false}
                    footer={()=>
                        <Pagination 
                            pagination={{page:pageNum, pageSize: pageSize}} 
                            setPagination={(pagination:PaginationState)=>handlePaginationChange(pagination.page, pagination.pageSize)} 
                            paginationTotal={dataSource.total || 1}
                        />
                    }
                />

            </Form>
            
        </Wrapper>
    );
}

const StatusTag = (enable: boolean) => {
    const style = {
        backgroundColor: enable?'#2E63A3':'#C4C4C4',
        color: 'white',
        borderRadius: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3px 10px',
        fontSize: ''
    };

    return (
        <div style={style}>
            {enable?'Enable':'Disable'}
        </div>
    )
}



