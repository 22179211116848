import React, { useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import ImageMapping from 'constants/imageMapping';
import { EditWrapper } from '../index';
import { Radio, Checkbox } from 'antd';
import { Form, TextArea } from 'components/form';
import { FormTitle } from '../../EvaluationForms/EvaluationDrawer';
import KRAListTable from './KRAListTable';
import { KRAdata } from 'api/record';
import { GlobalContext } from '../../../../../../index';

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 1.2vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

const StyledCheckbox = styled(Checkbox)`
    margin: 11px 2px !important;

    .ant-checkbox {
        display: none;
    }

    span {
        padding: 10px;
        background-color: #F3F3F3;
        color: #000000;
        border-radius: 0.3rem;
        font-weight: normal;
        white-space: nowrap;
    }

    &.ant-checkbox-wrapper-checked {
        span {
            color: #FFFFFF;
            background-color: #2E63A3;
        }
    }
`;

interface WalkComfortablyProps {
    kraData: KRAdata[];
    data: {
        keMakWalkComfortablyAffectedSideCode: string[],
        keMakWalkComfortablyUnaffectedSideCode: string,
    };
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    };
}

const WalkComfortablyEditKRA: React.FC<WalkComfortablyProps> = (props: WalkComfortablyProps) => {

    const { formatMessage } = useIntl();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;

    const facOptions = getOptions('FACScore').map((d: any)=>(
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    const affectOptions = getOptions('GaitAffectedSide').map((d: any)=>(
        <StyledCheckbox value={d.code}>{d.name}</StyledCheckbox>
    ));

    const unaffectOptions = getOptions('UnaffectedSide').map((d: any)=>(
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    return (
        <EditWrapper>
            <div className="left">
                <div className="icon">
                    <img src={ImageMapping.KRAExamination.KEN02} width='100%' />
                </div>
                <div className="header">
                    {formatMessage({id:'KRA_WALK_COMFORTABLY'}, {br: <br/>})}
                </div>
            </div>
            <div className="right">
                {props.kraData.length > 0 &&
                <KRAListTable
                    data={props.kraData}
                    classData={props.classData}
                    className='mb-15'
                />}

                <Form.Item label={<FormTitle>{formatMessage({id:'FAC'})}</FormTitle>} name='keMakWalkComfortablyFacScoreCode'>
                    <Radio.Group buttonStyle="solid">{facOptions}</Radio.Group>
                </Form.Item>

                <div className='text-24 text--bold mb-10'>{formatMessage({id:'GAIT_PATTERN'})}</div>

                <Form.Item className='mb-10' label={<FormTitle>{formatMessage({id:'AFFECTED_SIDE'})}</FormTitle>} name='keMakWalkComfortablyAffectedSideCode'>
                    <Checkbox.Group>{affectOptions}</Checkbox.Group>
                </Form.Item>

                {props.data.keMakWalkComfortablyAffectedSideCode.some(d=>d==='GAS08')&&
                <Form.Item className='mb-15 width--full' name='keMakWalkComfortablyAffectedSideOther'>
                    <TextArea rows={4} placeholder='Other' maxLength={500}/>
                </Form.Item>}

                <Form.Item className='mb-10' label={<FormTitle>{formatMessage({id:'UNAFFECTED_SIDE'})}</FormTitle>} name='keMakWalkComfortablyUnaffectedSideCode'>
                    <Radio.Group buttonStyle="solid">{unaffectOptions}</Radio.Group>
                </Form.Item>

                {props.data.keMakWalkComfortablyUnaffectedSideCode==='UNA02'&&
                <Form.Item className='width--full' name='keMakWalkComfortablyUnaffectedSideOther'>
                    <TextArea rows={4} placeholder='Other' maxLength={500}/>
                </Form.Item>}
            </div>
        </EditWrapper>
    )
}

export default WalkComfortablyEditKRA;
