import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row, Col, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImageMapping from 'constants/imageMapping';
import { GlobalContext } from '../../../../../index';
import VideoOption from './VideoOption';
import { TextArea } from 'components/form';
import VideoCompareDrawer from './VideoCompareDrawer';
import { TrainingPackage } from 'api/summary';
import { Video, VideoComparison } from '../SummaryEdit'
import VideoView from './VideoView';
import PrescriptionDrawer from '../PrescriptionDrawer';

interface Props { 
    trainingType: string;
    video: [VideoComparison | undefined, VideoComparison | undefined];
    setVideo: (videos: [VideoComparison | undefined, VideoComparison | undefined]) => void;
    packages: TrainingPackage[];
    edit?: boolean;
}

const TrainingItemBlock: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const [videoCompareDrawerSetting, setVideoCompareDrawerSetting] = useState({
        show: false,
        withKeeogo: true
    });
    const [videoViewSetting, setVideoViewSetting] = useState<{show: boolean, videoWithKeeogo?: Video, videoWithoutKeeogo?: Video}>({
        show: false,
        videoWithKeeogo: undefined,
        videoWithoutKeeogo: undefined
    });
    const [showPrescription, setShowPrescription] = useState(false);

    const handleRemoveVideo = (withKeeogo: boolean) => {
        props.setVideo(withKeeogo ? (
            [undefined, props.video[1]]
        ) : (
            [props.video[0], undefined]
        ));
    }

    const handleChangeVideo = (videos: [Video | undefined, Video | undefined], withKeeogo: boolean) => {
        props.setVideo(withKeeogo ? (
            [{memo: props.video[0]?.memo ?? '', videos: videos}, props.video[1]]
        ) : (
            [props.video[0], {memo: props.video[1]?.memo ?? '', videos: videos}]
        ));
    }

    const handleMemoEdit = (newMemo: string, withKeeogo: boolean) => {
        props.setVideo(withKeeogo ? (
            [{memo: newMemo, videos: props.video[0]?.videos ?? [undefined, undefined]}, props.video[1]]
        ) : (
            [props.video[0], {memo: newMemo, videos: props.video[1]?.videos ?? [undefined, undefined]}]
        ));
    }

    const videoOptions = props.video.map((d, i)=>{
        if(d){
            return (
                <Col span={11}>
                    <VideoOption 
                        removable={props.edit}
                        onRemove={props.edit ? () => handleRemoveVideo(i===0) : undefined}
                        labels={i===0 ? [formatMessage({id:'W_KEEOGO'})] : [formatMessage({id:'W_O_KEEOGO'})]} 
                        timestamp={d.videos[0]?.timestamp?.format('DD/MM/YYYY')}
                        leftView={d.videos[0]?.upload ? d.videos[0]?.uploadThumbnail : d.videos[0]?.frontalThumbnail}
                        rightView={d.videos[1]?.upload ? d.videos[1]?.uploadThumbnail : d.videos[1]?.frontalThumbnail}
                        onClick={props.edit ? ()=>{} : ()=>setVideoViewSetting({ show: true, videoWithKeeogo: d.videos[0], videoWithoutKeeogo: d.videos[1] })}
                    />
                </Col>
            )
        }
        return (
            <Col span={11}>
                {props.edit ? (
                    <AddVideoButton withKeeogo={i===0} onClick={()=>setVideoCompareDrawerSetting({show: true, withKeeogo: i===0})}/>
                ) : (
                    <div style={{
                        backgroundColor: '#E3E3E3',
                        color: '#909090',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '0.5rem',
                        padding: '10px'
                    }}>{`${formatMessage({id: i==0 ? 'W_KEEOGO' : 'W_O_KEEOGO'})} ${formatMessage({id:'NO_VIDEO'})}`}</div>
                )}
            </Col>
        )
    });
    videoOptions.splice(1, 0, <Col span={2}></Col>);

    const memos = props.video.map((d, i)=>{
        if(d){
            return (
                <Col span={11} className='d-flex is--column'>
                    <div className='text-16 text--bold'>
                        {formatMessage({id:'MEMO'})}
                    </div>
                    {props.edit ? <StyledTextArea onChange={(e: any)=>handleMemoEdit(e.target.value, i===0)} rows={3}/> : (
                        <div>
                            {d.memo}
                        </div>
                    )}
                </Col>
            )
        }
        return (
            <Col span={11}></Col>
        )
    });
    memos.splice(1, 0, <Col span={2}></Col>);

    return (
        <div className='mb-24'>
            {videoCompareDrawerSetting.show && 
            <VideoCompareDrawer
                onClose={()=>setVideoCompareDrawerSetting({show: false, withKeeogo: true})}
                trainingType={props.trainingType}
                packages={props.packages}
                selectedVideo={videoCompareDrawerSetting.withKeeogo ? 
                    [props.video[0]?.videos[0]?.id, props.video[0]?.videos[1]?.id] : 
                    [props.video[1]?.videos[0]?.id, props.video[1]?.videos[1]?.id]
                }
                setVideo={videos=>handleChangeVideo(videos, videoCompareDrawerSetting.withKeeogo)}
                withKeeogo={videoCompareDrawerSetting.withKeeogo}
            />}
            {videoViewSetting.show && 
            <VideoView
                onClose={()=>setVideoViewSetting({show: false, videoWithKeeogo: undefined, videoWithoutKeeogo: undefined})}
                videoWithKeeogo={videoViewSetting.videoWithKeeogo}
                videoWithoutKeeogo={videoViewSetting.videoWithoutKeeogo}
            />}
            {showPrescription && 
            <PrescriptionDrawer 
                onClose={()=>setShowPrescription(false)} 
                trainingType={props.trainingType} 
                selectedPackages={props.packages.map(d=>d.id)}
            />}

            {/* header */}
            <div className='d-flex is--space-between v--center mb-10'>
                <div className='d-flex v--center'>
                    <img src={ImageMapping.TrainingItem[props.trainingType]} className='mr-10'/>
                    <div className='text-20 text--bold'>{nameMapping[props.trainingType]}</div>
                </div>
                <div className='d-flex v--center'>
                    {props.packages.map(d=>(
                        <div style={{color: '#2E2E2E', paddingRight: '5px', marginRight: '5px', borderRight: '2px #E3E3E3 solid'}}>
                            {`${d.name}: ${(d.notes ? d.notes : []).filter(n=>n.trainingTypeCode===props.trainingType).reduce((acc, cur)=>acc+parseInt(cur.sets), 0)} ${formatMessage({id:'SETS'})}`}
                        </div>
                    ))}
                    <div style={{color: '#0083C1', fontWeight: 500, cursor: 'pointer'}} onClick={()=>setShowPrescription(true)}>
                        {`${formatMessage({id:'TOTAL_ROUNDS'})}: ${props.packages.reduce((acc, cur)=>acc+(cur.notes ? cur.notes : []).filter(n=>n.trainingTypeCode===props.trainingType).reduce((acc, cur)=>acc+parseInt(cur.sets), 0), 0)}`}
                    </div>
                </div>
            </div>

            {/* content */}
            <div style={{backgroundColor: '#F3F3F3', padding: '20px 0'}}>
                <Row gutter={24} style={{padding: '0 10%'}}>
                    <Col className='text-16 text--bold'>{formatMessage({id:'VIDEO_COMPARISON'})}</Col>        
                </Row>

                <Row gutter={24} style={{padding: '20px 10%'}}>
                    {videoOptions}
                </Row>
                <Row gutter={24} style={{padding: '0 10%'}}>
                    {memos}
                </Row>
            </div>
        </div>
    )
}

export default TrainingItemBlock;

interface AddVideoButtonProps {
    onClick: () => void;
    withKeeogo?: boolean;
}

const AddVideoButton: React.FC<AddVideoButtonProps> = (props: AddVideoButtonProps) => {

    const { formatMessage } = useIntl();

    return (
        <div 
            onClick={props.onClick} 
            style={{
                backgroundColor: '#E3E3E3',
                color: '#0083C1',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '0.5rem',
                padding: '10px',
                cursor: 'pointer'
            }}
        >
            <PlusOutlined style={{fontSize: '24px', marginRight: '10px'}}/>
            {formatMessage({id:'ADD_VIDEO_COMPARISON'})}
            <b className='ml-5'>{formatMessage({id: props.withKeeogo ? 'WITH_KEEOGO' : 'WITHOUT_KEEOGO'})}</b>
        </div>
    )
}

const StyledTextArea = styled(TextArea)`
    border: 2px #C4C4C4 solid;
    background-color: #FFFFFF;

    &.ant-input {
        resize: none !important;
    }
`;
