import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import SharableContainer from './SharableContainer';
import Button from 'components/button/button';
import { Row, Col } from 'antd';
import { PageProps } from './RecordTab';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import RecordDetail from './RecordDetail';
import RecordVideosUploadDrawer from './RecordVideosUploadDrawer';
import { getOneRecord, shareAllFile } from 'api/record';
import VideoView from './VideoView';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const SubheaderButton = styled(Button)`
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
    color: #0083C1;
    background-color: #FFFFFF;
    font-size: 12px;
    padding: 5px;
    display: flex;
    align-items: center;
    transform: scale(0.9);
`;

const Wrapper = styled.div`
    width: 100%;
    background-color: #FFFFFF;
    padding: 15px 20px;

    .topBar {
        display: flex;
        align-items: center;
        font-size: 20px;
        position: sticky;
        top: 0;
        background-color: #FFFFFF;
        margin-top: -15px;
        margin-left: -5px;
        width: 101%;
        padding: 15px 10px 15px 0;
        z-index: 1000;

        .header {
            color: #2E2E2E;
            margin-left: 15px;
            font-weight: bold;
        }
    }
`;

interface SingleVideo {
    id: number;
    videoUrl: string;
    thumbnailUrl: string;
    tags: string[];
    share: boolean;
    pending: boolean;
}

interface DoubleVideo {
    id: number;
    frontalViewVideo: string;
    frontalViewImage: string;
    sagittalViewVideo: string;
    sagittalViewImage: string;
    tags: string[];
    share: boolean;
}

interface VideoListProps extends PageProps {
    videos: SingleVideo[] | DoubleVideo[];
    upload?: boolean;
    trainingId: number;
}

const VideoList: React.FC<VideoListProps> = (props: VideoListProps) => {

    const { videos, upload, trainingId, ...pageProps } = props;
    const { formatMessage } = useIntl();
    const [permission] = usePermission('Record');

    const [updatedVideos, setUpdatedVideos] = useState(props.videos);
    const [showUploadDrawer, setShowUploadDrawer] = useState(false);
    const [showVideoView, setShowVideoView] = useState(false);
    const [videoViewSetting, setVideoViewSetting] = useState<{
        videoUrl: string;
        videoUrl2?: string;
    }>();

    const updateVideoData = () => {
        getOneRecord(
            props.trainingId
        ).then(res=>{
            setUpdatedVideos(props.upload ? (
                    res.data.data.uploadedVideos.map(video => ({
                        id: video.id,
                        videoUrl: video.videoUrl,
                        thumbnailUrl: video.thumbnailUrl,
                        share: video.share,
                        tags: [video.trainingTypeName, video.classification].filter(d=>d),
                        pending: video.pending
                    }))
                ) : (
                    res.data.data.trainingFiles.map(file => ({
                        id: file.id,
                        frontalViewVideo: file.frontalViewVideo,
                        frontalViewImage: file.frontalViewImage,
                        sagittalViewVideo: file.sagittalViewVideo,
                        sagittalViewImage: file.sagittalViewImage,
                        tags: [file.trainingType, file.classification].filter(d=>d),
                        share: file.share
                    }))
                )
            );
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }

    const videoBlocks = props.upload ? (updatedVideos as SingleVideo[]).filter(video=>video.videoUrl || video.pending).map(video => (
        <Col span={6} style={{margin:'15px 0'}}>
            <SharableContainer
                bgc='#2E2E2E'
                fileId={video.id}
                singleView={{ videoUrl: (video as SingleVideo).videoUrl, thumbnailUrl: (video as SingleVideo).thumbnailUrl }}
                tags={video.tags}
                share={video.share}
                noShareIcon={!checkIfAvailable(permission, 'UploadVideo', 'Share')}
                onClick={()=>{
                    setVideoViewSetting({
                        videoUrl: (video as SingleVideo).videoUrl
                    });
                    setShowVideoView(true);
                }}
                updateVideoData={updateVideoData}
                pending={video.pending}
            />
        </Col>
    )) : (updatedVideos as DoubleVideo[]).filter(videos=>videos.frontalViewVideo || videos.sagittalViewVideo).map(video => (
        <Col span={6} style={{margin:'15px 0'}}>
            <SharableContainer 
                bgc='#2E2E2E'
                fileId={video.id}
                frontalView={{ videoUrl: (video as DoubleVideo).frontalViewVideo, thumbnailUrl: (video as DoubleVideo).frontalViewImage }}
                sagittalView={{ videoUrl: (video as DoubleVideo).sagittalViewVideo, thumbnailUrl: (video as DoubleVideo).sagittalViewImage }}
                tags={video.tags}
                share={video.share}
                noShareIcon={!checkIfAvailable(permission, 'InstantRecord', 'Share')}
                onClick={()=>{
                    setVideoViewSetting({
                        videoUrl: (video as DoubleVideo).frontalViewVideo,
                        videoUrl2: (video as DoubleVideo).sagittalViewVideo
                    });
                    setShowVideoView(true);
                }}
                updateVideoData={updateVideoData}
            />
        </Col>
    ));

    return (
        <Wrapper>
            {showUploadDrawer && <RecordVideosUploadDrawer onClose={()=>{setShowUploadDrawer(false);updateVideoData();}} caseId={props.caseId} trainingId={props.trainingId} />}
            {showVideoView && <VideoView videoUrl={videoViewSetting?.videoUrl} videoUrl2={videoViewSetting?.videoUrl2} onClose={()=>setShowVideoView(false)}/>}

            <div className='topBar'>
                <LeftOutlined onClick={()=>props.setPageContent(<RecordDetail trainingId={trainingId} {...pageProps} />)} />
                <div className='header'>{props.upload ? formatMessage({id:'VIDEOS_UPLOAD'}) : formatMessage({id:'VIDEOS'})}</div>
                {props.upload && checkIfAvailable(permission, 'UploadVideo', 'Share') && 
                    <SubheaderButton
                        className='rect ml-10'
                        size='small'
                        icon={<UploadOutlined style={{ fontSize: '18px' }} />}
                        onClick={()=>setShowUploadDrawer(true)}
                    >{formatMessage({ id: 'UPLOAD' })}</SubheaderButton>
                }
                {(props.upload ? checkIfAvailable(permission, 'UploadVideo', 'Share') : checkIfAvailable(permission, 'InstantRecord', 'Share')) && 
                <Button
                    className='ml-10'
                    type='link'
                    onClick={() => shareAllFile({
                        personalTrainingRecordId: props.trainingId,
                        type: props.upload ? 'uploadVideo' : 'instantRecord'
                    }).then(updateVideoData).catch(err=>console.error(err))}
                    style={{
                        color: '#545454',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }}
                >{formatMessage({ id: 'SHARE_ALL' })}</Button>}
            </div>

            <Row gutter={24} className='width--full'>
                {videoBlocks}
            </Row>
        </Wrapper>
    )
}

export default VideoList;
