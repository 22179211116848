import React, { useState } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
    &.ant-tabs {
        width: 100%;

        &.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
            padding-left: 24px;
            padding-right: 24px;
            font-family: Poppins, Medium;
            background-color: #E3E3E3;
            color: var(--color-gray-dark);
        }

        &.ant-tabs-card .ant-tabs-bar .ant-tabs-tab-active {
            border-top: 3px solid var(--color-primary-dark);
            background-color: white;
            color: black;
            font-weight: bold;
        }

    }
`;

export const MyTabs: React.FC<any> = (props: any) => {
    const { children, onChange, ...others } = props;
    const [mykey, setMyKey] = useState<string>('1');

    const tabChange = (rowkey: any) => {
        rowkey = rowkey.replace('.$', '').replace('.', '');
        setMyKey(rowkey);
        if (onChange) {
            onChange(rowkey);
        }
    };

    return (
        <StyledTabs type={props.type} onChange={tabChange} {...others}>
            {React.Children.map(children, (child, idx) => {
                return React.cloneElement(child, {
                    ...child.props,
                    selectedKey: mykey.replace('.$', '').replace('.', ''),
                    tkey: child.key,
                });
            })}
        </StyledTabs>
    );
};

export const MyTabPane: React.FC<any> = (props: any) => {
    const { children, tab, key, selectedKey, tkey, refresh = false, ...others } = props;
    return (
        <TabPane {...others} tab={tkey} key={tkey}>
            {refresh ? tkey === selectedKey && children : children
            // { ...children }
            }
        </TabPane>
    );
};
