import React from 'react';
import styled from 'styled-components';
import { Select } from 'components/form';

const Wrapper = styled.div`
    display: flex;
    border: 1.5px solid #868686;
    padding: 5px 10px;
    border-radius: 0.5rem;
    cursor: default;
    width: max-content;
`;

interface SelectCardProps {
    img?: string;
    title: string;
    options: {
        name: string[];
        value: string[] | number[];
    };
    onChange: (val: string | number) => void;
    bordered?: boolean;
    selectWidth?: number;
    value: string | number;
    bgc?: string;
    className?: string;
    disabled?: boolean;
}

const SelectCard: React.FC<SelectCardProps> = (props: SelectCardProps) => {

    const selctOptions = props.options.name.map((name, i)=>(
        <Select.Option value={props.options.value[i]} key={i+1}>
            {name}
        </Select.Option>
    ));

    return (
        <Wrapper onClick={e=>e.stopPropagation()} className={props.className}>
            {props.img&&<div className="mr-10">
                <img style={{width:"50px"}} src={props.img}/>
            </div>}
            <div className="content">
                <div className="Title">
                    {props.title}
                </div>
                <Select disabled={props.disabled} bordered={false} onChange={props.onChange} style={{width: `${props.selectWidth||200}px`, backgroundColor: props.bgc || undefined, border: 'none'}} defaultValue={props.value}>
                    {selctOptions}
                </Select>
            </div>
        </Wrapper>
    )
}

export default SelectCard;
