import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Checkbox, Row, Col } from 'antd';
import moment from 'moment';
import { GlobalContext } from '../../../../../index';
import ImageMapping from 'constants/imageMapping';
import { TrainingPackage } from 'api/summary';

const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox {
        display: flex;
        align-items: center;

        .ant-checkbox-inner {
            border: 2px solid #707070!important;
        }
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: #707070!important;
            border: 2px solid #707070!important;
        }
    }
`;

const EllipsisAtSecondLine = styled.div`
    height: 44px;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

interface PackagePreviewProps {
    packageData: TrainingPackage;
    checked: boolean;
    onSelect: () => void;
    style?: object;
}

const PackagePreviewCard: React.FC<PackagePreviewProps> = (props: PackagePreviewProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping, getCode } = useContext(GlobalContext).dropdownOptionCtx;
    
    const trainingGoals = props.packageData.trainingGoalsToImproveCode.filter((d: string)=>d!=='PTG11').map((d: string)=>nameMapping[d]) as string[];
    if(props.packageData.trainingGoalsToImproveCode.some((d: string)=>d==='PTG11')){
        trainingGoals.push(`${nameMapping['PTG11']}: ${props.packageData.trainingGoalsToImproveOther}`);
    }
    const trainingItems = props.packageData.trainingItems.filter(d=>d!=='TTE07').map(d=>(
        <Col span={4}>
            <img 
                src={ImageMapping.TrainingItem[d]} 
                style={{
                    backgroundColor: '#F3F3F3', 
                    padding: '2px', 
                    margin: '5px',
                    aspectRatio: '1'
                }} 
            />
        </Col>
    ));

    return (
        <div className='d-flex is--column mt-10 mb-10' style={props.style}>
            <div className='d-flex v--center mb-10 cursor--pointer' onClick={props.onSelect}>
                <StyledCheckbox checked={props.checked}/>
                <div className='text-18 text--bold ml-10'>{props.packageData.name}</div>
            </div>
            <div className='d-flex is--column padding-10' style={{boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.2)', borderRadius: '0.2rem'}}>
                <div className='text-12' style={{color: '#909090'}}>
                    {formatMessage({id:'PURCHASE_DATE'})}<span>: </span>
                    {moment(props.packageData.createDateTime).format('DD/MM/YYYY')}
                </div>
                <div className='mt-15 mb-15'>
                    <div className='text-16 text--bold'>{formatMessage({id:'TRAINING_GOAL'})}</div>
                    <EllipsisAtSecondLine title={trainingGoals.join('/')}>{trainingGoals.join('/')}</EllipsisAtSecondLine>
                </div>
                <div className='width--full'>
                    <div className='text-16 text--bold'>{formatMessage({id:'TRAINING_ITEMS'})}</div>
                    <Row gutter={24}>{trainingItems}</Row>
                </div>
            </div>
        </div>
    )
}

export default PackagePreviewCard;
