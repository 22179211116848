import React from 'react';

export const Policy: React.FC<any> = (props: any) => {

    return (
        <div style={{padding: '18px 24px'}}>
            <div className='text--lg text--justify'>
                <span>
                    健康促進照護平台***(下文簡稱"***""我們"和"我們的") 深知隱私對您的重要性,並會盡全力保護您的隱私。
                    請在向我們的產品***提交個人或隱私數據之前，閱讀、瞭解並同意本《服務條款與隱私權政策》(下文簡稱"本政策")
                    <br />
                    我們制定本政策的目的在於幫助您瞭解以下內容 :
                </span>
                <br />
                <span className='text--500'>
                    1.	我們會向您收集哪些個人數據 <br />
                    2.	我們會如何蒐集、使用您的個人數據 <br />
                    3.	我們會如何保護您的個人數據 <br />
                    4.	我們會如何保護未成年人的隱私權 <br />
                    5.	我們的第三方提供商及其服務 <br />
                    6.	您的個人數據如何在全球範圍轉移 <br />
                    7.	隱私權保障條款的修正權利 <br />
                    8.	重要聲明 <br />
                </span>
                <span>
                    請注意,本政策僅適用於***的消費類電子產品，包括顯示或提及本政策的電腦應用程式、工具、軟體、網站以及服務。
                    本政策闡述了***如何處理您的個人和隱私數據並，申明***對保護隱私的承諾。建議您閱讀本政策 , 充分瞭解***或服務的隱私政策。
                </span>
                <br />
                <br />
                <span className='text--500'>
                    1.	我們會向您收集哪些個人數據
                </span>
                <br />
                <span>
                    個人數據是指在單獨使用或結合其他資訊使用時能夠確定個人身份的資訊。
                    此類數據會在您使用***或服務時被收集，包括姓名、出生日期、地址、身分證號、照片、健康醫療數據、第三方APP以及訪問產品或服務的位置和日期。
                    當您同意第三方提供予我們存取該資料時，即視為同意我們可依照本政策儲存並使用該資料。
                    <br />
                    下文為我們可能收集的個人數據類型：
                    <br />
                    (1)、 身分識別：姓名、身分證字號、信用卡號、健保卡資訊、通訊地址、電話號碼、電子郵件信箱、偏好聯絡方式等。
                    <br />
                    (2)、 個人特徵：性別、出生年月日、血型、身高、體重、習慣(如抽煙、飲酒)、個性。
                    <br />
                    (3)、 社會聯結：住家地址、旅行區域、休閒嗜好及興趣、生活格調、職業、家庭及其他成員細節、婚姻史、國籍等。
                    <br />
                    (4)、 健康記錄：病歷史、過敏史、就醫記錄、處方、檢驗、檢查、手術、醫療影像、處置、健康檢查報告、聯絡人、各式量測
                        (如人體感測資訊，如物連網感測器收集到的身體狀態資料、心跳速率、血壓、血糖、體重、體脂、體溫、壓力等；
                        或活動資訊包括跑步、步行或騎自行車等活動的步數、消耗的熱量和速度等)等。
                </span>
                <br />
                <br />
                <span className='text--500'>
                    2.	我們會如何蒐集、使用您的個人數據
                </span>
                <br />
                <span>
                    (1)、 我們可能會使用包括您生日在內的個人資訊來驗證身分、協助辨識使用者以及確認適當的服務。例如：我們可能使用出生年月日、性別、
                        健康紀錄等資訊，確認帳戶持有人屬性，以提供適當的客戶服務。
                    <br />
                    (2)、 基於***的健康服務特殊性，我們必需使用您的個人資訊及您所提供的資料來製作、開發、操作或分析判斷、研究，以確保服務及內容的準確性，或是用來改善前述作業。
                    <br />
                    (3)、 您留存的資料會被我們直接或間接服務您的狀況下：閱讀、分析、比較、整理、研究或進行補充記錄。多數作業會以匿名、自動化的方式進行，
                        但需要時，專家可能需要知道您的身分，以接受您的諮詢、或更新您的資料、進一步接觸服務您、緊急聯絡通知以保護您的健康等。
                    <br />
                    (4)、 我們可能不定時使用個人資訊，以寄送重要通知，如：（包括但不限於）服務條款、消費條件等政策變更、***最新的服務公告、健康提醒衛教、軟體更新、
                        排程課程活動等通知。此通知訊息對照顧您的健康互動至關重要，亦能提升您對***服務的使用者體驗。
                    <br />
                    (5)、 我們會記錄您的個人資訊以讓您享有更便利、更個人化的服務體驗。 舉例來說：如果我們得知您的名字、就能在您下次使用***時向您表示歡迎；
                        或填寫相關問卷時減少不必要的重複填寫。我們和大多數線上服務一樣、會自動收集部分資訊並將其儲存於記錄檔中。這類資訊包括網路通訊協定（IP）位址、
                        瀏覽器類型及語言、網路服務供應商（ISP）、轉介和離開系統和應用程式、作業系統、日期/時間戳記及點選填寫資料。我們使用這類資訊瞭解及分析趨勢、
                        管理系統、瞭解使用者在系統上的行為、判定使用者對特定主題的關注程度，並衡量與使用者溝通的效益、改善我們的產品和服務，
                        以及收集整體使用者的相關人口統計資訊。我們得使用前述資訊從事相關活動通知、快速填寫表單問卷、產品服務匹配通知服務、選擇遞送訊息方式時機等整合運用。
                    <br />
                    (6)、 我們可能會提供您個人化的服務，包括內容和廣告。我們會用您所提供的個人資料，為您打造個人化的服務，包括個人化內容或建議活動。
                        例如：若您過去曾參與某一系列活動，代表您對該活動有興趣，當該活動有最新消息，我們會優先知會予您。
                    <br />
                    (7)、 若您參加課程、揪團或類似健康活動，我們也可能使用您提供的資訊進行這些活動。
                    <br />
                    (8)、 我們也可能基於內部目的而使用個人資訊，例如進行資料分析及研究改進***的產品、服務。
                    <br />
                    (9)、 我們可能會基於損失預防及反詐騙目的而使用您個人資訊。
                </span>
                <br />
                <br />
                <span className='text--500'>
                    3.	我們會如何保護您的個人數據
                </span>
                <br />
                <span>
                    我們非常重視您個人資訊的安全性。***服務都會在資料傳輸期間使用加密技術來保護您的個人資訊。我們會使用具有存取限制的電腦系統來儲存您的個人資料、
                    並會將這些電腦系統存放在具有實體安全措施的場所。
                    <br />
                    我們會採取一切合理可行的措施，保護您的個人數據。例如，我們會使用加密技術確保數據的機密性；我們會使用受信賴的保護機制防止數據遭到惡意攻擊 ; 
                    我們會部署權限控制機制，確保只有授權人員才可訪問個人數據。我們會採取一切合理可行的措施 , 確保未收集無關的個人數據。
                    我們只會在達成本政策所述目的所需的期限內保留您的個人數據，除非需要延長保留期或受到法律的允許。我們會盡力保護您的個人數據，
                    但是請注意任何安全措施都無法做到無懈可擊。
                </span>
                <br />
                <br />
                <span className='text--500'>
                    4.	我們會如何保護未成年人的隱私權
                </span>
                <br />
                <span>
                    我們的產品和服務主要面向為成人。如果沒有父母或監護人的同意 , 不得創建未成年人帳號。對於經父母同意而收集未成年人數據的情況，我們只會在受到法律允許，
                    父母或監護人明確同意或者保護未成年人所必要的情況下使用或披露此數據。
                </span>
                <br />
                <br />
                <span className='text--500'>
                    5.	第三方提供商及其服務
                </span>
                <br />
                <span>
                    我除非與第三方合作夥伴合作或得到您授權，我們絕不會提供其他第三人任何您的個人資料給其他個人、團體或企業。
                    我們也會嚴格限制合作夥伴使用和揭露我們所提供的資料。以下是我們與其分享資訊的第三方合作夥伴類型：
                    <br />
                    (1)、 我們的關聯企業：我們的關聯企業是指我們及我們現在與未來所組設之分公司、子公司、辦事處、工廠、關聯企業及（或）其他營業組織。
                    「關聯企業」係指以下任何型態的事業組織：直接或間接控制我們、直接或間接受我們控制、與我們同受某一企業或同一經營團隊控制。
                    <br />
                    (2)、 服務供應商：***服務可能含有連結至第三方系統、產品或服務的連結。我們的產品與服務亦可能使用或提供第三方產品或服務，
                    例如：APP、各式量測設備、物流廠商、商城、金融機構等。第三方所收集的資料可能包括如位置資料或詳細聯絡資料等，均依據該第三方的隱私權實務為準。
                    我們鼓勵您瞭解這些第三方的隱私權實施方式。我們會提供上述服務供應商必要的個人資訊，以進行如服務提供、資訊處理、完成訂單與收款事項；
                    或是管理及加強您的資料、提供售後服務及評估您對產品及服務的興趣；以及執行滿意度調查或研究相關流程及品質適切性等。這類公司有義務保護您的資訊，
                    並可能位於***營運的任何地方。
                    <br />
                    (3)、 政府機關：您居住地的法律、法律程序、訴訟或公共和政府機關可能會要求***必須揭露您的個人資訊。我們也可能基於國家安全、執法或其他重要公共議題，
                    在判定為必要及適當的情況下，揭露您的個人資訊。我們也可能為了執行企業的條款與條件、保障企業權益或保障使用者，在判定為合理必要的情況下揭露您的相關資訊。
                </span>
                <br />
                <br />
                <span className='text--500'>
                    6.	您的個人數據如何在全球範圍轉移
                </span>
                <br />
                <span>
                    我們通過遍佈全球的資源和服務器提供產品和服務。這意味著，您的個人數據可能會被轉移到您使用產品或服務所在國家/地區境外的其他管轄區，
                    或者受到來自這些管轄區的訪問。此類管轄區可能設有不同的數據保護法，甚至未設立相關法律。在此類情況下，我們會確保您的數據得到所有適用
                    法律和法規要求的足夠同等保護。
                </span>
                <br />
                <br />
                <span className='text--500'>
                    7.	隱私權保障條款的修正權利
                </span>
                <br />
                <span>
                    隱私權保護政策將因應科技發展趨勢、相關法規之修訂或其他環境變遷等因素而為適當之修改，以落實保障使用者隱私權之立意。如有修改本條款時，
                    將透過本服務之網站/網頁或程式或相關系統頁面公告。使用者應隨時注意相關修改或變更，若使用者於變更或修改後繼續使用本服務，視為使用者已
                    接受及同意變更或修改的隱私權保護政策，假如使用者不同意變更或修改後之政策內容，請您立即停止使用本服務。
                </span>
                <br />
                <br />
                <span className='text--500'>
                    8.	重要聲明
                </span>
                <br />
                <span>
                    請妥善保管您的個人帳號、密碼，以防止他人蒐集並使用您的個人資料。您對 我們所提供之個人所屬帳號、密碼負保管之責。如您未妥善保管而遭盜用或遭偽造所致之損害，我們不負任何賠償責任。
                </span>
            </div>
        </div>
    );
};
