import React from 'react';
import styled from 'styled-components';
import HoverIcon from 'components/hoverIcon/hoverIcon';

const CardWrapper = styled.div<{selected?: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: ${props => props.selected ? '#E0F2FB' : '#FFFFFF'};

    .left {
        display: flex;
        align-items: center;

        .className {
            font-weight: bold;
            margin-right: 10px;
        }

        .info {
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .profile {
                color: #909090;
            }
        }
    }

    .right {
        display: flex;
        align-items: center;

        .keeogoLevel {
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

interface ClassProps {
    name: string;
    treatmentDate: string;
    treatmentTime: string;
    profile: string;
    assistDevice: string[];
    keeogoLevel: string;
    onClick: () => void;
    selected?: boolean;
}

const ClassPreviewCard: React.FC<ClassProps> = (props: ClassProps) => {

    return (
        <CardWrapper onClick={props.onClick} selected={props.selected}>
            <div className="left">
                <div className="className">{props.name}</div>
                <div className="info">
                    <div className="datetime">
                        {props.treatmentTime} {props.treatmentDate}
                    </div>
                    <div className="profile">
                        {props.profile}
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="keeogoLevel">{props.keeogoLevel}</div>
                <HoverIcon icon='A' hoverItem={props.assistDevice} />
            </div>
        </CardWrapper>
    )
}

export default ClassPreviewCard;
