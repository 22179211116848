import { Loader, LoaderOptions } from 'google-maps';
import { cityList } from '../components/geoMap/taiwanCtiy';
import cityGeo from './city.json';
import townGeo from './townByCity.json';
import * as _r from 'ramda';

const options: LoaderOptions = {};
const loader = new Loader('AIzaSyCnFBVec8n40fTUMY6bRBBRcrCvHqSdNeg', options);
let map: any = null;
const markers: any[] = [];
let defaultSetting = {
    zoom: 0,
    lat: 0,
    lng: 0
};
const TWSetting = {
    zoom: 8,
    lat: 23.83876,
    lng: 120.9876
}
const featureStyle = {
    strokeColor: '#027F96',
    strokeOpacity: 0.9,
    strokeWeight: 2,
    fillColor: '#38D6BC',
    fillOpacity: 0.4
}

export async function initWnd() {
    const google = await loader.load();
    const mapOpt = {
        center: {lng: 121.53453826904297, lat: 25.0487470849144},
        zoom: 11,
        disableDefaultUI: true,
        styles: style
    };
    map = new google.maps.Map(document.getElementById('TGMap') as Element, mapOpt);
    map.data.setStyle(featureStyle);
}

export function drawMap(level: any, countryId: string, cityId: string) {
    cleanAllMarker();
    map.data.forEach((item: any) => map.data.remove(item));
    if (level === 0) {
        if (countryId === 'TW') {
            const {lng, lat, zoom} = TWSetting;
            map.setCenter({lng, lat});
            map.setZoom(zoom);
            defaultSetting = TWSetting;
            map.data.addGeoJson(cityGeo, countryId);
            cityList.map(item => {
                return {
                    label: item.name,
                    lat: item.center.lat,
                    lng: item.center.lng
                }
            }).map((item: any) => addMarker(item));
        }
    } else {
        if (countryId === 'TW') {
            const city: any = _r.find(_r.propEq('id', cityId))(cityList);
            const { center, zoom } = city;
            map.setCenter(center);
            map.setZoom(zoom);
            defaultSetting = { zoom, ...center };
            map.data.addGeoJson({
                'type': 'FeatureCollection',
                'features': townGeo[cityId]
            }, cityId);
            cityList.find(item => item.id === cityId)?.districts.map(item => {
                return {
                    label: item.name,
                    lat: item.center.lat,
                    lng: item.center.lng
                }
            }).map((item) => addMarker(item));
        }
    }
    map.data.addListener('mouseover', (event: any) => {
        map.data.revertStyle();
        map.data.overrideStyle(event.feature, {fillColor: '#00809F'});
    });

    map.data.addListener('mouseout', () => map.data.revertStyle());
}

function addMarker(e: any) {
    markers[markers.length] = new google.maps.Marker({
        position: {
            lat: e.lat,
            lng: e.lng
        },
        map,
        label: {
            text: '100', // e.label,
            color: '#fff'
        },
        icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 20,
            fillColor: '#00809f',
            strokeColor: '#00809f',
            strokeWeight: 0.1,
            fillOpacity: 1,
        }
    });
}

function cleanAllMarker() {
    markers.forEach(item => item.setMap(null));
}

export function reSetCenter() {
    const {lat, lng, zoom} = defaultSetting;
    map.setCenter({lat, lng});
    map.setZoom(zoom);
}

export function zoomInHandler() {
    map.setZoom(map.getZoom() + 1);
}

export function zoomOutHandler() {
    map.setZoom(map.getZoom() - 1);
}

// map style
const style: any[] = [
    {
        'featureType': 'administrative',
        'elementType': 'geometry',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'administrative.land_parcel',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'administrative.neighborhood',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'poi',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'poi',
        'elementType': 'labels.text',
        'stylers': [
            {
                'color': '#ecd793'
            },
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'road',
        'stylers': [
            {
                'color': '#ece1a9'
            },
            {
                'saturation': -30
            },
            {
                'lightness': 20
            }
        ]
    },
    {
        'featureType': 'road',
        'elementType': 'labels',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'road',
        'elementType': 'labels.icon',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'road.arterial',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'road.highway',
        'elementType': 'labels',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'road.local',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'transit',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'water',
        'elementType': 'labels.text',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    }
];
