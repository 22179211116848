import React from 'react';
import { useIntl } from 'react-intl';
import ImageMapping from 'constants/imageMapping';
import { EditWrapper } from '../index';
import { Form, TextArea } from 'components/form';
import NumberInput from 'components/numberInput/numberInput';
import { FormTitle } from '../../EvaluationForms/EvaluationDrawer';
import KRAListTable from './KRAListTable';
import { KRAdata } from 'api/record';

interface StairAscentProps {
    kraData: KRAdata[];
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    };
}

const StairAscentEditKRA: React.FC<StairAscentProps> = (props: StairAscentProps) => {

    const { formatMessage } = useIntl();

    return (
        <EditWrapper>
            <div className="left">
                <div className="icon">
                    <img src={ImageMapping.KRAExamination.KEN04} width='100%' />
                </div>
                <div className="header">
                    {formatMessage({id:'ITEM_STAIR_ASCENT'}, {br: <br/>})}
                </div>
            </div>
            <div className="right">
                {props.kraData.length > 0 &&
                <KRAListTable
                    data={props.kraData}
                    classData={props.classData}
                    className='mb-15'
                />}

                <Form.Item label={<FormTitle>{formatMessage({id:'STAIRS'})}</FormTitle>} name='keMakStairAscentStairs'>
                    <NumberInput bold allowZero editable/>
                </Form.Item>

                <Form.Item className='width--full' label={<FormTitle>{formatMessage({id:'OBSERVATION'})}</FormTitle>} name='keMakStairAscentObservation'>
                    <TextArea rows={4} placeholder='(content)' maxLength={500}/>
                </Form.Item>
            </div>
        </EditWrapper>
    )
}

export default StairAscentEditKRA;
