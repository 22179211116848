import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import { PageProps, Wrapper } from './ParameterTab';
import SeparatedTable from 'components/table/seperatedTable';
import ParameterList from './ParameterList';
import ParameterDetail from './ParameterDetail';

// Deprecated
const ParameterArchive: React.FC<PageProps> = (props: PageProps) => {

    const { formatMessage } = useIntl();

    useEffect(()=>{
        props.refetch({ caseId: props.caseId, invalid: true });
    }, []);

    const columns = [
        {
            dataIndex: 'profileName',
            title: formatMessage({id:'USER_PROFILENAME'}),
            render: (text:string)=>text?text:'--',
            sorter: (a:any,b:any)=> a.profileName?.localeCompare(b.profileName),
            width: 240,
            ellipsis: true
        },
        {
            dataIndex: 'memo',
            title: formatMessage({id:'REMARK'}),
            render: (text:string)=>text || '--',
            width: 500,
            ellipsis: true
        },
        {
            dataIndex: 'updatedAt',
            title: formatMessage({id:'UPDATE_DATE'}),
            render: (text:string)=>text?moment(text).format('DD/MM/YYYY'):'--',
            sorter: (a:any,b:any)=> b.updatedAt - a.updatedAt,
            width: 160,
            ellipsis: true
        }
    ];

    const onRowClick = (record: any)=>{
        return {
            onClick: () => props.setPageContent(<ParameterDetail parameterId={record.id} parameterData={record} {...props}/>)
        }
    };

    return (
        <Wrapper>
            <div className="header d-flex v--center mb-15">
                <div className="Title">
                    <LeftOutlined onClick={()=>{props.refetch();props.setPageContent(<ParameterList {...props} />)}} />
                    <span className='ml-15'><b><strong>{formatMessage({id:'PARAMETER_ARCHIVE'})}</strong></b></span>
                </div>
            </div>
                <SeparatedTable
                    limitScroll={`calc(100vh - 80px - 60px - 42px - 40px - 75px - 55px)`}
                    columns={columns}
                    dataSource={props.data}
                    pagination={false}
                    onRow={onRowClick}
                />
        </Wrapper>
    );
}

export default ParameterArchive;
