import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .box {
        border-radius: 0.5rem;
        background-color: #E3E3E3;
        display: flex;
        

        .img {
            border-radius: 0.3rem;
            overflow: hidden;
            display: flex;
            background-color: #FFFFFF;
            height: 50px;
            width: 50px;
            
            img {
                width: 100%;
                height: 100%;
            }
        }

        .number {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #909090;
            padding: 10px;
        }
    }
    
    .label {
        margin-top: 5px;
        color: #2E2E2E;
        font-size: 12px;
    }
`;
interface Props {
    number: number;
    img: string;
    label?: string;
    className?: string;
}

const ImgNumber: React.FC<Props> = (props: Props) => {
    return (
        <Wrapper className={props.className}>
            <div className="box">
                <div className="img">
                    <img src={props.img} />
                </div>
                <div className="number">
                    {props.number}
                </div>
            </div>
            {props.label&&
            <div className="label">
                {props.label}
            </div>}
        </Wrapper>
    )
}

export default ImgNumber;
