interface PermissionBase {
    name: string;
    available: boolean;
}

export interface PermissionNode extends PermissionBase {
    children?: PermissionNode[];
    parent?: PermissionBase;
}

export interface RoleType {
    amount: number
    createdAt: number
    description: string
    id: number
    name: string
    permission: string;
    updatedAt: number
}

export const makePermissionNode = (template: Object, obj: Object): PermissionNode[] => {
    let nodes: PermissionNode[] = [];
    for(let prop in template){
        nodes.push({
            name: prop,
            available: obj?.[prop] ? true : false
        })
        if(typeof template[prop] === 'object'){
            nodes[nodes.length-1].children = makePermissionNode(template[prop], obj[prop]);
            let { parent, children, ...parentPermissionBase} = nodes[nodes.length-1];
            nodes[nodes.length-1].children?.forEach(c=>c.parent = parentPermissionBase);
        }
    }
    return nodes;
}

export const checkIfAvailable = (permission: PermissionNode, key: string, subKey?: string): boolean => {
    const keyPermission = permission.children?.find(n=>n.name==key);
    if(subKey && keyPermission?.children){
        return keyPermission.children?.find(n=>n.name==subKey)?.available || false;
    }
    return keyPermission?.available || false;
}

export const nodeToJson = (nodes: PermissionNode[]): Object => {
    let result = {};
    nodes.forEach(node=>{
        if(node.available && node.children){
            result[node.name] = nodeToJson(node.children);
        }
        else{
            result[node.name] = node.available;
        }
    })

    return result;
}