import React from 'react';
import Button from 'components/button/button';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
    onRemove: (id: number) => void;
    id: number;
    name: string;
    style?: object;
    bgc?: string;
}

const OptionButton: React.FC<Props> = (props: Props) => {
    return (
        <Button
            onClick={()=>props.onRemove(props.id)}
            style={{
                color: '#FFFFFF',
                backgroundColor: props.bgc ?? '#000000',
                margin: '2px',
                ...props.style
            }}
        >
            <div className='d-flex v--center'>
                <span>{props.name}</span>
                <CloseOutlined className='ml-15' style={{color: '#FFFFFF'}}/>
            </div>
        </Button>
    )
}

export default OptionButton;
