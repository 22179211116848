import * as React from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import styled from 'styled-components';

export const LineChart: React.FC<any> = React.memo((props: any) => {
    const { data, clickAction, amount } = props;
    const myCharts = React.useRef<any>(null);
    const onEvents = {
        'click': function(parames: any) {
            clickAction(parames);
        },
        'mouseover': function(parames: any) {
            show(parames);
        }
    };
    function show(params: any) {
        const { value, color } = params;
        const myChart = myCharts.current.getEchartsInstance();
        myChart.setOption({
            graphic: {
                type: 'group',
                silent: true,
                children: [
                    {
                        type: 'rect',
                        top: 0,
                        left: params.dataIndex * (myChart.getWidth() / amount),
                        shape: {
                            width: (myChart.getWidth() / amount),
                            height: 340,
                        },
                        silent: true,
                        style: {
                            fill: '#0DA5AB',
                            stroke: '#0DA5AB',
                            opacity: 0.1
                        }
                    },
                    {
                        type: 'line',
                        shape: {
                            x1: myChart.convertToPixel('grid', [value[0], value[3]])[0],
                            y1: myChart.convertToPixel('grid', [value[0], value[3]])[1],
                            x2: myChart.convertToPixel('grid', [value[0], value[4]])[0],
                            y2: myChart.convertToPixel('grid', [value[0], value[4]])[1],
                        },
                        silent: true,
                        style: {
                            stroke: color,
                            lineDash: [3]
                        }
                    },
                    {
                        type: 'circle',
                        position: myChart.convertToPixel('grid', [value[0], value[3]]),
                        shape: {
                            cx: 0, cy: 0, r: 4
                        },
                        style: {
                            fill: color
                        }
                    },
                    {
                        type: 'circle',
                        position: myChart.convertToPixel('grid', [value[0], value[4]]),
                        shape: {
                            cx: 0, cy: 0, r: 4
                        },
                        style: {
                            fill: color
                        }
                    }
                ]
            }
        });
    }

    const getOption = () => {
        return {
            grid: {
                left: 0,
                right: 0,
                top: 29,
                bottom: 29,
            },
            tooltip : {
                trigger: 'item',
                axisPointer: {
                    axis: 'x',
                    show: true,
                    type: 'shadow',
                    shadowStyle: {
                        color: 'red'
                    }
                },
                formatter: function(params: any) {
                    return `${params.value[0]} <br />${params.value[1]}${params.value[2]}
                        ${params.seriesName}(${params.value[5]}) : 最高 ${params.value[3]} / 最低 ${params.value[4]}`;
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    interval: 0,
                    lineStyle: {
                        color: '#dddddd'
                    }
                }
            },
            yAxis: {
                type: 'value',
                maxInterval: 3,
                minInterval: 3,
                interval: 15,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#dddddd'
                    }
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,
                }
            },
            series: [
            {
                data: Array.from({length: amount}, (v, i) => [`2020/4/5 (早)${i}`, Math.floor(Math.random() * 40), Math.floor(Math.random() * 40), 40, 20, 1]),
                name: 'kg',
                smooth: true,
                type: 'line',
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    width: 2,
                    color: '#74A8B7'
                },
                itemStyle: {
                    color: (params: any) => {
                        if (params.value[1] < 28 || params.value[1] > 32) {
                            return '#E85050';
                        } else {
                            return '#74A8B7';
                        }
                    }
                },
            },
            {
                data: Array.from({length: amount}, (v, i) => [`2020/4/5 (早)${i}`, Math.floor(Math.random() * 40) + 20, Math.floor(Math.random() * 40) + 20, 35, 18, 1]),
                name: 'yed',
                smooth: true,
                type: 'line',
                symbol: 'circle',
                symbolSize: 20,
                lineStyle: {
                    width: 0,
                },
                itemStyle: {
                    color: (params: any) => {
                        if (params.value[1] < 28 || params.value[1] > 32) {
                            return '#E85050';
                        } else {
                            return '#837A6C';
                        }
                    }
                },
                label: {
                    show: true,
                    position: 'inside',
                    color: '#fff',
                    fontSize: 10
                }
            }]
        };
    };
    return (
        <ReactEcharts ref={myCharts} style={{height: '340px'}} option={getOption() as any} onEvents={onEvents}/>
    );
});
