// tslint:disable no-var-requires
import React, { Fragment, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Redirect, Route, withRouter, useHistory } from 'react-router-dom';
import { Header, Navigation, LoadingBar, TimeOut, OverView } from '../components/component';
import { jwtParse } from '../service/jwtParse';
import { routeMatch } from '../utils/webHelper';
import { apiCaller } from '../service/apiCaller';
import { getPermissionApi } from '../api/login';
import { getDropDownOption } from 'api/case';
import { GlobalContext } from '../index';
import { Select } from 'components/form';
import usePermission from 'hooks/usePermission';
export interface IPropsModel {
    config: any[];
}

const Main = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 100%;
    & > * {
        overflow-y: auto;
    }
`;

const RouterGuard: React.FC<any> = (props: any) => {
    const { config } = props;
    const { pathname } = window.location;
    const [showTimeout, setShowTimeout] = useState(false);
    const token = localStorage.getItem('token');
    const isLogin = jwtParse(token); // 要作jtw金鑰檢查，是否為正確的token
    const history = useHistory();
    const targetRouter = config.find((v: any) => routeMatch(v.path, pathname));
    const {
        permissionCtx,
        setPermissionCtx,
        menuCtx,
        setMenuCtx,
        dropdownOptionCtx,
        setDropdownOptionCtx,
    } = useContext(GlobalContext);
    if (menuCtx.length && pathname === '/') {
        history.push(menuCtx[0].href);
    }

    useEffect(() => {
        getPermissionInfo(localStorage.getItem('uid') || '');
        getOptionList();
    }, []);

    const getPermissionInfo = (userId: string) => {
        const success = (res: any) => {
            const {
                status,
                data: {
                    data: { permission },
                },
            } = res;
            setPermissionCtx(permission);
        };
        const reject = (error: any) => {
            console.log(error);
        };
        apiCaller(getPermissionApi, userId, success, reject);
    };

    const getOptionList = () => {
        const multilingualKey = localStorage.getItem('lang') || 'EN';

        const optionType = multilingualKey==='EN'?
        [
            'Gender','OccuptionStatus','CaseType','MainDiagnosis','Neurological',
            'Musculoskeleta','CerebrovascularAccident','ScoreInfo','SpinalCordInjury',
            'MeniscalInjury','LigamentInjury','Amputation','Hemiplegia',
            'HomeEnvironmentStairs','ToiletCondition','TreatmentType','WaistBelt',
            'Language','ClassType','TreatmentDetailStatus','MentalStatus','ConditionStatus01',
            'CommunicationStatus','VisionStatus', 'HearingStatus' ,'Location', 'ProprioceptionTouch',
            'ActiveRangeMotion','FingerGripping' , 'MuscleTone', 'MuscleStrengthGeneral','MuscleStrengthLL',
            'AssistiveDevice','FACScore','GaitAffectedSide','UnaffectedSide',
            'SquattingAffectedSide','SingleLegAffectedSide','LRBoth', 'Deficits', 'DeficitsResolved', 
            'PlanTrainingGoal', 'TrainingType', 'EvaluationTypeTime', 'TrainingAssistDevices', 
            'KeeogoExamination', 'KeeogoAssistDevices'
        ]
            :['Language'];

        getDropDownOption({
            multilingualKey,
            optionType
        }).then(res=>{
            const options = res.data.data.data;
            const notFound = res.data.data.noFind;

            const selectOption = [];
            let nameMapping: {
                [code: string]: string;
            } = {};

            for (let i = 0; i < optionType.filter(d => !notFound.includes(d)).length; ++i) {
                selectOption.push(
                    options[i]
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((d: any, i: number) => (
                            <Select.Option key={i + 1} value={d.code}>
                                {d.name}
                            </Select.Option>
                        )),
                );
                for (let j = 0; j < options[i].length; ++j) {
                    nameMapping = Object.assign(nameMapping, {
                        [options[i][j].code]: options[i][j].name,
                    });
                }
            }

            const getOptions = (type: string) =>
                options[optionType.indexOf(type)]?.sort((a: any, b: any) => a.order - b.order) ?? [];

            const getCode = (type: string | string[], nameValue: string) => {
                if (!nameValue) {
                    return '';
                }

                if (typeof type === 'string') {
                    return (
                        options[optionType.indexOf(type)].find((d: any) => d.name == nameValue)?.code ||
                        ''
                    );
                }
                const typeIndex = optionType
                    .map((option: string, i: number) => {
                        if (type.indexOf(option) >= 0) {
                            return i;
                        }
                        return null;
                    })
                    .filter(d => d);
                return (
                    options
                        .filter((d: any, i: number) => typeIndex.indexOf(i) >= 0)
                        .flat()
                        .find((d: any) => d.name == nameValue)?.code || ''
                );
            };

            setDropdownOptionCtx({
                optionType,
                selectOption,
                nameMapping,
                getOptions,
                getCode
            });
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    };
    // timeout
    const timeout = () => {
        const expireTime = sessionStorage.getItem('timeOut')
            ? Number(sessionStorage.getItem('timeOut'))
            : +new Date() + 1800000;
        const now = +new Date();
        setShowTimeout(now > expireTime ? true : false);
    };

    const closeTimeout = (isLogout: boolean) => {
        setShowTimeout(false);
        const dt = +new Date() + 1800000;
        sessionStorage.setItem('timeOut', dt.toString());
        if (isLogout) {
            window.localStorage.removeItem('token');
            window.location.href = '/login';
        }
    };
    let timeoutCount;
    clearInterval(timeoutCount);
    timeoutCount = setInterval(timeout, 10000);

    const routePath = pathname.slice(1).split('/')[0];
    const [permissionForRoute] = usePermission(routePath);

    if (isLogin) {
        // 已登入，就不可以再導向登入頁
        if (pathname === '/login') {
            return <Redirect to="/overView" />;
        } else {
            if (targetRouter && permissionForRoute) {
                const Component = targetRouter.component;

                return (
                    <Fragment>
                        {showTimeout && <TimeOut close={closeTimeout} />}
                        <LoadingBar />
                        <Header />
                        <Main>
                            <Navigation permission={permissionCtx} />
                            <Route
                                path={decodeURIComponent(pathname)}
                                render={(routeProps: any) =>
                                    permissionForRoute.available ? (
                                        <Component {...routeProps} />
                                    ) : (
                                        <OverView />
                                    )
                                }
                            />
                        </Main>
                    </Fragment>
                );
            } else {
                return <Redirect to="/overView" />;
            }
        }
    } else if (targetRouter && !targetRouter.auth) {
        const { component } = targetRouter;
        return <Route exact={true} path={pathname} component={component} />;
    }
    return <Redirect to="/login" />; // 未登入，則一律跳轉至登入頁
};

export default withRouter(RouterGuard);
