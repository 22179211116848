export const twLang = {

    // common
    'SYS':'系統',
    'PROV':'醫院',
    'BUS':'企業',
    'NATIVE': '本國人',
    'FOREIGNER': '外國人',
    'ENABLE':'啟用',
    'DEACTIVE':'停用',
    'BASCIC_INFO':'基本資料',

    'USERID':'User ID',
    'FIRST_NAME':'姓',
    'LAST_NAME':'名',

    'CONFIRM': '確認',
    'SAVE': '儲存',
    'CANCEL': '取消',
    'ADD':'新增',
    'EDIT':'編輯',
    'PREVIEW':'檢視',
    'DELETE':'刪除',
    'INPUT_ID_NUMBER':'輸入身分證字號',
    'GENDER':'性別',
    'INPUT_GENDER':'請輸入性別',
    'COUNTRY': '國籍',
    'RESET_PASSWORD': '重設密碼',
    'RESET_PASSWORD_AS_ACCOUNT_LAST_5': '請輸入您的登入帳號，系統將會把密碼重設為其後五碼',
    'RESET_PASSWORD_SUCCESS':'密碼已重設成功，請重新登入',
    'RELOGIN':'請重新登入',
    'ACCOUNT_NOT_FOUND':'查無此帳號，請重新輸入',
    'EDIT_DATA':'編輯資料',
    'PRIMARY_MAIL':'主要電子信箱郵件',
    'SECONDARY_MAIL':'次要電子信箱郵件',
    'INPUT_SECONDARY_MAIL':'請輸入次要電子信箱郵件',
    'INVALID_MAIL':'不合法的E-mail格式!',
    'SEQ_NUMBER':'編號',
    'NAME': '姓名',
    'INPUT_NAME': '輸入姓名',
    'UPDATE_DATE':'更新日期',    
    'SEARCH_BY_KEYWORD':'輸入關鍵字',
    'EXPORT':'匯出', 
    'IMPORT':'匯入',
    'MALE':'男',
    'FEMALE':'女',
    'OTHER':'其他',

    'HAS_CHECKED':'已勾選',
    'DATA':'筆資料',

    // userMgmt
    'DEPARTMENT_NAME':'部門名稱',
    'INPUT_DEPARTMENT_NAME':'輸入部門名稱',
    'TEL':'電話',
    'INPUT_TEL':'輸入電話',
    'BIRTH':'出生日期',
    'SELECT_BIRTH':'請輸入出生日期',

    // roleMgmt
    'ROLE':'角色權限',
    'ROLE_SELECT':'選擇角色',
    'ROLE_NAME': '角色名稱',
    'ROLE_NAME_LABEL': '名稱',
    'PLEASE_INPUT_NAME': '請輸入名稱',
    'ROLE_ADD': '新增角色',
    'ROLE_MODIFYCATION': '修改角色',
    'SEARCH_ROLE_NAME':'輸入搜尋角色名稱',
    'ROLE_NAME_CANT_BE_CASE':'角色名稱不可命名為個案',
    'ROLE_SETTING': '角色權限設定',
    'ROLE_RIGHT':'功能權限',
    'ROLE_RIGHT_SELECT':'選擇功能',
    'ROLE_FN_NAME':'功能名稱',
    'DELETE_ROLE': '刪除角色',
    'DELETE_ROLE_CHECK': '是否確定刪除 : ',
    'ROLE_MGMT': '權限管理',

    // orgMgmt
    'ORG_MGMT':'組織單位管理',
    'ORG_LIST':'組織單位列表',
    'ORG_PLAN_DETAIL':'方案授權明細',
    'INPUT_ORG_NAME':'輸入組織單位名稱',
    'TYPE':'類型',
    'INPUT_TYPE':'請選擇類型',
    'ORG_VAT':'統一編號',
    'ORG_NAME':'組織單位名稱',
    'ORG_PLAN_NAME':'方案項目名稱',
    'ORG_DUE':'方案到期日',
    'ORG_AUTHED_USERS':'授權人數',
    'ORG_USED_USERS':'已使用人數',
    'ORG_UNUSED_NUMBER':'尚未使用',
    'ORG_STATUS':'狀態',
    'ORG_BASIC_INFO':'組織單位基本資料',
    'PLEASE_INPUT_VAT':'請輸入統一編號',
    'PLEASE_CHECK_VAT':'請驗證統一編號格式！',
    'ORG_CONTACT_INFO':'聯絡人資訊',
    'ORG_SCHEME_ITEM':'授權方案項目',
    'ORG_ADDRESS':'地址',
    'INPUT_ORG_ADDRESS':'請輸入地址',
    'ORG_CONTACT_NAME':'聯絡人姓名',
    'INPUT_ORG_CONTACT_NAME':'請輸入聯絡人姓名',
    'ID_NUMBER':'聯絡人帳號',
    'AUTH_NUMBER':'授權數/人',
    'INPUT_AUTH_NUMBER':'請輸入授權數',
    'AUTH_GATHER_THAN_USED':'授權人數不可小於已使用數',
    'AUTH_AT_LEAST_ONE':'授權人數不可少於一人',
    'EXP_DATE':'到期日',
    'SELECT_EXP_DATE':'請選擇到期日',
    'REMARK':'備註',

    // caseMgmt - caseList
    'USER_LIST':'個案列表',
    'CASE_DEPARTMENT':'部門科別',
    'CASE_RECEIVE_TYPE':'收案別',
    'CASE_DATA':'個案資料',
    'CASE_ADD' :'新增個案',
    'CASE_EDIT' :'編輯個案',
    'SELECT_MEDICAL':'選擇醫療單位', 
    'CASE_SELECT_USERID':'Choose the Composition of User ID',
    'CASE_CODE':'案件編號',
    'COMPOSE_WITH_NAME':'以姓名',

    // login
    'SIGNIN':'登入',
    'LOGIN':'登入',
    'PRIVACY':'服務條款及隱私權政策',
    'ACCOUNT': '帳號',
    'INTER_ACCOUNT': '輸入帳號',
    'PASSWORD': '密碼',
    'INPUT_PASSWORD': '輸入密碼',
    'SYS_GENERATED':'由系統自動產生',

    'plsKeyAccount': '輸入帳號',
    'plsKeyPwd': '輸入密碼',
    'accountIsNotAvailable': '此帳號已停用',
    'loginError': '帳號及密碼輸入錯誤，請重新輸入!',
    'autoRememberAccount': '自動記錄帳號',
    'AUTO_REMEMBER_TIPS': '提醒您！若您使用公共電腦，請勿勾選「自動記錄帳號」，並於使用後記得「登出系統」。',
    'FORGET_PWD': '忘記密碼',

    'HAS_LOGINED':'此帳號被登入',
    'HAS_LOGINED_INFO':'目前此帳號已被登入，請確認是否要繼續登入，若您選擇確認登入系統將會自動登出目前已登入的帳號。',

    // timeOut
    'timeOut001': '作業逾時',
    'timeOut002': `您的作業逾時，請確認是否繼續使用或選擇登出後再重新登入！`,
    'DONT_LOGOUT': '繼續使用，不登出',
    'LOGOUT': '登出',
    // roleMgmt
    'ID_NUMBER_INFO': '請驗證身分證字號格式！',
    'EDIT_ACCOUNT': '編輯帳號',
    'ADD_ACCOUNT': '新增帳號',
    'BASIC_INFO': '基本資訊',
    'roleMgmt001': '編號',
    'roleMgmt003': '人數',
    'roleMgmt004': '更新日期',
    'roleMgmt006': '權限設定',
    'roleMgmt009': '角色列表',

    'roleMgmt012': '由系統自動產生',

    'roleMgmt014': '取消',
    'roleMgmt015': '角色資料',
    'roleMgmt016': '角色編號',

    'roleMgmt020': '可編輯',
    'roleMgmt021': '可檢視',
    'roleMgmt023': '角色資料',

    //caseMgmt
    'caseMgmt001': '個案管理',
    'caseMgmt002': '收案別',
    'caseMgmt003': '關鍵字',
    'caseMgmt004': '輸入關鍵字查詢',
    'caseMgmt005': '查詢',
    'caseMgmt006': '+ 新增',
    'caseMgmt007': '個案列表',
    'caseMgmt008': '編號',
    'caseMgmt009': '姓名',
    'caseMgmt010': '性別',
    'caseMgmt011': '出生日期',
    'caseMgmt012': '部門科別',
    'caseMgmt013': '收案別',
    'caseMgmt014': '更新日期',
    'caseMgmt015': '新增個案',
    'caseMgmt016': '基本資料',
    'caseMgmt017': '身分證字號',
    'caseMgmt018': '輸入身分證字號',
    'caseMgmt019': '本國人',
    'caseMgmt020': '外國人',
    'caseMgmt021': '姓名',
    'caseMgmt022': '輸入姓名',
    'caseMgmt023': '電話',
    'caseMgmt024': '輸入電話',
    'caseMgmt025': '分機',
    'caseMgmt026': '輸入分機',
    'caseMgmt027': '部門科別',
    'caseMgmt028': '輸入部門科別',
    'caseMgmt029': '編號',
    'caseMgmt030': '輸入編號',
    'caseMgmt031': '出生日期',
    'caseMgmt032': '輸入出生日期',
    'caseMgmt033': '選擇日期',
    'caseMgmt034': '歲',
    'caseMgmt035': '身高',
    'caseMgmt036': '輸入身高',
    'caseMgmt037': '公分',
    'caseMgmt038': '性別',
    'caseMgmt039': '男',
    'caseMgmt040': '女',
    'caseMgmt043': '輸入Email',
    'caseMgmt044': '主要電子郵件信箱',
    'caseMgmt045': '次要電子郵件信箱',
    'caseMgmt046': '個人健康史 (0)',
    'caseMgmt047': '罹病史 (0)',
    'caseMgmt048': '罹病日期 (年)',
    'caseMgmt049': '選擇年份',
    'caseMgmt050': '罹患疾病',
    'caseMgmt051': '輸入罹患疾病',
    'caseMgmt052': '開刀史 (0)',
    'caseMgmt053': '開刀日期 (年)',
    'caseMgmt054': '開刀原因',
    'caseMgmt055': '輸入開刀原因',
    'caseMgmt056': '家族健康史 (0)',
    'caseMgmt057': '家族稱謂',
    'caseMgmt058': '是否同住',
    'caseMgmt059': '是',
    'caseMgmt060': '否',
    'caseMgmt061': '收案別 (0)',
    'caseMgmt062': '收案別',
    'caseMgmt063': '選擇收案別',
    'caseMgmt064': '收案狀態',
    'caseMgmt065': '選擇收案狀態',
    'caseMgmt066': '收案列表 (1)',
    'caseMgmt067': '收案時間',
    'caseMgmt068': '新增',
    'caseMgmt069': '取消',
    'caseMgmt070': '資料已存在，請重輸入！',
    'caseMgmt071': '請輸入部門科別！',
    'caseMgmt072': '請輸入部門科別！',
    'caseMgmt073': '請輸入姓名！',
    'caseMgmt074': '請選擇日期！',
    'caseMgmt075': '請輸入Email！',
    'caseMgmt076': '請輸入罹患疾病！',
    'caseMgmt077': '請選擇收案別',
    'caseMgmt078': '請選擇收案別狀態',
    'caseMgmt080': '',
    // userMgmt
    'userMgmt': '帳號管理'
};
