import React from 'react';
import Button from 'components/button/button';
import { LeftOutlined } from '@ant-design/icons';
import moment from 'moment';

interface Props {
    setDate: Function;
    date: moment.Moment;
}

const LastDate: React.FC<Props> = ({setDate, date}: Props) => {

    return (
        <Button
            icon={<LeftOutlined />}
            type='link'
            onClick={()=>setDate(moment(date).subtract(1, 'days'))}
            style={{color: '#2E2E2E'}}
        >
        </Button>
    )
}

export default LastDate;
