import React, { useState, useEffect } from 'react';

interface Props {
    defaultValue?: any;
    onChange?: (val: any) => void;
    children: JSX.Element[];
    column?: boolean;
    noFlex?: boolean;
    middleElement?: JSX.Element; 
}

const RadioWrapper: React.FC<Props> = (props: Props) => {

    const [selectedVal, setSelectedVal] = useState<any>(props.defaultValue);

    useEffect(()=>{
        props.onChange?.(selectedVal);
    }, [selectedVal]);
    
    const radioOptions = props.children?.map((child: JSX.Element)=>React.cloneElement(child, { onClick: setSelectedVal, selected: child.props.value == selectedVal, style: {cursor: 'pointer'} }));
    const middle = props.middleElement ? React.cloneElement(props.middleElement, {children: radioOptions}) : radioOptions;
    return (
        <div className={`${props.noFlex? '' : 'd-flex'} ${props.column?'is--column':''}`}>
            {middle}
        </div>
    )
}

export default RadioWrapper;