import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { length } from 'ramda';
import { Form, Row, Col, Tooltip, Radio, Spin, Modal, Dropdown, Menu } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import { SearchOutlined, ExportOutlined, CloseOutlined, EditOutlined , DeleteOutlined, ImportOutlined, EllipsisOutlined, MoreOutlined } from '@ant-design/icons';
import { icon_edit, icon_delete } from 'components/image';
import { Block } from 'components/layouts';
import { Input, Select } from 'components/form';
import { PlusOutlined } from '@ant-design/icons';

import QueryTable from 'components/queryTable/queryTable';
import useUser from 'hooks/useUser';
import useTableRowSelection from 'hooks/useTableRowSelection';
import useVisible from '../../hooks/useVisible';
import { GenderDisplay } from 'utils/mapping';
import { exportFile } from 'utils/webHelper';
import usePermission  from 'hooks/usePermission';

import { importUser,exportUser} from 'api/case';

import CardList from 'components/card/cardList';
import Button from 'components/button/button';
import { Search } from 'components/form/input';
import { api, toQueryString } from '../../api/api';

import Table from 'components/table/table';
import Pagination, { PaginationState } from 'components/pagination/pagination';
import ParameterDrawer from './parameterDrawer';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

type ParamterResponseType = {
    id: number
    createdAt: number
    updatedAt: number
    name: string
    step: number
    valueType: string
    desc: string
    sequence: string
    comment: string
    active: boolean
    createId: number
    createDateTime: string
    modifyId: number
    modifyDateTime: string
    fhirOrganizationId: number
}

export default (props: any) => {
    
    const {formatMessage} = useIntl();

    const [list, setList] = useState<Array<ParamterResponseType>>([])
    const [loading,setLoading] = useState(false)

    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10
    });

    // search/filter keyword
    const [keyword ,setKeyword] = useState("")

    const [drawerSetting,setDrawerSetting] = useState<{type:string;visible:boolean;data:ParamterResponseType|null;}>({type:'', visible:false , data:null})
    

    React.useEffect(()=>{
        getList()
    },[])

    const getList = async ()=>{
        try {
            setLoading(true)
            const res = await api.post('/keeogo/parameter_setting')
            setList(res?.data?.data ?? [])
        }catch (e){
            console.error(e)
        }finally {
            setLoading(false)
        }
    }

    const getListView = ()=>{
        let res = list.filter(x=>x.name.indexOf(keyword) > -1);

        res = list.slice(pagination.page-1, pagination.page*pagination.pageSize);

        return res
    }

    const columns = [
        {
            dataIndex: 'name',
            title: formatMessage({id:'NAME'}),
            sorter: (a:any,b:any)=> a.name.localeCompare(b.name),
            width: 260,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'createId',
            title: formatMessage({id:'ID'}),
            sorter: (a:any,b:any)=> b.createId - a.createId,
            width: 260,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'desc',
            title: formatMessage({id:'DESCRIPTION'}),
            sorter: (a:any,b:any)=> a.desc.localeCompare(b.desc),
            width: 260,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'step',
            title: formatMessage({id:'STEP'}),
            sorter: (a:any,b:any)=> b.step - a.step,
            width: 80,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'updatedAt',
            width: 150,
            title: formatMessage({id:'UPDATE_DATE'}),
            render: (text:any)=>text?moment(text).format('YYYY/MM/DD'):'--',
            sorter: (a:any,b:any)=> b.updatedAt - a.updatedAt,
            ellipsis: true
        },
        {
            key: 'action',
            width: 50,
            render: (text:any, record:any) => {
                const dropdownOption = (
                    <Menu>
                        <Menu.Item>
                            <Button
                                type='link'
                                style={{color: 'black', display: 'flex', alignItems: 'center'}}
                                icon={<img src={icon_edit} className='mr-10'/>}
                                onClick={()=>setDrawerSetting(prv=>({...prv,visible:true, data:record}))}
                            >
                                {formatMessage({id:'EDIT'})}
                            </Button>
                        </Menu.Item>
                        <Menu.Item>
                            <Button
                                type='link'
                                style={{color: 'red', display: 'flex', alignItems: 'center'}}
                                icon={<img src={icon_delete} className='mr-10'/>}
                                onClick={() => api.delete('/keeogo/parameter_setting', {data:{name: record.name}})}
                            >
                                {formatMessage({id:'DELETE'})}
                            </Button>
                        </Menu.Item>
                    </Menu>
                )

                return (
                    <div onClick={(e:any)=>{e.stopPropagation();}}>
                        <Dropdown
                            overlay={dropdownOption}
                            placement='bottomRight'
                            trigger={['click']}
                        >
                            <Button
                                type='link'
                                icon={<MoreOutlined style={{color: '#727272', transform: 'scale(1.6)'}} />}
                            />
                        </Dropdown>
                    </div>
                );
            }
        }
    ];

    return (
        <>
            {
                drawerSetting.visible && <ParameterDrawer 
                    data={drawerSetting.data as any}
                    onClose={()=> setDrawerSetting(prv=>({...prv, visible: false}))
                }/>
            }
            <Wrapper>
                <div className='main'>
                    <div className="header">
                        <b><strong>{formatMessage({id:'PARAMETER_LIST'})}</strong></b>
                        <Search
                            style={{ width: '180px' }}
                            placeholder={`${formatMessage({ id: 'SEARCH_BY' })} ${formatMessage({ id: 'NAME' })}`}
                            onChange={(e:any)=>{
                                setKeyword(e.target.value)
                            }}
                        />
                    </div>
                    <Table
                        columns={columns}
                        dataSource={getListView()}
                        pagination={false}
                        onRow={(record)=>{
                            return {
                                onClick: ()=>setDrawerSetting(prv=>({...prv,visible:true, data:record}))
                            }
                        }}
                    />
                </div>
                <Pagination pagination={pagination} setPagination={setPagination} paginationTotal={list.length}/>
            </Wrapper>
        </>
    );
};
