import { api, toQueryString } from './api';
import { Response, ListResponse } from 'types/Api';

const baseURL = '/keeogo/personal_info_basic/summary';

interface SummaryBrief {
    id: number;
    createdAt: number;
    updatedAt: number;
    reportName: string;
    selectedPackages: number[];
    invalid: boolean;
    updateState: boolean;
    packageShare: boolean;
    trainingShare: boolean;
    kraShare: boolean;
    commentShare: boolean;
    treatmentType: string;
}

export interface SummaryListRes {
    summaryList: SummaryBrief[];
    packagesCompleted: number;
    packageInProgress: number;
    classCompleted: number;

}

interface TrainingNote {
    trainingTypeCode: string;
    profile: string;
    reps: string;
    sets: string;
    mins: string;
    comments: string;
    assistDevice: string[];
}

interface Package {
    id: number;
    createdAt: number;
    updatedAt: number;
    name: string;
    classNum: number;
    treatmentTypeCode: string;
    classTypeCode: string;
    memo: string;
    createId: number;
    createDateTime: string;
    modifyId: number;
    modifyDateTime: string;
    fhirOrganizationId: number;
    trainingGoalsToImproveCode: string[];
    trainingGoalsToImproveOther: string;
}

interface Class {
    id: number;
    createdAt: number;
    updatedAt: number;
    personalTreatmentPlanMasterId: number;
    treatmentDate: string;
    treatmentHour: string;
    treatmentDetailStatusCode: string;
    memo: string;
    createId: number;
    createDateTime: string;
    modifyId: number;
    modifyDateTime: string;
    fhirOrganizationId: number;
}

export interface TrainingPackage extends Package {
    notes: TrainingNote[];
    trainingItems: string[];
}

export interface Video {
    id: string;
    sagittalView: string;
    frontalView: string;
    sagittalThumbnail: string;
    frontalThumbnail: string;
    memo: string;
    timestamp: string;
    labels?: string[];
    packageName: string;
    className: string;
}

interface File {
    id: number;
    createdAt: number;
    updatedAt: number;
    personalTrainingRecordId: number;
    classification: string;
    fileKey: number;
    trainingType: string;
    fileType: string;
    filePath: string;
    videoDirection: string;
    thumbnail: string;
    createId: number;
    modifyId: number;
    fhirOrganizationId: number;
    upload: boolean;
    share: boolean;
}

interface PackageInfo {
    packageName: string;
    classNum: string;
}

interface VideoDetail extends File, PackageInfo {
    memo: string;
}

interface PackageVideo {
    package: string;
    class: string;
    video: File[];
}

export interface Summary {
    id: number;
    createdAt: number;
    updatedAt: number;
    personalInfoBasicId: number;
    reportName: string;
    selectedPackages: TrainingPackage[];
    comment: string;
    invalidRecord: string;
    invalid: boolean;
    createId: number;
    modifyId: number;
    fhirOrganizationId: number;
    trainingShare: boolean;
    packageShare: boolean;
    kraShare: boolean;
    commentShare: boolean;
    trainingItem: TrainingItem[];
    kraReport: KRAReport[];
}

export interface TrainingItem {
    trainingTypeCode: string;
    trainingTypeName: string;
    videoWithKeeogo: [VideoDetail | undefined, VideoDetail | undefined];
    videoWithoutKeeogo: [VideoDetail | undefined, VideoDetail | undefined];
}

interface KRAReport {
    id: number;
    profile: string;
    level: string;
    dateTime: string;
    remark: string;
    timeConsumed: number;
    maxHipAngleLeft: number;
    maxHipAngleRight: number;
    type: string;
    assistDevice: string[];
    KRADetailRecords: KRADetailData;
}

interface KRADetailData {
    LeftHipAngle: number[];
    LeftKneeAngle: number[];
    RightHipAngle: number[];
    RightKneeAngle: number[];
    HipCOG: number[];
    time: string[];
}

export interface PackageKRA {
    packageName: string;
    createDateTime: string;
    kraReport: KRABrief[];
}

export interface KRABrief {
    class: string;
    treatmentDate: string;
    treatmentHour: string;
    profile: string;
    level: string;
    assistDevice: string[];
    configId: number;
    summaryData: KRASummaryData;
}

export interface KRASummaryData {
    flexionHipAngleLeft: number;
    flexionHipAngleRight: number;
    extensionHipAngleLeft: number;
    extensionHipAngleRight: number;
    flexionKneeAngleLeft: number;
    flexionKneeAngleRight: number;
    extensionKneeAngleLeft: number;
    extensionKneeAngleRight: number;
    timeStep: number;
    avgStepLength: number;
    kraDateTime: string;
}

export interface Prescription extends PackageInfo {
    createdAt: number;
    notes: TrainingNote[];
}

export interface PackageModifiedHistory {
    createdAt: number;
    treatmentName: string;
    classNum: string;
}

export const getReportList = (caseId: number) => 
    api.get<Response<SummaryListRes>>(`${baseURL}/find?${toQueryString({personalInfoBasicId: caseId})}`);

export const getOneReport = (reportId: number) => 
    api.get<Response<Summary>>(`${baseURL}/find-one?${toQueryString({personalSummaryId: reportId, multilingualKey: localStorage.getItem('lang') || 'EN'})}`);

export const getPackageList = (params: { 
    personalInfoBasicId: number, 
    treatmentTypeCode: string 
}) => api.get<ListResponse<TrainingPackage>>(`${baseURL}/package-list?${toQueryString(params)}`);

export const getVideoList = (params: { 
    selectedPackages: number[], 
    trainingTypeCode: string, 
    classification?: string
}) => api.get<ListResponse<PackageVideo>>(`${baseURL}/video-list?${toQueryString({ ...params, selectedPackages: params.selectedPackages.join(',') })}`);

export const getKRAList = (params: {
    selectedPackages: number[],
    keeogoExaminationCode: string
}) => api.get<ListResponse<PackageKRA>>(`${baseURL}/kra-list?${toQueryString(params)}`);

export const createReport = (data: {
    personalInfoBasicId: number;
    reportName: string;
    selectedPackages: number[];
    trainingItem: {
        trainingRecordFileId: number;
        memo: string;
    }[];
    kraReport: number[];
    comment: string;
    commentShare?: boolean;
    trainingShare?: boolean;
    kraShare?: boolean;
    packageShare?: boolean;
    device: 'pc';
}) => api.post(`${baseURL}/create`, data);

export const updateReport = (data: {
    personalSummaryId: number;
    invalid: boolean;
    invalidRecord: string;
    device: 'pc';
}) => api.put(`${baseURL}/update-state`, data);

export const getPrescription = (packages: number[]) => 
    api.get<ListResponse<Prescription>>(`${baseURL}/prescription?${toQueryString({ packages })}`);
    
export const getModifiedHistory = (summaryId: number) =>
    api.get<ListResponse<PackageModifiedHistory>>(`${baseURL}/history?${toQueryString({ personalSummaryId: summaryId })}`);
