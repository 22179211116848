import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import { TextArea } from 'components/form';
import { updateReport } from 'api/summary';
import { Modal } from 'antd';

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        padding-top: 24px;
        padding-bottom: 24px;
    }
`;

const StyledTextArea = styled(TextArea)`
    &.ant-input {
        resize: none !important;
        border: 2px #C4C4C4 solid;
    }
`

interface InvalidSummaryProps {
    onClose: () => void;
    refetch: () => void;
    reportId: number;
}

const InvalidSummaryDrawer: React.FC<InvalidSummaryProps> = (props: InvalidSummaryProps) => {

    const { formatMessage } = useIntl();
    const [invalidRecord, setInvalidRecord] = useState('');

    const handleConfirmInvalid = () => {
        updateReport({
            personalSummaryId: props.reportId,
            invalid: true,
            invalidRecord: invalidRecord,
            device: 'pc'
        }).then(res=>{
            Modal.success({
                maskClosable:true,
                title:'Success',
                onCancel: ()=>{
                    props.refetch();
                    props.onClose();
                }
            })
        }).catch(err=>{
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        });
    }

    return (
        <StyledDrawer
            visible
            closable
            maskClosable
            onClose={props.onClose}
            width='30vw'
            title={formatMessage({id:'MAKE_INVALID'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect text--bold" disabled={!invalidRecord} onClick={handleConfirmInvalid} >
                        {formatMessage({id:'CONFIRM_UPPERCASE'})}
                    </Button>
                </div>
            }
        >
            <div className='d-flex is--column'>
                <div className='text--bold mb-15'>{formatMessage({id:'FILL_INVALID_RECORD'})}:</div>
                <StyledTextArea onChange={(e: any)=>setInvalidRecord(e.target.value)} rows={6}/>
            </div>
        </StyledDrawer>
    )
}

export default InvalidSummaryDrawer;
