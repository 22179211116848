import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl }  from 'react-intl';
import { Modal, Row, Col, Radio } from 'antd';
import ReactPlayer from 'react-player';
import { Video } from '../SummaryEdit';

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 1rem;

        .Title {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .name {
                font-weight: bold;
                font-size: 18px;
                color: #000000;
            }

            .datetime {
                font-size: 12px;
                color: #2E2E2E;
            }
        }

        .player {
            aspect-ratio: 1.3;
            width: 100%;
            background-color: #2E2E2E;
            display: flex;
            align-items: center;
            padding: 10px;
        }

        .viewSelector {
            display: flex;
            justify-content: center;
            margin: 15px 0;
        }
    }
`;

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 1.2vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

interface ViewProps {
    onClose: () => void;
    videoWithKeeogo?: Video;
    videoWithoutKeeogo?: Video;
}

const VideoView: React.FC<ViewProps> = (props: ViewProps) => {

    const { formatMessage } = useIntl();
    const [view, setView] = useState<'frontal' | 'sagittal'>('frontal');

    return (
        <StyledModal
            visible
            maskClosable
            closable={false}
            footer={null}
            onCancel={props.onClose}
            width='30vw'
            mask={false}
            centered
        >
            <Row gutter={24}>
                <Col span={12}>
                    <div className='Title'>
                        <div className='name'>{`${props.videoWithKeeogo?.packageName ?? '-'} - ${props.videoWithKeeogo?.className ?? ''}`}</div>
                        <div className='datetime'>{props.videoWithKeeogo?.timestamp.format('DD/MM/YYYY HH:mm') ?? '--'}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className='Title'>
                        <div className='name'>{`${props.videoWithoutKeeogo?.packageName ?? '-'} - ${props.videoWithoutKeeogo?.className ?? ''}`}</div>
                        <div className='datetime'>{props.videoWithoutKeeogo?.timestamp.format('DD/MM/YYYY HH:mm') ?? '--'}</div>
                    </div>
                </Col>
            </Row>
            <div className='player'>
                <Row gutter={24}>
                    <Col span={12}>
                        <ReactPlayer url={view === 'frontal' ? (
                            props.videoWithKeeogo?.upload ? props.videoWithKeeogo.uploadView : props.videoWithKeeogo?.frontalView
                        ) : (
                            props.videoWithKeeogo?.upload ? props.videoWithKeeogo.uploadView : props.videoWithKeeogo?.sagittalView
                        )} controls width='100%' height='100%' />
                    </Col>
                    <Col span={12}>
                        <ReactPlayer url={view === 'frontal' ? (
                            props.videoWithoutKeeogo?.upload ? props.videoWithoutKeeogo.uploadView : props.videoWithoutKeeogo?.frontalView
                        ) : (
                            props.videoWithoutKeeogo?.upload ? props.videoWithoutKeeogo.uploadView : props.videoWithoutKeeogo?.sagittalView
                        )} controls width='100%' height='100%' />
                    </Col>
                </Row>
            </div>
            <div className='viewSelector'>
                <Radio.Group buttonStyle='solid' value={view} onChange={e=>setView(e.target.value)}>
                    <StyledRadio value='frontal'>{formatMessage({id:'FRONTAL_VIEW'})}</StyledRadio>
                    <StyledRadio value='sagittal'>{formatMessage({id:'SAGITTAL_VIEW'})}</StyledRadio>
                </Radio.Group>
            </div>
        </StyledModal>
    )
}

export default VideoView;
