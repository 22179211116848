/** @jsx jsx */
// tslint:disable: quotemark no-implicit-dependencies no-shadowed-variable no-var-requires
import React, { useEffect, useState, useContext } from 'react';
import { jsx } from '@emotion/core';
import { Form, Row, Col, Radio, Spin , Modal } from 'antd';
import cx from 'classnames';
import { PlusOutlined } from '@ant-design/icons'; // tslint:disable-line
import { DrawerProps } from 'antd/es/drawer';
import moment from 'moment';
import { pathOr, omit, length, evolve, isNil } from 'ramda';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import Switch from 'components/switch/switch';
import DatePicker from 'components/datePicker/datePicker';
import { Input, Select } from 'components/form';
import { RefetchQueriesFunction } from 'constants/types';
import { success, warning } from 'utils/message';
import { User, useActiveUser, useCreateUser, useUpdateUser } from 'api/user';
import { Enum, useEnums , OrganizationSelect } from 'api/enums';
import { getDropDownOption } from 'api/case';
import useVisible from 'hooks/useVisible';
import { useEditItem } from 'hooks/useEdit';
import RoleModal from './userMgmtRoleModal';
import { RoleTag } from './style';
import { useIntl } from 'react-intl';
import { Password } from 'components/form/input';
import { GlobalContext } from '../../index';

const { compose } = require('ramda');

type Props = Omit<DrawerProps, 'onClose'> & {
    onClose: (evt?: React.MouseEvent<HTMLElement>) => any;
    refetchQueries: RefetchQueriesFunction;
    item?: User;
    loadingData?: boolean;
    editType: 'edit' | 'add' | '';
};

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function UserEditDrawer({
    item,
    refetchQueries,
    onClose,
    loadingData = false,
    editType,
    ...props
}: Props) {
    const {formatMessage} = useIntl();
    const [form] = Form.useForm();

    const { byId, dataSource:orgOpts } = useEnums('/organization', {
        baseURL: undefined,
    })

    const [editItem, setEditItem] = useEditItem<User>({
        item,
        form,
        editType,
        onChange: values => {
            form.setFieldsValue({ role: values?.role });
        },
    });

    const nameCountLimit = 50;
    const [nameCount, setNameCount] = useState(0);
    const remarkCountLimit = 50;
    const [remarkCount, setRemarkCount] = useState(0);

    const [roleModalVisible, handleRoleModalVisible] = useVisible();

    const [account, setAccount] = useState('');
    const [accountValid, setAccountValid] = useState(true);
    const { optionType, selectOption} = useContext(GlobalContext).dropdownOptionCtx;
    const[phone,setPhone]=useState<string>("");
    var reg = /^([0-9]*)$/;

    useEffect(()=>{
        const accountRegex = new RegExp('(?=[a-zA-Z0-9])[a-zA-Z0-9]{8,20}');
        const match: RegExpMatchArray=accountRegex.exec(account) || [];
        if (
            account !== '' &&
            (!accountRegex.test(account) || match[0]?.length!=account.length)
        ){
            setAccountValid(false);
        }
        else{
            setAccountValid(true);
        }
    }, [account]);

    const activeUser = useActiveUser({
        onCompleted: ({ data }) => {
            success({ content: `帳號已${data.data.active ? '啟' : '停'}用` });
            setEditItem(prev => ({ ...prev, active: data.data.active } as any));
            refetchQueries();
        },
        onError: error => {
            console.log(error);
        },
    });

    const mutationOptions = {
        onCompleted: (data: any) => {
            Modal.success({
                maskClosable:true,
                title:'Success',
                onCancel: ()=>{
                    refetchQueries()
                    onClose()
                }
            })
        },
        onError: (error?: any) => {
            console.log(error)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}>
                    {
                        (()=>{
                            const msg = error?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()
                    }
                </div>
            })
        },
    };

    const createUser = useCreateUser(mutationOptions);

    const updateUser = useUpdateUser(mutationOptions);

    const handleRoleSelect = (role: Enum[]) => {
        form.setFieldsValue({ role: role.length ? role : null });
        setEditItem(prev => ({ ...prev, role } as any));
    };

    const handleRoleRemove = (role: Enum) => (_: any) => {
        const roles = editItem?.role.filter((x: any) => x.id !== role.id);
        setEditItem(
            prev =>
                ({
                    ...prev,
                    role: roles, // prev?.role.filter(x => x !== role.id),
                } as any),
        );
        form.setFieldsValue({ role: roles?.length ? roles : null });
    };

    const handleOk = ()=>{
        form.validateFields().then(values=>{
            const handler = item ? updateUser : createUser;
            handler(
                compose(
                    evolve({
                        managingOrganizationId: Number,
                    }),
                    omit(['updatedAt', 'createdAt', 'managingOrganizationName']),
                )({
                    ...editItem,
                    ...values,
                    managingOrganization: values.managingOrganizationId,
                    ...((values.birthDate
                        ? {
                            birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
                        }
                        : {}) as any),
                    role: pathOr([], ['role'], editItem).map((x: Enum) => x.id),
                }),
            );
        })
    }

    return (
        <React.Fragment>
            {roleModalVisible && (
                <RoleModal
                    selectedItems={pathOr([], ['role'], editItem)}
                    onOk={handleRoleSelect}
                    onCancel={handleRoleModalVisible}
                />
            )}
            <Drawer
                {...props}
                visible={true}
                maskClosable={true}
                closable={true}
                keyboard={false}
                width={'60%'}
                title={
                    editType === 'add'
                    ?
                    formatMessage({id:'ADD_ACCOUNT'})
                    :
                    formatMessage({id:'EDIT_ACCOUNT'})
                }
                onClose={onClose as any}
                footer={
                    <div className="pull--right">
                        <Button  style={{width:100,fontSize:16 }}className="is--primary rect"  onClick={handleOk} disabled={!accountValid}>
                            {formatMessage({id:'SAVE'})}
                        </Button>
                    </div>
                }
            >
                <Spin spinning={loadingData}>
                    <Form
                        layout="vertical" form={form}
                        onValuesChange={( changedValues,allValues)=>{
                            let phoneInpute = allValues.telecomPhone
                            if(phoneInpute === ""){
                                setPhone(phoneInpute)
                                form.setFieldsValue({ telecomPhone:""})
                            }else if(!reg.test(phoneInpute)){
                                form.setFieldsValue({telecomPhone:phone})
                            }else if(reg.test(phoneInpute)){
                                form.setFieldsValue({ telecomPhone:allValues.telecomPhone })
                                setPhone(phoneInpute)
                            }
                        }}
                    >
                        {/* 基本資訊 */}
                        <h2 className='title'>
                            {formatMessage({id:'BASIC_INFO'})}
                        </h2>
                        <Row gutter={24}>
                            {/* 帳號 */}
                            <Col span={11}>
                                <Form.Item
                                    label={formatMessage({id:'ACCOUNT_ID'})}
                                    name='username'
                                    rules={[{required: true, message: `${formatMessage({id:'ACCOUNT_ID'})} is required` }]}
                                >
                                    <Input
                                        bordered={false}
                                        disabled={item?true:false}
                                        onChange={e=>setAccount(e.target.value)}
                                    />
                                </Form.Item>
                                <span style={{fontSize: '12px', position: 'absolute', bottom: '-10px', left: '15px', color: 'red'}}>{!accountValid&&'Account ID must have at least 8 charaters, only alphabet and number available.'}</span>
                            </Col>
                            <Col span={2}></Col>
                            {/* 姓名 */}
                            <Col span={11}>
                                <Form.Item
                                    label={formatMessage({id:'USERNAME'})}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: `${formatMessage({id:'USERNAME'})} is required`
                                        },
                                    ]}
                                >
                                    <Input bordered={false}  />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            {/* 性別 */}
                            <Col span={11}>
                                <Form.Item
                                    label={formatMessage({id:'GENDER'})}
                                    name="gender"
                                    rules={[
                                        {
                                            required: true, 
                                            message: `${formatMessage({id:'GENDER'})} is required`
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear={true}
                                        bordered={false}
                                        
                                    >
                                        {selectOption[optionType.indexOf('Gender')]}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={2}></Col>
                            {/* 出生日期 */}
                            <Col span={11}>
                                <Form.Item
                                    label={formatMessage({id:'BIRTHDAY'})}
                                    name="birthDate"
                                >
                                    <DatePicker
                                        bordered={false}
                                        className="width--full"
                                        format="DD/MM/YYYY"
                                        
                                        disabledDate={current=>current>moment().endOf('day')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            {/* Organization */}
                            <Col span={11}>
                                <Form.Item
                                    label={formatMessage({id:'ORG_NAME'})}
                                    name="managingOrganizationId"
                                    rules={[
                                        {
                                            required: true, 
                                            message: `${formatMessage({id:'ORG_NAME'})} is required`
                                        }
                                    ]}
                                >
                                    <Select
                                        allowClear={true}
                                        bordered={false}
                                        
                                    >
                                        {orgOpts.map(x => (
                                            <Select.Option key={x.id} value={x.id}>
                                                {x.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={2}></Col>
                            {/* 電話 */}
                            <Col span={11}>
                                <Form.Item label={formatMessage({id:'TEL'})} name="telecomPhone" rules={[ {required: true, message: `${formatMessage({id:'TEL'})} is required`} ]} >
                                    <Input bordered={false}  />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            {/* 電子郵件信箱 */}
                            <Col span={11}>
                                <Form.Item
                                    label={formatMessage({id:'MAIL'})}
                                    name="telecomEmail"
                                    rules={[
                                        {
                                            required: true,
                                            message: `${formatMessage({id:'MAIL'})} is required`,
                                        },
                                        {
                                            validator: (_: any, value: any) => {
                                                if (value && !emailRegex.test(value)) {
                                                    return Promise.reject(
                                                        formatMessage({id:'INVALID_MAIL'})
                                                    );
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input bordered={false}  />
                                </Form.Item>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <Form.Item label={formatMessage({id:'TYPE'})}>
                                    <Input value={editType==='add' ? formatMessage({id:'COMPANY'}) : editItem?.type} disabled={true} bordered={false}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* 角色 */}
                        <Row className="mt-10">
                            <Col span={24}>
                                <div className="d-flex v--center">
                                    {/* 角色權限 */}
                                    <h2 className='title'>{formatMessage({id:'ROLE'})}</h2>
                                    {
                                        // 選擇角色
                                        (
                                            <Button
                                                icon={<PlusOutlined />}
                                                onClick={handleRoleModalVisible}
                                                className='is--primary light ml-10'
                                                size='small'
                                                style={{ background: "#2E63A3",color: 'white',marginLeft: '10px', transform: 'scale(1.2)translateX(10%)' }}
                                            >
                                              <span style={{ marginRight: '10px'}}> {formatMessage({id:'ROLE_SELECT'})} </span>
                                            </Button>
                                        )
                                    }
                                </div>
                                <Form.Item
                                    required={true}
                                    name="role"
                                    rules={[
                                        {
                                            required: true, 
                                            message: `${formatMessage({id:'ROLE'})} is required`
                                        }
                                    ]}
                                >
                                    <div style={{margin:'1rem 0'}}>
                                        {(compose(length, pathOr([], ['role']))(editItem) === 0) && (
                                            <div className="text--tip">
                                                {
                                                    formatMessage({id:'ROLE_SELECT_TIP'})
                                                }
                                            </div>
                                        )}
                                        {pathOr([], ['role'], editItem).map((x: Enum) => (
                                            <RoleTag
                                                key={x.id}
                                                data-id={x.id}
                                                className="mt-8"
                                                onClose={handleRoleRemove(x)}
                                                style= {{ background:" #E0F2FB"}}
                                            >
                                                {x.name}
                                            </RoleTag>
                                        ))}
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* 備註 */}
                        <Row>
                            <Col span={24}>
                                <Form.Item label={formatMessage({id:'REMARK'})} name="comment">
                                    <Input bordered={false}  maxLength={remarkCountLimit} onChange={e=>setRemarkCount(e.target.value.length)} />
                                </Form.Item>
                                <span style={{fontSize: '12px', position: 'absolute', bottom: 0, right: '15px'}}>{remarkCount}/{remarkCountLimit}</span>
                            </Col>
                        </Row>
                        {/* 狀態 */}
                        <Row>
                            <Col span={24}>
                                <div className="d-flex v--center">
                                    <h2 className='title'>{formatMessage({id:'STATUS'})}</h2>
                                    <Form.Item noStyle={true} name="active" valuePropName="checked">
                                        <Switch
                                            checkedChildren={formatMessage({id:'ENABLE'})}
                                            unCheckedChildren={formatMessage({id:'DEACTIVE'})}
                                            
                                             style={{   marginLeft: '10px', transform: 'scale(1.2)translateX(10%)' }}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>
        </React.Fragment>
    );
}
