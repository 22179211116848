import React, { useEffect } from 'react';
import Drawer from 'components/drawer/drawer';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import { Form, TextArea } from 'components/form';
import { Modal } from 'antd';
import ModifiedRecord from '../ModifiedRecord';
import { apiCaller } from 'service/apiCaller';
import { editCaseDevice } from 'api/case';
import { routeMatch } from 'utils/webHelper';
import { useLocation } from 'react-router-dom';

interface Props {
    refetch: Function;
    data: any;
    onClose: Function;
}

const DeviceDrawer: React.FC<Props> = (props: Props) => {

    const { id } = routeMatch('/Patient/:id', useLocation().pathname);
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const modifiedHistory = props.data.recordRemark?.sort((a: any,b: any)=>b.updatedAt-a.updatedAt).map((record: any)=><ModifiedRecord updatedAt={record.updatedAt} editorName={record.createName} content={record.recordRemark}/>) || [];

    useEffect(()=>{
        form.setFieldsValue({
            memo: props.data.memo
        });
    }, []);

    const handleOk = () => {
        form.validateFields().then(values=>{
            const success = (res: any) => {
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel: ()=>{
                        props.refetch();
                        props.onClose();
                    }
                })
            };

            const reject = (err: any) => {
                console.error(err)
                Modal.error({
                    maskClosable:true,
                    title:'Error',
                    content: <div style={{textAlign:'center'}}> 
                        {
                            (()=>{
                                const msg = err?.data?.detail
                                if(typeof msg === 'string'){
                                    return msg
                                }else if(msg instanceof Array){
                                    return msg.join('\n')
                                }else{
                                    return JSON.stringify(msg)
                                }
                            })()   
                        }
                    </div> 
                })
            };
            
            apiCaller(
                editCaseDevice,
                {
                    userId: id,
                    device: 'web',
                    ...values
                },
                success,
                reject
            )
        });
    }

    return (
        <Drawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='60vw'
            title={formatMessage({id:'EDIT'})}
            footer={
                <div className="pull--right rect">
                    <Button className="mr-20 rect" onClick={props.onClose as any}>
                        {formatMessage({id:'CANCEL'})}
                    </Button>
                    <Button className="is--primary rect" onClick={handleOk}>
                        {formatMessage({id:'SAVE'})}
                    </Button>
                </div>
            }
        >
            <Form form={form} layout='vertical'>
                <Form.Item label={formatMessage({id:'PROFILE_REMARK'})} name='memo'>
                    <TextArea rows={4}/>
                </Form.Item>
                <Form.Item label={formatMessage({id:'MODIFIED_REMARK'})} name='modifiedRemark'>
                    <TextArea rows={4}/>
                </Form.Item>
                <Form.Item label={formatMessage({id:'RECENT_ACT'})}>
                    {modifiedHistory}
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default DeviceDrawer;
