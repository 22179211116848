import { api, toQueryString } from './api';
import { Response, ListResponse } from 'types/Api';

const baseURL = '/keeogo/venue';

interface NewChannel {
    channelList: string[];
    organizationId: number;
}

export interface Channel {
    id: number;
    createdAt: number;
    updatedAt: number;
    organizationId: number;
    channelName: string;
    createId: number;
    modifyId: number;
    fhirOrganizationId: number;
}

export interface ChannelQRcode {
    channelId: number;
    channelName: string;
    token: string;
}

export interface OTPtoken {
    id: number;
    createdAt: number;
    updatedAt: number;
    otpToken: string;
    expiredAt: string;
    createId: number;
    modifyId: number;
    fhirOrganizationId: number;
}

export interface Device {
    id: number;
    createdAt: number;
    updatedAt: number;
    channelId: number;
    deviceName: string;
    createId: number;
    modifyId: number;
    fhirOrganizationId: number;
}

export const addChannel = (payload: NewChannel) => api.post<Response<NewChannel>>(`${baseURL}/create-channels`, payload);

export const deleteChannel = (channelId: number) => api.delete(`${baseURL}/delete-channel/${channelId}`);

export const getChannelList = (orgId: number) => api.get<ListResponse<Channel>>(`${baseURL}/channel-list?${toQueryString({organizationId: orgId})}`);

export const getQRcode = (channelId: number) => api.get<ListResponse<ChannelQRcode>>(`${baseURL}/channel-qrcode?${toQueryString({channelId})}`);

export const getQRcodeToken = () => api.post<Response<OTPtoken>>(`${baseURL}/create-otptoken`);

export const removeDevice = (deviceId: number) => api.delete(`${baseURL}/delete-device/${deviceId}`);

export const getDeviceList = (channelId: number) => api.get<ListResponse<Device>>(`${baseURL}/device-list?${toQueryString({channelId})}`);
