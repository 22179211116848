import { api, toQueryString } from './api';
import { Response, ListResponse } from 'types/Api';

const baseURL = '/keeogo/personal_profile_data';

export interface Parameter {
    Par0: string;
    Par1: string;
    Par2: string;
    Par3: string;
    Par4: string;
    Par5: string;
    Par6: string;
    Par7: string;
    Par8: string;
    Par9: string;
    Par10: string;
    Par11: string;
    Par12: string;
    Par13: string;
    Par14: string;
    Par15: string;
    Par16: string;
    Par17: string;
    Par18: string;
    Par19: string;
    Par20: string;
    Par21: string;
    Par22: string;
    Par23: string;
    Par24: string;
    Par25: string;
    Par26: string;
    Par27: string;
    Par28: string;
    Par29: string;
    Par30: string;
    Par31: string;
    Par32: string;
    Par33: string;
    Par34: string;
    Par35: string;
    Par36: string;
    Par37: string;
    Par38: string;
    Par39: string;
    Par40: string;
    Par41: string;
    Par42: string;
    Par43: string;
    Par44: string;
    Par45: string;
    Par46: string;
    Par47: string;
    Par48: string;
    Par49: string;
    Par50: string;
    Par51: string;
    Par52: string;
    Par53: string;
    Par54: string;
    Par55: string;
    Par56: string;
    Par57: string;
    Par58: string;
    Par59: string;
    Par60: string;
    Par61: string;
    Par62: string;
    Par63: string;
    Par64: string;
    Par65: string;
    Par66: string;
}

interface ProfileRecord {
    id: number;
    logType: string;
    modifyMemo: string;
    LastModified: number;
    createName: string;
}

export interface ParameterProfile extends Parameter {
    id: number,
    createdAt: number;
    updatedAt: number;
    personalInfoBasicId: number;
    profileName: string;
    invalid: boolean;
    addType: string;
    memo: string;
    createId: number;
    createDateTime: string;
    modifyId: number;
    modifyDateTime: string;
    fhirOrganizationId: number;
    modifyRecord?: ProfileRecord[];
}

export const getParameterList = ({caseId, invalid}: {caseId: number, invalid: boolean}) => api.get<ListResponse<ParameterProfile>>(`${baseURL}/${caseId}?${toQueryString({invalid, pageSize: -1})}`);

export const getParameter = (profileId: number) => api.get<Response<ParameterProfile>>(`${baseURL}/find_one/${profileId}`);
