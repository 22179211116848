import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{show: boolean;}>`
    width: ${props => props.show ? '170px' : '0px'};
    display: flex;
    flex-direction: column;
    transition: width 0.5s ease-in-out, padding 0.5s ease-in-out;
    padding: ${props => props.show ? '0 10px' : '0'};
`;

const Option = styled.div<{selected?: boolean;}>`
    width: 100%;
    border-bottom: 2px solid #C4C4C4;
    padding-bottom: 3px;
    margin-right: 10px;
    margin: 5px 0;
    color: ${props => props.selected ? '#0083C1' : '#2E2E2E'};
    cursor: pointer;
    font-weight: bold;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
`;

interface DrawerContentProps {
    show: boolean;
    options: {
        name: string;
        value: number;
    }[];
    currentValue: number;
    onChange: (val: number) => void;
}

const DrawerContent: React.FC<DrawerContentProps> = (props: DrawerContentProps) => {

    const optionList = props.options.map(d=>(
        <Option title={d.name} selected={props.currentValue === d.value} onClick={()=>props.onChange(d.value)}>{d.name}</Option>
    ));

    return (
        <Wrapper show={props.show}>
            {optionList}
        </Wrapper>
    )
}

export default DrawerContent;
