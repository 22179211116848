import React, { useState, useEffect } from 'react';
import { values, head } from 'ramda';
import { ButtonComponent } from '../component';

import { apiCaller } from '../../service/apiCaller';
import { getUserInfoApi, logoutApi } from '../../api/personal';
import './header.sass';
import logo from '../../assets/image/login/logo_Keeogo-en.svg';
import { useIntl } from 'react-intl';
import { DropdownAvatar } from 'components/avatar/avatar';
const { compose } = require('ramda');

export const Header: React.FC<any> = (props: any) => {
    const intl = useIntl();

    const [userInfo, setUserInfo] = useState({
        name: '',
        role: '',
        roleName: '',
        managingOrganization: '',
        managingOrganizationName: '',
        avatarImg: '' as string | undefined,
    });
    useEffect(() => {
        getUserInfo();
    }, []);
    const getUserInfo = () => {
        const success = (res: any) => {
            const { status } = res;
            if (status === 200) {
                const {
                    name,
                    comment,
                    managingOrganizationName,
                    managingOrganizationId,
                    role,
                    roleName,
                    abbreviation = 'orgAbbr',
                    imgPath,
                } = res.data.data;
                setUserInfo({
                    name,
                    role: comment,
                    roleName: compose(head, values)(roleName),
                    managingOrganization: comment,
                    managingOrganizationName,
                    avatarImg: imgPath?.data
                        ? `data:image/png;base64,${btoa(
                              imgPath.data.reduce(
                                  (data: any, byte: any) => data + String.fromCharCode(byte),
                                  '',
                              ),
                          )}`
                        : undefined,
                });
                window.localStorage.setItem('managingOrganization', comment);
                window.localStorage.setItem('managingOrganizationId', managingOrganizationId);
                window.localStorage.setItem('managingOrganizationName', managingOrganizationName);
                window.localStorage.setItem('managingOrganizationType', 'sys');
                window.localStorage.setItem('role', role);
                window.localStorage.setItem('abbreviation', abbreviation);
                window.localStorage.setItem('name', name);
            }
        };
        const reject = (error: any) => {
            const { status, data } = error;
        };
        apiCaller(getUserInfoApi, null, success, reject);
    };
    const logout = () => {
        const success = (res: any) => {
            const { status } = res;
            if (status === 200) {
                window.localStorage.removeItem('token');
                window.location.href = '/login';
            }
        };
        const reject = (error: any) => {
            window.localStorage.removeItem('token');
            window.location.href = '/login';
        };
        apiCaller(logoutApi, null, success, reject);
    };

    return (
        <header className="header_title">
            <img src={logo} className="header_logo" />
            <div style={{ padding: '1rem', display: 'flex', alignItems: 'center' }}>
                <span className="header_userName">{userInfo.name}</span>
                <span className="header_userName">
                    {`${userInfo.managingOrganizationName} / ${userInfo.roleName}`}
                </span>
                <DropdownAvatar avatarSrc={userInfo.avatarImg} />
            </div>
        </header>
    );
};
