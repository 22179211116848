import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/button/button';
import Drawer from 'components/drawer/drawer';
import Scroll from 'react-scroll';
import { icon_lower_limbs_menu, icon_upper_limbs_menu, icon_keeogo_exam_menu, icon_calendar } from 'components/image';
import RoundButton from 'components/roundedButton/roundedButton';
import { Form } from 'components/form';
import { Modal } from 'antd';
import { EvaluationDetail, defaultValue, EvaluationData } from '../type';
import { GlobalContext } from '../../../../../index';
import { addEvaluation, editEvaluation } from 'api/eavluation';
import { getUserById } from 'api/user';
import { KRAdata, getOneRecord } from 'api/record';
import { apiCaller } from 'service/apiCaller';
import moment from 'moment';
// forms
import GeneralCondition from './GeneralCondition';
import ActiveRange from './ActiveRange';
import MuscleTone from './MuscleTone';
import MuscleStrength from './MuscleStrength';
import Balance from './Balance';
import MovementAnalysis from './MovementAnalysis';
import Description from './Description';
import UserFitting from './UserFitting';
import KEMovementAnalysis from './KEMovementAnalysis';
import PlanDateSelector from './PlanDateSelector';

const DrawerContent = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F3F3F3;
    padding: 15px;
    display: flex;

    .menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width: 20%;
        padding: 0 10px;

        .menuContent {
            overflow-y: scroll;
            margin-bottom: 10px;

            &::-webkit-scrollbar {
                width: 0;
            }
    
            &>a {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
    
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
    
                .subMenu {
                    display: none;
                }
    
                &.expand>.subMenu {
                    display: flex;
                }

                &.scroll.active>.subMenu {
                    display: flex;
                }
            }

            .subMenu {
                font-size: 0.9vw;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
    
                a {
                    color: #909090;
                    margin: 5px 0;
                    display: block;
                }
    
                a.customActive {
                    color: #2E63A3!important;
                }

                a.scroll.active {
                    color: #2E63A3!important;
                }
        
                a::after {
                    content: '●';
                    margin-left: 10px;
                }
            }
        }
    }

    .scrollContent {
        width: 80%;
        background-color: #FFFFFF;
        height: 100%;
        overflow-y: scroll;
        padding: 15px 20px;

        &::-webkit-scrollbar {
            width: 0;
        }
    }
`;

const StyledDrawer = styled(Drawer)`
    z-index: 900;

    .ant-drawer-header {
        .ant-drawer-title {
            width: 100%;
            padding-right: 15px;
        }
    }
`;

export const FormTitle = styled.div`
    color: #909090;
    font-size: 16px;
    font-weight: 500;
`;

interface Props {
    onClose: Function;
    tabKey?: string;
    editData?: EvaluationDetail;
    userInfoData: any;
    treatmentData: any;
    refetch: Function;
    caseId: number;
    evaluationDate?: string;
    planId: number;
    copy?: boolean;
    hasPreliminary: boolean;
    isPreliminary?: boolean;
    kraData: KRAdata[];
    treatmentDate: string;
    treatmentHour: string;
    packageName: string;
    className: string;
}

const EvaluationDrawer: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { getCode } = useContext(GlobalContext).dropdownOptionCtx;
    const [form] = Form.useForm();
    const { Element, Link, scroller } = Scroll;
    const [formData, setFormData] = useState<EvaluationData>(props.editData ?? defaultValue);
    const [modifiedName, setModifiedName] = useState('--');
    // const [selectedPlan, setSelectedPlan] = useState<number | undefined>(props.planId);

    const [mainClass, setMainClass] = useState<string>(props.tabKey === 'Description' ? 'UL' : (props.tabKey === 'UserFitting' || props.tabKey === 'KEMovementAnalysis') ? 'KE' : 'LL');
    const [subClass, setSubClass] = useState<string>(props.tabKey ?? 'GeneralCondition');
    const [clickFlag, setClickFlag] = useState(false);

    useEffect(()=>{
        if(props.editData){
            const dataWithCode = {
                ...formData,
                gcMentalStatusCode: getCode('MentalStatus', props.editData.gcMentalStatusCode),
                gcMotivationCode: getCode('ConditionStatus01', props.editData.gcMotivationCode),
                gcAttentionCode: getCode('ConditionStatus01', props.editData.gcAttentionCode),
                gcCommunicationCode: getCode('CommunicationStatus', props.editData.gcCommunicationCode),
                gcVisionCode: getCode('VisionStatus', props.editData.gcVisionCode),
                gcVisionNeglectCode: getCode('LRBoth', props.editData.gcVisionNeglectCode),
                gcHearingCode: getCode('HearingStatus', props.editData.gcHearingCode),
                gcHearingImpairedHardHearingDeafCode: getCode('LRBoth', props.editData.gcHearingImpairedHardHearingDeafCode),
                gcPainCode: getCode('Location', props.editData.gcPainCode),
                gcSwellingCode: getCode('Location', props.editData.gcSwellingCode),
                gcSensationLtProprioceptionCode: getCode('ProprioceptionTouch', props.editData.gcSensationLtProprioceptionCode),
                gcSensationRtProprioceptionCode: getCode('ProprioceptionTouch', props.editData.gcSensationRtProprioceptionCode),
                gcSensationLtLightTouchCode: getCode('ProprioceptionTouch', props.editData.gcSensationLtLightTouchCode),
                gcSensationRtLightTouchCode: getCode('ProprioceptionTouch', props.editData.gcSensationRtLightTouchCode),
                armUlLtShoulderFlexCode: getCode('ActiveRangeMotion', props.editData.armUlLtShoulderFlexCode),
                armUlLtShoulderExtCode: getCode('ActiveRangeMotion', props.editData.armUlLtShoulderExtCode),
                armUlLtShoulderAbdCode: getCode('ActiveRangeMotion', props.editData.armUlLtShoulderAbdCode),
                armUlLtShoulderAddCode: getCode('ActiveRangeMotion', props.editData.armUlLtShoulderAddCode),
                armUlLtShoulderErCode: getCode('ActiveRangeMotion', props.editData.armUlLtShoulderErCode),
                armUlLtShoulderIrCode: getCode('ActiveRangeMotion', props.editData.armUlLtShoulderIrCode),
                armUlRtShoulderFlexCode: getCode('ActiveRangeMotion', props.editData.armUlRtShoulderFlexCode),
                armUlRtShoulderExtCode: getCode('ActiveRangeMotion', props.editData.armUlRtShoulderExtCode),
                armUlRtShoulderAbdCode: getCode('ActiveRangeMotion', props.editData.armUlRtShoulderAbdCode),
                armUlRtShoulderAddCode: getCode('ActiveRangeMotion', props.editData.armUlRtShoulderAddCode),
                armUlRtShoulderErCode: getCode('ActiveRangeMotion', props.editData.armUlRtShoulderErCode),
                armUlRtShoulderIrCode: getCode('ActiveRangeMotion', props.editData.armUlRtShoulderIrCode),
                armUlLtElbowFlexCode: getCode('ActiveRangeMotion', props.editData.armUlLtElbowFlexCode),
                armUlLtElbowExtCode: getCode('ActiveRangeMotion', props.editData.armUlLtElbowExtCode),
                armUlLtElbowPronationCode: getCode('ActiveRangeMotion', props.editData.armUlLtElbowPronationCode),
                armUlLtElbowSupinationCode: getCode('ActiveRangeMotion', props.editData.armUlLtElbowSupinationCode),
                armUlRtElbowFlexCode: getCode('ActiveRangeMotion', props.editData.armUlRtElbowFlexCode),
                armUlRtElbowExtCode: getCode('ActiveRangeMotion', props.editData.armUlRtElbowExtCode),
                armUlRtElbowPronationCode: getCode('ActiveRangeMotion', props.editData.armUlRtElbowPronationCode),
                armUlRtElbowSupinationCode: getCode('ActiveRangeMotion', props.editData.armUlRtElbowSupinationCode),
                armUlLtFinerGrippingCode: getCode('FingerGripping', props.editData.armUlLtFinerGrippingCode),
                armUlRtFinerGrippingCode: getCode('FingerGripping', props.editData.armUlRtFinerGrippingCode),
                armLlLtHipFlexCode: getCode('ActiveRangeMotion', props.editData.armLlLtHipFlexCode),
                armLlLtHipExtCode: getCode('ActiveRangeMotion', props.editData.armLlLtHipExtCode),
                armLlLtHipAbdCode: getCode('ActiveRangeMotion', props.editData.armLlLtHipAbdCode),
                armLlLtHipAddCode: getCode('ActiveRangeMotion', props.editData.armLlLtHipAddCode),
                armLlLtHipErCode: getCode('ActiveRangeMotion', props.editData.armLlLtHipErCode),
                armLlLtHipIrCode: getCode('ActiveRangeMotion', props.editData.armLlLtHipIrCode),
                armLlRtHipFlexCode: getCode('ActiveRangeMotion', props.editData.armLlRtHipFlexCode),
                armLlRtHipExtCode: getCode('ActiveRangeMotion', props.editData.armLlRtHipExtCode),
                armLlRtHipAbdCode: getCode('ActiveRangeMotion', props.editData.armLlRtHipAbdCode),
                armLlRtHipAddCode: getCode('ActiveRangeMotion', props.editData.armLlRtHipAddCode),
                armLlRtHipErCode: getCode('ActiveRangeMotion', props.editData.armLlRtHipErCode),
                armLlRtHipIrCode: getCode('ActiveRangeMotion', props.editData.armLlRtHipIrCode),
                armLlLtKneeFlexCode: getCode('ActiveRangeMotion', props.editData.armLlLtKneeFlexCode),
                armLlLtKneeExtCode: getCode('ActiveRangeMotion', props.editData.armLlLtKneeExtCode),
                armLlRtKneeFlexCode: getCode('ActiveRangeMotion', props.editData.armLlRtKneeFlexCode),
                armLlRtKneeExtCode: getCode('ActiveRangeMotion', props.editData.armLlRtKneeExtCode),
                armLlLtAnkleDfCode: getCode('ActiveRangeMotion', props.editData.armLlLtAnkleDfCode),
                armLlLtAnklePfCode: getCode('ActiveRangeMotion', props.editData.armLlLtAnklePfCode),
                armLlLtAnkleInversionCode: getCode('ActiveRangeMotion', props.editData.armLlLtAnkleInversionCode),
                armLlLtAnkleEversionCode: getCode('ActiveRangeMotion', props.editData.armLlLtAnkleEversionCode),
                armLlRtAnkleDfCode: getCode('ActiveRangeMotion', props.editData.armLlRtAnkleDfCode),
                armLlRtAnklePfCode: getCode('ActiveRangeMotion', props.editData.armLlRtAnklePfCode),
                armLlRtAnkleInversionCode: getCode('ActiveRangeMotion', props.editData.armLlRtAnkleInversionCode),
                armLlRtAnkleEversionCode: getCode('ActiveRangeMotion', props.editData.armLlRtAnkleEversionCode),
                mtUlLtShoulderFlexorCode: getCode('MuscleTone', props.editData.mtUlLtShoulderFlexorCode),
                mtUlLtShoulderAdductorCode: getCode('MuscleTone', props.editData.mtUlLtShoulderAdductorCode),
                mtUlLtElbowFlexorCode: getCode('MuscleTone', props.editData.mtUlLtElbowFlexorCode),
                mtUlLtWristFlexorCode: getCode('MuscleTone', props.editData.mtUlLtWristFlexorCode),
                mtUlRtShoulderFlexorCode: getCode('MuscleTone', props.editData.mtUlRtShoulderFlexorCode),
                mtUlRtShoulderAdductorCode: getCode('MuscleTone', props.editData.mtUlRtShoulderAdductorCode),
                mtUlRtElbowFlexorCode: getCode('MuscleTone', props.editData.mtUlRtElbowFlexorCode),
                mtUlRtWristFlexorCode: getCode('MuscleTone', props.editData.mtUlRtWristFlexorCode),
                mtLlLtHipFlexorCode: getCode('MuscleTone', props.editData.mtLlLtHipFlexorCode),
                mtLlLtHipExtensorCode: getCode('MuscleTone', props.editData.mtLlLtHipExtensorCode),
                mtLlLtHipAdductorCode: getCode('MuscleTone', props.editData.mtLlLtHipAdductorCode),
                mtLlLtKneeFlexorCode: getCode('MuscleTone', props.editData.mtLlLtKneeFlexorCode),
                mtLlLtKneeExtensorCode: getCode('MuscleTone', props.editData.mtLlLtKneeExtensorCode),
                mtLlLtAnklePlantarflexorCode: getCode('MuscleTone', props.editData.mtLlLtAnklePlantarflexorCode),
                mtLlRtHipFlexorCode: getCode('MuscleTone', props.editData.mtLlRtHipFlexorCode),
                mtLlRtHipExtensorCode: getCode('MuscleTone', props.editData.mtLlRtHipExtensorCode),
                mtLlRtHipAdductorCode: getCode('MuscleTone', props.editData.mtLlRtHipAdductorCode),
                mtLlRtKneeFlexorCode: getCode('MuscleTone', props.editData.mtLlRtKneeFlexorCode),
                mtLlRtKneeExtensorCode: getCode('MuscleTone', props.editData.mtLlRtKneeExtensorCode),
                mtLlRtAnklePlantarFlexorCode: getCode('MuscleTone', props.editData.mtLlRtAnklePlantarFlexorCode),
                msUlLtGeneralCode: getCode('MuscleStrengthGeneral', props.editData.msUlLtGeneralCode),
                msUlRtGeneralCode: getCode('MuscleStrengthGeneral', props.editData.msUlRtGeneralCode),
                msLlLtHipFlexorCode: getCode('MuscleStrengthLL', props.editData.msLlLtHipFlexorCode),
                msLlLtHipAbductorCode: getCode('MuscleStrengthLL', props.editData.msLlLtHipAbductorCode),
                msLlLtHipAdductorCode: getCode('MuscleStrengthLL', props.editData.msLlLtHipAdductorCode),
                msLlRtHipFlexorCode: getCode('MuscleStrengthLL', props.editData.msLlRtHipFlexorCode),
                msLlRtHipAbductorCode: getCode('MuscleStrengthLL', props.editData.msLlRtHipAbductorCode),
                msLlRtHipAdductorCode: getCode('MuscleStrengthLL', props.editData.msLlRtHipAdductorCode),
                msLlLtKneeFlexorCode: getCode('MuscleStrengthLL', props.editData.msLlLtKneeFlexorCode),
                msLlLtKneeExtensorCode: getCode('MuscleStrengthLL', props.editData.msLlLtKneeExtensorCode),
                msLlLtAnkleDorsiflexorCode: getCode('MuscleStrengthLL', props.editData.msLlLtAnkleDorsiflexorCode),
                msLlLtAnklePlantarflexorCode: getCode('MuscleStrengthLL', props.editData.msLlLtAnklePlantarflexorCode),
                msLlRtKneeFlexorCode: getCode('MuscleStrengthLL', props.editData.msLlRtKneeFlexorCode),
                msLlRtKneeExtensorCode: getCode('MuscleStrengthLL', props.editData.msLlRtKneeExtensorCode),
                msLlRtAnkleDorsiflexorCode: getCode('MuscleStrengthLL', props.editData.msLlRtAnkleDorsiflexorCode),
                msLlRtAnklePlantarflexorCode: getCode('MuscleStrengthLL', props.editData.msLlRtAnklePlantarflexorCode),
                balanceStaticSittingBalanceCode: getCode('ConditionStatus01', props.editData.balanceStaticSittingBalanceCode),
                balanceStaticStandingBalanceCode: getCode('ConditionStatus01', props.editData.balanceStaticStandingBalanceCode),
                balanceDynamicSittingBalanceCode: getCode('ConditionStatus01', props.editData.balanceDynamicSittingBalanceCode),
                balanceDynamicStandingBalanceCode: getCode('ConditionStatus01', props.editData.balanceDynamicStandingBalanceCode),
                makAssistiveDeviceCode: getCode('AssistiveDevice', props.editData.makAssistiveDeviceCode),
                makGaitAnalysisFacScoreCode: getCode('FACScore', props.editData.makGaitAnalysisFacScoreCode),
                makGaitAnalysisUnaffectedSideCode: getCode('UnaffectedSide', props.editData.makGaitAnalysisUnaffectedSideCode),
                makGaitAnalysisAffectedSide: props.editData.makGaitAnalysisAffectedSide.map(d=>getCode('GaitAffectedSide', d)),
                makOtherSquattingAssistiveDeviceCode: getCode('AssistiveDevice', props.editData.makOtherSquattingAssistiveDeviceCode),
                makOtherSquattingAffectdSide: props.editData.makOtherSquattingAffectdSide.map(d=>getCode('SquattingAffectedSide', d)),
                makOtherSingleLegStanceAssistiveDeviceCode: getCode('AssistiveDevice', props.editData.makOtherSingleLegStanceAssistiveDeviceCode),
                makOtherLtSingleLegStanceAffectdSide: props.editData.makOtherLtSingleLegStanceAffectdSide.map(d=>getCode('SingleLegAffectedSide', d)),
                makOtherRtSingleLegStanceAffectdSide: props.editData.makOtherRtSingleLegStanceAffectdSide.map(d=>getCode('SingleLegAffectedSide', d)),
                keUfiKeeogoBeltSize: getCode('WaistBelt', props.editData.keUfiKeeogoBeltSize),
                keMakWalkComfortablyFacScoreCode: getCode('FACScore', props.editData.keMakWalkComfortablyFacScoreCode),
                keMakWalkComfortablyAffectedSideCode: props.editData.keMakWalkComfortablyAffectedSideCode.map(d=>getCode('GaitAffectedSide', d)),
                keMakWalkComfortablyUnaffectedSideCode: getCode('UnaffectedSide', props.editData.keMakWalkComfortablyUnaffectedSideCode),
                keMakWalkFastFacScoreCode: getCode('FACScore', props.editData.keMakWalkFastFacScoreCode),
                keMakWalkFastAffectedSideCode: props.editData.keMakWalkFastAffectedSideCode.map(d=>getCode('GaitAffectedSide', d)),
                keMakWalkFastUnaffectedSideCode: getCode('UnaffectedSide', props.editData.keMakWalkFastUnaffectedSideCode),
                keMakOtherSquattingAssistiveDeviceCode: getCode('AssistiveDevice', props.editData.keMakOtherSquattingAssistiveDeviceCode),
                keMakOtherSquattingAffectdSide: props.editData.keMakOtherSquattingAffectdSide.map(d=>getCode('SquattingAffectedSide', d)),
                keMakOtherSingleLegStanceAssistiveDeviceCode: getCode('AssistiveDevice', props.editData.keMakOtherSingleLegStanceAssistiveDeviceCode),
                keMakOtherLtSingleLegStanceAffectdSide: props.editData.keMakOtherLtSingleLegStanceAffectdSide.map(d=>getCode('SingleLegAffectedSide', d)),
                keMakOtherRtSingleLegStanceAffectdSide: props.editData.keMakOtherRtSingleLegStanceAffectdSide.map(d=>getCode('SingleLegAffectedSide', d)),
                reviewAndPrioritizeBaselineData: props.editData.reviewAndPrioritizeBaselineData.map(d=>({
                    ...d,
                    prioritizedDeficitsCode:  getCode('Deficits', d.prioritizedDeficitsCode),
                    deficitsResolvedCode: getCode('DeficitsResolved', d.deficitsResolvedCode)
                }))
            };
            setFormData(dataWithCode);
            form.setFieldsValue(dataWithCode);
        }
        else{
            form.setFieldsValue({...formData});
        }
    }, []);

    useEffect(()=>{
        if(props.tabKey){
            setTimeout(()=>scroller.scrollTo(props.tabKey??'', {
                duration: 500,
                smooth: true,
                containerId: 'scrollContent'
            }), 500);
        }
    }, []);

    useEffect(()=>{
        if(props.editData){
            getUserById(
                props.editData.modifyId > 0 ? props.editData.modifyId.toString() : props.editData.createId.toString()
            ).then((res: any)=>{
                setModifiedName(res.data.data.name)
            }).catch((error: any)=>{
                console.error(error);
                throw(error);
            });
        }
    }, [props.editData]);

    const handleSave = () => {
        form.validateFields().then(values=>{
            const success = (res: any) => {
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel: ()=>{
                        props.refetch();
                        props.onClose();
                    }
                })
            };

            const reject = (err: any) => {
                console.error(err)
                Modal.error({
                    maskClosable:true,
                    title:'Error',
                    content: <div style={{textAlign:'center'}}> 
                        {
                            (()=>{
                                const msg = err?.data?.detail
                                if(typeof msg === 'string'){
                                    return msg
                                }else if(msg instanceof Array){
                                    return msg.join('\n')
                                }else{
                                    return JSON.stringify(msg)
                                }
                            })()   
                        }
                    </div> 
                })
            };
            console.log(values)
            if(props.copy){
                apiCaller(
                    addEvaluation,
                    {
                        personalInfoBasicId: props.caseId,
                        device: 'web',
                        personalTreatmentPlanDetailId: props.planId,
                        evaluationDate: moment().format('YYYY/MM/DD'),
                        ...values
                    },
                    success,
                    reject
                );
            }
            else{
                if(props.editData){
                    apiCaller(
                        editEvaluation,
                        {
                            id: props.editData?.id,
                            personalEvaluationDetailId: props.editData?.id,
                            evaluationDate: props.evaluationDate,
                            device: 'web',
                            // personalTreatmentPlanDetailId: selectedPlan,
                            ...values,
                            gcCommunicationOther: values.gcCommunicationCode === 'COM09' ?  values.gcCommunicationOther : '',
                            gcVisionNeglectCode: values.gcVisionCode === 'VIS02' ? values.gcVisionNeglectCode : '',
                            gcVisionOther: values.gcVisionCode==='VIS04' ? values.gcVisionOther : '',
                            gcHearingImpairedHardHearingDeafCode: (values.gcHearingCode==='HEA02' || values.gcHearingCode==='HEA03' || values.gcHearingCode==='HEA04') ? values.gcHearingImpairedHardHearingDeafCode : '',
                            gcPainLocationText: values.gcPainCode==='LOC02' ? values.gcPainLocationText : '',
                            gcSwellingLocationText: values.gcSwellingCode==='LOC02' ? values.gcSwellingLocationText : '',
                            gcSensationLtProprioceptionText: (values.gcSensationLtProprioceptionCode==='PRO02' || values.gcSensationLtProprioceptionCode==='PRO03') ? values.gcSensationLtProprioceptionText : '',
                            gcSensationRtProprioceptionText: (values.gcSensationRtProprioceptionCode==='PRO02' || values.gcSensationRtProprioceptionCode==='PRO03') ? values.gcSensationRtProprioceptionText : '',
                            gcSensationLtLightTouchCodeText: (values.gcSensationLtLightTouchCode==='PRO02' || values.gcSensationLtLightTouchCode==='PRO03') ? values.gcSensationLtLightTouchCodeText : '',
                            gcSensationRtLightTouchCodeText: (values.gcSensationRtLightTouchCode==='PRO02' || values.gcSensationRtLightTouchCode==='PRO03') ? values.gcSensationRtLightTouchCodeText : '',
                            msLlLtHipFlexorOther: values.msLlLtHipFlexorCode==='MSL10' ? values.msLlLtHipFlexorOther : '',
                            msLlRtHipFlexorOther: values.msLlRtHipFlexorCode==='MSL10' ? values.msLlRtHipFlexorOther : '',
                            msLlLtHipAbductorOther: values.msLlLtHipAbductorCode==='MSL10' ? values.msLlLtHipAbductorOther : '',
                            msLlRtHipAbductorOther: values.msLlRtHipAbductorCode==='MSL10' ? values.msLlRtHipAbductorOther : '',
                            msLlLtHipAdductorOther: values.msLlLtHipAdductorCode==='MSL10' ? values.msLlLtHipAdductorOther : '',
                            msLlRtHipAdductorOther: values.msLlRtHipAdductorCode==='MSL10' ? values.msLlRtHipAdductorOther : '',
                            msLlLtKneeFlexorOther: values.msLlLtKneeFlexorCode==='MSL10' ? values.msLlLtKneeFlexorOther : '',
                            msLlRtKneeFlexorOther: values.msLlRtKneeFlexorCode==='MSL10' ? values.msLlRtKneeFlexorOther : '',
                            msLlLtKneeExtensorOther: values.msLlLtKneeExtensorCode==='MSL10' ? values.msLlLtKneeExtensorOther : '',
                            msLlRtKneeExtensorOther: values.msLlRtKneeExtensorCode==='MSL10' ? values.msLlRtKneeExtensorOther : '',
                            msLlLtAnkleDorsiflexorOther: values.msLlLtAnkleDorsiflexorCode==='MSL10' ? values.msLlLtAnkleDorsiflexorOther : '',
                            msLlRtAnkleDorsiflexorOther: values.msLlRtAnkleDorsiflexorCode==='MSL10' ? values.msLlRtAnkleDorsiflexorOther : '',
                            msLlLtAnklePlantarflexorOther: values.msLlLtAnklePlantarflexorCode==='MSL10' ? values.msLlLtAnklePlantarflexorOther : '',
                            msLlRtAnklePlantarflexorOther: values.msLlRtAnklePlantarflexorCode==='MSL10' ? values.msLlRtAnklePlantarflexorOther : '',
                            makAssistiveDeviceOther: values.makAssistiveDeviceCode==='ASS04' ? values.makAssistiveDeviceOther : '',
                            keMakAssistiveDeviceOther: values.keMakAssistiveDeviceCode==='ASS04' ? values.keMakAssistiveDeviceOther : '',
                            makGaitAnalysisAffectedSideOther: values.makGaitAnalysisAffectedSide.some((d:string)=>d==='GAS08') ? values.makGaitAnalysisAffectedSideOther : '',
                            makGaitAnalysisUnaffectedSideOther: values.makGaitAnalysisUnaffectedSideCode==='UNA02' ? values.makGaitAnalysisUnaffectedSideOther : '',
                            makOtherSquattingAffectdSideOther: values.makOtherSquattingAffectdSide.some((d:string)=>d==='SAS06') ? values.makOtherSquattingAffectdSideOther : '',
                            makOtherLtSingleLegStanceAffectdSideOther: values.makOtherLtSingleLegStanceAffectdSide.some((d:string)=>d==='SLA05') ? values.makOtherLtSingleLegStanceAffectdSideOther : '',
                            makOtherRtSingleLegStanceAffectdSideOther: values.makOtherRtSingleLegStanceAffectdSide.some((d:string)=>d==='SLA05') ? values.makOtherRtSingleLegStanceAffectdSideOther : '',
                            keMakOtherSquattingAffectdSideOther: values.keMakOtherSquattingAffectdSide.some((d:string)=>d==='SAS06') ? values.keMakOtherSquattingAffectdSideOther : '',
                            keMakOtherLtSingleLegStanceAffectdSideOther: values.keMakOtherLtSingleLegStanceAffectdSide.some((d:string)=>d==='SLA05') ? values.keMakOtherLtSingleLegStanceAffectdSideOther : '',
                            keMakOtherRtSingleLegStanceAffectdSideOther: values.keMakOtherRtSingleLegStanceAffectdSide.some((d:string)=>d==='SLA05') ? values.keMakOtherRtSingleLegStanceAffectdSideOther : ''
                        },
                        success,
                        reject
                    );
                }
                else{
                    apiCaller(
                        addEvaluation,
                        {
                            personalInfoBasicId: props.caseId,
                            device: 'web',
                            personalTreatmentPlanDetailId: props.planId,
                            evaluationDate: moment().format('YYYY/MM/DD'),
                            ...values,
                            gcCommunicationOther: values.gcCommunicationCode === 'COM09' ?  values.gcCommunicationOther : '',
                            gcVisionNeglectCode: values.gcVisionCode === 'VIS02' ? values.gcVisionNeglectCode : '',
                            gcVisionOther: values.gcVisionCode==='VIS04' ? values.gcVisionOther : '',
                            gcHearingImpairedHardHearingDeafCode: (values.gcHearingCode==='HEA02' || values.gcHearingCode==='HEA03' || values.gcHearingCode==='HEA04') ? values.gcHearingImpairedHardHearingDeafCode : '',
                            gcPainLocationText: values.gcPainCode==='LOC02' ? values.gcPainLocationText : '',
                            gcSwellingLocationText: values.gcSwellingCode==='LOC02' ? values.gcSwellingLocationText : '',
                            gcSensationLtProprioceptionText: (values.gcSensationLtProprioceptionCode==='PRO02' || values.gcSensationLtProprioceptionCode==='PRO03') ? values.gcSensationLtProprioceptionText : '',
                            gcSensationRtProprioceptionText: (values.gcSensationRtProprioceptionCode==='PRO02' || values.gcSensationRtProprioceptionCode==='PRO03') ? values.gcSensationRtProprioceptionText : '',
                            gcSensationLtLightTouchCodeText: (values.gcSensationLtLightTouchCode==='PRO02' || values.gcSensationLtLightTouchCode==='PRO03') ? values.gcSensationLtLightTouchCodeText : '',
                            gcSensationRtLightTouchCodeText: (values.gcSensationRtLightTouchCode==='PRO02' || values.gcSensationRtLightTouchCode==='PRO03') ? values.gcSensationRtLightTouchCodeText : '',
                            msLlLtHipFlexorOther: values.msLlLtHipFlexorCode==='MSL10' ? values.msLlLtHipFlexorOther : '',
                            msLlRtHipFlexorOther: values.msLlRtHipFlexorCode==='MSL10' ? values.msLlRtHipFlexorOther : '',
                            msLlLtHipAbductorOther: values.msLlLtHipAbductorCode==='MSL10' ? values.msLlLtHipAbductorOther : '',
                            msLlRtHipAbductorOther: values.msLlRtHipAbductorCode==='MSL10' ? values.msLlRtHipAbductorOther : '',
                            msLlLtHipAdductorOther: values.msLlLtHipAdductorCode==='MSL10' ? values.msLlLtHipAdductorOther : '',
                            msLlRtHipAdductorOther: values.msLlRtHipAdductorCode==='MSL10' ? values.msLlRtHipAdductorOther : '',
                            msLlLtKneeFlexorOther: values.msLlLtKneeFlexorCode==='MSL10' ? values.msLlLtKneeFlexorOther : '',
                            msLlRtKneeFlexorOther: values.msLlRtKneeFlexorCode==='MSL10' ? values.msLlRtKneeFlexorOther : '',
                            msLlLtKneeExtensorOther: values.msLlLtKneeExtensorCode==='MSL10' ? values.msLlLtKneeExtensorOther : '',
                            msLlRtKneeExtensorOther: values.msLlRtKneeExtensorCode==='MSL10' ? values.msLlRtKneeExtensorOther : '',
                            msLlLtAnkleDorsiflexorOther: values.msLlLtAnkleDorsiflexorCode==='MSL10' ? values.msLlLtAnkleDorsiflexorOther : '',
                            msLlRtAnkleDorsiflexorOther: values.msLlRtAnkleDorsiflexorCode==='MSL10' ? values.msLlRtAnkleDorsiflexorOther : '',
                            msLlLtAnklePlantarflexorOther: values.msLlLtAnklePlantarflexorCode==='MSL10' ? values.msLlLtAnklePlantarflexorOther : '',
                            msLlRtAnklePlantarflexorOther: values.msLlRtAnklePlantarflexorCode==='MSL10' ? values.msLlRtAnklePlantarflexorOther : '',
                            makAssistiveDeviceOther: values.makAssistiveDeviceCode==='ASS04' ? values.makAssistiveDeviceOther : '',
                            keMakAssistiveDeviceOther: values.keMakAssistiveDeviceCode==='ASS04' ? values.keMakAssistiveDeviceOther : '',
                            makGaitAnalysisAffectedSideOther: values.makGaitAnalysisAffectedSide.some((d:string)=>d==='GAS08') ? values.makGaitAnalysisAffectedSideOther : '',
                            makGaitAnalysisUnaffectedSideOther: values.makGaitAnalysisUnaffectedSideCode==='UNA02' ? values.makGaitAnalysisUnaffectedSideOther : '',
                            makOtherSquattingAffectdSideOther: values.makOtherSquattingAffectdSide.some((d:string)=>d==='SAS06') ? values.makOtherSquattingAffectdSideOther : '',
                            makOtherLtSingleLegStanceAffectdSideOther: values.makOtherLtSingleLegStanceAffectdSide.some((d:string)=>d==='SLA05') ? values.makOtherLtSingleLegStanceAffectdSideOther : '',
                            makOtherRtSingleLegStanceAffectdSideOther: values.makOtherRtSingleLegStanceAffectdSide.some((d:string)=>d==='SLA05') ? values.makOtherRtSingleLegStanceAffectdSideOther : '',
                            keMakOtherSquattingAffectdSideOther: values.keMakOtherSquattingAffectdSide.some((d:string)=>d==='SAS06') ? values.keMakOtherSquattingAffectdSideOther : '',
                            keMakOtherLtSingleLegStanceAffectdSideOther: values.keMakOtherLtSingleLegStanceAffectdSide.some((d:string)=>d==='SLA05') ? values.keMakOtherLtSingleLegStanceAffectdSideOther : '',
                            keMakOtherRtSingleLegStanceAffectdSideOther: values.keMakOtherRtSingleLegStanceAffectdSide.some((d:string)=>d==='SLA05') ? values.keMakOtherRtSingleLegStanceAffectdSideOther : '',
                        },
                        success,
                        reject
                    );
                }
            }
        });
    }

    return (
        <StyledDrawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='85vw'
            title={
                <div className='d-flex is--space-between width--full'>
                    {formatMessage({id:'PRELIMINARY_EXAMINATION'})}
                    <div style={{
                        padding: '5px 10px',
                        backgroundColor: '#F3F3F3',
                        color: '#2E2E2E',
                        display: 'flex',
                        alignItems: 'center',
                        border: 'none',
                        borderRadius: '2rem',
                        fontSize: '0.9vw',
                        fontWeight: 'normal',
                        cursor: 'default'
                    }}>
                        <img src={icon_calendar} className='mr-5'/>
                        {formatMessage({id:'CLASS_DATE'})}
                        <span className='ml-10 mr-10'>|</span>
                        <span className='text--500'>
                            {`${props.packageName || '--'}${props.className ? ' - ' + props.className : ''}`}
                        </span>
                        <span className='ml-15 text--500'>
                            {props.treatmentDate ? moment(props.treatmentDate).format('DD/MM/YYYY') : ''}
                        </span>
                    </div>
                    {/* <PlanDateSelector selectedPlanId={props.planId} treatmentData={props.treatmentData} onChange={val=>setSelectedPlan(val)}/> */}
                </div>
            }
            footer={
                <div className="pull--right">
                    <Button className="is--primary rect" onClick={handleSave}>
                        {formatMessage({id:'SAVE_UPPERCASE'})}
                    </Button>
                </div>
            }
        >
            <DrawerContent>
                <div className="menu">
                    <div className='menuContent'>
                        <Link to='LL' containerId='scrollContent' className={(mainClass === 'LL' ? 'expand' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setMainClass('LL');setSubClass('GeneralCondition');}} spy isDynamic offset={-5}>
                            <RoundButton 
                                icon={<img src={icon_lower_limbs_menu}/>} 
                                text='Lower Limbs' 
                                bgc='#FFFFFF' 
                                textColor='#000000' 
                                width='65px' 
                                height='65px' 
                                onClick={()=>{}}
                            />
                            <div className='subMenu'>
                                <Link to='GeneralCondition' containerId='scrollContent' spy isDynamic className={(subClass === 'GeneralCondition' ? 'customActive' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setSubClass('GeneralCondition')}}offset={-5}>
                                    {formatMessage({id:'GENERAL_CONDITION'})}
                                </Link>
                                <Link to='ActiveRange' containerId='scrollContent' spy isDynamic className={(subClass === 'ActiveRange' ? 'customActive' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setSubClass('ActiveRange')}}>
                                    {formatMessage({id:'ACTIVE_RANGE_OF_MOTION'})}
                                </Link>
                                <Link to='MuscleTone' containerId='scrollContent' spy isDynamic className={(subClass === 'MuscleTone' ? 'customActive' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setSubClass('MuscleTone')}}>
                                    {formatMessage({id:'MUSCLE_TONE'})}
                                </Link>
                                <Link to='MuscleStrength' containerId='scrollContent' spy isDynamic className={(subClass === 'MuscleStrength' ? 'customActive' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setSubClass('MuscleStrength')}}>
                                    {formatMessage({id:'MUSCLE_STRENGTH'})}
                                </Link>
                                <Link to='Balance' containerId='scrollContent' spy isDynamic className={(subClass === 'Balance' ? 'customActive' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setSubClass('Balance')}}>
                                    {formatMessage({id:'BALANCE'})}
                                </Link>
                                <Link to='MovementAnalysis'  containerId='scrollContent' spy isDynamic className={(subClass === 'MovementAnalysis' ? 'customActive' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setSubClass('MovementAnalysis')}}style={{display: 'flex', alignItems: 'center'}} >
                                    <div className='text--right'>
                                        {formatMessage({id:'MOVEMENT_ANALYSIS_BR'}, {br: <br/>})}
                                    </div>
                                </Link>
                            </div>
                        </Link>
                        

                        <Link to='UL' containerId='scrollContent' className={(mainClass === 'UL' ? 'expand' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setMainClass('UL');setSubClass('Description');}} spy isDynamic offset={-5}>
                        <RoundButton 
                                icon={<img src={icon_upper_limbs_menu}/>} 
                                text='Upper Limbs' 
                                bgc='#FFFFFF' 
                                textColor='#000000' 
                                width='65px' 
                                height='65px' 
                                onClick={()=>{}}
                            />
                            <div className='subMenu'>
                                <Link to='Description' containerId='scrollContent' spy isDynamic className={(subClass === 'Description' ? 'customActive' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setSubClass('Description')}} offset={-5}>
                                    {formatMessage({id:'DESCRIPTION'})}
                                </Link>
                            </div>
                        </Link>
                        

                        <Link to='KE' containerId='scrollContent' className={(mainClass === 'KE' ? 'expand' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setMainClass('KE');setSubClass(props.isPreliminary ? 'UserFitting' : 'KEMovementAnalysis');}} spy isDynamic >
                        <RoundButton 
                                icon={<img src={icon_keeogo_exam_menu}/>} 
                                text='Keeogo Exam' 
                                bgc='#FFFFFF' 
                                textColor='#000000' 
                                width='65px' 
                                height='65px' 
                                onClick={()=>{}}
                            />
                            <div className='subMenu'>
                                {props.isPreliminary &&
                                <Link to='UserFitting' containerId='scrollContent' spy isDynamic className={(subClass === 'UserFitting' ? 'customActive' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setSubClass('UserFitting')}} style={{display: 'flex', alignItems: 'center'}} >
                                    <div className='text--right'>
                                        {formatMessage({id:'USER_FITTING'})}
                                    </div>
                                </Link>}
                                <Link to='KEMovementAnalysis' containerId='scrollContent' spy isDynamic className={(subClass === 'KEMovementAnalysis' ? 'customActive' : '') + (clickFlag ? '' : ' scroll')} onClick={()=>{setClickFlag(true);setSubClass('KEMovementAnalysis')}} style={{display: 'flex', alignItems: 'center'}} >
                                    <div className='text--right'>
                                        {formatMessage({id:'MOVEMENT_ANALYSIS_WITH_BR'}, {br: <br/>})}
                                    </div>
                                </Link>
                            </div>
                        </Link>
                    </div>

                    {props.editData!==undefined&&
                        <div style={{
                                borderTop: '2px #909090 solid', 
                                paddingTop: '10px', 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'center', 
                                position: 'sticky', 
                                bottom: 0,
                                width: '100%',
                                backgroundColor: '#F3F3F3',
                                fontSize: '0.9vw'
                            }}
                        >
                            <div className='text--500'>{formatMessage({id:'UPDATE'})}: {moment(props.editData.updatedAt).format('DD/MM/YYYY')}</div>
                            <div className='text--bold'>{modifiedName}</div>
                        </div>                    
                    }
                </div>
                <div className="scrollContent" id='scrollContent' onWheel={()=>{setMainClass('');setSubClass('');setClickFlag(false)}}>
                    <Form form={form} layout='vertical' onValuesChange={(changedValues, allValues)=>setFormData(allValues as EvaluationData)}>
                        <Element name='LL'>
                            <Element name='GeneralCondition'>
                                <GeneralCondition data={formData} />
                            </Element>
                            <Element name='ActiveRange'>
                                <ActiveRange form={form} data={formData}/>
                            </Element>
                            <Element name='MuscleTone'>
                                <MuscleTone data={formData} userInfoData={props.userInfoData}/>
                            </Element>
                            <Element name='MuscleStrength'>
                                <MuscleStrength data={formData} form={form} userInfoData={props.userInfoData} firstAdd={props.editData===undefined}/>
                            </Element>
                            <Element name='Balance'>
                                <Balance data={formData} userInfoData={props.userInfoData}/>
                            </Element>
                            <Element name='MovementAnalysis'>
                                <MovementAnalysis data={formData}/>
                            </Element>
                        </Element>

                        <Element name='UL'>
                            <Element name='Description'>
                                <Description data={formData} />
                            </Element>
                        </Element>

                        <Element name='KE'>
                            {props.isPreliminary &&
                            <Element name='UserFitting'>
                                <UserFitting showPreliminary={(props.isPreliminary && props.editData!==undefined) || (!props.editData && !props.hasPreliminary)} form={form} data={formData}/>
                            </Element>}
                            <Element name='KEMovementAnalysis'>
                                <KEMovementAnalysis 
                                    showPreliminary={(props.isPreliminary && props.editData!==undefined) || (!props.editData && !props.hasPreliminary)}  
                                    data={formData} 
                                    kraData={props.kraData} 
                                    classData={{ 
                                        treatmentName: props.packageName, 
                                        classNum: props.className, 
                                        treatmentDate: props.treatmentDate, 
                                        treatmentHour: props.treatmentHour 
                                    }}
                                />
                            </Element>
                        </Element>
                    </Form>
                </div>
            </DrawerContent>
        </StyledDrawer>
    )
}

export default EvaluationDrawer;
