import React from 'react';
import { uniqBy, takeLast, ifElse, identity, isNil, length, concat, filter } from 'ramda';
const { compose } = require('ramda');

type Props<T> = {
    defaultRowKeys?: React.Key[];
    defaultRows?: T[];
    multiple?: boolean;
    limit?: number;
};

/**
 *
 * @param defaultRowKeys - 預設選取的row key陣列
 * @param defaultRows - 預設選取的物件陣列
 */
export default function useRowSelection<T>({
    defaultRowKeys = [],
    defaultRows = [],
    multiple = true,
    limit,
}: Props<T> = {}) {
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(defaultRowKeys);
    const [selectedRows, setSelectedRows] = React.useState<T[]>(defaultRows);

    const onSelectChange = (rowKeys: React.Key[], rows: T[]) => {
        if (!isNil(limit) && length(rowKeys) > limit) {
            return;
        }

        setSelectedRowKeys(ifElse(() => multiple, identity, takeLast(1))(rowKeys));
        setSelectedRows(prev =>
            ifElse(
                () => multiple,
                compose(
                    uniqBy((x: any) => x?.id),
                    filter((x: any) => rowKeys.includes(x?.id)),
                    filter(Boolean),
                    concat(rows),
                ),
                () => takeLast(1)(rows),
            )(prev),
        );
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleClearAllRow = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    return {
        rowSelection,
        selectedRows,
        selectedRowCount: selectedRows.length,
        handleClearAllRow,
    };
}
