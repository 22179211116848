import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/button/button';
import ReportCard from './ReportCard';
import SummaryView from './SummaryView';
import AddReportDrawer from './AddReportDrawer';
import { SummaryProps } from './SummaryTab';
import { getReportList, SummaryListRes } from 'api/summary';
import moment from 'moment';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const Wrapper = styled.div`
    width: 100%;
    background-color: white;

    .Title {
        font-size: 1.3rem;
        color: black;
        font-weight: bolder;
    }
`;

interface SummaryListProps extends SummaryProps {
    
}

const SummaryList: React.FC<SummaryListProps> = (props: SummaryListProps) => {

    const { formatMessage } = useIntl();
    const [permission] = usePermission('Summary');
    const isBusinessUser = localStorage.getItem('role') === '5';

    const [showAddReportDrawer, setShowAddReportDrawer] = useState(false);
    const [reportData, setReportData] = useState<SummaryListRes>();

    useEffect(()=>{
        getReportList(
            props.caseId
        ).then(res=>{
            setReportData(res.data.data);
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }, []);

    const reportSource = isBusinessUser ? (
        reportData?.summaryList?.filter(d=>d.packageShare || d.trainingShare || d.kraShare || d.commentShare) ?? []
    ) : (
        reportData?.summaryList ?? []
    );
    const reports = reportSource.map(d=>(
        <Col span={6} className='mb-24'>
            <ReportCard 
                name={d.reportName}
                date={moment(d.createdAt).format('DD/MM/YYYY')} 
                time={moment(d.createdAt).format('HH:mm')}
                onClick={()=>props.setPageContent(<SummaryView {...props} reportId={d.id} treatmentType={d.treatmentType} />)}
                share={d.packageShare || d.trainingShare || d.kraShare || d.commentShare}
                invalid={d.invalid}
                modified={d.updateState}
            />
        </Col>
    ))

    return (
        <Wrapper>
            {showAddReportDrawer && <AddReportDrawer setPageContent={props.setPageContent} onClose={()=>setShowAddReportDrawer(false)} summaryProps={props} />}

            <div className='d-flex v--center is--space-between width--full padding-15' style={{position: 'sticky', top: 0, zIndex: 1000, backgroundColor: '#FFFFFF'}}>
                <div className='Title'>{formatMessage({id:'REPORTS'})}</div>
                <div className='d-flex v--center'>
                    <div className='d-flex v--center mr-10'>
                        {reportData?.packagesCompleted ?? 0}  {formatMessage({id:'PACKAGE_COMPLETED'}, {plural: reportData?.packagesCompleted ?? 0 > 1 ? 's' : ''})}
                        <div style={{borderLeft: '2px #E3E3E3 solid', paddingLeft: '10px', marginLeft: '10px'}}>
                            {reportData?.packageInProgress ?? 0}  {formatMessage({id:'PACKAGE_IN_PROGRESS'}, {plural: reportData?.packageInProgress ?? 0 > 1 ? 's' : ''})}
                        </div>
                        <div style={{borderLeft: '2px #E3E3E3 solid', paddingLeft: '10px', marginLeft: '10px'}}>
                            {reportData?.classCompleted ?? 0}  {formatMessage({id:'CLASS_COMPLETED'}, {plural: reportData?.classCompleted ?? 0 > 1 ? 'es' : ''})}
                        </div>
                    </div>
                    {checkIfAvailable(permission, 'Add') && 
                    <Button
                        icon={<PlusOutlined className='text-24 mr-5'/>}
                        className='rect text--bold d-flex v--center'
                        style={{backgroundColor: '#0083C1', color: '#FFFFFF', padding: '10px 15px'}}
                        onClick={()=>setShowAddReportDrawer(true)}
                    >{formatMessage({id:'GENERATE_NEW'})}</Button>}
                </div>
            </div>
            <div className='padding-15'>
                <Row gutter={24} style={{padding: '0 15px'}}>
                    {reports}
                </Row>
            </div>
        </Wrapper>
    )
}

export default SummaryList;
