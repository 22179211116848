import React, { useState, useEffect } from 'react';
import LLPage from './LLPage';
import ULPage from './ULPage';
import KeeogoPage from './KeeogoPage';
import InvalidRecordPage from './InvalidRecordPage';
import EvaluationDrawer from '../EvaluationForms/EvaluationDrawer';
import { findOneEvaluation } from 'api/eavluation';
import { getOneRecord, KRAdata } from 'api/record';
import { Evaluation } from '../type';
import { PageProps } from '../EvaluationTab';

export enum ViewPageOption {
    LLPage,
    ULPage,
    KeeogoPage,
    // InvalidRecord
}

interface Props extends PageProps {
    evaluationId: number;
    suspend: boolean;
    pageType: ViewPageOption;
    refetchPlanDate: () => void;
    isPreliminary: boolean;
    hasPreliminary: boolean;
}

const ViewPage: React.FC<Props> = (props :Props) => {

    const [drawerSetting, setDrawerSetting] = useState({
        showDrawer: false,
        tabKey: 'GeneralCondition'
    });
    const [currentEvaluation, setCurrentEvaluation] = useState<Evaluation>();
    const [kraData, setKraData] = useState<KRAdata[]>([]);
    const [planDetailData, setPlanDetailData] = useState({
        treatmentName: '--',
        classNum: '',
        treatmentDate: '',
        treatmentHour: ''
    });

    const pageRender = {
        [ViewPageOption.LLPage]: <LLPage setDrawerSetting={setDrawerSetting} data={currentEvaluation?.evaluation_detail} suspend={props.suspend} />,
        [ViewPageOption.ULPage]: <ULPage setDrawerSetting={setDrawerSetting} data={currentEvaluation?.evaluation_detail} suspend={props.suspend} />,
        [ViewPageOption.KeeogoPage]: <KeeogoPage setDrawerSetting={setDrawerSetting} data={currentEvaluation?.evaluation_detail} suspend={props.suspend} isPreliminary={props.isPreliminary} kraData={kraData} classData={planDetailData} />,
        // [ViewPageOption.InvalidRecord]: <InvalidRecordPage data={currentEvaluation} />
    }

    useEffect(()=>{
        fetchEvaluation();
    }, []);

    useEffect(()=>{
        if(currentEvaluation?.trainingId){
            getOneRecord(
                currentEvaluation.trainingId
            ).then(res=>{
                setKraData(res.data.data.KRA);
                setPlanDetailData({
                    treatmentName: res.data.data.treatmentPlanMasterName,
                    classNum: res.data.data.classNum,
                    treatmentDate: res.data.data.treatmentDate,
                    treatmentHour: res.data.data.treatmentHour
                });
            }).catch(err=>{
                console.error(err);
            });
        }
    }, [currentEvaluation]);

    const fetchEvaluation = () => {
        findOneEvaluation(
            props.evaluationId
        ).then(res=>{
            setCurrentEvaluation(res.data.data);
        }).catch((error: any)=>{
            console.error(error);
        });
    }

    return (
        <div>
            {drawerSetting.showDrawer&&
            <EvaluationDrawer 
                tabKey={drawerSetting.tabKey} 
                onClose={()=>setDrawerSetting({showDrawer: false, tabKey: 'GeneralCondition'})}
                editData={currentEvaluation?.evaluation_detail}
                evaluationDate={currentEvaluation?.evaluationDate}
                planId={currentEvaluation?.personalTreatmentPlanDetailId ?? 0}
                userInfoData={props.userInfoData}
                treatmentData={props.treatmentData}
                refetch={()=>{fetchEvaluation();props.refetchPlanDate();}}
                caseId={props.caseId}
                hasPreliminary={props.hasPreliminary}
                isPreliminary={currentEvaluation?.isPreliminary}
                kraData={kraData}
                treatmentDate={currentEvaluation?.treatmentDate ?? ''}
                treatmentHour={currentEvaluation?.treatmentHour ?? ''}
                packageName={currentEvaluation?.packageName ?? ''}
                className={currentEvaluation?.className ?? ''}
            />}
            {pageRender[props.pageType]}
        </div>
    )
}

export default ViewPage;
