import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { apiCaller } from '../../service/apiCaller';
import { getRoleListApi, insertRoleApi, editRoleApi, deleteRoleApi, I_role } from '../../api/role';
import { EditRole, EditRight } from '../../components/component'
import Button from 'components/button/button';
import { Input, Modal, Tooltip, Tag, Empty, Dropdown, Menu } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    SettingOutlined,
    MoreOutlined
} from '@ant-design/icons';
import { icon_edit_blue } from 'components/image';
import Table from 'components/table/table';
import Pagination, { PaginationState } from 'components/pagination/pagination';
import { warning, Icon_permission } from '../../components/image';
import cx from 'classnames';
import './roleMgmt.sass';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';
import { Search } from 'components/form/input';
import CardList from 'components/card/cardList';
import styled from 'styled-components';
import moment from 'moment';
import Filter from 'components/filter/filter';
import { FormInstance } from 'antd/lib/form';
import { RoleType } from 'service/permission';


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const RoleMgmt: React.FC = () => {
    const [myPermission] = usePermission('Access');
    const intl = useIntl();
    const [selected, setSelected] = useState<RoleType>(); // 被選中的資料列
    const [setting, setSetting] = useState({
        search: '',
        editRole: false,
        errorMsg: '',
        editRight: false,
        deleteRole:false
    });

    // 角色清單
    const [list, setList] = useState<Array<RoleType>>([]);
    const [listTotal, setListTotal] = useState(0);

    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10
    });

    // search/filter keyword
    // const [keyword ,setKeyword] = useState("")

    // list getter : get the filtered and sorted result
    // const getListView = ()=>{

    //     let res = list.filter(x=>x.name.indexOf(keyword) > -1);

    //     res = list.slice(pagination.page-1, pagination.page*pagination.pageSize);

    //     return res
    // }

    const columns = [
        {
            dataIndex: 'name',
            title: intl.formatMessage({id:'NAME'}),
            sorter: (a:any,b:any)=> a.name.localeCompare(b.name),
            width: 370,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'amount',
            title: intl.formatMessage({id:'NUMBER'}),
            sorter: (a:any,b:any)=> a.amount - b.amount,
            width: 200,
            render: (text:string, record:any)=>text?text:'--',
            ellipsis: true
        },
        {
            dataIndex: 'updatedAt',
            width: 120,
            title: intl.formatMessage({id:'UPDATE_DATE'}),
            render: (text:any)=>text?moment(text).format('DD/MM/YYYY'):'--',
            sorter: (a:any,b:any)=> b.updatedAt - a.updatedAt,
            ellipsis: true
        },
        {
            key: 'action',
            width: 50,
            render: (text:any, record:any) => checkIfAvailable(myPermission, 'Edit')&&(
                <Button
                    type='link'
                    style={{display: 'flex', alignItems: 'center'}}
                    icon={<img src={icon_edit_blue} className='mr-10'/>}
                    data-id={record.id}
                    onClick={() => {
                        setSetting({ ...setting, editRight: true });
                        setSelected(record);
                    }}
                />
            )
        }
    ];

    useEffect(() => {
        getRoleList({
            page: pagination.page,
            pageSize: pagination.pageSize
        });
    }, []);

    const getRoleList = (queryParams: {
        pageSize:number,
        page:number,
        keyword?:string,
        startDate?:string,
        endDate?:string
    }) => {
        const success = (res: any) => {
            const { status, data } = res;
            if (status === 200) {
                setList(data.data);
                setListTotal(data.total);
            }
        };
        const reject = (error: any) => {
            const { status } = error;
            console.log(status);
        };
        apiCaller(getRoleListApi, queryParams, success, reject);
    };
    const editRole = (role: I_role) => {
        const success = (res: any) => {
            const { status, data } = res;
            if (status === 200) {
                Modal.success ({
                    title: intl.formatMessage({id:'SUCCESS'}),
                    maskClosable:true,
                    onCancel: ()=>{
                        getRoleList({
                            page: 1,
                            pageSize: pagination.pageSize
                        });
                        setPagination({page: 1, pageSize: pagination.pageSize});
                        setSetting({ ...setting, editRole: false, errorMsg: '', });
                    }
                })
            }else{
                Modal.warn ({
                    title: intl.formatMessage({id:'WARN'}),
                    maskClosable:true,
                    content:(
                        <div style={{ wordBreak:'break-word' , whiteSpace: 'break-spaces' ,textAlign:'center' }}>
                            {res?.data?.detail}
                        </div>
                    )
                })
            }
        };
        const reject = (error: any) => {
            const { status, data: { detail } } = error;
            
            Modal.error ({
                title: intl.formatMessage({id:'ERROR'}),
                maskClosable:true,
                content:(
                    <div style={{ wordBreak:'break-word' , whiteSpace: 'break-spaces' ,textAlign:'center' }}>
                        {detail}
                    </div>
                )
            })

            setSetting({ ...setting, errorMsg: detail });
        };
        apiCaller(insertRoleApi, role, success, reject);
    };
    // const deleteRole = (role: I_role) => {
    //     const success = (res: any) => {
    //         const { status, data } = res;
    //         if (status === 200) {
    //             getRoleList({
    //                 page: 1,
    //                 pageSize: pagination.pageSize
    //             });
    //             setPagination({page: 1, pageSize: pagination.pageSize});
    //             setSetting({ ...setting, deleteRole: false })
    //         }
    //     };
    //     const reject = (error: any) => {
    //         const { status } = error;
    //         console.log(status);
    //     };
    //     apiCaller(deleteRoleApi, role.id, success, reject);
    // };

    const onFilterSubmit = (form: FormInstance) => {
        const keyword = form.getFieldValue('search');
        const rangeDate = form.getFieldValue('date') || [moment(''), moment('')];
        const startDate = rangeDate[0]?.isValid()?rangeDate[0].format('YYYY/MM/DD'):undefined;
        const endDate = rangeDate[1]?.isValid()?rangeDate[1].format('YYYY/MM/DD'):undefined;

        getRoleList({
            page: pagination.page,
            pageSize: pagination.pageSize,
            keyword: keyword,
            startDate: startDate,
            endDate: endDate
        })
    }

    return (
        <Wrapper>
            <div className='main'>

                {setting.editRole && <EditRole initData={selected} edit={editRole} close={() => setSetting({ ...setting, editRole: false, errorMsg: '' })} errorMsg={setting.errorMsg} />}
                {setting.editRight && <EditRight initData={selected} refetch={()=>{getRoleList({page: 1, pageSize: pagination.pageSize});setPagination({page: 1, pageSize: pagination.pageSize})}} close={() => setSetting({ ...setting, editRight: false })} />}
                {/* {setting.deleteRole && <DeleteRole initData={selected} del={deleteRole} close={() => setSetting({ ...setting, deleteRole: false })} />} */}

                <div className="header">
                    <b><strong>
                        <FormattedMessage id='ROLE_MGMT' />{/* 權限管理帳號管理 */}
                    </strong></b>
                    <div>
                        {/*checkIfAvailable(myPermission, 'Search')&&<Filter onSubmit={onFilterSubmit} searchBy={intl.formatMessage({id:'NAME'})}/>*/}
                        {/*新增*/}
                        {
                            checkIfAvailable(myPermission, 'Add')&&<Button
                                icon={<PlusOutlined />}
                                className='is--primary rect ml-16'
                                onClick={() => setSetting({ ...setting, editRole: true })}
                            >
                            </Button>
                        }
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={list}
                    pagination={false}
                    footer={()=>
                        <Pagination 
                            pagination={pagination} 
                            setPagination={setPagination} 
                            paginationTotal={listTotal}
                        />
                    }
                />
            </div>
        </Wrapper>
    );
};


const DeleteRole: React.FC<any> = (props: any) => {
    const { close, initData, del } = props;
    const StyledModal = styled(Modal)`
        .ant-modal-content {
            border-radius: 1rem;
        }
    `;

    return (
        <StyledModal
            visible={true}
            closable={false}
            footer={null}
            width={350}
        >
            <div style={{
                textAlign: 'center',
                display:'flex',
                flexDirection:'column',
                justifyContent: 'space-around',
                minHeight: '400px'
            }}>

                <img
                    style={{
                        width: '100px',
                        display: 'block',
                        margin: '0 auto'
                    }}
                    src={warning}
                />

                <div>
                    <h1 className='mt-10 mb-10 text--lg' >
                        <FormattedMessage id='DELETE_ROLE' /> {/*刪除角色*/}
                    </h1>

                    <p>
                        <FormattedMessage id='DELETE_CONFIRM' /> {/*是否確定刪除*/}
                        {initData.name} ?
                    </p>
                </div>

                <div>
                    <Button
                        className='is--warning mt-10 mb-10'
                        onClick={() => del(initData)}
                        style={{width:'100%'}}
                    >
                        <FormattedMessage id='DELETE' /> {/*刪除*/}
                    </Button>

                    <Button
                        className='is--plain mt-10 mb-10'
                        onClick={close}
                        style={{width:'100%'}}
                    >
                        <FormattedMessage id='CANCEL' /> {/*取消*/}
                    </Button>
                </div>

            </div>
        </StyledModal>
    );
};
