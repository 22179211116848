import React, { useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { EditWrapper } from './index';
import { gait_analysis } from 'components/image';
import { Form, TextArea, Input } from 'components/form';
import { Radio, Checkbox } from 'antd';
import { FormTitle } from '../EvaluationForms/EvaluationDrawer';
import TimeInput from 'react-advanced-time-input';
import { GlobalContext } from '../../../../../index';

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 1.2vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

const StyledCheckbox = styled(Checkbox)`
    margin: 11px 2px !important;

    .ant-checkbox {
        display: none;
    }

    span {
        padding: 10px;
        background-color: #F3F3F3;
        color: #000000;
        border-radius: 0.3rem;
        font-weight: normal;
        white-space: nowrap;
    }

    &.ant-checkbox-wrapper-checked {
        span {
            color: #FFFFFF;
            background-color: #2E63A3;
        }
    }
`;

interface Props {
    data: {
        makGaitAnalysisTime: string,
        makGaitAnalysisGaltSpeed: number,
        makGaitAnalysisFacScoreCode: string,
        makGaitAnalysisAffectedSide: string[],
        makGaitAnalysisAffectedSideOther: string,
        makGaitAnalysisUnaffectedSideCode: string,
        makGaitAnalysisUnaffectedSideOther: string
    };
    KE?: boolean;
}

const GaitAnalysisEdit: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;

    const time = props.data.makGaitAnalysisTime;
    const minute = parseInt(time.split(':')[0]);
    const second = parseInt(time.split(':')[1]);
    const totalTime = minute * 60 + second;
    const speed = (10 / totalTime).toFixed(1);

    const facOptions = getOptions('FACScore').map((d: any)=>(
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    const affectOptions = getOptions('GaitAffectedSide').map((d: any)=>(
        <StyledCheckbox value={d.code}>{d.name}</StyledCheckbox>
    ));

    const unaffectOptions = getOptions('UnaffectedSide').map((d: any)=>(
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    return (
        <EditWrapper>
            <div className="left">
                <div className="icon">
                    <img src={gait_analysis} width='100%' />
                </div>
                <div className="header">
                    {formatMessage({id:'ITEM_GAIT_ANALYSIS'}, {br: <br/>})}
                </div>
            </div>
            <div className="right">
                <div className='d-flex is--space-between width--full'>
                    <Form.Item label={<FormTitle>{formatMessage({id:'TIME'})}</FormTitle>} name={props.KE ? 'keMakGaitAnalysisTime' : 'makGaitAnalysisTime'}>
                    <TimeInput 
                            maxHours={99} 
                            style={{
                                width: '60px', 
                                border: 'none', 
                                backgroundColor: '#F3F3F3', 
                                textAlign: 'center', 
                                fontSize: '20px',
                                fontWeight: 'bold'
                            }} 
                        />
                    </Form.Item>
                    <Form.Item label={<FormTitle>{formatMessage({id:'GAIT_SPEED'})}</FormTitle>} name={props.KE ? 'keMakGaitAnalysisGaltSpeed' : 'makGaitAnalysisGaltSpeed'} valuePropName=' '>
                        <Input 
                            disabled 
                            value={(totalTime > 0 ? speed : '--') + ' m/s'} 
                            style={{
                                fontSize: '20px', 
                                fontWeight: 'bold', 
                                border: 'none', 
                                backgroundColor: 'transparent', 
                                cursor: 'normal', 
                                width: '90px',
                                padding: 0
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={<FormTitle>{formatMessage({id:'FAC'})}</FormTitle>} name={props.KE ? 'keMakGaitAnalysisFacScoreCode' : 'makGaitAnalysisFacScoreCode'}>
                        <Radio.Group buttonStyle="solid">{facOptions}</Radio.Group>
                    </Form.Item>
                </div>
                
                <div className='text-24 text--bold mb-10'>{formatMessage({id:'GAIT_PATTERN'})}</div>

                <Form.Item className='mb-10' label={<FormTitle>{formatMessage({id:'AFFECTED_SIDE'})}</FormTitle>} name={props.KE ? 'keMakGaitAnalysisAffectedSide' : 'makGaitAnalysisAffectedSide'}>
                    <Checkbox.Group>{affectOptions}</Checkbox.Group>
                </Form.Item>

                {props.data['makGaitAnalysisAffectedSide'].some(d=>d==='GAS08')&&
                <Form.Item className='mb-15 width--full' name={props.KE ? 'keMakGaitAnalysisAffectedSideOther' : 'makGaitAnalysisAffectedSideOther'}>
                    <TextArea rows={4} placeholder='Other' maxLength={500}/>
                </Form.Item>}

                <Form.Item className='mb-10' label={<FormTitle>{formatMessage({id:'UNAFFECTED_SIDE'})}</FormTitle>} name={props.KE ? 'keMakGaitAnalysisUnaffectedSideCode' : 'makGaitAnalysisUnaffectedSideCode'}>
                    <Radio.Group buttonStyle="solid">{unaffectOptions}</Radio.Group>
                </Form.Item>

                {props.data[props.KE ? 'keMakGaitAnalysisUnaffectedSideCode' : 'makGaitAnalysisUnaffectedSideCode']==='UNA02'&&
                <Form.Item className='width--full' name={props.KE ? 'keMakGaitAnalysisUnaffectedSideOther' : 'makGaitAnalysisUnaffectedSideOther'}>
                    <TextArea rows={4} placeholder='Other' maxLength={500}/>
                </Form.Item>}
                
            </div>
        </EditWrapper>
    )
}

export default GaitAnalysisEdit;
