// tslint:disable no-implicit-dependencies
import React from 'react';
import moment from 'moment';
import { pathOr, map, not, isNil, filter } from 'ramda';
import { DataSource, MutationOptions } from 'constants/types';
import useAxios from 'hooks/useAxios';
import { api, toQueryString } from './api';
import { useAllRoles } from './role';
import { OrgType } from '../constants/types'
import { User_ } from 'types/User';

const baseUrl = '';
const { compose } = require('ramda');

export type User = {
    abbreviation:string;
    id: number;
    username: string; // 帳號
    name: string; // 姓名
    gender: string; // 性別
    telecomEmail: string; // 主要信箱
    telecomSubEmail: string; // 備用信箱
    telecomPhone: string; // 手機號碼
    managingOrganizationId: number; // 組織代碼
    managingOrganizationType: string; // 組織類型
    managingOrganizationName: string; // 組織名稱
    departmentName: string // 部門
    comment: string; // 備註
    orgType: string; // 組織類型
    nationality: string; // 國籍
    active: boolean; // 啟用/停用
    birthDate: moment.Moment|string; // 生日
    updatedAt: moment.Moment; // 更新時間
    createdAt: moment.Moment; // 建立時間
    role: React.Key[]; // 角色清單
    type: string; // 帳號類型(Company or Patient)
};

export const useUsers = (variables: { [key: string]: any } = {}) => {
    const [data, setData] = React.useState<DataSource<User>>({
        content: [],
        total: 0,
    } as any);

    const [loading, request] = useAxios();

    const listUsers = React.useCallback(
        async ({
            page = 1,
            pageSize = 10,
            ...params
        }: {
            [key: string]: any;
        } = {}) => {
            const response = await request<User[]>(
                `/user?page=${page}&pageSize=${pageSize}&${toQueryString({ ...variables, ...params, sort: 'updatedAt', desc: true, multilingualKey: localStorage.getItem('lang')||'EN'})}`,
            );

            setData({
                content: compose(
                    map((x: User) => ({
                        ...x,
                        managingOrganizationType:OrgType[x.managingOrganizationType],
                        birthDate: moment(x.birthDate),
                        updatedAt: moment(+x.updatedAt),
                        createdAt: moment(+x.createdAt),
                    })),
                    pathOr([], ['data']),
                )(response),
                total: pathOr(0, ['total'], response),
            });
        },
        [], // eslint-disable-line
    );

    React.useEffect(() => {
        listUsers();
    }, []); // eslint-disable-line

    return {
        loading,
        refetch: listUsers,
        dataSource: data,
    };
};

export const useQueryUser = () => {
    const [data, setData] = React.useState<User | undefined>();
    const [loading, request] = useAxios();
    const { roleById } = useAllRoles();

    const queryUser = async (id: React.Key) => {
        const response = await request<User>(`/user/${id}?multilingualKey=en`);

        setData({
            ...response.data,
            birthDate: moment(response.data.birthDate).isValid()?moment(response.data.birthDate):'',
            updatedAt: moment(response.data.updatedAt),
            role: compose(
                filter(compose(not, isNil)),
                map((roleId: number) => pathOr(null, [roleId], roleById)),
                pathOr<any[]>([], ['data', 'role']),
            )(response),
        });
    };

    return { loading, queryUser, userItem: data };
};

export const useCreateUser = (options: MutationOptions = {}) => {
    const [, request] = useAxios(options);

    return (data: Partial<User>) =>
        request('/user', {
            data,
            method: 'POST',
        });
};

export const useUpdateUser = (options: MutationOptions = {}) => {
    const [, request] = useAxios(options);

    return (data: Partial<User>) =>
        request(`/user/${data.id}`, {
            data,
            method: 'PUT',
        });
};

export const useActiveUser = (options: MutationOptions = {}) => {
    const [, request] = useAxios(options);

    return (data: Partial<User>) =>
        request(`/user/${data.id}/activate`, {
            data,
            method: 'PUT',
        });
};

export const getUser = (id: string) => api.get(`${baseUrl}/user?username=${id}&multilingualKey=EN`);

export const getUserById = (id: string) => api.get(`/user/${id}?multilingualKey=EN`);

export const deleteUser = (id: string) => api.delete(`${baseUrl}/user/${id}`);

export const editPassword = ({username, oldPassword, newPassword}:{username:string;oldPassword:string;newPassword:string;}) => api.post(`${baseUrl}/user/editPassword`, {username, oldPassword, newPassword});

export const importAvatar = (payload: FormData) => api.post(`${baseUrl}/user/import-avatar`, payload);

