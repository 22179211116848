import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import TreatmentCard from './TreatmentCard';
import { useIntl } from 'react-intl';
import Switch from 'components/switch/switch';
import { Row, Col, Modal } from 'antd';
import { TreatmentProps } from './TreatmentCard';
import { PlusOutlined } from '@ant-design/icons';
import BookTreatmentDrawer from './BookTreatmentDrawer';
import { ToggleFinishModal, ModifiedRecordDrawer } from './FinishRemark';
import PlanInfoDrawer from './PlanInfoDrawer';
import { GlobalContext } from '../../../../index';
import moment from 'moment';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const Wrapper = styled.div`
    background-color: #F3F3F3;
    padding: 15px;
    width: 100%;

    .infoBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        border-bottom: 1px solid #909090;

        .info {
            display: flex;
            align-items: center;

            .icon {
                border: solid 1px rgba(0,0,0,0.65);
                border-radius: 0.5rem;
                margin-right: 5px;
                width: 25px;
                height: 25px;
            }

            .name {
                font-weight: bold;
                font-size: 16px;
                color: #2E2E2E;
                margin-right: 15px;
                cursor: pointer;
            }

            .startDate {
                color: #2E2E2E;
                font-size: 11px;
            }
        }

        .control {
            display: flex;
            align-items: center;

            .delete {
                margin-right: 10px;
                cursor: pointer;
            }

            .finish {
                margin-left: 10px;

                .text {
                    margin-right: 5px;
                }

                .ant-switch-checked {
                    background-color: #5DD197;
                }
            }
        }
    }

    .treatmentContainers {
        padding: 15px 0;

        .ant-row:not(:first-child) {
            margin-top: 20px;
        }
    }
`;

interface Props {
    title: string;
    startDate: string;
    isFinish: boolean;
    onCheckDetail: (props: TreatmentProps) => void;
    classNum: number;
    freeClassNum: number;
    classType: string;
    treatmentType: string;
    treatmentData: any[];
    refetch: Function;
    planId: number;
    caseId: number;
    planModifiedRecord: any[];
    orgId: number;
    finishLength:number;
}

const Plan: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const [isFinish, setIsFinish] = useState(props.isFinish);
    const [isNextState, setisNextState] = useState(true);
    const [setting, setSetting] = useState({
        showPlanInfoDrawer: false,
        showAddRecordDrawer: false,
        showToggleFinishModal: false,
        showBookTreatmentDrawer: false,
        nextFinishStatus: props.isFinish
    });
    const { getCode } = useContext(GlobalContext).dropdownOptionCtx;
    const [permission] = usePermission('Plan');

    useEffect(()=>{
        setIsFinish(props.isFinish);
    }, [props.isFinish])

    let nextOnIndex = -1;

    const treatmentCardsSort = props.treatmentData.sort((a,b)=>moment(`${a.treatmentDate} ${a.treatmentHour}`, 'YYYY/MM/DD HH:mm').diff(moment(`${b.treatmentDate} ${b.treatmentHour}`, 'YYYY/MM/DD HH:mm')))
    
    for(let i=0;i<treatmentCardsSort.length;i++){
       if( getCode('TreatmentDetailStatus', treatmentCardsSort[i].treatmentDetailStatusCode)==='TDS02' ){
            nextOnIndex = i;
            break;
        }
    }
    const treatmentCards = treatmentCardsSort.map((treatment: any, i: number,arry)=>(
       
        <Col span={6}>
            <TreatmentCard 
                id={treatment.id}
                name={`${formatMessage({id:'TREATMENT'})} ${(i+1).toString().padStart(2, '0')}`}
                treatmentDate={treatment.treatmentDate}
                treatmentHour={treatment.treatmentHour}
                classTypeCode={props.classType}
                treatmentTypeCode={props.treatmentType}
                treatmentDetailStatusCode={treatment.treatmentDetailStatusCode}
                onClick={props.onCheckDetail}
                treatmentOrg={treatment.abbreviation}
                memo={treatment.memo}
                //  isNext={i===0&&getCode('TreatmentDetailStatus', treatment.treatmentDetailStatusCode)==='TDS02'}
                isNext={i===nextOnIndex}
            />
        </Col> 
    ));

    if(!props.isFinish && checkIfAvailable(permission, 'BookTreatment')){
        for(let i=0; i<props.freeClassNum; ++i){
            treatmentCards.push(
                <Col span={6}>
                    <NewTreatment onClick={()=>setSetting({...setting, showBookTreatmentDrawer: true})}/>
                </Col>
            );
        }
    }

    const treatmentGroupBy4 = [];
    for(let i=0; i<treatmentCards.length; i+=4){
        treatmentGroupBy4.push(
            <Row gutter={24}>
                {treatmentCards.slice(i, i+4)}
            </Row>
        );
    }


    return (
        <Wrapper>
            {setting.showBookTreatmentDrawer&&<BookTreatmentDrawer 
                planId={props.planId} 
                refetch={props.refetch} 
                onClose={()=>setSetting({...setting, showBookTreatmentDrawer: false})} 
                remainingClass={props.freeClassNum} 
                numberClass={props.treatmentData.length}
                treatmentType={props.treatmentType} 
                orgId={props.orgId}
                title={props.title}
            />}
            {setting.showToggleFinishModal&&<ToggleFinishModal 
                onClose={()=>setSetting({...setting, showToggleFinishModal: false})} 
                handleOk={()=>setSetting({...setting, showToggleFinishModal: false, showAddRecordDrawer:true})} 
                nextFinishStatus={setting.nextFinishStatus} 
                
            />}
            {setting.showAddRecordDrawer&&<ModifiedRecordDrawer
                onClose={()=>setSetting({...setting, showAddRecordDrawer: false})}
                afterDone={()=>{setIsFinish(setting.nextFinishStatus);props.refetch(setting.nextFinishStatus?undefined:{id: props.caseId, inFinish: true});}}
                nextFinishStatus={setting.nextFinishStatus}
                planId={props.planId}
                refetch={props.refetch}
                finishLength={props.finishLength}
            />}
            {setting.showPlanInfoDrawer&&<PlanInfoDrawer
                onClose={()=>setSetting({...setting, showPlanInfoDrawer: false})}
                planName={props.title}
                startDate={props.startDate}
                status={props.isFinish?
                    formatMessage({id:'COMPLETE'}):
                    props.treatmentData.some(t=>getCode('TreatmentDetailStatus', t.treatmentDetailStatusCode)=='TDS03'||getCode('TreatmentDetailStatus', t.treatmentDetailStatusCode)=='TDS06')?
                        formatMessage({id:'IN_PROGRESS'}):
                        formatMessage({id:'UPCOMING'})
                }
                classNum={props.classNum}
                modifiedHistory={props.planModifiedRecord}
                caseId={props.caseId}
                planId={props.planId}

            />}

            <div className="infoBar">
                <div className="info">
                    <div className="name" onClick={()=>setSetting({...setting, showPlanInfoDrawer: true})}>
                        {props.title}
                    </div>
                    <div className="startDate">
                        {`${formatMessage({id:'PURCHASE_DATE'})}: ${props.startDate}`}
                    </div>
                </div>
                <div className="control">
                    <span>|</span>
                    <span className='finish'>
                        <span className="text">{formatMessage({id:'FINISH'})}</span>
                        <Switch
                            checked={isFinish}
                            onChange={checkIfAvailable(permission, 'FinishPackage')?e=>{setSetting({...setting, showToggleFinishModal: true, nextFinishStatus: e})} : ()=>{}}
                        />
                    </span>
                </div>
            </div>
            <div className="treatmentContainers">
                {treatmentGroupBy4}
            </div>
        </Wrapper>
    )
}

export default Plan;

const NewTreatmentWrapper = styled.div`
    border: 1px solid #2E2E2E;
    border-radius: 0.3rem;
    width: 100%;
    aspect-ratio: 5 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .anticon {
        margin: auto;
        font-size: 36px;
    }
`;

const NewTreatment: React.FC<{onClick: React.MouseEventHandler}> = ({onClick}) => {

    return (
        <NewTreatmentWrapper onClick={onClick}>
            <PlusOutlined/>
        </NewTreatmentWrapper>
    )
}
