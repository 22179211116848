// tslint:disable quotemark no-implicit-dependencies
import React from 'react';
import cx from 'classnames';
import { Table, Empty, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { TableProps } from 'antd/es/table';
import styled from 'styled-components';
import Button from 'components/button/button';
import theme from '../../theme';

const StyledTable: any = styled(Table)`
    &.ant-table-wrapper {

        &.ant-table-pagination--hide .ant-table-pagination {
            display: none;
        }

        .ant-table thead tr th {
            color: black;
            background-color: #E3E3E3;
            font-weight: bold;
        }

        .ant-checkbox-inner {
            height: 20px;
            width: 20px;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner,
        .ant-checkbox-checked .ant-checkbox-inner {
            background: #2E63A3;
            border-color: ${p => p.theme.secondary};
        }

        .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(45deg) scale(1) translate(-46%, -68%);
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            height: 2px;
            background-color: #fff;
        }

        &.checkbox__select-all---hide th .ant-table-selection {
            visibility: hidden;
        }
    }
    &.col_alt_color {
        thead > tr > th {
            background: #fff;
        }
        tbody > tr > td {
            background: #fff;
            color: #E3E3E3;
        }
        div {
            background: transparent;
        }
        .odd_col {
            background: #eff7f7 0% 0% no-repeat padding-box;
        }
    }
    .ant-table-footer {
        background-color: #FFFFFF;
    }
`;

type Props<T> = TableProps<T> & {
    editable?: boolean;
    isRowDeletable?: (row: T) => boolean;
    onAddRowClick?: (param?: any) => any;
    onDeleteRowClick?: (param?: any) => any;
    isCanEdit?: any;
    limitScroll?: string | boolean;
};

export default function TableComponent<T = any>({
    editable = false,
    columns = [],
    onAddRowClick,
    isRowDeletable = () => true,
    onDeleteRowClick,
    isCanEdit = '',
    limitScroll = 'calc(80vh - 80px - 55px)',
    ...props
}: Props<T>) {
    const _columns = React.useMemo(() => {
        let canEdit: boolean = true;
        if (!editable) {
            return columns;
        }
        if (isCanEdit !== '') {
            canEdit = isCanEdit;
        }

        const handleRowDelete = (key: React.Key) => () => {
            if (onDeleteRowClick) {
                onDeleteRowClick(key);
            }
        };

        return [
            {
                title: (
                    <Button
                        size="small"
                        style={{
                            background: theme.primary,
                            border: '1px solid #096576',
                            color: 'white',
                            minWidth: '26px',
                        }}
                        shape="circle"
                        onClick={onAddRowClick}
                        disabled={canEdit === false}
                    >
                        +
                    </Button>
                ),
                width: 50,
                render: (_: any, r: any, idx: number) => (
                    <Tooltip
                        {...(!isRowDeletable(r) ? { visible: false } : {})}
                        placement="topLeft"
                        title={'刪除'}
                    >
                        <Button
                            data-id={r.id}
                            data-index={idx}
                            disabled={!isRowDeletable(r) || canEdit === false}
                            className={cx({ 'is--disabled': !isRowDeletable(r) })}
                            style={{
                                minWidth: 26,
                                ...(isRowDeletable(r) ? { background: theme['cyan-2'] } : {}),
                            }}
                            size="small"
                            shape="circle"
                            onClick={handleRowDelete(r.id)}
                        >
                            <CloseOutlined className="text--white" />
                        </Button>
                    </Tooltip>
                ),
            },
            ...columns,
        ];
    }, [columns, editable]); // eslint-disable-line

    return (
        <StyledTable<T>
            columns={_columns}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                <span className="d-block">尚無資料</span>
                            </span>
                        }
                    />
                ),
            }}
            scroll={limitScroll?{y: limitScroll}:undefined}
            {...props}
        />
    );
}

TableComponent.Column = Table.Column;
TableComponent.ColumnGroup = Table.ColumnGroup;
