/** @jsx jsx */
// tslint:disable no-implicit-dependencies
import { jsx } from '@emotion/core';
import React from 'react';
import { Modal, Row } from 'antd';
import { ModalFuncProps } from 'antd/es/modal';
import {
    ExclamationCircleFilled,
    CheckCircleFilled,
    WarningFilled,
    DeleteOutlined,
} from '@ant-design/icons';
import Button from 'components/button/button';

// tslint:disable: quotemark

let modal: {
    destroy: () => void;
    update: (newConfig: ModalFuncProps) => void;
};

type Props = ModalFuncProps & {
    content: string | React.ReactNode;
    top?: string | number;
    duration?: number;
    icon?: React.ReactNode;
};

export function success({
    content,
    top = '35%',
    duration = 1000,
    icon = <CheckCircleFilled className="mb-20 d-block" />,
    ...props
}: Props) {
    modal = Modal.info({
        ...props,
        width: 349,
        style: {
            top,
            borderRadius: 12,
        },
        maskClosable: true,
        className: 'modal__info is--success',
        content: (
            <div className="text--center">
                {icon}
                {content}
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
    });

    setTimeout(() => modal.destroy(), duration);
}

export function error({ content, top = '35%', duration = 1000, ...props }: Props) {
    modal = Modal.info({
        ...props,
        width: 349,
        style: {
            top,
        },
        maskClosable: true,
        className: 'modal__info is--error',
        content: (
            <div className="text--center">
                <ExclamationCircleFilled
                    className="mb-20 d-block"
                    style={{
                        color: 'red',
                    }}
                />
                {content}
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
    });

    setTimeout(() => modal.destroy(), duration);
}

export function warning({
    content,
    onOk,
    onCancel,
    top = '25%',
    icon = <WarningFilled className="mb-20 d-block" />,
    ...props
}: Props) {
    const handleOk = () => {
        if (onOk) {
            onOk();
        }

        if (modal) {
            modal.destroy();
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        if (modal) {
            modal.destroy();
        }
    };

    modal = Modal.warn({
        width: 388,
        style: { top },
        className: 'modal__info is--warning',
        content: (
            <div className="text--center">
                {icon}
                {content}
                <Button
                    block={true}
                    className="is--primary mt-32 mb-15"
                    onClick={handleOk}
                >
                    確定
                </Button>
                <Button
                    type="link"
                    block={true}
                    onClick={handleCancel}
                    style={{ color: '#808996' }}
                >
                    取消
                </Button>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });
}

export function confirmToDelete({
    content,
    top = '35%',
    icon = <DeleteOutlined className="d-block mb-20" style={{ color: '#E85050' }} />,
    onOk,
    onCancel,
    ...props
}: Props) {
    const handleClose = () => {
        if (onCancel) {
            onCancel();
        }
        modal.destroy();
    };
    const handleOk = () => {
        if (onOk) {
            onOk();
        }
        modal.destroy();
    };
    modal = Modal.info({
        width: 360,
        style: {
            top,
            borderRadius: 12,
        },
        className: 'modal__info is--success',
        content: (
            <div>
                <div className="text--center mb-20">
                    {icon}
                    {content}
                </div>
                <Row className="mt-10">
                    <Button
                        className="d-inline-block width--full mr-20 ml-20"
                        style={{
                            backgroundColor: '#E85050',
                            border: '1px solid #AF0F0F',
                            color: 'white',
                        }}
                        onClick={handleOk}
                    >
                        確認刪除
                    </Button>
                </Row>
                <Row className="mt-10">
                    <Button
                        type="link"
                        block={true}
                        style={{ color: '#808996' }}
                        className="d-inline-block width--full mt-3 mr-20 ml-20"
                        onClick={handleClose}
                    >
                        取消
                    </Button>
                </Row>
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
        ...props,
    });

    // setTimeout(() => modal.destroy(), duration);
}

export function warn({ content, top = '25%', duration = 3000, ...props }: Props) {
    modal = Modal.warning({
        ...props,
        width: 388,
        style: {
            top,
        },
        className: 'modal__info is--warning',
        content: (
            <div className="text--center">
                <WarningFilled className="mb-20 d-block" />
                {content}
            </div>
        ),
        icon: false,
        okButtonProps: {
            style: { display: 'none' },
        },
    });
    setTimeout(() => modal.destroy(), duration);
}
