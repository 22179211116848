import React, { useState } from 'react';
import { Dropdown, Menu, DatePicker } from 'antd';
import { Form, Input } from 'components/form';
import Button from 'components/button/button';
import { SearchOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FormInstance } from 'antd/lib/form';

interface Props {
    onSubmit: (form: FormInstance) => void;
    className?: string;
    searchBy: string;
    dateWording?: string;
}

const Filter: React.FC<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY';

    const [toggleDropdown, setToggleDropdown] = useState(false);

    const options = (
        <MenuPadding>
            <div>
                <Form layout='vertical' form={form}>
                    <Form.Item
                        label={formatMessage({id: 'SEARCH'})}
                        name='search'
                    >
                        <Input 
                            placeholder={`${formatMessage({id:'SEARCH_BY'})} ${props.searchBy}`} 
                            // onChange={e=>props.setKeyword(e.target.value)}
                            style={{backgroundColor: '#F3F3F3'}}
                        />
                    </Form.Item>
                    <Form.Item
                        label={props.dateWording ?? formatMessage({id: 'DATE'})}
                        name='date'
                    >
                        <RangePicker style={{backgroundColor: '#F3F3F3'}} format={dateFormat}/>
                    </Form.Item>

                    <div style={{display: 'flex', 'justifyContent': 'flex-end'}}>
                        <Button type='link' style={{color: 'black'}} onClick={()=>setToggleDropdown(!toggleDropdown)}>
                            {formatMessage({id: 'CANCEL'})}
                        </Button>
                        <Button type='link' onClick={()=>{props.onSubmit(form);setToggleDropdown(!toggleDropdown);}}>
                            {formatMessage({id: 'SEARCH'})}
                        </Button>
                    </div>
                </Form>
            </div>
        </MenuPadding>
    )
    
    return (
        <Dropdown 
            overlay={options}
            placement='bottomRight'
            trigger={['click']}
            visible={toggleDropdown}
            onVisibleChange={visible=>setToggleDropdown(visible)}
            className={props.className}
        >
            <Button className='rect' icon={<SearchOutlined/>} onClick={()=>setToggleDropdown(!toggleDropdown)} />
        </Dropdown>
    );
}

const MenuPadding = styled(Menu)`
    .ant-dropdown-menu {
        padding: 5px;
    }
`

export default Filter;
