import React from 'react';
import { useIntl } from 'react-intl';
import ProfileCard from 'components/profileCard/profileCard';
import Label from 'components/label/label';
import { Row, Col } from 'antd';
import { KEData } from '../type';
import { SquattingView, SingleLegStanceView } from '../TrainingItemComponents';
import BlockTable, { RowData } from 'components/blockTable/blockTable';
import { KRAdata } from 'api/record';
import {
    SitToStandViewKRA, WalkComfortablyViewKRA, WalkFastViewKRA,
    StairAscentViewKRA, StairDescentViewKRA
} from '../TrainingItemComponents/KRA';

interface Props {
    setDrawerSetting: React.Dispatch<React.SetStateAction<{
        showDrawer: boolean;
        tabKey: string;
    }>>;
    data?: KEData;
    suspend: boolean;
    isPreliminary: boolean;
    kraData: KRAdata[];
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    }
}

const KeeogoPage: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();

    const colHeader = [
        formatMessage({id:'PRIORITIZED_DEFICITS'}),
        formatMessage({id:'DEFICITS_RESOLVED'})
    ];

    const rowData: RowData[] = (props.data?.reviewAndPrioritizeBaselineData || []).map((d, i)=>({
        rowHeader: (i+1).toString(),
        [formatMessage({id:'PRIORITIZED_DEFICITS'})]: (
            <div style={{width:'70%'}}>
                <div className='text-20 text--bold'>
                    {d.prioritizedDeficitsCode}
                </div>
                <p style={{overflowWrap: 'break-word', maxWidth: '40vw'}}>
                    {d.prioritizedDeficitsDetails}
                </p>
            </div>
        ),
        [formatMessage({id:'DEFICITS_RESOLVED'})]: (
            <div style={{width:'70%'}}>
                <div className='text-20 text--bold'>
                    {d.deficitsResolvedCode}
                </div>
                <p style={{overflowWrap: 'break-word', maxWidth: '40vw'}}>
                    {d.deficitsResolvedNotes}
                </p>
            </div>
        )
    }));
        
        

    return (
        <div>
            {/* <ProfileCard editable={!props.suspend} title={formatMessage({id:'USER_FITTING'})} onEdit={()=>props.setDrawerSetting({showDrawer: true, tabKey: 'UserFitting'})}>
                <Row gutter={24} className='mb-30'>
                    <Col span={6}>
                        <Label name={formatMessage({id:'WAIST_SIZE'})} text={(props.data?.keUfiUserWaistSizeInch || '--') + 'inch / ' + (props.data?.keUfiUserWaistSizeCm || '--') + 'cm'} />
                    </Col>
                    <Col span={6}>
                        <Label name={formatMessage({id:'KEEOGO_BELT_SIZE'})} text={props.data?.keUfiKeeogoBeltSize || '--'} />
                    </Col>
                    <Col span={6}>
                        <Label
                            name={formatMessage({id:'CONTACT_AREA_SIZE'})}
                            text={
                                (props.data?.keUfiKeeogoContactAreaSizePropriocption || '--') + ' / ' +
                                (props.data?.keUfiKeeogoContactAreaSizesLowerThigh || '--') + ' / ' +
                                (props.data?.keUfiKeeogoContactAreaSizesTibia || '--')
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Label
                            name={formatMessage({id:'FEMORAL_SHAFT_LENGTH'})}
                            text=''
                        />
                        <div className='text-16 text--bold'><span style={{color: '#0083C1'}}>{formatMessage({id:'LT'})} </span> {props.data?.keUfiKeeogoFemoralShaftLengthLt ? '#' + props.data?.keUfiKeeogoFemoralShaftLengthLt : '--'}</div>
                        <div className='text-16 text--bold'><span style={{color: '#0083C1'}}>{formatMessage({id:'RT'})} </span> {props.data?.keUfiKeeogoFemoralShaftLengthRt ? '#' + props.data?.keUfiKeeogoFemoralShaftLengthRt : '--'}</div>
                    </Col>
                </Row>
            </ProfileCard> */}

            <ProfileCard editable={!props.suspend} title={formatMessage({id:'MOVEMENT_ANALYSIS_WITH'})} onEdit={()=>props.setDrawerSetting({showDrawer: true, tabKey: 'KEMovementAnalysis'})}>
                <Row gutter={24} className='mt-20 mb-24'>
                    <Col span={8}>
                        <SitToStandViewKRA
                            data={{
                                observation: props.data?.keMakSitStandObservation ?? '--'
                            }}
                            kraData={props.kraData.filter(d=>d.typeCode === 'KEN01')}
                            classData={props.classData}
                        />
                    </Col>
                    <Col span={8}>
                        <WalkComfortablyViewKRA
                            data={{
                                fac: props.data?.keMakWalkComfortablyFacScoreCode ?? '--',
                                affected: props.data?.keMakWalkComfortablyAffectedSideCode ?? [],
                                affectedOther: props.data?.keMakWalkComfortablyAffectedSideOther ?? '--',
                                unaffected: props.data?.keMakWalkComfortablyUnaffectedSideCode ?? '--',
                                unaffectedOther: props.data?.keMakWalkComfortablyUnaffectedSideOther ?? '--'
                            }}
                            kraData={props.kraData.filter(d=>d.typeCode === 'KEN02')}
                            classData={props.classData}
                        />
                    </Col>
                    <Col span={8}>
                        <WalkFastViewKRA
                            data={{
                                fac: props.data?.keMakWalkFastFacScoreCode ?? '--',
                                affected: props.data?.keMakWalkFastAffectedSideCode ?? [],
                                affectedOther: props.data?.keMakWalkFastAffectedSideOther ?? '--',
                                unaffected: props.data?.keMakWalkFastUnaffectedSideCode ?? '--',
                                unaffectedOther: props.data?.keMakWalkFastUnaffectedSideOther ?? '--'
                            }}
                            kraData={props.kraData.filter(d=>d.typeCode === 'KEN03')}
                            classData={props.classData}
                        />
                    </Col>
                </Row>
                <Row gutter={24} className='mb-24'>
                    <Col span={8}>
                        <StairAscentViewKRA
                            data={{
                                stairs: props.data?.keMakStairAscentStairs?.toString() ?? '--',
                                observation: props.data?.keMakStairAscentObservation ?? '--'
                            }}
                            kraData={props.kraData.filter(d=>d.typeCode === 'KEN04')}
                            classData={props.classData}
                        />
                    </Col>
                    <Col span={8}>
                        <StairDescentViewKRA
                            data={{
                                stairs: props.data?.keMakStairDescentStairs?.toString() ?? '--',
                                observation: props.data?.keMakStairDescentObservation ?? '--'
                            }}
                            kraData={props.kraData.filter(d=>d.typeCode === 'KEN05')}
                            classData={props.classData}
                        />
                    </Col>
                    <Col span={8}>
                        <SquattingView 
                            data={{
                                assistDevice: props.data?.keMakOtherSquattingAssistiveDeviceCode ?? '',
                                assistDeviceOther: props.data?.keMakOtherSquattingAssistiveDeviceOther ?? '',
                                affected: props.data?.keMakOtherSquattingAffectdSide ?? [],
                                affectedOther: props.data?.keMakOtherSquattingAffectdSideOther ?? ''
                            }} 
                        />
                    </Col>
                </Row>
                <Row gutter={24} className='mb-50'>
                    <Col span={8}>
                        <SingleLegStanceView 
                            data={{
                                assistDevice: props.data?.keMakOtherSingleLegStanceAssistiveDeviceCode ?? '',
                                assistDeviceOther: props.data?.keMakOtherSingleLegStanceAssistiveDeviceOther ?? '',
                                affectedLt: props.data?.keMakOtherLtSingleLegStanceAffectdSide ?? [],
                                affectedLtOther: props.data?.keMakOtherLtSingleLegStanceAffectdSideOther ?? '',
                                affectedRt: props.data?.keMakOtherRtSingleLegStanceAffectdSide ?? [],
                                affectedRtOther: props.data?.keMakOtherRtSingleLegStanceAffectdSideOther ?? ''
                            }} 
                        />
                    </Col>
                </Row>

                {props.isPreliminary ?
                    <div>
                        <Label name={formatMessage({id:'REVIEW_AND_PRIORITIZE'})} text=''/>
                        <BlockTable colHeader={colHeader} data={rowData} rowHeaderWidth='5%' colHeaderWrapper={<div style={{width:'70%',borderBottom:'2px solid #909090'}}></div>}/>
                    </div> : <div></div>
                }

            </ProfileCard>
        </div>
    )
}

export default KeeogoPage;
