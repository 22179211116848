import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Select } from 'components/form';
import Button from 'components/button/button';
import DrawerContent from './DeviceDrawer';
import EditDrawer from './EditDrawer';
import DeviceCard from './DeviceCard';
import QRcodeModal from './QRcodeModal';
import { icon_qrcode, icon_edit_white, icon_menu } from 'components/image';
import { Row, Col, Modal } from 'antd';
import { 
    getChannelList, getDeviceList, getQRcodeToken, removeDevice,
    Channel, Device, OTPtoken
} from 'api/venue';
import { getOrgList } from 'api/organizationMgmtApi'; 

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .functions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .Title {
            color: #2E2E2E;
            font-size: 18px;
            font-weight: 500;
        }
    }

    .listArea {
        display: flex;

        .devices {
            background-color: #FFFFFF;
            padding: 20px;
            width: 100%;

            .venueName {
                font-size: 18px;
                font-weight: bold;
                color: #2E2E2E;
                margin-bottom: 15px;
            }
        }
    }
    
`;

const VenueList: React.FC = () => {

    const { formatMessage } = useIntl();
    const [showMenu, setShowMenu] = useState(false);
    const [showQRcodeModal, setShowQRcodeModal] = useState(false);
    const [showEditDrawer, setShowEditDrawer] = useState(false);

    const [orgList, setOrgList] = useState<any[]>([])
    const [selectedOrg, setSelectedOrg] = useState(0);
    const [channelList, setChannelList] = useState<Channel[]>([]);
    const [selectedChannel, setSelectedChannel] = useState(0);
    const [deviceList, setDeviceList] = useState<Device[]>([]);
    const [OTPtoken, setOTPtoken] = useState<OTPtoken>();

    useEffect(()=>{
        getOrgList(
            //no args
        ).then((res: any)=>{
            setOrgList(res.data.data);
            setSelectedOrg(res.data.data.find((d: any)=>d.name===localStorage.getItem('managingOrganizationName'))?.id || 0);
        }).catch((error: any)=>{
            console.error(error);
            throw(error);
        });
    }, []);

    useEffect(()=>{
        fetchChannel();
    }, [selectedOrg]);

    useEffect(()=>{
        fetchDevice();
    }, [selectedChannel]);

    const fetchChannel = () => {
        getChannelList(
            selectedOrg
        ).then(res=>{
            setChannelList(res.data.data);
            setSelectedChannel(res.data.data[0]?.id || 0);
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }


    const fetchDevice = () => {
        getDeviceList(
            selectedChannel
        ).then(res=>{
            setDeviceList(res.data.data);
        }).catch(error=>{
            setDeviceList([]);
            console.error(error);
            throw(error);
        });
    }

    const fetchQRcodeToken = async () => {
        await getQRcodeToken(
            // no args
        ).then(res=>{
            setOTPtoken(res.data.data);
        }).catch(error=>{
            console.error(error);
            Modal.error({
                title: formatMessage({id:'ERROR'}),
                maskClosable: true,
                content: (
                    <div style={{ wordBreak:'break-word', whiteSpace: 'break-spaces' , textAlign:'center' }}>
                        {
                            typeof (error?.data?.detail)  === 'string'
                            ?
                            error.data.detail
                            :
                            (error?.data?.detail) instanceof Array
                            ?
                            error.data.detail.join('\n')
                            :
                            null
                        }
                    </div>
                )
            });
        });
    }

    const handleShowQRcode = () => {
        if(selectedChannel===0){
            return ;
        }

        if((parseInt(OTPtoken?.expiredAt || '0') ?? 0) <= Date.now()){
            fetchQRcodeToken();
        }
        setShowQRcodeModal(true);
    }

    const handleRemoveDevice = (deviceId: number) => {
        removeDevice(
            deviceId
        ).then(res=>{
            Modal.success({
                maskClosable:true,
                title:'Success',
                onCancel: fetchDevice
            });
        }).catch((error: any)=>{
            console.error(error)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = error?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            })
        });
    }

    const orgOptions = orgList.map(d=>(
        <Select.Option value={d.id}>{d.name}</Select.Option>
    ));

    const channelOptions = channelList.map(d=>({
        name: d.channelName,
        value: d.id
    }));

    const deviceOptions = deviceList.map(d=>(
        <Col>
            <DeviceCard 
                id={d.id} 
                name={d.deviceName} 
                channelId={d.channelId} 
                channelName={channelList.find(c=>c.id===d.channelId)?.channelName ?? ''} 
                onRemove={()=>handleRemoveDevice(d.id)} 
            />
        </Col>
    ))

    return (
        <Wrapper className='main'>
            {showQRcodeModal && 
            <QRcodeModal 
                title={channelList.find(d=>d.id===selectedChannel)?.channelName || '--'}
                payload={{
                    channelId: selectedChannel,
                    channelName: channelList.find(d=>d.id===selectedChannel)?.channelName || '--',
                    token: OTPtoken?.otpToken || ''
                }}
                onClose={()=>setShowQRcodeModal(false)}
            />}
            {showEditDrawer && 
            <EditDrawer 
                currentOrgId={selectedOrg}
                currentOrgName={orgList.find(d=>d.id===selectedOrg)?.name || '--'}
                channelList={channelList} 
                onClose={()=>setShowEditDrawer(false)}
                refetch={fetchChannel}
            />}

            <React.Fragment>
                <div className='header'>
                    <div>
                        <b><strong>{formatMessage({id:'VENUE_CHANNEL_SETTINGS'})}</strong></b>
                    </div>
                    <div>
                        <Select bordered={false} style={{width: '240px'}} value={selectedOrg} onChange={val=>setSelectedOrg(val as number)}>
                            {orgOptions}
                        </Select>
                    </div>
                </div>
                <div className='functions'>
                    <div className='d-flex v--center'>
                        <Button
                            className='mr-10' 
                            type='link'
                            icon={<img src={icon_menu} />}
                            onClick={()=>setShowMenu(!showMenu)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: 0,
                                paddingLeft: 0
                            }}
                        />
                        <div className='Title'>{formatMessage({id:'DEVICE_LIST'})}</div>
                    </div>
                    <div className='d-flex v--center'>
                        <Button 
                            icon={<img src={icon_qrcode}/>}
                            className='rect mr-10'
                            onClick={handleShowQRcode}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '5px'
                            }}
                        />
                        <Button 
                            icon={<img src={icon_edit_white}/>}
                            className='rect'
                            onClick={()=>setShowEditDrawer(true)}
                            style={{
                                backgroundColor: '#47A6D8',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '5px'
                            }}
                        />
                    </div>
                </div>
                <div className='listArea'>
                    <DrawerContent 
                        show={showMenu}
                        options={channelOptions} 
                        currentValue={selectedChannel} 
                        onChange={val=>setSelectedChannel(val)}
                    />
                    <div className='devices'>
                        <div className='venueName'>{channelList.find(d=>d.id===selectedChannel)?.channelName || '--'}</div>
                        <Row gutter={24}>
                            {deviceOptions}
                        </Row>
                    </div>
                </div>
                
            </React.Fragment>
        </Wrapper>
    )
}

export default VenueList;
