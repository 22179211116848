import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<Props>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: ${props => props.width ?? 'auto'};

    .label_title {
        color: #909090;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: ${props => props.bgc ? '5px' : ''};
    }

    .label_text {
        color: black;
        font-size: 16px;
        font-weight: bold;
        background-color: ${props => props.bgc};
        padding: 2px ${props => props.bgc ? '10px' : ''};
        width: ${props => props.width ?? 'auto'};
        text-align: ${props => props.bgc ? 'center' : undefined};
    }
`;

interface Props {
    name: string | JSX.Element;
    text?: string | JSX.Element | JSX.Element[];
    className?: string;
    bgc?: string;
    width?: string;
}

const Label: React.FC<Props> = (props: Props) => {

    const { name, text = '--', className } = props;

    return (
        <Wrapper {...props}>
            <div className="label_title">{name}</div>
            <div className="label_text">{text}</div>
        </Wrapper>
    )
}

export default Label;
