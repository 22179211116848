import React, { useContext } from 'react';
import { GlobalContext } from '../index';
import { PermissionNode } from 'service/permission';

const usePermission = (propKey?: string) => {
    const { permissionCtx } = useContext(GlobalContext);

    const iterate = (obj: Object, key?: string): PermissionNode[] => {
        let result: PermissionNode = {
            name: '',
            available: false
        };

        if(key){
            findKey: {
                for(let prop in obj){
                    if(prop === key){
                        if(obj[prop]){// true or object
                            result.name = prop;
                            result.available = true;
                            if(typeof obj[prop] === 'object'){
                                // let nodes = {};
                                // iterate(obj[prop]).forEach((d: PermissionNode)=>{
                                //     nodes[d.name] = d;
                                // });
                                // result.children = Object.keys(nodes).length > 0 ? nodes : undefined;
                                result.children = iterate(obj[prop]);
                            }
                            
                        }
    
                        if(obj[prop] === false){
                            result.name = prop;
                            result.available = false;
                        }
                        break findKey;
                    }
                }
                for(let prop in obj){
                    if(typeof obj[prop] === 'object' && result.name===''){
                        [result] = iterate(obj[prop], key);
                    }
                }
            }
        }
        else{
            let nodes: PermissionNode[] = [];
            for(let prop in obj){
                if(obj[prop]){// true or object
                    nodes.push({
                        name: prop,
                        available: true
                    });
                    if(typeof obj[prop] === 'object'){
                        // let children = {};
                        // iterate(obj[prop]).forEach((d: PermissionNode)=>{
                        //     children[d.name] = d;
                        // });
                        // nodes[nodes.length-1].children = Object.keys(children).length > 0 ? children : undefined;
                        nodes[nodes.length-1].children = iterate(obj[prop]);
                    }
                }

                if(obj[prop] === false){
                    nodes.push({
                        name: prop,
                        available: false
                    });
                }
            }
            return nodes;
        }
        
        return [result];
    }

    return iterate(permissionCtx, propKey);
}

export default usePermission;
