import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Divider, Radio, Row, Col } from 'antd';
import { GlobalContext } from '../../../../../index';
import { Form } from 'components/form';
import ActiveRangeButton, { ActiveRangeValue } from '../components/ActiveRangeButton';
import { ActiveRangeMotionData } from '../type';
import { FormInstance } from 'antd/lib/form';
import { FormTitle } from './EvaluationDrawer';

const StyledRadio: any = styled(Radio.Button)`
    background-color: #F3F3F3;
    color: #000000;
    border: none !important;
    font-weight: 500;
    padding: 0 15px;

    &.ant-radio-button-wrapper-checked {
        background-color: #5DD197!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

interface Props {
    data: ActiveRangeMotionData;
    form: FormInstance;
}

const ActiveRange: React.FC<Props> = ({ data, form }: Props) => {
    
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const { formatMessage } = useIntl();

    useEffect(()=>{
        form.setFieldsValue({
            armUlLtShoulderFlex: {code: data.armUlLtShoulderFlexCode, limit: data.armUlLtShoulderFlexLimit},
            armUlLtShoulderExt: {code: data.armUlLtShoulderExtCode, limit: data.armUlLtShoulderExtLimit},
            armUlLtShoulderAbd: {code: data.armUlLtShoulderAbdCode, limit: data.armUlLtShoulderAbdLimit},
            armUlLtShoulderAdd: {code: data.armUlLtShoulderAddCode, limit: data.armUlLtShoulderAddLimit},
            armUlLtShoulderEr: {code: data.armUlLtShoulderErCode, limit: data.armUlLtShoulderErLimit},
            armUlLtShoulderIr: {code: data.armUlLtShoulderIrCode, limit: data.armUlLtShoulderIrLimit},
            armUlRtShoulderFlex: {code: data.armUlRtShoulderFlexCode, limit: data.armUlRtShoulderFlexLimit},
            armUlRtShoulderExt: {code: data.armUlRtShoulderExtCode, limit: data.armUlRtShoulderExtLimit},
            armUlRtShoulderAbd: {code: data.armUlRtShoulderAbdCode, limit: data.armUlRtShoulderAbdLimit},
            armUlRtShoulderAdd: {code: data.armUlRtShoulderAddCode, limit: data.armUlRtShoulderAddLimit},
            armUlRtShoulderEr: {code: data.armUlRtShoulderErCode, limit: data.armUlRtShoulderErLimit},
            armUlRtShoulderIr: {code: data.armUlRtShoulderIrCode, limit: data.armUlRtShoulderIrLimit},
            armUlLtElbowFlex: {code: data.armUlLtElbowFlexCode, limit: data.armUlLtElbowFlexLimit},
            armUlLtElbowExt: {code: data.armUlLtElbowExtCode, limit: data.armUlLtElbowExtLimit},
            armUlLtElbowPronation: {code: data.armUlLtElbowPronationCode, limit: data.armUlLtElbowPronationLimit},
            armUlLtElbowSupination: {code: data.armUlLtElbowSupinationCode, limit: data.armUlLtElbowSupinationLimit},
            armUlRtElbowFlex: {code: data.armUlRtElbowFlexCode, limit: data.armUlRtElbowFlexLimit},
            armUlRtElbowExt: {code: data.armUlRtElbowExtCode, limit: data.armUlRtElbowExtLimit},
            armUlRtElbowPronation: {code: data.armUlRtElbowPronationCode, limit: data.armUlRtElbowPronationLimit},
            armUlRtElbowSupination: {code: data.armUlRtElbowSupinationCode, limit: data.armUlRtElbowSupinationLimit},
            armLlLtHipFlex: {code: data.armLlLtHipFlexCode, limit: data.armLlLtHipFlexLimit},
            armLlLtHipExt: {code: data.armLlLtHipExtCode, limit: data.armLlLtHipExtLimit},
            armLlLtHipAbd: {code: data.armLlLtHipAbdCode, limit: data.armLlLtHipAbdLimit},
            armLlLtHipAdd: {code: data.armLlLtHipAddCode, limit: data.armLlLtHipAddLimit},
            armLlLtHipEr: {code: data.armLlLtHipErCode, limit: data.armLlLtHipErLimit},
            armLlLtHipIr: {code: data.armLlLtHipIrCode, limit: data.armLlLtHipIrLimit},
            armLlRtHipFlex: {code: data.armLlRtHipFlexCode, limit: data.armLlRtHipFlexLimit},
            armLlRtHipExt: {code: data.armLlRtHipExtCode, limit: data.armLlRtHipExtLimit},
            armLlRtHipAbd: {code: data.armLlRtHipAbdCode, limit: data.armLlRtHipAbdLimit},
            armLlRtHipAdd: {code: data.armLlRtHipAddCode, limit: data.armLlRtHipAddLimit},
            armLlRtHipEr: {code: data.armLlRtHipErCode, limit: data.armLlRtHipErLimit},
            armLlRtHipIr: {code: data.armLlRtHipIrCode, limit: data.armLlRtHipIrLimit},
            armLlLtKneeFlex: {code: data.armLlLtKneeFlexCode, limit: data.armLlLtKneeFlexLimit},
            armLlLtKneeExt: {code: data.armLlLtKneeExtCode, limit: data.armLlLtKneeExtLimit},
            armLlRtKneeFlex: {code: data.armLlRtKneeFlexCode, limit: data.armLlRtKneeFlexLimit},
            armLlRtKneeExt: {code: data.armLlRtKneeExtCode, limit: data.armLlRtKneeExtLimit},
            armLlLtAnkleDf: {code: data.armLlLtAnkleDfCode, limit: data.armLlLtAnkleDfLimit},
            armLlLtAnklePf: {code: data.armLlLtAnklePfCode, limit: data.armLlLtAnklePfLimit},
            armLlLtAnkleInversion: {code: data.armLlLtAnkleInversionCode, limit: data.armLlLtAnkleInversionLimit},
            armLlLtAnkleEversion: {code: data.armLlLtAnkleEversionCode, limit: data.armLlLtAnkleEversionLimit},
            armLlRtAnkleDf: {code: data.armLlRtAnkleDfCode, limit: data.armLlRtAnkleDfLimit},
            armLlRtAnklePf: {code: data.armLlRtAnklePfCode, limit: data.armLlRtAnklePfLimit},
            armLlRtAnkleInversion: {code: data.armLlRtAnkleInversionCode, limit: data.armLlRtAnkleInversionLimit},
            armLlRtAnkleEversion: {code: data.armLlRtAnkleEversionCode, limit: data.armLlRtAnkleEversionLimit}
        });
    }, []);

    const handleButtonChange = (val: ActiveRangeValue, formName: string) => {
        form.setFieldsValue({
            [formName+'Code']: val.code,
            [formName+'Limit']: val.limit
        });
    }

    const FingerGripping = getOptions('FingerGripping').map((d: any) => (
        <StyledRadio value={d.code}>{d.name}</StyledRadio>
    ));

    return (
        <div>
            <div className='text-24 text--bold'>{formatMessage({id:'ACTIVE_RANGE_OF_MOTION'})}</div>
            <div className='text-20 text--bold mb-15'>{formatMessage({id:'UPPER_LIMBS'})}</div>

            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9', width: '100%'}}>{formatMessage({id:'LT'})}</div>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9', width: '100%'}}>{formatMessage({id:'RT'})}</div>
                </Col>
            </Row>
            <Row gutter={24} style={{paddingTop: '15px', marginBottom: '10px'}}><Col><FormTitle>{formatMessage({id:'SHOULDER'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armUlLtShoulderFlex" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Flex' onChange={val=>handleButtonChange(val, 'armUlLtShoulderFlex')}/>
                    </Form.Item>
                    <Form.Item name="armUlLtShoulderExt" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Ext' onChange={val=>handleButtonChange(val, 'armUlLtShoulderExt')}/>
                    </Form.Item>
                    <Form.Item name="armUlLtShoulderAbd" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Abd' onChange={val=>handleButtonChange(val, 'armUlLtShoulderAbd')}/>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armUlRtShoulderFlex" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Flex' onChange={val=>handleButtonChange(val, 'armUlRtShoulderFlex')}/>
                    </Form.Item>
                    <Form.Item name="armUlRtShoulderExt" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Ext' onChange={val=>handleButtonChange(val, 'armUlRtShoulderExt')}/>
                    </Form.Item>
                    <Form.Item name="armUlRtShoulderAbd" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Abd' onChange={val=>handleButtonChange(val, 'armUlRtShoulderAbd')}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armUlLtShoulderAdd" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Add' onChange={val=>handleButtonChange(val, 'armUlLtShoulderAdd')}/>
                    </Form.Item>
                    <Form.Item name="armUlLtShoulderEr" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='ER' onChange={val=>handleButtonChange(val, 'armUlLtShoulderEr')}/>
                    </Form.Item>
                    <Form.Item name="armUlLtShoulderIr" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='IR' onChange={val=>handleButtonChange(val, 'armUlLtShoulderIr')}/>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armUlRtShoulderAdd" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Add' onChange={val=>handleButtonChange(val, 'armUlRtShoulderAdd')}/>
                    </Form.Item>
                    <Form.Item name="armUlRtShoulderEr" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='ER' onChange={val=>handleButtonChange(val, 'armUlRtShoulderEr')}/>
                    </Form.Item>
                    <Form.Item name="armUlRtShoulderIr" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='IR' onChange={val=>handleButtonChange(val, 'armUlRtShoulderIr')}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24} style={{borderTop: '1.5px solid #E9E9E9', paddingTop: '15px', marginBottom: '10px'}}><Col><FormTitle>{formatMessage({id:'ELBOW'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armUlLtElbowFlex" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Flex' onChange={val=>handleButtonChange(val, 'armUlLtElbowFlex')}/>
                    </Form.Item>
                    <Form.Item name="armUlLtElbowExt" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Ext' onChange={val=>handleButtonChange(val, 'armUlLtElbowExt')}/>
                    </Form.Item>
                    <Form.Item name="armUlLtElbowPronation" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Pronation' onChange={val=>handleButtonChange(val, 'armUlLtElbowPronation')}/>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armUlRtElbowFlex" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Flex' onChange={val=>handleButtonChange(val, 'armUlRtElbowFlex')}/>
                    </Form.Item>
                    <Form.Item name="armUlRtElbowExt" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Ext' onChange={val=>handleButtonChange(val, 'armUlRtElbowExt')}/>
                    </Form.Item>
                    <Form.Item name="armUlRtElbowPronation" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Pronation' onChange={val=>handleButtonChange(val, 'armUlRtElbowPronation')}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armUlLtElbowSupination" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Supination' onChange={val=>handleButtonChange(val, 'armUlLtElbowSupination')}/>
                    </Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armUlRtElbowSupination" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Supination' onChange={val=>handleButtonChange(val, 'armUlRtElbowSupination')}/>
                    </Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                </Col>
            </Row>

            <Row gutter={24} style={{borderTop: '1.5px solid #E9E9E9', paddingTop: '15px', marginBottom: '10px'}}><Col><FormTitle>{formatMessage({id:'FINGER'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item label={<span style={{height: '18px', fontSize: '16px'}}>{formatMessage({id:'GRIPPING'})}</span>} name="armUlLtFinerGrippingCode">
                        <Radio.Group buttonStyle="solid">{FingerGripping}</Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item label={<span style={{height: '18px'}}></span>} name="armUlRtFinerGrippingCode">
                        <Radio.Group buttonStyle="solid">{FingerGripping}</Radio.Group>
                    </Form.Item>
                </Col>
            </Row>


            <div className='text-20 text--bold mb-15'>{formatMessage({id:'LOWER_LIMBS'})}</div>


            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9', width: '100%'}}>{formatMessage({id:'LT'})}</div>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                <div style={{color: '#0083C1', fontSize: '20px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1.5px solid #E9E9E9', width: '100%'}}>{formatMessage({id:'RT'})}</div>
                </Col>
            </Row>
            <Row gutter={24} style={{borderTop: '1.5px solid #E9E9E9', paddingTop: '15px', marginBottom: '10px'}}><Col><FormTitle>{formatMessage({id:'HIP'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlLtHipFlex" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Flex' onChange={val=>handleButtonChange(val, 'armLlLtHipFlex')}/>
                    </Form.Item>

                    <Form.Item name="armLlLtHipExt" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Ext' onChange={val=>handleButtonChange(val, 'armLlLtHipExt')}/>
                    </Form.Item>
                    <Form.Item name="armLlLtHipAbd" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Abd' onChange={val=>handleButtonChange(val, 'armLlLtHipAbd')}/>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlRtHipFlex" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Flex' onChange={val=>handleButtonChange(val, 'armLlRtHipFlex')}/>
                    </Form.Item>
                    <Form.Item name="armLlRtHipExt" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Ext' onChange={val=>handleButtonChange(val, 'armLlRtHipExt')}/>
                    </Form.Item>
                    <Form.Item name="armLlRtHipAbd" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Abd' onChange={val=>handleButtonChange(val, 'armLlRtHipAbd')}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlLtHipAdd" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Add' onChange={val=>handleButtonChange(val, 'armLlLtHipAdd')}/>
                    </Form.Item>
                    <Form.Item name="armLlLtHipEr" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='ER' onChange={val=>handleButtonChange(val, 'armLlLtHipEr')}/>
                    </Form.Item>
                    <Form.Item name="armLlLtHipIr" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='IR' onChange={val=>handleButtonChange(val, 'armLlLtHipIr')}/>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlRtHipAdd" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Add' onChange={val=>handleButtonChange(val, 'armLlRtHipAdd')}/>
                    </Form.Item>
                    <Form.Item name="armLlRtHipEr" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='ER' onChange={val=>handleButtonChange(val, 'armLlRtHipEr')}/>
                    </Form.Item>
                    <Form.Item name="armLlRtHipIr" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='IR' onChange={val=>handleButtonChange(val, 'armLlRtHipIr')}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24} style={{borderTop: '1.5px solid #E9E9E9', paddingTop: '15px', marginBottom: '10px'}}><Col><FormTitle>{formatMessage({id:'KNEE'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlLtKneeFlex" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Flex' onChange={val=>handleButtonChange(val, 'armLlLtKneeFlex')}/>
                    </Form.Item>
                    <Form.Item name="armLlLtKneeExt" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Ext' onChange={val=>handleButtonChange(val, 'armLlLtKneeExt')}/>
                    </Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlRtKneeFlex" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Flex' onChange={val=>handleButtonChange(val, 'armLlRtKneeFlex')}/>
                    </Form.Item>
                    <Form.Item name="armLlRtKneeExt" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Ext' onChange={val=>handleButtonChange(val, 'armLlRtKneeExt')}/>
                    </Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                </Col>
            </Row>

            <Row gutter={24} style={{borderTop: '1.5px solid #E9E9E9', paddingTop: '15px', marginBottom: '10px'}}><Col><FormTitle>{formatMessage({id:'ANKLE'})}</FormTitle></Col></Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlLtAnkleDf" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='DF' onChange={val=>handleButtonChange(val, 'armLlLtAnkleDf')}/>
                    </Form.Item>
                    <Form.Item name="armLlLtAnklePf" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='PF' onChange={val=>handleButtonChange(val, 'armLlLtAnklePf')}/>
                    </Form.Item>
                    <Form.Item name="armLlLtAnkleInversion" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Inversion' onChange={val=>handleButtonChange(val, 'armLlLtAnkleInversion')}/>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlRtAnkleDf" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='DF' onChange={val=>handleButtonChange(val, 'armLlRtAnkleDf')}/>
                    </Form.Item>
                    <Form.Item name="armLlRtAnklePf" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='PF' onChange={val=>handleButtonChange(val, 'armLlRtAnklePf')}/>
                    </Form.Item>
                    <Form.Item name="armLlRtAnkleInversion" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Inversion' onChange={val=>handleButtonChange(val, 'armLlRtAnkleInversion')}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlLtAnkleEversion" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Eversion' onChange={val=>handleButtonChange(val, 'armLlLtAnkleEversion')}/>
                    </Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11} className='d-flex'>
                    <Form.Item name="armLlRtAnkleEversion" style={{width: '100%'}}>
                        <ActiveRangeButton width='90%' title='Eversion' onChange={val=>handleButtonChange(val, 'armLlRtAnkleEversion')}/>
                    </Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                    <Form.Item style={{width: '100%'}}><span></span></Form.Item>
                </Col>
            </Row>
            <Form.Item noStyle name='armUlLtShoulderFlexCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderFlexLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderExtCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderExtLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderAbdCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderAbdLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderAddCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderAddLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderErCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderErLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderIrCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtShoulderIrLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderFlexCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderFlexLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderExtCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderExtLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderAbdCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderAbdLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderAddCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderAddLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderErCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderErLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderIrCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtShoulderIrLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtElbowFlexCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtElbowFlexLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtElbowExtCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtElbowExtLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtElbowPronationCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtElbowPronationLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtElbowSupinationCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlLtElbowSupinationLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtElbowFlexCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtElbowFlexLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtElbowExtCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtElbowExtLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtElbowPronationCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtElbowPronationLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtElbowSupinationCode'><span></span></Form.Item>
            <Form.Item noStyle name='armUlRtElbowSupinationLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipFlexCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipFlexLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipExtCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipExtLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipAbdCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipAbdLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipAddCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipAddLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipErCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipErLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipIrCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtHipIrLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipFlexCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipFlexLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipExtCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipExtLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipAbdCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipAbdLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipAddCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipAddLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipErCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipErLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipIrCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtHipIrLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtKneeFlexCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtKneeFlexLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtKneeExtCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtKneeExtLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtKneeFlexCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtKneeFlexLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtKneeExtCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtKneeExtLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtAnkleDfCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtAnkleDfLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtAnklePfCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtAnklePfLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtAnkleInversionCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtAnkleInversionLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtAnkleEversionCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlLtAnkleEversionLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtAnkleDfCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtAnkleDfLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtAnklePfCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtAnklePfLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtAnkleInversionCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtAnkleInversionLimit'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtAnkleEversionCode'><span></span></Form.Item>
            <Form.Item noStyle name='armLlRtAnkleEversionLimit'><span></span></Form.Item>
        </div>
    );
};
export default ActiveRange;
