import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{width?: string, colSpacing?: boolean, rowHeaderVertical?: string, rowHeaderWidth?: string, colWidth?: string, titleUnderline?: boolean}>`
    background-color: #F3F3F3;
    width: 100%;
    padding: 20px;

    table {
        width: ${props => props.width ?? '100%'};
        border-spacing: ${props => props.colSpacing ? '10px' : '0 10px'};
        border-collapse: separate;

        thead {
            tr>td {
                color: #0083C1;
                font-weight: bold;

                &:nth-child(2) {
                    padding-left: ${props => props.colSpacing ? '0' : '20px'};
                }
            }
        }

        tbody {
            tr>td.rowHeader {
                color: #0083C1;
                font-weight: bold;
                padding-right: 20px;
                width: ${props => props.rowHeaderWidth ?? '0'};
                text-align: right;
                vertical-align: ${props => props.rowHeaderVertical ?? 'middle'};
            }

            tr>td.dataCol {
                background-color: #FFFFFF;
                padding: ${props => props.colSpacing ? '30px' : '30px 0'};
                ${props => props.colWidth ? 'width: ' + props.colWidth + ';' : undefined}
            }

            tr>td:nth-child(2) {
                padding-left: ${props => props.colSpacing ? '' : '20px'};
            }
        }
    }
`;

export interface RowData {
    rowHeader: string;
    [key: string]: JSX.Element | string;
}

interface Props {
    colHeader: string[];
    data: RowData[];
    width?: string;
    colSpacing?: boolean;
    rowHeaderVertical?: string;
    rowHeaderWidth?: string;
    colHeaderWrapper?: JSX.Element;
}

const BlockTable = (props: Props) => {
    return (
        <Wrapper 
            width={props.width} 
            colSpacing={props.colSpacing} 
            rowHeaderVertical={props.rowHeaderVertical} 
            rowHeaderWidth={props.rowHeaderWidth}
            colWidth={props.rowHeaderWidth ? `calc((100% - ${props.rowHeaderWidth}) / ${props.colHeader.length})` : undefined}
        >
            <table>
                <thead>
                    <tr>
                        <td></td>
                        {props.colHeader.map(header=>(
                            props.colHeaderWrapper ? 
                            <td>
                                {React.cloneElement(props.colHeaderWrapper, {children: header})}
                            </td> :
                            <td>{header}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(row=>(
                        <tr>
                            <td className='rowHeader'>{row.rowHeader}</td>
                            {props.colHeader.map(colName=>(
                                <td className='dataCol'>
                                    {row[colName]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Wrapper>
    )
}

export default BlockTable;
