import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/button/button';

interface Props {
    value?: string[];
    onChange?: (value: string[]) => void;
}

const WeekdaySelector: React.FC<Props> = (props: Props) => {

    const options = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const [weekdays, setWeekdays] = useState<string[]>([]);

    return (
        <div className="d-flex is--space-around">
            {options.map(d=>
                <WeekdayOption 
                    value={d} 
                    checked={(props.value || weekdays).indexOf(d)>=0} 
                    onClick={()=>{
                        if(weekdays.indexOf(d)>=0){//checked
                            setWeekdays(weekdays.filter(s=>s!==d));
                            props.onChange?.(weekdays.filter(s=>s!==d));
                        }
                        else{
                            setWeekdays(weekdays.concat([d]));
                            props.onChange?.(weekdays.concat([d]));
                        }
                    }}
                />
            )}
        </div>
    )
}

export default WeekdaySelector;

const WeekdayOption = (props:{value: string, checked: boolean, onClick: React.MouseEventHandler<HTMLElement>}) => (
    <Option shape='circle' onClick={props.onClick} checked={props.checked}>
        {props.value[0].toUpperCase()}
    </Option>
);

interface OptionProps {
    checked: boolean;
}

const Option = styled(Button)<OptionProps>`
    background-color: ${(props: any)=>props.checked?'#0083C1':'#E3E3E3'};
    color: ${(props: any)=>props.checked?'white':'black'};
    border-radius: 20px;
    font-size: 16px;
    margin: 0 5px;
    padding: 0;

    &:focus {
        background-color: ${(props: any)=>props.checked?'#0083C1':'#E3E3E3'};
        color: ${(props: any)=>props.checked?'white':'black'};
    }
`;
