import React, { useState, useEffect } from 'react';
import Drawer from 'components/drawer/drawer';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import { Form, Select } from 'components/form';
import DatePicker from 'components/datePicker/datePicker';
import { getOrgById } from 'api/organizationMgmtApi';
import { editTreatment } from 'api/case';
import { apiCaller } from 'service/apiCaller';
import { Modal } from 'antd';
import moment from 'moment';

interface Props {
    onClose: Function;
    editDate:Function;
    treatmentId: number;
    orgId: number;
    treatmentDate: moment.Moment;
    treatmentHour: string;
}

const EditTreatmentDrawer: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY';
    const [businessHours, setBusinessHours] = useState<string[]>([]);
    const timeOptions = businessHours.map((time: string, i: number)=>(
        <Select.Option key={i+1} value={time}>{time}</Select.Option>
    ));

    useEffect(()=>{
        getOrgById(
            props.orgId
        ).then((res:any)=>{
            let startTime = moment(res.data.data.businessHourS, 'HH:mm');
            const endTime = moment(res.data.data.businessHourE, 'HH:mm');

            const  hours = [];
            while(startTime<=endTime){
                hours.push(startTime.format('HH:mm'));
                startTime = startTime.add(30, 'minutes');
            }
            setBusinessHours(hours);
        }).catch((error: any)=>{
            console.error(error);
            throw(error);
        });
        form.setFieldsValue({
            treatmentDate: props.treatmentDate,
            treatmentHour: props.treatmentHour
        })
    }, []);

    const handleOk = () => {
        form.validateFields().then(values=>{
            const success = (res: any) =>{
                
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    
                    onCancel: ()=>{
                        props.editDate()
                        props.onClose()
                    }
                })
            };

            const reject = (error: any) => {
                console.error(error)
                Modal.error({
                    maskClosable:true,
                    title:'Error',
                    content: <div style={{textAlign:'center'}}> 
                        {
                            (()=>{
                                const msg = error?.data?.detail
                                if(typeof msg === 'string'){
                                    return msg
                                }else if(msg instanceof Array){
                                    return msg.join('\n')
                                }else{
                                    return JSON.stringify(msg)
                                }
                            })()   
                        }
                    </div> 
                });
            }

            apiCaller(
                editTreatment, 
                {
                    ...values,
                    treatmentDate: values.treatmentDate.format('YYYY/MM/DD'),
                    planDetailId: props.treatmentId,
                    device: 'web'
                }, 
                success, 
                reject
            );
        })
    }

    return (
        <Drawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='30vw'
            title={formatMessage({id:'EDIT_THE_CLASS'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect" onClick={handleOk}>
                        {formatMessage({id:'SAVE'})}
                    </Button>
                </div>
            }
        >
            <Form form={form} layout='vertical'>
                <Form.Item label={formatMessage({id:'TIME'})} name='treatmentDate'>
                    <DatePicker bordered={false} placeholder={dateFormat} format={dateFormat} style={{width: '100%'}} disabledDate={current=>current<moment().startOf('day')} />
                </Form.Item>
                <Form.Item  name='treatmentHour'>
                    <Select bordered={false}>
                        {timeOptions}
                    </Select>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default EditTreatmentDrawer;
