import { api, toQueryString } from './api';
import { Response, ListResponse } from 'types/Api';
import { EvaluationBrief, Evaluation } from 'containers/caseMgmt/caseProfile/Evaluation/type';

export const getEvaluation = (query: { personalInfoBasicId: number, page: number, pageSize: number, isPreliminary: boolean }) =>
    api.get<ListResponse<EvaluationBrief>>(`/keeogo/personal_info_basic/evaluation?${toQueryString(query)}`);

export const addEvaluation = (data: any) =>
    api.post(`/keeogo/personal_info_basic/evaluation/create`, data);

export const editEvaluation = (data: any) =>
    api.put(`/keeogo/personal_info_basic/evaluation/update`, data);

// 停用資料內status:改成false

export const findOneEvaluation = (id: number) =>
    api.get<Response<Evaluation>>(
        `keeogo/personal_info_basic/evaluation/find-one?${toQueryString({personalEvaluationMasterId: id, multilingualKey:localStorage.getItem('lang')||'EN'})}`,
    );

export const checklistFindOne = ({ Id }: any) =>
    api.get(`/keeogo/personal_info_basic/evaluation/checklist?personalInfoBasicId=${Id}`);

export const putChecklist = (data: any) =>
    api.put(`/keeogo/personal_info_basic/evaluation/checklist`, data);

export const recordFindOne = ({ Id }: any) =>
    api.get(`/keeogo/personal_info_basic/evaluation/record?personalInfoBasicId=${Id}`);

export const putRecord = (data: any) => api.put(`/keeogo/personal_info_basic/evaluation/record`, data);
