import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import { Row, Col } from 'antd';
import RadioWrapper from 'components/radioWrapper/radioWrapper';
import { GlobalContext } from '../../../../index';
import ColorCard from 'components/colorCard/colorCard';
import ImgCard from 'components/imgCard/imgCard';
import ImageMapping from 'constants/imageMapping';

const PackageCard = styled.div<{value?: number, selected?: boolean}>`
    padding: 10px 30px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    width: 100%;
    margin: 10px 1.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    font-weight: bold;
    color: ${props => props.selected ? '#FFFFFF' : '#0083C1'};
    background-color: ${props => props.selected ? '#0083C1' : '#FFFFFF'};
`;

const PackageOption:React.FC<{value?: number, selected?: boolean, onClick?: (val: number) => void}> = (props: {value?: number, selected?: boolean, onClick?: (val: number) => void}) => (
    <Col span={6}>
        <PackageCard {...props} onClick={()=>props.onClick?.(props.value || 0)}></PackageCard>
    </Col>
)

interface PlanDateProps {
    treatmentData: any;
    onSet: (planDetail: {
        planId: number;
        treatmentDate: string;
        treatmentHour: string;
        packageName: string;
        className: string;
    }) => void;
    onClose: () => void;
}

const PlanDateSelectDrawer: React.FC<PlanDateProps> = (props: PlanDateProps) => {

    const { formatMessage } = useIntl();
    const { getOptions, nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    const [packageOptions, setPackageOptions] = useState<JSX.Element[]>([]);
    const [classOptions, setClassOptions] = useState<JSX.Element[]>([]);
    const [selectedPackageId, setSelectedPackageId] = useState<number>();
    const [selectedClassId, setSelectedClassId] = useState<number>();

    const handleTypeChange = (val: string) => {
        const packages = props.treatmentData.filter((d: any)=>
            d.treatment_course.treatmentTypeCode === nameMapping[val] && 
            d.treatment_course.classTypeCode !== nameMapping['CLA01'] && 
            !d.inFinish
        ).map((d: any, i: number)=>{
            const nameSplit = d.treatment_course.name.split(' ');
            return (
                <PackageOption value={d.treatment_course.id} key={i+1}>{nameSplit[nameSplit.length - 1]}</PackageOption>
            )
        });
        setPackageOptions(packages);
    }

    const handlePackageChange = (val: number) => {
        setSelectedPackageId(val);
        const plan = props.treatmentData.find((d: any)=>d.treatment_course.id === val);
        const classes = plan?.plan_time.filter((d: any)=>
            !d.evaluationId && 
            moment(d.treatmentDate, 'YYYY/MM/DD').isSameOrBefore(moment().format('YYYY/MM/DD'))
        ).sort((a: any, b: any)=>
            moment(`${a.treatmentDate} ${a.treatmentHour}`, 'YYYY/MM/DD HH:mm').diff(
                moment(`${b.treatmentDate} ${b.treatmentHour}`, 'YYYY/MM/DD HH:mm')
            )
        ).map((d: any)=>(
            <ColorCard
                value={d.id}
                color='#2E63A3'
                month={moment(d.treatmentDate).format('MMM')+'.'}
                date={moment(d.treatmentDate).format('D')}
                weekday={moment(d.treatmentDate).format('ddd').toUpperCase()}
                hour={d.treatmentHour + ' - ' + moment(d.treatmentHour, 'HH:mm').add(50, 'minutes').format('HH:mm')}
                type={`${formatMessage({id:'CLASS'})} ${d.detailClassNum}`}
                name=''
                width='45%'
            />
        ));
        setClassOptions(classes);
    }

    const handleSubmit = () => {
        const plan = props.treatmentData.find((d: any)=>d.treatment_course.id === selectedPackageId);
        props.onSet({
            planId: selectedClassId ?? 0,
            treatmentDate: plan?.plan_time?.find((d: any)=>d.id === selectedClassId)?.treatmentDate,
            treatmentHour: plan?.plan_time?.find((d: any)=>d.id === selectedClassId)?.treatmentHour,
            packageName: plan?.treatment_course?.name,
            className: plan?.plan_time?.find((d: any)=>d.id === selectedClassId)?.detailClassNum
        });
    }

    const typeOptions = getOptions('TreatmentType').filter((d: any)=>d.code==='TRE01').map((d: any, i: number)=>(
        <ImgCard img={ImageMapping.TreatmentType[d.code]} text={d.name} key={i+1} value={d.code}/>
    ));

    return (
        <Drawer
            visible
            closable
            maskClosable
            onClose={props.onClose}
            width='30vw'
            title={formatMessage({id:'EXAMINATION'})}
            footer={
                <div className="pull--right rect">
                    <Button 
                        className="is--primary rect text--bold" 
                        disabled={selectedClassId === undefined} 
                        onClick={handleSubmit} 
                    >{formatMessage({id:'ADD'})}</Button>
                </div>
            }
        >
            <div className='mb-15'>
                <div className='text--bold mb-5'>{formatMessage({id:'TYPE'})}</div>
                <RadioWrapper onChange={handleTypeChange} defaultValue='TRE01'>
                    {typeOptions}
                </RadioWrapper>
            </div>
            
            <div className='mb-15'>
                <div className='text--bold mb-5'>{formatMessage({id:'PACKAGE'})}</div>
                <RadioWrapper onChange={handlePackageChange} column noFlex middleElement={<Row gutter={24} style={{padding: '0 10px'}}></Row>}>
                    {packageOptions}
                </RadioWrapper>
            </div>
            
            <div>
                <div className='text--bold mb-5'>{formatMessage({id:'TREATMENT'})}</div>
                <RadioWrapper onChange={val=>setSelectedClassId(val)} column noFlex middleElement={<Row gutter={24} style={{padding: '0 10px'}}></Row>}>
                    {classOptions}
                </RadioWrapper>
            </div>
        </Drawer>        
    )
}

export default PlanDateSelectDrawer;
