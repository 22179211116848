import React, { useState, useEffect,useContext } from 'react';
import Drawer from 'components/drawer/drawer';
import { useIntl } from 'react-intl';
import Button from 'components/button/button';
import { Form } from 'components/form/form';
import styled from 'styled-components';
import { GlobalContext } from '../../../../index';
import { Modal, Row, Col, Radio, Progress } from 'antd';
import ImageMapping from 'constants/imageMapping';
import { uploadVideo } from 'api/record';
import { AxiosRequestConfig } from 'axios';

interface Props {
    onClose: Function;
    caseId: number;
    trainingId: number;
}

const RecordVideosUploadDrawer: React.FC<Props> = (props: Props) => {

    const { formatMessage } = useIntl();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const [form] = Form.useForm();

    const [showProgressModal, setShowProgressModal] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [formValues, setFormValues] = useState({
        classification: '',
        trainingType: ''
    });

    const trainingItemOptions = getOptions('TrainingType').map(d=>(
        <Col span={8} style={{padding: '0 8px'}}>
            <StyledRadioButtonItem value={d.code} key={d.code}>
                <img src={ImageMapping.TrainingItem[d.code]} />
                <div style={{whiteSpace: 'normal'}}>{d.name}</div>
            </StyledRadioButtonItem>
        </Col>
    )); 

    const handleNextOnClick = () => {
        form.validateFields().then(values=>{
            const fileSelector = document.getElementById('videopicker');
            fileSelector?.click();
            setFormValues({
                classification: values.classification,
                trainingType: values.trainingType
            });
        });
    }

    const onImport = async (e: any) => {
        try{
            if(e.target.files.length > 5){
                throw(new Error('File amount exceed limit.(At most 5 files)'));
            }
            const formData = new FormData();
            formData.append('personalTrainingRecordId', props.trainingId.toString());
            formData.append('classification', formValues.classification);
            formData.append('trainingTypeCode', formValues.trainingType);
            for(let i = 0; i<e.target.files.length; ++i){
                if(e.target.files[i].size > 70000000) {
                    throw(new Error('File size exceed limit.(Should be less than 70MB)'));
                }
                if(e.target.files[i].type!=='video/mp4'){
                    throw(new Error('File format error.'));
                }
                formData.append('video', e.target.files[i]);
            }

            const config: AxiosRequestConfig = {
                onUploadProgress: progressEvent => {
                    setUploadProgress(Math.round( progressEvent.loaded / progressEvent.total * 100 ));
                },
                timeout: 120000 // 2 mins
            };

            uploadVideo(
                formData,
                config
            ).then((res: any)=>{
                setShowProgressModal(false);
                Modal.success({
                    maskClosable:true,
                    title: <div className='text--bold text-24'>{formatMessage({id:'SUCCESSFUL'})}</div>,
                    onCancel: () => props.onClose(),
                    centered: true,
                    content: (
                        <div className='d-flex is--column v--center mt-20'>
                            <div className='mt-5 mb-5'>{formatMessage({id:'FINISH_UPLOADING'})}</div>
                            <div className='text--bold mt-5 mb-20'>{formatMessage({id:'VIDEOS_NUM'})}: ###{res.data.data.length}</div>
                            <Button
                                style={{
                                    backgroundColor: '#9ec25b',
                                    color: '#FFFFFF',
                                    height: '40px',
                                    fontWeight: 'bold',
                                    width: '80%'
                                }}
                                onClick={()=>{Modal.destroyAll();props.onClose();}}
                            >{formatMessage({id:'GOOD'})}</Button>
                        </div>
                    )
                })
            }).catch((error: any)=>{
                console.error(error);
                throw(error);
            });
            setShowProgressModal(true);
        }
        catch(err){
            console.error(err);
            Modal.error({
                maskClosable: false,
                title: <div className='text--bold text-24'>{formatMessage({id:'FAIL_TITLE'})}</div>,
                onCancel: () => props.onClose(),
                centered: true,
                content: (
                    <div className='d-flex is--column v--center mt-20'>
                        <div className='mt-20 mb-20 text--center'>{formatMessage({id:'FILE_FORMAT_ERROR'})}</div>
                        <Button
                            style={{
                                backgroundColor: '#dd4242',
                                color: '#FFFFFF',
                                height: '40px',
                                fontWeight: 'bold',
                                width: '80%',
                                marginBottom: '10px'
                            }}
                            onClick={()=>{Modal.destroyAll();onImport(e);}}
                        >{formatMessage({id:'TRY_AGAIN'})}</Button>
                        <Button
                            type='link'
                            style={{
                                color: '#2E2E2E',
                                fontWeight: 'bold'
                            }}
                            onClick={()=>{Modal.destroyAll();props.onClose();}}
                        >{formatMessage({id:'CANCEL'})}</Button>
                    </div>
                )
            })
        }
    }
    
    return (
        <StyledDrawer
            visible={true}
            closable={true}
            maskClosable={true}
            onClose={props.onClose as any}
            width='35vw'
            title={formatMessage({ id: 'VIDEOS_UPLOAD' })}
            footer={
                <div className="pull--right rect">
                    <Button
                        className="is--primary rect"
                        onClick={handleNextOnClick}
                        style={{ backgroundColor: '#47A6D8' }}
                    >{formatMessage({ id: 'NEXT' })}</Button>
                </div>
            }
        >
            <input type="file" id="videopicker" hidden accept='video/mp4' multiple onChange={e=>onImport(e)} />
            {showProgressModal && <ProgressModal progress={uploadProgress} />}

            <Form layout='vertical' form={form}> 
                    <StyledFormItem className='width--full' label={formatMessage({ id: 'CHOOSE_TRAINING_TYPE' })} name='classification' rules={[{required: true, message: formatMessage({id:'TRAINING_TYPE_REQUIRED'})}]}>  
                        <Radio.Group buttonStyle='solid' className='width--full'>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <ClassificationRadio value={formatMessage({id:'W_KEEOGO'})}>
                                        {formatMessage({id:'W_KEEOGO'})}
                                    </ClassificationRadio>
                                </Col>
                                <Col span={8}>
                                    <ClassificationRadio value={formatMessage({id:'W_O_KEEOGO'})}>
                                        {formatMessage({id:'W_O_KEEOGO'})}
                                    </ClassificationRadio>
                                </Col>
                            </Row>
                        </Radio.Group>
                    </StyledFormItem>
                    <StyledFormItem label={formatMessage({ id:'CHOOSE_TRAINING_ITEMS' })} name='trainingType' rules={[{required: true, message: formatMessage({id:'TRAINING_ITEM_REQUIRED'})}]}>
                        <Radio.Group buttonStyle='solid'>
                            <Row gutter={24}> 
                                {trainingItemOptions}
                            </Row>
                        </Radio.Group>
                    </StyledFormItem>
            </Form>
        </StyledDrawer>
    )
}

export default RecordVideosUploadDrawer;

interface ProgressModalProps {
    progress: number;
}

const ProgressModal: React.FC<ProgressModalProps> = (props: ProgressModalProps) => {

    const { formatMessage } = useIntl();

    return (
        <StyledModal
            visible
            closable={false}
            maskClosable={false}
            centered
            footer={null}
            wrapClassName='progressModal'
        >
            <Progress percent={props.progress} strokeColor='#66BCEA' strokeWidth={15}/>
            <div className='text-16 mt-20 d-flex h--center v--center' style={{color: '2E2E2E'}}>{formatMessage({id:'UPLOADING'})}...</div>
        </StyledModal>
    )
}

const ClassificationRadio =  styled(Radio)<{selected?: boolean}>`
    width: 100%;

    span {
        display: flex;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        border-radius: 0.4rem;
        overflow: hidden;
        padding: 12px 0;
        color: #0083C1;
        background-color: #FFFFFF;
    }
    
    &.ant-radio-wrapper-checked span {
        color: #FFFFFF;
        background-color: #0083C1;
    }

    .ant-radio {
        display: none;
    }
`;

const StyledRadioButtonItem = styled(Radio)<{selected?: boolean}>`  
    width: 100%;

    span {
        display: flex;
        align-items: center;
        font-weight: bold;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        border-radius: 0.4rem;
        overflow: hidden;
        padding: 10px;
        margin: 10px 0;
        border: none;

        img {
            background-color: #F3F3F3;
            width: 50px;
            margin-right: 10px;
            padding: 3px;
        }
    }

    &.ant-radio-wrapper-checked span {
        border: 3px #0083C1 solid;
    }
    
    .ant-radio {
        display: none;
    }
`;

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        padding: 20px;
    }
`;

const StyledFormItem = styled(Form.Item)`
    .ant-form-item-label {
        label::before {
            display: none;
        }
    }
`;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 0.5rem;

        .ant-modal-body {
            padding: 30px 20px;
        }
    }
`;
