import React from 'react';
import styled from 'styled-components';

/**
 * @param {number} value
 * @param {string} color(hex)
 */
export const CycleSvg: React.FC<any> = (props: any) => {
    const { value, color, style } = props;
    return (
        <Cycle viewBox='0 0 42 42' style={style}>
            <circle cx='21' cy='21' r='18' fill='none' stroke='#00708D' strokeWidth='3' />
            <circle cx='21' cy='21' r='18' fill='none' stroke={color} strokeWidth='3' strokeDasharray={parseDash(value)} transform='rotate(-90, 21, 21)' />
            <Value x='50%' y='50%' fill={color}>
                {value}%
            </Value>
        </Cycle>
    );
};

const parseDash = (value: number): string => {
    value = value > 100 ? 100 : value < 0 ? 0 : value;
    return `${(114 * value / 100)} ${114 - (114 * value / 100)}`;
}

const Cycle: any = styled.svg`
    width: 100%;
    height: 100%;
`;

const Value: any = styled.text`
    font-size: 70%;
    text-transform: uppercase;
    text-anchor: middle;
    transform: translateY(10%);
`;
