// tslint:disable no-implicit-dependencies quotemark
import React, { useState, useEffect } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { Spin,Row ,Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { apiCaller } from 'service/apiCaller';
import { routeMatch } from 'utils/webHelper';
import { MyTabs, MyTabPane } from 'components/myTab/myTab';
import { KeeogoProfileList } from './keeogoProfileList';
import { CrossOrganizationList } from './crossOrganizationList';
import usePermission  from 'hooks/usePermission';
import Button from 'components/button/button';
import moment from 'moment';
import { getUser } from 'api/case';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Pagination, { PaginationState } from 'components/pagination/pagination';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const Keeogo: React.FC<RouteComponentProps> = ({ location }) => {

    const {formatMessage} = useIntl()
    const history = useHistory()
    const [data, setData] = useState<any>([]);
    const [orgData, setOrgData] = useState();
    const [myPermissionState] = usePermission('state');
    const [loading, setLoading] = useState<any>(false);
    const [crossOrganizationPaginationTotal, setCrossOrganizationPaginationTotal] = useState(0);
    const [crossOrganizationPagination, setCrossOrganizationPagination] = useState<PaginationState>({
        page: 1,
        pageSize: 10
    });
    const [parameterPaginationTotal, setParameterPaginationTotal] = useState(0);
    const [parameterPagination, setParameterPagination] = useState<PaginationState>({
        page: 1,
        pageSize: 10
    });

    const [tabKey, setTabKey] = useState(1);

    const { id } = routeMatch('/Patient/:id', location.pathname);

    const getData = async ()=>{
        try{
            

            setLoading(true)
            await apiCaller(
                getUser,
                { id },
                (res: any) => setData(res.data.data[0]),
                (error: Error) => {throw(error)},
            )
        }catch (e){
            console.error(e)
        }finally{
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    const tabPanes =  [
        { 
            tab: formatMessage({id:'PARAMETER_LIST'}), 
            component: KeeogoProfileList, 
            canRead: true , 
            prop:{
                data,
                pagination: parameterPagination, 
                setPaginationTotal: setParameterPaginationTotal
            } 
        },
        { 
            tab: formatMessage({id:'CROSS_ORG'}), 
            component: CrossOrganizationList, 
            canRead: true , 
            prop:{
                userInfo:data, 
                pagination: crossOrganizationPagination, 
                setPaginationTotal: setCrossOrganizationPaginationTotal
            } 
        }
    ].filter((item: any) => item.canRead);

    return (
        <Wrapper>
            {/* edit drawer  */}
            <div
                className="main pt-0 pb-30 pl-24 pr-24"
                css={{ background: 'rgb(245, 245, 245)' }}
                style={{ overflowX: 'hidden' }}
            >
                <Spin spinning={loading}>
                    <div style={{
                        padding: '1rem 0' ,
                        display:'flex' ,
                        alignItems :'center',
                        height: 'calc( (100vh - 80px) * 0.9 * 0.2 )'
                    }}>
                        <Button
                            className="is--primary light"
                            style={{
                                height: '100%',
                                marginRight: '1rem',
                                borderRadius: '1rem'
                            }}
                            onClick={()=>{
                                history.push('/Patient')
                            }}
                            icon={<LeftOutlined />}
                        >
                            BACK
                        </Button>

                        <Row gutter={24} style={{flex: 1}}>
                            {/* user id */}
                            <Col span={8}>
                                <h2>{formatMessage({id:'USERID'})}</h2>
                                <hr/>
                                <span>{data.userId || '--'}</span>
                            </Col>

                            {/* name */}
                            <Col span={4}>
                                <h2>{formatMessage({id:'NAME'})}</h2>
                                <hr/>
                                <span>{data.firstName || '--'}{data.lastName || '--'}</span>
                            </Col>

                            {/* org */}
                            <Col span={4}>
                                <h2>{formatMessage({id:'ORG_NAME'})}</h2>
                                <hr/>
                                <span>{data.abbreviation || '--'}</span>
                            </Col>

                            {/* case code */}
                            <Col span={4}>
                                <h2>{formatMessage({id:'CASE_CODE'})}</h2>
                                <hr/>
                                <span>{data.medicalRecordNumber || '--'}</span>
                            </Col>

                            {/* birth */}
                            <Col span={4}>
                                <h2>{formatMessage({id:'BIRTH'})}</h2>
                                <hr/>
                                {data.birthday ? moment(data.birthday).format('DD/MM/YYYY') : '--'}
                            </Col>
                        </Row>

                    </div>
                    <Row className="card-container">
                        <MyTabs type="card" onChange={(key:any)=>setTabKey(key)}>
                            {
                                tabPanes.map((item: any, i: number) => (
                                    <MyTabPane tab={item.tab} key={i + 1} refresh={true} >
                                        <item.component {...item.prop}/>
                                    </MyTabPane>
                                ))
                            }
                        </MyTabs>
                    </Row>
                </Spin>
            </div>
            <Pagination
                pagination={[parameterPagination, crossOrganizationPagination][tabKey-1]}
                setPagination={[setParameterPagination, setCrossOrganizationPagination][tabKey-1]}
                paginationTotal={[parameterPaginationTotal, crossOrganizationPaginationTotal][tabKey-1]}
            />
        </Wrapper>
    );
};
