// tslint:disable quotemark no-implicit-dependencies
import React from 'react';
import { Row, Col, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { SelectValue, SelectProps } from 'antd/es/select';
import { pathOr, find, propEq, map } from 'ramda';
import { Select } from 'components/form';
import { useEnums, Enum } from 'api/enums';
import { DataSource, MutationOptions } from 'constants/types';
import useAxios from 'hooks/useAxios';
import { useAllOrgs } from './enums';
import { api, toQueryString } from './api';
import moment from 'moment';

const { compose } = require('ramda');

export type CaseTypeStatus = {
    id?: React.Key;
    name?: string;
    caseType?: number;
    active?: boolean;
    order?: number;
    updatedAt?: moment.Moment;
    isDeletable: boolean;
};

export type CaseType =
    // Enum &
    {
        id: React.Key;
        name?: string;
        active: boolean;
        createdAt?: moment.Moment;
        updatedAt?: moment.Moment;
        level: string;
        status: CaseTypeStatus[];
    };

export const getCaseType = () => api.get(`/caseType?pageSize=-1`);

export const useCaseTypes = ({
    form,
    ...variables
}: { form?: FormInstance; [key: string]: any } = {}) => {
    const { byId, dataSource, ...others } = useEnums<CaseType>('/caseType', {
        baseURL: undefined,
        useCache: false,
        ...variables,
    });

    const [caseStats, setCaseStats] = React.useState<any[]>([]);

    const handleCaseTypeChange = (id: SelectValue) => {
        setCaseStats(compose(pathOr([], ['status']), find(propEq('id', id)))(dataSource));

        if (form) {
            form.setFieldsValue({ caseType: id });
        }
    };

    return {
        caseTypeById: byId,
        caseTypeEnums: dataSource,
        dataSource,
        handleCaseTypeChange,
        caseStats,
        ...others,
    };
};

export function CaseTypeSelect<T = Enum>(props: SelectProps<T>) {
    const { dataSource } = useCaseTypes();

    return (
        <Select allowClear={true} placeholder="請選擇收案別" {...props}>
            {dataSource.map(x => (
                <Select.Option key={x.id} value={x.id}>
                    {x.name}
                </Select.Option>
            ))}
        </Select>
    );
}

export function CaseTypeSelectNew<T = Enum>(props: SelectProps<T>) {
    const { dataSource } = useCaseTypes();

    return (
        <Select allowClear={true} placeholder="請選擇收案別" {...props}>
            <Select.Option key={''} value={''}>
                全部
            </Select.Option>
            {dataSource.map(x => (
                <Select.Option key={x.id} value={x.id}>
                    {x.name}
                </Select.Option>
            ))}
        </Select>
    );
}

export function CaseSelect(props: any) {
    const { onChange, form, ...others }: { onChange: any; form: FormInstance } = props;
    const { dataSource } = useCaseTypes();
    const [detail, setDetail] = React.useState<any[]>([]);
    const onSubjectChange = (e: any) => {
        if (dataSource) {
            const rows = dataSource.filter((t: any) => t.pid === e);
            setDetail(rows);
        } else {
            setDetail([]);
        }
        form.setFieldsValue({ caseStatus: null });
        if (onChange) {
            const resp = { caseType: e, caseStatus: null };
            onChange(resp);
        }
    };

    const onSubjectStatusChange = (e: any) => {
        if (onChange) {
            const resp = { caseKind: null, caseStatus: e };
            onChange(resp);
        }
    };

    return (
        <Row gutter={8}>
            <Col span={12}>
                <Form.Item name="caseType" label={'收案別'}>
                    <CaseTypeSelectNew onChange={onSubjectChange} {...others} />
                </Form.Item>
            </Col>
            <Col span={12}>
                {detail && (
                    <Form.Item name="caseStatus" label={'收案狀態'}>
                        <Select
                            onChange={onSubjectStatusChange}
                            allowClear={true}
                            placeholder="請選擇收案狀態"
                            {...others}
                        >
                            <Select.Option key={''} value={''}>
                                全部
                            </Select.Option>
                            {detail.map(x => (
                                <Select.Option key={x.id} value={x.id}>
                                    {x.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
            </Col>
        </Row>
    );
}

export const useCaseTypeList = (variables: { [key: string]: any } = {}) => {
    const [data, setData] = React.useState<DataSource<CaseType>>({
        content: [],
        total: 0,
    } as any);

    const [loading, request] = useAxios();

    const { loading: loadingOrgs } = useAllOrgs();

    const listCaseTypes = React.useCallback(
        async ({
            page = 1,
            pageSize = 10,
            ...params
        }: {
            [key: string]: any;
        } = {}) => {
            const { page: _page, pageSize: _pageSize, ..._params } = {
                page,
                pageSize,
                ...variables,
                ...params,
            };

            const response = await request<CaseType[]>(
                `/caseType?page=${_page}&pageSize=${_pageSize}&${toQueryString(_params)}`,
            );

            setData({
                content: compose(
                    map((x: any) => ({
                        ...x,
                    })),
                    pathOr([], ['data']),
                )(response),
                total: pathOr(0, ['total'], response),
            });
        },
        [],
    );

    React.useEffect(() => {
        listCaseTypes();
    }, []);

    return {
        loading: loading || loadingOrgs,
        refetch: listCaseTypes,
        dataSource: {
            ...data,
        },
    };
};

export const useQueryCaseType = () => {
    const [data, setData] = React.useState<CaseType | undefined>();
    const [loading, request] = useAxios();

    const queryCaseType = async (id: any) => {
        const response = await request<CaseType>(`/caseType/${id}`);

        setData({
            ...response.data,
        });
    };

    return { loading, queryCaseType, caseTypeItem: data };
};

export const useCreateCaseType = (options: MutationOptions = {}) => {
    const [, request] = useAxios(options);

    return (data: Partial<CaseType>) => {
        data?.status?.forEach((i: any, idx: number) => {
            i.order = idx;
        });
        return request('/caseType', {
            data,
            method: 'POST',
        });
    };
};

export const useUpdateCaseType = (options: MutationOptions = {}) => {
    const [, request] = useAxios(options);

    return (data: Partial<CaseType>) => {
        data?.status?.forEach((i: any, idx: number) => {
            i.order = idx;
        });
        return request('/caseType', {
            data,
            method: 'PUT',
        });
    };
};

export const useActvieCaseType = (options: MutationOptions = {}) => {
    const [, request] = useAxios(options);

    return (data: { id: React.Key; active: boolean }) =>
        request(`/caseType/${data.id}/activate`, {
            data: {
                active: data.active,
            },
            method: 'PATCH',
        });
};

export const useActvieCaseStatus = (options: MutationOptions = {}) => {
    const [, request] = useAxios(options);

    return (data: { id: React.Key; active: boolean }) =>
        request(`/caseType/status/${data.id}/activate`, {
            data: {
                active: data.active,
            },
            method: 'PATCH',
        });
};

export const useIsStatusDeletable = () => {
    const [, request] = useAxios();

    return async (id: React.Key, callback: any) => {
        const response = await request<any>(`/caseType/status/${id}`);
        if ((response as any).success) {
            callback(response.data);
        }
    };
};
