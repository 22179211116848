import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Collapse, Checkbox, Modal } from 'antd';
import SelectCard from 'components/selectCard/selectCard';
import { icon_delete } from 'components/image';
import Label from 'components/label/label';
import Button from 'components/button/button';
import { ParameterProfile } from 'api/parameter';
import { TrainingNote } from 'api/record';
import { warning } from 'components/image';
import { GlobalContext } from '../../../../index';
import ImageMapping from 'constants/imageMapping';
import TimeInput from 'react-advanced-time-input';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';

const StyledCollapse = styled(Collapse)`
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    
    &:not(:last-child) {
        margin-bottom: 15px;
    }

    .ant-collapse-header {
        .ant-collapse-arrow {
            right: 60px!important;
        }

        .deleteBtn {
            position: absolute;
            right: 15px;
            left: auto;
            top: 50%;
            transform: translateY(-55%);
        }
    }
`;

interface CardProps {
    index: number;
    caseId: number;
    noteData: TrainingNote;
    profileList: ParameterProfile[];
    onEdit: (index: number, newNote: TrainingNote) => void;
    onDelete: (index: number) => void;
}

const TrainingItemCard: React.FC<CardProps> = (props: CardProps) => {

    const { formatMessage } = useIntl();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const [permission] = usePermission('Note');

    const [noteData, setNoteData] = useState<TrainingNote>(props.noteData);
    
    const assistDeviceOptions = getOptions('TrainingAssistDevices').map((d:any )=> ({ label: d.name, value: d.name }));
    const trainingItemOptions = getOptions('TrainingType');

    useEffect(()=>{
        props.onEdit(props.index, noteData);
    }, [noteData]);
    
    return (
        <StyledCollapse expandIconPosition='right' bordered={false}>
            <Collapse.Panel key={props.index} header={
                <div className='d-flex'>
                    <SelectCard 
                        img={ImageMapping.TrainingItem[noteData.trainingTypeCode]}
                        title={formatMessage({ id: 'TRAINING_ITEMS' })}
                        options={{ name: trainingItemOptions.map(d=>d.name), value: trainingItemOptions.map(d=>d.code) }}
                        onChange={val => setNoteData({...noteData, trainingTypeCode: (val as string)})}
                        selectWidth={250}
                        value={noteData.trainingTypeCode}
                        bgc='#F3F3F3'
                        className='mr-10'
                        disabled={!checkIfAvailable(permission, 'Edit')}
                    />
                    <SelectCard
                        title={formatMessage({ id: 'PARAM_PROFILE' })}
                        options={{ name: props.profileList.map(profile=>profile.profileName), value: props.profileList.map(profile=>profile.id) }}
                        onChange={val => setNoteData({...noteData, profile: (val as number)})}
                        selectWidth={250}
                        value={noteData.profile > 0 ? noteData.profile : ''}
                        bgc='#F3F3F3'
                        disabled={!checkIfAvailable(permission, 'Edit')}
                    />
                    <div className="deleteBtn" onClick={e => e.stopPropagation()}>
                        {checkIfAvailable(permission, 'Delete') && <Button
                            icon={<img src={icon_delete} />}
                            type='link'
                            onClick={() => {
                                Modal.warning({
                                    icon: <img style={{ margin: '1rem' }} src={warning} />,
                                    content: <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <h1 style={{ fontSize: '1.3rem' }}>
                                            {formatMessage({ id: 'DELETE_CHECK' })}
                                        </h1>
                                        <br />
                                        <br />
                                        <h2>
                                            {formatMessage({ id: 'DELETE_CHECK_INFO' })}
                                        </h2>
                                        <br />
                                        <br />
                                        <Button
                                            className='is--warning mb-10'
                                            style={{ width: '100%' }}
                                            title={formatMessage({ id: 'YES' })}
                                            onClick={() => {
                                                props.onDelete(props.index);
                                                Modal.destroyAll();
                                            }}
                                        >{formatMessage({ id: 'YES' })}</Button>
                                        <Button
                                            className='is--plain mb-10'
                                            style={{ width: '100%' }}
                                            title={formatMessage({ id: 'CANCEL' })}
                                            onClick={() => {
                                                Modal.destroyAll();
                                            }}
                                        >{formatMessage({ id: 'CANCEL' })}</Button>
                                    </div>
                                })
                            }
                            }
                        />}
                    </div>
                </div>
            }>
                <div className="d-flex is--column" style={{ paddingTop: '15px', borderTop: '1px solid #00000066' }}>
                    <div className='d-flex is--row'>
                        <Label name='Reps.' text={
                            <StyledInput 
                                center 
                                type='text' 
                                maxLength={2} 
                                value={noteData.reps} 
                                disabled={!checkIfAvailable(permission, 'Edit')}
                                onChange={e => {
                                    let matches = /[0-9]+/.exec(e.target.value) ?? [''];
                                    if(matches[0] === e.target.value){
                                        setNoteData({
                                            ...noteData, 
                                            reps: e.target.value ? parseInt(e.target.value).toString()/* remove leading zero */ : '0'
                                        });
                                    }
                                }} 
                            />
                        } bgc='#F3F3F3' width='50px' className='mr-15' />
                        <Label name='Sets' text={
                            <StyledInput 
                                center 
                                type='text' 
                                maxLength={2} 
                                value={noteData.sets} 
                                disabled={!checkIfAvailable(permission, 'Edit')}
                                onChange={e => {
                                    let matches = /[0-9]+/.exec(e.target.value) ?? [''];
                                    if(matches[0] === e.target.value){
                                        setNoteData({
                                            ...noteData, 
                                            sets: e.target.value ? parseInt(e.target.value).toString()/* remove leading zero */ : '0'
                                        });
                                    }
                                }} 
                            />
                        } bgc='#F3F3F3' width='50px' className='mr-15' />
                        <Label name='Mins' text={
                            <TimeInput 
                                value={noteData.mins}
                                onChange={e => {
                                    let matches = /[0-9]+/.exec(e.target.value) ?? [''];
                                    if(matches[0] === e.target.value){
                                        setNoteData({
                                            ...noteData, 
                                            mins: e.target.value ? parseInt(e.target.value).toString()/* remove leading zero */ : '0'
                                        });
                                    }
                                }}
                                maxHours={99} 
                                input={<input type='text' disabled={!checkIfAvailable(permission, 'Edit')}/>}
                                style={{
                                    width: '60px', 
                                    border: 'none', 
                                    backgroundColor: '#F3F3F3', 
                                    textAlign: 'center', 
                                    fontWeight: 'bold',
                                    padding: '3px 0',
                                    marginTop: '5px'
                                }} 
                            />
                        } width='60px' className='mr-15' />
                        <Label name='Comments' text={<StyledInput type='text' maxLength={500} value={noteData.comments} disabled={!checkIfAvailable(permission, 'Edit')} onChange={e => setNoteData({...noteData, comments: e.target.value})} />} bgc='#F3F3F3' width='-webkit-fill-available' />
                    </div>
                    <Label
                        width='100%'
                        name='Assist device'
                        text={
                            <CheckboxWrapper>
                                <StyledCheckboxGroup options={assistDeviceOptions} value={noteData.assistDevice} onChange={checkIfAvailable(permission, 'Edit') ? val => setNoteData({...noteData, assistDevice: (val as string[])}) : ()=>{}} />
                            </CheckboxWrapper>
                        }
                    />
                </div>
            </Collapse.Panel>
        </StyledCollapse>
    )
}

export default TrainingItemCard;

const StyledInput = styled.input<{ center?: boolean }>`
    width: 100%;
    border: none;
    background-color: transparent;
    color: black;
    font-size: 16px;
    font-weight: bold;
    text-align: ${props => props.center ? 'center' : 'left'};
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
    display: flex;

    .ant-checkbox-group-item {
        display: flex;

        .ant-checkbox {
            display: none;
        }

        span {
            padding: 10px;
            background-color: #F3F3F3;
            color: #000000;
            border-radius: 0.3rem;
            font-weight: normal;
            white-space: nowrap;
        }

        &.ant-checkbox-wrapper-checked {
            span {
                color: #FFFFFF;
                background-color: #2E63A3;
            }
        }
    }
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;
