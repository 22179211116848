import React, { useState, useEffect } from 'react';
import { Form, InputNumber, Row, Col, Empty } from 'antd';
import Switch from 'components/switch/switch';
import Drawer from 'components/drawer/drawer';
import DatePicker from 'components/datePicker/datePicker';
import { Input, Select  } from 'components/form';
import Button from 'components/button/button';
import { success, warning, error } from '../../utils/message';
import { Organization, Plan } from './type';
import {
    addOrganization,
    updateOrganization,
    updateOrganizationActive,
} from '../../api/organizationMgmtApi';
import moment from 'moment';
import { apiCaller } from 'service/apiCaller';
import { pathOr } from 'ramda';
import { useIntl } from 'react-intl';
import Modal from 'antd/es/modal';

type Props = {
    type:'edit'|'add'
    data:Organization
    onClose:()=>void
    onRefresh:()=>void
}

export const OrganizationMgmtDrawer: React.FC<Props> = (props) => {
    const intl = useIntl();

    const [form] = Form.useForm();

    const {
        data:item,
        type,
        onRefresh,
        onClose
    } = props

    const addressCountLimit = 50;
    const [addressCount, setAddressCount] = useState(0);

    useEffect(() => {

        // console.log('set init data when props change', item)

        form.setFieldsValue({
            ...item,
            active: type === 'add' || item.active
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const handleOk = () => {
        form.validateFields().then(values => {
            // TODO: call API directly or invoke a callback function from parent
            const row = { ...item, ...values } as Organization;
            const doSuccess = (res: any) => {
                Modal.success({
                    maskClosable:true,
                    title:'Success',
                    onCancel:()=>{
                        handleClose()
                        onRefresh()
                    }
                })
            };
            const doReject = (err: any) => {
                Modal.error({
                    maskClosable:true,
                    title:'Error',
                    content:<p style={{textAlign:'center'}}>{err?.data?.detail ?? ''}</p>
                })
            };

            if (row.planLimit) {
                row.planLimit = +row.planLimit;
            }

            if (row.tax) {
                row.tax = row.tax.toString();
            }

            console.log('[ payload before req ]', row)

            if (type === 'add') {
                apiCaller(addOrganization, row, doSuccess, doReject);
            } else {
                apiCaller(updateOrganization, row, doSuccess, doReject);
            }
        });
    };

    const handleClose = () => {
        form.resetFields();
        form.setFieldsValue({});
        onClose();
    };

    return (
        <Drawer
            visible={true}
            getContainer={false}
            maskClosable={true}
            closable={false}
            keyboard={false}
            width={820}
            title={intl.formatMessage({id:type==='add'?'ADD_ORG_MGMT':'EDIT_ORG_MGMT'})}
            onClose={handleClose}
            footer={
                (
                    <div className="pull--right">
                        <Button className="mr-20 rect" onClick={handleClose}>
                            {intl.formatMessage({id:'CANCEL'})}
                        </Button>
                        <Button className="is--primary rect" onClick={handleOk}>
                            {intl.formatMessage({id:'SAVE'})}
                        </Button>
                    </div>
                )
            }
        >
            <Form form={form} layout="vertical">
                {/* 組織單位基本資料 */}
                <h2 className='title'>{intl.formatMessage({id:'ORG_BASIC_INFO'})}</h2>
                <Row gutter={24}>

                    {/* 組織單位名稱 */}
                    <Col span={11}>
                        <Form.Item
                            label={intl.formatMessage({id:'NAME'})}
                            name="name"
                            rules={[{ required: true, message: `${intl.formatMessage({id:'NAME'})} is required` }]}
                        >
                            <Input bordered={false}  />
                        </Form.Item>
                    </Col>

                    <Col span={2}></Col>

                    {/* 類型 */}
                    <Col span={11}>
                        <Form.Item
                            label={intl.formatMessage({id:'TYPE'})}
                            name="type"
                            rules={[{ required: true, message: `${intl.formatMessage({id:'TYPE'})} is required` }]}
                        >
                            <Select bordered={false} >
                                <Select.Option value={'sys'}>level1</Select.Option>
                                <Select.Option value={'prov'}>level2</Select.Option>
                                <Select.Option value={'bus'}>level3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* 組織 EN 縮寫 */}
                    <Col span={11}>
                        <Form.Item
                            label={intl.formatMessage({id:'ABBRE_NAME'})}
                            name="abbreviation"
                            rules={[
                                { required: true, message: `${intl.formatMessage({id:'ABBRE_NAME'})} is required` },
                                {
                                    validator: async (rule, value) => {
                                        if(value && !/^([A-Z]|[0-9])/.test(value)){
                                            throw('Only allow upperCase alphabet and number')
                                        }
                                    }
                                }

                            ]}
                        >
                            <Input bordered={false} maxLength={5} disabled={ type === 'edit'} />
                        </Form.Item>
                    </Col>

                    <Col span={2}></Col>

                    {/* 地址 */}
                    <Col span={11}>
                        <Form.Item
                            label={intl.formatMessage({id:'ORG_ADDRESS'})}
                            name="address"
                            rules={[{ required: true, message: `${intl.formatMessage({id:'ORG_ADDRESS'})} is required` }]}
                        >
                            <Input bordered={false} maxLength={addressCountLimit} onChange={e=>setAddressCount(e.target.value.length)}  />
                        </Form.Item>
                        <span style={{fontSize: '12px', position: 'absolute', bottom: 0, right: '15px'}}>{addressCount}/{addressCountLimit}</span>
                    </Col>

                    {/* 統一編號 */}
                    <Col span={11}>
                        <Form.Item
                            name="tax"
                            label={intl.formatMessage({id:'ORG_VAT'})}
                            rules={[
                                {
                                    required: true,
                                    validator: async (_: any, value: string) => {
                                        if(!value){
                                            throw(`${intl.formatMessage({id:'VAT'})} is required`)
                                        }
                                        if (
                                            value && (
                                                !/[a-zA-Z0-9][a-zA-Z0-9-]{0,19}/.test(value) || 
                                                /[a-zA-Z0-9][a-zA-Z0-9-]{0,19}/.exec(value)?.[0].length !== value.length
                                            )
                                        ) {
                                            throw(intl.formatMessage({id:'PLEASE_CHECK_VAT'}))
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input maxLength={30} bordered={false}  />
                        </Form.Item>
                    </Col>



                </Row>

                {/* 聯絡人資訊 */}
                <div className='title'>{intl.formatMessage({id:'ORG_CONTACT_INFO'})}</div>
                <Row gutter={24}>


                    {/* 聯絡人姓名 */}
                    <Col span={11}>
                        <Form.Item
                            label={intl.formatMessage({id:'NAME'})}
                            className="mr-8"
                            name="telecomName"
                            rules={[{ required: true, message: `${intl.formatMessage({id:'NAME'})} is required` }]}
                        >
                            <Input bordered={false}  />
                        </Form.Item>
                    </Col>

                    <Col span={2}></Col>

                    <Col span={11}></Col>



                   {/* 聯絡人帳號 */}
                   <Col span={11}>
                        <Form.Item
                            className="mr-8"
                            label={intl.formatMessage({id:'ID_NUMBER'})}
                            name="telecomUserId"
                            rules={[{
                                required: true,
                                message: `${intl.formatMessage({id:'ID_NUMBER'})} is required`
                                }]}
                        >
                            <Input bordered={false}  />
                        </Form.Item>
                    </Col>

                    <Col span={2}></Col>

                    {/* 主要電子信箱郵件 */}
                    <Col span={11}>
                        <Form.Item
                            className="mr-8"
                            label={intl.formatMessage({id:'PRIMARY_MAIL'})}
                            name="telecomEmail"
                            rules={[
                                {
                                    type: 'email',
                                    message: intl.formatMessage({id:'INVALID_MAIL'}),
                                },
                                {
                                    required: true,
                                    message: `${intl.formatMessage({id:'PRIMARY_MAIL'})} is required`,
                                },
                            ]}
                        >
                            <Input bordered={false}  />
                        </Form.Item>
                    </Col>

                  {/* 國籍 */}
                  <Col span={11}>
                        <Form.Item
                            label={intl.formatMessage({id:'COUNTRY'})}
                            name="telecomNationality"
                        >
                            <Select bordered={false} >
                                {/* 本國 */}
                                <Select.Option value={'TW'}>{intl.formatMessage({id:'NATIVE'})}</Select.Option>
                                {/* 外國 */}
                                <Select.Option value={'US'}>{intl.formatMessage({id:'FOREIGNER'})}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={2}></Col>

                    {/* 次要電子信箱郵件 */}
                    <Col span={11}>
                        <Form.Item
                            label={intl.formatMessage({id:'SECONDARY_MAIL'})}
                            name="telecomSubEmail"
                            rules={[
                                {
                                    type: 'email',
                                    message: intl.formatMessage({id:'INVALID_MAIL'}),
                                },
                            ]}
                        >
                            <Input bordered={false}  />
                        </Form.Item>
                    </Col>

                </Row>

                {/* 狀態 */}
                <div style={{ display: 'flex' , alignItems:'center', marginTop: '50px' }}>
                    <h2 className='title'>{intl.formatMessage({id:'ORG_STATUS'})}</h2>
                    <Form.Item
                        valuePropName="checked"
                        label={intl.formatMessage({id:'ORG_STATUS'})}
                        noStyle={true}
                        name="active"
                    >
                        <Switch
                            style={{ marginLeft: '10px', transform: 'scale(1.5)translateX(20%)' }}
                            checkedChildren={intl.formatMessage({id:'ENABLE'})}
                            unCheckedChildren={intl.formatMessage({id:'DEACTIVE'})}
                            
                        />
                    </Form.Item>
                </div>
            </Form>
        </Drawer>
    );
};

export const InputNumberWithSuffix: React.FC<any> = (props: any) => {
    const { value, onChange, suffix, disabled } = props;
    return (
        <div style={{ borderBottom: '1px solid #d9d9d9' }}>
            <InputNumber
                value={value}
                onChange={onChange}
                style={{ border: 'none', width: 'calc(80% - 5px)' }}
                disabled={disabled}
            />
            <div style={{ display: 'inline-block', width: 'calc(20% - 5px)' }}>{suffix}</div>
        </div>
    );
};
