import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Table from 'components/table/table';
import { KRAdata } from 'api/record';
import moment from 'moment';
import { icon_play_btn } from 'components/image';
import KRADetail from './KRADetail';

const StyledTable = styled(Table)`
    .ant-table {

        .ant-table-header {
            table {
                thead {
                    tr {
                        th {
                            background-color: transparent;
                            font-weight: 500;
                            border-bottom: none;
                            color: #909090;
                        }
                    }
                }
            }
        }

        .ant-table-body {
            margin-top: -15px;

            &::-webkit-scrollbar {
                width: 0;
            }

            table {
                border-spacing: 0 5px;
                padding: 0 3px;

                tbody {
                    tr {
                        cursor: pointer;
                        background-color: #F3F3F3;
                        border-radius: 0.3rem;

                        &:hover {
                            td {
                                background-color: #E3E3E3;
                            }
                        }

                        td {
                            border-bottom: none;
                            color: #000000;

                            &:first-child {
                                border-top-left-radius: 0.3rem;
                                border-bottom-left-radius: 0.3rem;
                            }

                            &:last-child {
                                border-top-right-radius: 0.3rem;
                                border-bottom-right-radius: 0.3rem;
                            }
                        }
                    }
                }
            }
        }
    }
`;

interface KRAListProps {
    data: KRAdata[];
    className?: string;
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    };
}

const KRAListTable: React.FC<KRAListProps> = (props: KRAListProps) => {

    const { formatMessage } = useIntl();
    const [kraDetailSetting, setKraDetailSetting] = useState<{
        show: boolean;
        data: KRAdata | null;
    }>({
        show: false,
        data: null
    });
    const [kraDetailPortal, setKraDetailPortal] = useState<React.ReactPortal>();

    useEffect(()=>{
        if(kraDetailSetting.show){
            setKraDetailPortal(
                createPortal(
                    <KRADetail 
                        kraConfigId={kraDetailSetting.data?.id ?? 0} 
                        classData={props.classData} 
                        onClose={()=>setKraDetailSetting({ show: false, data: null })}
                    />,
                    document.body
                )
            );
        }
        else{
            setKraDetailPortal(undefined);
        }
    }, [kraDetailSetting]);

    const columns = [
        {
            title: formatMessage({id:'CREATE_TIME'}),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: any) => text ? moment(text).format('HH:mm') : '--'
        },
        {
            title: formatMessage({id:'ASSIST_DEVICES'}),
            dataIndex: 'assistDevice',
            key: 'assistDevice',
            render: (text: any) => text?.length > 0 ? formatMessage({id:'YES'}) : formatMessage({id:'NO'})
        },
        {
            title: formatMessage({id:'USER_PROFILE'}),
            dataIndex: 'profile',
            key: 'profile',
            render: (text: any) => text ?? '--',
            ellipsis: true
        },
        {
            title: formatMessage({id:'TIME_CONSUMED'}),
            dataIndex: 'timeConsumed',
            key: 'timeConsumed',
            render: (text: any) => text ? moment(text).format('mm:ss') : '--'
        },
        {
            title: formatMessage({id:'LEVEL'}),
            dataIndex: 'level',
            key: 'level',
            width: 75,
            render: (text: any) => text ? ( text === 'Free Mode' ? 'F' : text[text.length - 1] ) : '--'
        },
        {
            title: formatMessage({id:'VIDEO'}),
            dataIndex: 'video',
            key: 'video',
            width: 75,
            render: (text: any) => text ?  <img src={icon_play_btn} /> : ''
        }
    ];

    return (
        <div className={props.className}>
            {kraDetailPortal}

            <div className='text-20 text--bold'>{formatMessage({id:'KRA'})}</div>
            <StyledTable
                columns={columns}
                dataSource={props.data}
                pagination={false}
                onRow={(record, rowIndex) => ({
                    onClick: () => {
                        setKraDetailSetting({
                            show: true,
                            data: props.data[rowIndex ?? 0]
                        });
                    }
                })}
                scroll={{ y: 160 }}
            />
        </div>
    )
}

export default KRAListTable;
