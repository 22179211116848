import * as React from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import styled from 'styled-components';
import { Row, Col } from 'antd';

export const PieChart: React.FC<any> = React.memo((props: any) => {
    //const { data } = props;
    const data = [
        {value: 335, name: '台南市'},
        {value: 310, name: '台北市'},
        {value: 234, name: '新北市'},
        {value: 135, name: '高雄市'},
        {value: 1548, name: '台中市'}
    ];
    const myCharts = React.useRef<any>(null);
    const colors = ['#287BB2', '#39B6C9', '#8ED172', '#529879', '#97C336', '#D5E369', '#FFDE26', '#F5AA16', '#EF7E70',
        '#C15471', '#F06D9F', '#BA4FA5', '#808DEB', '#5C6BC4', '#6EABC6', '#E2B855', '#DB873F', '#A86635', '#7B5B46', '#A37962'];
    const randomColor = (i: number) => {
        if (i < colors.length) return colors[i];
        const rand = Math.random();
        const color = colors[Math.floor(rand * colors.length)];
        colors.splice(Math.floor(rand * colors.length), 1);
        return color;
    }
    const colorList = Array.from({length: colors.length}, (v, i) => randomColor(i));
    const getOption = () => {
        return {
            color: colorList,
            grid: {
                top: 0,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}: {d}%'
            },
            graphic: [
                {
                    type: 'text',
                    left: 'center',
                    top: 75,
                    z: 100,
                    style: {
                        fill: '#aaaaaa',
                        text: '全部',
                        fontSize: 20,
                        fontFamily: 'Microsoft JhengHei',
                        fontWeight: 'bold'
                    }
                },
                {
                    type: 'text',
                    left: 'center',
                    top: 105,
                    z: 100,
                    style: {
                        fill: '#0C8298',
                        text: '1000',
                        fontSize: 42,
                    }
                }
            ],
            series: [
                {
                    type: 'pie',
                    center: [125, 125],
                    radius: ['70%', '90%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false
                    },
                    data
                }
            ]
        };
    }

    return (
        <div>
            <ReactEcharts ref={myCharts} style={{width: '250px', height: '250px'}} option={getOption() as any} />
            <div style={{width: '250px', height: '170px', overflowY: 'auto'}}>
                <Row align='middle' justify='space-between'>
                    {
                        data.map((item: any, i: number) => (
                            <Col key={i} span={11} className='mt-4 mb-4'>
                                <Row align='middle' justify='start'>
                                    <div style={{backgroundColor: `${colorList[i]}`, width: '15px', height: '15px', borderRadius: '50%'}} />
                                    <div className='ml-4 text--grey-1 text--sm' style={{width: '55px'}}>
                                        {item.name}
                                    </div>
                                    <div className='ml-4 text--right text--grey-1 text--sm' style={{width: '30px'}}>
                                        {item.value}
                                    </div>
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </div>
    );
});


