import React, { useState, useEffect } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Input } from 'components/form';

interface Props {
    value?: number;
    onChange?: (value: number) => void;
    defaultValue?: number;
    fixed?: boolean;
    bold?: boolean;
    allowZero?: boolean;
    editable?: boolean;

}

const NumberInput: React.FC<Props> = (props: Props) => {
    
    const [number, setNumber] = useState(props.defaultValue || props.value || 0);

    useEffect(()=>{
        props.onChange?.(props.defaultValue || props.value || 0);
    }, []);

    const handleInputChange = (val: string) => {
        let newCharIndex: number = val.length - 1;
        
        if(val.length > (props.value?.toString().length || 0)){
            for(let i=0; i< (props.value?.toString().length || 0); ++i){
                if(val[i] !== props.value?.toString()[i]){
                    newCharIndex = i;
                    break;
                }
            }
        }

        if(val.length < (props.value?.toString().length || 0)){
            for(let i=0; i< val.length; ++i){
                if(val[i] !== props.value?.toString()[i]){
                    newCharIndex = i;
                    break;
                }
            }
        }

        if((val.charCodeAt(newCharIndex)>=48 && val.charCodeAt(newCharIndex)<=57)){
            setNumber(parseInt(val));
            props.onChange?.(parseInt(val));
        }
        else if(val === ''){
            setNumber(0);
            props.onChange?.(0);
        }
    }

    return (
        <div className='d-flex v--center'>
            <MinusOutlined onClick={props.fixed ? ()=>{} : ()=>{
                if(number>(props.allowZero ? 0 : 1)){
                    setNumber(number-1);
                    props.onChange?.(number-1);
                }
            }}/>
            {props.editable ? <Input 
                value={props.value} 
                maxLength={2}
                onChange={e=>handleInputChange(e.target.value)}
                style={{
                    width: '40px',
                    backgroundColor: '#F3F3F3', 
                    border: 'none', 
                    padding: '5px', 
                    margin: '5px', 
                    userSelect: 'none', 
                    fontWeight: props.bold ? 'bold' : undefined,
                    textAlign: 'center'
                }} 
            /> : 
            <span style={{backgroundColor: '#F3F3F3', padding: '5px', margin: '5px', userSelect: 'none', fontWeight: props.bold ? 'bold' : undefined}}>
                {props.value}
            </span>}
            <PlusOutlined onClick={props.fixed ? ()=>{} : ()=>{
                
                if(number < 20){
                    setNumber(number+1);
                    props.onChange?.(number+1);
                }
            }}/>
        </div>
    )
}

export default NumberInput;
