import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Modal, Row, Col, Slider, Radio } from 'antd';
import { ArrowLeftOutlined, CaretRightFilled, PauseOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import { getKRAData, KRAReport } from 'api/record'
import ImageMapping from 'constants/imageMapping';
import { GlobalContext } from '../../../../../../index';
import moment from 'moment';
import Button from 'components/button/button';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const FullPageWrapper = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: #2E2E2E;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 20px;
            font-weight: bold;
            color: #FFFFFF;

            * {
                margin-right: 15px;
            }
        }

        .classInfo {
            font-size: 20px;
            font-weight: bold;
            color: #FFFFFF;

            * {
                margin-left: 15px;
            }
        }
    }

    .content {
        padding: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .info {
            margin-bottom: 25px;
            height: 45px;

            .name {
                color: #909090;
                font-size: 12px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .text {
                color: #FFFFFF;
                font-size: 16px;
                font-weight: bold;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .view {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            
            .title {
                font-size: 12px;
                font-weight: bold;
                color: #FFFFFF;
            }

            .mainView {
                height: 100%;
            }

            .video {
                display: flex;

                .player {
                    width: 100%;
                }

                .baseline {
                    width: 50%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 0px;
                    border-right: 3px dashed #0083C1;
                }

                .playerControl {
                    background-color: #000000;
                    color: #FFFFFF;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    width: calc( 100% - 110px );

                    .anticon {
                        font-size: 20px;
                    }

                    .ant-slider {
                        width: 100%;
                        margin: 0 20px;

                        .ant-slider-handle {
                            width: 7px;
                            height: 15px;
                            border-radius: 0px;
                            border: none;
                            background-color: #FFFFFF;
                        }
                    }
                }

                .ant-btn {
                    width: 100px;
                    margin-left: 10px;
                    font-size: 14px;
                    padding: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;

                    &.on {
                        background-color: #0083C1;
                        color: #FFFFFF;
                    }
                    
                    &.off {
                        background-color: #FFFFFF;
                        color: #0083C1;
                    }
                }
            }
        }
    }
`;

const StyledRadio = styled(Radio.Button)`
    background-color: #909090;
    color: #FFFFFF;
    border: none !important;
    font-weight: 500;
    padding: 0 1.5vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3 !important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &:not(:first-child)::before {
        display: none;
    }

    &:hover {
        color: #FFFFFF;
    }
`;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        background-color: #2E2E2E;
        border: 2px solid #707070;
        padding: 20px;
        min-height: 200px;

        .ant-modal-body {
            .name {
                color: #909090;
            }
        
            .text {
                font-size: 18px;
                color: #FFFFFF;
            }
        }
    }
`;

interface KRADetailProps {
    kraConfigId: number;
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    }
    onClose: () => void;
}

const KRADetail: React.FC<KRADetailProps> = (props: KRADetailProps) => {

    const canvasId = 'kra-detail-chart';
    enum ChartType {
        ANGLE,
        COG
    }

    const { formatMessage } = useIntl();
    const { getCode } = useContext(GlobalContext).dropdownOptionCtx;
    const [kraDetailData, setKraDetailData] = useState<KRAReport>();
    const [baselined, setBaselined] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasPlayed, setHasPlayed] = useState(false);
    const [videoDuration, setVideoDuration] = useState(0);
    const [videoFraction, setVideoFraction] = useState(0);
    const [videoState, setVideoState] = useState<{
        played: number;
        playedSeconds: number;
        loaded: number;
        loadedSeconds: number;
    }>({
        played: 0,
        playedSeconds: 0,
        loaded: 0,
        loadedSeconds: 0
    });
    const [videoInstance, setVideoInstance] = useState<[ReactPlayer | null, ReactPlayer | null]>([null, null]);
    const [chartInstance, setChartInstance] = useState<Chart<any>>();
    const [chartType, setChartType] = useState(ChartType.ANGLE);
    const [showRemarkModal, setShowRemarkModal] = useState(false);

    const cogChartConfig = {
        LeftHipAngle: {
            name: formatMessage({id:'LEFT_HIP'}),
            color: '#EDB754'
        },
        RightHipAngle: {
            name: formatMessage({id:'RIGHT_HIP'}),
            color: '#47A6D8'
        },
        LeftKneeAngle: {
            name: formatMessage({id:'LEFT_KNEE'}),
            color: '#DD4242'
        },
        RightKneeAngle: {
            name: formatMessage({id:'RIGHT_KNEE'}),
            color: '#9EC25B'
        }
    }

    useEffect(()=>{
        getKRAData(
            props.kraConfigId
        ).then(res=>{
            setKraDetailData({
                ...res.data.data,
                KRADetailRecords: {
                    ...res.data.data.KRADetailRecords,
                    HipCOG: res.data.data.KRADetailRecords.HipCOG.map(d=>-d)
                }
            });
        }).catch(err=>{
            console.error(err);
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        });
    }, []);

    useEffect(()=>{
        if(videoFraction !== videoState.played){
            handleVideoProgressChange(videoFraction);
        }
    }, [videoFraction]);

    useEffect(()=>{
        setVideoFraction(videoState.played);
    }, [videoState.played]);

    useEffect(()=>{
        drawChart(chartType);
    }, [kraDetailData, chartType]);

    const handleVideoProgressChange = (value: number) => {
        if(videoInstance[0]){
            videoInstance[0].seekTo(value, 'fraction');
        }
        if(videoInstance[1]){
            videoInstance[1].seekTo(value, 'fraction');
        }
    }

    const drawChart = (type: ChartType) => {
        if(chartInstance){
            chartInstance.destroy();
        }
        
        const canvas = document.getElementById(canvasId);
        if(canvas){
            const chart = new Chart((canvas as HTMLCanvasElement), {
                type: 'line',
                data: {
                    labels: Array(kraDetailData?.KRADetailRecords?.time?.length ?? 0).fill(0).map((d, i)=>(i+1).toString()),
                    datasets: type === ChartType.ANGLE ? (
                        Object.keys(kraDetailData?.KRADetailRecords ?? {}).filter(key=>key!=='HipCOG' && key!=='time').map((key, i)=>({
                            label: cogChartConfig[key].name,
                            data: kraDetailData?.KRADetailRecords[key] ?? [],
                            pointRadius: 0,
                            borderWidth: 1,
                            borderColor: cogChartConfig[key].color,
                            backgroundColor: cogChartConfig[key].color
                        }))
                    ) : (
                        [{
                            label: formatMessage({id:'HIP_COG'}),
                            data: kraDetailData?.KRADetailRecords.HipCOG ?? [],
                            pointRadius: 0,
                            borderWidth: 1,
                            borderColor: '#F59A23',
                            backgroundColor: '#F59A23'
                        }]
                    )
                },
                options: {
                    layout: {
                        padding: 20
                    },
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                color: 'transparent',
                                borderColor: '#909090',
                                tickColor: '#909090'
                            },
                            ticks: {
                                color: '#909090'
                            },
                            title: {
                                display: true,
                                text: formatMessage({id:'TIME'}),
                                color: '#909090'
                            }
                        },
                        y: {
                            grid: {
                                color: context => {
                                    if(context.tick.value === 0){
                                        return '#909090';
                                    }
                                    return 'transparent';
                                },
                                borderColor: '#909090',
                                tickColor: '#909090'
                            },
                            ticks: {
                                color: '#909090'
                            },
                            title: {
                                display: true,
                                text: type === ChartType.ANGLE ? formatMessage({id:'ANGLE'}) : formatMessage({id:'COG_FULLNAME'}),
                                color: '#909090'
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: type === ChartType.ANGLE,
                            position: 'top',
                            labels: {
                                color: '#FFFFFF'
                            }
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                title: tooltipItem => `${formatMessage({id:'TIME'})}: ${(tooltipItem[0].dataIndex+1).toString()}`
                            }
                        }
                    }
                }
            });
            setChartInstance(chart);
        }
    }
    
    return (
        <FullPageWrapper id='KRA-detail'>
            {showRemarkModal && 
            <StyledModal
                visible
                centered
                closable={false}
                maskClosable
                onCancel={()=>setShowRemarkModal(false)}
                footer={false}
                width='40vw'
            >
                <div className='name'>{formatMessage({id:'REMARK'})}</div>
                <div className='text'>
                    {kraDetailData?.remark}
                </div>
            </StyledModal>}

            <div className="header">
                <div className="title">
                    <ArrowLeftOutlined onClick={props.onClose}/>
                    <img src={ImageMapping.KRAExamination[getCode('KeeogoExamination', kraDetailData?.type ?? '')]}/>
                    {kraDetailData?.type ?? '--'}
                </div>
                <div className="classInfo">
                    <span>{`${props.classData.treatmentName} - ${props.classData.classNum}`}</span>
                    <span>{`${props.classData.treatmentHour} - ${moment(props.classData.treatmentHour, 'HH:mm').add(50, 'minutes').format('HH:mm')}`}</span>
                </div>
            </div>
            <div className="content">
                <Row gutter={24} className="info">
                    <Col span={2}>
                        <div className="name" title={formatMessage({id:'USER_PROFILE'})}>
                            {formatMessage({id:'USER_PROFILE'})}
                        </div>
                        <div className="text" title={kraDetailData?.profile ?? '--'}>
                            {kraDetailData?.profile ?? '--'}
                        </div>
                    </Col>
                    <Col span={2}>
                        <div className="name" title={formatMessage({id:'LEVEL'})}>
                            {formatMessage({id:'LEVEL'})}
                        </div>
                        <div className="text" title={kraDetailData?.level ?? '--'}>
                            {kraDetailData?.level ?? '--'}
                        </div>
                    </Col>
                    <Col span={2}>
                        <div className="name" title={formatMessage({id:'TIME_CONSUMED'})}>
                            {formatMessage({id:'TIME_CONSUMED'})}
                        </div>
                        <div className="text" title={kraDetailData?.timeConsumed ? moment(kraDetailData?.timeConsumed).format('mm:ss') : '--'}>
                            {kraDetailData?.timeConsumed ? moment(kraDetailData?.timeConsumed).format('mm:ss') : '--'}
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="name" title={formatMessage({id:'MAX_HIP_ANGLE_L'})}>
                            {formatMessage({id:'MAX_HIP_ANGLE_L'})}
                        </div>
                        <div className="text" title={kraDetailData?.maxHipAngleLeft?.toString() ?? '--'}>
                            {kraDetailData?.maxHipAngleLeft ?? '--'}
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="name" title={formatMessage({id:'MAX_HIP_ANGLE_R'})}>
                            {formatMessage({id:'MAX_HIP_ANGLE_R'})}
                        </div>
                        <div className="text" title={kraDetailData?.maxHipAngleRight?.toString() ?? '--'}>
                            {kraDetailData?.maxHipAngleRight ?? '--'}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='d-flex is--space-between v--center width--full'>
                            <div>
                                <div className="name" title={formatMessage({id:'ASSIST_DEVICES'})}>
                                    {formatMessage({id:'ASSIST_DEVICES'})}
                                </div>
                                <div className="text">
                                    {(kraDetailData?.assistDevice?.length ?? 0) > 0 ? kraDetailData?.assistDevice?.join(', ') : '--'}
                                </div>
                            </div>
                            <Button
                                className='rect'
                                onClick={()=>setShowRemarkModal(true)}
                                style={{
                                    fontWeight: 500,
                                    color: '#FFFFFF',
                                    backgroundColor: '#909090',
                                    border: 'none'
                                }}
                            >{formatMessage({id:'REMARK'})}</Button>
                        </div>
                    </Col>
                </Row>
                <Row gutter={24} className="view">
                    <Row gutter={24} className='mb-10'>
                        <Col span={6} className='title'>{formatMessage({id:'FRONTAL_VIEW'})}</Col>
                        <Col span={6} className='title'>{formatMessage({id:'SAGITTAL_VIEW'})}</Col>
                        <Col span={12} className='title'>{formatMessage({id:'HIP_KNEE_ANGLE'})}</Col>
                    </Row>
                    <Row gutter={24} className='mainView'>
                        <Col span={12} className='video'>
                            <Row gutter={24} className='player'>
                                <Col span={12}>
                                    {baselined && <div className='baseline'></div>}
                                    <ReactPlayer 
                                        width='100%'
                                        height='100%'
                                        url={kraDetailData?.frontalViewVideo ?? ''} 
                                        controls={false} 
                                        playing={isPlaying} 
                                        onProgress={state=>setVideoState(state)}
                                        onDuration={duration=>setVideoDuration(duration)}
                                        onStart={()=>setHasPlayed(true)}
                                        onReady={player=>setVideoInstance(prv=>[player, prv[1]])}
                                    />
                                </Col>
                                <Col span={12}>
                                    {baselined && <div className='baseline'></div>}
                                    <ReactPlayer 
                                        width='100%'
                                        height='100%'
                                        url={kraDetailData?.sagittalViewVideo ?? ''}
                                        controls={false} 
                                        playing={isPlaying} 
                                        onReady={player=>setVideoInstance(prv=>[prv[0], player])}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} className='chart'>
                            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '20px', backgroundColor: '#000000'}}>
                                <canvas id={canvasId} width='100%' style={{backgroundColor: '#000000'}}/>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24} className='mt-10'>
                        <Col span={12} className='video'>
                            <div className='playerControl'>
                                {isPlaying ? <PauseOutlined onClick={()=>setIsPlaying(false)}/> : <CaretRightFilled onClick={()=>setIsPlaying(true)}/>}
                                <Slider value={videoFraction * 1000} max={1000} min={0} onChange={val=>setVideoFraction(val as number / 1000)}/>
                                {hasPlayed ? moment(videoState.playedSeconds * 1000).format('mm:ss') : moment(videoDuration * 1000).format('mm:ss')}
                            </div>
                            <Button 
                                className={baselined ? 'on' : 'off'}
                                onClick={()=>setBaselined(prv=>!prv)}
                            >{formatMessage({id:'BASELINE'})}</Button>
                        </Col>
                        <Col span={12} className='d-flex h--center'>
                            <Radio.Group buttonStyle='solid' className='d-flex' value={chartType} onChange={e=>setChartType(e.target.value)}>
                                <StyledRadio value={ChartType.ANGLE}>{formatMessage({id:'HIP_KNEE_ANGLE'})}</StyledRadio>
                                <StyledRadio value={ChartType.COG}>{formatMessage({id:'COG_FULLNAME'})}</StyledRadio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Row>
            </div>
        </FullPageWrapper>
    )
}

export default KRADetail;
