import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import { GlobalContext } from '../../../../../index';
import ImageMapping from 'constants/imageMapping';
import RadioWrapper from 'components/radioWrapper/radioWrapper';
import KRASelectDrawer from './KRASelectDrawer';
import { TrainingPackage } from 'api/summary';
import { KraConfig } from '../SummaryEdit';

const OptionCardWrapper = styled.div<{selected?: boolean, value?: string, disabled?: boolean}>`
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    background-color: #FFFFFF;
    color: #2E2E2E;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border: ${props => props.selected ? '3px #0083C1 solid' : 'none'};
    padding: 12px;
    margin: 10px 0;
    ${props => props.disabled ? `
        pointer-events: none;
        opacity: 0.6;
        background-color: #F3F3F3;
    ` : undefined}

    img {
        width: 50px;
        height: 50px;
    }
`;

const OptionCard: React.FC<{
    value: string, 
    selected?: boolean, 
    onClick?: (val: string) => void, 
    children: React.ReactNode,
    disabled?: boolean;
}> = (props: {value: string, selected?: boolean, onClick?: (val: string) => void, children: React.ReactNode, disabled?: boolean}) => {
    return (
        <OptionCardWrapper {...props} onClick={()=>props.onClick?.(props.value)}>
            {props.children}
        </OptionCardWrapper>
    )
}

interface DrawerProps {
    onClose: () => void;
    disabledItems: string[];
    selectedPackage: TrainingPackage[];
    addKra: (kra: KraConfig) => void;
}

const ExamItemSelectDrawer: React.FC<DrawerProps> = (props: DrawerProps) => {

    const { formatMessage } = useIntl();
    const { getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const [examItem, setExamItem] = useState<string>();
    const [showKRADrawer, setShowKRADrawer] = useState(false);

    const examinationOptions = getOptions('KeeogoExamination').map((option, i) => (
        <OptionCard key={i} value={option.code} disabled={props.disabledItems.includes(option.code)}>
            <img src={ImageMapping.KRAExamination[option.code]} className='mr-20'/>
            <div>{option.name}</div>
        </OptionCard>
    ))

    return (
        <Drawer
            visible
            closable
            maskClosable
            onClose={props.onClose}
            width='25vw'
            title={formatMessage({id:'KRA_REPORT'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect text--bold" disabled={!examItem} onClick={()=>setShowKRADrawer(true)} >
                        {formatMessage({id:'NEXT_UPPERCASE'})}
                    </Button>
                </div>
            }
        >
            {showKRADrawer && <KRASelectDrawer addKra={props.addKra} selelctedPackage={props.selectedPackage} onClose={()=>setShowKRADrawer(false)} closeLastDrawer={props.onClose} examItem={examItem ?? ''} />}

            <div className='text-24 mb-20 text--500' style={{color: '#000000'}}>
                {formatMessage({id:'EXAMINATION_ITEM'})}
            </div>

            <RadioWrapper onChange={val=>setExamItem(val)} middleElement={<div className='d-flex is--column width--full'></div>}>
                {examinationOptions}
            </RadioWrapper>
        </Drawer>
    )
}

export default ExamItemSelectDrawer;
