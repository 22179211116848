import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { KRAdata } from 'api/record';
import Label from 'components/label/label';
import { Row, Col } from 'antd';
import moment from 'moment';
import { icon_play_btn_blue } from 'components/image';
import KRADetail from './KRADetail';

const Wrapper = styled.div`
    background-color: #FFFFFF;
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

interface BlockViewProps {
    data: KRAdata;
    classData: {
        treatmentName: string;
        classNum: string;
        treatmentDate: string;
        treatmentHour: string;
    }
}

const KRABlockView: React.FC<BlockViewProps> = ({ data, classData }: BlockViewProps) => {

    const { formatMessage } = useIntl();
    const [showKraDetail, setShowKraDetail] = useState(false);
    const [kraDetailPortal, setKraDetailPortal] = useState<React.ReactPortal>();

    useEffect(()=>{
        if(showKraDetail){
            setKraDetailPortal(
                createPortal(
                    <KRADetail kraConfigId={data.id} classData={classData} onClose={()=>setShowKraDetail(false)}/>,
                    document.body
                )
            );
        }
        else{
            setKraDetailPortal(undefined);
        }
    }, [showKraDetail]);

    return (
        <Wrapper>
            {kraDetailPortal}
            <div onClick={()=>setShowKraDetail(true)}>
                <Row gutter={24} className='width--full mb-10'>
                    <Col span={8}>
                        <Label 
                            name={formatMessage({id:'TIME'})} 
                            text={data.timeConsumed ? moment(data.timeConsumed).format('mm:ss') : '--'}
                        />
                    </Col>
                    <Col span={8}>
                        <Label 
                            name={formatMessage({id:'ASSIST_DEVICES'})} 
                            text={data.assistDevice.length > 0 ? formatMessage({id:'YES'}) : formatMessage({id:'NO'})}
                        />
                    </Col>
                    <Col span={8}>
                        <Label 
                            name={formatMessage({id:'LEVEL'})} 
                            text={data.level ? ( data.level === 'Free Mode' ? 'F' : data.level[data.level.length - 1]) : '--'}
                        />
                    </Col>
                </Row>
                <Row gutter={24} className='width--full'>
                    <Col span={16}>
                        <Label 
                            name={formatMessage({id:'USER_PROFILE'})}
                            text={data.profile}
                        />
                    </Col>
                    {data.video && 
                    <Col span={8}>
                        <Label 
                            name={formatMessage({id:'VIDEO'})}
                            text={<img src={icon_play_btn_blue}/>}
                        />
                    </Col>}
                </Row>
            </div>
        </Wrapper>
    )
}

export default KRABlockView;
