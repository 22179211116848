import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Radio } from 'antd';
import { Chart, registerables, ChartDataset, TooltipOptions, ChartTypeRegistry } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getCOGData, COGData } from 'api/record';
import { GlobalContext } from '../../../../../index';
import { _DeepPartialObject } from 'chart.js/types/utils';
import distinctColors from 'distinct-colors';
import moment from 'moment';

Chart.register(...registerables);

const StyledRadio = styled(Radio.Button)`
    background-color: #F3F3F3;
    border: none !important;
    font-weight: 500;
    padding: 0 1.2vw;

    &.ant-radio-button-wrapper-checked {
        background-color: #2E63A3!important;
        color: #FFFFFF;

        &::before {
            display: none;
        }

        &:hover {
            color: #FFFFFF;
        }
    }

    &.ant-radio-button-wrapper-disabled:hover {
        color: rgba(0, 0, 0, .25);
    }

    &:hover {
        color: rgba(0, 0, 0, 0.65);
    }
`;

interface ChartConfig {
    chartType: keyof ChartTypeRegistry;
    labels: string[];
    dataset: ChartDataset[];
    scaleTitleX: string;
    scaleTitleY: string;
    tooltip: _DeepPartialObject<TooltipOptions<keyof ChartTypeRegistry>>;
    additionalScale?: object;
}

export interface ChartData {
    level: string;
    assistDevice: string[];
    configId: number;
    flexionHipAngleLeft: number;
    flexionHipAngleRight: number;
    extensionHipAngleLeft: number;
    extensionHipAngleRight: number;
    flexionKneeAngleLeft: number;
    flexionKneeAngleRight: number;
    extensionKneeAngleLeft: number;
    extensionKneeAngleRight: number;
    timeStep: number;
    avgStepLength: number;
    kraDateTime: string;
}

interface KRAChartProps {
    canvasId: string;
    examItem: string;
    data: ChartData[];
}

const KRAChartTab: React.FC<KRAChartProps> = (props: KRAChartProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;

    enum ChartType {
        L_HIP,
        R_HIP,
        L_KNEE,
        R_KNEE,
        COG,
        SPEED
    };

    const [chartType, setChartType] = useState(ChartType.L_HIP);
    const [chartInstance, setChartInstance] = useState<Chart<any>>();
    const [cogData, setCogData] = useState<COGData[]>();

    const barChartConfig = (dataPropName: string[]): ChartConfig => ({
        chartType: 'bar',
        labels: props.data.map(d=>d.kraDateTime),
        dataset: [
            {
                data: props.data.map(d=>Math.abs(d[dataPropName[0]])),
                backgroundColor: '#FFBB32',
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    color: '#FFFFFF',
                    formatter: (value, context) => {
                        let curData = props.data[context.dataIndex];
                        return (curData.assistDevice.length > 0 ? 'A/' : '') + (curData.level === 'Free Mode' ? 'F' : curData.level.slice(-1))
                    }
                }
            },
            {
                data: props.data.map(d=>-Math.abs(d[dataPropName[1]])),
                backgroundColor: 'rgba(255, 187, 50, 0.7)',
                datalabels: {
                    display: false
                }
            }
        ],
        scaleTitleX: formatMessage({id:'DATE'}),
        scaleTitleY: formatMessage({id:'ANGLE'}),
        tooltip: {
            mode: 'index',
            callbacks: {
                label: tooltipItem => ` ${formatMessage({id:tooltipItem.datasetIndex > 0 ? 'EXTENSION' : 'FLEXION'})}: ${tooltipItem.dataset.data[tooltipItem.dataIndex]}°`,
                beforeFooter: tooltipItem => {
                    let dataset1 = tooltipItem[0].dataset.data as number[];
                    let dataset2 = tooltipItem[1].dataset.data as number[];
                    return `     ${formatMessage({id:'TOTAL'})}: ${dataset1[tooltipItem[0].dataIndex]+dataset2[tooltipItem[1].dataIndex]}°\n`;
                },
                footer: tooltipItem => {
                    return `● ${formatMessage({id:'ASSISTIVE_DEVICE'})}\n   ${props.data[tooltipItem[0].dataIndex].assistDevice.map(a=>nameMapping[a]).join('\n   ')}`
                }
            }
        }
    });

    const colors = distinctColors({count: cogData?.length, lightMin: 50, chromaMin: 30})

    const chartConfig: {
        [key: number]: ChartConfig;
    } = {
        [ChartType.L_HIP]: barChartConfig(['flexionHipAngleLeft', 'extensionHipAngleLeft']),
        [ChartType.R_HIP]: barChartConfig(['flexionHipAngleRight', 'extensionHipAngleRight']),
        [ChartType.L_KNEE]: barChartConfig(['flexionKneeAngleLeft', 'extensionKneeAngleLeft']),
        [ChartType.R_KNEE]: barChartConfig(['flexionKneeAngleRight', 'extensionKneeAngleRight']),
        [ChartType.COG]: {
            chartType: 'line',
            labels: Array(Math.max(...((cogData??[]).map(d=>d.HipCOG.length).length>0?(cogData??[]).map(d=>d.HipCOG.length):[0]))).fill(0).map((d, i)=>(i+1).toString()),
            dataset: (cogData??[]).map((data, i)=>({
                data: data.HipCOG,
                pointRadius: 0,
                borderWidth: 1,
                borderColor: colors[i].hex(),
                backgroundColor: colors[i].hex(),
                datalabels: {
                    display: false
                }
            })),
            scaleTitleX: formatMessage({id:'TIME'}),
            scaleTitleY: formatMessage({id:'COG_FULLNAME'}),
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: tooltipItem => `${formatMessage({id:'TIME'})}: ${(tooltipItem[0].dataIndex+1).toString()}`,
                    label: tooltipItem => ` ${moment(cogData?.[tooltipItem.datasetIndex].time[tooltipItem.dataIndex], 'YYYYMMDD_HHmmssSSS').format('YYYY-MM-DD HH:mm:ss.SSS')}:   ${tooltipItem.dataset.data[tooltipItem.dataIndex]}`
                }
            }
        },
        [ChartType.SPEED]: {
            chartType: 'bar',
            labels: props.data.map(d=>d.kraDateTime),
            dataset: [
                {
                    type: 'bar',
                    data: props.data.map(d=>Math.abs(d.timeStep)),
                    backgroundColor: '#FFBB32',
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                        color: '#FFFFFF',
                        formatter: (value, context) => {
                            let curData = props.data[context.dataIndex];
                            return (curData.assistDevice.length > 0 ? 'A/' : '') + (curData.level === 'Free Mode' ? 'F' : curData.level.slice(-1))
                        }
                    },
                    order: 2
                },
                {
                    type: 'line',
                    data: props.data.map(d=>Math.abs(d.avgStepLength)),
                    backgroundColor: '#0A95FF',
                    borderColor: '#0A95FF',
                    pointRadius: 2,
                    borderWidth: 1,
                    datalabels: {
                        display: false
                    },
                    yAxisID: 'rightAxisY',
                    order: 1
                }
            ],
            scaleTitleX: formatMessage({id:'DATE'}),
            scaleTitleY: formatMessage({id:'SPEED'}),
            tooltip: {
                mode: 'index',
                callbacks: {
                    label: tooltipItem => ` ${formatMessage({id:tooltipItem.datasetIndex > 0 ? 'AVG_SPEED' : 'AVG_LENGTH'})}: ${tooltipItem.dataset.data[tooltipItem.dataIndex]} ${formatMessage({id:tooltipItem.datasetIndex > 0 ? 'S_STEP' : 'CM_STEP'})}`,
                    footer: tooltipItem => {
                        return `\n● ${formatMessage({id:'ASSISTIVE_DEVICE'})}\n   ${props.data[tooltipItem[0].dataIndex].assistDevice.map(a=>nameMapping[a]).join('\n   ')}`
                    }
                }
            },
            additionalScale: {
                rightAxisY: {
                    grid: {
                        color: 'transparent',
                        borderColor: '#909090',
                        tickColor: '#909090'
                    },
                    ticks: {
                        color: '#909090',
                        callback: (value: number) => Math.abs(value)
                    },
                    position: 'right'
                }
            }
        }
    }

    useEffect(()=>{
        if(chartType === ChartType.COG) {
            Promise.all(props.data.map(config=>
                getCOGData(
                    config.configId
                )
            )).then(values=>{
                setCogData(values.map(res=>res.data.data.KRADetailRecords));
            }).catch(err=>{
                console.error(err);
            });
        }
        else {
            drawChart(chartType);
        }
    }, [chartType, props.data]);

    useEffect(()=>{
        if(cogData){
            drawChart(chartType);
        }
    }, [cogData]);

    const drawChart = (type: ChartType) => {
        if(chartInstance){
            chartInstance.destroy();
        }
        
        const canvas = document.getElementById(props.canvasId);
        if(canvas){
            const config = chartConfig[type];
            const chart = new Chart((canvas as HTMLCanvasElement), {
                type: config.chartType,
                data: {
                    labels: config.labels,
                    datasets: config.dataset
                },
                plugins: [ChartDataLabels],
                options: {
                    layout: {
                        padding: 20
                    },
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                color: 'transparent',
                                borderColor: '#909090',
                                tickColor: '#909090'
                            },
                            ticks: {
                                color: '#909090'
                            },
                            title: {
                                display: true,
                                text: config.scaleTitleX,
                                color: '#909090'
                            }
                        },
                        y: {
                            grid: {
                                color: context => {
                                    if(context.tick.value === 0){
                                        return '#909090';
                                    }
                                    return 'transparent';
                                },
                                borderColor: '#909090',
                                tickColor: '#909090'
                            },
                            ticks: {
                                color: '#909090',
                                callback: value => Math.abs(value as number)
                            },
                            title: {
                                display: true,
                                text: config.scaleTitleY,
                                color: '#909090'
                            }
                        },
                        ...config.additionalScale
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: config.tooltip
                    }
                }
            });
            setChartInstance(chart);
        }
    }
    
    return (
        <div>
            <Radio.Group className='mb-20' value={chartType} onChange={e=>setChartType(e.target.value)}>
                <StyledRadio value={ChartType.L_HIP}>{formatMessage({id:'L_HIP'})}</StyledRadio>
                <StyledRadio value={ChartType.R_HIP}>{formatMessage({id:'R_HIP'})}</StyledRadio>
                <StyledRadio value={ChartType.L_KNEE}>{formatMessage({id:'L_KNEE'})}</StyledRadio>
                <StyledRadio value={ChartType.R_KNEE}>{formatMessage({id:'R_KNEE'})}</StyledRadio>
                <StyledRadio value={ChartType.COG}>{formatMessage({id:'COG'})}</StyledRadio>
                <StyledRadio value={ChartType.SPEED} disabled={props.examItem==='KEN01'}>{formatMessage({id:'SPEED'})}</StyledRadio>
            </Radio.Group>
            <div style={{width: '100%', padding: '20px', backgroundColor: '#000000'}}>
                <canvas id={props.canvasId} width='100%' style={{backgroundColor: '#000000'}}/>
            </div>
        </div>
    )
}

export default KRAChartTab;
