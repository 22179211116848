import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Drawer from 'components/drawer/drawer';
import Button from 'components/button/button';
import { Modal } from 'antd';
import { getPrescription, Prescription } from 'api/summary';
import { GlobalContext } from '../../../../index';
import ImageMapping from 'constants/imageMapping';
import moment from 'moment';

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        padding-top: 24px;
        padding-bottom: 24px;
    }
`;

interface PrescriptionProps {
    onClose: () => void;
    trainingType: string;
    selectedPackages: number[];
}

const PrescriptionDrawer: React.FC<PrescriptionProps> = (props: PrescriptionProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping } = useContext(GlobalContext).dropdownOptionCtx;
    const [packageList, setPackageList] = useState<Prescription[]>([]);

    useEffect(()=>{
        getPrescription(
            props.selectedPackages
        ).then(res=>{
            setPackageList(res.data.data);
        }).catch(err=>{
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = err?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        });
    }, []);

    const packagePrescriptions = packageList.filter((p, i, arr)=>arr.findIndex(k=>k.packageName===p.packageName)===i).map(pack=>(
        <div className='mb-15 d-flex is--column'>
            <div className='text-16 text--bold' style={{color: '#2E2E2E'}}>{pack.packageName}</div>
            <div className='mb-10'>{formatMessage({id:'PURCHASE_DATE'})}: {moment(pack.createdAt).format('DD/MM/YYYY')}</div>
            <div className='width--full padding-10 d-flex is--column' style={{boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)'}}>
                {packageList.filter(d=>d.packageName===pack.packageName)
                    .map(d=>({...d, notes: d.notes?.filter(k=>k.trainingTypeCode===props.trainingType)}))
                    // .filter(d=>d.notes&&d.notes.length>0)
                    .map(n=>(
                    <div>
                        <div className='text-16 text--bold'>{`${formatMessage({id:'CLASS'})} ${n.classNum.padStart(2, '0')}`}</div>
                        {(n.notes && n.notes.length > 0) ? <div className='d-flex'>
                            <div className='text-12'>
                                {`${n.notes[0].reps} ${formatMessage({id:'REPS'})} - ${n.notes[0].sets} ${formatMessage({id:'SETS'})}`}
                            </div>
                            {n.notes.slice(1).map(s=>(
                                <div className='text-12' style={{borderLeft: '2px #E3E3E3 solid', paddingLeft: '2px'}}>
                                    {`${s.reps} ${formatMessage({id:'REPS'})}-${s.sets} ${formatMessage({id:'SETS'})}`}
                                </div>
                            ))}
                        </div> : <div className='d-flex'>{formatMessage({id:'NO_TRAINING'})}</div>}
                    </div>
                ))}
            </div>
        </div>
    ))

    return (
        <StyledDrawer
            visible
            closable
            maskClosable
            onClose={props.onClose}
            width='30vw'
            title={formatMessage({id:'PRESCRIPTION_OVERVIEW'})}
            footer={
                <div className="pull--right rect">
                    <Button className="is--primary rect text--bold" onClick={props.onClose} >
                        {formatMessage({id:'OK'})}
                    </Button>
                </div>
            }
        >
            <div className='d-flex is--column'>
                <div className='d-flex is--row mb-10'>
                    <img src={ImageMapping.TrainingItem[props.trainingType]}/>
                    <div className='ml-10 text--bold text-20' style={{color: '#2E2E2E'}}>{nameMapping[props.trainingType]}</div>
                </div>
                {packagePrescriptions}
            </div>
        </StyledDrawer>
    )
}

export default PrescriptionDrawer;
