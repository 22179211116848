import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Plan from './Plan';
import Button from 'components/button/button';
import { PlusOutlined, LeftOutlined } from '@ant-design/icons';
import { icon_archive } from 'components/image';
import AddNewPlanDrawer from './AddNewPlanDrawer';
import { TreatmentProps } from './TreatmentCard';
import { GlobalContext } from '../../../../index';
import TreatmentCard from './TreatmentCard';
import { Row, Col, Modal,Checkbox} from 'antd';
import moment from 'moment';
import { icon_edit_blue, icon_add_new_plan } from 'components/image';
import EditTreatmentDrawer from './EditTreatmentDrawer';
import { cancelTreatment, getTreatment,getTreatmentDetail, editTreatment } from 'api/case';
import { getListRecord,getOneRecord } from 'api/record';
import { apiCaller } from 'service/apiCaller';
import usePermission from 'hooks/usePermission';
import { checkIfAvailable } from 'service/permission';
import { createRecord } from 'api/record';
import SuspendTreatmentDrawer from './SuspendTreatmentDrawer';
import ImageMapping from 'constants/imageMapping';
import { updateRecord, TrainingNote } from 'api/record';
import { Form } from 'components/form';
import PromptNoteModal, { PromptRecord } from '../PromptNoteModal';

const Wrapper = styled.div`
    width: 100%;
    background-color: white;
    padding: 15px;

    .Title {
        font-size: 1.3rem;
        color: black;
        font-weight: bolder;
    }
`;

interface Props {
    caseId: number;
    data: any[];
    refetch: Function;
    orgId: number;
    jumpToRecordTab: (recordId: number) => void;
    remarkData: any[];
}

const PlanTab: React.FC<Props> = (props: Props) => {
    const [pageContent, setPageContent] = useState<JSX.Element>(<div></div>);
   
    
    useEffect(()=>{
        props.refetch({id: props.caseId, inFinish: false});
    }, []);

    useEffect(()=>{
        if(pageContent.key === 'archive'){
            setPageContent(<ArchivePage key='archive' setPageContent={setPageContent} {...props}/>);
        }
        else if(props.data.length==0){
            setPageContent(<NoData setPageContent={setPageContent} {...props} />);
        }
        else{
            setPageContent(<PlanPage setPageContent={setPageContent} {...props} />);
        }
    }, [props.data]);

    return withData(pageContent, props);
}

const StyledNoData = styled.div`
    height: 100%;

    .noData {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
            width: 100px;
            height: 100px;
        }

        .desc {
            font-size: 18px;
            color: #909090;
        }

        .newPlanBtn {
            font-size: 32px;
            color: #0083C1;
            font-weight: bold;
            margin-top: 20px;
        }
    }
`;

interface PageProps {
    setPageContent: React.Dispatch<React.SetStateAction<JSX.Element>>;
}

interface NoDataProps extends Props, PageProps {}

const NoData: React.FC<NoDataProps> = (props: NoDataProps) => {
    
    const { formatMessage } = useIntl();
    const [showAddPlanDrawer, setShowAddPlanDrawer] = useState(false);
    const [permission] = usePermission('Plan');
    
    return (
        <Wrapper style={{height: '100%'}}>
            <StyledNoData>
                <div className='noData'>
                    {showAddPlanDrawer&&<AddNewPlanDrawer firstPlan refetch={()=>{props.refetch();props.setPageContent(<PlanPage {...props} />);}} onClose={() => setShowAddPlanDrawer(false)} defaultClassNum={10} caseId={props.caseId} orgId={props.orgId} />}
                    <img src={icon_add_new_plan} alt="add new plan" />
                    <div className="desc">
                        {formatMessage({id:'NO_PLAN_DATA'})}
                    </div>
                    {checkIfAvailable(permission, 'AddNewPlan') && <Button
                        className="newPlanBtn"
                        type='link'
                        icon={<PlusOutlined />}
                        onClick={() => setShowAddPlanDrawer(true)}
                    >
                        {formatMessage({id:'ADD_PLAN'})}
                    </Button>}
                </div>
            </StyledNoData>
        </Wrapper>
    )
}

const StyledPlanPage = styled.div`
    .planTitle {
        color: #2E2E2E;
        font-size: 20px;
        font-weight: bold;
    }

    .plansContainer {
        display: flex;
        margin-bottom: 15px;

        &.trial {
            background-color: #F3F3F3;
            padding: 15px;
            width: 100%;
            display: flex;
            flex-direction: column;

            .ant-row {
                width: 100%;
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }

        .ant-row {
            width: 100%;
        }
    }
`;

interface PlanPageProps extends PageProps, Props {
    
}

const PlanPage: React.FC<PlanPageProps> = (props: PlanPageProps) => {

    const { formatMessage } = useIntl();
    const { nameMapping, getCode } = useContext(GlobalContext).dropdownOptionCtx;
    const [permission] = usePermission('Plan');
    const [promptModalSetting, setPromptModalSetting] = useState({
        show: false,
        recordRemark: ''
    });

    useEffect(()=>{
        if(props.remarkData.length > 0){
            const promptRecord = JSON.parse(localStorage.getItem('promptRecord') || '{}') as PromptRecord;
            const record = promptRecord[localStorage.getItem('uid') || '']?.find(d=>d.caseId===props.caseId);
            if(!record){
                setPromptModalSetting({
                    show: true,
                    recordRemark: props.remarkData[props.remarkData.length-1].recordRemark
                });
            }
            else if(moment(props.remarkData[props.remarkData.length-1].createdAt).diff(record.lastPrompt) > 0){
                setPromptModalSetting({
                    show: true,
                    recordRemark: props.remarkData[props.remarkData.length-1].recordRemark
                });
            }
        }
    }, [props.remarkData]);

    const [showAddPlanDrawer, setShowAddPlanDrawer] = useState(false);
    const trialPlan = props.data.filter((plan: any)=>getCode('ClassType', plan.treatment_course.classTypeCode)=='CLA01'&&plan.plan_time.length>0).sort((a,b)=>moment(`${a.plan_time[0].treatmentDate} ${a.plan_time[0].treatmentHour}`, 'YYYY/MM/DD HH:mm').diff(moment(`${b.plan_time[0].treatmentDate} ${b.plan_time[0].treatmentHour}`, 'YYYY/MM/DD HH:mm')));
    const formalPlans = props.data.filter((plan: any)=>getCode('ClassType', plan.treatment_course.classTypeCode)=='CLA02');

    const trialPlanCard = [];
    for(let i=0; i<trialPlan.length; i+=4){
        trialPlanCard.push(
            <Row gutter={24}>
                {
                    trialPlan.slice(i, i+4).map(plan=>(
                        <Col span={6}>
                            <TreatmentCard 
                                id={plan.plan_time[0].id}
                                name={nameMapping['CLA01']} 
                                treatmentDate={plan.plan_time[0].treatmentDate} 
                                treatmentHour={plan.plan_time[0].treatmentHour} 
                                treatmentOrg={plan.plan_time[0].abbreviation}
                                classTypeCode={plan.treatment_course.classTypeCode}
                                treatmentTypeCode={plan.treatment_course.treatmentTypeCode} 
                                treatmentDetailStatusCode={plan.plan_time[0].treatmentDetailStatusCode}
                                isNext={getCode('TreatmentDetailStatus', plan.plan_time[0].treatmentDetailStatusCode)=='TDS02'}
                                onClick={(treatment: TreatmentProps)=>{props.setPageContent(
                                    <TreatmentDetailPage {...{...props, ...treatment, setPageContent: props.setPageContent, planIsFinish: plan.inFinish, planId: plan.id}} />
                                )}}
                            />
                        </Col>
                    ))
                }
            </Row>
        );
    }

    return (
        <Wrapper>
            {promptModalSetting.show && <PromptNoteModal caseId={props.caseId} recordRemark={promptModalSetting.recordRemark} onClose={()=>setPromptModalSetting({show: false, recordRemark: ''})}/>}

            <StyledPlanPage>
                {showAddPlanDrawer&&<AddNewPlanDrawer refetch={props.refetch} onClose={() => setShowAddPlanDrawer(false)} defaultClassNum={10} caseId={props.caseId} orgId={props.orgId} />}

                <div className="d-flex is--space-between v--center width--full" style={{position: 'sticky', top: 0, backgroundColor: '#FFFFFF', marginTop: '-15px', padding: '15px 0', zIndex: 1000 } }>
                    <div className="progress">
                        <b>{formalPlans.length|| '-'}</b> {' '+formatMessage({id:'IN_PGOGRESS'})}
                    </div>
                    <div className="btns">
                        {checkIfAvailable(permission, 'Archive') && <Button
                            className="rect ml-5 is--primary rect"
                            icon={<img src={icon_archive} />}
                            onClick={() => {props.refetch({id: props.caseId, inFinish: true});props.setPageContent(<ArchivePage key='archive' {...{...props, setPageContent: props.setPageContent}}/>)}}
                            style={{backgroundColor: '#F3F3F3', border: 'none' }}
                        />}
                        {checkIfAvailable(permission, 'AddNewPlan') && <Button
                            className="rect ml-5 is--primary rect"
                            icon={<PlusOutlined />}
                            onClick={() => setShowAddPlanDrawer(true)}
                        >
                            {formatMessage({id:'ADD_PLAN'})}
                        </Button>}
                     
                    </div>
                </div>
                {trialPlan.length>0&&<div>
                    <div className="planTitle">
                        {nameMapping['CLA01']}
                    </div>
                    <div className="plansContainer trial">
                        {trialPlanCard}
                    </div>
                </div>}
    

                {formalPlans.length>0&&<div>
                    <div className="planTitle">
                        {nameMapping['CLA02']}
                    </div>
                    {
                       
                        formalPlans.map((plan: any)=>(
                            <div className="plansContainer">
                                <Plan
                                    orgId={props.orgId}
                                    finishLength={props.data.filter((plan: any)=>plan.inFinish).length}
                                    title={plan.treatment_course.name}
                                    startDate={moment(plan.treatment_course.createDateTime).format('DD/MM/YYYY')}
                                    isFinish={plan.inFinish}
                                    classNum={plan.treatment_course.classNum}
                                    freeClassNum={plan.freeClassNum}
                                    treatmentType={plan.treatment_course.treatmentTypeCode}
                                    classType={plan.treatment_course.classTypeCode}
                                    treatmentData={plan.plan_time}
                                    refetch={props.refetch}
                                    planId={plan.id}
                                    caseId={props.caseId}
                                    planModifiedRecord={plan.plan_modify}
                                    onCheckDetail={(treatment: TreatmentProps)=>{props.setPageContent(
                                        <TreatmentDetailPage {...{...props, ...treatment, setPageContent: props.setPageContent, planIsFinish: plan.inFinish, planId: plan.id}} />
                                    )}}
                                    key={Date.now().toString()}
                                />
                            </div>
                        ))
                    }
                </div>}
            </StyledPlanPage>
        </Wrapper>
    )
}

const StyledArchivePage = styled.div`
    .plansContainer {
        display: flex;
        margin-bottom: 15px;

        .ant-row {
            width: 100%;
        }
    }
`;

interface ArchivePageProps extends PageProps, Props {
    
}

const ArchivePage: React.FC<ArchivePageProps> = (props: ArchivePageProps) => {

    const { formatMessage } = useIntl();
    useEffect(()=>{
        props.refetch({inFinish: true,desc:true});
    }, []);
      return (
        <Wrapper>
            <StyledArchivePage>
                <div className="d-flex v--center mb-10">
                    <LeftOutlined onClick={()=>{props.refetch();props.setPageContent(<PlanPage {...{...props, setPageContent: props.setPageContent}}/>);}}/>
                    <div className="Title ml-15">
                        {formatMessage({id:'ARCHIVE'})}
                    </div>
                </div>
                {
                   props.data.filter((plan: any)=>plan.inFinish).map((plan: any)=> (
                        <div className="plansContainer">
                            <Plan
                                orgId={props.orgId}
                                title={plan.treatment_course.name}
                                startDate={moment(plan.treatment_course.createDateTime).format('DD/MM/YYYY')}
                                isFinish={plan.inFinish}
                                classNum={plan.treatment_course.classNum}
                                freeClassNum={plan.freeClassNum}
                                treatmentType={plan.treatment_course.treatmentTypeCode}
                                classType={plan.treatment_course.classTypeCode}
                                treatmentData={plan.plan_time}
                                refetch={props.refetch}
                                planId={plan.id}
                                caseId={props.caseId}
                                planModifiedRecord={plan.plan_modify}
                                onCheckDetail={(treatment: TreatmentProps)=>{props.setPageContent(
                                    <TreatmentDetailPage {...{...props, ...treatment, setPageContent: props.setPageContent, planIsFinish: plan.inFinish, planId: plan.id}} />
                                )}}
                                key={Date.now().toString()}
                                finishLength={props.data.filter((plan: any)=>plan.inFinish).length}
                                
                            />
                        </div>
                    ))
                }
            </StyledArchivePage>
        </Wrapper>
    )
}

const StyledTreatmentDetailPage = styled.div`
    .treatment {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid #707070;
        margin-bottom: 100px;

        .treatmentInfo {
            display: flex;

            .icon {
                height:160px;
                aspect-ratio: 1.78;
                margin-right: 20px;

                img {
                    object-fit: contain;
                    border-radius: 0.5rem;
                }
            }

            .classInfo {
                display:flex;
                flex-direction: column;
                align-items: flex-start;

                .date {
                    font-size: 15px;
                }

                .time {
                    font-size: 24px;
                    font-weight: bold;
                }

                .detail {
                    margin-top: 15px;
                    display:flex;
                    flex-direction: column;

                    .detailTitle {
                        font-size: 14px;
                        color: #909090;
                        font-weight: bold;
                    }

                    .hr {
                        height: 0;
                        border-top: 0.5px solid #707070;
                        margin: 3px 0;
                    }

                    .details {
                        font-size: 20px;
                        font-weight: bold;

                        .topic {
                            color: #909090;
                        }
                    }
                }
            }
        }

        .control {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 40px;

            .edit {
                text-align: right;
            }

            .trainingRecord {
                text-align: right;
            }

            .addRecord {
                text-align: right;
            }

            .classOperation {
                button {
                    font-weight: bold;
                }
            }
        }
    }
`;

interface TreatmentDetailPageProps extends PageProps, TreatmentProps, Props {
    planName?: string;
    planIsFinish: boolean;
    planId: number;
}

const TreatmentDetailPage: React.FC<TreatmentDetailPageProps> = (props: TreatmentDetailPageProps) => {
    const { formatMessage } = useIntl();
    const [permission] = usePermission('Plan');
    const { getCode, nameMapping,getOptions } = useContext(GlobalContext).dropdownOptionCtx;
    const [showEditTreatmentDrawer, setShowTreatmentDrawer] = useState(false);
    const [showSuspendTreatmentDrawer, setShowSuspendTreatmentDrawer] = useState(false);
    const [treatmentSuspend,setTreatmentSuspend]= useState("");
    const [treatmentDetailState,seTreatmentDetailState]= useState("");
    const [treatmentDetailTrarining,seTreatmentDetailTrarining]= useState(0);
    const [trainingNotes, setTrainingNotes] = useState([]);
    const [form] = Form.useForm();
    const [allRecord, setAllRecord] = useState<any[]>([]);
    // const treatmentTypeImg = {
    //     TRE01: class_keeogo,
    //     TRE02: class_mirror_hand,
    //     TRE03: class_others
        
    // };
    const treatmentDate = moment(props.treatmentDate);

    const [treatmentData, setTreatmentData] = useState({
        treatmentDate: moment(props.treatmentDate),
        treatmentHour: props.treatmentHour
    })
    const [newNotes, setNewNotes] = useState<TrainingNote[]>([]);

    useEffect(()=>{
        getListRecord({
            personalInfoBasicId: props.caseId,
            trainingTypeCode:"",
            desc: false,
        }).then(res=>{
            setAllRecord(res.data.data);
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }, []);
    const constclickBack=()=>{
        props.refetch()
        props.setPageContent(props.planIsFinish?<ArchivePage key='archive' {...{...props, setPageContent: props.setPageContent}}/>:<PlanPage {...{...props, setPageContent: props.setPageContent}}/>)
    }

    const handleSave = () => {
        const record = allRecord.find(d=>d.treatmentPlanID===props.id);
        form.validateFields().then((e) => {
            
            const success = (res: any) => {
                Modal.success({
                    maskClosable: true,
                    title: 'Success',
                    onCancel: () => {
                        setTrainingNotes(res.data.data.notes)
                        // props.refetch();
                        getTreatmentDetail(props.id).then(res=>{
                            seTreatmentDetailTrarining(res.data.data.trainingId)
                            seTreatmentDetailState(res.data.data.treatmentDetailStatusCode)
                        }).catch(error=>{
                            console.error(error);
                            throw(error);
                        });
                        const newRecord = allRecord.find(d=>d.treatmentPlanID===props.id);
                        var checkRecord: any
                        if(newRecord!==undefined||treatmentDetailTrarining!==0){
                            checkRecord=(newRecord===undefined)?treatmentDetailTrarining:newRecord.trainingId
                        }
                        if (checkRecord>= 0) {
                            getOneRecord(checkRecord).then(res=>{
                                setRecordHome(res.data.data.homeProgram);
                                setRecordRemark(res.data.data.remark);
                                setRecordNote(res.data.data.notes)
                            }).catch(error=>{
                                console.error(error);
                                throw(error);
                            });
                        }
                    }
                });
               
            };
            const reject = (error: any) => {
                console.error(error);
                throw (error);
            }
            if(record?.trainingId||treatmentDetailTrarining!==0){
                    apiCaller(
                        updateRecord,
                        {
                            personalTrainingRecordId:(record?.trainingId)?record.trainingId:treatmentDetailTrarining,
                            notes: newNotes,
                            device: 'web',
                            status: false,
                        },
                        success,
                        reject
                    );
            }
        })
    }
   
    const aftterSuspend = () => {
        getTreatmentDetail(props.id).then(res=>{
            setTreatmentData({
                treatmentDate: moment(res.data.data.treatmentDate),
                treatmentHour: res.data.data.treatmentHour
            });
            seTreatmentDetailState(res.data.data.treatmentDetailStatusCode)
            setTreatmentSuspend(res.data.data.planTimeMomo)
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }
    const afterEditTreatmentDetail = () => {
        // const treatment = res.data.data.find((d: any)=>d.id===props.planId).plan_time.find((t: any)=>t.id===props.id);
        getTreatmentDetail(props.id).then(res=>{
            setTreatmentData({
                treatmentDate: moment(res.data.data.treatmentDate),
                treatmentHour: res.data.data.treatmentHour
            });
           
        }).catch(error=>{
            console.error(error);
            throw(error);
        });
    }

  
    const handleCancel = () => {
        const success = (res: any) => {
            props.refetch();
            props.setPageContent(props.planIsFinish?<ArchivePage key='archive' {...{...props, setPageContent: props.setPageContent}}/>:<PlanPage {...{...props, setPageContent: props.setPageContent}}/>);
        }

        const reject = (error: any) => {
            console.error(error)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = error?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            })
        }

        apiCaller(
            cancelTreatment,
            props.id,
            success,
            reject
        );
    }

    const handleStartTraining = () => {
        if(props.treatmentTypeCode === nameMapping['TRE01']){ // Keeogo
            createRecord({
                personalInfoBasicId: props.caseId,
                personalTreatmentPlanDetailId: props.id
            }).then(res=>{
                getListRecord({
                    personalInfoBasicId: props.caseId,
                    trainingTypeCode:"",
                    desc: false,
                }).then(res=>{
                    setAllRecord(res.data.data);
                }).catch(error=>{
                    console.error(error);
                    throw(error);
                });
            }).catch(error=>{});
        }

        
        editTreatment({
            treatmentDetailStatusCode: 'TDS03',
            planDetailId: props.id,
            device: 'web'
        }).then(res=>{
            getTreatmentDetail(props.id).then(res=>{
                seTreatmentDetailState(res.data.data.treatmentDetailStatusCode)
                seTreatmentDetailTrarining(res.data.data.trainingId)
            }).catch(error=>{
                console.error(error);
                throw(error);
            });
        }).catch(error=>{
            console.error(error)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = error?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            });
        })
    }

    const moveToTraingRecord = () => {
        const record = allRecord.find(d=>d.treatmentPlanID===props.id);
        (record!==undefined)?props.jumpToRecordTab(record.trainingId):props.jumpToRecordTab(treatmentDetailTrarining);
    }
   
    const handleCompleted = () => {
        const success = (res: any) => {
            getTreatmentDetail(props.id).then(res=>{
                seTreatmentDetailState(res.data.data.treatmentDetailStatusCode)
            }).catch(error=>{
                console.error(error);
                throw(error);
            });
        }

        const reject = (error: any) => {
            console.error(error)
            Modal.error({
                maskClosable:true,
                title:'Error',
                content: <div style={{textAlign:'center'}}> 
                    {
                        (()=>{
                            const msg = error?.data?.detail
                            if(typeof msg === 'string'){
                                return msg
                            }else if(msg instanceof Array){
                                return msg.join('\n')
                            }else{
                                return JSON.stringify(msg)
                            }
                        })()   
                    }
                </div> 
            })
        }

        apiCaller(
            editTreatment, 
            {
                treatmentDetailStatusCode: 'TDS06',
                planDetailId: props.id,
                device: 'web'
            }, 
            success, 
            reject
        );
    }
    // var recordData = allRecord.find(d=>d.treatmentPlanID===props.id);
    //下面選項
    const CheckboxWrapper = styled.div`
    display: flex;
   
    align-items: center;
    
    height:100px
    width: 100%;
    overflow-x: scroll;
    
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;
    const CheckboxContainer = styled(Checkbox.Group)`
    display: block;
    .ant-checkbox-wrapper {
        margin: 20px 1px;

        .ant-checkbox {
            display: none;
        }

        span {
            padding:0.8rem 0.9rem;
            background-color: #F3F3F3;
            color: #000000;
            border-radius: 0.3rem;
            font-weight: normal;
            white-space: nowrap;
            margin-right:10px;
        }

        &.ant-checkbox-wrapper-checked {
            span {
                color: #FFFFFF;
                background-color: #2E63A3;
            }
        }
    }
    `;
    const Label = styled.label` 
                padding:0.8rem 0.9rem;
                background-color: #F3F3F3;
                color: #000000;
                border-radius: 0.3rem;
                font-weight: normal;
                white-space: nowrap;
                margin-right:10px;

    }
    `;
    const StyledTreatmentItem = styled.div`
        margin-top:-5.5rem;
        margin-left:9px;
        .trainingItem {
            color: #2E2E2E;
            font-size: 17px;
            font-weight: bold;
        }
        .saveButton{
            border-radius: 0.3rem;
            padding:0px 30px
            
        }
        .saveButton:disabled {
            color: rgba(0, 0, 0, 0.25) !important;
        }
    `;
    const [itemInpute,setItemInpute]= useState([]);
    const [recordHome,setRecordHome]= useState("");
    const [recordRemark,setRecordRemark]= useState("");
    const [recordNote,setRecordNote]= useState<TrainingNote[]>([]);
    const assistDeviceCheckbox = getOptions('TrainingType').map((d:any )=> ({ label: d.name, value: d.name }));
 
    const trainingItem = recordNote.map(item => (item.trainingTypeName));
   
    const Item = trainingItem.reduce((prev, cur)=>prev.concat(prev.includes(cur)?[]:[cur]), ([] as string[])).map(d =>
        <Label >{d}</Label>
    );
         
    const itemChange=(a:any)=>{
        setItemInpute(a);
        let newVal = newNotes.slice();
        for(let x=0;x<a.length;x++){
            newVal.push({
                trainingTypeCode: getCode('TrainingType',a[x]),
                trainingTypeName: a[x],
                profile: -1,
                reps:'0',
                sets:'0',
                mins: "",
                comments:"" ,
                assistDevice: []
            });
        }
        setNewNotes(a.map((d:any)=>({
            trainingTypeCode: getCode('TrainingType',d),
            profile: -1,
            reps:'0',
            sets:'0',
            mins: "",
            comments:"" ,
            assistDevice: []
        })));
    }

    const newRecord = allRecord.find(d=>d.treatmentPlanID===props.id);
    var checkRecord: any
    if(newRecord!==undefined||treatmentDetailTrarining!==null){
        checkRecord=(newRecord===undefined)?treatmentDetailTrarining:newRecord.trainingId
    }

    useEffect(() => {
            if (checkRecord>= 0) {
                getOneRecord(checkRecord).then(res=>{
                 
                    setRecordHome(res.data.data.homeProgram);
                    setRecordRemark(res.data.data.remark);
                    setRecordNote(res.data.data.notes)
                }).catch(error=>{
                    console.error(error);
                    throw(error);
                });
            }
    },[checkRecord])
    // useEffect(() => {
    //     if (props.createdRecord >= 0) {
    //         getOneRecord(
    //             props.createdRecord
    //         ).then(res=>{
    //             setPageContent(<RecordDetail {...props} {...res.data.data} recordData={res.data.data} onClose={() => any} setPageContent={setPageContent} />)
    //         }).catch(error=>{
    //             console.error(error);
    //             throw(error);
    //         });
    //     }
    // }, [props.createdRecord])
    const statusTodo = (
        <div className="control">
            <div className="edit">
                {checkIfAvailable(permission, 'EditClass') && <Button
                    className='rect'
                    icon={<img src={icon_edit_blue} className='mr-5' />}
                    onClick={()=>setShowTreatmentDrawer(true)}
                    style={{color: '#0083C1'}}
                >{formatMessage({id:'EDIT'})}</Button>}
            </div>
            <div className="classOperation">
                {checkIfAvailable(permission, 'CancelClass') && <Button
                    className='rect mr-20'
                    onClick={handleCancel}
                    type='link'
                    style={{color: '#2E2E2E'}}
                >{formatMessage({id:'CANCEL_UPPERCASE'})}</Button>}
                {checkIfAvailable(permission, 'StartTraining') && <Button
                    className='rect'
                    onClick={handleStartTraining}
                    style={{color: '#FFFFFF', backgroundColor: '#2E63A3'}}
                >{formatMessage({id:'START_TRAINING'})}</Button>}
            </div>
        </div>
    );

    const statusInprocess = (
        <div className="control">
            <div className="trainingRecord">
                {props.treatmentTypeCode === nameMapping['TRE01'] && checkIfAvailable(permission, 'TrainingRecord') && <Button
                    className='rect'
                    style={{color: '#0083C1'}}
                    onClick={moveToTraingRecord}
                >{formatMessage({id:'TRAINING_RECORD'})}</Button>}
            </div>
            <div className="classOperation">
                {checkIfAvailable(permission, 'SuspendClass') && <Button
                    className='rect mr-20'
                    onClick={()=>setShowSuspendTreatmentDrawer(true)}
                    type='link'
                    style={{color: '#2E2E2E'}}
                >{formatMessage({id:'SUSPEND'})}</Button>}
                {checkIfAvailable(permission, 'FinishClass') && <Button
                    className='rect'
                    onClick={handleCompleted}
                    style={{color: '#FFFFFF', backgroundColor: '#5DD197'}}
                >{formatMessage({id:'COMPLETE_UPPERCASE'})}</Button>}
            </div>
        </div>
    );

    const statusSuspended = (
        <div className="control">
            <div className="addRecord">
            {props.treatmentTypeCode === nameMapping['TRE01'] && checkIfAvailable(permission, 'TrainingRecord') && <Button
                    className='rect'
                    style={{color: '#0083C1'}}
                    onClick={moveToTraingRecord}
                >{formatMessage({id:'TRAINING_RECORD'})}</Button>}
            </div>
        </div>
    );

    const statusCompleted = (
        <div className="control">
            <div className="trainingRecord">
                {/* {checkIfAvailable(permission, 'TrainingRecord') && <Button
                    className='rect'
                    style={{color: '#0083C1'}}
                    onClick={moveToTraingRecord}
                >{formatMessage({id:'TRAINING_RECORD'})}</Button>} */}
            </div>
        </div>
    );

    const code = getCode('TreatmentDetailStatus',props.treatmentDetailStatusCode);

    let result =(treatmentDetailState===nameMapping['TDS05'])?statusSuspended:(treatmentDetailState===nameMapping['TDS03'])?statusInprocess:(treatmentDetailState===nameMapping['TDS06'])?statusCompleted: (code === 'TDS02')? statusTodo:(code === 'TDS03')?statusInprocess:(code === 'TDS05')?statusSuspended:(code === 'TDS06')?statusCompleted:(<div></div>);

    const statusSuspendedDetail=(
            <div>
                <span className='trainingItem'>{formatMessage({id:'SUPENDED_COMMENT'})}</span>
                <div  style={{color: '#000000', height:'3.5rem'}}>
                    {(treatmentSuspend==="")?props.memo:treatmentSuspend}
                </div>
            </div>);
    const statusDetail = (
        <div className="control">
            {(code === 'TDS05'||treatmentDetailState==='Suspend')? <span>{statusSuspendedDetail}</span>:<span></span>}
            {props.treatmentTypeCode === nameMapping['TRE01'] && 
            <div>
                <span className='trainingItem'>{formatMessage({id:'TRAINING_ITEM'})}</span>
                <div   className='mt-20'>  
                    <div className='mt-20'>{Item}</div>
                </div>
                
                <div  className='mt-20'>  
                    <span className='trainingItem'>{formatMessage({id:'HOME_PROGRAM'})}</span>
                    <div> {recordHome}</div>
                </div>
                <div   className='mt-20'>  
                    <span className='trainingItem'>{formatMessage({id:'REMARK'})}</span>
                    <div> {recordRemark}</div>
                </div>
            </div>}
        </div>
    );

    const statusInprocessDetail =  props.treatmentTypeCode === nameMapping['TRE01'] ? (
        <div>
            <span className='trainingItem'>{formatMessage({id:'TRAINING_ITEM'})}</span>
            {(trainingItem.length>0||recordHome!==""||recordRemark!=="")?
                <div>  
                    <div className='mt-20'>{Item}</div>
            
                    <div className='mt-20'  >  
                        <span className='trainingItem'>{formatMessage({id:'HOME_PROGRAM'})}</span>
                        <div> {recordHome.toString()}</div>
                    </div>
                    <div  className='mt-20'>  
                        <span className='trainingItem'>{formatMessage({id:'REMARK'})}</span>
                        <div> {recordRemark.toString()}</div>
                    </div>
                </div>:
                <div>
                    <CheckboxWrapper>
                        <CheckboxContainer options={assistDeviceCheckbox}  value={itemInpute} onChange={itemChange} />
                    </CheckboxWrapper>
                    <div  style={{marginTop: '8px'}}>  
                        <Button className='saveButton' type="primary" ghost disabled={newNotes.length === 0} onClick={handleSave}> Save</Button>
                    </div>
                </div>
            }

        </div>
    ) : <div></div>;

    var checkState
    if(treatmentDetailState!==""){
        checkState=treatmentDetailState
    }
     let detail = (<div></div>);
     if(code === 'TDS03'||checkState===nameMapping['TDS03']){
        detail = statusInprocessDetail;
    }
    if(code === 'TDS06'||code === 'TDS05'||checkState===nameMapping['TDS06']||checkState===nameMapping['TDS05']){
        detail = statusDetail;
    }
    var times =moment(treatmentData.treatmentHour, 'HH:mm').add(50, 'minutes').format("HH:mm") ;
    return (
        <Wrapper>
            {showEditTreatmentDrawer&&
            <EditTreatmentDrawer 
                treatmentDate={treatmentData.treatmentDate} 
                treatmentHour={treatmentData.treatmentHour} 
                onClose={()=>setShowTreatmentDrawer(false)} 
                editDate={afterEditTreatmentDetail}
                treatmentId={props.id}
                orgId={props.orgId} 
            />}
            {showSuspendTreatmentDrawer&&
            <SuspendTreatmentDrawer 
                onClose={()=>setShowSuspendTreatmentDrawer(false)} 
                // refetch={afterEditTreatment} 
                changeState={aftterSuspend}
                treatmentId={props.id}
                orgId={props.orgId} 
            />}
            <StyledTreatmentDetailPage>
                <div className="d-flex v--center mb-10">
                <LeftOutlined onClick={constclickBack}/>
                    <div className="Title ml-15">
                        <span className='mr-15'>{props.planName|| ''}</span>
                     
                        <span style={{fontWeight: 'normal'}}>{props.name}</span>
                        {(code === 'TDS05'||treatmentDetailState==='Suspend')? <span className='ml-15' style={{color:'#EDB754'}}>{`(${formatMessage({id:'SUPENDED'})})`}</span>: <span></span>}
                    </div>
                </div>
                <div className="treatment">
                    <div className="treatmentInfo">
                        <div className="icon">
                            <img src={ImageMapping.TreatmentType[getCode('TreatmentType', props.treatmentTypeCode)]} alt={props.treatmentTypeCode} />
                        </div>
                        <div className="classInfo">
                            <span className="date">
                                {/* {(moment(newTreatmentDate).format('DD/MM/YYYY - dddd')??treatmentData.treatmentDate.format('DD/MM/YYYY - dddd'))} */}
                                {treatmentData.treatmentDate.format('DD/MM/YYYY - dddd')}

                            </span>
                            <span className="time">
                                 {props.classTypeCode===nameMapping['CAS01']? treatmentData.treatmentHour:treatmentData.treatmentHour +"-"+times}
                            </span>
                            <div className="detail">
                                <span className="detailTitle">
                                    {formatMessage({id:'DETAILS'})}
                                </span>
                                <span className="hr"></span>
                                <div className="details">
                                    <span className='topic'>{`${formatMessage({id:'STATUS'})}: `}</span>
                                    <span>{(treatmentDetailState)?treatmentDetailState:props.treatmentDetailStatusCode}</span>
                                </div>
                                <div className="details">
                                    <span className='topic'>{`${formatMessage({id:'VENUE'})}: `}</span>
                                    <span>{props.treatmentOrg}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {result}
                </div>
                <StyledTreatmentItem>
                    {detail}
                </StyledTreatmentItem>
            </StyledTreatmentDetailPage>
        </Wrapper>
    )
}
export default PlanTab;
const withData = (Component: JSX.Element, props: Props) => {
    return React.cloneElement(Component, {...props});
}